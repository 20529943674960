import * as yup from "yup";
import validationMethod from "../common-validation";

const REQUIRED = "Поле не заполнено";
const INCORRECT = "Некорректно заполнено поле";
const INC_INN = "Должен содержать 10 цифр";
const INC_ORG =
  "Только кириллица, с заглавной буквы названия компании (либо аббревиатура правовой формы)";
export const FALLBACK_MSG = "Что-то пошло не так, попробуйте еще раз";
export const SUCCESS_DESCR = "Мы свяжемся с вами в течение рабочего дня с 9:00 до 18:00";

export const schema = yup.object().shape({
  title: yup
    .string()
    .required(REQUIRED)
    .test("correctTitle", INC_ORG, validationMethod.correctOrganization),
  inn: yup.string().required(REQUIRED).test("correctInn", INC_INN, validationMethod.correctInn),
  phone: yup
    .string()
    .required(REQUIRED)
    .test("correctPhone", INCORRECT, validationMethod.correctPhone),
  email: yup.string().email(INCORRECT).optional(),
  userName: yup
    .string()
    .required(REQUIRED)
    .test("correctUserName", INCORRECT, validationMethod.correctUserName),
  agree: yup.string().test("a", (v) => (v && v === "true") as any),
});

export const defaultValues = {
  title: "",
  inn: "",
  phone: "",
  email: "",
  userName: "",
  agree: undefined,
};
