import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";

import { Header } from "components/header/header";
import { Sidebar } from "components/sidebar/sidebar";
import { SMain, SCont } from "../styles";
import { ContextSettings } from "context/context-settings";
import { ROUTE_WELCOME_PAGE } from "stream-constants/route-constants";

interface PageLayoutProps {
  isHideHeader?: boolean;
  isHideSidebar?: boolean;
}

export const PageLayout: React.FC<PageLayoutProps> = ({
  isHideHeader = false,
  isHideSidebar = false,
}) => {
  const { isDesktop } = useContext(ContextSettings);
  return (
    <>
      {!isHideHeader ? <Header /> : null}
      <SCont withHeader={!isHideHeader} withMenu={!isHideSidebar}>
        {!isHideSidebar && isDesktop() ? <Sidebar /> : null}
        <SMain>
          <Outlet />
        </SMain>
      </SCont>
    </>
  );
};

export const ProtectedRoute: React.FC<any> = ({ isAuthorized, isHideHeader, isHideSidebar }) => {
  return isAuthorized ? (
    <PageLayout isHideHeader={isHideHeader} isHideSidebar={isHideSidebar} />
  ) : (
    <Navigate replace to={ROUTE_WELCOME_PAGE} />
  );
};
