import React, { Dispatch, SetStateAction, useContext, useMemo, useState } from "react";
import { isEmpty } from "lodash";
import getYear from "date-fns/getYear";

import { SlideIn } from "../../table/slideIn/slideIn.component";
import { useResearchFavsList } from "hooks/api/analytics";
import { constructColumns, constructTableData, handleDateSwitch, tableStructure } from "./utils";
import { ITableFilterClm, Table } from "components/table/table.component";
import { Switch } from "components/switch/switch.component";
import { SGroup, SHeadStyle } from "./subcomponents";
import { ContextSettings } from "context/context-settings";
import { CusTypo } from "components/cusTypo/custom-typography";
import { IFavs } from "pages/analytics/const";

const dateCol = "date_change";
const usrCol = "user_id";
const defState = [
  {
    column: dateCol,
    value: [],
  },
  {
    column: usrCol,
    value: [],
  },
];

interface IProp {
  isOpen: boolean;
  close: () => void;
  open: () => void;
  setFavs: Dispatch<SetStateAction<IFavs>>;
}
export const FavsList: React.FC<IProp> = ({ isOpen, close, setFavs }) => {
  const { profile } = useContext(ContextSettings);
  const [columnFilter, setColumnFilter] = useState<ITableFilterClm[]>(defState);
  const { data } = useResearchFavsList();

  const tableData = useMemo(() => constructTableData(data ?? []), [data]);

  const columns = useMemo(
    () =>
      constructColumns({
        columns: tableStructure,
        setFavs: setFavs,
        closeSlide: close,
      }),
    [setFavs, close],
  );

  return (
    <SlideIn isOpen={isOpen} close={close} zIndex={21} maxWidth={720} withCloseHook={false}>
      <SHeadStyle>
        <SGroup>
          <CusTypo variant="h3_medium" font="comp">
            Списки устройств
          </CusTypo>
          <div>
            <Switch
              id="date__switch"
              setChecked={() => handleDateSwitch(setColumnFilter, dateCol, getYear(new Date()))}
              checked={!isEmpty(columnFilter.find((a) => a.column === dateCol)?.value)}
              label="Текущий год"
            />
            <Switch
              id="user__switch"
              setChecked={() => handleDateSwitch(setColumnFilter, usrCol, profile?.id)}
              checked={!isEmpty(columnFilter.find((a) => a.column === usrCol)?.value)}
              label="Мои списки"
            />
          </div>
        </SGroup>
        <Table
          columns={columns}
          data={tableData}
          view="alternatingRows"
          filterByColumn={columnFilter}
        />
      </SHeadStyle>
    </SlideIn>
  );
};
