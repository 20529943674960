import { FC, useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";

import { MTSButton } from "components/mts-button/mts-button";
import { InputDatePicker } from "components/mts-datepicker/mts-datepicker";
import { useAddCheckResultat } from "hooks/api/anomalies";
import { Select } from "components/select/select";
import { Radio } from "components/radio/radio.component";
import { MTSInput } from "components/mts-input";
import { SModalTitle } from "components/modal-notify-body/styles";
import { Toast } from "components/toast/toast";
import { Spacer } from "components/spacer/spacer.component";
import { Checkbox } from "components/checkbox/checkbox";
import { CusTypo } from "components/cusTypo/custom-typography";
import { schema, statsForbidPhase, statusArray } from "./validation-schema";
import { defaultValues } from "./const";
import { SBtns, STitle, SWrap } from "./styles";

type CommonProps = {
  idDevice: number;
  handleMutationRes: (a: "success" | "error") => void;
  onMutation: (a: string) => void;
  close: () => void;
};

const Form: FC<CommonProps> = ({ idDevice, handleMutationRes, onMutation, close }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
    setValue,
    trigger,
  } = useForm<any>({
    defaultValues: {
      ...defaultValues,
      id_pp: idDevice,
    },
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: false,
    reValidateMode: "onChange",
  });
  const mutation = useAddCheckResultat();

  const [watchStatus, watchPhase] = useWatch({
    control: control,
    name: ["telebot_request_result", "phase_num"],
  });

  const isRequired = !statsForbidPhase.includes(watchStatus);

  const onSubmit = (sbmtData: any) => {
    mutation.mutate(
      {
        ...sbmtData,
        id_pp: idDevice,
        telebot_request_dt: dirtyFields["telebot_request_dt"]
          ? format(new Date(sbmtData.telebot_request_dt), "yyyy-MM-dd'T'HH:mm:ss'Z'")
          : new Date(),
      },
      {
        onSuccess: () => {
          handleMutationRes("success");
          onMutation("");
          close();
          reset();
        },
        onError: () => {
          handleMutationRes("error");
          onMutation("");
        },
      },
    );
  };

  useEffect(() => {
    setValue("phase_num", isRequired ? "1" : undefined);
    trigger(["phase_num"]);
  }, [isRequired, setValue, trigger]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <section className="single">
        <Controller
          control={control}
          name="telebot_request_dt"
          render={({ field: { onChange, value } }) => {
            return (
              <InputDatePicker
                label="Дата выезда"
                onSelect={onChange}
                defaultVal={value}
                errorMessage={errors?.telebot_request_dt?.message}
                size="M"
                placeholder="ДД.ММ.ГГ"
              />
            );
          }}
        />
      </section>
      <section className="single">
        <Controller
          control={control}
          name="telebot_request_result"
          render={({ field: { onChange, value } }) => {
            return (
              <Select
                isDefaultValue={!value}
                label="Статус"
                size="M"
                type="status"
                optionsList={statusArray}
                onChange={onChange}
                errorMessage={errors?.telebot_request_result?.message}
              />
            );
          }}
        />
      </section>
      <section className="single">
        <Controller
          name="gaz_flg"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Checkbox
                value="true"
                checked={value === "true"}
                onChange={(a) => onChange(a === value ? undefined : a)}
                label="Подключен газ"
                color="red"
              />
            );
          }}
        />
      </section>
      {isRequired ? (
        <>
          <section className="radios">
            <Controller
              name="phase_num"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <Radio
                    id="one_phase"
                    name="one_phase"
                    value="1"
                    checked={value === "1"}
                    onChange={onChange}
                    text="Однофазный"
                    label="Тип прибора учета"
                    hasError={error != null && !value}
                  />
                  <Radio
                    id="three_phase"
                    name="three_phase"
                    value="3"
                    checked={value === "3"}
                    onChange={onChange}
                    text="Трехфазный"
                    hasError={error != null && !value}
                  />
                </>
              )}
            />
          </section>
          <section>
            <Controller
              control={control}
              name="phase_clamp_A"
              render={({ field: { onChange, value } }) => {
                return (
                  <MTSInput
                    control={control}
                    label="<span style='font-weight:500'>Фаза А</span> клещи"
                    onChange={onChange}
                    value={value}
                    errorMessage={errors?.phase_clamp_A?.message}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="phase_meter_A"
              render={({ field: { onChange, value } }) => {
                return (
                  <MTSInput
                    control={control}
                    label="<span style='font-weight:500'>Фаза А</span> прибор учета"
                    onChange={onChange}
                    value={value}
                    errorMessage={errors?.phase_meter_A?.message}
                  />
                );
              }}
            />
          </section>
          {watchPhase === "3" ? (
            <>
              <section>
                <Controller
                  control={control}
                  name="phase_clamp_B"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <MTSInput
                        control={control}
                        label="<span style='font-weight:500'>Фаза B</span> клещи"
                        onChange={onChange}
                        value={value}
                        errorMessage={errors?.phase_clamp_B?.message}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="phase_meter_B"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <MTSInput
                        control={control}
                        label="<span style='font-weight:500'>Фаза B</span> прибор учета"
                        onChange={onChange}
                        value={value}
                        errorMessage={errors?.phase_meter_B?.message}
                      />
                    );
                  }}
                />
              </section>
              <section>
                <Controller
                  control={control}
                  name="phase_clamp_C"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <MTSInput
                        control={control}
                        label="<span style='font-weight:500'>Фаза C</span> клещи"
                        onChange={onChange}
                        value={value}
                        errorMessage={errors?.phase_clamp_C?.message}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="phase_meter_C"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <MTSInput
                        control={control}
                        label="<span style='font-weight:500'>Фаза C</span> прибор учета"
                        onChange={onChange}
                        value={value}
                        errorMessage={errors?.phase_meter_C?.message}
                      />
                    );
                  }}
                />
              </section>
            </>
          ) : null}
        </>
      ) : null}
      <section className="single">
        <Controller
          control={control}
          name="telebot_request_comment"
          render={({ field: { onChange, value } }) => {
            return (
              <MTSInput
                control={control}
                placeholder="Не обязательно"
                type="textarea"
                label="Комментарий"
                onChange={onChange}
                value={value}
              />
            );
          }}
        />
      </section>
      <section className="single">
        <SBtns>
          <MTSButton size="M" variant="primary" type="submit" onClick={() => {}}>
            Подтвердить
          </MTSButton>
          <MTSButton size="M" variant="secondary" onClick={close}>
            Отмена
          </MTSButton>
        </SBtns>
      </section>
    </form>
  );
};

type CommonPropsForm = {
  idDevice?: string;
  inModal?: boolean;
  deviceSn?: string;
  close: () => void;
  onMutation?: (a: string) => void;
};

export const FormAddResultCheck: FC<CommonPropsForm> = ({
  idDevice = "",
  inModal = false,
  deviceSn = null,
  close,
  onMutation = () => {},
}) => {
  const handleMutation = (val: "success" | "error") => {
    onMutation("");
    val === "success" ? toastSuc() : toastFail();
  };

  return (
    <SWrap>
      {inModal ? (
        <>
          <SModalTitle>Добавить результат выезда</SModalTitle>
        </>
      ) : (
        <STitle>
          <CusTypo variant="h3_bold">Добавить результат выезда</CusTypo>
          {deviceSn ? (
            <CusTypo variant="p3_regular" font="comp">
              {deviceSn}
            </CusTypo>
          ) : null}
        </STitle>
      )}
      <Spacer value="24px" />
      <Form
        idDevice={idDevice ? Number(idDevice) : Number(deviceSn)}
        handleMutationRes={handleMutation}
        onMutation={onMutation}
        close={close}
      />
    </SWrap>
  );
};

const toastSuc = () =>
  toast(<Toast title={`Результат выезда добавлен`} />, {
    autoClose: 2000,
    hideProgressBar: false,
  });

const toastFail = () =>
  toast(<Toast title={`Результат выезда не добавлен`} isError />, {
    autoClose: 2000,
    hideProgressBar: false,
  });
