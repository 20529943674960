export const individual_user_mock = {
  createdTime: "2023-03-06T11:59:46.913336",
  email: "dontknow@mts.ru",
  id: 47,
  isActive: true,
  phone: "9053013966",
  roles: [
    {
      id: 2,
      name: "owner",
    },
  ],
  tenant: {
    email: "besk@mail.ru",
    id: "bsk",
    phone: "88007777777",
    title: "ООО БашкирЭнерго",
  },
  userName: "Дерябин Александр",
};
