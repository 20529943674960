import { isEmpty } from "lodash";
import { IHttpMethods } from "stream-constants/server-constants";
import {
  ADD_CHECK_RESULT,
  ANOMALIES_ADD_DEVICE,
  ANOMALIES_ARCHIVE,
  ANOMALIES_CHECK_DEVICE,
  ANOMALIES_EFFICIENCY,
  DEVICE_LIST,
  GET_XLSX_DEVICE_LIST,
  MAKE_ACTIVE,
  UPLOAD_XLSX_DEVICE_LIST,
} from "services/urls";
import { fetchData } from "services/services-utils/fetch-data";

import { base, mock_an_archive } from "./mock-data";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { IDeviceStatusUpdateRequest, IDevlistReq } from "interface/anomalies";

const makeQueryParams = (args: string[][]) => {
  if (isEmpty(args)) return "";
  return args
    .map(([key, val]) => (val == null ? "" : `${key}=${val}`))
    .filter((c) => c !== "")
    .map((b, ind) => (ind === 0 ? `?${b}` : `&${b}`))
    .join("");
};
export const getDeviceList = async (data: IDevlistReq) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = base;
  } else {
    result = await fetchData({
      url: `${DEVICE_LIST}${makeQueryParams(Object.entries(data))}`,
      method: IHttpMethods.GET,
      name: "getDeviceList",
      emptyResponse: false,
    });
  }

  return result;
};

export const getXlsxDeviceList = async (data: any) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = [];
  } else {
    result = await fetchData({
      url: GET_XLSX_DEVICE_LIST,
      method: IHttpMethods.POST,
      name: "downloadFile",
      body: JSON.stringify({
        list_of_id: data,
      }),
      emptyResponse: false,
      blobResponse: true,
      responseAbordTimeOut: false,
    }).then((blob) => {
      const dateNow = new Date();
      const dateString = dateNow.toISOString().slice(0, 10).replace(/-/g, "");
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `devicelist_${dateString}.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
  }

  return result;
};

export const uploadExcelFile = async <T,>(data: T): Promise<{}> => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = [];
  } else {
    result = fetchData({
      url: UPLOAD_XLSX_DEVICE_LIST,
      method: IHttpMethods.POST,
      name: "uploadFile",
      body: data,
      emptyResponse: false,
    });
  }

  return result;
};

export const getAddCheckResult = async (data: IDeviceStatusUpdateRequest) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = "OK";
  } else {
    result = fetchData({
      url: ADD_CHECK_RESULT,
      method: IHttpMethods.POST,
      name: "formAddCheckResult",
      body: JSON.stringify(data),
      emptyResponse: false,
    });
  }

  return result;
};

export const getListEfficiency = async (data: IDevlistReq) => {
  return await fetchData({
    url: ANOMALIES_EFFICIENCY,
    method: IHttpMethods.POST,
    name: "getListEfficiency",
    emptyResponse: false,
    body: JSON.stringify(data),
    responseAbordTimeOut: false,
  });
};

export const getAnomaliesArchive = async () => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = mock_an_archive;
  } else {
    result = await fetchData({
      url: ANOMALIES_ARCHIVE,
      method: IHttpMethods.GET,
      name: "getAnomaliesArchive",
      emptyResponse: false,
    });
  }

  return result;
};

export const anoMakeActive = async (id: string | number) => {
  return await fetchData({
    url: `${MAKE_ACTIVE}/${id}`,
    method: IHttpMethods.GET,
    name: "anoMakeActive",
    emptyResponse: false,
  });
};

export const getCheckDevice = async (sn: string) => {
  return await fetchData({
    url: `${ANOMALIES_CHECK_DEVICE}/${sn}`,
    method: IHttpMethods.GET,
    name: "getCheckDevice",
    emptyResponse: false,
  });
};

export const addDevice = async (id_pp: number) => {
  return await fetchData({
    url: ANOMALIES_ADD_DEVICE,
    method: IHttpMethods.POST,
    name: "formAddCheckResult",
    body: JSON.stringify({ id_pp }),
    emptyResponse: false,
  });
};
