import { IMenegementBilling } from "interface/management";

export const base: IMenegementBilling[] = [
  {
    company_nm: "ООО БашкирЭнерго 1",
    service_nm: "Поиск коммерческих потерь",
    bill_id: 1111111,
    bill_dt: "Mon, 22 Aug 2022 00:00:00 GMT",
    bill_amount: "300000",
    bill_status: "Оплачен",
    tenant_id: "OOO TestovajaStranitsa",
  },
  {
    company_nm: "ООО БашкирЭнерго 2",
    service_nm: "Поиск коммерческих потерь",
    bill_id: 222222,
    bill_dt: "Mon, 22 Aug 2022 00:00:00 GMT",
    bill_amount: "200000",
    bill_status: "К оплате",
    tenant_id: "OOO TestovajaStranitsa",
  },
  {
    company_nm: "ООО БашкирЭнерго",
    service_nm: "Поиск коммерческих потерь",
    bill_id: 333333,
    bill_dt: "Mon, 22 Aug 2022 00:00:00 GMT",
    bill_amount: "1000000",
    bill_status: "Оплачен",
    tenant_id: "OOO TestovajaStranitsa",
  },
];
