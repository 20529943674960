import { DeviceName, SActions } from "./subComponents";
import Icon from "components/icons";
import { ProductDeskAction } from "components/card-info/product/product.component";
import { EDevicesCost } from "components/forms/shop/const";
import { CusTypo } from "components/cusTypo/custom-typography";
import { emptyPrice, formatPrice } from "./const";

export const renderPriceByVal = (value: number, priceType) =>
  value === -1
    ? emptyPrice
    : `${formatPrice(value)} ${priceType === EDevicesCost["Ежемесячная"] ? " ₽/месяц" : " ₽"}`;

const renderCell = (val) => {
  switch (val) {
    case "name":
      return {
        Cell: ({ value, row }) => <DeviceName data={row} />,
      };
    case "price":
      return {
        Cell: ({ value, row }) => (
          <CusTypo
            variant="p4_medium"
            styles={row.original.hide_flag ? { filter: "opacity(.4)" } : undefined}
          >
            {renderPriceByVal(value, row.original.price_type)}
          </CusTypo>
        ),
      };
    case "hide_flag":
      return {
        Cell: ({ value, row }) => (
          <SActions>
            {value ? <Icon.Eye /> : null}
            <ProductDeskAction data={row} />
          </SActions>
        ),
      };
    default:
      return {
        Cell: ({ value, row }) => (
          <CusTypo
            variant="p4_regular"
            styles={row.original.hide_flag ? { filter: "opacity(.4)" } : undefined}
          >
            {value ?? ""}
          </CusTypo>
        ),
      };
  }
};

export const constructColumns = ({ columns }) => {
  return columns.map((singleColumn) => {
    return {
      ...singleColumn,
      ...renderCell(singleColumn.accessor),
    };
  });
};
