interface IProp {
  code: "history" | "info";
  name: string;
}

export const tabListDesktop: IProp[] = [
  { code: "history", name: "История проверок" },
  { code: "info", name: "Дополнительная информация" },
];
export const tabListMob: IProp[] = [
  { code: "history", name: "История проверок" },
  { code: "info", name: "Доп. информация" },
];
