import React, { useRef, useState, useLayoutEffect } from "react";
import { AccordionDetails, AccordionSummary } from "@mui/material";
import { toast } from "react-toastify";

import { useAnoMakeActive } from "hooks/api/anomalies";
import { DeviceAccordionDetails } from "components/card-info/cardInfo.component";
import Icon from "components/icons";
import { makeCopyText } from "components/copyToClipboard/copyToClipboard.component";
import { PopupAction } from "components/popup-action/popup-action";
import { Toast } from "components/toast/toast";
import { CustomWidthTooltip } from "components/tooltip/tooltip";
import { CusTypo } from "components/cusTypo/custom-typography";
import { deviceCardTitleByKey, makeDeviceCard } from "components/card-info/utils";
import { HEIGHT_EXP } from "components/table/styles";
import { SFltBtn } from "components/table/table-sort/styles";
import { SAccordion, SCont, SGroup, SItem, SText } from "./styles";

const MAGIC_HEIGHT = 429;
const FAULT = 40;

export const Expandable = ({ value, row, isOpen, code }) => {
  const [parent, setParent] = useState(900);
  const [heightInnerCard, setHeightInnerCard] = useState(0);

  const refOne = useRef<HTMLDivElement>(null);
  const refTwo = useRef<any>(null);

  const calcHeightInner = (val: number) => setHeightInnerCard(val);

  useLayoutEffect(() => {
    if (refOne?.current) {
      const width = refOne?.current?.closest?.(".tbody_tr")?.clientWidth ?? 900;
      setParent(width);
    }
  }, []);

  useLayoutEffect(() => {
    if (refTwo?.current) {
      const elem = refTwo?.current;
      const measured = elem?.getBoundingClientRect().height;
      const totalHeight = measured < MAGIC_HEIGHT ? measured + FAULT : measured;
      if (isOpen) {
        elem.closest(".tbody_tr").style.height =
          heightInnerCard > totalHeight ? `${heightInnerCard}px` : `${totalHeight + HEIGHT_EXP}px`;
      } else {
        elem.closest(".tbody_tr").style.height = `${HEIGHT_EXP}px`;
      }
    }
  }, [isOpen, heightInnerCard]);

  return (
    <>
      <SText ref={refOne} onClick={(e) => makeCopyText(e, value)}>
        № {value}
        <Icon.Copy sx={{ width: 12, height: 12, marginLeft: "4px" }} />
      </SText>
      <SAccordion
        id={row.original.id}
        aria-controls={`panel${row.original.id}-content`}
        expanded={isOpen}
        TransitionProps={{
          unmountOnExit: true,
        }}
        style={{
          width: parent,
        }}
        ref={refTwo}
      >
        <AccordionSummary
          style={{
            minHeight: 0,
            height: 0,
          }}
        />
        <AccordionDetails>
          <DeviceAccordionDetails
            data={row.original}
            calcHeightInner={calcHeightInner}
            isDisabled={code === "В архиве"}
          />
        </AccordionDetails>
      </SAccordion>
    </>
  );
};

export const Enlarge = ({ value }) => {
  return <CusTypo variant="p4_regular">{value}</CusTypo>;
};

export const MobDeviceCard = ({ row }) => {
  return (
    <SCont>
      {Object.entries(makeDeviceCard(row.original ?? row)).map(([key, val]) => {
        return key === "factory_count_num" || key === "adress_norm" ? (
          val.value
        ) : (
          <SItem key={key}>
            <CusTypo variant="c1_regular" font="comp">
              {deviceCardTitleByKey[key]}
            </CusTypo>
            <CusTypo variant="c1_regular">{val.value}</CusTypo>
          </SItem>
        );
      })}
    </SCont>
  );
};

export const StateCode = ({ code }) => {
  const isOk = code === "Актуально";

  return (
    <SGroup isOk={isOk}>
      {isOk ? <Icon.CheckBorder /> : <Icon.Archive />}
      <CusTypo variant="c1_regular">{isOk ? "Актуально" : "В архиве"}</CusTypo>{" "}
    </SGroup>
  );
};

interface IProp {
  code: string;
  id: string | number;
}
export const SelectAction: React.FC<IProp> = ({ code, id }) => {
  const { mutate } = useAnoMakeActive();

  return code === "В архиве" ? (
    <PopupAction
      view="light"
      items={[
        {
          title: "Переместить в актуальный список",
          onClick: () => mutate(id, {}),
        },
      ]}
    />
  ) : null;
};

export const ArchiveFilter = ({ withValue, handleClick }) => {
  return (
    <CustomWidthTooltip
      title={<div style={{ fontWeight: 400 }}>Показывать архивные устройства</div>}
      arrow
      placement="right"
    >
      <SFltBtn withValue={withValue} onClick={handleClick}>
        <Icon.Archive />
      </SFltBtn>
    </CustomWidthTooltip>
  );
};

export const toastArchiveSuc = () =>
  toast(<Toast title="Перемещено в актуальный список" />, {
    autoClose: 2000,
    hideProgressBar: false,
  });

export const toastArchiveFail = () =>
  toast(<Toast title="Не удалось переместить" isError />, {
    autoClose: 2000,
    hideProgressBar: false,
  });
