import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Eye = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.79289 5.20711C5.40237 4.81658 5.40237 4.18342 5.79289 3.79289C6.18342 3.40237 6.81658 3.40237 7.20711 3.79289L21.2071 17.7929C21.5976 18.1834 21.5976 18.8166 21.2071 19.2071C20.8166 19.5976 20.1834 19.5976 19.7929 19.2071L17.4258 16.84C16.1973 17.7309 15.3641 18.2139 13.5232 18.5292C12.8647 18.6419 11.1432 18.6419 10.4847 18.5292C8.37587 18.168 7.58938 17.5868 6.01639 16.4244C5.1112 15.7555 4.28298 15.0297 3.58411 14.3631C2.53072 13.3584 2.00403 12.856 2.00403 11.9998C2.00403 11.1435 2.53072 10.6411 3.58411 9.63643C4.28299 8.96984 5.1112 8.24405 6.01639 7.57514C6.48468 7.22909 6.88326 6.93455 7.26778 6.68199L5.79289 5.20711ZM8.7197 8.13391C8.31624 8.36685 7.88757 8.67922 7.20501 9.18361C6.38351 9.79068 5.61976 10.4587 4.9645 11.0837C4.69397 11.3417 4.49064 11.536 4.32202 11.7082C4.19498 11.838 4.11163 11.9313 4.05599 11.9998C4.11163 12.0682 4.19498 12.1615 4.32202 12.2913C4.49064 12.4636 4.69397 12.6578 4.9645 12.9159C5.61976 13.5408 6.38351 14.2089 7.20501 14.8159C8.02368 15.4209 8.4771 15.7496 8.95987 15.9964C9.3957 16.2192 9.91638 16.4027 10.8223 16.5579C10.9723 16.5835 11.4221 16.6137 12.0039 16.6137C12.5858 16.6137 13.0356 16.5835 13.1856 16.5579C14.0915 16.4027 14.6122 16.2192 15.048 15.9964C15.3436 15.8453 15.6281 15.6635 15.9922 15.4064L14.7517 14.1659C14.1104 14.9794 13.1162 15.5016 12 15.5016C10.066 15.5016 8.49823 13.9338 8.49823 11.9999C8.49823 10.8836 9.02048 9.88941 9.83397 9.24818L8.7197 8.13391ZM13.3137 12.7279L11.272 10.6862C10.8106 10.9424 10.4984 11.4347 10.4984 11.9999C10.4984 12.8292 11.1707 13.5015 12 13.5015C12.5652 13.5015 13.0574 13.1892 13.3137 12.7279Z"
        fill="#969FA8"
      />
      <path
        d="M13.5569 5.47615C13.0128 5.38172 12.4951 5.74629 12.4007 6.29044C12.3062 6.83459 12.6708 7.35227 13.2149 7.4467C14.1025 7.60073 14.6167 7.78264 15.048 8.0031C15.5305 8.24973 15.9834 8.57801 16.8029 9.18356C17.6244 9.79062 18.3881 10.4586 19.0434 11.0836C19.5622 11.5785 19.8093 11.8221 19.9522 11.9996C19.8594 12.1145 19.7125 12.2696 19.4536 12.5218C19.0579 12.9071 19.0495 13.5402 19.4348 13.9359C19.8201 14.3315 20.4533 14.3399 20.8489 13.9546C21.1551 13.6564 21.4125 13.3928 21.6088 13.1257C21.7229 12.9706 21.8315 12.7945 21.9055 12.5878C21.9837 12.3692 22.0038 12.1711 22.0038 11.9997C22.0038 11.8283 21.9837 11.6302 21.9055 11.4116C21.8315 11.2049 21.7229 11.0288 21.6088 10.8737C21.3455 10.5155 20.9497 10.138 20.4259 9.63837L20.4238 9.63637C19.7249 8.96979 18.8967 8.244 17.9915 7.57508L17.9906 7.57441C17.2053 6.99412 16.6151 6.55799 15.9583 6.22225C15.3087 5.89021 14.5934 5.65602 13.5569 5.47615Z"
        fill="#969FA8"
      />
    </SvgIcon>
  );
};

export default Eye;
