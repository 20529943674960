import { IResearchStatInfoRes, IResearchStatResResponse } from "interface/analytics";
import { Highlighter, SHeadGroup } from "../researchTable/subComponents";
import { CusTypo } from "components/cusTypo/custom-typography";
import { RenderFilter } from "components/table/render-filter/renderFilter.component";
import { StatBubble } from "components/demo/demoTable/subComponents";
import { groupHeaderByName, subheaders } from "./const";

export const constructTableData = (draft: IResearchStatResResponse[]) =>
  draft?.map((d) => {
    const { id_res } = d;

    return {
      ...d,
      id: id_res,
    };
  });

const renderHeaderGroup = (col) => {
  const pos =
    col.originalId === "podrazdel" ? "left" : col.originalId === "total" ? "right" : undefined;
  return (
    <SHeadGroup pos={pos}>
      <CusTypo variant="c1_medium" font="comp">
        {groupHeaderByName[col.headerTitle]}
      </CusTypo>
    </SHeadGroup>
  );
};

const getStringOrElem = (accessor) => {
  switch (accessor) {
    case "cnt_fp_high":
    case "cnt_fp_fraud":
    case "cnt_ml_score_05":
      return <StatBubble value={subheaders[accessor]} size="XS" />;
    default:
      return subheaders[accessor] ?? "";
  }
};

const renderHeader = (column) => {
  return (
    <RenderFilter
      isSorted={column.isSorted}
      isSortedDesc={column.isSortedDesc || false}
      title={getStringOrElem(column.id)}
    />
  );
};

const renderCell = (val, props) => {
  switch (val) {
    case "name_po":
      return {
        Cell: ({ value, row }) => (
          <Highlighter val={value} isSelected={props.selectedId === row.original.id} />
        ),
      };

    default:
      return {
        Cell: ({ value }) => value,
      };
  }
};

export const constructColumns = ({ columns, selectedId }) => {
  return columns.map((singleColumn) => {
    return {
      ...singleColumn,
      Header: ({ column }) => renderHeaderGroup(column),
      columns: singleColumn.columns?.map((a) => ({
        ...a,
        ...renderCell(a.accessor, { selectedId }),
        Header: ({ column }) => renderHeader(column),
      })),
    };
  });
};

export const makeStat = (data: IResearchStatInfoRes) => {
  const {
    cnt_res,
    cnt_res_es,
    cnt_id_pp,
    cnt_city,
    cnt_village,
    cnt_mixed,
    cnt_addr_yes,
    cnt_addr_not,
    cnt_addr_all,
    cnt_fl,
    cnt_ul,
    cnt_fu_not,
  } = data;
  return [
    {
      title: "Основные показатели общей статистики",
      columns: {
        cnt_res,
        cnt_res_es,
      },
      total: cnt_id_pp,
    },
    {
      title: "Территориальный признак",
      columns: {
        cnt_city,
        cnt_village,
        cnt_mixed,
      },
      total: null,
    },
    {
      title: "Привязка к адресам",
      columns: {
        cnt_addr_yes,
        cnt_addr_not,
      },
      total: cnt_addr_all,
    },
    {
      title: "Категории абонента",
      columns: {
        cnt_fl,
        cnt_ul,
        cnt_fu_not,
      },
      total: null,
    },
  ];
};
