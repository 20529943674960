import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ContextSettings } from "context";
import { useNavigate } from "react-router-dom";
import theme from "theme";
import { AccordionSummary, AccordionDetails, Accordion } from "@mui/material";

import { ICardInfo } from "../cardInfo.component";
import { FormEditTenant } from "components/forms/tenant/form-edit-tenant";
import { FormAddUser } from "components/forms/user/form-add-user";
import { ModalNotifyBody } from "components/modal-notify-body/modal-notify-body";
import { MTSModal } from "components/mts-modal/mts-modal";
import { MTSStatus } from "components/mts-status/mts-status";
import { PopupAction } from "components/popup-action/popup-action";
import {
  activeTenantDetails,
  convertEmptyValue,
  isActiveTenantCard,
  renderStaffField,
  renderDeployedDetails,
  renderDeployedBar,
} from "../utils";
import Icon from "components/icons";
import { invalidateManagmentTenants, useMutationDeleteTenant } from "hooks/api/management";
import { ROUTE_TENANT_PAGE, ROUTE_USERS } from "stream-constants/route-constants";
import { MTSButton } from "components/mts-button/mts-button";
import { CusTypo } from "components/cusTypo/custom-typography";
import { Spacer } from "components/spacer/spacer.component";
import { ModalAction } from "components/modal-action/modal-action";
import { SBtns, SWrapper } from "./styles";
import { SModalTitle } from "components/modal-notify-body/styles";
import {
  SAddUser,
  SButton,
  SCardField,
  SCardInfo,
  SCardTitle,
  SContainer,
  SContainerMobile,
  SContent,
  SInner,
  SInnerItem,
  SSummaryContent,
} from "../styles";

export const TenantCard = React.forwardRef<any, any>((props: ICardInfo, ref) => {
  const { data, aria, expanded, handleChange } = props;
  const navigate = useNavigate();
  const { isDesktop } = useContext(ContextSettings);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showAddUser, setAddUser] = useState(false);
  const [showModalAction, setShowModalAction] = useState(false);
  const toggleShowModalDelete = () => {
    setShowModalDelete(!showModalDelete);
    if (deleteTenant?.isSuccess) invalidateManagmentTenants();
  };
  const toggleModalEdit = () => setShowModalEdit(!showModalEdit);
  const toggleAddUser = () => setAddUser(!showAddUser);
  const toggleShowModalAction = () => setShowModalAction(!showModalAction);
  const deleteTenant = useMutationDeleteTenant(data.id as unknown as string);
  const navigateToUsers = () =>
    navigate(`/${ROUTE_USERS}`, {
      state: { tenant_id: data.id, tenant_name: data.title },
    });

  const dataPopup = [
    {
      title: "Редактировать",
      onClick: toggleModalEdit,
    },
    {
      title: "Управление сотрудниками",
      onClick: navigateToUsers,
    },
    {
      title: "Удалить",
      onClick: toggleShowModalDelete,
    },
  ];
  const dataPopupMobile = [
    {
      title: "Добавить пользователя",
      onClick: toggleAddUser,
    },
    {
      title: "Редактировать",
      onClick: toggleModalEdit,
    },
    {
      title: "Управление сотрудниками",
      onClick: navigateToUsers,
    },
    {
      title: "Удалить",
      onClick: toggleShowModalDelete,
    },
  ];
  const propsCard = {
    dataPopup: isDesktop() ? dataPopup : dataPopupMobile,
    showModalDelete,
    toggleShowModalDelete,
    deleteTenant,
    showAddUser,
    toggleAddUser,
    showModalEdit,
    toggleModalEdit,
    showModalAction,
    toggleShowModalAction,
  };

  return isDesktop() ? (
    <Accordion
      key={aria}
      expanded={expanded === `panel${aria}`}
      onChange={handleChange(`panel${aria}`)}
      TransitionProps={{ unmountOnExit: true }}
      ref={ref}
    >
      <TenantAccordionSummary
        data={data}
        aria={aria}
        key={`${aria}-summ`}
        expanded={expanded}
        props={propsCard}
        isActiveTenant={isActiveTenantCard(data.deploy_steps)}
      />
      <TenantAccordionDetails
        data={data}
        key={`${aria}-details`}
        isActiveTenant={isActiveTenantCard(data.deploy_steps)}
      />
    </Accordion>
  ) : (
    <TenantMobileCard data={data} key={aria} props={propsCard} />
  );
});

const TenantAccordionSummary: React.FC<ICardInfo> = ({
  data,
  aria,
  expanded,
  isActiveTenant,
  props,
}) => {
  const {
    dataPopup,
    showModalDelete,
    toggleShowModalDelete,
    deleteTenant,
    showAddUser,
    toggleAddUser,
    showModalEdit,
    toggleModalEdit,
  } = props;

  return (
    <SContainer>
      <AccordionSummary aria-controls={`panel${aria}d-content`} id={`panel${aria}d-header`}>
        <SSummaryContent type="tenant" isActiveTenant={isActiveTenant}>
          <SCardTitle>
            <CusTypo variant="p3_bold">{convertEmptyValue(data.title)}</CusTypo>
          </SCardTitle>
          <SCardInfo>
            {isActiveTenant ? (
              <>
                <SCardField>{MTSStatus(data.is_active ? "Активна" : "Приостановлена")}</SCardField>
                <SCardField>
                  <CusTypo variant="p4_regular" styles={{ whiteSpace: "nowrap" }}>
                    {!expanded ? renderStaffField(data) : null}
                  </CusTypo>
                </SCardField>
              </>
            ) : (
              renderDeployedBar(data)
            )}
          </SCardInfo>
        </SSummaryContent>
      </AccordionSummary>
      <SButton>
        <SAddUser onClick={toggleAddUser}>
          <Icon.UserAdd sx={{ width: 24, height: 24 }} />
        </SAddUser>
        <PopupAction items={dataPopup} />
      </SButton>
      <MTSModal open={showModalDelete} close={toggleShowModalDelete}>
        {deleteTenant?.isSuccess ? (
          <ModalNotifyBody title="Организация удалена" isSuccess />
        ) : deleteTenant?.isError ? (
          <ModalNotifyBody
            title="Организация не удалена"
            isError
            textDescrioption="Что-то пошло не так, попробуйте удалить организацию еще раз"
          />
        ) : (
          <TenantDelete
            tenantData={data}
            onClick={() => deleteTenant.mutate()}
            onCancel={toggleShowModalDelete}
          />
        )}
      </MTSModal>
      <MTSModal open={showAddUser} close={toggleAddUser}>
        <FormAddUser onCancel={toggleAddUser} withTenantRefresh tenantId={data.id} />
      </MTSModal>
      <MTSModal open={showModalEdit} close={toggleModalEdit}>
        <FormEditTenant onCancel={toggleModalEdit} id={data.id} />
      </MTSModal>
    </SContainer>
  );
};

const TenantAccordionDetails: React.FC<ICardInfo> = ({ data, isActiveTenant }) => {
  return (
    <AccordionDetails key={data.id}>
      <SInner>
        <ul>
          {isActiveTenant
            ? activeTenantDetails(data).map((item) => (
                <SInnerItem>
                  <div>
                    <CusTypo variant="c1_regular">{item.title}</CusTypo>
                  </div>
                  <div>
                    <CusTypo variant="c1_medium">{item.value}</CusTypo>
                  </div>
                </SInnerItem>
              ))
            : renderDeployedDetails(data)}
        </ul>
      </SInner>
    </AccordionDetails>
  );
};

const TenantMobileCard: React.FC<ICardInfo> = ({ data, props }) => {
  const {
    dataPopup,
    showModalDelete,
    toggleShowModalDelete,
    deleteTenant,
    showAddUser,
    toggleAddUser,
    showModalEdit,
    toggleModalEdit,
  } = props;
  const navigate = useNavigate();
  const { isMobile } = useContext(ContextSettings);
  const clickMobile = useCallback((id) => navigate(`/${ROUTE_TENANT_PAGE}/${id}`), [navigate]);

  return (
    <>
      <SContainerMobile onClick={() => clickMobile?.(data.id)}>
        <SContent>
          <div>
            {isActiveTenantCard(data.deploy_steps) ? (
              <div>{MTSStatus(data.is_active ? "Активна" : "Приостановлена")}</div>
            ) : null}
          </div>
          <div>
            <CusTypo variant="p3_bold">{convertEmptyValue(data.title)}</CusTypo>
          </div>
          {isActiveTenantCard(data.deploy_steps) ? (
            <>
              <CusTypo variant="p4_regular" styles={{ whiteSpace: "nowrap" }}>
                {renderStaffField(data)}
              </CusTypo>
            </>
          ) : (
            renderDeployedBar(data)
          )}
        </SContent>
        {isMobile() ? (
          <ModalAction items={dataPopup} />
        ) : (
          <PopupAction items={dataPopup} view="light" />
        )}
      </SContainerMobile>
      <MTSModal open={showModalDelete} close={toggleShowModalDelete}>
        {deleteTenant?.isSuccess ? (
          <ModalNotifyBody title="Организация удалена" isSuccess />
        ) : deleteTenant?.isError ? (
          <ModalNotifyBody
            title="Организация не удалена"
            isError
            textDescrioption="Что-то пошло не так, попробуйте удалить организацию еще раз"
          />
        ) : (
          <TenantDelete
            tenantData={data}
            onClick={() => deleteTenant.mutate()}
            onCancel={toggleShowModalDelete}
          />
        )}
      </MTSModal>
      <MTSModal open={showAddUser} close={toggleAddUser}>
        <FormAddUser onCancel={toggleAddUser} withTenantRefresh tenantId={data.id} />
      </MTSModal>
      <MTSModal open={showModalEdit} close={toggleModalEdit}>
        <FormEditTenant onCancel={toggleModalEdit} id={data.id} />
      </MTSModal>
    </>
  );
};

export const TenantDelete = ({ tenantData, onCancel, onClick }) => {
  return (
    <>
      <SModalTitle styles={{ textAlign: "center" }}>Вы хотите удалить организацию?</SModalTitle>
      <Spacer value="32px" />
      <div style={{ textAlign: "center" }}>
        <CusTypo variant="p3_regular" styles={{ display: "block" }}>
          {`${
            tenantData?.title ?? "Организация"
          } будет удалена, а всем назначенным сотрудникам будет присвоен статус `}
        </CusTypo>

        <CusTypo
          variant="p3_regular"
          styles={{ color: theme.mtsColor.accent.warning.lightMode, display: "block" }}
        >
          Приостановлен
        </CusTypo>
      </div>
      <Spacer value="32px" />
      <SBtns>
        <MTSButton size="M" variant="primary" type="submit" onClick={onClick}>
          Удалить
        </MTSButton>
        <MTSButton size="M" variant="secondary" onClick={onCancel}>
          Отмена
        </MTSButton>
      </SBtns>
    </>
  );
};

export const TenantCardInfo: React.FC<ICardInfo> = ({ data, variant, isAccented }) => {
  const [expanded, setExpanded] = React.useState<string | false>("");
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const someId = variant === "billing" ? data.bill_id : data.id;
  const contRef = useRef<any>(null);

  useEffect(() => {
    if (contRef.current && isAccented) {
      setExpanded(`panel${someId}`);
      contRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isAccented, someId]);

  return (
    <SWrapper>
      {variant === "tenant" ? (
        <TenantCard
          data={data}
          aria={someId}
          expanded={expanded}
          handleChange={handleChange}
          ref={contRef}
        />
      ) : null}
    </SWrapper>
  );
};
