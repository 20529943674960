import * as yup from "yup";
import { IShopProduct } from "interface/management";
import { formatPrice } from "components/management-shop/shopDevicesTable/const";
import { EDevicesCategory } from "./const";

const REQUIRED = "Поле не заполнено";
const INCORRECT = "Некорректно заполнено поле";
export const FALLBACK_MSG = "Что-то пошло не так, попробуйте еще раз";

export const schema = yup.object().shape({
  category: yup
    .mixed()
    .test("correctCategory", REQUIRED, (value?: string) => (value ?? "").length > 0)
    .required(REQUIRED),
  type: yup.mixed().when(["category"], {
    is: (val) => val === EDevicesCategory["Счетчик учета электроэнергии"],
    then: yup
      .mixed()
      .test("correctType", REQUIRED, (value?: string) => (value ? value?.length > 0 : false))
      .required(REQUIRED),
    otherwise: yup.mixed().nullable().notRequired(),
  }),
  name: yup
    .string()
    .required(REQUIRED)
    .test("correctTitle", "Минимум 4 символа", (value?: string) => (value ?? "").length > 3),
  price_type: yup
    .mixed()
    .test("correctCategory", REQUIRED, (value?: string) => (value ?? "").length > 0)
    .required(REQUIRED),
  price: yup.string().when(["price_type"], {
    is: (val) => val !== "По запросу",
    then: yup
      .string()
      .required(REQUIRED)
      .test("correctPrice", INCORRECT, (value?: string) => {
        if (value === undefined) return false;
        return parseInt(value?.replace(/\D/g, ""), 10) > 0;
      }),
    otherwise: yup.string().nullable().notRequired(),
  }),
  description: yup.string().notRequired(),
});

export const makeDefaultForEdit = (data: IShopProduct) => {
  return {
    category: data?.category,
    type: [data?.type],
    name: data?.name,
    price_type: data?.price_type,
    price: formatPrice(data?.price),
    description: data?.desc,
  };
};
