import { MTSStatus } from "components/mts-status/mts-status";
import { IUserResponse } from "interface/management";
import { FILTER_BY_ROLES } from "pages/managementUsers/const";
import { convertRoleToCyr } from "components/forms/user/validation-schema";
import { Expandable, Info } from "./subComponents";
import { UserDeskAction } from "components/card-info/users/user.component";

export const constructTableData = (draft: IUserResponse[]) =>
  draft?.map((d) => {
    const { tenant, isActive, roles } = d;
    const roleNames = roles.map((a) => a.name);

    return {
      ...d,
      status: isActive,
      info: tenant?.title,
      tenantId: tenant?.id,
      rolesCyrString: roleNames.map((a) => convertRoleToCyr(a)).join(", "),
      [FILTER_BY_ROLES]: roleNames,
    };
  });

interface IProp {
  columns: any[];
  active: { [key: string]: boolean };
}

export const constructColumns = (data: IProp) => {
  return data.columns.map((singleColumn) => {
    return {
      ...singleColumn,
      ...(singleColumn.accessor === "userName"
        ? {
            Cell: ({ value, row }) => (
              <Expandable value={value} row={row} isOpen={data.active[row.original.id] ?? false} />
            ),
          }
        : singleColumn.accessor === "status"
        ? { Cell: ({ value }) => (value ? MTSStatus("Активен") : null) }
        : singleColumn.accessor === "info"
        ? {
            Cell: ({ row }) => <Info rowData={row.original} />,
          }
        : singleColumn.accessor === "action"
        ? {
            Cell: ({ row }) => <UserDeskAction row={row} />,
          }
        : { Cell: ({ value }) => null }),
    };
  });
};
