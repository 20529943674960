import { EventMobileCard } from "components/card-info/events/event.component";
import { RenderFilter } from "components/table/render-filter/renderFilter.component";

function selectPeriod(rows, columnIds, filterValue) {
  if (filterValue.length === 0) return rows;
  else {
    const [from, to] = filterValue;
    let start = new Date(from);
    let end = new Date(to);

    return rows.filter((row) => {
      if (!from) {
        return new Date(row.original.eventDatetime) <= end;
      } else if (!to) {
        return new Date(row.original.eventDatetime) >= start;
      } else {
        return (
          new Date(row.original.eventDatetime) >= start &&
          new Date(row.original.eventDatetime) <= end
        );
      }
    });
  }
}

export const tableStructure = [
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Дата и время"
        withPadding
      />
    ),
    accessor: "eventDatetime",
    minWidth: 188,
    canFilter: true,
    filter: selectPeriod,
    sortType: "datetime",
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Событие"
        withPadding
      />
    ),
    accessor: "eventDesc",
    minWidth: 200,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Источник"
        withPadding
      />
    ),
    accessor: "userName",
    minWidth: 188,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Роль"
        withPadding
      />
    ),
    accessor: "roles",
    minWidth: 264,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Уровень"
        withPadding
      />
    ),
    accessor: "eventType",
    width: "100%",
  },
];

export const tableMobStructure = [
  {
    accessor: "userName",
    Row: EventMobileCard,
  },
  {
    accessor: "eventDesc",
  },
  {
    accessor: "eventDatetime",
    sortType: "datetime",
  },
  {
    accessor: "roles",
  },
  {
    accessor: "eventType",
  },
];
