import styled from "@emotion/styled";

export const SCard = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
  border-radius: 8px;
  margin-bottom: 16px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  text-align: left;

  & > article {
    & > ul {
      li {
        display: flex;
        justify-content: space-between;
        padding: 20px 16px;
        background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};

        &:nth-child(2n) {
          background-color: #f9f9fb;
        }

        &:last-of-type {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        & span:not(:first-of-type) {
          text-align: right;
        }

        & span:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }
`;

export const SContent = styled.div`
  flex: 1;
`;

export const SHead = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid ${(props) => props.theme.mtsColor.grey.greyLight};

  & > section {
    & > div:not(:last-child) {
      margin-bottom: 12px;

      & > .MuiTypography-root:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
`;

export const SHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  & > span {
    white-space: nowrap;
    line-height: 1;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
  }
`;

export const SBody = styled.div`
  & > ul {
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      white-space: nowrap;
      padding: 4px 0;

      &:not(:last-of-type) {
        border-bottom: 1px solid ${(props) => props.theme.mtsColor.background.secondary.lightMode};
      }

      & > span {
        &:first-of-type {
          text-align: left;
          width: 120px;
          flex: 0 0 120px;
          color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
        }

        &:last-of-type {
          flex: 1 1 auto;
        }

        &:not(:first-of-type) {
          white-space: normal;
          text-align: right;
        }
      }
    }
  }
`;

export const SDown = styled.div`
  white-space: nowrap;
  cursor: pointer;

  & > span {
    color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
  }
`;
