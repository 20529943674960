import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Selection = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 0H4.30828C4.30828 1.43578 4.30875 2.87203 4.30781 4.30781C2.87203 4.30875 1.43578 4.30828 0 4.30828V0Z"
        fill="black"
      />
      <path
        d="M19.692 0H24.0003V4.30828C22.564 4.30828 21.1283 4.30875 19.6925 4.30781C19.6915 2.87203 19.692 1.43625 19.692 0Z"
        fill="black"
      />
      <path
        d="M5.70703 1.40679C6.17344 1.40632 6.63984 1.40585 7.10625 1.40679C7.10578 1.87554 7.10625 2.34382 7.10625 2.8121C6.63984 2.81257 6.17344 2.81304 5.70703 2.8121C5.70703 2.34382 5.70703 1.87507 5.70703 1.40679Z"
        fill="black"
      />
      <path
        d="M8.50293 1.4078C8.96934 1.40499 9.43574 1.40686 9.90262 1.40686C9.90262 1.87514 9.90262 2.34389 9.90262 2.81217C9.43574 2.81217 8.96934 2.81405 8.50293 2.81124C8.50387 2.34342 8.50387 1.87561 8.50293 1.4078Z"
        fill="black"
      />
      <path
        d="M11.3013 1.4066C11.7672 1.40613 12.2331 1.40613 12.6991 1.4066C12.6991 1.87488 12.6991 2.34363 12.6991 2.81191C12.2331 2.81238 11.7672 2.81238 11.3013 2.81191C11.3013 2.34363 11.3013 1.87488 11.3013 1.4066Z"
        fill="black"
      />
      <path
        d="M14.0977 1.40686C14.5641 1.40686 15.0309 1.40499 15.4973 1.4078C15.4964 1.87561 15.4959 2.34342 15.4973 2.81124C15.0309 2.81405 14.5641 2.81217 14.0977 2.81217C14.0977 2.34389 14.0977 1.87514 14.0977 1.40686Z"
        fill="black"
      />
      <path
        d="M16.8945 1.40637C17.3604 1.40637 17.8268 1.4059 18.2932 1.40684C18.2932 1.87512 18.2932 2.34387 18.2932 2.81215C17.8264 2.81215 17.36 2.81402 16.8936 2.81168C16.895 2.3434 16.8936 1.87512 16.8945 1.40637Z"
        fill="black"
      />
      <path
        d="M1.45378 5.70724C1.92206 5.70677 2.39081 5.70724 2.85909 5.70724C2.85909 6.17365 2.86097 6.64052 2.85862 7.10693C2.39034 7.10599 1.92253 7.10599 1.45425 7.10693C1.45237 6.64052 1.45331 6.17365 1.45378 5.70724Z"
        fill="black"
      />
      <path
        d="M21.0965 5.70715C21.5652 5.70715 22.034 5.70668 22.5022 5.70762C22.5032 6.17355 22.5032 6.63996 22.5022 7.1059C22.034 7.10684 21.5657 7.10543 21.0974 7.10684C21.0951 6.64043 21.0969 6.17402 21.0965 5.70715Z"
        fill="black"
      />
      <path
        d="M1.45425 8.50293C1.92206 8.50387 2.39034 8.50434 2.85815 8.50293C2.86097 8.96934 2.85909 9.43574 2.85909 9.90262C2.39081 9.90262 1.92206 9.90262 1.45378 9.90262C1.45331 9.43621 1.45237 8.9698 1.45425 8.50293Z"
        fill="black"
      />
      <path
        d="M21.0973 8.50377C21.5655 8.5033 22.0338 8.5033 22.5026 8.50377C22.504 8.96971 22.503 9.43611 22.5026 9.90205C22.0343 9.90299 21.5655 9.90252 21.0968 9.90252C21.0968 9.43611 21.0963 8.96971 21.0973 8.50377Z"
        fill="black"
      />
      <path
        d="M11.2646 9.04318C11.7334 9.04225 12.2021 9.04225 12.6709 9.04318C12.6723 9.78428 12.669 10.5254 12.6723 11.2665C13.4129 11.2655 14.1536 11.2665 14.8946 11.266C14.8951 11.7347 14.8946 12.2035 14.8946 12.6722C14.1536 12.6732 13.4125 12.6718 12.6714 12.6727C12.6704 13.4138 12.6718 14.1549 12.6709 14.896C12.2021 14.8965 11.7334 14.8965 11.2646 14.896C11.2646 14.1554 11.2642 13.4143 11.2651 12.6737C10.524 12.6704 9.78293 12.6737 9.04137 12.6722C9.0409 12.2035 9.0409 11.7347 9.04137 11.2665C9.78246 11.2655 10.5236 11.2669 11.2642 11.2655C11.2651 10.5249 11.2642 9.78381 11.2646 9.04318Z"
        fill="black"
      />
      <path
        d="M1.45348 11.3013C1.92176 11.3013 2.39051 11.3013 2.85879 11.3013C2.85973 11.7672 2.85926 12.2331 2.85926 12.6991C2.39051 12.6991 1.92176 12.6991 1.45348 12.6991C1.45301 12.2331 1.45301 11.7672 1.45348 11.3013Z"
        fill="black"
      />
      <path
        d="M21.097 11.3009C21.5652 11.3009 22.034 11.3004 22.5023 11.3014C22.5032 11.7668 22.5032 12.2328 22.5023 12.6982C22.034 12.6992 21.5652 12.6987 21.0965 12.6987C21.096 12.2328 21.096 11.7668 21.097 11.3009Z"
        fill="black"
      />
      <path
        d="M1.45384 14.0977C1.92212 14.0977 2.39087 14.0977 2.85915 14.0977C2.85962 14.5641 2.85962 15.0305 2.85915 15.4969C2.39087 15.4969 1.92259 15.4959 1.4543 15.4973C1.45196 15.0309 1.45384 14.5641 1.45384 14.0977Z"
        fill="black"
      />
      <path
        d="M21.0974 14.0978C21.5657 14.0978 22.0344 14.0973 22.5027 14.0982C22.5032 14.5642 22.5036 15.0306 22.5027 15.4965C22.0339 15.497 21.5657 15.497 21.0974 15.4965C21.0964 15.0301 21.0964 14.5642 21.0974 14.0978Z"
        fill="black"
      />
      <path
        d="M1.4543 16.8936C1.92259 16.895 2.39087 16.894 2.85915 16.894C2.86009 17.3604 2.85962 17.8268 2.85915 18.2932C2.39087 18.2932 1.92212 18.2932 1.45384 18.2932C1.45384 17.8264 1.45196 17.36 1.4543 16.8936Z"
        fill="black"
      />
      <path
        d="M21.097 16.8944C21.5652 16.8939 22.0335 16.8939 22.5023 16.8944C22.5037 17.3603 22.5027 17.8267 22.5023 18.2927C22.034 18.2936 21.5652 18.2931 21.0965 18.2931C21.096 17.8267 21.096 17.3603 21.097 16.8944Z"
        fill="black"
      />
      <path
        d="M0 19.6915C1.43625 19.6915 2.87203 19.6911 4.30828 19.692C4.30875 21.1278 4.30828 22.564 4.30828 23.9998H0V19.6915Z"
        fill="black"
      />
      <path
        d="M19.6925 19.692C21.1283 19.6911 22.564 19.6915 24.0003 19.6915V23.9998H19.692C19.692 22.5636 19.6915 21.1278 19.6925 19.692Z"
        fill="black"
      />
      <path
        d="M5.70715 21.0987C6.17355 21.0982 6.63996 21.0977 7.1059 21.0987C7.10637 21.567 7.10637 22.0357 7.1059 22.504C6.63996 22.5054 6.17355 22.5049 5.70762 22.5045C5.70668 22.0357 5.70715 21.567 5.70715 21.0987Z"
        fill="black"
      />
      <path
        d="M8.50377 21.0987C8.96971 21.0977 9.43611 21.0982 9.90252 21.0987C9.90252 21.567 9.90299 22.0357 9.90205 22.5045C9.43611 22.5049 8.96971 22.5054 8.50377 22.504C8.5033 22.0352 8.5033 21.567 8.50377 21.0987Z"
        fill="black"
      />
      <path
        d="M11.3014 21.0987C11.7673 21.0977 12.2333 21.0982 12.6987 21.0987C12.6992 21.567 12.6992 22.0357 12.6987 22.5045C12.2333 22.5049 11.7673 22.5049 11.3019 22.5045C11.3009 22.0357 11.3014 21.567 11.3014 21.0987Z"
        fill="black"
      />
      <path
        d="M14.0978 21.0987C14.5642 21.0982 15.0301 21.0977 15.4965 21.0987C15.497 21.567 15.497 22.0352 15.4965 22.504C15.0306 22.5054 14.5642 22.5049 14.0982 22.5045C14.0973 22.0357 14.0978 21.567 14.0978 21.0987Z"
        fill="black"
      />
      <path
        d="M16.8944 21.0987C17.3603 21.0977 17.8267 21.0982 18.2927 21.0987C18.2931 21.567 18.2936 22.0357 18.2927 22.5045C17.8267 22.5049 17.3603 22.5054 16.8944 22.504C16.8939 22.0357 16.8939 21.567 16.8944 21.0987Z"
        fill="black"
      />
    </SvgIcon>
  );
};

export default Selection;
