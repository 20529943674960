import styled from "@emotion/styled";
import { device } from "theme";

export const HEADER_HEIGHT = 64;

export const SHeader = styled.header<{ isDark?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  padding-right: 20px;
  z-index: 20;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
  border-bottom: none;

  ${(props) =>
    props.isDark &&
    `
	background-color: ${props.theme.mtsColor.background.primaryElevated.darkMode};
	border-bottom: none;
  `};

  & > ul {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 16px;

    & .btn-consult {
      margin-right: 20px;
    }
  }

  @media ${device.desktopPlus} {
    padding-right: 40px;
    border-bottom: 1px solid ${(props) => props.theme.mtsColor.grey.greyLight};

    & > ul {
      margin: 0;
    }
  }
`;

export const SInfo = styled.div`
  position: absolute;
  top: 18px;
  left: 22px;
  display: flex;
  align-items: center;

  & a {
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  @media ${device.desktopPlus} {
    left: 40px;
  }
`;
