import { useState } from "react";
import { isEmpty } from "lodash";
import format from "date-fns/format";
import { ru } from "date-fns/locale";

import { useAskueReleLog } from "hooks/api/askue";
import { MTSModal } from "components/mts-modal/mts-modal";
import { PopupAction } from "components/popup-action/popup-action";
import { Spacer } from "components/spacer/spacer.component";
import Icon from "components/icons";
import { ModalNotifyBody } from "components/modal-notify-body/modal-notify-body";
import { MTSButton } from "components/mts-button/mts-button";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SHist, SInfo, SStack } from "./substyles";

type IProp = {
  id: string;
};
export const SelectAction: React.FC<IProp> = ({ id }) => {
  const [isOpenModal, setOpenModal] = useState(false);

  const { data, mutate, isError, error, isSuccess } = useAskueReleLog();

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const isOkEmpty = isEmpty(data) && isSuccess;

  return (
    <div className="popup-container">
      <PopupAction
        items={[
          {
            title: "История изменений статусов",
            onClick: () => {
              mutate({ sn: id });
              setOpenModal(true);
            },
          },
        ]}
        view="light"
      />
      <MTSModal open={isOpenModal} close={handleModalClose} size={isOkEmpty ? "S" : "M"}>
        {isOkEmpty ? (
          <SStack>
            <Icon.Warn sx={{ width: 44, height: 44 }} />
            <Spacer value="16px" />

            <CusTypo variant="h4_medium" font="comp" styles={{ display: "block" }}>
              История изменений статусов отсутствует
            </CusTypo>
            <Spacer value="8px" />

            <SInfo variant="p4_regular" font="comp">
              Счетчик №{id}
            </SInfo>
            <Spacer value="20px" />
            <MTSButton size="M" variant="secondary" onClick={handleModalClose}>
              Закрыть
            </MTSButton>
          </SStack>
        ) : !isEmpty(data) && isSuccess ? (
          <div>
            <CusTypo variant="h3_medium" font="comp" styles={{ display: "block" }}>
              История изменений статусов реле нагрузки
            </CusTypo>
            <Spacer value="8px" />

            <SInfo variant="p4_regular" font="comp">
              Счетчик №{id}
            </SInfo>
            <Spacer value="24px" />
            <SHist>
              {data?.map((a) => (
                <li key={a.datetime}>
                  <CusTypo variant="c1_medium">
                    {format(new Date(a.datetime), "dd.MM.yyyy HH:mm", {
                      locale: ru,
                    })}
                  </CusTypo>

                  <CusTypo variant="c1_regular">{a.event}</CusTypo>
                </li>
              ))}
            </SHist>
          </div>
        ) : isError ? (
          <ModalNotifyBody
            title="Ошибка при запросе истории"
            isError
            textDescrioption={error?.message ?? "Попробуйте повторить действие еще раз"}
          />
        ) : null}
      </MTSModal>
    </div>
  );
};
