import styled from "@emotion/styled";

export const STabs = styled.ul`
  display: flex;
`;
export const STabsItem = styled.li<{ active: boolean }>`
  margin-right: 32px;
  padding-bottom: 8px;
  border-bottom: 4px solid transparent;
  cursor: pointer;
  ${(props) => props.active && `border-bottom: 4px solid ${props.theme.mtsColor.red}`}
`;
