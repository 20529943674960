import React, { SyntheticEvent, useContext, useState } from "react";
import { Control, Controller } from "react-hook-form";
import cn from "classnames";

import { styled } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { InputErrorMessage } from "components/input-error-message";
import { InputLabel } from "components/input-label";
import Icon from "components/icons";
import { Spinner } from "components/spinner";
import { MTSCheckbox } from "components/mts-checkbox/mts-checkbox";
import { CustomWidthTooltip } from "components/tooltip/tooltip";
import { Spacer } from "components/spacer/spacer.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { ContextSettings } from "context/context-settings";
import { getTypographyVariant, SizeVariant } from "components/select/const";
import { renderTooltipByVal } from "./const";
import useStyles, { SDelIcon, SSelect } from "./styles";

interface ICustomTextFieldProps {
  variant?: "outlined";
  errorMessage?: string;
}

const CustomTextField = styled(TextField)<ICustomTextFieldProps>(({ theme }) => ({
  "& label.Mui-focused": {
    color: theme.mtsColor.background.primary.lightMode,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.mtsColor.text.primaryLink.lightMode,
  },
  "& .MuiOutlinedInput-root": {
    "& .MuiAutocomplete-input": {
      padding: "0 3px",
    },
  },
}));

interface IMTSAutocompleteProps {
  size?: "S" | "M" | "L";
  options: any;
  placeholder: string;
  label?: string;
  control: Control<any>;
  name: string;
  loading?: boolean;
  errorMessage?: string | undefined;
  disabled?: boolean;
  defaultValue?: any | undefined;
  variant?: "normal" | "checkbox";
  isCanDelete?: boolean;
  onDelete?: () => void;
}

export const MTSAutocomplete = React.forwardRef((props: IMTSAutocompleteProps, ref) => {
  const {
    size = SizeVariant.S,
    options = [],
    placeholder,
    label,
    control,
    name,
    loading = false,
    errorMessage,
    disabled = false,
    defaultValue,
    variant = "normal",
    isCanDelete = false,
    onDelete = () => {},
  } = props;

  const { isDesktop } = useContext(ContextSettings);
  const [inputValue, setInputValue] = useState<string>("");
  const classes = useStyles();
  const isMultiCheckbox = variant === "checkbox";

  const optionClasses = cn({
    [classes.option]: true,
    [classes.optionSizeS]: size === SizeVariant.S,
    [classes.optionSizeM]: size === SizeVariant.M,
    [classes.optionSizeL]: size === SizeVariant.L,
  });

  const rootClasses = cn({
    [classes.root]: true,
    [classes.rootError]: errorMessage ? true : false,
    [classes.fieldSizeS]: size === SizeVariant.S,
    [classes.fieldSizeM]: size === SizeVariant.M,
    [classes.fieldSizeL]: size === SizeVariant.L,
  });

  return (
    <div className={classes.wrapper}>
      {label ? (
        <>
          <InputLabel label={label} disabled={disabled} hasError={!!errorMessage} />
          <Spacer value="4px" />
        </>
      ) : null}
      <SSelect>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => {
            return (
              <Autocomplete
                disablePortal
                id="mts-autocomplete"
                classes={{
                  root: rootClasses,
                  noOptions: classes.noOption,
                  listbox: classes.listBox,
                  paper: classes.paper,
                }}
                ref={ref}
                clearText=""
                closeText=""
                openText=""
                autoHighlight={true}
                multiple={isMultiCheckbox}
                disabled={disabled}
                disableCloseOnSelect={isMultiCheckbox}
                openOnFocus={true}
                value={value ?? defaultValue}
                noOptionsText={
                  <CusTypo variant="p3_regular" className={classes.noOptionText}>
                    {loading ? <Spinner /> : "Ничего не найдено"}
                  </CusTypo>
                }
                options={options}
                onChange={(event: SyntheticEvent, newValue: any) => {
                  onChange(newValue || []);
                }}
                getOptionLabel={(option: any) => option}
                isOptionEqualToValue={(option: any, incomingValue: any): boolean => {
                  return String(option) === String(incomingValue);
                }}
                popupIcon={<Icon.ArrowDown className={classes.icon} />}
                clearIcon={<Icon.Close className={classes.icon} />}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => {
                  return (
                    <CustomTextField
                      placeholder={placeholder}
                      {...params}
                      onChange={(e) => (isMultiCheckbox ? undefined : onChange(e))}
                    />
                  );
                }}
                renderOption={(renderProps, option: any, { selected }) => {
                  if (options.length === 0) {
                    return null;
                  }

                  if (!isMultiCheckbox) {
                    return (
                      <li {...renderProps} className={optionClasses}>
                        <CusTypo
                          variant={getTypographyVariant(size)}
                          className={classes.optionText}
                        >
                          {option}
                        </CusTypo>
                      </li>
                    );
                  }

                  if (isMultiCheckbox) {
                    return (
                      <li {...renderProps} className={optionClasses}>
                        <div className={classes.checkBox}>
                          <MTSCheckbox checked={selected ? true : false} boxSize={"small"} />
                        </div>
                        <CusTypo
                          variant={getTypographyVariant(size)}
                          className={classes.optionText}
                        >
                          {option}
                          {renderTooltipByVal(option).length && isDesktop() ? (
                            <CustomWidthTooltip
                              title={
                                <div
                                  className={classes.tooltip}
                                  dangerouslySetInnerHTML={{ __html: renderTooltipByVal(option) }}
                                />
                              }
                              arrow
                              placement="right"
                            >
                              <span className={classes.optionIcon}>
                                <Icon.Info />
                              </span>
                            </CustomWidthTooltip>
                          ) : null}
                        </CusTypo>
                      </li>
                    );
                  }
                }}
              />
            );
          }}
        />
        {isCanDelete ? (
          <SDelIcon onClick={onDelete}>
            <Icon.Close className={classes.icon} sx={{ width: 20 }} />
          </SDelIcon>
        ) : null}
      </SSelect>
      {errorMessage ? <InputErrorMessage errorMessage={errorMessage} /> : null}
    </div>
  );
});
