import styled from "@emotion/styled";

export const SFormAddOrg = styled.form`
  margin-top: 21px;
`;

export const SFormField = styled.div`
  margin-bottom: 16px;
`;

export const SBtns = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;

  & .MuiButtonBase-root {
    width: 100%;
  }
`;
