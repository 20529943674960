import { toast } from "react-toastify";
import { Toast } from "components/toast/toast";

export const toastDelSuc = () =>
  toast(<Toast title="Список удален" />, {
    autoClose: 2000,
    hideProgressBar: false,
  });

export const toastDelFail = () =>
  toast(<Toast title={"Не удалось удалить"} isError />, {
    autoClose: 2000,
    hideProgressBar: false,
  });

export const toastSaveSuc = () =>
  toast(<Toast title="Список сохранен" />, {
    autoClose: 2000,
    hideProgressBar: false,
  });

export const toastSaveFail = () =>
  toast(<Toast title={"Ошибка при сохранении"} isError />, {
    autoClose: 2000,
    hideProgressBar: false,
  });
