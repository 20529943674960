import { ru } from "date-fns/locale";

const scaleYDefault = {
  display: true,
  title: {
    display: true,
    text: "Среднесуточное показание, кВт",
  },
};

const defaultOptions = {
  responsive: true,
  maintainAspectRatio: false,
  clip: false,
  hover: {
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};

const ticksByString = {
  seasons: 6,
  years: 1,
  months: 1,
  quarters: 1,
  days: 1,
  hours: 1,
  halfhours: 30,
};

const timeByString = {
  seasons: {
    unit: "month",
    displayFormats: {
      month: "LLLL yyyy",
    },
  },
  years: {
    unit: "year",
    displayFormats: {
      month: "yyyy",
    },
  },
  months: {
    unit: "month",
    displayFormats: {
      month: "LLLL yyyy",
    },
  },
  quarters: {
    unit: "quarter",
    displayFormats: {
      month: "MMM yyyy",
    },
  },
  days: {
    unit: "day",
    displayFormats: {
      day: "dd LLL yy",
    },
  },
  hours: {
    unit: "hour",
    displayFormats: {
      hour: "hh:mm dd LLL",
    },
  },
  halfhours: {
    unit: "minute",
    displayFormats: {
      minute: "hh:mm dd LLL",
    },
  },
};

export const makeOptions = (val) => {
  return {
    ...defaultOptions,
    scales: {
      x: {
        type: "time",
        time: timeByString[val],
        ticks: {
          stepSize: ticksByString[val],
          autoSkip: true,
          source: "data",
        },
        adapters: {
          date: {
            locale: ru,
          },
        },
      },
      y: scaleYDefault,
    },
  };
};
