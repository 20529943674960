import { useNavigate } from "react-router-dom";
import { useContext } from "react";

import { IEvent } from "interface/management";
import { ContextSettings } from "context/context-settings";
import { getEventItemUrl } from "./utils";

const linkStyles = {
  color: "goldenrod",
  fontWeight: 500,
  textDecoration: "underline",
  cursor: "pointer",
};

interface IProp {
  value: IEvent;
}
export const Anchor: React.FC<IProp> = ({ value }) => {
  const { isDesktop } = useContext(ContextSettings);
  const navigate = useNavigate();
  const { eventDesc, objectId, objectType, objectName } = value;

  const handleShit = () => {
    navigate(`/${getEventItemUrl(objectType, objectId, !isDesktop())}`, {
      state: { e_object_id: objectId },
    });
  };

  return objectType != null ? (
    <span>
      {eventDesc}{" "}
      <span onClick={handleShit} style={linkStyles}>
        {objectName}
      </span>
    </span>
  ) : (
    <span dangerouslySetInnerHTML={{ __html: eventDesc }} />
  );
};
