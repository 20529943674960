export const score_data = {
  isFailed: false,
  isModerating: false,
  results: [
    {
      id: 531133,
      sn: "9479484",
      address:
        "450069, Башкортостан Респ., Уфа г., Князевод., Центральная ул., дом №  62, квартира 1",
      score: "Высокая",
    },
    {
      id: 562222,
      sn: "9419480",
      address: "450104, Башкортостан Респ., Уфа г., Искры ул., дом №  19, квартира  2",
      score: "Средняя",
    },
    {
      id: 57000,
      sn: "9214480",
      address: "450109, Башкортостан Респ., Уфа г., Пегая ул., дом №  1, квартира  14",
      score: "Низкая",
    },
    {
      id: 1231231,
      sn: "13441",
      address: "450109, Башкортостан Респ., Уфа г., Пегая ул., дом №  1, квартира  14",
      score: "Фрод",
    },
  ],
};

export const chart_data = [
  {
    id: 719243,
    measuredate: "Fri, 01 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "0.00",
  },
  {
    id: 719243,
    measuredate: "Sun, 05 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.53",
  },
  {
    id: 719243,
    measuredate: "Mon, 06 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "7.30",
  },
  {
    id: 719243,
    measuredate: "Tue, 07 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "2.59",
  },
  {
    id: 719243,
    measuredate: "Wed, 08 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.97",
  },
  {
    id: 719243,
    measuredate: "Thu, 09 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.13",
  },
  {
    id: 719243,
    measuredate: "Fri, 10 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "6.74",
  },
  {
    id: 719243,
    measuredate: "Sat, 11 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.86",
  },
  {
    id: 719243,
    measuredate: "Sun, 12 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.75",
  },
  {
    id: 719243,
    measuredate: "Mon, 13 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "6.64",
  },
  {
    id: 719243,
    measuredate: "Tue, 14 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.27",
  },
  {
    id: 719243,
    measuredate: "Wed, 15 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.99",
  },
  {
    id: 719243,
    measuredate: "Thu, 16 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.98",
  },
  {
    id: 719243,
    measuredate: "Fri, 17 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.34",
  },
  {
    id: 719243,
    measuredate: "Sat, 18 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.11",
  },
  {
    id: 719243,
    measuredate: "Sun, 19 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "8.98",
  },
  {
    id: 719243,
    measuredate: "Mon, 20 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "6.12",
  },
  {
    id: 719243,
    measuredate: "Tue, 21 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.64",
  },
  {
    id: 719243,
    measuredate: "Wed, 22 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "6.00",
  },
  {
    id: 719243,
    measuredate: "Thu, 23 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.56",
  },
  {
    id: 719243,
    measuredate: "Fri, 24 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "2.85",
  },
  {
    id: 719243,
    measuredate: "Sat, 25 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.04",
  },
  {
    id: 719243,
    measuredate: "Sun, 26 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.96",
  },
  {
    id: 719243,
    measuredate: "Mon, 27 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "6.81",
  },
  {
    id: 719243,
    measuredate: "Tue, 28 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.99",
  },
  {
    id: 719243,
    measuredate: "Wed, 29 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.80",
  },
  {
    id: 719243,
    measuredate: "Thu, 30 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.51",
  },
  {
    id: 719243,
    measuredate: "Fri, 01 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "6.3",
  },
  {
    id: 719243,
    measuredate: "Sat, 02 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.42",
  },
  {
    id: 719243,
    measuredate: "Sun, 03 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.35",
  },
  {
    id: 719243,
    measuredate: "Mon, 04 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "2.59",
  },
  {
    id: 719243,
    measuredate: "Tue, 05 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.34",
  },
  {
    id: 719243,
    measuredate: "Wed, 06 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "6.43",
  },
  {
    id: 719243,
    measuredate: "Thu, 07 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.70",
  },
  {
    id: 719243,
    measuredate: "Fri, 08 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.70",
  },
  {
    id: 719243,
    measuredate: "Sat, 09 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.87",
  },
  {
    id: 719243,
    measuredate: "Sun, 10 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.63",
  },
  {
    id: 719243,
    measuredate: "Mon, 11 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.74",
  },
  {
    id: 719243,
    measuredate: "Tue, 12 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "9.72",
  },
  {
    id: 719243,
    measuredate: "Wed, 13 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "6.20",
  },
  {
    id: 719243,
    measuredate: "Thu, 14 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.85",
  },
  {
    id: 719243,
    measuredate: "Fri, 15 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.53",
  },
  {
    id: 719243,
    measuredate: "Sat, 16 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.13",
  },
  {
    id: 719243,
    measuredate: "Sun, 17 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "7.5",
  },
  {
    id: 719243,
    measuredate: "Mon, 18 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "6.35",
  },
  {
    id: 719243,
    measuredate: "Tue, 19 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.80",
  },
  {
    id: 719243,
    measuredate: "Wed, 20 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.26",
  },
  {
    id: 719243,
    measuredate: "Thu, 21 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.09",
  },
  {
    id: 719243,
    measuredate: "Fri, 22 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.47",
  },
  {
    id: 719243,
    measuredate: "Sat, 23 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.02",
  },
  {
    id: 719243,
    measuredate: "Sun, 24 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "7.45",
  },
  {
    id: 719243,
    measuredate: "Mon, 25 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "6.85",
  },
  {
    id: 719243,
    measuredate: "Tue, 26 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "6.61",
  },
  {
    id: 719243,
    measuredate: "Wed, 27 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.22",
  },
  {
    id: 719243,
    measuredate: "Thu, 28 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.90",
  },
  {
    id: 719243,
    measuredate: "Fri, 29 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "7.85",
  },
  {
    id: 719243,
    measuredate: "Sat, 30 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "10.18",
  },
  {
    id: 719243,
    measuredate: "Sun, 31 Oct 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.13",
  },
  {
    id: 719243,
    measuredate: "Mon, 01 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "6.33",
  },
  {
    id: 719243,
    measuredate: "Tue, 02 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "7.62",
  },
  {
    id: 719243,
    measuredate: "Wed, 03 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.49",
  },
  {
    id: 719243,
    measuredate: "Thu, 04 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "6.76",
  },
  {
    id: 719243,
    measuredate: "Fri, 05 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.36",
  },
  {
    id: 719243,
    measuredate: "Sat, 06 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.71",
  },
  {
    id: 719243,
    measuredate: "Sun, 07 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.99",
  },
  {
    id: 719243,
    measuredate: "Tue, 09 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "17.86",
  },
  {
    id: 719243,
    measuredate: "Wed, 10 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "9.19",
  },
  {
    id: 719243,
    measuredate: "Thu, 11 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "6.83",
  },
  {
    id: 719243,
    measuredate: "Fri, 12 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "8.05",
  },
  {
    id: 719243,
    measuredate: "Sat, 13 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.87",
  },
  {
    id: 719243,
    measuredate: "Sun, 14 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.07",
  },
  {
    id: 719243,
    measuredate: "Mon, 15 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.06",
  },
  {
    id: 719243,
    measuredate: "Tue, 16 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.80",
  },
  {
    id: 719243,
    measuredate: "Wed, 17 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.99",
  },
  {
    id: 719243,
    measuredate: "Thu, 18 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.82",
  },
  {
    id: 719243,
    measuredate: "Fri, 19 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "7.40",
  },
  {
    id: 719243,
    measuredate: "Mon, 22 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "18.80",
  },
  {
    id: 719243,
    measuredate: "Tue, 23 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.55",
  },
  {
    id: 719243,
    measuredate: "Wed, 24 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.15",
  },
  {
    id: 719243,
    measuredate: "Thu, 25 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.89",
  },
  {
    id: 719243,
    measuredate: "Fri, 26 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.32",
  },
  {
    id: 719243,
    measuredate: "Sat, 27 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.58",
  },
  {
    id: 719243,
    measuredate: "Sun, 28 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "7.32",
  },
  {
    id: 719243,
    measuredate: "Mon, 29 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "10.99",
  },
  {
    id: 719243,
    measuredate: "Tue, 30 Nov 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.24",
  },
  {
    id: 719243,
    measuredate: "Wed, 01 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.44",
  },
  {
    id: 719243,
    measuredate: "Thu, 02 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.52",
  },
  {
    id: 719243,
    measuredate: "Fri, 03 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "6.21",
  },
  {
    id: 719243,
    measuredate: "Sat, 04 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "7.49",
  },
  {
    id: 719243,
    measuredate: "Sun, 05 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "6.53",
  },
  {
    id: 719243,
    measuredate: "Mon, 06 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "6.89",
  },
  {
    id: 719243,
    measuredate: "Tue, 07 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.23",
  },
  {
    id: 719243,
    measuredate: "Wed, 08 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.48",
  },
  {
    id: 719243,
    measuredate: "Thu, 09 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.94",
  },
  {
    id: 719243,
    measuredate: "Fri, 10 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.58",
  },
  {
    id: 719243,
    measuredate: "Sat, 11 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.94",
  },
  {
    id: 719243,
    measuredate: "Sun, 12 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "6.72",
  },
  {
    id: 719243,
    measuredate: "Mon, 13 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "6.73",
  },
  {
    id: 719243,
    measuredate: "Tue, 14 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.93",
  },
  {
    id: 719243,
    measuredate: "Wed, 15 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.89",
  },
  {
    id: 719243,
    measuredate: "Thu, 16 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.06",
  },
  {
    id: 719243,
    measuredate: "Fri, 17 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.72",
  },
  {
    id: 719243,
    measuredate: "Sat, 18 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.98",
  },
  {
    id: 719243,
    measuredate: "Sun, 19 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "6.64",
  },
  {
    id: 719243,
    measuredate: "Mon, 20 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.03",
  },
  {
    id: 719243,
    measuredate: "Tue, 21 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.51",
  },
  {
    id: 719243,
    measuredate: "Wed, 22 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "6.30",
  },
  {
    id: 719243,
    measuredate: "Thu, 23 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "7.05",
  },
  {
    id: 719243,
    measuredate: "Fri, 24 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.09",
  },
  {
    id: 719243,
    measuredate: "Sat, 25 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.16",
  },
  {
    id: 719243,
    measuredate: "Sun, 26 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.32",
  },
  {
    id: 719243,
    measuredate: "Mon, 27 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "7.25",
  },
  {
    id: 719243,
    measuredate: "Tue, 28 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.93",
  },
  {
    id: 719243,
    measuredate: "Wed, 29 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.95",
  },
  {
    id: 719243,
    measuredate: "Thu, 30 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.66",
  },
  {
    id: 719243,
    measuredate: "Fri, 31 Dec 2021 01:00:00 GMT",
    n_rate: 0,
    val: "7.16",
  },
  {
    id: 719243,
    measuredate: "Fri, 01 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "0.00",
  },
  {
    id: 719243,
    measuredate: "Sat, 02 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.55",
  },
  {
    id: 719243,
    measuredate: "Sun, 03 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.29",
  },
  {
    id: 719243,
    measuredate: "Mon, 04 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.86",
  },
  {
    id: 719243,
    measuredate: "Tue, 05 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.95",
  },
  {
    id: 719243,
    measuredate: "Wed, 06 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "7.53",
  },
  {
    id: 719243,
    measuredate: "Thu, 07 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.74",
  },
  {
    id: 719243,
    measuredate: "Fri, 08 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.57",
  },
  {
    id: 719243,
    measuredate: "Sat, 09 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "5.08",
  },
  {
    id: 719243,
    measuredate: "Sun, 10 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.01",
  },
  {
    id: 719243,
    measuredate: "Mon, 11 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.72",
  },
  {
    id: 719243,
    measuredate: "Tue, 12 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.49",
  },
  {
    id: 719243,
    measuredate: "Wed, 13 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.29",
  },
  {
    id: 719243,
    measuredate: "Thu, 14 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.83",
  },
  {
    id: 719243,
    measuredate: "Fri, 15 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.91",
  },
  {
    id: 719243,
    measuredate: "Sat, 16 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "2.77",
  },
  {
    id: 719243,
    measuredate: "Sun, 17 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.28",
  },
  {
    id: 719243,
    measuredate: "Mon, 18 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.89",
  },
  {
    id: 719243,
    measuredate: "Tue, 19 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.73",
  },
  {
    id: 719243,
    measuredate: "Wed, 20 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "5.77",
  },
  {
    id: 719243,
    measuredate: "Thu, 21 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.87",
  },
  {
    id: 719243,
    measuredate: "Mon, 07 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.23",
  },
  {
    id: 719243,
    measuredate: "Tue, 08 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.05",
  },
  {
    id: 719243,
    measuredate: "Wed, 09 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.87",
  },
  {
    id: 719243,
    measuredate: "Thu, 10 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.65",
  },
  {
    id: 719243,
    measuredate: "Fri, 11 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.14",
  },
  {
    id: 719243,
    measuredate: "Sat, 12 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.76",
  },
  {
    id: 719243,
    measuredate: "Sun, 13 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "1.88",
  },
  {
    id: 719243,
    measuredate: "Mon, 14 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "1.79",
  },
  {
    id: 719243,
    measuredate: "Tue, 15 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.28",
  },
  {
    id: 719243,
    measuredate: "Wed, 16 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.15",
  },
  {
    id: 719243,
    measuredate: "Thu, 17 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "5.36",
  },
  {
    id: 719243,
    measuredate: "Fri, 18 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.91",
  },
  {
    id: 719243,
    measuredate: "Sat, 19 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.58",
  },
  {
    id: 719243,
    measuredate: "Sun, 20 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.52",
  },
  {
    id: 719243,
    measuredate: "Mon, 21 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "1.93",
  },
  {
    id: 719243,
    measuredate: "Tue, 22 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "5.84",
  },
  {
    id: 719243,
    measuredate: "Wed, 23 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.81",
  },
  {
    id: 719243,
    measuredate: "Thu, 24 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.54",
  },
  {
    id: 719243,
    measuredate: "Fri, 25 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.69",
  },
  {
    id: 719243,
    measuredate: "Sat, 26 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.53",
  },
  {
    id: 719243,
    measuredate: "Sun, 27 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.83",
  },
  {
    id: 719243,
    measuredate: "Mon, 28 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.11",
  },
  {
    id: 719243,
    measuredate: "Tue, 29 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.42",
  },
  {
    id: 719243,
    measuredate: "Wed, 30 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.57",
  },
  {
    id: 719243,
    measuredate: "Thu, 01 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "6.40",
  },
  {
    id: 719243,
    measuredate: "Fri, 02 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.71",
  },
  {
    id: 719243,
    measuredate: "Sat, 03 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.86",
  },
  {
    id: 719243,
    measuredate: "Sun, 04 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.40",
  },
  {
    id: 719243,
    measuredate: "Mon, 05 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.97",
  },
  {
    id: 719243,
    measuredate: "Tue, 06 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.16",
  },
  {
    id: 719243,
    measuredate: "Wed, 07 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.07",
  },
  {
    id: 719243,
    measuredate: "Thu, 08 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.32",
  },
  {
    id: 719243,
    measuredate: "Fri, 09 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.48",
  },
  {
    id: 719243,
    measuredate: "Sat, 10 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.33",
  },
  {
    id: 719243,
    measuredate: "Sun, 11 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.74",
  },
  {
    id: 719243,
    measuredate: "Mon, 12 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.88",
  },
  {
    id: 719243,
    measuredate: "Tue, 13 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.78",
  },
  {
    id: 719243,
    measuredate: "Wed, 14 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.43",
  },
  {
    id: 719243,
    measuredate: "Thu, 15 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.75",
  },
  {
    id: 719243,
    measuredate: "Fri, 16 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.18",
  },
  {
    id: 719243,
    measuredate: "Sat, 17 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.43",
  },
  {
    id: 719243,
    measuredate: "Sun, 18 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.72",
  },
  {
    id: 719243,
    measuredate: "Mon, 19 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.82",
  },
  {
    id: 719243,
    measuredate: "Wed, 21 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "6.06",
  },
  {
    id: 719243,
    measuredate: "Thu, 22 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.20",
  },
  {
    id: 719243,
    measuredate: "Fri, 23 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.25",
  },
  {
    id: 719243,
    measuredate: "Mon, 26 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "9.35",
  },
  {
    id: 719243,
    measuredate: "Tue, 27 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.28",
  },
  {
    id: 719243,
    measuredate: "Wed, 28 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.60",
  },
  {
    id: 719243,
    measuredate: "Thu, 29 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.57",
  },
  {
    id: 719243,
    measuredate: "Fri, 30 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "1.73",
  },
  {
    id: 719243,
    measuredate: "Sat, 31 Jul 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.00",
  },
  {
    id: 719243,
    measuredate: "Sun, 01 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.80",
  },
  {
    id: 719243,
    measuredate: "Mon, 02 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.91",
  },
  {
    id: 719243,
    measuredate: "Tue, 03 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.13",
  },
  {
    id: 719243,
    measuredate: "Wed, 04 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.77",
  },
  {
    id: 719243,
    measuredate: "Thu, 05 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.13",
  },
  {
    id: 719243,
    measuredate: "Fri, 06 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.96",
  },
  {
    id: 719243,
    measuredate: "Sat, 07 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.93",
  },
  {
    id: 719243,
    measuredate: "Sun, 08 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.10",
  },
  {
    id: 719243,
    measuredate: "Mon, 09 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.52",
  },
  {
    id: 719243,
    measuredate: "Tue, 10 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.63",
  },
  {
    id: 719243,
    measuredate: "Wed, 11 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.89",
  },
  {
    id: 719243,
    measuredate: "Thu, 12 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "1.89",
  },
  {
    id: 719243,
    measuredate: "Fri, 13 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.47",
  },
  {
    id: 719243,
    measuredate: "Sat, 14 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.03",
  },
  {
    id: 719243,
    measuredate: "Sun, 15 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.73",
  },
  {
    id: 719243,
    measuredate: "Mon, 16 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.83",
  },
  {
    id: 719243,
    measuredate: "Tue, 17 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.67",
  },
  {
    id: 719243,
    measuredate: "Wed, 18 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.21",
  },
  {
    id: 719243,
    measuredate: "Thu, 19 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.86",
  },
  {
    id: 719243,
    measuredate: "Fri, 20 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.15",
  },
  {
    id: 719243,
    measuredate: "Sat, 21 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.34",
  },
  {
    id: 719243,
    measuredate: "Sun, 22 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.64",
  },
  {
    id: 719243,
    measuredate: "Mon, 23 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.20",
  },
  {
    id: 719243,
    measuredate: "Tue, 24 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.65",
  },
  {
    id: 719243,
    measuredate: "Wed, 25 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.10",
  },
  {
    id: 719243,
    measuredate: "Thu, 26 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.25",
  },
  {
    id: 719243,
    measuredate: "Fri, 27 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.67",
  },
  {
    id: 719243,
    measuredate: "Sat, 28 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.46",
  },
  {
    id: 719243,
    measuredate: "Sun, 29 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.50",
  },
  {
    id: 719243,
    measuredate: "Mon, 30 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.65",
  },
  {
    id: 719243,
    measuredate: "Tue, 31 Aug 2021 01:00:00 GMT",
    n_rate: 1,
    val: "1.76",
  },
  {
    id: 719243,
    measuredate: "Wed, 01 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.52",
  },
  {
    id: 719243,
    measuredate: "Thu, 02 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.82",
  },
  {
    id: 719243,
    measuredate: "Fri, 03 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "1.89",
  },
  {
    id: 719243,
    measuredate: "Sat, 04 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.12",
  },
  {
    id: 719243,
    measuredate: "Sun, 05 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.68",
  },
  {
    id: 719243,
    measuredate: "Mon, 06 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "6.43",
  },
  {
    id: 719243,
    measuredate: "Tue, 07 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "1.65",
  },
  {
    id: 719243,
    measuredate: "Wed, 08 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.81",
  },
  {
    id: 719243,
    measuredate: "Thu, 09 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.13",
  },
  {
    id: 719243,
    measuredate: "Fri, 10 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "5.06",
  },
  {
    id: 719243,
    measuredate: "Sat, 11 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.85",
  },
  {
    id: 719243,
    measuredate: "Sun, 12 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.04",
  },
  {
    id: 719243,
    measuredate: "Mon, 13 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "5.12",
  },
  {
    id: 719243,
    measuredate: "Tue, 14 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.43",
  },
  {
    id: 719243,
    measuredate: "Wed, 15 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.45",
  },
  {
    id: 719243,
    measuredate: "Thu, 16 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.83",
  },
  {
    id: 719243,
    measuredate: "Fri, 17 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.44",
  },
  {
    id: 719243,
    measuredate: "Sat, 18 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.27",
  },
  {
    id: 719243,
    measuredate: "Sun, 19 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "6.71",
  },
  {
    id: 719243,
    measuredate: "Mon, 20 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.93",
  },
  {
    id: 719243,
    measuredate: "Tue, 21 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.12",
  },
  {
    id: 719243,
    measuredate: "Wed, 22 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.21",
  },
  {
    id: 719243,
    measuredate: "Thu, 23 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.07",
  },
  {
    id: 719243,
    measuredate: "Fri, 24 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "1.99",
  },
  {
    id: 719243,
    measuredate: "Sat, 25 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.79",
  },
  {
    id: 719243,
    measuredate: "Sun, 26 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.03",
  },
  {
    id: 719243,
    measuredate: "Mon, 27 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "5.09",
  },
  {
    id: 719243,
    measuredate: "Tue, 28 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.39",
  },
  {
    id: 719243,
    measuredate: "Wed, 29 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.81",
  },
  {
    id: 719243,
    measuredate: "Thu, 30 Sep 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.17",
  },
  {
    id: 719243,
    measuredate: "Fri, 01 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.86",
  },
  {
    id: 719243,
    measuredate: "Sat, 02 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.96",
  },
  {
    id: 719243,
    measuredate: "Sun, 03 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.07",
  },
  {
    id: 719243,
    measuredate: "Mon, 04 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "1.90",
  },
  {
    id: 719243,
    measuredate: "Tue, 05 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.98",
  },
  {
    id: 719243,
    measuredate: "Wed, 06 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.98",
  },
  {
    id: 719243,
    measuredate: "Thu, 07 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.72",
  },
  {
    id: 719243,
    measuredate: "Fri, 08 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.35",
  },
  {
    id: 719243,
    measuredate: "Sat, 09 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.48",
  },
  {
    id: 719243,
    measuredate: "Sun, 10 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.82",
  },
  {
    id: 719243,
    measuredate: "Mon, 11 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.50",
  },
  {
    id: 719243,
    measuredate: "Tue, 12 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "8.23",
  },
  {
    id: 719243,
    measuredate: "Wed, 13 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.64",
  },
  {
    id: 719243,
    measuredate: "Thu, 14 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.40",
  },
  {
    id: 719243,
    measuredate: "Fri, 15 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.96",
  },
  {
    id: 719243,
    measuredate: "Sat, 16 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.43",
  },
  {
    id: 719243,
    measuredate: "Sun, 17 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "5.89",
  },
  {
    id: 719243,
    measuredate: "Mon, 18 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.70",
  },
  {
    id: 719243,
    measuredate: "Tue, 19 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.21",
  },
  {
    id: 719243,
    measuredate: "Wed, 20 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.74",
  },
  {
    id: 719243,
    measuredate: "Thu, 21 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.66",
  },
  {
    id: 719243,
    measuredate: "Fri, 22 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.81",
  },
  {
    id: 719243,
    measuredate: "Sat, 23 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.36",
  },
  {
    id: 719243,
    measuredate: "Fri, 12 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.25",
  },
  {
    id: 719243,
    measuredate: "Sat, 13 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.46",
  },
  {
    id: 719243,
    measuredate: "Sun, 14 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.61",
  },
  {
    id: 719243,
    measuredate: "Mon, 15 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.84",
  },
  {
    id: 719243,
    measuredate: "Tue, 16 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.19",
  },
  {
    id: 719243,
    measuredate: "Wed, 17 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.19",
  },
  {
    id: 719243,
    measuredate: "Thu, 18 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.31",
  },
  {
    id: 719243,
    measuredate: "Fri, 19 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.25",
  },
  {
    id: 719243,
    measuredate: "Sat, 20 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.52",
  },
  {
    id: 719243,
    measuredate: "Sun, 21 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.64",
  },
  {
    id: 719243,
    measuredate: "Mon, 22 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.53",
  },
  {
    id: 719243,
    measuredate: "Tue, 23 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.40",
  },
  {
    id: 719243,
    measuredate: "Wed, 24 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.12",
  },
  {
    id: 719243,
    measuredate: "Thu, 25 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.26",
  },
  {
    id: 719243,
    measuredate: "Fri, 26 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.36",
  },
  {
    id: 719243,
    measuredate: "Sat, 27 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.36",
  },
  {
    id: 719243,
    measuredate: "Sun, 28 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.76",
  },
  {
    id: 719243,
    measuredate: "Mon, 29 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.67",
  },
  {
    id: 719243,
    measuredate: "Tue, 30 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.51",
  },
  {
    id: 719243,
    measuredate: "Wed, 31 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.61",
  },
  {
    id: 719243,
    measuredate: "Thu, 01 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.54",
  },
  {
    id: 719243,
    measuredate: "Fri, 02 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.73",
  },
  {
    id: 719243,
    measuredate: "Sat, 03 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "2.16",
  },
  {
    id: 719243,
    measuredate: "Sun, 04 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.64",
  },
  {
    id: 719243,
    measuredate: "Mon, 05 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.75",
  },
  {
    id: 719243,
    measuredate: "Tue, 06 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.31",
  },
  {
    id: 719243,
    measuredate: "Wed, 07 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.49",
  },
  {
    id: 719243,
    measuredate: "Thu, 08 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.42",
  },
  {
    id: 719243,
    measuredate: "Fri, 09 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.30",
  },
  {
    id: 719243,
    measuredate: "Sat, 10 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.41",
  },
  {
    id: 719243,
    measuredate: "Sun, 11 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.45",
  },
  {
    id: 719243,
    measuredate: "Mon, 12 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.69",
  },
  {
    id: 719243,
    measuredate: "Tue, 13 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.44",
  },
  {
    id: 719243,
    measuredate: "Wed, 14 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.06",
  },
  {
    id: 719243,
    measuredate: "Thu, 15 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.30",
  },
  {
    id: 719243,
    measuredate: "Fri, 16 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.22",
  },
  {
    id: 719243,
    measuredate: "Sat, 17 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "0.79",
  },
  {
    id: 719243,
    measuredate: "Sun, 18 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "0.99",
  },
  {
    id: 719243,
    measuredate: "Mon, 19 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.05",
  },
  {
    id: 719243,
    measuredate: "Tue, 20 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.61",
  },
  {
    id: 719243,
    measuredate: "Wed, 21 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "0.91",
  },
  {
    id: 719243,
    measuredate: "Thu, 22 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.47",
  },
  {
    id: 719243,
    measuredate: "Fri, 23 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.35",
  },
  {
    id: 719243,
    measuredate: "Sat, 24 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.46",
  },
  {
    id: 719243,
    measuredate: "Sun, 25 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "0.76",
  },
  {
    id: 719243,
    measuredate: "Mon, 26 Apr 2021 00:00:00 GMT",
    n_rate: 2,
    val: "0.82",
  },
  {
    id: 719243,
    measuredate: "Tue, 27 Apr 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.90",
  },
  {
    id: 719243,
    measuredate: "Wed, 28 Apr 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.03",
  },
  {
    id: 719243,
    measuredate: "Thu, 29 Apr 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.53",
  },
  {
    id: 719243,
    measuredate: "Fri, 30 Apr 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.50",
  },
  {
    id: 719243,
    measuredate: "Sat, 01 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.03",
  },
  {
    id: 719243,
    measuredate: "Sun, 02 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.90",
  },
  {
    id: 719243,
    measuredate: "Mon, 03 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.87",
  },
  {
    id: 719243,
    measuredate: "Tue, 04 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.82",
  },
  {
    id: 719243,
    measuredate: "Wed, 05 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.91",
  },
  {
    id: 719243,
    measuredate: "Thu, 06 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.06",
  },
  {
    id: 719243,
    measuredate: "Fri, 07 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.82",
  },
  {
    id: 719243,
    measuredate: "Sat, 08 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.74",
  },
  {
    id: 719243,
    measuredate: "Sun, 09 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.86",
  },
  {
    id: 719243,
    measuredate: "Mon, 10 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.00",
  },
  {
    id: 719243,
    measuredate: "Tue, 11 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.25",
  },
  {
    id: 719243,
    measuredate: "Wed, 12 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.97",
  },
  {
    id: 719243,
    measuredate: "Thu, 13 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.03",
  },
  {
    id: 719243,
    measuredate: "Fri, 14 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.07",
  },
  {
    id: 719243,
    measuredate: "Sat, 15 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.15",
  },
  {
    id: 719243,
    measuredate: "Sun, 16 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.89",
  },
  {
    id: 719243,
    measuredate: "Mon, 17 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.18",
  },
  {
    id: 719243,
    measuredate: "Tue, 18 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.98",
  },
  {
    id: 719243,
    measuredate: "Wed, 19 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.78",
  },
  {
    id: 719243,
    measuredate: "Thu, 20 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.94",
  },
  {
    id: 719243,
    measuredate: "Fri, 21 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.18",
  },
  {
    id: 719243,
    measuredate: "Sat, 22 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.20",
  },
  {
    id: 719243,
    measuredate: "Sun, 23 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.30",
  },
  {
    id: 719243,
    measuredate: "Mon, 24 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.40",
  },
  {
    id: 719243,
    measuredate: "Tue, 25 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.70",
  },
  {
    id: 719243,
    measuredate: "Wed, 26 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.10",
  },
  {
    id: 719243,
    measuredate: "Thu, 27 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.22",
  },
  {
    id: 719243,
    measuredate: "Fri, 28 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.89",
  },
  {
    id: 719243,
    measuredate: "Sat, 29 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.24",
  },
  {
    id: 719243,
    measuredate: "Sun, 30 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.56",
  },
  {
    id: 719243,
    measuredate: "Mon, 31 May 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.42",
  },
  {
    id: 719243,
    measuredate: "Tue, 01 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.23",
  },
  {
    id: 719243,
    measuredate: "Wed, 02 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.92",
  },
  {
    id: 719243,
    measuredate: "Thu, 03 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.83",
  },
  {
    id: 719243,
    measuredate: "Fri, 04 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.92",
  },
  {
    id: 719243,
    measuredate: "Sat, 05 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.12",
  },
  {
    id: 719243,
    measuredate: "Sun, 06 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.23",
  },
  {
    id: 719243,
    measuredate: "Mon, 07 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.12",
  },
  {
    id: 719243,
    measuredate: "Tue, 08 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.92",
  },
  {
    id: 719243,
    measuredate: "Wed, 09 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.02",
  },
  {
    id: 719243,
    measuredate: "Thu, 10 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.89",
  },
  {
    id: 719243,
    measuredate: "Fri, 11 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.23",
  },
  {
    id: 719243,
    measuredate: "Sat, 12 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.12",
  },
  {
    id: 719243,
    measuredate: "Sun, 13 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.76",
  },
  {
    id: 719243,
    measuredate: "Mon, 14 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.55",
  },
  {
    id: 719243,
    measuredate: "Tue, 15 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.81",
  },
  {
    id: 719243,
    measuredate: "Wed, 16 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.23",
  },
  {
    id: 719243,
    measuredate: "Thu, 17 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.91",
  },
  {
    id: 719243,
    measuredate: "Fri, 18 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.54",
  },
  {
    id: 719243,
    measuredate: "Sat, 19 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.31",
  },
  {
    id: 719243,
    measuredate: "Sun, 20 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.04",
  },
  {
    id: 719243,
    measuredate: "Mon, 21 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.29",
  },
  {
    id: 719243,
    measuredate: "Tue, 22 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.22",
  },
  {
    id: 719243,
    measuredate: "Wed, 23 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.01",
  },
  {
    id: 719243,
    measuredate: "Thu, 24 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.68",
  },
  {
    id: 719243,
    measuredate: "Fri, 25 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.71",
  },
  {
    id: 719243,
    measuredate: "Sat, 26 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.17",
  },
  {
    id: 719243,
    measuredate: "Sun, 27 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.87",
  },
  {
    id: 719243,
    measuredate: "Mon, 28 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.95",
  },
  {
    id: 719243,
    measuredate: "Tue, 29 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.46",
  },
  {
    id: 719243,
    measuredate: "Wed, 30 Jun 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.95",
  },
  {
    id: 719243,
    measuredate: "Thu, 01 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "2.06",
  },
  {
    id: 719243,
    measuredate: "Fri, 02 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.96",
  },
  {
    id: 719243,
    measuredate: "Sat, 03 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.69",
  },
  {
    id: 719243,
    measuredate: "Sun, 04 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.77",
  },
  {
    id: 719243,
    measuredate: "Mon, 05 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.69",
  },
  {
    id: 719243,
    measuredate: "Tue, 06 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.52",
  },
  {
    id: 719243,
    measuredate: "Wed, 07 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.15",
  },
  {
    id: 719243,
    measuredate: "Thu, 08 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.39",
  },
  {
    id: 719243,
    measuredate: "Fri, 09 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.52",
  },
  {
    id: 719243,
    measuredate: "Sat, 10 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.61",
  },
  {
    id: 719243,
    measuredate: "Sun, 11 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.66",
  },
  {
    id: 719243,
    measuredate: "Mon, 12 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.19",
  },
  {
    id: 719243,
    measuredate: "Tue, 13 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.29",
  },
  {
    id: 719243,
    measuredate: "Wed, 14 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.65",
  },
  {
    id: 719243,
    measuredate: "Thu, 15 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.14",
  },
  {
    id: 719243,
    measuredate: "Fri, 16 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.27",
  },
  {
    id: 719243,
    measuredate: "Sat, 17 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.40",
  },
  {
    id: 719243,
    measuredate: "Sun, 18 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.27",
  },
  {
    id: 719243,
    measuredate: "Mon, 19 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.26",
  },
  {
    id: 719243,
    measuredate: "Wed, 21 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "2.35",
  },
  {
    id: 719243,
    measuredate: "Thu, 22 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.45",
  },
  {
    id: 719243,
    measuredate: "Fri, 23 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.39",
  },
  {
    id: 719243,
    measuredate: "Mon, 26 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "4.68",
  },
  {
    id: 719243,
    measuredate: "Tue, 27 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.52",
  },
  {
    id: 719243,
    measuredate: "Wed, 28 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.37",
  },
  {
    id: 719243,
    measuredate: "Wed, 15 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.34",
  },
  {
    id: 719243,
    measuredate: "Thu, 16 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.42",
  },
  {
    id: 719243,
    measuredate: "Fri, 17 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.33",
  },
  {
    id: 719243,
    measuredate: "Sat, 18 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.37",
  },
  {
    id: 719243,
    measuredate: "Sun, 19 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.24",
  },
  {
    id: 719243,
    measuredate: "Mon, 20 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.60",
  },
  {
    id: 719243,
    measuredate: "Tue, 21 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.44",
  },
  {
    id: 719243,
    measuredate: "Wed, 22 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.38",
  },
  {
    id: 719243,
    measuredate: "Thu, 23 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.33",
  },
  {
    id: 719243,
    measuredate: "Fri, 24 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.50",
  },
  {
    id: 719243,
    measuredate: "Sat, 25 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.50",
  },
  {
    id: 719243,
    measuredate: "Sun, 26 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.39",
  },
  {
    id: 719243,
    measuredate: "Mon, 27 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.83",
  },
  {
    id: 719243,
    measuredate: "Tue, 28 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.51",
  },
  {
    id: 719243,
    measuredate: "Wed, 29 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.30",
  },
  {
    id: 719243,
    measuredate: "Thu, 30 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.33",
  },
  {
    id: 719243,
    measuredate: "Fri, 31 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.33",
  },
  {
    id: 719243,
    measuredate: "Mon, 19 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.56",
  },
  {
    id: 719243,
    measuredate: "Tue, 20 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.73",
  },
  {
    id: 719243,
    measuredate: "Wed, 21 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "3.33",
  },
  {
    id: 719243,
    measuredate: "Thu, 22 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "6.71",
  },
  {
    id: 719243,
    measuredate: "Fri, 23 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.38",
  },
  {
    id: 719243,
    measuredate: "Sat, 24 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.29",
  },
  {
    id: 719243,
    measuredate: "Sun, 25 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "3.11",
  },
  {
    id: 719243,
    measuredate: "Mon, 26 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.20",
  },
  {
    id: 719243,
    measuredate: "Tue, 27 Apr 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.45",
  },
  {
    id: 719243,
    measuredate: "Wed, 28 Apr 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.61",
  },
  {
    id: 719243,
    measuredate: "Thu, 29 Apr 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.64",
  },
  {
    id: 719243,
    measuredate: "Fri, 30 Apr 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.97",
  },
  {
    id: 719243,
    measuredate: "Sat, 01 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.07",
  },
  {
    id: 719243,
    measuredate: "Sun, 02 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.51",
  },
  {
    id: 719243,
    measuredate: "Mon, 03 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.35",
  },
  {
    id: 719243,
    measuredate: "Tue, 04 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.52",
  },
  {
    id: 719243,
    measuredate: "Wed, 05 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "6.76",
  },
  {
    id: 719243,
    measuredate: "Thu, 06 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.51",
  },
  {
    id: 719243,
    measuredate: "Fri, 07 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.25",
  },
  {
    id: 719243,
    measuredate: "Sat, 08 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.09",
  },
  {
    id: 719243,
    measuredate: "Sun, 09 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.74",
  },
  {
    id: 719243,
    measuredate: "Mon, 10 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.50",
  },
  {
    id: 719243,
    measuredate: "Tue, 11 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.95",
  },
  {
    id: 719243,
    measuredate: "Wed, 12 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.08",
  },
  {
    id: 719243,
    measuredate: "Thu, 13 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "2.89",
  },
  {
    id: 719243,
    measuredate: "Fri, 14 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.56",
  },
  {
    id: 719243,
    measuredate: "Sat, 15 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.07",
  },
  {
    id: 719243,
    measuredate: "Sun, 16 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.17",
  },
  {
    id: 719243,
    measuredate: "Mon, 17 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.68",
  },
  {
    id: 719243,
    measuredate: "Tue, 18 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.16",
  },
  {
    id: 719243,
    measuredate: "Wed, 19 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.49",
  },
  {
    id: 719243,
    measuredate: "Thu, 20 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.20",
  },
  {
    id: 719243,
    measuredate: "Fri, 21 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.88",
  },
  {
    id: 719243,
    measuredate: "Sat, 22 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.11",
  },
  {
    id: 719243,
    measuredate: "Sun, 23 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.03",
  },
  {
    id: 719243,
    measuredate: "Mon, 24 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.45",
  },
  {
    id: 719243,
    measuredate: "Tue, 25 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.82",
  },
  {
    id: 719243,
    measuredate: "Wed, 26 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "7.35",
  },
  {
    id: 719243,
    measuredate: "Thu, 27 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.55",
  },
  {
    id: 719243,
    measuredate: "Fri, 28 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.22",
  },
  {
    id: 719243,
    measuredate: "Sat, 29 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.23",
  },
  {
    id: 719243,
    measuredate: "Sun, 30 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.64",
  },
  {
    id: 719243,
    measuredate: "Mon, 31 May 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.64",
  },
  {
    id: 719243,
    measuredate: "Tue, 01 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "2.85",
  },
  {
    id: 719243,
    measuredate: "Wed, 02 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.71",
  },
  {
    id: 719243,
    measuredate: "Thu, 03 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.06",
  },
  {
    id: 719243,
    measuredate: "Fri, 04 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.22",
  },
  {
    id: 719243,
    measuredate: "Sat, 05 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.77",
  },
  {
    id: 719243,
    measuredate: "Sun, 06 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.67",
  },
  {
    id: 719243,
    measuredate: "Mon, 07 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.35",
  },
  {
    id: 719243,
    measuredate: "Tue, 08 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.97",
  },
  {
    id: 719243,
    measuredate: "Wed, 09 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.89",
  },
  {
    id: 719243,
    measuredate: "Thu, 10 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.54",
  },
  {
    id: 719243,
    measuredate: "Fri, 11 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.37",
  },
  {
    id: 719243,
    measuredate: "Sat, 12 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.88",
  },
  {
    id: 719243,
    measuredate: "Sun, 13 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "2.64",
  },
  {
    id: 719243,
    measuredate: "Mon, 14 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "2.34",
  },
  {
    id: 719243,
    measuredate: "Tue, 15 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.09",
  },
  {
    id: 719243,
    measuredate: "Wed, 16 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.38",
  },
  {
    id: 719243,
    measuredate: "Thu, 17 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "7.27",
  },
  {
    id: 719243,
    measuredate: "Fri, 18 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.45",
  },
  {
    id: 719243,
    measuredate: "Sat, 19 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.89",
  },
  {
    id: 719243,
    measuredate: "Sun, 20 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.56",
  },
  {
    id: 719243,
    measuredate: "Mon, 21 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.22",
  },
  {
    id: 719243,
    measuredate: "Tue, 22 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "7.06",
  },
  {
    id: 719243,
    measuredate: "Wed, 23 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.82",
  },
  {
    id: 719243,
    measuredate: "Thu, 24 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.22",
  },
  {
    id: 719243,
    measuredate: "Fri, 25 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.40",
  },
  {
    id: 719243,
    measuredate: "Sat, 26 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.70",
  },
  {
    id: 719243,
    measuredate: "Sun, 27 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.70",
  },
  {
    id: 719243,
    measuredate: "Mon, 28 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.06",
  },
  {
    id: 719243,
    measuredate: "Tue, 29 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.88",
  },
  {
    id: 719243,
    measuredate: "Wed, 30 Jun 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.52",
  },
  {
    id: 719243,
    measuredate: "Thu, 01 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "8.46",
  },
  {
    id: 719243,
    measuredate: "Fri, 02 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.67",
  },
  {
    id: 719243,
    measuredate: "Sat, 03 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.55",
  },
  {
    id: 719243,
    measuredate: "Sun, 04 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.17",
  },
  {
    id: 719243,
    measuredate: "Mon, 05 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.66",
  },
  {
    id: 719243,
    measuredate: "Tue, 06 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.68",
  },
  {
    id: 719243,
    measuredate: "Wed, 07 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.22",
  },
  {
    id: 719243,
    measuredate: "Thu, 08 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.71",
  },
  {
    id: 719243,
    measuredate: "Fri, 09 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.00",
  },
  {
    id: 719243,
    measuredate: "Sat, 10 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.94",
  },
  {
    id: 719243,
    measuredate: "Sun, 11 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.4",
  },
  {
    id: 719243,
    measuredate: "Mon, 12 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.07",
  },
  {
    id: 719243,
    measuredate: "Tue, 13 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.07",
  },
  {
    id: 719243,
    measuredate: "Wed, 14 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.08",
  },
  {
    id: 719243,
    measuredate: "Thu, 15 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.89",
  },
  {
    id: 719243,
    measuredate: "Fri, 16 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.45",
  },
  {
    id: 719243,
    measuredate: "Sat, 17 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.83",
  },
  {
    id: 719243,
    measuredate: "Sun, 18 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.99",
  },
  {
    id: 719243,
    measuredate: "Mon, 19 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.08",
  },
  {
    id: 719243,
    measuredate: "Wed, 21 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "8.41",
  },
  {
    id: 719243,
    measuredate: "Thu, 22 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.65",
  },
  {
    id: 719243,
    measuredate: "Fri, 23 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.64",
  },
  {
    id: 719243,
    measuredate: "Mon, 26 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "14.03",
  },
  {
    id: 719243,
    measuredate: "Tue, 27 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.80",
  },
  {
    id: 719243,
    measuredate: "Wed, 28 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.97",
  },
  {
    id: 719243,
    measuredate: "Thu, 29 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.72",
  },
  {
    id: 719243,
    measuredate: "Fri, 30 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "2.88",
  },
  {
    id: 719243,
    measuredate: "Sat, 31 Jul 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.41",
  },
  {
    id: 719243,
    measuredate: "Sun, 01 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.29",
  },
  {
    id: 719243,
    measuredate: "Mon, 02 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "6.14",
  },
  {
    id: 719243,
    measuredate: "Tue, 03 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.24",
  },
  {
    id: 719243,
    measuredate: "Wed, 04 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.00",
  },
  {
    id: 719243,
    measuredate: "Thu, 05 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.25",
  },
  {
    id: 719243,
    measuredate: "Fri, 06 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.07",
  },
  {
    id: 719243,
    measuredate: "Sat, 07 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.96",
  },
  {
    id: 719243,
    measuredate: "Sun, 08 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.33",
  },
  {
    id: 719243,
    measuredate: "Mon, 09 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.60",
  },
  {
    id: 719243,
    measuredate: "Tue, 10 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.63",
  },
  {
    id: 719243,
    measuredate: "Wed, 11 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.06",
  },
  {
    id: 719243,
    measuredate: "Thu, 12 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.07",
  },
  {
    id: 719243,
    measuredate: "Fri, 13 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.52",
  },
  {
    id: 719243,
    measuredate: "Sat, 14 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.14",
  },
  {
    id: 719243,
    measuredate: "Sun, 15 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "5.85",
  },
  {
    id: 719243,
    measuredate: "Mon, 16 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.08",
  },
  {
    id: 719243,
    measuredate: "Tue, 17 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.7",
  },
  {
    id: 719243,
    measuredate: "Wed, 18 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.6",
  },
  {
    id: 719243,
    measuredate: "Thu, 19 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.9",
  },
  {
    id: 719243,
    measuredate: "Fri, 20 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.48",
  },
  {
    id: 719243,
    measuredate: "Sat, 21 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.56",
  },
  {
    id: 719243,
    measuredate: "Sun, 22 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.90",
  },
  {
    id: 719243,
    measuredate: "Mon, 23 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.49",
  },
  {
    id: 719243,
    measuredate: "Tue, 24 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.84",
  },
  {
    id: 719243,
    measuredate: "Wed, 25 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.10",
  },
  {
    id: 719243,
    measuredate: "Thu, 26 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.56",
  },
  {
    id: 719243,
    measuredate: "Fri, 27 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.91",
  },
  {
    id: 719243,
    measuredate: "Sat, 28 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.68",
  },
  {
    id: 719243,
    measuredate: "Sun, 29 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.72",
  },
  {
    id: 719243,
    measuredate: "Mon, 30 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.86",
  },
  {
    id: 719243,
    measuredate: "Tue, 31 Aug 2021 01:00:00 GMT",
    n_rate: 0,
    val: "2.7",
  },
  {
    id: 719243,
    measuredate: "Wed, 01 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.68",
  },
  {
    id: 719243,
    measuredate: "Thu, 02 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "4.31",
  },
  {
    id: 719243,
    measuredate: "Fri, 03 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "2.88",
  },
  {
    id: 719243,
    measuredate: "Sat, 04 Sep 2021 01:00:00 GMT",
    n_rate: 0,
    val: "3.07",
  },
  {
    id: 719243,
    measuredate: "Fri, 22 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.29",
  },
  {
    id: 719243,
    measuredate: "Sat, 23 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "5.46",
  },
  {
    id: 719243,
    measuredate: "Sun, 24 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.85",
  },
  {
    id: 719243,
    measuredate: "Mon, 25 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.88",
  },
  {
    id: 719243,
    measuredate: "Tue, 26 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.77",
  },
  {
    id: 719243,
    measuredate: "Wed, 27 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.24",
  },
  {
    id: 719243,
    measuredate: "Thu, 28 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.01",
  },
  {
    id: 719243,
    measuredate: "Fri, 29 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.97",
  },
  {
    id: 719243,
    measuredate: "Sat, 30 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.38",
  },
  {
    id: 719243,
    measuredate: "Sun, 31 Jan 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.71",
  },
  {
    id: 719243,
    measuredate: "Mon, 01 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.53",
  },
  {
    id: 719243,
    measuredate: "Tue, 02 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.96",
  },
  {
    id: 719243,
    measuredate: "Wed, 03 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.25",
  },
  {
    id: 719243,
    measuredate: "Thu, 04 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.37",
  },
  {
    id: 719243,
    measuredate: "Fri, 05 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "5.36",
  },
  {
    id: 719243,
    measuredate: "Sat, 06 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.36",
  },
  {
    id: 719243,
    measuredate: "Sun, 07 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "5.76",
  },
  {
    id: 719243,
    measuredate: "Mon, 08 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.71",
  },
  {
    id: 719243,
    measuredate: "Tue, 09 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "2.82",
  },
  {
    id: 719243,
    measuredate: "Wed, 10 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.83",
  },
  {
    id: 719243,
    measuredate: "Thu, 11 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.05",
  },
  {
    id: 719243,
    measuredate: "Fri, 12 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.08",
  },
  {
    id: 719243,
    measuredate: "Sat, 13 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.89",
  },
  {
    id: 719243,
    measuredate: "Sun, 14 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.02",
  },
  {
    id: 719243,
    measuredate: "Mon, 15 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "7.22",
  },
  {
    id: 719243,
    measuredate: "Tue, 16 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.63",
  },
  {
    id: 719243,
    measuredate: "Wed, 17 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.19",
  },
  {
    id: 719243,
    measuredate: "Thu, 18 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "2.56",
  },
  {
    id: 719243,
    measuredate: "Fri, 19 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.55",
  },
  {
    id: 719243,
    measuredate: "Sat, 20 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.74",
  },
  {
    id: 719243,
    measuredate: "Sun, 21 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.64",
  },
  {
    id: 719243,
    measuredate: "Mon, 22 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.58",
  },
  {
    id: 719243,
    measuredate: "Tue, 23 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.67",
  },
  {
    id: 719243,
    measuredate: "Wed, 24 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.88",
  },
  {
    id: 719243,
    measuredate: "Thu, 25 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.12",
  },
  {
    id: 719243,
    measuredate: "Fri, 26 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.31",
  },
  {
    id: 719243,
    measuredate: "Sat, 27 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.29",
  },
  {
    id: 719243,
    measuredate: "Sun, 28 Feb 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.39",
  },
  {
    id: 719243,
    measuredate: "Mon, 01 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "5.13",
  },
  {
    id: 719243,
    measuredate: "Tue, 02 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.15",
  },
  {
    id: 719243,
    measuredate: "Wed, 03 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "2.92",
  },
  {
    id: 719243,
    measuredate: "Thu, 04 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.77",
  },
  {
    id: 719243,
    measuredate: "Fri, 05 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.66",
  },
  {
    id: 719243,
    measuredate: "Sat, 06 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "2.64",
  },
  {
    id: 719243,
    measuredate: "Sun, 07 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.73",
  },
  {
    id: 719243,
    measuredate: "Mon, 08 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.42",
  },
  {
    id: 719243,
    measuredate: "Tue, 09 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.96",
  },
  {
    id: 719243,
    measuredate: "Wed, 10 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.31",
  },
  {
    id: 719243,
    measuredate: "Thu, 11 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "2.69",
  },
  {
    id: 719243,
    measuredate: "Fri, 12 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.64",
  },
  {
    id: 719243,
    measuredate: "Sat, 13 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.56",
  },
  {
    id: 719243,
    measuredate: "Sun, 14 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "6.37",
  },
  {
    id: 719243,
    measuredate: "Mon, 15 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.01",
  },
  {
    id: 719243,
    measuredate: "Tue, 16 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.28",
  },
  {
    id: 719243,
    measuredate: "Wed, 17 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.01",
  },
  {
    id: 719243,
    measuredate: "Thu, 18 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.00",
  },
  {
    id: 719243,
    measuredate: "Fri, 19 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "5.06",
  },
  {
    id: 719243,
    measuredate: "Sat, 20 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.57",
  },
  {
    id: 719243,
    measuredate: "Sun, 21 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.23",
  },
  {
    id: 719243,
    measuredate: "Mon, 22 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.98",
  },
  {
    id: 719243,
    measuredate: "Tue, 23 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.34",
  },
  {
    id: 719243,
    measuredate: "Wed, 24 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.15",
  },
  {
    id: 719243,
    measuredate: "Thu, 25 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.84",
  },
  {
    id: 719243,
    measuredate: "Fri, 26 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.40",
  },
  {
    id: 719243,
    measuredate: "Sat, 27 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.62",
  },
  {
    id: 719243,
    measuredate: "Sun, 28 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.50",
  },
  {
    id: 719243,
    measuredate: "Mon, 29 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "5.56",
  },
  {
    id: 719243,
    measuredate: "Tue, 30 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.56",
  },
  {
    id: 719243,
    measuredate: "Wed, 31 Mar 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.82",
  },
  {
    id: 719243,
    measuredate: "Thu, 01 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.11",
  },
  {
    id: 719243,
    measuredate: "Fri, 02 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "2.98",
  },
  {
    id: 719243,
    measuredate: "Sat, 03 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.97",
  },
  {
    id: 719243,
    measuredate: "Sun, 04 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.10",
  },
  {
    id: 719243,
    measuredate: "Mon, 05 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "5.78",
  },
  {
    id: 719243,
    measuredate: "Tue, 06 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.40",
  },
  {
    id: 719243,
    measuredate: "Wed, 07 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.21",
  },
  {
    id: 719243,
    measuredate: "Thu, 08 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.46",
  },
  {
    id: 719243,
    measuredate: "Fri, 09 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.92",
  },
  {
    id: 719243,
    measuredate: "Sat, 10 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "5.65",
  },
  {
    id: 719243,
    measuredate: "Sun, 11 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.59",
  },
  {
    id: 719243,
    measuredate: "Mon, 12 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "6.65",
  },
  {
    id: 719243,
    measuredate: "Tue, 13 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.43",
  },
  {
    id: 719243,
    measuredate: "Wed, 14 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.23",
  },
  {
    id: 719243,
    measuredate: "Thu, 15 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.28",
  },
  {
    id: 719243,
    measuredate: "Fri, 16 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "2.71",
  },
  {
    id: 719243,
    measuredate: "Sat, 17 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "2.93",
  },
  {
    id: 719243,
    measuredate: "Sun, 18 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "2.10",
  },
  {
    id: 719243,
    measuredate: "Mon, 19 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.51",
  },
  {
    id: 719243,
    measuredate: "Tue, 20 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.12",
  },
  {
    id: 719243,
    measuredate: "Wed, 21 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "2.42",
  },
  {
    id: 719243,
    measuredate: "Thu, 22 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "5.24",
  },
  {
    id: 719243,
    measuredate: "Fri, 23 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "4.03",
  },
  {
    id: 719243,
    measuredate: "Sat, 24 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "2.83",
  },
  {
    id: 719243,
    measuredate: "Sun, 25 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "2.35",
  },
  {
    id: 719243,
    measuredate: "Mon, 26 Apr 2021 00:00:00 GMT",
    n_rate: 1,
    val: "3.38",
  },
  {
    id: 719243,
    measuredate: "Tue, 27 Apr 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.55",
  },
  {
    id: 719243,
    measuredate: "Wed, 28 Apr 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.58",
  },
  {
    id: 719243,
    measuredate: "Thu, 29 Apr 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.11",
  },
  {
    id: 719243,
    measuredate: "Fri, 30 Apr 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.47",
  },
  {
    id: 719243,
    measuredate: "Sat, 01 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.04",
  },
  {
    id: 719243,
    measuredate: "Sun, 02 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.61",
  },
  {
    id: 719243,
    measuredate: "Mon, 03 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.48",
  },
  {
    id: 719243,
    measuredate: "Tue, 04 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.7",
  },
  {
    id: 719243,
    measuredate: "Wed, 05 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "5.85",
  },
  {
    id: 719243,
    measuredate: "Thu, 06 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.45",
  },
  {
    id: 719243,
    measuredate: "Fri, 07 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.43",
  },
  {
    id: 719243,
    measuredate: "Sat, 08 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.35",
  },
  {
    id: 719243,
    measuredate: "Sun, 09 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.88",
  },
  {
    id: 719243,
    measuredate: "Mon, 10 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.50",
  },
  {
    id: 719243,
    measuredate: "Tue, 11 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.70",
  },
  {
    id: 719243,
    measuredate: "Wed, 12 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.11",
  },
  {
    id: 719243,
    measuredate: "Thu, 13 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "1.86",
  },
  {
    id: 719243,
    measuredate: "Fri, 14 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.49",
  },
  {
    id: 719243,
    measuredate: "Sat, 15 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.92",
  },
  {
    id: 719243,
    measuredate: "Sun, 16 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.28",
  },
  {
    id: 719243,
    measuredate: "Mon, 17 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.50",
  },
  {
    id: 719243,
    measuredate: "Tue, 18 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.18",
  },
  {
    id: 719243,
    measuredate: "Wed, 19 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.71",
  },
  {
    id: 719243,
    measuredate: "Thu, 20 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.26",
  },
  {
    id: 719243,
    measuredate: "Fri, 21 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.70",
  },
  {
    id: 719243,
    measuredate: "Sat, 22 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "1.91",
  },
  {
    id: 719243,
    measuredate: "Sun, 23 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.73",
  },
  {
    id: 719243,
    measuredate: "Mon, 24 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.05",
  },
  {
    id: 719243,
    measuredate: "Tue, 25 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.12",
  },
  {
    id: 719243,
    measuredate: "Wed, 26 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "6.25",
  },
  {
    id: 719243,
    measuredate: "Thu, 27 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.33",
  },
  {
    id: 719243,
    measuredate: "Fri, 28 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.33",
  },
  {
    id: 719243,
    measuredate: "Sat, 29 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.99",
  },
  {
    id: 719243,
    measuredate: "Sun, 30 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.08",
  },
  {
    id: 719243,
    measuredate: "Mon, 31 May 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.22",
  },
  {
    id: 719243,
    measuredate: "Tue, 01 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "1.62",
  },
  {
    id: 719243,
    measuredate: "Wed, 02 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.79",
  },
  {
    id: 719243,
    measuredate: "Thu, 03 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.23",
  },
  {
    id: 719243,
    measuredate: "Fri, 04 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.30",
  },
  {
    id: 719243,
    measuredate: "Sat, 05 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.65",
  },
  {
    id: 719243,
    measuredate: "Sun, 06 Jun 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.44",
  },
  {
    id: 719243,
    measuredate: "Sun, 24 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "6.05",
  },
  {
    id: 719243,
    measuredate: "Mon, 25 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "5.08",
  },
  {
    id: 719243,
    measuredate: "Tue, 26 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.85",
  },
  {
    id: 719243,
    measuredate: "Wed, 27 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.77",
  },
  {
    id: 719243,
    measuredate: "Thu, 28 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.42",
  },
  {
    id: 719243,
    measuredate: "Fri, 29 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "5.95",
  },
  {
    id: 719243,
    measuredate: "Sat, 30 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "7.97",
  },
  {
    id: 719243,
    measuredate: "Sun, 31 Oct 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.58",
  },
  {
    id: 719243,
    measuredate: "Mon, 01 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "5.06",
  },
  {
    id: 719243,
    measuredate: "Tue, 02 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "5.99",
  },
  {
    id: 719243,
    measuredate: "Wed, 03 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.99",
  },
  {
    id: 719243,
    measuredate: "Thu, 04 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "5.21",
  },
  {
    id: 719243,
    measuredate: "Fri, 05 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.86",
  },
  {
    id: 719243,
    measuredate: "Sat, 06 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.51",
  },
  {
    id: 719243,
    measuredate: "Sun, 07 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.69",
  },
  {
    id: 719243,
    measuredate: "Tue, 09 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "14.44",
  },
  {
    id: 719243,
    measuredate: "Wed, 10 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "7.41",
  },
  {
    id: 719243,
    measuredate: "Thu, 11 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "5.16",
  },
  {
    id: 719243,
    measuredate: "Fri, 12 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "6.50",
  },
  {
    id: 719243,
    measuredate: "Sat, 13 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.20",
  },
  {
    id: 719243,
    measuredate: "Sun, 14 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.67",
  },
  {
    id: 719243,
    measuredate: "Mon, 15 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.83",
  },
  {
    id: 719243,
    measuredate: "Tue, 16 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.34",
  },
  {
    id: 719243,
    measuredate: "Wed, 17 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.23",
  },
  {
    id: 719243,
    measuredate: "Thu, 18 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.14",
  },
  {
    id: 719243,
    measuredate: "Fri, 19 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "5.66",
  },
  {
    id: 719243,
    measuredate: "Mon, 22 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "14.40",
  },
  {
    id: 719243,
    measuredate: "Tue, 23 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.92",
  },
  {
    id: 719243,
    measuredate: "Wed, 24 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.48",
  },
  {
    id: 719243,
    measuredate: "Thu, 25 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.48",
  },
  {
    id: 719243,
    measuredate: "Fri, 26 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "2.82",
  },
  {
    id: 719243,
    measuredate: "Sat, 27 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.05",
  },
  {
    id: 719243,
    measuredate: "Sun, 28 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "5.77",
  },
  {
    id: 719243,
    measuredate: "Mon, 29 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "8.91",
  },
  {
    id: 719243,
    measuredate: "Tue, 30 Nov 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.71",
  },
  {
    id: 719243,
    measuredate: "Wed, 01 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.91",
  },
  {
    id: 719243,
    measuredate: "Thu, 02 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.96",
  },
  {
    id: 719243,
    measuredate: "Fri, 03 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.58",
  },
  {
    id: 719243,
    measuredate: "Sat, 04 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "5.78",
  },
  {
    id: 719243,
    measuredate: "Sun, 05 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.99",
  },
  {
    id: 719243,
    measuredate: "Mon, 06 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "5.27",
  },
  {
    id: 719243,
    measuredate: "Tue, 07 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.57",
  },
  {
    id: 719243,
    measuredate: "Wed, 08 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.05",
  },
  {
    id: 719243,
    measuredate: "Thu, 09 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.47",
  },
  {
    id: 719243,
    measuredate: "Fri, 10 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.10",
  },
  {
    id: 719243,
    measuredate: "Sat, 11 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.43",
  },
  {
    id: 719243,
    measuredate: "Sun, 12 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "5.26",
  },
  {
    id: 719243,
    measuredate: "Mon, 13 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "5.03",
  },
  {
    id: 719243,
    measuredate: "Tue, 14 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.22",
  },
  {
    id: 719243,
    measuredate: "Wed, 15 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.55",
  },
  {
    id: 719243,
    measuredate: "Thu, 16 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.64",
  },
  {
    id: 719243,
    measuredate: "Fri, 17 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.39",
  },
  {
    id: 719243,
    measuredate: "Sat, 18 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.61",
  },
  {
    id: 719243,
    measuredate: "Sun, 19 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "5.40",
  },
  {
    id: 719243,
    measuredate: "Mon, 20 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.43",
  },
  {
    id: 719243,
    measuredate: "Tue, 21 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.07",
  },
  {
    id: 719243,
    measuredate: "Wed, 22 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "4.92",
  },
  {
    id: 719243,
    measuredate: "Thu, 23 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "5.72",
  },
  {
    id: 719243,
    measuredate: "Fri, 24 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.59",
  },
  {
    id: 719243,
    measuredate: "Sat, 25 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.66",
  },
  {
    id: 719243,
    measuredate: "Sun, 26 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.93",
  },
  {
    id: 719243,
    measuredate: "Mon, 27 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "5.42",
  },
  {
    id: 719243,
    measuredate: "Tue, 28 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.42",
  },
  {
    id: 719243,
    measuredate: "Wed, 29 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.65",
  },
  {
    id: 719243,
    measuredate: "Thu, 30 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "3.33",
  },
  {
    id: 719243,
    measuredate: "Fri, 31 Dec 2021 01:00:00 GMT",
    n_rate: 1,
    val: "5.83",
  },
  {
    id: 719243,
    measuredate: "Fri, 01 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "0.00",
  },
  {
    id: 719243,
    measuredate: "Sat, 02 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.84",
  },
  {
    id: 719243,
    measuredate: "Sun, 03 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.55",
  },
  {
    id: 719243,
    measuredate: "Mon, 04 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.34",
  },
  {
    id: 719243,
    measuredate: "Tue, 05 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.78",
  },
  {
    id: 719243,
    measuredate: "Wed, 06 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.37",
  },
  {
    id: 719243,
    measuredate: "Thu, 07 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.57",
  },
  {
    id: 719243,
    measuredate: "Fri, 08 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.42",
  },
  {
    id: 719243,
    measuredate: "Sat, 09 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.75",
  },
  {
    id: 719243,
    measuredate: "Sun, 10 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.53",
  },
  {
    id: 719243,
    measuredate: "Mon, 11 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.70",
  },
  {
    id: 719243,
    measuredate: "Tue, 12 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.28",
  },
  {
    id: 719243,
    measuredate: "Wed, 13 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.51",
  },
  {
    id: 719243,
    measuredate: "Thu, 14 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.54",
  },
  {
    id: 719243,
    measuredate: "Fri, 15 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.42",
  },
  {
    id: 719243,
    measuredate: "Sat, 16 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.60",
  },
  {
    id: 719243,
    measuredate: "Sun, 17 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.57",
  },
  {
    id: 719243,
    measuredate: "Mon, 18 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.24",
  },
  {
    id: 719243,
    measuredate: "Tue, 19 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.33",
  },
  {
    id: 719243,
    measuredate: "Wed, 20 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.58",
  },
  {
    id: 719243,
    measuredate: "Thu, 21 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.51",
  },
  {
    id: 719243,
    measuredate: "Fri, 22 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.32",
  },
  {
    id: 719243,
    measuredate: "Sat, 23 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.44",
  },
  {
    id: 719243,
    measuredate: "Sun, 24 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.60",
  },
  {
    id: 719243,
    measuredate: "Mon, 25 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.44",
  },
  {
    id: 719243,
    measuredate: "Tue, 26 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.31",
  },
  {
    id: 719243,
    measuredate: "Wed, 27 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.14",
  },
  {
    id: 719243,
    measuredate: "Thu, 28 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.34",
  },
  {
    id: 719243,
    measuredate: "Fri, 29 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.24",
  },
  {
    id: 719243,
    measuredate: "Sat, 30 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.34",
  },
  {
    id: 719243,
    measuredate: "Sun, 31 Jan 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.53",
  },
  {
    id: 719243,
    measuredate: "Mon, 01 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.67",
  },
  {
    id: 719243,
    measuredate: "Tue, 02 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.52",
  },
  {
    id: 719243,
    measuredate: "Wed, 03 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.14",
  },
  {
    id: 719243,
    measuredate: "Thu, 04 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.21",
  },
  {
    id: 719243,
    measuredate: "Fri, 05 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.50",
  },
  {
    id: 719243,
    measuredate: "Sat, 06 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.39",
  },
  {
    id: 719243,
    measuredate: "Sun, 07 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.51",
  },
  {
    id: 719243,
    measuredate: "Mon, 08 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.37",
  },
  {
    id: 719243,
    measuredate: "Tue, 09 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.38",
  },
  {
    id: 719243,
    measuredate: "Wed, 10 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.30",
  },
  {
    id: 719243,
    measuredate: "Thu, 11 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.67",
  },
  {
    id: 719243,
    measuredate: "Fri, 12 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.45",
  },
  {
    id: 719243,
    measuredate: "Sat, 13 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.57",
  },
  {
    id: 719243,
    measuredate: "Sun, 14 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.30",
  },
  {
    id: 719243,
    measuredate: "Mon, 15 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.44",
  },
  {
    id: 719243,
    measuredate: "Tue, 16 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.39",
  },
  {
    id: 719243,
    measuredate: "Wed, 17 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.42",
  },
  {
    id: 719243,
    measuredate: "Thu, 18 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.86",
  },
  {
    id: 719243,
    measuredate: "Fri, 19 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.71",
  },
  {
    id: 719243,
    measuredate: "Sat, 20 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.47",
  },
  {
    id: 719243,
    measuredate: "Sun, 21 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.40",
  },
  {
    id: 719243,
    measuredate: "Mon, 22 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.70",
  },
  {
    id: 719243,
    measuredate: "Tue, 23 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.56",
  },
  {
    id: 719243,
    measuredate: "Wed, 24 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.88",
  },
  {
    id: 719243,
    measuredate: "Thu, 25 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.44",
  },
  {
    id: 719243,
    measuredate: "Fri, 26 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.93",
  },
  {
    id: 719243,
    measuredate: "Sat, 27 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.45",
  },
  {
    id: 719243,
    measuredate: "Sun, 28 Feb 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.80",
  },
  {
    id: 719243,
    measuredate: "Mon, 01 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.91",
  },
  {
    id: 719243,
    measuredate: "Tue, 02 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.74",
  },
  {
    id: 719243,
    measuredate: "Wed, 03 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.25",
  },
  {
    id: 719243,
    measuredate: "Thu, 04 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.46",
  },
  {
    id: 719243,
    measuredate: "Fri, 05 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.31",
  },
  {
    id: 719243,
    measuredate: "Sat, 06 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.41",
  },
  {
    id: 719243,
    measuredate: "Sun, 07 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.21",
  },
  {
    id: 719243,
    measuredate: "Mon, 08 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.42",
  },
  {
    id: 719243,
    measuredate: "Tue, 09 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.41",
  },
  {
    id: 719243,
    measuredate: "Wed, 10 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.24",
  },
  {
    id: 719243,
    measuredate: "Thu, 11 Mar 2021 00:00:00 GMT",
    n_rate: 2,
    val: "1.21",
  },
  {
    id: 719243,
    measuredate: "Thu, 29 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.15",
  },
  {
    id: 719243,
    measuredate: "Fri, 30 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.15",
  },
  {
    id: 719243,
    measuredate: "Sat, 31 Jul 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.41",
  },
  {
    id: 719243,
    measuredate: "Sun, 01 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.49",
  },
  {
    id: 719243,
    measuredate: "Mon, 02 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.23",
  },
  {
    id: 719243,
    measuredate: "Tue, 03 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.11",
  },
  {
    id: 719243,
    measuredate: "Wed, 04 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.23",
  },
  {
    id: 719243,
    measuredate: "Thu, 05 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.12",
  },
  {
    id: 719243,
    measuredate: "Fri, 06 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.11",
  },
  {
    id: 719243,
    measuredate: "Sat, 07 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.03",
  },
  {
    id: 719243,
    measuredate: "Sun, 08 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.23",
  },
  {
    id: 719243,
    measuredate: "Mon, 09 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.08",
  },
  {
    id: 719243,
    measuredate: "Tue, 10 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.00",
  },
  {
    id: 719243,
    measuredate: "Wed, 11 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.17",
  },
  {
    id: 719243,
    measuredate: "Thu, 12 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.18",
  },
  {
    id: 719243,
    measuredate: "Fri, 13 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.05",
  },
  {
    id: 719243,
    measuredate: "Sat, 14 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.11",
  },
  {
    id: 719243,
    measuredate: "Sun, 15 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.12",
  },
  {
    id: 719243,
    measuredate: "Mon, 16 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.25",
  },
  {
    id: 719243,
    measuredate: "Tue, 17 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.03",
  },
  {
    id: 719243,
    measuredate: "Wed, 18 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.39",
  },
  {
    id: 719243,
    measuredate: "Thu, 19 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.04",
  },
  {
    id: 719243,
    measuredate: "Fri, 20 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.33",
  },
  {
    id: 719243,
    measuredate: "Sat, 21 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.22",
  },
  {
    id: 719243,
    measuredate: "Sun, 22 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.26",
  },
  {
    id: 719243,
    measuredate: "Mon, 23 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.29",
  },
  {
    id: 719243,
    measuredate: "Tue, 24 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.19",
  },
  {
    id: 719243,
    measuredate: "Wed, 25 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.00",
  },
  {
    id: 719243,
    measuredate: "Thu, 26 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.31",
  },
  {
    id: 719243,
    measuredate: "Fri, 27 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.24",
  },
  {
    id: 719243,
    measuredate: "Sat, 28 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.22",
  },
  {
    id: 719243,
    measuredate: "Sun, 29 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.22",
  },
  {
    id: 719243,
    measuredate: "Mon, 30 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.21",
  },
  {
    id: 719243,
    measuredate: "Tue, 31 Aug 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.94",
  },
  {
    id: 719243,
    measuredate: "Wed, 01 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.16",
  },
  {
    id: 719243,
    measuredate: "Thu, 02 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.49",
  },
  {
    id: 719243,
    measuredate: "Fri, 03 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.99",
  },
  {
    id: 719243,
    measuredate: "Sat, 04 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.95",
  },
  {
    id: 719243,
    measuredate: "Sun, 05 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.85",
  },
  {
    id: 719243,
    measuredate: "Mon, 06 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.87",
  },
  {
    id: 719243,
    measuredate: "Tue, 07 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.94",
  },
  {
    id: 719243,
    measuredate: "Wed, 08 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.16",
  },
  {
    id: 719243,
    measuredate: "Thu, 09 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.00",
  },
  {
    id: 719243,
    measuredate: "Fri, 10 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.68",
  },
  {
    id: 719243,
    measuredate: "Sat, 11 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.01",
  },
  {
    id: 719243,
    measuredate: "Sun, 12 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.71",
  },
  {
    id: 719243,
    measuredate: "Mon, 13 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.52",
  },
  {
    id: 719243,
    measuredate: "Tue, 14 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.84",
  },
  {
    id: 719243,
    measuredate: "Wed, 15 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.54",
  },
  {
    id: 719243,
    measuredate: "Thu, 16 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.15",
  },
  {
    id: 719243,
    measuredate: "Fri, 17 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.90",
  },
  {
    id: 719243,
    measuredate: "Sat, 18 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.84",
  },
  {
    id: 719243,
    measuredate: "Sun, 19 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "2.27",
  },
  {
    id: 719243,
    measuredate: "Mon, 20 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.19",
  },
  {
    id: 719243,
    measuredate: "Tue, 21 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.52",
  },
  {
    id: 719243,
    measuredate: "Wed, 22 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.79",
  },
  {
    id: 719243,
    measuredate: "Thu, 23 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.49",
  },
  {
    id: 719243,
    measuredate: "Fri, 24 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.86",
  },
  {
    id: 719243,
    measuredate: "Sat, 25 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.25",
  },
  {
    id: 719243,
    measuredate: "Sun, 26 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.93",
  },
  {
    id: 719243,
    measuredate: "Mon, 27 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.72",
  },
  {
    id: 719243,
    measuredate: "Tue, 28 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.60",
  },
  {
    id: 719243,
    measuredate: "Wed, 29 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.99",
  },
  {
    id: 719243,
    measuredate: "Thu, 30 Sep 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.34",
  },
  {
    id: 719243,
    measuredate: "Fri, 01 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.44",
  },
  {
    id: 719243,
    measuredate: "Sat, 02 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.46",
  },
  {
    id: 719243,
    measuredate: "Sun, 03 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.28",
  },
  {
    id: 719243,
    measuredate: "Mon, 04 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.69",
  },
  {
    id: 719243,
    measuredate: "Tue, 05 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.36",
  },
  {
    id: 719243,
    measuredate: "Wed, 06 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.45",
  },
  {
    id: 719243,
    measuredate: "Thu, 07 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.98",
  },
  {
    id: 719243,
    measuredate: "Fri, 08 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.35",
  },
  {
    id: 719243,
    measuredate: "Sat, 09 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.39",
  },
  {
    id: 719243,
    measuredate: "Sun, 10 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "0.81",
  },
  {
    id: 719243,
    measuredate: "Mon, 11 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.24",
  },
  {
    id: 719243,
    measuredate: "Tue, 12 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.49",
  },
  {
    id: 719243,
    measuredate: "Wed, 13 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.56",
  },
  {
    id: 719243,
    measuredate: "Thu, 14 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.45",
  },
  {
    id: 719243,
    measuredate: "Fri, 15 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.57",
  },
  {
    id: 719243,
    measuredate: "Sat, 16 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.70",
  },
  {
    id: 719243,
    measuredate: "Sun, 17 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.61",
  },
  {
    id: 719243,
    measuredate: "Mon, 18 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.65",
  },
  {
    id: 719243,
    measuredate: "Tue, 19 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.59",
  },
  {
    id: 719243,
    measuredate: "Wed, 20 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.52",
  },
  {
    id: 719243,
    measuredate: "Thu, 21 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.43",
  },
  {
    id: 719243,
    measuredate: "Fri, 22 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.66",
  },
  {
    id: 719243,
    measuredate: "Sat, 23 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.66",
  },
  {
    id: 719243,
    measuredate: "Sun, 24 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.40",
  },
  {
    id: 719243,
    measuredate: "Mon, 25 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.77",
  },
  {
    id: 719243,
    measuredate: "Tue, 26 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.76",
  },
  {
    id: 719243,
    measuredate: "Wed, 27 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.45",
  },
  {
    id: 719243,
    measuredate: "Thu, 28 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.48",
  },
  {
    id: 719243,
    measuredate: "Fri, 29 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.9",
  },
  {
    id: 719243,
    measuredate: "Sat, 30 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "2.21",
  },
  {
    id: 719243,
    measuredate: "Sun, 31 Oct 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.55",
  },
  {
    id: 719243,
    measuredate: "Mon, 01 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.27",
  },
  {
    id: 719243,
    measuredate: "Tue, 02 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.63",
  },
  {
    id: 719243,
    measuredate: "Wed, 03 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.50",
  },
  {
    id: 719243,
    measuredate: "Thu, 04 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.55",
  },
  {
    id: 719243,
    measuredate: "Fri, 05 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.50",
  },
  {
    id: 719243,
    measuredate: "Sat, 06 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.20",
  },
  {
    id: 719243,
    measuredate: "Sun, 07 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.30",
  },
  {
    id: 719243,
    measuredate: "Tue, 09 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "3.42",
  },
  {
    id: 719243,
    measuredate: "Wed, 10 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.78",
  },
  {
    id: 719243,
    measuredate: "Thu, 11 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.67",
  },
  {
    id: 719243,
    measuredate: "Fri, 12 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.55",
  },
  {
    id: 719243,
    measuredate: "Sat, 13 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.67",
  },
  {
    id: 719243,
    measuredate: "Sun, 14 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.4",
  },
  {
    id: 719243,
    measuredate: "Mon, 15 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.23",
  },
  {
    id: 719243,
    measuredate: "Tue, 16 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.46",
  },
  {
    id: 719243,
    measuredate: "Wed, 17 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.76",
  },
  {
    id: 719243,
    measuredate: "Thu, 18 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.68",
  },
  {
    id: 719243,
    measuredate: "Fri, 19 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.74",
  },
  {
    id: 719243,
    measuredate: "Mon, 22 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "4.40",
  },
  {
    id: 719243,
    measuredate: "Tue, 23 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.63",
  },
  {
    id: 719243,
    measuredate: "Wed, 24 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.67",
  },
  {
    id: 719243,
    measuredate: "Thu, 25 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.41",
  },
  {
    id: 719243,
    measuredate: "Fri, 26 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.50",
  },
  {
    id: 719243,
    measuredate: "Sat, 27 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.53",
  },
  {
    id: 719243,
    measuredate: "Sun, 28 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.55",
  },
  {
    id: 719243,
    measuredate: "Mon, 29 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "2.08",
  },
  {
    id: 719243,
    measuredate: "Tue, 30 Nov 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.53",
  },
  {
    id: 719243,
    measuredate: "Wed, 01 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.53",
  },
  {
    id: 719243,
    measuredate: "Thu, 02 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.56",
  },
  {
    id: 719243,
    measuredate: "Fri, 03 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.63",
  },
  {
    id: 719243,
    measuredate: "Sat, 04 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.71",
  },
  {
    id: 719243,
    measuredate: "Sun, 05 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.54",
  },
  {
    id: 719243,
    measuredate: "Mon, 06 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.62",
  },
  {
    id: 719243,
    measuredate: "Tue, 07 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.66",
  },
  {
    id: 719243,
    measuredate: "Wed, 08 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.43",
  },
  {
    id: 719243,
    measuredate: "Thu, 09 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.47",
  },
  {
    id: 719243,
    measuredate: "Fri, 10 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.48",
  },
  {
    id: 719243,
    measuredate: "Sat, 11 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.51",
  },
  {
    id: 719243,
    measuredate: "Sun, 12 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.46",
  },
  {
    id: 719243,
    measuredate: "Mon, 13 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.70",
  },
  {
    id: 719243,
    measuredate: "Tue, 14 Dec 2021 01:00:00 GMT",
    n_rate: 2,
    val: "1.71",
  },
  {
    id: 719243,
    measuredate: "Sat, 02 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "6.39",
  },
  {
    id: 719243,
    measuredate: "Sun, 03 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.84",
  },
  {
    id: 719243,
    measuredate: "Mon, 04 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "6.20",
  },
  {
    id: 719243,
    measuredate: "Tue, 05 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "6.73",
  },
  {
    id: 719243,
    measuredate: "Wed, 06 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "8.90",
  },
  {
    id: 719243,
    measuredate: "Thu, 07 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "6.31",
  },
  {
    id: 719243,
    measuredate: "Fri, 08 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.99",
  },
  {
    id: 719243,
    measuredate: "Sat, 09 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "6.83",
  },
  {
    id: 719243,
    measuredate: "Sun, 10 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.54",
  },
  {
    id: 719243,
    measuredate: "Mon, 11 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.42",
  },
  {
    id: 719243,
    measuredate: "Tue, 12 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.77",
  },
  {
    id: 719243,
    measuredate: "Wed, 13 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.80",
  },
  {
    id: 719243,
    measuredate: "Thu, 14 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.37",
  },
  {
    id: 719243,
    measuredate: "Fri, 15 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "6.33",
  },
  {
    id: 719243,
    measuredate: "Sat, 16 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.37",
  },
  {
    id: 719243,
    measuredate: "Sun, 17 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.85",
  },
  {
    id: 719243,
    measuredate: "Mon, 18 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.13",
  },
  {
    id: 719243,
    measuredate: "Tue, 19 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.06",
  },
  {
    id: 719243,
    measuredate: "Wed, 20 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "7.35",
  },
  {
    id: 719243,
    measuredate: "Thu, 21 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "6.38",
  },
  {
    id: 719243,
    measuredate: "Fri, 22 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.61",
  },
  {
    id: 719243,
    measuredate: "Sat, 23 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "6.90",
  },
  {
    id: 719243,
    measuredate: "Sun, 24 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.45",
  },
  {
    id: 719243,
    measuredate: "Mon, 25 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.32",
  },
  {
    id: 719243,
    measuredate: "Tue, 26 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.08",
  },
  {
    id: 719243,
    measuredate: "Wed, 27 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.38",
  },
  {
    id: 719243,
    measuredate: "Thu, 28 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.35",
  },
  {
    id: 719243,
    measuredate: "Fri, 29 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.21",
  },
  {
    id: 719243,
    measuredate: "Sat, 30 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.72",
  },
  {
    id: 719243,
    measuredate: "Sun, 31 Jan 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.24",
  },
  {
    id: 719243,
    measuredate: "Mon, 01 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "6.20",
  },
  {
    id: 719243,
    measuredate: "Tue, 02 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.48",
  },
  {
    id: 719243,
    measuredate: "Wed, 03 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.39",
  },
  {
    id: 719243,
    measuredate: "Thu, 04 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.58",
  },
  {
    id: 719243,
    measuredate: "Fri, 05 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "6.86",
  },
  {
    id: 719243,
    measuredate: "Sat, 06 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.75",
  },
  {
    id: 719243,
    measuredate: "Sun, 07 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "7.27",
  },
  {
    id: 719243,
    measuredate: "Mon, 08 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "6.08",
  },
  {
    id: 719243,
    measuredate: "Tue, 09 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.20",
  },
  {
    id: 719243,
    measuredate: "Wed, 10 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "6.13",
  },
  {
    id: 719243,
    measuredate: "Thu, 11 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.72",
  },
  {
    id: 719243,
    measuredate: "Fri, 12 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.53",
  },
  {
    id: 719243,
    measuredate: "Sat, 13 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.46",
  },
  {
    id: 719243,
    measuredate: "Sun, 14 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.32",
  },
  {
    id: 719243,
    measuredate: "Mon, 15 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "8.66",
  },
  {
    id: 719243,
    measuredate: "Tue, 16 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.02",
  },
  {
    id: 719243,
    measuredate: "Wed, 17 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.61",
  },
  {
    id: 719243,
    measuredate: "Thu, 18 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.42",
  },
  {
    id: 719243,
    measuredate: "Fri, 19 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.26",
  },
  {
    id: 719243,
    measuredate: "Sat, 20 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.21",
  },
  {
    id: 719243,
    measuredate: "Sun, 21 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "6.04",
  },
  {
    id: 719243,
    measuredate: "Mon, 22 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "6.28",
  },
  {
    id: 719243,
    measuredate: "Tue, 23 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "6.23",
  },
  {
    id: 719243,
    measuredate: "Wed, 24 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.76",
  },
  {
    id: 719243,
    measuredate: "Thu, 25 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.56",
  },
  {
    id: 719243,
    measuredate: "Fri, 26 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.24",
  },
  {
    id: 719243,
    measuredate: "Sat, 27 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.74",
  },
  {
    id: 719243,
    measuredate: "Sun, 28 Feb 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.19",
  },
  {
    id: 719243,
    measuredate: "Mon, 01 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "7.04",
  },
  {
    id: 719243,
    measuredate: "Tue, 02 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.89",
  },
  {
    id: 719243,
    measuredate: "Wed, 03 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.17",
  },
  {
    id: 719243,
    measuredate: "Thu, 04 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.23",
  },
  {
    id: 719243,
    measuredate: "Fri, 05 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.97",
  },
  {
    id: 719243,
    measuredate: "Sat, 06 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.05",
  },
  {
    id: 719243,
    measuredate: "Sun, 07 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.94",
  },
  {
    id: 719243,
    measuredate: "Mon, 08 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.84",
  },
  {
    id: 719243,
    measuredate: "Tue, 09 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "6.37",
  },
  {
    id: 719243,
    measuredate: "Wed, 10 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.55",
  },
  {
    id: 719243,
    measuredate: "Thu, 11 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "3.90",
  },
  {
    id: 719243,
    measuredate: "Fri, 12 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.89",
  },
  {
    id: 719243,
    measuredate: "Sat, 13 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.02",
  },
  {
    id: 719243,
    measuredate: "Sun, 14 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "7.98",
  },
  {
    id: 719243,
    measuredate: "Mon, 15 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.85",
  },
  {
    id: 719243,
    measuredate: "Tue, 16 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.47",
  },
  {
    id: 719243,
    measuredate: "Wed, 17 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.20",
  },
  {
    id: 719243,
    measuredate: "Thu, 18 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.31",
  },
  {
    id: 719243,
    measuredate: "Fri, 19 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "6.31",
  },
  {
    id: 719243,
    measuredate: "Sat, 20 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.09",
  },
  {
    id: 719243,
    measuredate: "Sun, 21 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.87",
  },
  {
    id: 719243,
    measuredate: "Mon, 22 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "6.51",
  },
  {
    id: 719243,
    measuredate: "Tue, 23 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.74",
  },
  {
    id: 719243,
    measuredate: "Wed, 24 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.27",
  },
  {
    id: 719243,
    measuredate: "Thu, 25 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.10",
  },
  {
    id: 719243,
    measuredate: "Fri, 26 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.76",
  },
  {
    id: 719243,
    measuredate: "Sat, 27 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.98",
  },
  {
    id: 719243,
    measuredate: "Sun, 28 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "6.26",
  },
  {
    id: 719243,
    measuredate: "Mon, 29 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "7.23",
  },
  {
    id: 719243,
    measuredate: "Tue, 30 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.07",
  },
  {
    id: 719243,
    measuredate: "Wed, 31 Mar 2021 00:00:00 GMT",
    n_rate: 0,
    val: "6.43",
  },
  {
    id: 719243,
    measuredate: "Thu, 01 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.65",
  },
  {
    id: 719243,
    measuredate: "Fri, 02 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.71",
  },
  {
    id: 719243,
    measuredate: "Sat, 03 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "7.13",
  },
  {
    id: 719243,
    measuredate: "Sun, 04 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.74",
  },
  {
    id: 719243,
    measuredate: "Mon, 05 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "7.53",
  },
  {
    id: 719243,
    measuredate: "Tue, 06 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.71",
  },
  {
    id: 719243,
    measuredate: "Wed, 07 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.70",
  },
  {
    id: 719243,
    measuredate: "Thu, 08 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.88",
  },
  {
    id: 719243,
    measuredate: "Fri, 09 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.22",
  },
  {
    id: 719243,
    measuredate: "Sat, 10 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "7.06",
  },
  {
    id: 719243,
    measuredate: "Sun, 11 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "5.04",
  },
  {
    id: 719243,
    measuredate: "Mon, 12 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "8.34",
  },
  {
    id: 719243,
    measuredate: "Tue, 13 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.87",
  },
  {
    id: 719243,
    measuredate: "Wed, 14 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.29",
  },
  {
    id: 719243,
    measuredate: "Thu, 15 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "4.58",
  },
  {
    id: 719243,
    measuredate: "Fri, 16 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "3.93",
  },
  {
    id: 719243,
    measuredate: "Sat, 17 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "3.72",
  },
  {
    id: 719243,
    measuredate: "Sun, 18 Apr 2021 00:00:00 GMT",
    n_rate: 0,
    val: "3.09",
  },
  {
    id: 719243,
    measuredate: "Thu, 13 Jan 2022 00:00:00 GMT",
    n_rate: 2,
    val: "10.09",
  },
  {
    id: 719243,
    measuredate: "Thu, 13 Jan 2022 00:00:00 GMT",
    n_rate: 1,
    val: "10.09",
  },
  {
    id: 719243,
    measuredate: "Tue, 1 Feb 2022 00:00:00 GMT",
    n_rate: 2,
    val: "10.09",
  },
  {
    id: 719243,
    measuredate: "Tue, 1 Feb 2022 00:00:00 GMT",
    n_rate: 1,
    val: "10.09",
  },
];
