import { format } from "date-fns";
import { ru } from "date-fns/locale";

import { CusTypo } from "components/cusTypo/custom-typography";
import { EMPTY_CELL_VALUE } from "stream-constants/keys-and-constants";
import { getNumberWithSpaces } from "utils/formatters";
import { MTSStatus } from "components/mts-status/mts-status";
import { CopyToClipboard } from "components/copyToClipboard/copyToClipboard.component";
import { BillingDeskAction } from "components/card-info/billing/billing.component";

const renderCell = (val) => {
  switch (val) {
    case "bill_id":
      return {
        Cell: ({ value }) => (
          <CopyToClipboard text={value ?? EMPTY_CELL_VALUE} variant="p4_medium" font="comp" />
        ),
      };
    case "bill_amount":
      return {
        Cell: ({ value }) => (
          <CusTypo variant="p4_bold" font="comp">
            {`${getNumberWithSpaces(value)} ₽` ?? EMPTY_CELL_VALUE}
          </CusTypo>
        ),
      };
    case "bill_status":
      return {
        Cell: ({ value }) => MTSStatus(value),
      };
    case "bill_dt":
      return {
        Cell: ({ value }) => (
          <CusTypo variant="p4_regular">
            {format(new Date(value ?? 0), "dd.MM.yyyy HH:mm", {
              locale: ru,
            })}
          </CusTypo>
        ),
      };
    case "action":
      return {
        Cell: ({ row }) => <BillingDeskAction data={row} />,
      };
    default:
      return {
        Cell: ({ value }) => <CusTypo variant="p4_regular">{value ?? ""}</CusTypo>,
      };
  }
};

export const constructColumns = ({ columns }) => {
  return columns.map((singleColumn) => {
    return {
      ...singleColumn,
      ...renderCell(singleColumn.accessor),
    };
  });
};
