import styled from "@emotion/styled";

export const SCheckBtn = styled.button<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  padding: 6px 16px;
  border-radius: 100px;
  background-color: ${(props) => props.theme.mtsColor.background.secondary.lightMode};
  cursor: pointer;

  & > svg,
  & > div {
    margin-right: 8px;
  }

  & > span {
    white-space: nowrap;
    color: ${(props) => props.theme.mtsColor.text.primary.lightMode};
  }

  ${(props) =>
    props.isActive &&
    `
	background-color: ${props.theme.mtsColor.background.primary.darkMode};
    & > svg {
      fill: ${props.theme.mtsColor.background.primary.lightMode};
      display: block;
    }

	& > div {
	  display: flex;
	}

    & > span {
      color: ${props.theme.mtsColor.text.primary.darkMode};
    }
  `}
`;
