import { useEffect, useMemo } from "react";
import { isEmpty } from "lodash";

import { Table } from "components/table/table.component";
import { IResearchStatResResponse } from "interface/analytics";
import { constructColumns, constructTableData } from "./utils";
import { tableStructure } from "./const";

interface IProp {
  rawData: IResearchStatResResponse[];
  onRowClick: (a: any) => void;
  rowSelected: number;
}
export const AnalStatTable: React.FC<IProp> = ({ rawData, onRowClick, rowSelected }) => {
  const tableData = useMemo(() => constructTableData(rawData), [rawData]);

  const columns = useMemo(
    () =>
      constructColumns({
        columns: tableStructure,
        selectedId: rowSelected,
      }),
    [rowSelected],
  );

  const handleRowClick = (v) => {
    onRowClick(v);
  };

  useEffect(() => {
    if (!isEmpty(rawData)) onRowClick(rawData[0].id_res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData.length]);

  return (
    <Table
      columns={columns}
      data={tableData}
      view="sheets"
      onRowClick={handleRowClick}
      initSelRow={{ "0": true }}
      headerHeight={48}
    />
  );
};
