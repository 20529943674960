import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Analytics = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.9 3V5.73383C15.951 6.17594 18.3 8.79248 18.3 11.9774C18.3 12.7895 18.138 13.5564 17.868 14.2692L20.208 15.6496C20.712 14.5308 21 13.2857 21 11.9774C21 7.30376 17.445 3.45113 12.9 3ZM12 18.2932C8.517 18.2932 5.7 15.4692 5.7 11.9774C5.7 8.79248 8.049 6.17594 11.1 5.73383V3C6.546 3.45113 3 7.29474 3 11.9774C3 16.9579 7.023 21 11.991 21C14.97 21 17.607 19.5474 19.245 17.3098L16.905 15.9293C15.753 17.3729 13.989 18.2932 12 18.2932Z"
        fill="#FF0032"
      />
    </SvgIcon>
  );
};

export default Analytics;
