import styled from "@emotion/styled";
import { CusTypo } from "components/cusTypo/custom-typography";
import { MTS_COMPACT, p1_styles } from "theme";

export const SBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  max-width: 464px;
  background-color: ${(props) => props.theme.mtsColor.background.modal.lightMode};
  border-radius: 16px;
  outline: none;
  padding: 20px;
`;

export const SIcons = styled.div`
  display: flex;
  justify-content: center;
`;

export const SText = styled(CusTypo)`
  text-align: center;
  display: inline-block;
  width: 100%;
`;

export const SModalTitle = styled(CusTypo)`
  display: inline-block;
  width: 100%;
  font-family: ${MTS_COMPACT}, sans-serif;
  font-weight: 500;
  ${p1_styles as any};
`;

export const SLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
`;
