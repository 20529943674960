import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { device } from "theme";

export const SWrap = styled.div`
  margin-bottom: 8px;

  @media ${device.desktopPlus} {
    margin-bottom: 48px;
  }
`;

export const SLink = styled(Link)`
  text-decoration: none;
`;
