import { useMemo } from "react";

import { Table } from "components/table/table.component";
import { useAnalStatsArchive } from "hooks/api/analytics";
import { constructColumns, constructTableData } from "./utils";
import { tableStructure } from "./const";

interface IProp {
  id: string | number;
}
export const AnalArchiveTable: React.FC<IProp> = ({ id }) => {
  const { data } = useAnalStatsArchive(id);

  const tableData = useMemo(() => constructTableData(data ?? ([] as any)), [data]);

  const columns = useMemo(
    () =>
      constructColumns({
        columns: tableStructure,
      }),
    [],
  );

  return <Table columns={columns} data={tableData} view="alternatingRows" height={370} />;
};
