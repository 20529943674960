import { DEPARTURE_RESULT } from "stream-constants/keys-and-constants";

export const TABLE_SORT_DEFAULT_BILLING = [
  {
    accessor: "service_nm",
    text: "Организация",
  },
  {
    accessor: "bill_dt",
    text: "Дата выставления счета",
  },
  {
    accessor: "bill_status",
    text: "Статус",
  },
  {
    accessor: "bill_amount",
    text: "Сумма",
  },
];

export const FILTER_BY_STATUS = "bill_status";
export const FILTER_BY_TENANT = "tenant_id";

export const TABLE_FILTER_DEFAULT_BILLING = {
  [FILTER_BY_STATUS]: [],
  [FILTER_BY_TENANT]: [],
};

export const HARDCODE_STATUSES = [
  {
    id: 0,
    name: DEPARTURE_RESULT.PAID,
  },
  {
    id: 1,
    name: "Неоплачен",
  },
  {
    id: 2,
    name: "Оплачен частично",
  },
  {
    id: 3,
    name: DEPARTURE_RESULT.TRANSACTION,
  },
  {
    id: 4,
    name: DEPARTURE_RESULT.NOT_REQUIRE_PAYMENT,
  },
];

export const SCHEME_BILLING = {
  checkbox: { [FILTER_BY_STATUS]: HARDCODE_STATUSES },
  radio: {},
};
