export const TABLE_SORT_DEFAULT_EVENTS = [
  {
    accessor: "eventDatetime",
    text: "Дата и время",
  },
  {
    accessor: "userName",
    text: "Источник",
  },
  {
    accessor: "roles",
    text: "Роль",
  },
  {
    accessor: "eventType",
    text: "Уровень",
  },
];
