import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";

import useStyles from "./styles";
import { CheckboxSize } from "./types";
import getBoxSize from "./utils/getBoxSize";
import getIconBySize from "./utils/getIconBySize";

interface MTSCheckboxProps extends CheckboxProps {
  boxSize: "small" | "medium" | "large";
}

export const MTSCheckbox = (props: MTSCheckboxProps) => {
  const { boxSize, ...rest } = props;
  const classes = useStyles();

  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: boxSize === CheckboxSize.MEDIUM_24 || boxSize === CheckboxSize.LARGE_32 ? 6 : 4,
    width: getBoxSize(boxSize),
    height: getBoxSize(boxSize),
    border: "2px solid",
    borderColor: "rgba(0,0,0, 0.6)",
    backgroundColor: theme.mtsColor.background.primary.lightMode,

    ".Mui-focusVisible &": {
      outline: "1px solid rgba(0, 124, 255, 0.6)",
      borderRadius: 2,
      outlineOffset: 4,
    },
    "input:hover ~ &": {
      backgroundColor: theme.mtsColor.background.primary.lightMode,
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      borderColor: "transparent",
      background: theme.mtsColor.controls.inactive.lightMode,
    },
  }));

  const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
    backgroundColor: theme.mtsColor.background.primary.darkMode,
    border: "2px solid transparent",
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: "100%",
      height: "100%",
      backgroundImage: `url(${getIconBySize(boxSize)})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: theme.mtsColor.background.primary.darkMode,
    },
  }));

  const BpIndeterminateIcon = styled(BpIcon)(({ theme }) => ({
    background: theme.mtsColor.red,
    border: "2px solid transparent",
    "&:before": {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width:
        boxSize === CheckboxSize.SMALL_16
          ? "10px"
          : boxSize === CheckboxSize.MEDIUM_24
          ? "12px"
          : boxSize === CheckboxSize.LARGE_32
          ? "18px"
          : "10px",
      height:
        boxSize === CheckboxSize.SMALL_16
          ? "1.5px"
          : boxSize === CheckboxSize.MEDIUM_24
          ? "2px"
          : boxSize === CheckboxSize.LARGE_32
          ? "2.5px"
          : "1.5px",
      background: theme.mtsColor.background.primary.lightMode,
      content: "''",
    },
    "input:hover ~ &": {
      backgroundColor: theme.mtsColor.red,
    },
  }));

  return (
    <Checkbox
      classes={{
        root: classes.root,
      }}
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      indeterminateIcon={<BpIndeterminateIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...rest}
    />
  );
};
