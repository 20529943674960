import { useMemo, useState, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { ITableFilterClm, ITableSortColumn, Table } from "components/table/table.component";
import { anomaliesGlobFilter, constructColumns, constructTableData } from "./utils";
import { tableMobStructure, tableStructure } from "./const";
import { ContextSettings } from "context/context-settings";
import { MobileTable } from "components/table/mobile-table/mobileTable.component";
import { ROUTE_DEVICE_LIST_PAGE } from "stream-constants/route-constants";

interface IProp {
  rawData: any;
  filterByColumn?: ITableFilterClm[];
  onRowsChange: Function;
  globalFilter?: any;
  sortByColumn?: ITableSortColumn[];
  withStub?: boolean;
  savedId?: number;
}
export const DeviceTable: React.FC<IProp> = ({
  rawData,
  filterByColumn,
  onRowsChange,
  globalFilter,
  sortByColumn,
  withStub,
  savedId,
}) => {
  const { isDesktop } = useContext(ContextSettings);
  const navigate = useNavigate();
  const [active, setActive] = useState({});

  const tableData = useMemo(() => constructTableData(rawData ?? [{}]), [rawData]);
  const columns = useMemo(() => constructColumns({ columns: tableStructure, active }), [active]);
  const mobileColumns = useMemo(() => tableMobStructure, []);

  const toggle = useCallback((id) => setActive((a) => ({ [id]: !a[id] })), []);
  const clickMobile = useCallback((id) => navigate(`/${ROUTE_DEVICE_LIST_PAGE}/${id}`), [navigate]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRowsChange = useCallback((a) => onRowsChange(a), []);

  return isDesktop() ? (
    <Table
      columns={columns}
      data={tableData}
      filterByColumn={filterByColumn}
      view="expandable"
      onRowClick={toggle}
      onRowsChange={handleRowsChange}
      globalFilter={globalFilter}
      globalFilterFunction={anomaliesGlobFilter}
      withStub={withStub}
      savedId={savedId}
    />
  ) : (
    <MobileTable
      columns={mobileColumns}
      data={tableData}
      filterByColumn={filterByColumn}
      sortByColumn={sortByColumn}
      onRowClick={clickMobile}
      onRowsChange={handleRowsChange}
      globalFilter={globalFilter}
      globalFilterFunction={anomaliesGlobFilter}
      withStub={withStub}
      padding="0 20px"
    />
  );
};
