import { IUpdateProdCardRequest } from "interface/management";
import { fetchData } from "services/services-utils/fetch-data";
import { SHOP, SHOP_CHANGE_HIDE_CART, SHOP_EDIT_CART } from "services/urls";
import { IHttpMethods } from "stream-constants/server-constants";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";

export const getShopCatalogue = async () => {
  return fetchData({
    url: SHOP,
    method: IHttpMethods.GET,
    name: "getShopCatalogue",
    emptyResponse: false,
  });
};

export interface IProdVisReq {
  hide_flag: boolean;
  id: number | string;
}

export const changeProductVisibility = async (data: IProdVisReq) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = "OK";
  } else {
    result = fetchData({
      url: SHOP_CHANGE_HIDE_CART,
      method: IHttpMethods.PATCH,
      name: "changeHideProd",
      body: JSON.stringify(data),
      emptyResponse: false,
    });
  }

  return result;
};

export const updateProduct = async (data: IUpdateProdCardRequest) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = "OK";
  } else {
    result = fetchData({
      url: SHOP_EDIT_CART,
      method: IHttpMethods.PATCH,
      name: "updateProduct",
      body: JSON.stringify(data),
      emptyResponse: false,
    });
  }

  return result;
};
