import { useMemo } from "react";

import { ITariffRes } from "interface/management";
import { Table } from "components/table/table.component";
import { constructColumns, constructTableData } from "./utils";
import { tableStructure } from "./const";

interface IProp {
  rawData: ITariffRes[];
  globalFilter?: string;
}
export const TariffBillingTable: React.FC<IProp> = ({ rawData, globalFilter }) => {
  const tableData = useMemo(() => constructTableData(rawData), [rawData]);
  const columns = useMemo(() => constructColumns({ columns: tableStructure }), []);
  const defaultSortBy = useMemo(
    () => [
      {
        id: "created_time",
        desc: true,
      },
    ],
    [],
  );

  return (
    <Table
      view="alternatingRows"
      columns={columns}
      data={tableData}
      globalFilter={globalFilter}
      sortByColumn={defaultSortBy}
      height={516}
    />
  );
};
