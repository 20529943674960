import { FC } from "react";
import { toast } from "react-toastify";

import { MTSButton } from "components/mts-button/mts-button";
import { IRoles } from "interface/common";
import { useMutationDeleteUser } from "hooks/api/management";
import { LIST_ROLES_NAME } from "utils/roles";
import { Spacer } from "components/spacer/spacer.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import Icon from "components/icons";
import { Toast } from "components/toast/toast";
import { SModalTitle } from "components/modal-notify-body/styles";
import { SBtns, SRoleText, SUserBlock } from "./styles";

type IProps = {
  onCancel: Function;
  id: number;
  userData?: any;
};

const mergeAllRoles = (roles: IRoles[]) => {
  const arrayAllRoles = roles.map(
    (item: IRoles, index: number) => LIST_ROLES_NAME[item.name.toUpperCase()],
  );

  return arrayAllRoles.join(", ");
};

export const FormDeleteUser: FC<IProps> = ({ onCancel, id, userData }) => {
  const mutation = useMutationDeleteUser(id);

  return (
    <>
      <SUserBlock>
        <Icon.Warn sx={{ width: 44, height: 44 }} />
        <Spacer value="16px" />
        <SModalTitle styles={{ textAlign: "center" }}>
          Вы действительно хотите удалить&nbsp;пользователя&nbsp;?
        </SModalTitle>

        <CusTypo variant="p3_bold" styles={{ display: "block" }}>
          {`${userData?.userName ?? ""}`}
        </CusTypo>
        {userData?.roles ? (
          <SRoleText>
            <CusTypo variant="c1_regular">{mergeAllRoles(userData.roles)}</CusTypo>
          </SRoleText>
        ) : null}
      </SUserBlock>
      <SBtns>
        <MTSButton
          size="M"
          variant="primary"
          type="submit"
          onClick={() => {
            mutation.mutate();
            onCancel();
          }}
        >
          Удалить
        </MTSButton>
        <MTSButton size="M" variant="secondary" onClick={onCancel as any}>
          Отмена
        </MTSButton>
      </SBtns>
    </>
  );
};

export const toastDelUserSucc = () => {
  toast(<Toast title="Пользователь успешно удален" />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });
};

export const toastDelUserErr = () => {
  toast(<Toast title="Пользователь не удален" isError />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });
};
