import { Footer } from "components/footer/footer";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SMain, SContainer, SArticle, SWrap, SBlock, SList, SListItem, SParag } from "./styles";

export const UserAgreement = () => (
  <>
    <SMain>
      <SContainer>
        <SArticle>
          <CusTypo variant="h2_bold" font="wide">
            Согласие на обработку персональных данных
          </CusTypo>
          <SWrap>
            <SBlock>
              <CusTypo variant="p3_regular">
                Нажимая кнопку &laquo;Отправить&raquo;, Вы, действуя свободно, своей волей
                и&nbsp;в&nbsp;своем интересе, выражаете конкретное, сознательное
                и&nbsp;информированное согласие Публичному акционерному обществу &laquo;Мобильные
                ТелеСистемы&raquo; (109147, г.&nbsp;Москва, ул.&nbsp;Марксистская, дом. 4) (далее
                по&nbsp;тексту&nbsp;&mdash; МТС) на&nbsp;обработку Ваших персональных данных
                с&nbsp;использованием средств автоматизации и/или без использования таковых средств
                путем совершения следующих действий (операций) или совокупности следующих действий
                (операций): сбор, запись, систематизация, накопление, хранение, уточнение
                (обновление, изменение), извлечение, использование, передача (предоставление,
                доступ), обезличивание, блокирование, удаление, уничтожение персональных данных, для
                реализации следующих целей:
              </CusTypo>
              <SList>
                <SListItem>
                  <CusTypo variant="p3_regular">
                    Формирование и&nbsp;рассмотрение заявок на&nbsp;приобретение лицензии
                    на&nbsp;программное обеспечение &laquo;EnegryTool&raquo;;
                  </CusTypo>
                </SListItem>
                <SListItem>
                  <CusTypo variant="p3_regular">
                    Использование программного обеспечения &laquo;EnegryTool&raquo;;
                  </CusTypo>
                </SListItem>
                <SListItem>
                  <CusTypo variant="p3_regular">
                    Oсуществление МТС информационных, новостных и&nbsp;рекламных рассылок
                    об&nbsp;услугах и&nbsp;сервисах МТС и&nbsp;Партнеров МТС; о&nbsp;проводимых МТС
                    и&nbsp;Партнерами МТС акциях, специальных предложениях и&nbsp;программах
                    лояльности;
                  </CusTypo>
                </SListItem>
                <SListItem>
                  <CusTypo variant="p3_regular">Проведение МТС маркетинговых исследований;</CusTypo>
                </SListItem>
                <SListItem>
                  <CusTypo variant="p3_regular">
                    МТС может осуществлять обработку следующих категорий персональных данных:
                  </CusTypo>
                  <br />

                  <CusTypo variant="p3_regular">
                    фамилия, имя, отчество; ИНН организации представителем которой
                    Вы&nbsp;являетесь; контактная информация (номер телефона, адрес электронной
                    почты).
                  </CusTypo>
                </SListItem>
              </SList>
              <SParag>
                <CusTypo variant="p3_regular">
                  Настоящее согласие на&nbsp;обработку персональных данных действует с&nbsp;момента
                  его акцепта и&nbsp;до&nbsp;момента достижения заявленных целей обработки
                  персональных данных, с&nbsp;учетом сроков, предусмотренных действующим
                  законодательством Российской Федерации.
                </CusTypo>
              </SParag>
              <SParag>
                <CusTypo variant="p3_regular">
                  Настоящее согласие на&nbsp;обработку персональных данных, в&nbsp;соответствии
                  с&nbsp;требованиями Федерального закона от&nbsp;27.07.2006 &#8470;&nbsp;152-ФЗ
                  &laquo;О&nbsp;персональных данных&raquo; может быть отозвано Вами путем
                  направления письменного заявления МТС по&nbsp;адресу: 109147, г.&nbsp;Москва,
                  ул.&nbsp;Марксистская, дом. 4.
                </CusTypo>
              </SParag>
            </SBlock>
          </SWrap>
        </SArticle>
      </SContainer>
    </SMain>

    <Footer />
  </>
);
