import { css } from "@emotion/react";
import { ITableFilterClm } from "./table.component";
import { IKek } from "./table-filter/table-filter";

export const default_header_height = 40;
export const scrollbarWidth = 4;

export const customScrollVertical = ({
  theme,
  width = scrollbarWidth,
  trackStart = default_header_height,
  trackEnd = 0,
}) => css`
  &::-webkit-scrollbar {
    width: ${width}px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track-piece:start {
    background-color: transparent;
    margin-top: ${trackStart}px;
  }

  &::-webkit-scrollbar-track-piece:end {
    background-color: transparent;
    margin-bottom: ${trackEnd}px;
  }

  &::-webkit-scrollbar-corner {
    border-radius: 4px;
    width: 6px;
    height: 6px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.mtsColor.background.stroke.lightMode};
    border-radius: 4px 4px 2px 4px;
  }
`;

export const customScrollHorisontal = (theme, height = scrollbarWidth) => css`
  &::-webkit-scrollbar {
    height: ${height}px;
    border-radius: 4px 4px 4px 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track-piece:start {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track-piece:end {
    background-color: transparent;
  }

  &::-webkit-scrollbar-corner {
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.mtsColor.background.stroke.lightMode};
    border-radius: 4px 4px 4px 4px;
  }
`;

export const getFilterArray = (data: IKek): ITableFilterClm[] => {
  return Object.entries(data).map(([key, val]) => {
    return { column: key, value: val };
  });
};
