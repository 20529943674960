import styled from "@emotion/styled";
import { CusTypo } from "components/cusTypo/custom-typography";
import { MTS_WIDE, device } from "theme";

export const SSection = styled.section`
  padding: 48px 20px 0;
  text-align: left;

  @media ${device.tabletPlus} {
    padding: 48px 32px 0 32px;
  }
`;

export const SAnomalies = styled.div<{ isRowMob?: boolean }>`
  display: flex;
  ${(props) =>
    props.isRowMob
      ? `align-items: center;
		 flex-flow: row nowrap;`
      : `flex-flow: column`};
  justify-content: space-between;
  margin-bottom: 24px;
  gap: 24px;

  @media ${device.desktopPlus} {
    ${(props) =>
      props.isRowMob
        ? ""
        : ` flex-flow: row nowrap;
			align-items: center;`}
    margin-bottom: 48px;
  }
`;

export const SGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;

  @media ${device.mobilePlus} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.miniTabletPlus} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${device.desktopPlus} {
    grid-template-rows: 1fr;
    gap: 32px;
  }
`;

export const SStub = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  text-align: center;

  & > svg {
    margin-bottom: 40px;
  }

  & > span:first-of-type {
    margin-bottom: 24px;
  }

  & > span:last-of-type {
    color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
  }
`;

export const SEff = styled.div`
  & > section {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 12px;

    @media ${device.miniTabletPlus} {
      grid-template-columns: 1fr 1fr;
      column-gap: 32px;
    }

    & > div {
      min-height: 98px;
      display: flex;
      flex-flow: column wrap;
      justify-content: space-between;
      align-items: start;
      gap: 14px;
      padding: 12px;
      border-radius: 8px;
      box-shadow: 1px 5px 22px 0px ${(props) => props.theme.mtsColor.background.stroke.lightMode};
      background-color: white;
      min-height: 82px;
      white-space: normal;

      @media ${device.desktopPlus} {
        flex-flow: row nowrap;
        align-items: center;
        gap: 24px;
        padding: 24px 32px;
        white-space: pre-wrap;
      }

      &:first-of-type {
        background-color: #122840;
        & span {
          color: ${(props) => props.theme.mtsColor.text.primary.darkMode};
        }
      }
    }
  }
`;

export const SPerc = styled(CusTypo)`
  font-family: ${MTS_WIDE}, sans-serif;
`;
