import styled from "@emotion/styled";
import { CusTypo } from "components/cusTypo/custom-typography";

export const SFavInfo = styled.div`
  position: relative;
  top: 3px;
  min-width: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  padding-left: 16px;

  &:before {
    position: absolute;
    content: "";
    width: 1px;
    height: 100%;
    left: 0;
    background-color: ${(props) => props.theme.mtsColor.background.hover.lightMode};
  }
`;

export const SInfo = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  & > svg {
    margin-bottom: 3px;
  }
`;

export const SBlue = styled(CusTypo)`
  text-decoration: none;
  cursor: pointer;
  color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
`;

export const SGrey = styled(CusTypo)`
  color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
`;
