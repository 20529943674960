import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Anomalies = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.9807 0H13.0093C13.2663 0.0192188 13.5219 0.150938 13.6462 0.382969C13.7836 0.607031 13.7419 0.879844 13.7217 1.12781C13.5018 3.75187 13.2832 6.37594 13.0647 9C14.7937 9.00234 16.5233 8.99578 18.2523 9.00281C18.634 8.99578 18.9787 9.32063 18.997 9.70078C19.0242 9.96844 18.8601 10.1958 18.7274 10.4119C16.0402 14.8059 13.3545 19.2009 10.6669 23.595C10.5361 23.8266 10.2922 23.9869 10.0239 24H9.97002C9.71819 23.9766 9.47292 23.8397 9.35052 23.6142C9.21687 23.3916 9.25626 23.122 9.27502 22.8769C9.49684 20.0011 9.71772 17.1258 9.93813 14.25C8.22268 14.2491 6.50722 14.2514 4.79224 14.2491C4.47897 14.2613 4.16524 14.0723 4.05316 13.7761C3.95046 13.5361 4.00439 13.2553 4.14789 13.0439C6.87489 8.83031 9.60235 4.61719 12.3303 0.404531C12.4686 0.174844 12.7106 0.016875 12.9807 0ZM6.12596 12.7495C7.65148 12.7505 9.17654 12.7495 10.7021 12.7505C11.0045 12.7359 11.3084 12.9103 11.4294 13.1911C11.5279 13.388 11.4913 13.613 11.4763 13.8234C11.3126 15.9473 11.1495 18.0708 10.9867 20.1947C12.9629 16.9636 14.9382 13.732 16.9139 10.5005C15.3588 10.4981 13.8038 10.5028 12.2492 10.4981C11.9495 10.5005 11.6592 10.3059 11.5546 10.0242C11.4721 9.8325 11.5091 9.61969 11.5246 9.41859C11.6845 7.50281 11.844 5.58656 12.003 3.67078C10.0436 6.69656 8.08433 9.72281 6.12596 12.7495Z"
        fill="#FF0032"
      />
    </SvgIcon>
  );
};

export default Anomalies;
