import { useNavigate } from "react-router-dom";
import { useState } from "react";

import {
  detailTitleByKey,
  historyTitleByKey,
  makeTenantDetailsMob,
  makeTenantHistory,
  sortHistoryCheckByDate,
} from "components/card-info/utils";
import { IDeviceList, ITelebotRequest } from "interface/anomalies";
import { MTSButton } from "components/mts-button/mts-button";
import Icon from "components/icons";
import { ROUTE_DEVICE_LIST_PAGE } from "stream-constants/route-constants";
import { CopyToClipboard } from "components/copyToClipboard/copyToClipboard.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { MobDeviceCard } from "../deviceTable/subComponents";
import { InfoTabsCard } from "../subComponents/infoTabsCard";
import { SCont, SInfoMes } from "./styles";

export const MobDevice = ({ data }: { data: IDeviceList }) => {
  const navigate = useNavigate();
  const history = data.telebot_request?.sort(sortHistoryCheckByDate);
  const [showTab, setShowTab] = useState<"history" | "info">("history");

  return (
    <>
      <SCont>
        <MobDeviceCard row={data} />
        <article>
          <div style={{ paddingTop: "24px" }}>
            <InfoTabsCard isMobile onSelect={setShowTab as any} />
          </div>
          <ul>
            {showTab === "history" ? (
              history && history?.length ? (
                history.map((item: ITelebotRequest, index: number) => {
                  return (
                    <li key={index}>
                      {Object.entries(makeTenantHistory(data, item, true)).map(([key, val]) => {
                        if (
                          (key === "telebot_request_metter" || key === "telebot_request_clamp") &&
                          (item.val_meter_A || item.val_clamp_A)
                        ) {
                          return val.value;
                        } else {
                          return key === "telebot_request_comment" && !val.value ? null : (
                            <div key={key}>
                              <CusTypo variant="c1_regular" font="comp">
                                {historyTitleByKey[key]}
                              </CusTypo>
                              <CusTypo
                                variant={
                                  key === "telebot_request_comment" ? "c1_regular" : "c1_medium"
                                }
                                font="comp"
                              >
                                {val.value}
                              </CusTypo>
                            </div>
                          );
                        }
                      })}
                    </li>
                  );
                })
              ) : (
                <SInfoMes variant="c1_regular" font="comp">
                  Пока еще не было добавлено ни одного результата проверок
                </SInfoMes>
              )
            ) : (
              <li>
                {Object.entries(makeTenantDetailsMob(data)).map(([key, val], index) => (
                  <div key={index}>
                    <CusTypo variant="c1_regular">{detailTitleByKey[key]}</CusTypo>
                    {val["isCanCopy"] ? (
                      <CopyToClipboard text={val.text ?? "нет значения"} />
                    ) : (
                      <CusTypo variant="c1_medium">{val.text}</CusTypo>
                    )}
                  </div>
                ))}
              </li>
            )}
          </ul>
        </article>
      </SCont>
      {showTab === "history" ? (
        <MTSButton
          size="M"
          onClick={() =>
            navigate(`/${ROUTE_DEVICE_LIST_PAGE}/${data.id_pp}/update`, {
              state: { device_sn: data.factory_count_num },
            })
          }
          icon={<Icon.Plus sx={{ width: 16, height: 16 }} />}
        >
          Добавить результат
        </MTSButton>
      ) : null}
    </>
  );
};
