import { IResearchPriemResponse, IResearchPoResponse } from "interface/analytics";

export const mock_priem: IResearchPriemResponse = {
  name_res: [
    "Восточный РЭС",
    "Западный РЭС",
    "Северный РЭС",
    "Центральный РЭС",
    "Юго-Восточный РЭС",
    "Южный РЭС",
  ],
};

export const mock_po: IResearchPoResponse = {
  name_po: ["БЭС", "КЭС", "УГЭС", "ЦЭС"],
};
