import format from "date-fns/format";
import {
  FRAUD_COL,
  TICKETS_COL,
  PU_COL,
  MINER_COL,
  CONS_COL,
  RESULT_COL,
  CHANGE_DATE_COL,
  DATE_GET_COL,
  DATE_SEND_COL,
  periodTitleByKey,
  rangeTitleByKey,
  RES_COL,
  PO_COL,
  BADTEL_COL,
  RECIDIV_COL,
  REDCONS_COL,
  REVER_COL,
  FLUL_COL,
  selectTitleByKey,
  headByKey,
} from "./const";

type IInnerFilt = {
  [key: string]: number | number[] | string[] | undefined;
};

const calcDropboxAmount = (subcatContent, state) => [
  Object.keys(subcatContent)
    .map((el) => {
      return { type: el, val: subcatContent[el] };
    })
    .reduce((tot, cur) => {
      let res = 0;
      if (cur.type === "checkbox") {
        res = Array.isArray(cur.val)
          ? cur.val?.reduce((n, m) => {
              return n + (state?.[m.name]?.length || 0);
            }, 0)
          : state?.[cur.val.name]?.length;
      }
      if (cur.type === "period") {
        res = state?.[cur.val]?.some((ev) => ev != null) ? 1 : 0;
      }
      if (cur.type === "select") {
        res = cur.val
          .map((cv) => (state?.[cv]?.some((ev) => ev !== "all") ? 1 : 0))
          ?.reduce((sum, am) => sum + am, 0);
      }
      if (cur.type === "range") {
        res = cur.val
          .map((cv) => (state?.[cv]?.some((ev) => ev != null) ? 1 : 0))
          ?.reduce((sum, am) => sum + am, 0);
      }
      if (cur.type === "number") {
        res = cur.val.map((cv) => (state?.[cv] != null ? 1 : 0))?.reduce((sum, am) => sum + am, 0);
      }
      return tot + (res || 0);
    }, 0),
];

export const renderCatNumbers = (subcatContent, state) => {
  return (
    Object.values(subcatContent)
      .map((a) => calcDropboxAmount(a, state))
      .flat()
      ?.reduce((g, h) => g + (h || 0), 0) || " "
  );
};

export const renderSubcatNumbers = (subcatContent, state) =>
  calcDropboxAmount(subcatContent, state)?.reduce((g, h) => g + h, 0) || " ";

const checkboxKeys = [FLUL_COL, FRAUD_COL, TICKETS_COL, CONS_COL, RESULT_COL, PO_COL, RES_COL];
const periodKeys = [CHANGE_DATE_COL, DATE_GET_COL, DATE_SEND_COL];
export const selectKeys = [PU_COL, MINER_COL, BADTEL_COL, RECIDIV_COL, REDCONS_COL, REVER_COL];

export const getChipsValue = (state: IInnerFilt) => {
  return Object.entries(state).map(([key, val]) => {
    if (checkboxKeys.includes(key)) {
      return { type: "checkbox", val, key };
    }
    if (periodKeys.includes(key)) {
      return {
        type: "period",
        val: `${periodTitleByKey[key] || headByKey[key]} (${
          (val as string[])[0] != null
            ? `c ${format(new Date((val as string[])[0] ?? 0), "dd.MM.yyyy")}`
            : ""
        } ${
          (val as string[])[1] != null
            ? `по ${format(new Date((val as string[])[1] ?? 0), "dd.MM.yyyy")}`
            : ""
        })`,
        key,
      };
    }
    if (selectKeys.includes(key)) {
      return {
        type: "select",
        val: `${selectTitleByKey[key]} - ${val?.[0] === null ? "Пусто" : "Значение"}`,
        key,
      };
    }
    return {
      type: "range",
      val: `${rangeTitleByKey[key]} (${
        (val as string[])[0] != null ? `от ${(val as string[])[0]}` : ""
      } ${(val as string[])[1] != null ? `до ${(val as string[])[1]}` : ""})`,
      key,
    };
  });
};

export const prepareFilter = (s: IInnerFilt) => {
  let copy = { ...s };
  Object.keys(s).forEach((k) => {
    if (typeof s[k] === "number" && !s[k]) {
      delete copy[k];
    }
  });
  return copy;
};
