import { css } from "@emotion/react";
import { device, c1_styles, p4_styles } from "theme";
import styled from "@emotion/styled";
import rgba from "polished/lib/color/rgba";
import { CusTypo } from "components/cusTypo/custom-typography";
import { Link } from "react-router-dom";

export const SItem = styled.div`
  padding: 12px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 1px 5px 22px 0px ${(props) => props.theme.mtsColor.base.rockBlueTransparent};

  @media ${device.tabletPlus} {
    padding: 32px 32px 24px 32px;
  }
`;

export const STypo = styled(CusTypo)`
  color: #000000;
`;

export const SDevicesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(98px, auto) repeat(2, 1fr);
  gap: 12px;

  & .sticky {
    margin-top: 0 !important;
  }
  & .th,
  & .tbody {
    background-color: white !important;
  }
  & .thead {
    height: 18px !important;
    margin-bottom: 16px !important;
  }
  & .tbody_tr {
    height: auto !important;
    border: none !important;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }

    .td {
      width: 100% !important;
    }
  }

  & .item {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }

  & .total {
    grid-area: total;
  }

  & .unver {
    grid-area: unver;
  }

  & .status {
    grid-area: status;
  }

  & .time {
    grid-area: time;
  }

  & .chart {
    grid-area: chart;
    min-height: 112px;

    @media ${device.tabletPlus} {
      min-height: 452px;
    }
  }

  & .ports {
    grid-area: ports;
  }

  & .name {
    grid-area: name;
  }

  & .meter--on {
    grid-area: meter--on;
  }

  & .meter--off {
    grid-area: meter--off;
  }

  & .meters {
    grid-area: meters;
  }

  grid-template-areas:
    "meters meters"
    "unver unver"
    "status time"
    "chart chart"
    "ports ports";

  @media ${device.tabletPlus} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: minmax(98px, auto) repeat(3, 1fr);
    gap: 16px;
    grid-template-areas:
      "total meter--on meter--off"
      "unver chart ports"
      "status chart ports"
      "time chart ports";
  }
`;

export const SStatus = styled.span<{ isOk?: boolean }>`
  color: ${(props) =>
    props.isOk
      ? props.theme.mtsColor.text.positive.lightMode
      : props.theme.mtsColor.text.negative.lightMode};
`;

export const MeterInfo = styled(Link)<{ color?: "green" | "red" }>`
  position: relative;
  min-height: 98px;
  min-width: 128px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 14px;
  padding: 12px;
  border-radius: 8px;
  background-color: #122840;
  white-space: pre-wrap;
  overflow: hidden;
  text-decoration: none;

  @media ${device.tabletPlus} {
    align-items: center;
    flex-flow: row nowrap;
    gap: 24px;
    padding: 24px 32px;
  }

  & span {
    flex: 1;
    color: ${(props) => props.theme.mtsColor.text.primary.darkMode};

    @media ${device.tabletPlus} {
      flex: 1 1 144px;
    }
  }

  & svg {
    display: none;

    @media ${device.tabletPlus} {
      position: absolute;
    }
  }

  ${(props) =>
    props.color === "green"
      ? css`
          background-color: ${rgba(props.theme.mtsColor.text.positive.lightMode, 0.08)};

          & span {
            color: ${props.theme.mtsColor.text.positive.lightMode};
          }
          & svg {
            right: -14px;
            bottom: -23px;
          }
        `
      : props.color === "red"
      ? css`
          background-color: ${rgba(props.theme.mtsColor.base.critical, 0.08)};
          & span {
            color: ${props.theme.mtsColor.base.critical};
          }
          & svg {
            right: -16px;
            bottom: -16px;
          }
        `
      : ""};
`;

export const SDown = styled.a`
  text-decoration: none;
  cursor: pointer;
  ${c1_styles as any};
  color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};

  @media ${device.tabletPlus} {
    ${p4_styles as any};
    color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
  }
`;

export const SWrap = styled.div`
  display: flex;
  gap: 8px;
  overflow-x: scroll;
`;

export const SInf = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-flow: row nowrap;

  @media ${device.tabletPlus} {
    align-items: flex-start;
    flex-direction: column;
  }
`;
