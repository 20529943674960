import { useMemo, useCallback, useContext } from "react";

import { IAskueManagementResponse } from "interface/askue";
import { ITableFilterClm, ITableSortColumn, Table } from "components/table/table.component";
import { tableMobStructure, tableStructure } from "./const";
import { constructColumns } from "./utils";
import { ContextSettings } from "context/context-settings";
import { MobileTable } from "components/table/mobile-table/mobileTable.component";

interface IProp {
  rawData: IAskueManagementResponse[];
  filterBy?: any;
  filterByColumn?: ITableFilterClm[];
  globalFilter?: string;
  onRowsChange?: Function;
  sortByColumn?: ITableSortColumn[];
}
export const AskueManagementTable: React.FC<IProp> = ({
  rawData,
  filterByColumn,
  globalFilter,
  onRowsChange,
  sortByColumn,
}) => {
  const { isDesktop } = useContext(ContextSettings);
  const tableData = useMemo(() => rawData ?? [{}], [rawData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRowsChange = useCallback((a) => onRowsChange?.(a), []);

  const columns = useMemo(
    () =>
      constructColumns({
        columns: tableStructure,
      }),
    [],
  );
  const mobileColumns = useMemo(() => tableMobStructure, []);

  const defaultSortBy = useMemo(
    () => [
      {
        id: "created_time",
        desc: true,
      },
    ],
    [],
  );

  return isDesktop() ? (
    <Table
      columns={columns}
      data={tableData}
      view="alternatingRows"
      filterByColumn={filterByColumn}
      globalFilter={globalFilter}
      onRowsChange={handleRowsChange}
      sortBy={defaultSortBy}
    />
  ) : (
    <MobileTable
      columns={mobileColumns}
      data={tableData}
      sortByColumn={sortByColumn}
      filterByColumn={filterByColumn}
      globalFilter={globalFilter}
    />
  );
};
