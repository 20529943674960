import styled from "@emotion/styled";

export const SActions = styled.div`
  & > ul {
    margin-bottom: 10px;
    & > li {
      padding: 10px 16px;
      cursor: pointer;
    }
  }
  & > button {
    width: 100%;
  }
`;
