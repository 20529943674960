import { isEmpty } from "lodash";
import { format } from "date-fns";

import { StatBubble } from "components/demo/demoTable/subComponents";
import { RenderFilter } from "components/table/render-filter/renderFilter.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { MTSStatus } from "components/mts-status/mts-status";
import { IResearch } from "interface/analytics";
import { groupHeaderByName, headersInfo, renderTooltip } from "./const";
import {
  RageVal,
  Favorite,
  ColorCircle,
  SCenter,
  SHeadGroup,
  SOverflow,
  Highlighter,
  SBlue,
} from "./subComponents";

const colorByInteger = {
  0: "black",
  1: "red",
  2: "yellow",
  3: "green",
  4: "blue",
};

export const constructTableData = (draft: IResearch[]) =>
  draft?.map((d) => {
    const { id_pp } = d;

    return {
      ...d,
      id: id_pp,
    };
  });

type IRef = {
  [key: string]: true;
};

export const filterColumnsByVisibility = (template: any, reference: IRef) => {
  const kek = Object.keys(reference).concat(["fav"]);
  let res: any[] = [];

  for (let i = 0; i < template.length; i++) {
    const trg = template[i]?.columns;
    if (trg != null) {
      let temp = trg?.map((a) =>
        kek.includes(a.accessor) ? a : { ...a, minWidth: 0, maxWidth: 0, isHeaderHidden: true },
      );
      const isAllInvisible = temp.every((b) => b.maxWidth != undefined);

      if (!isAllInvisible) {
        let resObj = { ...template[i], columns: temp };
        res.push(resObj);
      }
    } else {
      res.push(template[i]);
    }
  }
  return res;
};

export const renderHeader = (column, showHints) => {
  return (
    <RenderFilter
      isSorted={column.isSorted}
      isSortedDesc={column.isSortedDesc || false}
      title={headersInfo[column.id]?.title ?? ""}
      tooltip={renderTooltip(column.id)}
      showHints={showHints}
    />
  );
};

export const renderHeaderGroup = (col) => {
  const pos = col.originalId === "id_pp" ? "left" : col.originalId === "fav" ? "right" : undefined;
  return (
    <SHeadGroup pos={pos}>
      <CusTypo variant="c1_medium" font="comp">
        {groupHeaderByName[col.headerTitle]}
      </CusTypo>
    </SHeadGroup>
  );
};

export const renderCell = (val, props) => {
  switch (val) {
    case "id_pp":
      return {
        Cell: ({ value, row }) => (
          <Highlighter val={value} isSelected={props.selectedId === row.original.id} />
        ),
      };
    case "fp":
      return {
        Cell: ({ value }) => <StatBubble value={value} size="XS" />,
      };
    case "fav":
      return {
        Cell: ({ row }) => (
          <Favorite id={row.original.id} favs={props.favs} setFavs={props.setFavs} />
        ),
      };
    case "d_summer":
    case "n_summer":
    case "koef_dn":
    case "multiplicity":
    case "amplitude":
    case "cnt_04":
      return {
        Cell: RageVal,
      };
    case "t0":
    case "t1":
    case "t2":
    case "t2_dc":
    case "t1_dc":
    case "t3":
    case "t4":
    case "t5":
      return {
        Cell: ({ value }) => (
          <SCenter>
            <ColorCircle color={colorByInteger[value]} />
          </SCenter>
        ),
      };
    case "fp_dc":
    case "dt_change":
    case "dt_get":
    case "dt_send":
      return {
        Cell: ({ value }) => (value ? format(new Date(value ?? 0), "dd.MM.yyyy") : "—"),
      };
    case "score":
    case "type1":
    case "type2":
    case "type3":
    case "type4":
    case "type5":
    case "unitype":
      return {
        Cell: ({ value }) => <SBlue>{value}</SBlue>,
      };
    case "result_verify":
      return {
        Cell: ({ value }) => MTSStatus(value, "medium"),
      };
    default:
      return {
        Cell: ({ value }) => <SOverflow>{value ?? "—"}</SOverflow>,
      };
  }
};

export const constructColumns = ({ columns, selectedId, showHints, setFavs, favs }) => {
  return columns.map((singleColumn) => {
    return {
      ...singleColumn,
      Header: ({ column }) => renderHeaderGroup(column),
      columns: singleColumn.columns?.map((a) => ({
        ...a,
        ...renderCell(a.accessor, { selectedId, showHints, setFavs, favs }),
        Header: ({ column }) => renderHeader(column, showHints),
      })),
    };
  });
};

export const calcFooterNonNull = (table, accessor) =>
  table.rows.reduce((sum, row) => {
    return !row.values[accessor] ? sum : sum + 1;
  }, 0);

export const calcFooterUnique = (table, accessor) => {
  const total = table.rows.reduce(
    (sum, row) =>
      row.values[accessor] == null || sum.indexOf(row.values[accessor]) !== -1
        ? sum
        : [...sum, row.values[accessor]],
    [],
  );
  return total.length;
};

export const manualGlobFilter = (rows, columnIds, globFilt) => {
  if (globFilt.arr.length === 0 && globFilt.text.length === 0) {
    return rows;
  }
  const res = !isEmpty(globFilt.arr)
    ? rows.filter((a) => {
        const { id_pp } = a.original;
        return id_pp && globFilt.arr.includes(id_pp);
      })
    : rows;

  return globFilt.text.length > 0
    ? res.filter((b) => {
        return JSON.stringify(b.original).includes(globFilt.text);
      })
    : res;
};
