import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material";

import App from "components/app";
import theme from "theme";
import { Mocker } from "utils/mocker.component";
import ReactQueryWrapper from "react-query-wrapper";
import { ContextProvider } from "context";

import "./index.scss";
import "react-toastify/dist/ReactToastify.min.css";

ReactDOM.render(
  <ContextProvider>
    <ThemeProvider theme={theme}>
      <ReactQueryWrapper>
        <Router>
          <App />
          <ToastContainer
            position={"bottom-right"}
            autoClose={false}
            pauseOnHover
            closeOnClick
            closeButton={false}
            draggable={false}
            theme="dark"
          />
          {process.env.NODE_ENV === "development" ? <Mocker /> : null}
        </Router>
      </ReactQueryWrapper>
    </ThemeProvider>
  </ContextProvider>,
  document.getElementById("root"),
);
