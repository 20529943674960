import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Icon from "components/icons";
import { p4_styles } from "theme";

const useStyles = makeStyles((theme: Theme) => ({
  datepicker: {
    width: "100%",
    fontWeight: 400,
    ...p4_styles,
    color: theme.mtsColor.text.primary.lightMode,
    outline: "none",
    padding: "12px 36px 12px 12px",
    border: "2px solid rgba(188, 195, 208, 0.5)",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    transition: "0.2s all ease-out",
    lineHeight: "1",

    "&:focus": {
      borderColor: theme.mtsColor.text.primaryLink.lightMode,
    },

    "&::placeholder": {
      color: theme.mtsColor.text.tertiary.lightMode,
    },

    "&:disabled": {
      backgroundColor: theme.mtsColor.background.secondary.lightMode,
      color: theme.mtsColor.grey.greyMedium,
    },
  },

  customField: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 6px 10px 12px",
    border: "1px solid rgba(188, 195, 208, 0.5)",
    backgroundColor: "#fff",
    borderRadius: "8px",
    minWidth: "100px",
  },
  disabled: {
    backgroundColor: "#F2F3F7",
  },
  error: {
    borderColor: theme.mtsColor.accent.negative.lightMode,
  },
  sizeS: {
    height: "32px",
  },
  sizeM: {
    height: "44px",
  },
  sizeL: {
    height: "52px",
  },
  valueField: {
    fontWeight: 400,
    ...p4_styles,
    color: theme.mtsColor.text.primary.lightMode,
  },
  placeholderField: {
    fontWeight: 400,
    ...p4_styles,
    color: theme.mtsColor.text.tertiary.lightMode,
  },
  datePickerWrapper: {
    textAlign: "left",

    "& .react-datepicker-popper": {
      zIndex: 5,
    },

    "& .react-datepicker": {
      boxShadow: "rgb(0 0 0 / 12%) 0px 4px 24px, rgb(0 0 0 / 14%) 0px 12px 20px",
      border: "none",
      borderRadius: "16px",
      padding: "12px",

      "&__triangle": {
        display: "none",
      },
      "&__header": {
        backgroundColor: theme.mtsColor.background.primaryElevated.lightMode,
        borderTopLeftRadius: "16px",
        borderTopRightRadius: "16px",
        borderBottom: "none",
        padding: "0",
      },
      "&__navigation": { top: "21px" },
      "&__day, &__month-text, &__quarter-text, &__year-text": {
        color: theme.mtsColor.text.primary.lightMode,
        fontSize: "16px",
        lineHeight: "32px",
        minWidth: "32px",
        width: "auto",
        height: "32px",
        "&--today ": {
          color: theme.mtsColor.text.primary.lightMode,
          backgroundColor: "transparent",
          fontWeight: 700,
        },
      },
      "&__day, &__month": {
        "&--selected": {
          color: theme.mtsColor.background.primaryElevated.lightMode,
          backgroundColor: theme.mtsColor.text.primary.lightMode,
          borderRadius: "6px",
          "&:hover": {
            backgroundColor: "transparent",
            color: theme.mtsColor.text.primary.lightMode,
          },
        },
      },
      "&__day--keyboard-selected, &__month--keyboard-selected": {
        color: theme.mtsColor.text.primary.lightMode,
        backgroundColor: "transparent",
        borderRadius: "6px",
      },
      "&__current-month": {
        padding: "10px 0",
        color: theme.mtsColor.text.primary.lightMode,
        fontSize: "16px",
        lineHeight: "1.57",
        fontWeight: "500",
      },
      "&__day--outside-month": {
        opacity: 0,
        visibility: "hidden",
      },
      "&__day-name": {
        color: theme.mtsColor.text.primary.lightMode,
        fontSize: "16px",
        lineHeight: "32px",
        width: "32px",
        height: "32px",
      },
      "&__day-names": {
        marginBottom: "0",
      },
      "&__month": {
        margin: "0",
        "&-container": {
          minWidth: "150px",
        },
      },
    },
    "& .react-datepicker__close-icon:after": {
      content: '""',
      backgroundImage: `${Icon.Close}`,
      backgroundColor: "transparent",
    },
  },
  inputCalendar: {
    position: "relative",
  },
  icon: {
    color: theme.mtsColor.icons.secondary.lightMode,
    fontSize: 24,
    cursor: "pointer",
  },
}));

export default useStyles;
