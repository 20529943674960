import { useContext } from "react";
import { isEmpty } from "lodash";

import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { Spacer } from "components/spacer/spacer.component";
import { useMiningInfo } from "hooks/api/map";
import { Loader } from "components/loader/loader";
import { MiningSummary } from "components/mining/summary/miningSummary";
import { CusTypo } from "components/cusTypo/custom-typography";
import { ContextSettings } from "context/context-settings";
import { links_root } from "./const";
import { SSection } from "pages/askue/styles";
import { NotifyBlock } from "components/notification-block/notification-block";

export const MiningPage = () => {
  const { isDesktop } = useContext(ContextSettings);
  const info = useMiningInfo();

  return (
    <>
      <SSection>
        <Breadcrumbs links={links_root} />
        <div>
          <CusTypo variant={isDesktop() ? "h2_medium" : "h4_medium"} font="wide">
            Общая информация
          </CusTypo>
          <Spacer value="48px" />
        </div>
        {info.isSuccess && !isEmpty(info.data.miners) ? (
          <MiningSummary data={info.data} />
        ) : info.isSuccess && isEmpty(info.data.miners) ? (
          <NotifyBlock content="Нет данных для отображения общей информации" status="warning" />
        ) : info.isLoading ? null : (
          <NotifyBlock content="Произошла ошибка запроса общей информации" status="error" />
        )}
      </SSection>
      {info.isLoading ? <Loader isFullPage /> : null}
    </>
  );
};
