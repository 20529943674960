import { useContext, useMemo, useState } from "react";
import { ResizableBox } from "react-resizable";

import { Table } from "components/table/table.component";
import { constructTableData, filterColumnsByVisibility } from "../researchTable/utils";
import { IResearch } from "interface/analytics";
import { ContextSettings } from "context/context-settings";
import { tableStructure } from "./const";
import { constructColumns } from "./utils";

interface IProp {
  rawData: IResearch[];
  globalFilter?: string | any;
  onRowClick: (a: any) => void;
  rowSelected: number;
  columnVisibility: { [key: string]: true };
}
export const GeoTable: React.FC<IProp> = ({
  rawData,
  globalFilter,
  onRowClick,
  rowSelected,
  columnVisibility,
}) => {
  const { isDesktop } = useContext(ContextSettings);
  const [tableHeight, setTableHeight] = useState(365);
  const tableData = useMemo(() => constructTableData(rawData), [rawData]);

  const columns = useMemo(
    () =>
      constructColumns({
        columns: filterColumnsByVisibility(tableStructure, columnVisibility),
        selectedId: rowSelected,
      }),
    [rowSelected, columnVisibility],
  );

  const handleRowClick = (v) => {
    onRowClick(v);
  };

  return isDesktop() ? (
    <ResizableBox
      height={tableHeight}
      minConstraints={[160, 160]}
      maxConstraints={[300, 700]}
      axis="y"
      onResize={(e, somedata) => setTableHeight(somedata.size.height)}
    >
      <span>
        <Table
          columns={columns}
          data={tableData}
          view="sheets"
          onRowClick={handleRowClick}
          globalFilter={globalFilter}
          initSelRow={{ "0": true }}
          height={tableHeight}
          headerHeight={48}
          withFooter
        />
      </span>
    </ResizableBox>
  ) : (
    <Table
      columns={columns}
      data={tableData}
      view="sheets"
      onRowClick={handleRowClick}
      globalFilter={globalFilter}
      initSelRow={{ "0": true }}
      height={300}
      headerHeight={48}
      withFooter
    />
  );
};
