import styled from "@emotion/styled";
import { SModalTitle } from "components/modal-notify-body/styles";
import { device } from "theme";
export const SUserBlock = styled.div`
  text-align: center;

  & > ${SModalTitle} {
    margin-bottom: 8px;

    @media ${device.tabletPlus} {
      margin-bottom: 24px;
    }
  }
`;

export const SForm = styled.form`
  margin-top: 21px;
`;

export const SFormField = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

export const SRoleText = styled.div`
  & .MuiTypography-root {
    color: ${(props) => props.theme.mtsColor.text.secondary.darkMode};
  }
`;

export const SBtns = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 12px;
  margin-left: 0;

  & .MuiButtonBase-root {
    min-width: 287px;
  }
`;
