import { useRef } from "react";
import { UseMutationResult } from "react-query/types/react";
import { toast } from "react-toastify";

import { MTSButton } from "components/mts-button/mts-button";
import { Toast } from "components/toast/toast";
import { SInp } from "./styles";
import Icon from "components/icons";

interface UploadFileProps<T> {
  textBtn: string;
  hook: () => UseMutationResult<T, any, FormData, unknown>;
  callback?: (a?: any) => void;
}

export const UploadFileBtn = <T extends object>(props: UploadFileProps<T>) => {
  const { callback, hook, textBtn } = props;
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const mutation = hook();

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleUpload = (e) => {
    const fileUploaded = e.target.files[0];
    let formData = new FormData();
    formData.append("file", fileUploaded);
    mutation.mutate(formData, {
      onSuccess: (response) => {
        //@ts-ignore
        hiddenFileInput.current.value = null;
        callback?.(response);
        toast(<Toast title="Файл успешно загружен" />, {
          progress: undefined,
          autoClose: 2000,
          hideProgressBar: true,
        });
      },
      onError: () => {
        //@ts-ignore
        hiddenFileInput.current.value = null;
        toast(
          <Toast title={(mutation?.error as any).message ?? "Попробуйте загрузить другой файл"} />,
          {
            progress: undefined,
            autoClose: 2000,
            hideProgressBar: true,
          },
        );
      },
    });
  };

  return (
    <>
      <form encType="multipart/form-data">
        <SInp type="file" onChange={handleUpload} ref={hiddenFileInput} accept=".xlsx" />
        <MTSButton
          size="S"
          type="button"
          variant="secondary"
          onClick={handleClick}
          icon={<Icon.Plus sx={{ width: 16, height: 16 }} />}
        >
          {textBtn}
        </MTSButton>
      </form>
    </>
  );
};
