import { useContext, useState } from "react";

import { ContextSettings } from "context/context-settings";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { Footer } from "components/footer/footer";
import { Loader } from "components/loader/loader";
import { useResearchHandbook } from "hooks/api/analytics";
import { HandbookTable } from "components/analytics-research/handbookTable/handbookTable.component";
import { Select } from "components/select/select";
import { Spacer } from "components/spacer/spacer.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { handbookLinks, handbook_options } from "./const";
import { SSelect, STitle, STop, SWrap } from "./styles";

export const AnalyticsHandbook = () => {
  const { isDesktop } = useContext(ContextSettings);
  const [selected, setSelected] = useState("1");
  const { data, isSuccess, isLoading, isError } = useResearchHandbook(selected);

  return (
    <>
      <SWrap>
        <Breadcrumbs links={handbookLinks} />
        <STop>
          <STitle>
            <CusTypo variant={isDesktop() ? "h2_medium" : "h4_medium"} font="wide">
              Справочник оценок
            </CusTypo>
          </STitle>
          <SSelect>
            <CusTypo variant="p4_medium">Территориальный признак РЭС</CusTypo>
            <Select
              initValue="Городские"
              size="S"
              type="normal"
              optionsList={handbook_options}
              onChange={(e) => {
                setSelected(e);
              }}
            />
          </SSelect>
        </STop>
        <Spacer value="48px" />
        {isLoading ? (
          <Loader />
        ) : isSuccess && (data ?? [])?.length > 0 ? (
          <HandbookTable rawData={data ?? []} />
        ) : isError ? (
          <CusTypo variant="h3_medium">Не удалось загрузить справочник</CusTypo>
        ) : null}
      </SWrap>
      <Footer />
    </>
  );
};
