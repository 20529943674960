import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Navi = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4310_43126)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.48466 12.513C10.0098 13.0381 10.2334 13.741 10.3275 13.9882L11.757 17.7453L15.9081 8.75066C16.8903 6.62241 17.5318 5.22122 17.836 4.22613C17.8461 4.19309 17.8555 4.16129 17.8644 4.1307C17.8339 4.13958 17.8021 4.14905 17.7691 4.15914C16.7741 4.46328 15.3729 5.10486 13.2447 6.08709L4.24859 10.2391L8.00942 11.6701C8.25667 11.7642 8.95955 11.9878 9.48466 12.513ZM7.29816 13.5394C7.7119 13.6968 7.91877 13.7755 8.07043 13.9272C8.22208 14.0788 8.30079 14.2857 8.45822 14.6995L9.97408 18.6835C10.6051 20.3419 12.7021 20.4702 13.4457 18.8591L17.724 9.58873C19.6334 5.45137 20.5881 3.38269 19.6003 2.39482C18.6125 1.40696 16.5438 2.36169 12.4066 4.27117L3.13653 8.54961C1.5247 9.29352 1.65308 11.3914 3.31225 12.0227L7.29816 13.5394Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_4310_43126">
          <rect
            width="21.8182"
            height="21.8182"
            fill="white"
            transform="translate(0.0908813 0.0908203)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Navi;
