import styled from "@emotion/styled";
import { device } from "theme";

export const SWrap = styled.div`
  max-width: 100%;
  padding: 0 40px;
  text-align: left;
`;

export const SRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  column-gap: 88px;
  row-gap: 16px;
  align-items: center;

  & > span {
    white-space: nowrap;
  }

  & .searchField {
    flex: 1 1 auto;
  }

  @media ${device.tabletPlus} {
    & .searchField {
      flex: 0 1 500px;
    }
  }
`;

export const SGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  column-gap: 32px;
  row-gap: 16px;
`;
