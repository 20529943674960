import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { CusTypo } from "components/cusTypo/custom-typography";

export const SCheck = styled.span<{ checked?: boolean; hasError?: boolean; size?: string }>`
  position: relative;
  width: 24px;
  height: 24px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;

  & .checkbox__icon {
    position: absolute;
  }
`;

export const SCheckbox = styled.label<{ size?: string; labelLeft?: boolean }>`
  min-width: 24px;
  width: auto;
  min-height: 42px;
  height: auto;
  max-height: 42px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.labelLeft &&
    css`
      flex-direction: row-reverse;
    `};
`;

export const SCheckInput = styled.input`
  display: none;
`;

export const SLabel = styled(CusTypo)<{
  labelLeft?: boolean;
  labelWrap?: string;
}>`
  min-width: 0;
  min-height: 0;
  display: inline-block;
  ${(props) => (props.labelLeft ? "margin-right: 8px;" : "margin-left: 8px")};
  line-height: normal;
  color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
  white-space: ${(props) => props.labelWrap};
  user-select: none;
`;
