import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import theme from "theme";

import { IUserResponse } from "interface/management";
import { EEventObjectType } from "interface/management";
import { MTSModal } from "components/mts-modal/mts-modal";
import { FormDeleteUser } from "components/forms/user/form-delete-user";
import { FormEditUser } from "components/forms/user/form-edit-user";
import { ContextSettings } from "context/context-settings";
import { getEventItemUrl } from "components/management-events/eventsTable/utils";
import { CusTypo } from "components/cusTypo/custom-typography";
import { LIST_ROLES } from "utils/roles";
import { useUpdateTenant } from "hooks/api/management";
import { makeManagUserInfo, userTitleByKey } from "../utils";
import { UserMobCard } from "./subcomponents";
import { Spacer } from "components/spacer/spacer.component";
import { CopyToClipboard } from "components/copyToClipboard/copyToClipboard.component";
import { SPage } from "./styles";

export const MobUsers = ({ data }: { data: IUserResponse }) => {
  const navigate = useNavigate();
  const { isDesktop, roles: contextRoles } = useContext(ContextSettings);
  const isAdmin = contextRoles.includes(LIST_ROLES.ADMIN);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const toggleShowModalDelete = () => setShowModalDelete(!showModalDelete);
  const toggleShowModalEdit = () => setShowModalEdit(!showModalEdit);
  const mutation = useUpdateTenant();

  const editFunc = {
    title: "Редактировать",
    onClick: toggleShowModalEdit,
  };

  const delFunc = {
    title: "Удалить",
    onClick: toggleShowModalDelete,
    color: theme.mtsColor.red,
  };

  const navFunc = {
    title: "Перейти к организации",
    onClick: () =>
      navigate(
        `/${getEventItemUrl(EEventObjectType.tenant_id, data?.tenant?.id ?? "", !isDesktop())}`,
        {
          state: { e_object_id: data?.tenant?.id },
        },
      ),
  };

  const isOwner = data.roles.some((role) => {
    return role.name === LIST_ROLES.OWNER;
  });

  const dataPopup = [editFunc]
    .concat(
      isOwner && !data.isResponsible && data?.tenant != null
        ? {
            title: "Назначить ответственным",
            onClick: () =>
              mutation.mutate({
                data: {
                  additional_info: data.userName,
                },
                id: data.tenant!.id,
              }),
          }
        : [],
    )
    .concat(isAdmin ? [navFunc] : [])
    .concat([delFunc]);

  return (
    <>
      <SPage>
        <UserMobCard data={data} dataPopup={dataPopup} />
        <Spacer value="24px" />
        <article>
          <ul>
            {Object.entries(makeManagUserInfo(data)).map(([key, val], index) => (
              <li key={index}>
                <CusTypo variant="c1_regular">{userTitleByKey[key]}</CusTypo>
                {val["isCanCopy"] ? (
                  <CopyToClipboard text={val.value as any} />
                ) : (
                  <CusTypo variant="c1_medium">{val.value}</CusTypo>
                )}
              </li>
            ))}
          </ul>
        </article>
      </SPage>
      <MTSModal open={showModalDelete} close={toggleShowModalDelete}>
        <FormDeleteUser onCancel={toggleShowModalDelete} id={data.id} userData={data} />
      </MTSModal>
      <MTSModal open={showModalEdit} close={toggleShowModalEdit}>
        <FormEditUser onCancel={toggleShowModalEdit} id={data.id} />
      </MTSModal>
    </>
  );
};
