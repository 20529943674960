export const groupHeaderByName = {
  podrazdel: "Подразделение",
  by_id: "По ID_pp",
  by_fp: "По вероятности",
  score: "Скор BD=>0.5",
  total: "Итого",
};

export const subheaders = {
  name_po: "Группа",
  name_res: "Наименование РЭС",
  cnt_use: "Шт.",
  cnt_send: "Передано",
  cnt_get: "Проверено",
  cnt_fp_high: "Высокая",
  cnt_fp_fraud: "Фрод",
  cnt_ml_score_05: "Средняя",
  cnt_all: "Всего",
  per_use: "%",
};

export const infoByKey = {
  cnt_res: "Всего РЭС",
  cnt_res_es: 'РЭС "Энергосфера"',
  cnt_city: "Городские",
  cnt_village: "Сельские",
  cnt_mixed: "Смешанные",
  cnt_addr_yes: "Привязано",
  cnt_addr_not: "Не привязано",
  cnt_fl: "Физические лица",
  cnt_ul: "Юридические лица",
  cnt_fu_not: "Не привязано",
};

export const tableStructure = [
  {
    accessor: "id",
    minWidth: 0,
    maxWidth: 0,
  },
  {
    accessor: "id_res",
    minWidth: 0,
    maxWidth: 0,
  },
  {
    id: "podrazdel",
    headerTitle: "podrazdel",
    columns: [
      {
        accessor: "name_po",
        minWidth: 80,
        width: 80,
      },
      {
        accessor: "name_res",
        minWidth: 154,
        width: 154,
      },
    ],
  },
  {
    id: "by_id",
    headerTitle: "by_id",
    columns: [
      {
        accessor: "cnt_all",
        minWidth: 68,
        width: 68,
      },
      {
        accessor: "cnt_send",
        minWidth: 90,
        width: 90,
      },
      {
        accessor: "cnt_get",
        minWidth: 102,
        width: 102,
      },
    ],
  },
  {
    id: "by_fp",
    headerTitle: "by_fp",
    columns: [
      {
        accessor: "cnt_fp_high",
        minWidth: 100,
        width: 100,
      },
      {
        accessor: "cnt_fp_fraud",
        minWidth: 82,
        width: 82,
      },
    ],
  },
  {
    id: "score",
    headerTitle: "score",
    columns: [
      {
        accessor: "cnt_ml_score_05",
        minWidth: 100,
        width: 100,
      },
    ],
  },
  {
    id: "total",
    headerTitle: "total",
    columns: [
      {
        accessor: "cnt_use",
        minWidth: 58,
        width: 58,
      },
      {
        accessor: "per_use",
        minWidth: 52,
        width: 52,
      },
    ],
  },
];
