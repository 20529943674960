import { uniqBy } from "lodash";
import { format, parseISO } from "date-fns";

import { IOption } from "components/select/select";
import { IBreadcrumbsLink } from "interface/common";
import { IDeviceList } from "interface/anomalies";
import { DEPARTURE_RESULT } from "stream-constants/keys-and-constants";
import { ROUTE_ANOMALIES } from "stream-constants/route-constants";

export const links: IBreadcrumbsLink[] = [{ name: "Аномалии", href: `/${ROUTE_ANOMALIES}` }];

export const EFF_HEADERS = {
  eff_prc: "Общая эффективность выявления\nкоммерческих потерь",
  eff_prc_by_dt: "Эффективность\nза выбранный период",
};

export const PO_COLUMN = "list_po";
export const RES_COLUMN = "list_res";
export const FILTER_BY_STATUS = "telebot_request_last_result";
export const FILTER_BY_CODE = "state_code";
export const SCHEME_ANOMALIES = { checkbox: {}, radio: {} };
export const TABLE_FILTER_DEFAULT_ANOMALIES = {
  [PO_COLUMN]: [],
  [RES_COLUMN]: [],
};

export const TABLE_SORT_DEFAULT_DEVICES = [
  {
    accessor: "factory_count_num",
    text: "Серийный номер",
  },
  {
    accessor: "adress_norm",
    text: "Адрес",
  },
  {
    accessor: RES_COLUMN,
    text: "РЭС",
  },
  {
    accessor: "telebot_request_last_result",
    text: "Статус",
  },
  {
    accessor: "telebot_request_last_dt",
    text: "Дата изменения",
  },
];

export const TABLE_FILTER_DEFAULT_DEVICES = {
  ...TABLE_FILTER_DEFAULT_ANOMALIES,
  [FILTER_BY_STATUS]: [],
};

const anomaliesCardsTemplate: Array<{
  title: string;
  number: number;
  variant:
    | "toCheck"
    | "normal"
    | "hacking"
    | "suspicion"
    | "noLoad"
    | "noAccess"
    | "defective"
    | "replaced";
  value: DEPARTURE_RESULT;
}> = [
  {
    title: "К проверке",
    number: 0,
    variant: "toCheck",
    value: DEPARTURE_RESULT.TO_CHECK,
  },
  {
    title: "Нормальный",
    number: 0,
    variant: "normal",
    value: DEPARTURE_RESULT.NORMAL,
  },
  {
    title: "Взлом",
    number: 0,
    variant: "hacking",
    value: DEPARTURE_RESULT.BREAKING,
  },
  {
    title: "Подозрение",
    number: 0,
    variant: "suspicion",
    value: DEPARTURE_RESULT.SUSPICION,
  },
  {
    title: "Неисправен",
    number: 0,
    variant: "defective",
    value: DEPARTURE_RESULT.DEFECTIVE,
  },
  {
    title: "Заменен",
    number: 0,
    variant: "replaced",
    value: DEPARTURE_RESULT.REPLACED,
  },
  {
    title: "Нет нагрузки",
    number: 0,
    variant: "noLoad",
    value: DEPARTURE_RESULT.NO_LOAD,
  },
  {
    title: "Нет доступа",
    number: 0,
    variant: "noAccess",
    value: DEPARTURE_RESULT.NO_ACCESS,
  },
];

export const HARDCODE_STATUSES = [
  {
    id: 0,
    name: DEPARTURE_RESULT.TO_CHECK,
  },
  {
    id: 1,
    name: DEPARTURE_RESULT.NORMAL,
  },
  {
    id: 2,
    name: DEPARTURE_RESULT.BREAKING,
  },
  {
    id: 3,
    name: DEPARTURE_RESULT.SUSPICION,
  },
  {
    id: 4,
    name: DEPARTURE_RESULT.NO_LOAD,
  },
  {
    id: 5,
    name: DEPARTURE_RESULT.NO_ACCESS,
  },
  {
    id: 6,
    name: DEPARTURE_RESULT.DEFECTIVE,
  },
  {
    id: 7,
    name: DEPARTURE_RESULT.REPLACED,
  },
];

const getNumberOfCountersType = (nameResult: string, targetArr: IDeviceList[] | []) =>
  targetArr.filter((item: IDeviceList) => item.telebot_request_last_result === nameResult).length;

export const makeCards = (devices) =>
  anomaliesCardsTemplate.map((a) => ({ ...a, number: getNumberOfCountersType(a.title, devices) }));

const hardcode_drop_item = [
  {
    val: "",
    friendlyName: "Текущий список",
  },
];

export const makeArchiveDropdown = (a: (string | null)[]): IOption[] => {
  return hardcode_drop_item.concat(
    uniqBy(
      a
        ?.filter((b) => b != null)
        .map((raw, i) => {
          const kek = parseISO(raw!);
          return {
            val: format(kek, "yyyy-MM-dd'T'HH:mm:ss.SSS"),
            friendlyName: format(kek, "dd.MM.yyyy HH:mm"),
          };
        }),
      "val",
    ),
  );
};

export const defaultPeriod = { dt_from: undefined, dt_to: undefined };
