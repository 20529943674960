import { memo, useContext } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { Bar } from "react-chartjs-2";

import { ContextSettings } from "context/context-settings";
import { IAskueServer } from "interface/askue";
import { Spacer } from "components/spacer/spacer.component";
import { calcWorktime, makeChartData, makeOptions } from "./utils";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SGrid, SStatus } from "./styles";
import { SItem, STypo } from "../summary/styles";

ChartJS.register(
  CategoryScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LinearScale,
  TimeScale,
);

interface IProp {
  data: IAskueServer;
}

export const AskueServer: React.FC<IProp> = memo(({ data }) => {
  const { isMobile } = useContext(ContextSettings);

  return (
    <>
      <SGrid>
        <SItem className="item time">
          <CusTypo variant={!isMobile() ? "h4_regular" : "c1_regular"}>Время работы</CusTypo>

          <STypo variant={!isMobile() ? "h2_medium" : "h4_medium"} font="wide">
            <SStatus isOk={Boolean(data.activity_time)}>{calcWorktime(data.activity_time)}</SStatus>
          </STypo>
        </SItem>
        <SItem className="item sess">
          <CusTypo variant={!isMobile() ? "h4_regular" : "c1_regular"}>Активные сессии</CusTypo>

          <STypo variant={!isMobile() ? "h2_medium" : "h4_medium"} font="wide">
            {data.activity_sessions}
          </STypo>
        </SItem>
        <SItem className="item name">
          <CusTypo variant={!isMobile() ? "h4_regular" : "c1_regular"}>Имя сервера</CusTypo>
          <STypo variant={!isMobile() ? "h2_medium" : "h4_medium"} font="wide">
            {data.server_name}
          </STypo>
        </SItem>
        <SItem className="item version">
          <CusTypo variant={!isMobile() ? "h4_regular" : "c1_regular"}>
            Версия програмного комплекса
          </CusTypo>

          <STypo variant={!isMobile() ? "h2_medium" : "h4_medium"} font="wide">
            {data.version_pk}
          </STypo>
        </SItem>
        <SItem className="chart">
          <CusTypo variant={!isMobile() ? "h4_regular" : "c1_regular"}>
            Отчет о работе сервера
          </CusTypo>
          <Spacer value="32px" />
          <div>
            <Bar
              options={makeOptions() as any}
              data={makeChartData(data.report_server_activity) as any}
              height={84}
            />
          </div>
        </SItem>
      </SGrid>
    </>
  );
});
