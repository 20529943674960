import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ContextSettings } from "context";

import { ROUTE_PERSONAL_AREA, ROUTE_USER_AGREEMENT } from "stream-constants/route-constants";
import { CusTypo } from "components/cusTypo/custom-typography";
import { MAIL_SUPPORT } from "services/urls";

import useStyles from "./styles";

interface LinkItem {
  id: number;
  name: string;
  path: string;
}
interface FooterProps {
  isDarkTheme?: boolean;
}

const otherLinks: LinkItem[] = [
  {
    id: 0,
    name: "Обработка персональных данных",
    path: `/${ROUTE_USER_AGREEMENT}`,
  },
  {
    id: 1,
    name: "Связаться с нами",
    path: MAIL_SUPPORT,
  },
];

export const Footer = ({ isDarkTheme }: FooterProps) => {
  const [currentYear] = useState<number>(new Date().getFullYear());
  const { isAuthorized } = useContext(ContextSettings);

  const classes = useStyles();

  return (
    <footer
      className={`${classes.footer} ${isDarkTheme ? classes.footerDark : ""} ${
        !isAuthorized ? classes.footerFull : ""
      }`}
    >
      <div className={classes.container}>
        <div className={classes.block}>
          <CusTypo
            variant="p4_regular"
            className={`${classes.сopyrightText} ${isDarkTheme ? classes.сopyrightWhiteText : ""}`}
          >
            © {currentYear} EnergyTool. Все права защищены
          </CusTypo>
          {isAuthorized ? (
            <Link
              className={classes.agreementLink}
              to={`/${ROUTE_PERSONAL_AREA}/${ROUTE_USER_AGREEMENT}`}
            >
              <CusTypo variant="c1_regular" className={classes.agreementText}>
                Обработка персональных данных
              </CusTypo>
            </Link>
          ) : null}
          {!isAuthorized ? (
            <ul className={classes.otherLinks}>
              {otherLinks.map((link: LinkItem) => {
                if (link.id === 0) {
                  return (
                    <li className={classes.otherLinkItem} key={link.name}>
                      <Link className={classes.otherLink} to={link.path}>
                        <CusTypo variant="p4_regular" className={classes.otherLinkText}>
                          {link.name}
                        </CusTypo>
                      </Link>
                    </li>
                  );
                } else {
                  return (
                    <li className={classes.otherLinkItem} key={link.name}>
                      <a className={classes.otherLink} href={link.path}>
                        <CusTypo variant="p4_regular" className={classes.otherLinkText}>
                          {link.name}
                        </CusTypo>
                      </a>
                    </li>
                  );
                }
              })}
            </ul>
          ) : null}
        </div>
      </div>
    </footer>
  );
};
