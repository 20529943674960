import { Fragment, useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { uniq, isEmpty } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import formatISO from "date-fns/formatISO";

import { useCreateCheckTask, useGetEmployees } from "hooks/api/map";
import { Toast } from "components/toast/toast";
import { Spacer } from "components/spacer/spacer.component";
import { MTSAutocomplete } from "components/mts-autocomplete/mts-autocomplete";
import { MTSButton } from "components/mts-button/mts-button";
import { InputDatePicker } from "components/mts-datepicker/mts-datepicker";
import { Spinner } from "components/spinner";
import Icon from "components/icons";
import { IFormDepartures } from "components/map/const";
import { employeesId, formDefaultEmpty, removeAllDevices, schema } from "./validation-schema";
import { SControls, SFormCheck, SSpinnerWrap } from "./styles";

interface IProp {
  form: IFormDepartures[] | null;
  devices?: string[];
  onClose: () => void;
  onSuccess: () => void;
}

export const FormDepartures: React.FC<IProp> = ({ form, devices, onClose, onSuccess }) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    trigger,
    getValues,
  } = useForm<any>({
    resolver: yupResolver(schema),
    mode: "onTouched",
    shouldFocusError: false,
    reValidateMode: "onChange",
  });

  const watchDevicesList = watch("devicesList")?.some((a) => a.name.length === 0);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "devicesList",
  });

  const handleCloseForm = () => {
    onClose();
    reset(formDefaultEmpty);
    remove(removeAllDevices());
  };

  const employees = useGetEmployees();
  const employeesList =
    employees.data && Array.isArray(employees.data) ? employees.data?.map((item) => item.name) : [];
  const noDuplOptions = devices?.filter(
    (a) =>
      !getValues()
        .devicesList?.map((b) => b.name)
        ?.includes(a),
  );

  const mutation = useCreateCheckTask();
  const onSubmit = async (submData) => {
    const devicesIdPP = submData.devicesList
      .map((item) => +item.name.split(",")[0])
      .filter((item) => item !== 0);

    mutation.mutate(
      {
        deadLineDate: formatISO(new Date(submData.deadLineDate)),
        idPP: uniq(devicesIdPP),
        employeeId: employeesId(employees, submData),
      },
      {
        onSuccess: () => {
          handleCloseForm();
          toast(<Toast title="Успешно назначено" />, {
            progress: undefined,
            autoClose: 1500,
            hideProgressBar: true,
          });
          onSuccess();
        },
        onError: () => {
          handleCloseForm();
          toast(<Toast title="Проверка не назначена" isError />, {
            progress: undefined,
            autoClose: 1500,
            hideProgressBar: true,
          });
        },
      },
    );
  };

  useEffect(() => {
    if (form && !isEmpty(form)) {
      form.forEach((a) => {
        const { id_pp, adress_too } = a;
        append({
          name: `${id_pp}, ${adress_too}`,
        });
      });
      trigger("devicesList");
    } else {
      reset({
        devicesList: [{ name: "" }],
        deadLineDate: new Date(),
      });
    }
  }, [append, trigger, form, reset]);

  return (
    <SFormCheck>
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field, index) => (
          <Fragment key={field.id}>
            <MTSAutocomplete
              label="Устройство"
              placeholder="Выберите точку или введите адрес, серийник, id_pp"
              options={noDuplOptions}
              size="M"
              control={control}
              {...register(`devicesList.${index}.name`, { required: index === 0 })}
              errorMessage={
                watchDevicesList
                  ? "Не выбраны устройства"
                  : errors?.devicesList?.[index]?.name?.message
              }
              isCanDelete={index !== 0}
              onDelete={() => remove(index)}
              ref={null}
            />
            <Spacer value="24px" />
            {index === fields.length - 1 && (
              <MTSButton
                size="S"
                variant="secondary"
                onClick={() => {
                  append({
                    name: "",
                  });
                  trigger("devicesList");
                }}
                icon={<Icon.Plus sx={{ width: 14, height: 14 }} />}
                disabled={index !== 0 && watchDevicesList}
              >
                Новое устройство
              </MTSButton>
            )}
          </Fragment>
        ))}
        <Spacer value="24px" />
        <Controller
          control={control}
          name="deadLineDate"
          render={({ field: { onChange, value } }) => {
            return (
              <InputDatePicker
                label="Выберите крайний срок завершения проверки"
                onSelect={onChange}
                defaultVal={value ?? new Date()}
                size="M"
                placeholder="ДД.ММ.ГГ"
                errorMessage={errors?.deadLineDate?.message}
                portalId="datePickerWrap"
              />
            );
          }}
        />

        <Spacer value="24px" />
        <MTSAutocomplete
          label="Ответсвенный"
          placeholder="Выберите сотрудника"
          options={employeesList}
          size="M"
          control={control}
          {...register("user")}
          errorMessage={errors?.user?.message}
        />
        <Spacer value="48px" />
        <SControls>
          <MTSButton size="M" variant="primary" type="submit" onClick={() => {}}>
            Подтвердить
          </MTSButton>
          <MTSButton size="M" variant="secondary" onClick={handleCloseForm}>
            Отмена
          </MTSButton>
        </SControls>
      </form>
      {mutation.isLoading ? (
        <SSpinnerWrap>
          <Spinner />
        </SSpinnerWrap>
      ) : null}
    </SFormCheck>
  );
};
