import { useContext, useState } from "react";
import { isEmpty } from "lodash";

import { useAnalStatInfo, useAnalStatRes } from "hooks/api/analytics";
import { ContextSettings } from "context/context-settings";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { CusTypo } from "components/cusTypo/custom-typography";
import { Footer } from "components/footer/footer";
import { AnalStatTable } from "components/analytics-research/statTable/statTable.component";
import { StatBlock } from "components/analytics-research/statTable/subComponents";
import { Spacer } from "components/spacer/spacer.component";
import { Loader } from "components/loader/loader";
import { statLinks } from "./const";
import { SStat, STop, SWrap } from "./styles";

export const AnalyticsStat = () => {
  const { isDesktop } = useContext(ContextSettings);
  const [selected, setSelected] = useState<number>(0);

  const { data: stat, isLoading: isStatLoading } = useAnalStatRes();
  const { data: info, isLoading: isInfoLoading } = useAnalStatInfo();

  return (
    <>
      <SWrap>
        <Breadcrumbs links={statLinks} />
        <STop>
          <CusTypo variant={isDesktop() ? "h2_medium" : "h4_medium"} font="wide">
            Статистика РЭС
          </CusTypo>
        </STop>
        <SStat>
          <div>
            <AnalStatTable rawData={stat ?? []} onRowClick={setSelected} rowSelected={selected} />
          </div>
          <div>
            <Spacer value="26px" />
            {isInfoLoading ? (
              <Loader />
            ) : !isEmpty(info) ? (
              <StatBlock data={info!} />
            ) : (
              <span>Нет данных для блока общей статистики</span>
            )}
          </div>
        </SStat>
      </SWrap>
      <Footer />
      {isStatLoading ? <Loader isFullPage /> : null}
    </>
  );
};
