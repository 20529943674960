import { useContext, useState } from "react";
import { useShopCatalog } from "hooks/api/management";
import { Loader } from "components/loader/loader";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { Footer } from "components/footer/footer";
import { MTSInput } from "components/mts-input";
import { useDebounce } from "hooks/useDebounce";
import { ContextSettings } from "context/context-settings";
import { ShopDevicesTable } from "components/management-shop/shopDevicesTable/shopDevicesTable.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { TABLE_SORT_DEFAULT_SHOP, links } from "./const";
import { STop, STitle, SWrap, SSettings } from "./styles";
import { MTSButton } from "components/mts-button/mts-button";
import { TableSort } from "components/table/table-sort/table-sort";
import Icon from "components/icons";

export const ManagementShop = () => {
  const { isDesktop } = useContext(ContextSettings);
  const [searchVal, setSearch] = useState("");
  const [isOpenSort, setIsOpenSort] = useState(false);
  const [columnSort, setColumnSort] = useState<any>([]);
  const handleColumnSort = (sorting) => setColumnSort(sorting);

  const { data, isLoading } = useShopCatalog();

  const debouncedSearch = useDebounce(searchVal, 500);

  const handleInput = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <SWrap>
        <Breadcrumbs links={links} />
        <STop>
          <STitle>
            <CusTypo variant={isDesktop() ? "h2_medium" : "h4_medium"} font="wide">
              Список товаров
            </CusTypo>
          </STitle>
          <SSettings>
            <div className="searchField">
              <MTSInput
                searchIcon
                placeholder="Поиск"
                onChange={handleInput}
                value={searchVal}
                size="S"
              />
            </div>
            {!isDesktop() ? (
              <div>
                <MTSButton
                  size="S"
                  variant="secondary"
                  onClick={() => setIsOpenSort(true)}
                  format="icon"
                  icon={<Icon.Sort sx={{ width: 16, height: 16 }} />}
                />
                <TableSort
                  items={TABLE_SORT_DEFAULT_SHOP}
                  isOpen={isOpenSort}
                  close={() => setIsOpenSort(false)}
                  onApplySort={handleColumnSort}
                  view="modal"
                />
              </div>
            ) : null}
          </SSettings>
        </STop>
        <ShopDevicesTable
          rawData={data ?? []}
          globalFilter={debouncedSearch}
          sortByColumn={columnSort}
        />
      </SWrap>
      <Footer />
      {isLoading ? <Loader isFullPage /> : null}
    </>
  );
};
