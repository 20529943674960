import styled from "@emotion/styled";
import { CSSObject, css } from "@emotion/react";
import { device } from "theme";
import { CSSProperties } from "react";

const padSide = "40px";
export const defaultMaxWidth = 472;

export const SCont = styled.section<{
  isOpen: boolean;
  zIndex?: number;
  direction?: string;
  style?: CSSObject & CSSProperties;
  maxWidth: number;
}>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 64px;
  ${(props) => (props.direction === "right" ? "right: -100vw;" : `left: -100vw;`)}
  ${(props) => props.style};
  bottom: 0;
  width: 100%;
  max-width: 100%;
  padding: 20px 20px 36px 20px;
  margin: 0 !important;
  transition: all 0.5s ease-out;
  border: 1px solid #dde1e7;
  border-top: none;
  background-color: #ffffff;
  z-index: ${(props) => props.zIndex ?? 10};

  & h2 {
    text-align: left;
    margin-bottom: 24px;
  }

  @media ${device.desktopPlus} {
    padding: 48px ${padSide};
    max-width: ${(props) => props.maxWidth}px;
  }

  ${(props) =>
    props.isOpen &&
    css`
      ${props.direction === "right" ? "right: 0;" : "left: 0"}
    `}
`;

export const SHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  & div {
    display: flex;
    align-items: center;

    & .header__switch {
      margin-right: 16px;
    }
  }

  @media ${device.desktopPlus} {
    margin-bottom: 48px;
  }
`;

export const SBack = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & .MuiTypography-root {
    margin-left: 10px;
  }
`;

export const STitle = styled.div`
  margin-bottom: 24px;

  & h2 {
    text-align: left;
  }
`;

export const SClose = styled.div`
  position: absolute;
  right: ${padSide};
  cursor: pointer;
`;
