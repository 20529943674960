import { useMemo, useCallback, useContext } from "react";

import { ContextSettings } from "context/context-settings";
import { constructColumns } from "./utils";
import { IAskueEvent } from "interface/askue";
import { Table } from "components/table/table.component";
import { MobileTable } from "components/table/mobile-table/mobileTable.component";
import { tableMobStructure, tableStructure } from "./const";

interface IProp {
  rawData: IAskueEvent[];
  globalFilter?: string;
  onRowsChange?: Function;
  onEndReach?: Function;
}
export const AskueEventsTable: React.FC<IProp> = ({
  rawData,
  globalFilter,
  onRowsChange,
  onEndReach,
}) => {
  const { isDesktop } = useContext(ContextSettings);
  const mobileColumns = useMemo(() => tableMobStructure, []);
  const tableData = useMemo(() => rawData ?? [{}], [rawData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRowsChange = useCallback((a) => onRowsChange?.(a), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInfiniteLoading = useCallback(() => onEndReach, []);

  const columns = useMemo(
    () =>
      constructColumns({
        columns: tableStructure,
      }),
    [],
  );

  const defaultSortBy = useMemo(
    () => [
      {
        id: "datetime",
        desc: true,
      },
    ],
    [],
  );

  return isDesktop() ? (
    <div style={{ padding: "0 32px" }}>
      <Table
        columns={columns}
        data={tableData}
        view="alternatingRows"
        onRowsChange={handleRowsChange}
        onEndReach={handleInfiniteLoading}
        sortBy={defaultSortBy}
      />
    </div>
  ) : (
    <MobileTable
      columns={mobileColumns}
      data={tableData}
      globalFilter={globalFilter}
      padding="0 20px"
    />
  );
};
