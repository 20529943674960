import { useContext, useState } from "react";

import { ContextSettings } from "context/context-settings";
import { links_archive } from "pages/mining/const";
import { useMiningArchive } from "hooks/api/map";
import { Loader } from "components/loader/loader";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { Footer } from "components/footer/footer";
import { Spacer } from "components/spacer/spacer.component";
import { MiningArchiveTable } from "components/mining/archiveTable/miningArchiveTable";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SFltBtn } from "components/table/table-filter/styles";
import Icon from "components/icons";
import { sortCss } from "components/mining/archiveTable/subcomponents";
import { TableSort } from "components/table/table-sort/table-sort";
import { TABLE_SORT_ASKUE_ARCHIVE } from "./const";
import { STop, SWrap } from "./styles";

export const MiningArchive = () => {
  const { isDesktop } = useContext(ContextSettings);
  const archive = useMiningArchive();
  const [columnSort, setColumnSort] = useState<any>([]);
  const [isOpenSort, setIsOpenSort] = useState(false);

  const handleColumnSort = (sorting) => setColumnSort(sorting);

  return (
    <>
      <SWrap>
        <Breadcrumbs links={links_archive} />
        <STop>
          <CusTypo variant={isDesktop() ? "h2_medium" : "h4_medium"} font="wide">
            Архив отчетов
          </CusTypo>
          {!isDesktop() ? (
            <>
              <SFltBtn withValue={false} onClick={() => setIsOpenSort(true)} btnStyle={sortCss}>
                <Icon.Sort />
              </SFltBtn>
              <TableSort
                items={TABLE_SORT_ASKUE_ARCHIVE}
                isOpen={isOpenSort}
                close={() => setIsOpenSort(false)}
                onApplySort={handleColumnSort}
                view="modal"
              />
            </>
          ) : null}
        </STop>
        <MiningArchiveTable data={archive.data?.files_list ?? []} sortByColumn={columnSort} />
        <Spacer value="32px" />
      </SWrap>
      <Footer />
      {archive.isLoading ? <Loader isFullPage /> : null}
    </>
  );
};
