export enum SizeVariant {
  XS = "XS",
  S = "S",
  M = "M",
  L = "L",
}

export const sizeMTSStatus = (sizeStatus?: "S" | "M" | "L") => {
  switch (sizeStatus) {
    case "S":
      return "small";
    case "M":
      return "medium";
    case "L":
      return "large";
  }
};

export const getTypographyVariant = (size: string) => {
  switch (size) {
    case SizeVariant.XS:
      return "c1_medium";
    case SizeVariant.S:
      return "p4_regular";
    case SizeVariant.M:
    case SizeVariant.L:
      return "p3_regular";
    default:
      return "p4_regular";
  }
};
