import React, { useRef, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

import { ICardInfo } from "components/card-info/cardInfo.component";
import { convertRoleToCyr, hasRoleAccess } from "components/forms/user/validation-schema";
import { PopupAction } from "components/popup-action/popup-action";
import { FormDeleteUser } from "components/forms/user/form-delete-user";
import { MTSModal } from "components/mts-modal/mts-modal";
import { FormEditUser } from "components/forms/user/form-edit-user";
import { ContextSettings } from "context/context-settings";
import { EMPTY_CELL_VALUE } from "stream-constants/keys-and-constants";
import { getPhoneWithSpaces } from "utils/formatters";
import { convertEmptyValue } from "../utils";
import { useUpdateTenant } from "hooks/api/management";
import { LIST_ROLES } from "utils/roles";
import { getEventItemUrl } from "components/management-events/eventsTable/utils";
import { EEventObjectType } from "interface/management";
import { CusTypo } from "components/cusTypo/custom-typography";
import { UserMobCard } from "./subcomponents";
import { SInner, SInnerItem } from "../styles";
import theme from "theme";

export const UserAccordionDetails: React.FC<ICardInfo> = ({ data, calcHeightInner }) => {
  const { roles } = useContext(ContextSettings);
  const isCanSeeTenant = hasRoleAccess(roles, data.roles);
  const refInner = useRef<any>(null);

  useEffect(() => {
    if (refInner?.current) {
      const elem = refInner?.current;
      if (calcHeightInner) {
        calcHeightInner(elem?.getBoundingClientRect().height);
      }
    }
  });

  return (
    <SInner ref={refInner}>
      <ul>
        {isCanSeeTenant ? (
          <SInnerItem>
            <div>
              <CusTypo variant="c1_regular">Название организации</CusTypo>
            </div>
            <div>
              <CusTypo variant="c1_medium">{data.tenant?.title ?? EMPTY_CELL_VALUE}</CusTypo>
            </div>
          </SInnerItem>
        ) : null}
        <SInnerItem>
          <div>
            <CusTypo variant="c1_regular">Роль</CusTypo>
          </div>
          <div>
            <CusTypo variant="c1_medium">
              {data.roles?.map((a) => convertRoleToCyr(a.name)).join(", ")}
            </CusTypo>
          </div>
        </SInnerItem>
        <SInnerItem>
          <div>
            <CusTypo variant="c1_regular">Email</CusTypo>
          </div>
          <div>
            <CusTypo variant="c1_medium">{convertEmptyValue(data.email)}</CusTypo>
          </div>
        </SInnerItem>
        {isCanSeeTenant ? (
          <SInnerItem>
            <div>
              <CusTypo variant="c1_regular">Телефон</CusTypo>
            </div>
            <div>
              <CusTypo variant="c1_medium">
                {convertEmptyValue(getPhoneWithSpaces(data.phone))}
              </CusTypo>
            </div>
          </SInnerItem>
        ) : null}
        <SInnerItem>
          <div>
            <CusTypo variant="c1_regular">Дата регистрации</CusTypo>
          </div>
          <div>
            <CusTypo variant="c1_medium">
              {format(new Date(data.createdTime ?? 0), "dd.MM.yyyy")}
            </CusTypo>
          </div>
        </SInnerItem>
      </ul>
    </SInner>
  );
};

export const UserMobileCard = ({ row }) => {
  const { userName, id, roles, tenantId, isResponsible } = row.original;
  const { roles: contextRoles, isDesktop } = useContext(ContextSettings);
  const isAdmin = contextRoles.includes(LIST_ROLES.ADMIN);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const mutation = useUpdateTenant();
  const navigate = useNavigate();

  const toggleShowModalDelete = () => setShowModalDelete(!showModalDelete);

  const toggleShowModalEdit = () => setShowModalEdit(!showModalEdit);

  const editFunc = {
    title: "Редактировать",
    onClick: toggleShowModalEdit,
  };

  const delFunc = {
    title: "Удалить",
    onClick: toggleShowModalDelete,
    color: theme.mtsColor.red,
  };

  const navFunc = {
    title: "Перейти к организации",
    onClick: () =>
      navigate(`/${getEventItemUrl(EEventObjectType.tenant_id, tenantId, !isDesktop())}`, {
        state: { e_object_id: tenantId },
      }),
  };

  const isOwner = roles.some((role) => {
    return role.name === LIST_ROLES.OWNER;
  });

  const dataPopup = [editFunc]
    .concat(
      isOwner && !isResponsible
        ? {
            title: "Назначить ответственным",
            onClick: () =>
              mutation.mutate({
                data: {
                  additional_info: userName,
                },
                id: tenantId,
              }),
          }
        : [],
    )
    .concat(isAdmin ? [navFunc] : [])
    .concat([delFunc]);

  return (
    <>
      <UserMobCard data={row.original} dataPopup={dataPopup} />
      <MTSModal open={showModalDelete} close={toggleShowModalDelete}>
        <FormDeleteUser onCancel={toggleShowModalDelete} id={id} userData={row.original} />
      </MTSModal>
      <MTSModal open={showModalEdit} close={toggleShowModalEdit}>
        <FormEditUser onCancel={toggleShowModalEdit} id={id} />
      </MTSModal>
    </>
  );
};

export const UserDeskAction = ({ row }) => {
  const { isResponsible, roles, userName, tenantId, id } = row.original;
  const { roles: contextRoles, isDesktop } = useContext(ContextSettings);
  const isAdmin = contextRoles.includes(LIST_ROLES.ADMIN);
  const navigate = useNavigate();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const mutation = useUpdateTenant();
  const toggleShowModalDelete = () => setShowModalDelete(!showModalDelete);
  const toggleShowModalEdit = () => setShowModalEdit(!showModalEdit);

  const editFunc = {
    title: "Редактировать",
    onClick: toggleShowModalEdit,
  };

  const delFunc = {
    title: "Удалить",
    onClick: toggleShowModalDelete,
  };

  const navFunc = {
    title: "Перейти к организации",
    onClick: () =>
      navigate(`/${getEventItemUrl(EEventObjectType.tenant_id, tenantId, !isDesktop())}`, {
        state: { e_object_id: tenantId },
      }),
  };

  const isOwner = roles.some((role) => {
    return role.name === LIST_ROLES.OWNER;
  });

  const dataPopup = [editFunc]
    .concat(
      isOwner && !isResponsible
        ? {
            title: "Назначить ответственным",
            onClick: () =>
              mutation.mutate({
                data: {
                  additional_info: userName,
                },
                id: tenantId,
              }),
          }
        : [],
    )
    .concat(isAdmin ? [navFunc] : [])
    .concat([delFunc]);

  return (
    <div>
      <PopupAction items={dataPopup} view="light" />
      <MTSModal open={showModalDelete} close={toggleShowModalDelete}>
        <FormDeleteUser onCancel={toggleShowModalDelete} id={id} userData={row.original} />
      </MTSModal>
      <MTSModal open={showModalEdit} close={toggleShowModalEdit}>
        <FormEditUser onCancel={toggleShowModalEdit} id={id} />
      </MTSModal>
    </div>
  );
};
