import { useLayoutEffect, useRef, useState } from "react";
import Icon from "components/icons";
import { CusTypo } from "components/cusTypo/custom-typography";

import useOnClickOutside from "hooks/useOnClickOutside";
import { SBtn, SChildPop, SPop, SPopItem, SPopList, SWrap } from "./styles";

interface IPopupAction {
  items: any;
  parentRef?: HTMLDivElement | null;
  view?: "light" | "contrast";
  pos?: "right" | "center";
  onClose?: Function;
}

export const PopupActionChildren: React.FC<IPopupAction> = ({
  items,
  view,
  parentRef,
  pos = "right",
  children,
  onClose,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [openDirection, setDirection] = useState<"up" | "down">("down");
  const containerRef = useRef<any>(null);

  const handleTogglePopup = (e: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
    e.stopPropagation();
  };

  const handleClosePopup = (e: React.MouseEvent<HTMLElement>) => {
    setOpen(false);
    e.stopPropagation();
    onClose?.();
  };

  useOnClickOutside(containerRef, handleClosePopup as any);

  useLayoutEffect(() => {
    if (parentRef != null) {
      let list = parentRef?.closest(".tbody")?.querySelectorAll(".tbody_tr");
      let lastchild = list?.[list.length - 1];

      if (lastchild?.contains(parentRef) && (list?.length ?? 2) > 2) {
        setDirection("up");
      }
    }
  }, [parentRef]);

  return (
    <SWrap ref={containerRef}>
      <SBtn
        onClick={(e) => {
          handleTogglePopup(e);
        }}
        view={view}
        type="button"
      >
        <Icon.Ellipsis sx={{ width: 18, height: 4 }} />
      </SBtn>
      <SPop direction={openDirection} pos={pos} isOpen={isOpen}>
        <SPopList>
          {items.map((item: any, index: number) => (
            <SPopItem
              key={index}
              onClick={() => {
                item.onClick();
              }}
            >
              <CusTypo variant="p4_regular">{item.title}</CusTypo>
            </SPopItem>
          ))}
        </SPopList>
        {children ? (
          <SChildPop
          // onClick={handleClosePopup}
          >
            {children}
          </SChildPop>
        ) : null}
      </SPop>
    </SWrap>
  );
};
