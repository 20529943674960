import Icon from "components/icons";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SAccord, SAmount, SDetails, SSummary } from "./styles";

interface FilterAccordionProps {
  name: string;
  title: string;
  children?: React.ReactNode;
  amountChecked?: number;
}

export const FilterAccordion = (props: FilterAccordionProps) => {
  const { name, title, children, amountChecked } = props;

  return (
    <SAccord>
      <SSummary
        expandIcon={<Icon.ArrowRight sx={{ width: 21, height: 16 }} />}
        id={`${name}-header`}
      >
        <CusTypo variant="p3_regular">{title}</CusTypo>
        {Boolean(amountChecked) ? <SAmount>{amountChecked}</SAmount> : null}
      </SSummary>
      <SDetails>{children}</SDetails>
    </SAccord>
  );
};
