import styled from "@emotion/styled";
import theme, { device } from "theme";
import { TENANT_DEPLOY_STATUS } from "./tenant/const";
import rgba from "polished/lib/color/rgba";
import { css } from "@emotion/react";
import { customScrollVertical } from "components/table/utils";

const columns = "90px 110px 90px 40px 100px 120px auto";

export const SContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  & .MuiAccordionSummary-root {
    width: 100%;
    max-width: 90%;
    padding: 24px;
    min-height: 44px;
  }
`;

export const SSummaryContent = styled.div<{
  type?: "billing" | "tenant" | "user";
  isActiveTenant?: boolean;
}>`
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: 24px;

  ${(props) => {
    const billingStyle = `& > div:nth-of-type(1) {
		width: 18%;
	}
	& > div:nth-of-type(2) {
		width: 22%;
	}
	& > div:nth-of-type(3) {
		width: 20%;
	}
	& > div:nth-of-type(4) {
		width: 20%;
	}
	& > div:nth-of-type(5) {
		width: 20%;
	}`;
    const userStyle = `& > div:nth-of-type(1) {
		width: 35%;
	  }
	  & > div:nth-of-type(2) {
		width: 10%;
	  }
	  & > div:nth-of-type(3) {
		width: 45%;
	  }`;
    return props.type === "billing" ? billingStyle : props.type === "user" ? userStyle : "";
  }};
`;
export const SCardTitle = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  width: 30%;
`;
export const SCardInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
`;
const commonBar = css`
  height: 8px;
  border-radius: 2px;
`;
export const SBar = styled.div`
  ${commonBar};
  position: relative;
  width: 100%;
  background-color: ${(props) => rgba(props.theme.mtsColor.grey.greyMedium, 0.1)};
`;
export const SBarBack = styled.div<{ width: number }>`
  ${commonBar};
  position: absolute;
  left: 0;
  top: 0;
  width: ${(props) => props.width}%;
  background-color: ${(props) => props.theme.mtsColor.accent.positive.lightMode};
`;
export const SBarTitle = styled.div`
  padding-top: 8px;
  color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
`;
export const SBarWrap = styled.div<{ isError?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  ${SBarTitle} {
    color: ${(props) =>
      props.isError
        ? props.theme.mtsColor.accent.negative.lightMode
        : props.theme.mtsColor.text.secondary.lightMode};
  }

  ${SBarBack} {
    background-color: ${(props) =>
      props.isError
        ? props.theme.mtsColor.accent.negative.lightMode
        : props.theme.mtsColor.accent.positive.lightMode};
  }
`;
export const SCardField = styled.div<{ price?: boolean }>`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin: 0 10px;
  flex: 1 1 120px;
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    flex-basis: 170px;
    margin-right: 0;
  }

  ${(props) => props.price && `justify-content: end`}
`;
export const SInner = styled.div`
  padding: 24px 8px;
  cursor: default;
`;
export const SInnerItem = styled.li`
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
  &:nth-of-type(2n) {
    background-color: ${(props) => props.theme.mtsColor.background.primary.lightMode};
  }

  & div:not(:last-child) span {
    color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
  }
`;

export const SSpecItem = styled.li<{ color: keyof typeof TENANT_DEPLOY_STATUS | null }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  padding: 12px 24px;

  @media ${device.miniTabletPlus} {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 20px 16px;

    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  & > span {
    color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media ${device.mobilePlus} {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
    }

    @media ${device.miniTabletPlus} {
      width: inherit;
      align-items: center;
      justify-content: space-between;
      gap: 64px;
    }

    & > span {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
      min-width: 102px;

      @media ${device.mobilePlus} {
        justify-content: flex-end;
      }
    }

    & div {
      & > span {
        color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};

        & span {
          color: ${(props) => props.theme.mtsColor.text.primary.lightMode};
        }
      }
    }
  }

  ${(props) =>
    props.color &&
    `
	margin-top: 4px;
	background-color: ${rgba(colorByStatus(props.color), 0.08)}
  `}
`;

const colorByStatus = (val) => {
  switch (val) {
    case TENANT_DEPLOY_STATUS.Выполнено:
      return theme.mtsColor.base.success;
    case TENANT_DEPLOY_STATUS["В процессе"]:
      return theme.mtsColor.base.night;
    case TENANT_DEPLOY_STATUS.Ошибка:
      return theme.mtsColor.base.critical;
    default:
      return theme.mtsColor.background.primaryElevated.lightMode;
  }
};
export const SButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-left: 32px;
  margin-right: 24px;
  cursor: pointer;
`;
export const SAddUser = styled.div`
  background-color: ${(props) => props.theme.mtsColor.controls.tertiaryActive.lightMode};
  border: 1px solid ${(props) => props.theme.mtsColor.background.stroke.lightMode};
  border-radius: 6px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const SValueText = styled.span`
  font-size: 1.214rem;
  font-weight: 600;
`;
export const SBody = styled.div`
  margin-top: 24px;
`;
export const SHistoryBlock = styled.div`
  display: flex;
  margin-bottom: 24px;
`;
export const SHistory = styled.div`
  width: 100%;
  max-height: 255px;
  overflow-y: auto;
  ${(props) =>
    customScrollVertical({
      theme: props.theme,
      trackStart: 36,
      trackEnd: 0,
    })};
`;
export const SHistoryItem = styled.div`
  display: grid;
  grid-template-columns: ${columns};
  gap: 16px;
  align-items: center;
  padding: 10px 16px;
  background-color: ${(props) => props.theme.mtsColor.background.primary.lightMode};
  &:nth-of-type(2n) {
    background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
  }
`;
export const SHistoryHeader = styled.div`
  display: grid;
  grid-template-columns: ${columns};
  gap: 16px;
  padding: 10px 16px;
  position: sticky;
  top: 0;
  background: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
`;
export const SHistoryTitle = styled.div`
  color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
  font-weight: 700;
`;
export const SHistoryField = styled.div`
  white-space: nowrap;
  & span:not(:last-child) {
    margin-right: 10px;
  }
  & .MuiTypography-root {
    display: block;
    text-align: left;
  }
`;

export const SInfoMessage = styled.div`
  color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
`;
export const SBtns = styled.div`
  display: flex;
`;
export const SItemPage = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 100%;
  padding: 28px 20px 64px 20px;
  background-color: #ffffff;
`;

// Mobile
export const SContainerMobile = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 10px;
  text-align: left;
  width: 100%;
  padding: 12px;
  background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
  border-radius: 8px;
  /* margin-bottom: 16px; */
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);

  @media ${device.miniTabletPlus} {
    gap: 15px;
  }
`;
export const SContent = styled.div`
  flex: 1;

  & > div {
    &:first-of-type {
      display: flex;
      flex-flow: row nowrap;
      gap: 16px;
      align-items: center;
    }
    &:not(:last-child) {
      margin-bottom: 4px;

      @media ${device.miniTabletPlus} {
        margin-bottom: 8px;
      }
    }
    & .MuiTypography-root:not(:last-child) {
      margin-right: 16px;
    }

    & > a {
      text-decoration: none;
      & > span {
        color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
      }
    }
  }
  & > section {
    display: flex;
    justify-content: space-between;
    align-items: end;
    flex-wrap: wrap;

    & > span {
      color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};

      &:last-of-type {
        margin-right: -25px;
        font-size: 10px;
      }
    }
  }

  & > .MuiTypography-root {
    display: inline-block;
    margin-bottom: 4px;
  }
`;
