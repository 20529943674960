import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import { MTS_COMPACT } from "theme";

export const SCont = styled.div`
  align-self: flex-start;
  display: flex;

  & .MuiSvgIcon-root {
    width: auto !important;
    height: auto !important;
  }
  & .label__icon {
    position: relative;
    top: -2px;
    left: 2px;
  }
`;

export const SLabel = styled(Typography)<{ hasError?: boolean }>`
  &.MuiTypography-root {
    font-family: ${MTS_COMPACT}, sans-serif;
    color: ${(props) =>
      props.hasError
        ? props.theme.mtsColor.accent.negative.lightMode
        : props.theme.mtsColor.text.secondary.lightMode};
    position: relative;
    bottom: 2px;
  }
`;
