import { ESn } from "./common";

export enum EDepStats {
  "Выезд не назначен" = "Выезд не назначен",
  Проверяется = "Проверяется",
  "Проверка завершена" = "Проверка завершена",
}

export type IMapMarker = {
  lat: number | null;
  lng: number | null;
  id_pp: number;
  adress_too?: string | null;
  mpoisk_id: number | null;
  sn: string | null;
  fraud_probability?: ESn;
  task_status?: EDepStats;
  gaz_flg?: boolean;
};

export interface IMapMiner {
  score: number;
  dt: string;
  mean_x: number; // longitute
  mean_y: number; // latitude
  zid: number;
  district_name: string;
  region_name: string;
  points: number[][];
}

type IMiningRef = {
  count_black: number;
  count_red: number;
  count_beige: number;
  count_lightred: number;
};

export interface IMiningInfoResponse {
  time_schema: string; // 2023-09-19
  file: string; // url
  miners: IMiningRef[];
  count: number;
}

export interface IMiningArchiveResponse {
  files_list: {
    time_schema: string; //2023-09-22
    file_path: string;
  }[];
}

export type IEmployees = {
  allowedOvertime: number | null;
  canRequest: boolean | null;
  canSend: boolean | null;
  canTrack: boolean | null;
  icon: number | null;
  isBlockEnabled: boolean | null;
  isLocateEnabled: boolean | null;
  isOnline: boolean | null;
  name: string;
  subscriberGroupID: number | null;
  subscriberID: number | null;
  subscriberTariffTypeID: number | null;
  workScheduleID: number | null;
};

export type ICreateCheckTaskRequest = {
  deadLineDate: string;
  idPP: (number | null)[];
  employeeId: number | null;
};

export type IDeparturesRequest = {
  list_res?: string[];
  list_po?: string[];
  task_status?: string[];
  fraud_probability?: string[];
  created_dt?: string[];
  request_result?: string[];
};

export type IMinersRequest = {
  list_res?: string[];
  list_po?: string[];
  mining_probability?: string[];
};
