import { IHttpMethods } from "stream-constants/server-constants";
import { GET_PROFILE } from "services/urls";

export const fetchProfile = async (): Promise<Response> => {
  return await fetch(GET_PROFILE, {
    mode: "cors",
    cache: "no-store",
    credentials: "include",
    redirect: "follow",
    method: IHttpMethods.GET,
  });
};
