import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Admin = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M22 44C34.1503 44 44 34.1503 44 22C44 9.84974 34.1503 0 22 0C9.84974 0 0 9.84974 0 22C0 34.1503 9.84974 44 22 44Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6669 21.9508C17.6313 21.6024 16.8553 21.6012 16.709 20.3294C16.6305 19.6468 16.9427 19.2625 17.1318 19.1417C17.1318 19.1417 16.6608 18.04 16.6608 16.9475C16.6608 13.6974 18.2288 11 22.0122 11C24.0547 11 24.6879 12.486 24.6879 12.486C26.5128 12.486 27.3636 14.5392 27.3636 16.9475C27.3636 18.148 26.8927 19.1417 26.8927 19.1417C27.0818 19.2625 27.3939 19.6468 27.3155 20.3294C27.1692 21.6012 26.6733 21.9233 26.3576 21.9508C26.2363 23.1366 25.0893 24.6555 24.6879 24.877V26.8589C25.2116 28.6059 27.8838 28.6511 30 29.7972C30 31.8199 24.9851 33 22 33C19.0149 33 14 31.8199 14 29.8106C16.12 28.6515 18.8108 28.6126 19.3365 26.8589V24.877C18.9352 24.6574 17.7882 23.1384 17.6669 21.9508Z"
        fill="#969FA8"
      />
    </SvgIcon>
  );
};

export default Admin;
