import { DEPARTURE_RESULT } from "stream-constants/keys-and-constants";
import * as yup from "yup";

const REQUIRED = "Поле не заполнено";
const INCORRECT = "Некорректно заполнено поле";
export const FALLBACK_MSG = "Что-то пошло не так, попробуйте еще раз";
const checkForNumberIntFloat = (value: string) => /^[+-]?([0-9]*\.)?[0-9]+$/.test(value ?? "");

export const statusArray = [
  { val: DEPARTURE_RESULT.NORMAL },
  { val: DEPARTURE_RESULT.SUSPICION },
  { val: DEPARTURE_RESULT.BREAKING },
  { val: DEPARTURE_RESULT.DEFECTIVE },
  { val: DEPARTURE_RESULT.REPLACED },
  { val: DEPARTURE_RESULT.TO_CHECK },
  { val: DEPARTURE_RESULT.NO_LOAD },
  { val: DEPARTURE_RESULT.NO_ACCESS },
];

export const statsForbidPhase = [
  DEPARTURE_RESULT.REPLACED,
  DEPARTURE_RESULT.NO_LOAD,
  DEPARTURE_RESULT.NO_ACCESS,
  DEPARTURE_RESULT.TO_CHECK,
];

export const schema = yup.object().shape({
  id_pp: yup.string().nullable().required(),
  telebot_request_dt: yup.string().nullable().required(),
  telebot_request_result: yup.string().required(REQUIRED),
  telebot_request_comment: yup.string().notRequired(),
  gaz_flg: yup.boolean().notRequired(),
  phase_num: yup.string().when(["telebot_request_result"], {
    is: (status) => !statsForbidPhase.includes(status),
    then: yup
      .string()
      .nullable()
      .required(REQUIRED)
      .test("phase_meter_A", INCORRECT, (value) => checkForNumberIntFloat(value ?? "")),
    otherwise: yup.string().nullable().notRequired(),
  }),
  phase_meter_A: yup.string().when(["telebot_request_result"], {
    is: (status) => !statsForbidPhase.includes(status),
    then: yup
      .string()
      .nullable()
      .required(REQUIRED)
      .test("phase_meter_A", INCORRECT, (value) => checkForNumberIntFloat(value ?? "")),
    otherwise: yup.string().nullable(),
  }),
  phase_clamp_A: yup.string().when(["telebot_request_result"], {
    is: (status) => !statsForbidPhase.includes(status),
    then: yup
      .string()
      .nullable()
      .required(REQUIRED)
      .test("phase_clamp_A", INCORRECT, (value) => checkForNumberIntFloat(value ?? "")),
    otherwise: yup.string().nullable(),
  }),
  phase_meter_B: yup.string().when(["phase_num", "telebot_request_result"], {
    is: (phase_num, status) => phase_num === "3" && !statsForbidPhase.includes(status),
    then: yup
      .string()
      .nullable()
      .required(REQUIRED)
      .test("phase_clamp_A", INCORRECT, (value) => checkForNumberIntFloat(value ?? "")),
    otherwise: yup.string().nullable(),
  }),
  phase_clamp_B: yup.string().when(["phase_num", "telebot_request_result"], {
    is: (phase_num, status) => phase_num === "3" && !statsForbidPhase.includes(status),
    then: yup
      .string()
      .nullable()
      .required(REQUIRED)
      .test("phase_clamp_A", INCORRECT, (value) => checkForNumberIntFloat(value ?? "")),
    otherwise: yup.string().nullable(),
  }),
  phase_meter_C: yup.string().when(["phase_num", "telebot_request_result"], {
    is: (phase_num, status) => phase_num === "3" && !statsForbidPhase.includes(status),
    then: yup
      .string()
      .nullable()
      .required(REQUIRED)
      .test("phase_clamp_A", INCORRECT, (value) => checkForNumberIntFloat(value ?? "")),
    otherwise: yup.string().nullable(),
  }),
  phase_clamp_C: yup.string().when(["phase_num", "telebot_request_result"], {
    is: (phase_num, status) => phase_num === "3" && !statsForbidPhase.includes(status),
    then: yup
      .string()
      .nullable()
      .required(REQUIRED)
      .test("phase_clamp_A", INCORRECT, (value) => checkForNumberIntFloat(value ?? "")),
    otherwise: yup.string().nullable(),
  }),
});

export const schemaAddDevice = yup.object().shape({
  sn: yup.string().required(REQUIRED),
});
