import { ShopMobCard } from "components/card-info/shop/shop.component";
import { RenderFilter } from "components/table/render-filter/renderFilter.component";

function dontFilter(rows, columnIds, filterValue) {
  return rows;
}

export const emptyPrice = "Цена по запросу";

export const formatPrice = (val: number | string) => {
  return val === -1 ? emptyPrice : val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const tableStructure = [
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Название"
        withPadding
      />
    ),
    accessor: "name",
    minWidth: 320,
    canFilter: false,
    disableFilters: true,
    filter: dontFilter,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Категория"
        withPadding
      />
    ),
    accessor: "category",
    minWidth: 232,
    canFilter: false,
    disableFilters: true,
    filter: dontFilter,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Тип"
        withPadding
      />
    ),
    accessor: "type",
    minWidth: 156,
    canFilter: false,
    disableFilters: true,
    filter: dontFilter,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Цена"
        withPadding
      />
    ),
    accessor: "price",
    minWidth: 160,
    canFilter: false,
    disableFilters: true,
    filter: dontFilter,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Видимость"
        withPadding
      />
    ),
    accessor: "hide_flag",
    minWidth: 180,
    width: "100%",
    canFilter: false,
    disableFilters: true,
    filter: dontFilter,
    sortType: "basic",
  },
];

export const tableMobStructure = [
  {
    accessor: "name",
    Row: ShopMobCard,
  },
  {
    accessor: "category",
  },
  {
    accessor: "type",
  },
  {
    accessor: "price",
  },
  {
    accessor: "hide_flag",
  },
];
