import styled from "@emotion/styled";
import { device } from "theme";

export const SWrap = styled.div`
  max-width: 100%;
  padding: 48px 20px 0px;
`;

export const SArticle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "title btns"
    "fields fields";
  gap: 24px;
  padding-bottom: 12px;

  @media ${device.tabletPlus} {
    grid-template-columns: auto 1fr auto;
    grid-template-areas: "title fields btns";
    grid-template-rows: 1fr;
  }

  @media ${device.desktopPlus} {
    grid-template-columns: auto 1fr;
    grid-template-areas: "title fields";
  }
`;

export const STitle = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  grid-area: title;
`;

export const SFields = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column-reverse;
  gap: 12px;
  width: 100%;
  grid-area: fields;

  @media ${device.tabletPlus} {
    flex-direction: row;
  }

  @media ${device.desktopPlus} {
    gap: 16px;
    flex-direction: inherit;

    & .MuiButton-root {
      white-space: nowrap;
      flex: 0 0 auto;
      height: 32px;
    }
  }
`;

export const SItem = styled.div`
  width: 100%;
  max-width: inherit;

  &.searchField {
    width: 100%;

    @media ${device.tabletPlus} {
      max-width: 300px;
    }
  }

  @media ${device.tabletPlus} {
    max-width: fit-content;
  }
`;

export const SError = styled.div`
  padding: 48px 0 0;
`;

export const SPos = styled.div`
  display: flex;
  align-items: center;

  & > .MuiTypography-root {
    margin-right: 16px;
  }

  & > div {
    flex: 1;
    width: 100%;

    @media ${device.desktopPlus} {
      width: 160px;
    }
  }
`;

export const SDash = styled.form`
  padding: 0 8px;
`;

export const SBtns = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  grid-area: btns;
`;
