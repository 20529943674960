import { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash";

import { useManagementTenants } from "hooks/api/management";
import { useDebounce } from "hooks/useDebounce";
import { Loader } from "components/loader/loader";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { ILocationState } from "interface/common";
import { Footer } from "components/footer/footer";
import { MTSButton } from "components/mts-button/mts-button";
import { MTSModal } from "components/mts-modal/mts-modal";
import Icon from "components/icons";
import { MTSInput } from "components/mts-input";
import { FormAddTenant } from "components/forms/tenant/form-add-tenant";
import { TenantCardInfo } from "components/card-info/tenant/tenant.component";
import { ContextSettings } from "context/context-settings";
import { FormTempUpload } from "components/forms/temp-form-upload";
import { IFilter, TableFilter } from "components/table/table-filter/table-filter";
import { LIST_ROLES } from "utils/roles";
import { CusTypo } from "components/cusTypo/custom-typography";
import {
  FILTER_BY_DEPLOY,
  FILTER_BY_SERVICES,
  HARDCODED_FILTER_ITEMS,
  filterByDeploy,
  filterBySearch,
  filterByService,
  links,
} from "./const";
import { SBody, SBtns, SItem, SSettings, STitle, STop, SWrap } from "./styles";

export const ManagementTenant = () => {
  const locationState = useLocation().state as ILocationState;
  const [showTenantModal, setTenantModal] = useState(false);
  const [showUpload, setUploadModal] = useState(false);
  const [searchVal, setSearch] = useState("");
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [columnFilter, setColumnFilter] = useState<IFilter | undefined>(undefined);

  const tenants = useManagementTenants();
  const { isDesktop, roles } = useContext(ContextSettings);

  const isCanSee = roles.includes(LIST_ROLES.ADMIN) || roles.includes(LIST_ROLES.ANALYST_SYSTEM);

  const handleInput = (e) => {
    setSearch(e.target.value);
  };

  const debouncedSearch = useDebounce(searchVal, 500);

  const toggleTenantModal = () => {
    setTenantModal((s) => !s);
  };

  const toggleUploadModal = () => {
    setUploadModal((s) => !s);
  };

  const handleColumnFilter = ({ filtersArr }) => {
    setColumnFilter(filtersArr);
  };

  const filteredData = (tenants?.data ?? [])
    ?.filter((a) => filterBySearch(a, debouncedSearch))
    .filter((b) => filterByService(b, columnFilter))
    .filter((d) => filterByDeploy(d, columnFilter));

  return (
    <>
      <SWrap>
        <Breadcrumbs links={links} />
        <STop>
          <STitle>
            <CusTypo variant={isDesktop() ? "h2_medium" : "h4_medium"} font="wide">
              Организации
            </CusTypo>
            <MTSButton
              size="S"
              variant="primary"
              onClick={toggleTenantModal}
              format="icon"
              icon={<Icon.Plus sx={{ width: 24, height: 24 }} />}
            />
          </STitle>
          <SSettings>
            <SItem className="searchField">
              <MTSInput
                searchIcon
                placeholder="Название организации"
                onChange={handleInput}
                value={searchVal}
                size="S"
              />
            </SItem>
          </SSettings>
          <SBtns>
            {isCanSee ? (
              <SItem>
                <MTSButton size="S" variant="dark_blue" onClick={toggleUploadModal}>
                  Загрузить файл
                </MTSButton>
              </SItem>
            ) : null}
            <SItem>
              <TableFilter
                className="btns__filter"
                dataLength={filteredData.length ?? 0}
                isOpen={isOpenFilter}
                isOn
                defaultFilter={{ [FILTER_BY_SERVICES]: [], [FILTER_BY_DEPLOY]: [] }}
                toogleShowFilter={setIsOpenFilter}
                onApplyFilter={handleColumnFilter}
                scheme={HARDCODED_FILTER_ITEMS}
                isSchemeOnly
              />
            </SItem>
          </SBtns>
        </STop>
        <div>
          <SBody>
            <div>
              {isEmpty(tenants?.data) || isEmpty(filteredData) ? (
                <CusTypo variant="p4_regular">
                  {isEmpty(tenants?.data)
                    ? "Список пуст"
                    : isEmpty(filteredData)
                    ? "Фильтрация не дала результатов"
                    : ""}
                </CusTypo>
              ) : (
                filteredData.map((item) => (
                  <TenantCardInfo
                    key={item.id}
                    data={item}
                    variant="tenant"
                    isAccented={locationState?.e_object_id === item.id}
                  />
                ))
              )}
            </div>
          </SBody>
        </div>
      </SWrap>
      <MTSModal open={showTenantModal} close={toggleTenantModal}>
        <FormAddTenant onCancel={toggleTenantModal} />
      </MTSModal>
      {isCanSee ? (
        <MTSModal open={showUpload} close={toggleUploadModal}>
          <FormTempUpload onCancel={toggleUploadModal} />
        </MTSModal>
      ) : null}
      <Footer />
      {tenants.isLoading ? <Loader isFullPage /> : null}
    </>
  );
};
