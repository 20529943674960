import { ReactNode, useLayoutEffect, useRef, useState } from "react";
import Icon from "components/icons";

import useOnClickOutside from "hooks/useOnClickOutside";
import { Loader } from "components/loader/loader";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SBtn, SPop, SPopItem, SPopList, SWrap } from "./styles";

interface IPopupAction {
  items: { title: ReactNode | string; onClick: Function }[];
  parentRef?: HTMLDivElement | null;
  view?: "light" | "contrast";
  pos?: "right" | "center";
  isLoading?: boolean;
  onOpen?: Function;
}

export const PopupAction: React.FC<IPopupAction> = ({
  items,
  view,
  parentRef,
  pos = "right",
  isLoading,
  onOpen,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [openDirection, setDirection] = useState<"up" | "down">("down");
  const containerRef = useRef<any>(null);

  const handleTogglePopup = (e: React.MouseEvent<HTMLElement>) => {
    setOpen(!isOpen);
    e.stopPropagation();
  };

  const handleClosePopup = (e: React.MouseEvent<HTMLElement>) => {
    setOpen(false);
    e.stopPropagation();
  };

  useOnClickOutside(containerRef, handleClosePopup as any);

  // FIXME раскрытие выпадашки вверх не работает после виртуализации
  useLayoutEffect(() => {
    if (parentRef != null) {
      let list = parentRef?.closest(".tbody")?.querySelectorAll(".tbody_tr");
      let lastchild = list?.[list.length - 1];

      if (lastchild?.contains(parentRef) && (list?.length ?? 2) > 2) {
        setDirection("up");
      }
    }
  }, [parentRef]);

  return (
    <SWrap ref={containerRef}>
      <SBtn
        onClick={(e) => {
          onOpen?.();
          handleTogglePopup(e);
        }}
        view={view}
      >
        <Icon.Ellipsis sx={{ width: 18, height: 4 }} />
      </SBtn>
      <SPop direction={openDirection} pos={pos} isOpen={isOpen}>
        <SPopList>
          {isLoading ? (
            <Loader />
          ) : (
            items.map((item: any, index: number) => (
              <SPopItem
                key={index}
                onClick={(e) => {
                  item.onClick();
                  handleClosePopup(e);
                }}
              >
                {typeof item.title === "string" ? (
                  <CusTypo variant="p4_regular">{item.title}</CusTypo>
                ) : (
                  item.title
                )}
              </SPopItem>
            ))
          )}
        </SPopList>
      </SPop>
    </SWrap>
  );
};
