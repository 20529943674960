import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { customScrollVertical } from "components/table/utils";
import rgba from "polished/lib/color/rgba";

export const SItem = styled.div`
  padding: 32px 32px 24px 32px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 1px 5px 22px 0px ${(props) => props.theme.mtsColor.base.rockBlueTransparent};
`;

export const SDevicesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 67%;
  grid-template-rows: 140px 164px;
  gap: 16px;

  & .item {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }

  & .total {
    grid-area: total;
  }

  & .time {
    grid-area: time;
  }

  & .chart {
    min-height: 320px;
    grid-area: chart;
  }

  grid-template-areas:
    "total chart"
    "time chart";
`;

export const SStatus = styled.span<{ isOk?: boolean }>`
  color: ${(props) =>
    props.isOk
      ? props.theme.mtsColor.text.positive.lightMode
      : props.theme.mtsColor.text.negative.lightMode};
`;

const commonBar = css`
  height: 24px;
  border-radius: 4px;
`;

export const SBar = styled.div`
  ${commonBar};
  position: relative;
  width: 100%;
  background-color: ${(props) => rgba(props.theme.mtsColor.accent.active.lightMode, 0.1)};
`;

export const SBarBack = styled.div<{ width: number }>`
  ${commonBar};
  position: absolute;
  left: 0;
  top: 0;
  width: ${(props) => props.width}%;
  background-color: ${(props) => props.theme.mtsColor.accent.active.lightMode};
`;

export const SBarHead = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 144px auto minmax(20px, max-content);
  & > div {
    align-self: center;
  }
`;

export const SBarGrid = styled(SBarHead)`
  grid-auto-rows: 1fr;
  grid-auto-flow: row;
  min-height: 10px;
  height: auto;
  max-height: 312px;
  overflow-y: auto;
  overflow-x: hidden;
  ${(props) =>
    customScrollVertical({
      theme: props.theme,
      trackStart: 0,
    })};
`;

export const SDown = styled.a`
  width: 150px;
  color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
  text-decoration: none;
  cursor: pointer;
`;
