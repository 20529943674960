import { EDemoMngStatus, IBreadcrumbsLink } from "interface/common";
import { ROUTE_DEMO_MANAGEMENT, ROUTE_MANAGEMENT } from "stream-constants/route-constants";

export const links: IBreadcrumbsLink[] = [
  { name: "Управление", href: `/${ROUTE_MANAGEMENT}` },
  { name: "Демо", href: `/${ROUTE_DEMO_MANAGEMENT}` },
];

export const HARDCODED_FILTER_ITEMS = {
  checkbox: {
    status: [
      {
        id: 0,
        name: EDemoMngStatus["Заполнение анкеты"],
      },
      {
        id: 1,
        name: EDemoMngStatus["На согласовании"],
      },
      {
        id: 2,
        name: EDemoMngStatus["На модерации"],
      },
      {
        id: 3,
        name: EDemoMngStatus["Приостановлен"],
      },
      {
        id: 4,
        name: EDemoMngStatus["Активен"],
      },
    ],
  },
  radio: {},
};
