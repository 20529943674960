import { IAskueChartReq, IAskueEnergyReq, IAskueMngUpdStatusRequest } from "interface/askue";
import { fetchData } from "services/services-utils/fetch-data";
import {
  ASKUE_MANAGEMENT,
  ASKUE_DEVICE_LIST,
  ASKUE_MANAGEMENT_UPDATE_STATUS,
  ASKUE_ENERGY,
  ASKUE_ENERGY_CHART,
} from "services/urls";
import { IHttpMethods } from "stream-constants/server-constants";
import { StorageAPI, EStorageKeys } from "utils/localStorageAPI";
import { mock_management_tenant } from "./mock-data";

export const getManagmentAskue = async () => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = mock_management_tenant;
  } else {
    result = await fetchData({
      url: ASKUE_MANAGEMENT,
      method: IHttpMethods.GET,
      name: "getManagmentAskue",
      emptyResponse: false,
    });
  }

  return result;
};

export const askueMngUpdateStatus = async (data: IAskueMngUpdStatusRequest) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = "OK";
  } else {
    result = fetchData({
      url: ASKUE_MANAGEMENT_UPDATE_STATUS,
      method: IHttpMethods.PATCH,
      name: "askueMngUpdateStatus",
      body: JSON.stringify(data),
      emptyResponse: false,
    });
  }

  return result;
};

export const askueMngGetDeviceListXLSX = async (id: string | number) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = [];
  } else {
    result = await fetchData({
      url: `${ASKUE_DEVICE_LIST}/${id}`,
      method: IHttpMethods.GET,
      name: "askueMngGetDeviceListXLSX",
      emptyResponse: false,
      blobResponse: true,
      responseAbordTimeOut: false,
    }).then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `askue_${id}_device_list.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
  }
  return result;
};

export const getEnergyAskue = async (data: IAskueEnergyReq) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = mock_management_tenant;
  } else {
    result = await fetchData({
      url: ASKUE_ENERGY,
      method: IHttpMethods.POST,
      name: "getEnergyAskue",
      emptyResponse: false,
      body: JSON.stringify(data),
      responseAbordTimeOut: false,
    });
  }

  return result;
};

export const getAskueEnergyChart = async (data: IAskueChartReq) => {
  const raw = { ...data };
  const toCheck = Object.keys(data);
  toCheck.forEach((a) => {
    if (data[a] === null) delete raw[a];
  });
  const result = await fetchData({
    url: ASKUE_ENERGY_CHART,
    method: IHttpMethods.POST,
    name: "getAskueEnergyChart",
    emptyResponse: false,
    body: JSON.stringify(raw),
  });

  return result;
};
