import { FC } from "react";
import { useForm } from "react-hook-form";
import { MTSInput } from "components/mts-input";
import { MTSButton } from "components/mts-button/mts-button";
import { SERVICES, TIME_ZONE } from "stream-constants/keys-and-constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { FALLBACK_MSG, schema } from "./validation-schema";
import { SBtns, SFormAddOrg, SFormField } from "./styles";
import { useAddTenant } from "hooks/api/management";
import { MTSAutocomplete } from "components/mts-autocomplete/mts-autocomplete";
import { ModalNotifyBody } from "components/modal-notify-body/modal-notify-body";
import { SModalTitle } from "components/modal-notify-body/styles";
import { Spacer } from "components/spacer/spacer.component";

const productsArray = Object.values(SERVICES);

type IProps = {
  onCancel?: any;
};

export const FormAddTenant: FC<IProps> = ({ onCancel }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {},
    resolver: yupResolver(schema),
    mode: "onTouched",
    shouldFocusError: false,
    reValidateMode: "onChange",
  });

  const mutation = useAddTenant();

  const onSubmit = async (sbmtData: any) => {
    mutation.mutate(sbmtData);
  };

  if (mutation?.isSuccess) {
    return <ModalNotifyBody title="Организация добавлена" isSuccess />;
  } else if (mutation?.isError) {
    return (
      <ModalNotifyBody
        title="Организация не добавлена"
        isError
        textDescrioption={(mutation?.error as any).message ?? FALLBACK_MSG}
      />
    );
  } else {
    return (
      <>
        <SModalTitle>Добавить организацию</SModalTitle>
        <SFormAddOrg onSubmit={handleSubmit(onSubmit)}>
          <SFormField>
            <MTSInput
              label="Название организации"
              errorMessage={errors?.title?.message}
              {...register("title", {
                required: true,
              })}
              control={control}
            />
          </SFormField>
          <SFormField>
            <MTSInput
              label="Номер договора"
              errorMessage={errors?.contr_num?.message}
              {...register("contr_num", {
                required: true,
              })}
              control={control}
            />
          </SFormField>
          <SFormField>
            <MTSAutocomplete
              label="Оказываемые услуги"
              placeholder=""
              options={productsArray}
              control={control}
              size="M"
              variant="checkbox"
              {...register("service_type", {
                required: true,
              })}
              errorMessage={errors?.service_type?.message}
            />
          </SFormField>
          <SFormField>
            <MTSAutocomplete
              label="Часовой пояс"
              placeholder=""
              options={TIME_ZONE}
              control={control}
              size="M"
              {...register("time_schema", {
                required: true,
              })}
              errorMessage={errors?.time_schema?.message}
            />
          </SFormField>
          <Spacer value="8px" />
          <SBtns>
            <MTSButton size="M" variant="primary" type="submit" onClick={() => undefined}>
              Сохранить
            </MTSButton>
            <MTSButton size="M" variant="secondary" onClick={onCancel}>
              Отмена
            </MTSButton>
          </SBtns>
        </SFormAddOrg>
      </>
    );
  }
};
