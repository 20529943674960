const URL = window.location.hostname;
export const isDevMode = (): boolean => process.env.NODE_ENV === "development";
export const isProdWeb = () => !URL.includes("dev") && !URL.includes("test") && !isDevMode();

export const URL_LOGOUT_MTS_BY_ID = (param: number | string) =>
  `https://login.mts.ru/amserver/UI/Logout?goto=${param}`;

export const WELCOME_PAGE_URL = isDevMode() ? "https://localhost.mts.ru" : `https://${URL}`;
export const MAIN_URL = isDevMode() ? "https://lk.energytool-dev.mts.ru/api" : `https://${URL}/api`;
export const LANDING_URL = isDevMode()
  ? "https://energytool-dev.mts.ru"
  : `https://${URL.replace("lk.", "")}`;
export const LANDING_SHOP_URL = `${LANDING_URL}/shop`;

export const USERS = (api_prefix) => `${MAIN_URL}${api_prefix}/users`;
export const TENANT = `${MAIN_URL}/tenant`;
export const GET_PROFILE = `${MAIN_URL}/users/me`;

export const ANOMALIES = `${MAIN_URL}/list`;
export const DEVICE_LIST = `${ANOMALIES}/lines`;
export const ANOMALIES_CHECK_DEVICE = `${ANOMALIES}/search_meter`;
export const ANOMALIES_ADD_DEVICE = `${ANOMALIES}/add_meter`;
export const GET_XLSX_DEVICE_LIST = `${ANOMALIES}/get_xlsx`;
export const UPLOAD_XLSX_DEVICE_LIST = `${ANOMALIES}/upload_xlsx`;
export const ADD_CHECK_RESULT = `${ANOMALIES}/results`;
export const ANOMALIES_ARCHIVE = `${ANOMALIES}/files`;
export const FILTERS_PO_DEVICE_LIST = `${ANOMALIES}/filter_list_po`;
export const FILTERS_PRIEM_DEVICE_LIST = `${ANOMALIES}/filter_list_priem`;
export const ANOMALIES_EFFICIENCY = `${ANOMALIES}/effeciency`;
export const MAKE_ACTIVE = `${ANOMALIES}/back_from_archive`;

export const GET_BILLING_LIST = `${MAIN_URL}/billing/detail`;
export const GET_XLSX_BILING = `${MAIN_URL}/billing/billing_xlsx`;
export const GET_BILL_TARIFF = `${MAIN_URL}/billing/tariffication_detail`;
export const SUBMIT_BILL_TARIFF = `${MAIN_URL}/billing/tarifficate`;

export const DEMO_MANAGEMENT = `${MAIN_URL}/demo`;
export const DEMO_MANAGEMENT_UPDATE_STATUS = `${DEMO_MANAGEMENT}/status`;
export const DEMO_GET_SAMPLE_XLSX = `${DEMO_MANAGEMENT}/get_sample_xlsx`;
export const DEMO_GET_SCORE = `${DEMO_MANAGEMENT}/score`;
export const DEMO_GET_CHART = `${DEMO_MANAGEMENT}/meter_data`;
export const DEMO_SUBMIT_FORM = `${DEMO_MANAGEMENT}/load_sample_desc`;
export const DEMO_UPLOAD_XLSX = `${DEMO_MANAGEMENT}/upload_xlsx`;
export const DEMO_DETAILING = `${DEMO_MANAGEMENT}/get_detailing_xlsx`;

export const LOG_OUT = `${MAIN_URL}/auth/logout`;
export const LOG_IN = isDevMode()
  ? `${MAIN_URL}/fake/9651478026`
  : `${MAIN_URL}/auth/login?return_uri=https://${URL}/lk/`;
export const LOG_IN_VALIDATE = `${MAIN_URL}/auth/login/validate`;
export const CHANGE_TENANT = `${MAIN_URL}/auth/choice_tenant`;

export const MAIL_SUPPORT = `mailto:support@energytool.mts.ru`;

export const RESEARCH = `${MAIN_URL}/analytics`;
export const RESEARCH_DEVICE_LIST = `${RESEARCH}/results_pg`;
export const RESEARCH_NEW = `${RESEARCH}/dashboard`;
export const RESEARCH_GET_CHART = `${RESEARCH}/data_norm`;
export const RESEARCH_REP_DASH = `${RESEARCH}/report_dashboard`;
export const RESEARCH_REP_LIST = `${RESEARCH}/report_list`;
export const RESEARCH_SET_TICKET = `${RESEARCH}/ticket_set`;
export const RESEARCH_SET_STATUS = `${RESEARCH}/status_set`;
export const RESEARCH_SET_FB = `${RESEARCH}/status_fb_set`;
export const RESEARCH_SET_BD = `${RESEARCH}/bigdata_scores`;
export const RESEARCH_SET_T2 = `${RESEARCH}/ticket_t2_auto`;
export const RESEARCH_SET_T3 = `${RESEARCH}/ticket_t3_auto`;
export const FILTERS_PO_RESEARCH = `${RESEARCH}/filter_po`;
export const FILTERS_RES_RESEARCH = `${RESEARCH}/filter_res`;
export const FIND_ON_MAP = `${RESEARCH}/find_on_map`;
export const FIND_GEO = `${RESEARCH}/find_geo`;
export const FAVS_LIST = `${RESEARCH}/lists`;
export const FAV_SINGLE = `${RESEARCH}/list_load`;
export const FAVLIST_EDIT = `${RESEARCH}/list_save`;
export const FAVLIST_DEL = `${RESEARCH}/list_del`;
export const HANDBOOK = `${RESEARCH}/re`;
export const FILTER_SAVE = `${RESEARCH}/filter_save`;
export const FILTER_LOAD = `${RESEARCH}/filter_load`;
export const COLUMNS_SAVE = `${RESEARCH}/columns_save`;
export const COLUMNS_LOAD = `${RESEARCH}/columns_load`;
export const COLUMNS_CHECK = `${RESEARCH}/columns_visible`;
export const STAT_RES = `${RESEARCH}/stats_res`;
export const STAT_INFO = `${RESEARCH}/stats_info`;
export const RESEARCH_STATS_ARCHIVE = `${RESEARCH}/status_archive`;

export const GET_EVENTS_LIST = `${MAIN_URL}/admin/user_activity`;
export const GET_MAP_MARKERS = `${MAIN_URL}/list/counter_coordinates`;

export const TASKS = `${MAIN_URL}/tasks`;
export const GET_EMPLOYEES = `${TASKS}/get_employees`;

export const MINING = `${MAIN_URL}/mining`;
export const MINING_INFO = `${MINING}/general_info`;
export const MINING_ARCHIVE = `${MINING}/files`;
export const GET_MINERS = `${MINING}/counter_coordinates`;
export const MINING_DOWN = `${MINING}/download_file`;
export const MINING_TEMP_UPLOAD = `${MINING}/upload_data_for_tenant`;

export const ASKUE = `${MAIN_URL}/energosphere`;
export const ASKUE_DEVICES = `${ASKUE}/counters_list`;
export const ASKUE_DEVICES_XLSX = `${ASKUE}/counters_list_xlsx`;
export const ASKUE_GET_XLSX = `${ASKUE}/get_template_askue`;
export const ASKUE_UPLOAD_XLSX = `${ASKUE}/statement`;
export const ASKUE_INFO = `${ASKUE}/general_info`;
export const ASKUE_SERVER = `${ASKUE}/server_activity`;
export const ASKUE_MANAGEMENT = `${ASKUE}/applications`;
export const ASKUE_MANAGEMENT_UPDATE_STATUS = `${ASKUE}/status`;
export const ASKUE_DEVICE_LIST = `${ASKUE}/device_list`;
export const ASKUE_EVENTS = `${ASKUE}/events_log`;
export const ASKUE_EVENT_XLSX = `${ASKUE}/events_log_xlsx`;
export const ASKUE_ENERGY = `${ASKUE}/meter_energy_list`;
export const ASKUE_ENERGY_CHART = `${ASKUE}/meter_energy_data`;
export const ASKUE_RELE_LOG = `${ASKUE}/meter_rele_log`;
export const ASKUE_UNVER = `${ASKUE}/counters_unverified_xlsx`;
export const ASKUE_DEMO = `${ASKUE}/demo`;

export const SHOP = `${MAIN_URL}/shop`;
export const SHOP_CHANGE_HIDE_CART = `${SHOP}/change_hiding_card`;
export const SHOP_EDIT_CART = `${SHOP}/edit_card`;
