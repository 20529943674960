import { IBreadcrumbsLink } from "interface/common";
import { ROUTE_BILLING_PAGE, ROUTE_USERS } from "stream-constants/route-constants";
import { LIST_ROLES, LIST_ROLES_NAME } from "utils/roles";

export const links: IBreadcrumbsLink[] = [
  { name: "Управление", href: `/${ROUTE_BILLING_PAGE}` },
  { name: "Пользователи", href: `/${ROUTE_USERS}` },
];

export const calcBreadcrumbs = (entiny?: string | null) =>
  entiny != null && entiny !== "" ? links.concat({ name: entiny, href: "" }) : links;

export const FILTER_BY_ROLES = "roles_names";

export const HARDCODED_FILTER_ITEMS = {
  checkbox: {
    [FILTER_BY_ROLES]: [
      {
        id: 0,
        name: LIST_ROLES.ADMIN,
        friendlyName: LIST_ROLES_NAME.ADMIN,
      },
      {
        id: 1,
        name: LIST_ROLES.OWNER,
        friendlyName: LIST_ROLES_NAME.OWNER,
      },
      {
        id: 2,
        name: LIST_ROLES.ANALYTIC,
        friendlyName: LIST_ROLES_NAME.ANALYTIC,
      },
      {
        id: 3,
        name: LIST_ROLES.ENGINEER,
        friendlyName: LIST_ROLES_NAME.ENGINEER,
      },
      {
        id: 4,
        name: LIST_ROLES.ANALYST_SYSTEM,
        friendlyName: LIST_ROLES_NAME.ANALYST_SYSTEM,
      },
    ],
  },
  radio: {},
};

export const TABLE_SORT_DEFAULT_USERS = [
  {
    accessor: "userName",
    text: "Пользователь",
  },
  {
    accessor: "status",
    text: "Статус",
  },
  {
    accessor: "info",
    text: "Организация",
  },
];
