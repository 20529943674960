import { FC, useContext } from "react";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";

import { MTSInput } from "components/mts-input";
import { MTSButton } from "components/mts-button/mts-button";
import { useAddUser, useManagementTenants } from "hooks/api/management";
import { MTSAutocomplete } from "components/mts-autocomplete/mts-autocomplete";
import { PHONE_MASK } from "stream-constants/keys-and-constants";
import { unformatPhone } from "utils/formatters";
import {
  calcDropdownRoles,
  convertIdToOrgname,
  convertOrgnameToId,
  convertRoleToObject,
  makeDropdownFromTenants,
  schema,
} from "./validation-schema";
import { LIST_ROLES } from "utils/roles";
import { ContextSettings } from "context";
import { Loader } from "components/loader/loader";
import { Spacer } from "components/spacer/spacer.component";
import { Toast } from "components/toast/toast";
import { SBtns, SForm, SFormField } from "./styles";
import { SModalTitle } from "components/modal-notify-body/styles";

type IProps = {
  onCancel?: any;
  withTenantRefresh?: boolean;
  tenantId?: string;
  isCanChangePreselected?: boolean;
};

export const FormAddUser: FC<IProps> = ({
  onCancel,
  withTenantRefresh,
  tenantId,
  isCanChangePreselected,
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {},
    resolver: yupResolver(schema),
    mode: "onTouched",
    shouldFocusError: false,
    reValidateMode: "onChange",
  });

  const { roles } = useContext(ContextSettings);
  const mutation = useAddUser(withTenantRefresh);
  const tenantsData = useManagementTenants();

  const isAdmin = roles.includes(LIST_ROLES.ADMIN);
  const rolesArray = calcDropdownRoles(roles);

  const onSubmit = async (submData) => {
    mutation.mutate({
      userName: submData.userName,
      email: submData.email,
      phone: unformatPhone(submData.phone),
      roles: convertRoleToObject(submData.roles),
      tenantId: !tenantId
        ? convertOrgnameToId(submData.tenant, tenantsData?.data)
        : String(tenantId),
    });
  };

  if (mutation?.isSuccess) {
    onCancel();
    toastSucc();
  } else if (mutation?.isError) {
    onCancel();
    toastErr();
  }

  return (
    <>
      <SModalTitle>Добавить пользователя</SModalTitle>
      <SForm onSubmit={handleSubmit(onSubmit)}>
        <SFormField>
          <MTSInput
            label="ФИО"
            control={control}
            {...register("userName", {
              required: true,
            })}
            errorMessage={errors?.userName?.message}
          />
        </SFormField>
        <SFormField>
          <InputMask mask={PHONE_MASK} {...register("phone")}>
            {() => (
              <MTSInput
                label="Телефон"
                {...register("phone", {
                  required: true,
                })}
                control={control}
                errorMessage={errors?.phone?.message}
              />
            )}
          </InputMask>
        </SFormField>
        <SFormField>
          <MTSInput
            label="Электронная почта"
            {...register("email", {
              required: true,
            })}
            control={control}
            errorMessage={errors?.email?.message}
          />
        </SFormField>
        {isAdmin ? (
          <SFormField>
            {!tenantId ? (
              tenantsData.isLoading ? (
                <Loader />
              ) : (
                <MTSAutocomplete
                  label="Организация"
                  placeholder=""
                  options={makeDropdownFromTenants(tenantsData?.data)}
                  control={control}
                  size="M"
                  {...register("tenant")}
                  errorMessage={errors?.roles?.message}
                />
              )
            ) : (
              <MTSAutocomplete
                label="Организация"
                placeholder={convertIdToOrgname(tenantId, tenantsData?.data)}
                options={isCanChangePreselected ? makeDropdownFromTenants(tenantsData?.data) : []}
                control={control}
                size="M"
                name="disabled_tenant"
                disabled={!isCanChangePreselected}
              />
            )}
          </SFormField>
        ) : null}

        <SFormField>
          <MTSAutocomplete
            label="Роль"
            placeholder=""
            options={rolesArray}
            control={control}
            size="M"
            {...register("roles")}
            errorMessage={errors?.roles?.message}
            variant="checkbox"
          />
        </SFormField>
        <Spacer value="8px" />
        <SBtns>
          <MTSButton size="M" variant="primary" type="submit" onClick={() => undefined}>
            Сохранить
          </MTSButton>
          <MTSButton size="M" variant="secondary" onClick={onCancel}>
            Отмена
          </MTSButton>
        </SBtns>
      </SForm>
    </>
  );
};

const toastSucc = () => {
  toast(<Toast title="Пользователь успешно добавлен" />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });
};

const toastErr = () => {
  toast(<Toast title="Пользователь не добавлен" isError />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });
};
