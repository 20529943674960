import { useState } from "react";
import { isEmpty } from "lodash";

import { Loader } from "components/loader/loader";
import Icon from "components/icons";
import { IDemoMngScoreResponse } from "interface/management";
import { dqToScore, makeDetailPanel, makeScorePanel, titleByKey } from "./utils";
import { CopyToClipboard } from "components/copyToClipboard/copyToClipboard.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SCont, SInner, SSpecLi, STabItem, STabs } from "./substyles";

const commonStyles = {
  width: 18,
  height: 18,
};

const renderIcon = (score) =>
  score === "green" ? (
    <Icon.CheckShaped sx={{ ...commonStyles }} />
  ) : score === "yellow" ? (
    <Icon.Warn sx={{ ...commonStyles }} />
  ) : score === "red" ? (
    <Icon.ErrorShaped sx={{ ...commonStyles }} />
  ) : null;

type IProp = {
  isLoading: boolean;
  data?: IDemoMngScoreResponse;
};

export const ScoreDetails: React.FC<IProp> = ({ data, isLoading }) => {
  const [showTab, setShowTab] = useState<"score" | "info">("score");

  const handleSwitchTabs = (value: "score" | "info") => {
    setShowTab(value);
  };

  return (
    <SCont onClick={(event) => event.stopPropagation()}>
      <div>
        <STabs>
          <STabItem isActive={showTab === "score"} onClick={() => handleSwitchTabs("score")}>
            <CusTypo variant="p4_regular">Оценка качества данных</CusTypo>
          </STabItem>
          <STabItem isActive={showTab === "info"} onClick={() => handleSwitchTabs("info")}>
            <CusTypo variant="p4_regular">Дополнительная информация</CusTypo>
          </STabItem>
        </STabs>
      </div>
      <SInner>
        {showTab === "score" ? (
          <ul>
            {isLoading ? (
              <Loader />
            ) : !isLoading && !isEmpty(data) ? (
              <>
                {Object.entries(makeScorePanel(data))?.map(([key, val]) => (
                  <SSpecLi key={key}>
                    <div>
                      <CusTypo variant="c1_regular">{titleByKey[key]}</CusTypo>
                    </div>
                    <span>
                      <CusTypo variant="c1_medium">{val.text}</CusTypo>
                      {renderIcon(val.score)}
                    </span>
                  </SSpecLi>
                ))}
                <SSpecLi color={data?.dq_score}>
                  <div>
                    <CusTypo variant="p4_medium">Общая оценка качества</CusTypo>
                  </div>
                  <span>
                    <CusTypo variant="p3_bold">{data?.dq_score}</CusTypo>
                    {renderIcon(dqToScore(data?.dq_score))}
                  </span>
                </SSpecLi>
              </>
            ) : (
              <SSpecLi>
                <CusTypo variant="c1_medium">Нет данных для отображения</CusTypo>
              </SSpecLi>
            )}
          </ul>
        ) : !isEmpty(data) ? (
          Object.entries(makeDetailPanel(data))?.map(([key, val]) => (
            <SSpecLi key={key}>
              <div>
                <CusTypo variant="c1_regular">{titleByKey[key]}</CusTypo>
              </div>
              <span>
                {val["isCanCopy"] ? (
                  <CopyToClipboard text={val.text ?? "нет значения"} />
                ) : (
                  <CusTypo variant="c1_medium">{val.text}</CusTypo>
                )}
              </span>
            </SSpecLi>
          ))
        ) : null}
      </SInner>
    </SCont>
  );
};
