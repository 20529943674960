import styled from "@emotion/styled";

export const SFormCheck = styled.div`
  text-align: left;
  overflow-y: auto;
`;

export const SControls = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  gap: 16px;
`;

export const SSpinnerWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1;
`;
