import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  forwardRef,
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import { isEmpty, get } from "lodash";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import theme from "theme";

import { EDepStats, IMapMarker, IMapMiner } from "interface/map";
import { useDebounce } from "hooks/useDebounce";
import useOnClickOutside from "hooks/useOnClickOutside";
import Icon from "components/icons";
import { SlideIn } from "components/table/slideIn/slideIn.component";
import { MTSInput } from "components/mts-input";
import { MTSStatus } from "components/mts-status/mts-status";
import { MTSButton } from "components/mts-button/mts-button";
import { Spacer } from "components/spacer/spacer.component";
import { MTSModal } from "components/mts-modal/mts-modal";
import { CopyToClipboard } from "components/copyToClipboard/copyToClipboard.component";
import { Toast } from "components/toast/toast";
import { PopupAction } from "components/popup-action/popup-action";
import { preparePanFilter, makePopData } from "./utils";
import { CusTypo } from "components/cusTypo/custom-typography";
import {
  ColorCircle,
  SBord,
  SFilCont,
  SFilEl,
  SLPanWrap,
  SLeaflet,
  SMinerPopup,
  SPanFilCont,
  SRow,
  SRowBorder,
  SRowEnd,
  SSearch,
  SStack,
} from "../styles";
import { ELayers, IFormDepartures, ISearch, ISel, scoreToTitle } from "../const";
import { ContextSettings } from "context/context-settings";
import { ModalAction } from "components/modal-action/modal-action";

interface IMarkerPop {
  openCheck: () => void;
  cancelDeparture?: (a) => void;
  setForm: Dispatch<SetStateAction<IFormDepartures[] | null>>;
  data?: IMapMarker;
  view?: "min" | "stand";
}

export const PopContent: React.FC<IMarkerPop> = ({
  openCheck,
  setForm,
  data,
  cancelDeparture,
  view = "stand",
}) => {
  return (
    <div style={{ display: "flex", flexFlow: "column nowrap", alignItems: "flex-start" }}>
      <CusTypo variant="p3_medium" font="comp">
        Серийный № {data?.sn ?? "XXXX"}
      </CusTypo>
      <Spacer value="4px" />

      <CusTypo
        variant="p4_regular"
        font="comp"
        styles={{ color: theme.mtsColor.text.secondary.lightMode, textAlign: "left" }}
      >
        {data?.adress_too ?? "нет адреса"}
      </CusTypo>
      <Spacer value="6px" />
      {MTSStatus(`${data?.task_status}`)}
      <Spacer value="10px" />
      <SRow>
        <CusTypo variant="c1_regular">Результат последней проверки</CusTypo>
        {MTSStatus(`${data?.fraud_probability}`)}
      </SRow>
      <Spacer value="10px" />
      <SRow>
        <CusTypo variant="c1_regular">Газ</CusTypo>
        {data?.gaz_flg == null ? (
          <div style={{ width: 74, textAlign: "center" }}>—</div>
        ) : data?.gaz_flg ? (
          <Icon.CheckShaped sx={{ width: 18, height: 18 }} />
        ) : (
          <Icon.ErrorShaped sx={{ width: 18, height: 18 }} />
        )}
      </SRow>
      <Spacer value={view === "stand" ? "40px" : "16px"} />
      {data?.task_status === EDepStats["Проверяется"] ? (
        <MTSButton
          size={view === "stand" ? "M" : "S"}
          onClick={() => cancelDeparture?.(data.id_pp)}
          disabled={cancelDeparture === undefined}
        >
          Отменить проверку
        </MTSButton>
      ) : (
        <MTSButton
          size={view === "stand" ? "M" : "S"}
          variant="dark_blue"
          onClick={() => {
            openCheck();
            setForm([data] as any);
          }}
        >
          Назначить проверку
        </MTSButton>
      )}
    </div>
  );
};

export const HoverPopup = ({ point }) => {
  return <div>{point.adress_too ?? "no address"}</div>;
};

interface IMinerPopup {
  data: IMapMiner;
}

export const MinerPopup: React.FC<IMinerPopup> = ({ data }) => {
  return (
    <SMinerPopup>
      <CusTypo variant="p3_medium" font="comp">
        ZID {data.zid ?? "XXXX"}
      </CusTypo>
      <Spacer value="12px" />
      <SRowBorder>
        <CusTypo variant="c1_regular">Дата последней активности</CusTypo>
        <CusTypo variant="c1_regular">
          {format(new Date(data.dt ?? 0), "dd MMMM yyyy", { locale: ru })}
        </CusTypo>
      </SRowBorder>
      <Spacer value="8px" />
      <SRowBorder>
        <CusTypo variant="c1_regular">Частота активности</CusTypo>
        <SRowEnd>
          <ColorCircle score={data.score} />
          <CusTypo variant="c1_regular">{scoreToTitle(data.score)}</CusTypo>
        </SRowEnd>
      </SRowBorder>
      <Spacer value="8px" />
      <SRowBorder>
        <CusTypo variant="c1_regular">Скоринговый балл</CusTypo>
        <CusTypo variant="c1_regular">{data.score}</CusTypo>
      </SRowBorder>
      <Spacer value="8px" />
      <SRowBorder>
        <CusTypo variant="c1_regular">Регион</CusTypo>
        <CusTypo variant="c1_regular">{data.region_name}</CusTypo>
      </SRowBorder>
      <Spacer value="8px" />
      <SRowBorder>
        <CusTypo variant="c1_regular">Координаты</CusTypo>
        <CusTypo variant="c1_regular">
          <CopyToClipboard text={`${data.mean_y}, ${data.mean_x}`} />
        </CusTypo>
      </SRowBorder>
    </SMinerPopup>
  );
};

export const SimpleSearch: React.FC<ISearch> = ({ onBtnClick, onItemClick, list, disabled }) => {
  const { isDesktop } = useContext(ContextSettings);
  const [searchVal, setSearch] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [suggestions, setSuggest] = useState<IMapMarker[] | undefined>([]);
  const debouncedSearch = useDebounce(searchVal, 500);
  const ulRef = useRef(null);

  const handleInput = (e) => {
    setSearch(e.target.value);
    setOpen(true);
  };

  const handleSuggClick = (payload) => {
    setSearch("");
    setOpen(false);
    const { lat, lng, id_pp, adress_too } = payload;
    onItemClick({ lat, lng, id_pp, adress_too });
  };

  useOnClickOutside(ulRef, () => setOpen(false));

  useEffect(() => {
    if (debouncedSearch) {
      const edited = debouncedSearch.toLowerCase().trim();
      setSuggest(
        list?.filter(
          (a) =>
            a.adress_too?.toLowerCase().includes(edited) ||
            a.id_pp.toString().includes(edited) ||
            a.sn?.includes(edited),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  return (
    <SSearch>
      <MTSInput
        className="searchField"
        searchIcon
        placeholder="Введите адрес, серийный номер или id_pp"
        onChange={handleInput}
        value={searchVal}
        size={isDesktop() ? "M" : "S"}
        disabled={disabled}
      />
      {!isEmpty(suggestions) && isOpen ? (
        <ul ref={ulRef}>
          {suggestions?.map((a) => (
            <li key={a.id_pp} onClick={() => handleSuggClick(a)}>
              {`${a.id_pp}, ${a.adress_too}`}
            </li>
          ))}
        </ul>
      ) : null}
      <MTSButton size={isDesktop() ? "M" : "S"} variant="dark_blue" onClick={onBtnClick}>
        Назначить проверку
      </MTSButton>
    </SSearch>
  );
};

interface IPan {
  isOpen: boolean;
  close: () => void;
  selection: ISel[];
  group: IMapMarker[];
  openCheck: () => void;
  cancelDeparture: (a) => void;
  setForm: (data: any) => any;
}
export const LeftPanel: React.FC<IPan> = ({
  isOpen,
  close,
  group,
  selection,
  openCheck,
  setForm,
  cancelDeparture,
}) => {
  const { isDesktop } = useContext(ContextSettings);
  const [filt, setFilt] = useState<string[]>([]);
  const toggle = (a) => setFilt((s) => (s.includes(a) ? s.filter((el) => el !== a) : s.concat(a)));
  const getFiltered = (arr: any[], path) =>
    arr.filter((o) => (isEmpty(filt) ? true : filt.includes(get(o, path))));

  return (
    <SlideIn
      isOpen={isOpen}
      close={close}
      withArrow={!isDesktop()}
      withCloseHook={false}
      zIndex={410}
      direction="left"
      style={{ position: "absolute", top: 0, paddingBottom: 0 }}
    >
      <SLPanWrap>
        {!isEmpty(group) ? (
          <>
            <PanelFilter
              items={group}
              setter={toggle}
              cur={filt}
              openCheck={openCheck}
              cancelDeparture={cancelDeparture}
              setForm={setForm}
            />
            <Spacer value="8px" />
            {getFiltered(group, "task_status")?.map((gr, ind, self) => (
              <Fragment key={gr.id_pp}>
                <SBord>
                  <PopContent
                    data={gr}
                    openCheck={openCheck}
                    setForm={setForm}
                    cancelDeparture={cancelDeparture}
                    view="min"
                  />
                </SBord>
                {ind !== self.length - 1 && !isDesktop() ? <Spacer value="12px" /> : null}
              </Fragment>
            ))}
          </>
        ) : !isEmpty(selection) ? (
          <>
            <PanelFilter
              items={selection}
              setter={toggle}
              cur={filt}
              openCheck={openCheck}
              cancelDeparture={cancelDeparture}
              setForm={setForm}
            />
            <Spacer value="8px" />
            {getFiltered(selection, "pointData.task_status")?.map((m) => (
              <SBord key={m.pointData.id_pp}>
                <PopContent
                  data={m.pointData}
                  openCheck={openCheck}
                  setForm={setForm}
                  cancelDeparture={cancelDeparture}
                  view="min"
                />
              </SBord>
            ))}
          </>
        ) : (
          <span>Нет выбранных точек</span>
        )}
      </SLPanWrap>
    </SlideIn>
  );
};

const FiltElem = ({
  status,
  quant,
  handler,
  cur,
}: {
  status: string;
  quant: number;
  handler: Function;
  cur: string[];
}) => {
  const isSel = cur.includes(status);
  const col = isSel ? "white" : undefined;

  return (
    <SFilEl onClick={() => handler(status)} isSelected={isSel}>
      {MTSStatus(status, undefined, true)}

      <CusTypo variant="c1_regular" font="comp" styles={{ color: col, height: "12px" }}>
        {status}
      </CusTypo>

      <CusTypo variant="c1_medium" styles={{ color: col, fontWeight: 500 }}>
        {quant}
      </CusTypo>
    </SFilEl>
  );
};

export const PanelFilter = ({
  items,
  setter,
  cur,
  openCheck,
  cancelDeparture,
  setForm,
}: {
  items: ISel[] | IMapMarker[];
  setter: Function;
  cur: string[];
  openCheck: () => void;
  cancelDeparture: (a) => void;
  setForm: Dispatch<SetStateAction<IFormDepartures[] | null>>;
}) => {
  const { isDesktop } = useContext(ContextSettings);
  const [isOpen, setOpen] = useState(false);
  const { filter, toDeparture, toCancel } = preparePanFilter(items);
  const popData = makePopData({
    arr: Object.entries(filter),
    openCheck,
    invokeModal: () => setOpen(true),
    setForm,
    toDeparture,
    toCancel,
  });

  return (
    <SPanFilCont>
      <section>
        <CusTypo variant="h4_medium">Список адресов</CusTypo>
        {isDesktop() ? (
          <PopupAction items={popData} pos="center" />
        ) : (
          <ModalAction items={popData} />
        )}
      </section>

      <Spacer value="16px" />
      <SFilCont>
        {Object.entries(filter).map(([key, val]) => (
          <FiltElem key={`${key}_${val}`} quant={val} status={key} handler={setter} cur={cur} />
        ))}
      </SFilCont>

      <MTSModal open={isOpen} close={() => setOpen(false)}>
        <SStack>
          <Icon.Warn sx={{ width: 44, height: 44 }} />
          <CusTypo variant="h4_medium" font="comp">
            Вы действительно хотите отменить проверку?
          </CusTypo>
          <MTSButton size="M" variant="primary" onClick={() => cancelDeparture(toCancel)}>
            Отменить проверку
          </MTSButton>
          <MTSButton size="M" variant="secondary" onClick={() => setOpen(false)}>
            Закрыть
          </MTSButton>
        </SStack>
      </MTSModal>
    </SPanFilCont>
  );
};

const MapContainer = forwardRef<HTMLDivElement, any>(function MapContainerComponent(
  { className },
  ref?,
) {
  return <SLeaflet id="myMap" ref={ref} />;
});

export const MemoizedMap = memo(MapContainer, () => true);

const entToTitle = {
  [ELayers.DEP]: "аномалии",
  [ELayers.MIN]: "случаи майнинга",
};

export const toastOnEmpty = (ent: string) =>
  toast(
    <Toast
      title={`На данный момент ${entToTitle[ent]} не обнаружены\n(либо фильтрация не дала результатов),\nсписок анализируется и формируется`}
    />,
    {
      autoClose: 5000,
      hideProgressBar: false,
    },
  );

export const toastOnError = () =>
  toast(<Toast title="Ошибка запроса данных" isError />, {
    progress: undefined,
    autoClose: 5000,
    hideProgressBar: true,
  });

export const toastDepSubmtSucc = () =>
  toast(<Toast title="Проверка отменена" />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });

export const toastDepSubmtFail = () =>
  toast(<Toast title="Не удалось отменить проверку" isError />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });

export const toastDel = () => toast.dismiss();
