import styled from "@emotion/styled";

export const SPage = styled.div`
  width: 100%;

  & > article {
    & > ul {
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
        white-space: nowrap;
        padding: 8px 12px;
        background-color: #f9f9fb;

        &:not(:last-of-type) {
          margin-bottom: 2px;
        }

        & > span {
          &:first-of-type {
            text-align: left;
            width: 120px;
            flex: 0 0 120px;
            color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
          }

          &:last-of-type {
            flex: 1 1 auto;
          }

          &:not(:first-of-type) {
            white-space: normal;
            text-align: right;
          }
        }
      }
    }
  }
`;

export const SActions = styled.div`
  & > ul {
    margin-bottom: 10px;
    & > li {
      padding: 10px 16px;
    }
  }
  & > button {
    width: 100%;
  }
`;

export const SName = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
