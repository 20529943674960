import styled from "@emotion/styled";

export const SWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
`;

export const SCheck = styled.div<{ isError?: boolean; isLoading?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 9px;
  width: 18px;
  height: 18px;
  background: ${(props) => props.theme.mtsColor.accent.positive.lightMode};
  border-radius: 6px;

  & > svg {
    width: 18px;
    height: 18px;
    fill: ${(props) => props.theme.mtsColor.background.primary.darkMode};
  }

  ${(props) =>
    props.isError &&
    `
	background-color: ${props.theme.mtsColor.accent.negative.lightMode};
  `}

  ${(props) =>
    props.isLoading &&
    `
	& path {
		fill: ${props.theme.mtsColor.base.warning}
	}
  `}
`;
