import { ReactElement } from "react";
import theme from "theme";
import styled from "@emotion/styled";

import { Base, Filled } from "./icon.component";
import { SCheckbox, SCheck, SCheckInput, SLabel } from "./styles";

interface ICheckboxProps {
  value?: string | number;
  id?: string;
  checked?: boolean;
  onChange?: (val: string | number) => void;
  className?: string;
  readOnly?: boolean;
  label?: string | ReactElement;
  hasError?: boolean;
  labelLeft?: boolean;
  labelWrap?: string;
  color?: "red" | "black";
  disabled?: boolean;
  name?: string;
}

const StyledCheckbox: React.FC<ICheckboxProps> = ({
  onChange,
  id,
  checked = false,
  value,
  readOnly = false,
  className = "",
  label,
  hasError,
  labelLeft,
  labelWrap = "nowrap",
  color = "black",
  disabled = false,
  name = "checkbox",
}) => {
  return (
    <SCheckbox htmlFor={id} labelLeft={labelLeft}>
      <SCheckInput
        id={id}
        name={name}
        value={value}
        type="checkbox"
        checked={checked}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange?.(e.target.value)}
        readOnly={readOnly}
        disabled={disabled}
      />
      <SCheck checked={checked} className={className} hasError={hasError}>
        {!checked ? (
          <Base
            className="checkbox__icon"
            border={hasError ? theme.mtsColor.red : undefined}
            fill={disabled ? "#BCC3D05F" : undefined}
          />
        ) : (
          <Filled
            fill={
              color === "black" ? theme.mtsColor.background.inverted.lightMode : theme.mtsColor.red
            }
            className="checkbox__icon"
          />
        )}
      </SCheck>
      {label ? (
        <SLabel variant="p4_regular" labelLeft={labelLeft} labelWrap={labelWrap}>
          {label}
        </SLabel>
      ) : null}
    </SCheckbox>
  );
};

export const Checkbox = styled(StyledCheckbox)``;
