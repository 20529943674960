import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { MTS_WIDE } from "theme";

import bgWelcomePage from "./images/bgWelcomePage.png";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  landing: {
    width: "100%",
  },
  article: {
    position: "relative",
    minHeight: "calc(100vh - 128px)",
    marginTop: "64px",
  },
  articleHeader: {
    height: "64px",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    left: "0",
    top: "0",
    right: "0",
    bottom: "0",
    zIndex: 1,
    padding: "0 40px",
  },
  projectName: {
    "&.MuiTypography-root": {
      color: theme.mtsColor.red,
      marginLeft: "10px",
    },
  },
  containerGeo: {
    position: "absolute",
    left: "0",
    top: "-64px",
    right: "0",
    bottom: "0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "64px 40px 0 40px",
    backgroundImage: `url(${bgWelcomePage})`,
    backgroundSize: "auto 100%",
    backgroundPosition: "right",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.mtsColor.background.primaryElevated.darkMode,
  },
  blockInfoBtn: {
    display: "flex",
    marginTop: "44px",
  },
  title: {
    display: "block",
    fontFamily: `${MTS_WIDE}, sans-serif`,
    textAlign: "left",
    color: "#FFFFFF !important",
  },
  text: {
    display: "block",
    textAlign: "left",
    marginTop: "26px !important",
    color: "#FFFFFF !important",
  },
  subtext: {
    display: "block",
    maxWidth: "689px",
    marginTop: "32px !important",
    textAlign: "left",
    color: "#FAFAFA !important",
  },
}));

export default useStyles;
