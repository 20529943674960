import styled from "@emotion/styled";

export const SBtns = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;

  & .MuiButton-root {
    white-space: nowrap;
  }
`;
