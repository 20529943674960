import { calcFooterNonNull } from "../researchTable/utils";

export const tableStructure = [
  {
    accessor: "id",
    minWidth: 0,
    maxWidth: 0,
  },
  {
    sticky: "left",
    id: "id_pp",
    Header: null,
    Footer: (table) => calcFooterNonNull(table, "id_pp"),
    columns: [
      {
        accessor: "id_pp",
        minWidth: 68,
        width: 68,
        sticky: "left",
        Footer: (table) => calcFooterNonNull(table, "id_pp"),
      },
    ],
  },
  {
    id: "abonent",
    headerTitle: "abonent",
    Footer: () => null,
    columns: [
      {
        accessor: "sn",
        minWidth: 140,
        width: 140,
      },
      {
        accessor: "fl_ul",
        minWidth: 90,
        width: 90,
      },
      {
        accessor: "name_res",
        minWidth: 160,
        width: 160,
      },
    ],
  },
  {
    id: "status",
    headerTitle: "status",
    Footer: () => null,
    columns: [
      {
        accessor: "fp",
        minWidth: 112,
        width: 112,
      },
      {
        accessor: "fp_dc",
        minWidth: 155,
        width: 155,
      },
      {
        accessor: "fp_uc",
        minWidth: 160,
        width: 160,
      },
      {
        accessor: "fp_cm",
        minWidth: 194,
        width: 194,
      },
    ],
  },
  {
    id: "tickets",
    headerTitle: "tickets",
    Footer: () => null,
    columns: [
      {
        accessor: "t0",
        minWidth: 48,
        width: 48,
        Footer: (table) => calcFooterNonNull(table, "t0"),
      },
      {
        accessor: "t1",
        minWidth: 48,
        width: 48,
        Footer: (table) => calcFooterNonNull(table, "t1"),
      },
      {
        accessor: "t1_dc",
        minWidth: 120,
        width: 120,
      },
      {
        accessor: "t2",
        minWidth: 48,
        width: 48,
        Footer: (table) => calcFooterNonNull(table, "t2"),
      },
      {
        accessor: "t2_kc",
        minWidth: 110,
        width: 110,
      },
      {
        accessor: "t3",
        minWidth: 48,
        width: 48,
        Footer: (table) => calcFooterNonNull(table, "t3"),
      },
      {
        accessor: "t4",
        minWidth: 48,
        width: 48,
        Footer: (table) => calcFooterNonNull(table, "t4"),
      },
      {
        accessor: "t5",
        minWidth: 48,
        width: 48,
        Footer: (table) => calcFooterNonNull(table, "t5"),
      },
    ],
  },
  {
    id: "consumption",
    headerTitle: "consumption",
    Footer: () => null,
    columns: [
      {
        accessor: "d_winter",
        minWidth: 80,
        width: 80,
      },
      {
        accessor: "d_summer",
        minWidth: 80,
        width: 80,
      },
      {
        accessor: "n_summer",
        minWidth: 80,
        width: 80,
      },
      {
        accessor: "koef_dn",
        minWidth: 88,
        width: 88,
      },
      {
        accessor: "amplitude",
        minWidth: 80,
        width: 80,
      },
      {
        accessor: "multiplicity",
        minWidth: 92,
        width: 92,
      },
      {
        accessor: "cnt_04",
        minWidth: 84,
        width: 84,
      },
      {
        accessor: "cnt_008",
        minWidth: 92,
        width: 92,
      },
      {
        accessor: "consumption",
        minWidth: 136,
        width: 136,
      },
      {
        accessor: "score",
        minWidth: 64,
        width: 64,
      },
    ],
  },
  {
    id: "address",
    headerTitle: "address",
    Footer: () => null,
    columns: [
      {
        accessor: "district",
        minWidth: 96,
        width: 96,
      },
      {
        accessor: "tol",
        minWidth: 54,
        width: 54,
      },
      {
        accessor: "locality",
        minWidth: 146,
        width: 146,
      },
      {
        accessor: "street",
        minWidth: 128,
        width: 128,
      },
      {
        accessor: "house",
        minWidth: 54,
        width: 54,
      },
      {
        accessor: "apartment",
        minWidth: 54,
        width: 54,
      },
    ],
  },
  {
    id: "bigdata",
    headerTitle: "bigdata",
    Footer: () => null,
    columns: [
      {
        accessor: "type1",
        minWidth: 64,
        width: 64,
      },
      {
        accessor: "type2",
        minWidth: 64,
        width: 64,
      },
      {
        accessor: "type3",
        minWidth: 64,
        width: 64,
      },
      {
        accessor: "type4",
        minWidth: 64,
        width: 64,
      },
      {
        accessor: "type5",
        minWidth: 64,
        width: 64,
      },
      {
        accessor: "unitype",
        minWidth: 74,
        width: 74,
      },
    ],
  },
  {
    id: "feedback",
    headerTitle: "feedback",
    Footer: () => null,
    columns: [
      { accessor: "dt_send", minWidth: 104, width: 104 },
      { accessor: "dt_get", minWidth: 104, width: 104 },
      { accessor: "phase_a_cl", minWidth: 90, width: 90 },
      { accessor: "phase_a_pu", minWidth: 68, width: 68 },
      { accessor: "phase_b_cl", minWidth: 90, width: 90 },
      { accessor: "phase_b_pu", minWidth: 68, width: 68 },
      { accessor: "phase_c_cl", minWidth: 90, width: 90 },
      { accessor: "phase_c_pu", minWidth: 68, width: 68 },
      { accessor: "result_verify", minWidth: 146, width: 146 },
      { accessor: "comment_verify", minWidth: 200, width: 200 },
      { accessor: "dt_change", minWidth: 100, width: 100 },
      { accessor: "sn_change", minWidth: 96, width: 96 },
      { accessor: "type_hack", minWidth: 96, width: 96 },
    ],
  },
];
