import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "lodash";

import { MTSInput } from "components/mts-input";
import { MTSButton } from "components/mts-button/mts-button";
import { MTSAutocomplete } from "components/mts-autocomplete/mts-autocomplete";
import { ModalNotifyBody } from "components/modal-notify-body/modal-notify-body";
import { SERVICES, TIME_ZONE } from "stream-constants/keys-and-constants";
import { SBtns, SFormAddOrg, SFormField } from "./styles";
import { FALLBACK_MSG, schema, makeDefaultForEdit } from "./validation-schema";
import { useGetTenant, useUpdateTenant } from "hooks/api/management";
import { Loader } from "components/loader/loader";
import { SModalTitle } from "components/modal-notify-body/styles";
import { Spacer } from "components/spacer/spacer.component";
import { ITenantResponse } from "interface/management";
import { getAdditionalUsers } from "./const";

const productsArray = Object.values(SERVICES);

type IProps = {
  onCancel: Function;
  id: string;
};

export const FormEditTenant: FC<IProps> = ({ onCancel, id }) => {
  const tenantPrefilled = useGetTenant(id);
  const mutation = useUpdateTenant();
  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { dirtyFields, errors, isDirty },
    reset,
  } = useForm<any>({
    defaultValues: {},
    resolver: yupResolver(schema),
    mode: "onTouched",
    shouldFocusError: false,
    reValidateMode: "onChange",
  });

  const onSubmit = (submData) => {
    mutation.mutate({
      data: {
        title: dirtyFields["title"] != null ? submData.title : undefined,
        contr_num: dirtyFields["contr_num"] != null ? submData.contr_num : undefined,
        service_type: dirtyFields["service_type"] != null ? submData.service_type : undefined,
        time_schema: dirtyFields["time_schema"] != null ? submData.time_schema : undefined,
        additional_info:
          dirtyFields["additional_info"] != null && !isEmpty(submData.additional_info)
            ? submData.additional_info
            : "",
      },
      id,
    });
  };

  useEffect(() => {
    reset(makeDefaultForEdit(tenantPrefilled?.data as ITenantResponse));
  }, [reset, tenantPrefilled.data]);

  if (mutation?.isSuccess) {
    return <ModalNotifyBody title="Информация обновлена" isSuccess />;
  } else if (mutation?.isError) {
    return (
      <ModalNotifyBody title="Информация не обновлена" isError textDescrioption={FALLBACK_MSG} />
    );
  } else {
    return (
      <>
        <SModalTitle>Редактировать организацию</SModalTitle>
        <SFormAddOrg onSubmit={handleSubmit(onSubmit)}>
          <SFormField>
            <MTSInput
              label="Название организации"
              {...register("title", {
                required: true,
              })}
              control={control}
              errorMessage={errors?.title?.message}
            />
          </SFormField>
          <SFormField>
            <MTSInput
              label="Номер договора"
              {...register("contr_num", {
                required: true,
              })}
              control={control}
              errorMessage={errors?.contr_num?.message}
            />
          </SFormField>
          <SFormField>
            {tenantPrefilled.isLoading ? (
              <Loader />
            ) : getValues("service_type") ? (
              <MTSAutocomplete
                label="Оказываемые услуги"
                placeholder=""
                options={productsArray}
                control={control}
                size="M"
                variant="checkbox"
                {...register("service_type", {
                  required: true,
                })}
                errorMessage={errors?.service_type?.message}
              />
            ) : null}
          </SFormField>
          <SFormField>
            {tenantPrefilled.isLoading ? (
              <Loader />
            ) : getValues("additional_info") ? (
              <MTSAutocomplete
                label="Ответственный"
                placeholder=""
                options={getAdditionalUsers(tenantPrefilled?.data)}
                control={control}
                size="M"
                {...register("additional_info")}
              />
            ) : null}
          </SFormField>
          <SFormField>
            {tenantPrefilled.isLoading ? (
              <Loader />
            ) : getValues("time_schema") ? (
              <MTSAutocomplete
                label="Часовой пояс"
                placeholder=""
                options={TIME_ZONE}
                control={control}
                size="M"
                {...register("time_schema", {
                  required: true,
                })}
                errorMessage={errors?.time_schema?.message}
              />
            ) : null}
          </SFormField>
          <Spacer value="8px" />
          <SBtns>
            <MTSButton
              size="M"
              variant="primary"
              type="submit"
              onClick={() => undefined}
              disabled={!isDirty}
            >
              Сохранить
            </MTSButton>
            <MTSButton size="M" variant="secondary" onClick={() => onCancel()}>
              Отмена
            </MTSButton>
          </SBtns>
        </SFormAddOrg>
      </>
    );
  }
};
