import styled from "@emotion/styled";

export const SCont = styled.div`
  flex: 1 1 100%;
  padding: 12px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  text-align: left;

  & > section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const SItems = styled.div`
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0;

    & > span:first-of-type {
      color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid ${(props) => props.theme.mtsColor.background.secondary.lightMode};
    }
  }
`;
