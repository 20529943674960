const requiredOrganizationName = (value: string): boolean => {
  return value?.length > 0;
};

const correctContractNumber = (value: string): boolean => {
  if (value) {
    const contractNumberCheck = /^[A-Za-zА-ЯЁа-яё0-9\s]+$/;

    return contractNumberCheck.test(value) ? true : false;
  }

  return true;
};

const requiredContractNumber = (value: string): boolean => {
  return value?.length > 0;
};

const requiredServiceType = (value: string): boolean => {
  return value?.length > 0;
};

const requiredTimeSchema = (value: string): boolean => {
  return value?.length > 0;
};

export const validationMethod = {
  requiredOrganizationName,
  correctContractNumber,
  requiredContractNumber,
  requiredServiceType,
  requiredTimeSchema,
};
