import styled from "@emotion/styled";
import { CusTypo } from "components/cusTypo/custom-typography";

export const pad = "32px";

export const SWrap = styled.div`
  max-width: 100%;
  padding: 48px 32px 0px;
`;

export const STop = styled.div`
  padding: 0 0 48px 0;
  border-bottom: 1px solid ${(props) => props.theme.mtsColor.background.hover.lightMode};
  text-align: left;
`;

export const SPhone = styled(CusTypo)`
  color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
`;

export const SCard = styled.div<{ isDis?: boolean }>`
  position: relative;
  padding: ${pad};
  max-width: 736px;
  border-radius: 8px;
  box-shadow: 1px 5px 22px 0px ${(props) => props.theme.mtsColor.base.rockBlueTransparent};
  text-align: left;
  ${(props) =>
    props.isDis && `background-color: ${props.theme.mtsColor.background.secondary.lightMode}`}
`;

export const STitle = styled(CusTypo)<{ isDis?: boolean }>`
  display: block;
  white-space: pre-wrap;
  padding-right: 32px;
  ${(props) => props.isDis && `color: ${props.theme.mtsColor.text.secondary.lightMode}`}
`;

export const SSub = styled(CusTypo)<{ isDis?: boolean }>`
  display: block;
  ${(props) => props.isDis && `color: ${props.theme.mtsColor.text.secondary.lightMode}`}
`;

export const SPre = styled(CusTypo)`
  display: block;
  white-space: pre-wrap;
  color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
`;

export const SLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
`;

export const SIcon = styled.div`
  position: absolute;
  width: auto;
  height: auto;
  top: ${pad};
  right: ${pad};
`;
