import { renderToString } from "react-dom/server";
import * as L from "leaflet";

import { HoverPopup } from "./subcomponents/mapElements";
import {
  createMarkerIcon,
  markerByDeparture,
  markerByStatus,
  marker_default,
  scoreToColor,
} from "./const";
import { HARDCODE_STATUSES } from "pages/anomalies/const";
import {
  HARDCODED_FILTER_DEPARTURE,
  FRAUD_COLUMN,
  HARDCODED_FILTER_MINING,
  DEPARTURE_COLUMN,
  ARCHIVE_COLUMN,
  makeArchiveFilter,
  RESULT_COLUMN,
  MAP_FILTER_DEFAULT_DEPARTURE,
  MAP_FILTER_DEFAULT_ANOMALIES,
  MAP_FILTER_DEFAULT_MINING,
} from "pages/mapPage/const";

export const drawClusterMarker = ({ point, stateSetter, entity }) => {
  let preparedIcon;

  preparedIcon =
    entity === "dep" && point.task_status != null
      ? createMarkerIcon(markerByDeparture[point.task_status])
      : entity !== "dep" && point.fraud_probability != null
      ? createMarkerIcon(markerByStatus[point.fraud_probability])
      : marker_default;

  const marker = new L.Marker(point, { icon: preparedIcon });

  marker.bindPopup("", {
    maxWidth: "auto" as any,
  });

  marker.on("popupopen", (e) => {
    var cont = e.target.getPopup();

    const composed = {
      node: cont._contentNode,
      data: point,
    };
    stateSetter(composed);
    e.popup.update();
  });

  // пишем в кастомное свойство
  (marker as any).pointData = point;

  marker.bindTooltip(renderToString(<HoverPopup point={point} />));
  return marker;
};

export const drawStackedMarker = ({
  point,
  stateSetter,
  slideSetter,
  integer,
  selectionSetter,
}) => {
  let preparedIcon;

  preparedIcon = L.divIcon({
    className: "marker__group",
    html: integer,
  });

  const marker = new L.Marker(point, { icon: preparedIcon });

  marker.on("click", () => {
    selectionSetter([]);
    stateSetter();
    slideSetter(true);
  });

  (marker as any).pointData = point;
  return marker;
};

export const makeLayers = ({
  arr,
  singleSetter,
  groupSetter,
  slideSetter,
  entity,
  selectionSetter,
}) => {
  const hashTable: any = arr?.reduce((tot, point) => {
    let someKey = `${point.lat}_${point.lng}`;
    if (tot.hasOwnProperty(someKey)) {
      return { ...tot, [someKey]: [...tot[someKey], point] };
    } else {
      return {
        ...tot,
        [someKey]: [point],
      };
    }
  }, {});

  let markersLayer: L.Marker<any>[] = [];

  Object.values(hashTable).forEach((v: any) => {
    if (v.length === 1) {
      markersLayer.push(
        drawClusterMarker({
          point: v[0],
          stateSetter: singleSetter,
          entity: entity,
        }),
      );
    } else {
      v.forEach((el) =>
        markersLayer.push(
          drawStackedMarker({
            point: el,
            stateSetter: () => groupSetter(v),
            selectionSetter,
            slideSetter,
            integer: v.length,
          }),
        ),
      );
    }
  });

  return markersLayer;
};

export const drawMinerMarker = ({ point, stateSetter }) => {
  const coords = { lat: point.mean_y, lng: point.mean_x };

  const marker = L.circleMarker(coords, {
    pane: "miningMarkersPane",
    color: "white",
    fill: true,
    stroke: true,
    fillOpacity: 1,
    fillColor: scoreToColor(point.score),
    weight: 0.5,
  });

  marker.bindPopup("", {
    maxWidth: "auto" as any,
  });

  (marker as any).on("popupopen", (e) => {
    var cont = e.target.getPopup();

    const composed = {
      node: cont._contentNode,
      data: point,
    };
    stateSetter(composed);
    e.popup.update();
  });

  return marker;
};

const calcRadius = (bounds: L.LatLngBounds) => {
  const center = bounds.getCenter();
  const eastBound = bounds.getEast();
  const centerEast = L.latLng(center.lat, eastBound);
  return center.distanceTo(centerEast);
};

export const drawPolygon = ({ point, stateSetter }) => {
  const poly = new L.Polygon(point.points);
  const polyBox = poly.getBounds();
  const center = polyBox.getCenter();
  const radius = calcRadius(polyBox);

  const circle = L.circle(center, radius, {
    color: scoreToColor(point.score),
    weight: 1,
    fillOpacity: 0.4,
  });

  circle.bindPopup("", {
    maxWidth: "auto" as any,
  });

  circle.on("popupopen", (e) => {
    var cont = e.target.getPopup();
    const composed = {
      node: cont._contentNode,
      data: point,
    };
    stateSetter(composed);
    e.popup.update();
  });

  return circle;
};

export const makeFilterItems = (minVis?: boolean, depVis?: boolean, arc?: any[]) => {
  return Boolean(minVis)
    ? {
        checkbox: { [FRAUD_COLUMN]: HARDCODED_FILTER_MINING },
        radio: {},
      }
    : Boolean(depVis)
    ? {
        checkbox: {
          [DEPARTURE_COLUMN]: HARDCODED_FILTER_DEPARTURE,
        },
        radio: { [ARCHIVE_COLUMN]: makeArchiveFilter(arc?.map((el) => el.file_dttm) ?? []) },
      }
    : {
        checkbox: {
          [RESULT_COLUMN]: HARDCODE_STATUSES,
        },
        radio: {},
      };
};

export const calcDefaultFilter = (depState, anoState, isFltAvl, strgFlt) => {
  if (depState) {
    return isFltAvl
      ? strgFlt.reduce((total, amount) => {
          return { ...total, [amount.column]: amount.value };
        }, {})
      : MAP_FILTER_DEFAULT_DEPARTURE;
  } else if (anoState) {
    return isFltAvl
      ? strgFlt.reduce((total, amount) => {
          return { ...total, [amount.column]: amount.value };
        }, {})
      : MAP_FILTER_DEFAULT_ANOMALIES;
  } else {
    return MAP_FILTER_DEFAULT_MINING;
  }
};
