import { useCallback, useContext, useEffect, useRef, useState } from "react";

import useOnClickOutside from "hooks/useOnClickOutside";
import { Switch } from "components/switch/switch.component";
import { ELayers, IReducerState, calcAvailSwitches, labelBySwitch } from "../const";
import { SLayersBase, SLayersControl } from "../styles";
import { ContextSettings } from "context/context-settings";
import { MTSModal } from "components/mts-modal/mts-modal";
import { CusTypo } from "components/cusTypo/custom-typography";

interface IProp {
  layersState: IReducerState;
  layersDispatch: any;
  mapInstance: any;
  depInstance: any;
  anoInstance: any;
  minPolyInstance: any;
  minMarkersInstance: any;
  onClose: any;
  onSwitch?: Function;
}
export const LayersControl: React.FC<IProp> = ({
  layersState,
  layersDispatch,
  mapInstance,
  depInstance,
  anoInstance,
  minPolyInstance,
  minMarkersInstance,
  onClose,
  onSwitch,
}) => {
  const { profile, isTablet } = useContext(ContextSettings);
  const [lastSwitched, setLast] = useState("");

  const containerRef = useRef<any>(null);
  useOnClickOutside(containerRef, onClose);

  const handleSwitch = (id) => {
    onSwitch?.();
    setLast(id);
    layersDispatch({ type: "toggle_show", payload: id });
  };

  const operateVisibility = useCallback(
    (targetLayer, requiredInstance, optionalInstance?) => {
      if (layersState[targetLayer]?.visible) {
        if (requiredInstance != null) {
          requiredInstance.addTo(mapInstance);
        } else {
          requiredInstance?.addTo(mapInstance);
        }
      } else {
        requiredInstance && mapInstance.removeLayer(requiredInstance ?? {});
        optionalInstance && mapInstance.removeLayer(optionalInstance ?? {});
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [layersState],
  );

  useEffect(() => {
    if (lastSwitched === ELayers.DEP) {
      operateVisibility(ELayers.DEP, depInstance);
    }
    if (lastSwitched === ELayers.ANO) {
      operateVisibility(ELayers.ANO, anoInstance);
    }
    if (lastSwitched === ELayers.MIN) {
      operateVisibility(ELayers.MIN, minMarkersInstance, minPolyInstance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSwitched, JSON.stringify(layersState)]);

  const renderControls = () =>
    calcAvailSwitches(profile?.services_ch ?? []).map((sw) => (
      <Switch
        key={sw}
        id={sw}
        setChecked={() => handleSwitch(sw)}
        checked={layersState[sw]?.visible ?? false}
        label={labelBySwitch[sw]}
        rightPlhlr
      />
    ));

  return isTablet() ? (
    <SLayersControl ref={containerRef}>{renderControls()}</SLayersControl>
  ) : (
    <MTSModal open={true} close={() => onClose()} size="S">
      <CusTypo variant="h4_medium" font="comp">
        Режим отображения
      </CusTypo>
      <SLayersBase>{renderControls()}</SLayersBase>
    </MTSModal>
  );
};
