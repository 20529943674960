import { UserMobileCard } from "components/card-info/users/user.component";
import { RenderFilter } from "components/table/render-filter/renderFilter.component";
import { FILTER_BY_ROLES } from "pages/managementUsers/const";

function dontFilter(rows, columnIds, filterValue) {
  return rows;
}

export const tableStructure = [
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Пользователь"
        withPadding
      />
    ),
    accessor: "userName",
    minWidth: 340,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Статус"
        withPadding
      />
    ),
    accessor: "status",
    minWidth: 150,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Организация"
        withPadding
      />
    ),
    accessor: "info",
    minWidth: 264,
    canFilter: false,
    disableFilters: true,
    filter: dontFilter,
  },
  {
    accessor: FILTER_BY_ROLES,
    minWidth: 0,
    maxWidth: 0,
    canFilter: true,
    filter: "arrIncludesSome",
  },
  {
    accessor: "rolesCyrString",
    minWidth: 0,
    maxWidth: 0,
  },
  {
    accessor: "id",
    minWidth: 0,
    maxWidth: 0,
  },
  {
    accessor: "action",
    minWidth: 56,
    width: "100%",
    disableSortBy: true,
  },
];

export const tableMobStructure = [
  {
    accessor: "userName",
    Row: UserMobileCard,
  },
  {
    accessor: "status",
  },
  {
    accessor: "info",
    canFilter: false,
    disableFilters: true,
    filter: dontFilter,
  },
  {
    accessor: FILTER_BY_ROLES,
    canFilter: true,
    filter: "arrIncludesSome",
  },
  {
    accessor: "rolesCyrString",
  },
  {
    accessor: "id",
  },
  {
    accessor: "action",
    width: "100%",
    disableSortBy: true,
  },
];
