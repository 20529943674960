import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import { useManagementUsers } from "hooks/api/management";
import { Loader } from "components/loader/loader";
import { Back } from "components/back/back.component";
import { Spacer } from "components/spacer/spacer.component";
import { MobUsers } from "components/card-info/users/mobUsers.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SItemPage } from "components/card-info/styles";

// NOTE: Страница имеет только мобильную верстку
export const UserItemPage = () => {
  let { id } = useParams();
  const queryUsers = useManagementUsers();
  const selected = queryUsers?.data?.find((a) => `${a.id}` === id);

  return (
    <>
      <SItemPage>
        <Back />
        <Spacer value="28px" />
        {selected && !isEmpty(selected) ? (
          <MobUsers data={selected} />
        ) : (
          <CusTypo variant="p3_regular">Нет данных</CusTypo>
        )}
        {queryUsers.isLoading ? <Loader isFullPage /> : null}
      </SItemPage>
    </>
  );
};
