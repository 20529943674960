import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Update = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.00012 12.5661C6.00016 12.0021 6.02487 11.4309 6.06665 10.8696C6.24971 8.40991 6.35264 8.04074 6.91295 7.48044C7.47325 6.92013 7.84242 6.8172 10.3021 6.63414C10.8639 6.59233 11.4355 6.56761 12 6.56761C12.6769 6.56761 13.3634 6.60315 14.0301 6.66091C14.6949 6.7185 15.173 6.7671 15.5551 6.82087C15.9624 6.87818 16.3868 6.76613 16.6776 6.4753C16.901 6.25193 17.007 5.96793 17 5.70283C16.9904 5.33817 16.767 5.0093 16.3411 4.92328C15.7498 4.80388 15.0748 4.74393 14.2027 4.66837C13.4903 4.60665 12.7451 4.56761 12 4.56761C11.3782 4.56761 10.7564 4.5948 10.1536 4.63966C7.82835 4.81272 6.66571 4.89925 5.49873 6.06622C4.33176 7.2332 4.24523 8.39584 4.07217 10.7211C4.02734 11.3235 4.00016 11.9448 4.00012 12.5661H2.63197C2.12045 12.5661 1.82159 13.1769 2.11657 13.6194C3.42926 15.5886 4.0856 16.5661 5 16.5661C5.9144 16.5661 6.57074 15.5886 7.88343 13.6194C8.17841 13.1769 7.87955 12.5661 7.36802 12.5661H6.00012Z"
        fill="#969FA8"
      />
      <path
        d="M21.3828 12.5661H19.9998V12.5675C19.9998 13.1893 19.9727 13.811 19.9278 14.4138C19.7547 16.7391 19.6682 17.9017 18.5012 19.0687C17.3342 20.2357 16.1716 20.3222 13.8463 20.4953C13.2436 20.5401 12.6218 20.5673 12 20.5673C11.2548 20.5673 10.5097 20.5283 9.79722 20.4666C8.9252 20.391 8.25018 20.3311 7.65899 20.2117C7.23255 20.1256 7.0091 19.796 7 19.4308C6.9934 19.1661 7.09944 18.8827 7.32243 18.6597C7.61326 18.3688 8.03765 18.2568 8.44493 18.3141C8.82699 18.3678 9.30512 18.4164 9.96984 18.474C10.6365 18.5318 11.3231 18.5673 12 18.5673C12.5645 18.5673 13.1361 18.5426 13.6979 18.5008C16.1575 18.3177 16.5267 18.2148 17.087 17.6545C17.6473 17.0942 17.7502 16.725 17.9333 14.2654C17.9751 13.7036 17.9998 13.1319 17.9998 12.5675V12.5661H16.6172C16.1176 12.5661 15.8258 11.9703 16.1138 11.5385L16.1657 11.4608C17.4452 9.54291 18.0969 8.56615 19 8.56615C19.9031 8.56615 20.5547 9.54285 21.8342 11.4606L21.8862 11.5385C22.1742 11.9703 21.8824 12.5661 21.3828 12.5661Z"
        fill="#969FA8"
      />
    </SvgIcon>
  );
};

export default Update;
