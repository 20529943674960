import { useContext, useState } from "react";

import { Breadcrumbs, IBreadcrumbsLink } from "components/breadcrumbs/breadcrumbs";
import { ROUTE_BILLING_PAGE, ROUTE_EVENTS } from "stream-constants/route-constants";
import { MTSInput } from "components/mts-input";
import { useManagementEvents } from "hooks/api/management";
import { useDebounce } from "hooks/useDebounce";
import { Footer } from "components/footer/footer";
import { Spacer } from "components/spacer/spacer.component";
import { EventsTable } from "components/management-events/eventsTable/eventsTable.component";
import { InputDatePicker } from "components/mts-datepicker/mts-datepicker";
import { ITableFilterClm } from "components/table/table.component";
import { ContextSettings } from "context/context-settings";
import { Loader } from "components/loader/loader";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SDash, SPos, SItem, STitle, SWrap, SArticle, SFields, SBtns } from "./styles";
import { MTSButton } from "components/mts-button/mts-button";
import Icon from "components/icons";
import { TableSort } from "components/table/table-sort/table-sort";
import { TABLE_SORT_DEFAULT_EVENTS } from "./const";

const links: IBreadcrumbsLink[] = [
  { name: "Управление", href: `/${ROUTE_BILLING_PAGE}` },
  { name: "События", href: `/${ROUTE_EVENTS}` },
];

const defaultPeriod = [{ column: "eventDatetime", value: [] }];

export const ManagementEvents = () => {
  const { isDesktop } = useContext(ContextSettings);
  const [selected, setSelected] = useState<number>(0);
  const [searchVal, setSearch] = useState("");
  const [columnFilter, setColumnFilter] = useState<ITableFilterClm[]>(defaultPeriod);
  const managementEvents = useManagementEvents();
  const debouncedSearch = useDebounce(searchVal, 500);
  const [isOpenSort, setIsOpenSort] = useState(false);
  const [columnSort, setColumnSort] = useState<any>([]);
  const handleColumnSort = (sorting) => setColumnSort(sorting);

  const handleInput = (e) => {
    setSearch(e.target.value);
  };

  const handleDatesPeriod = (e, index) => {
    let start = columnFilter[0]?.value;
    start[index] = e;
    let someArr = start.every((a) => a == null) ? [] : start;
    setColumnFilter([{ column: "eventDatetime", value: someArr }]);
  };

  return (
    <>
      <SWrap>
        <Breadcrumbs links={links} />
        <SArticle>
          <STitle>
            <CusTypo
              variant={isDesktop() ? "h2_medium" : "h4_medium"}
              font="wide"
              styles={{ margin: "0 24px 0 0", whiteSpace: "nowrap" }}
            >
              Журнал событий
            </CusTypo>
          </STitle>
          <SFields>
            <SItem className="searchField">
              <MTSInput
                searchIcon
                placeholder="Имя, название организации, роль"
                onChange={handleInput}
                value={searchVal}
                size="S"
              />
            </SItem>
            <SItem>
              <SPos>
                <CusTypo variant="p4_medium">Период</CusTypo>
                <InputDatePicker
                  placeholder="ДД.ММ.ГГ"
                  onSelect={(e) => handleDatesPeriod(e, 0)}
                  isPickAM
                />
                <SDash>
                  <CusTypo variant="p4_bold">-</CusTypo>
                </SDash>

                <InputDatePicker
                  placeholder="ДД.ММ.ГГ"
                  onSelect={(e) => handleDatesPeriod(e, 1)}
                  isPickAM
                />
              </SPos>
            </SItem>
          </SFields>
          {!isDesktop() ? (
            <SBtns>
              <MTSButton
                size="S"
                variant="secondary"
                onClick={() => setIsOpenSort(true)}
                format="icon"
                icon={<Icon.Sort sx={{ width: 16, height: 16 }} />}
              />
              <TableSort
                items={TABLE_SORT_DEFAULT_EVENTS}
                isOpen={isOpenSort}
                close={() => setIsOpenSort(false)}
                onApplySort={handleColumnSort}
                view="modal"
              />
            </SBtns>
          ) : null}
        </SArticle>
        <EventsTable
          rawData={managementEvents.data ?? []}
          globalFilter={debouncedSearch}
          filterByColumn={columnFilter}
          onRowClick={setSelected}
          rowSelected={selected}
          sortByColumn={columnSort}
        />
        <Spacer value="16px" />
      </SWrap>
      <Footer />
      {managementEvents.isLoading ? <Loader isFullPage /> : null}
    </>
  );
};
