import styled from "@emotion/styled";
import { EDqScore } from "interface/common";
import rgba from "polished/lib/color/rgba";
import theme from "theme";

export const SCont = styled.div`
  padding: 24px 8px;
  cursor: default;
`;

export const STabs = styled.ul`
  display: flex;
`;

export const STabItem = styled.li<{ isActive?: boolean }>`
  margin-right: 32px;
  padding-bottom: 8px;
  border-bottom: 4px solid transparent;
  cursor: pointer;

  ${(props) =>
    props.isActive &&
    `
		border-bottom: 4px solid ${props.theme.mtsColor.red};
	`}
`;

export const SSpecLi = styled.li<{ color?: EDqScore }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  background-color: #ffffff;

  &:nth-of-type(2n) {
    background-color: #f9f9fb;
  }

  & > span {
    display: flex;
    align-items: center;

    & span {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  ${(props) =>
    props.color &&
    `
	margin-top: 16px;
	background-color: ${rgba(colorByScore(props.color), 0.08)}
  `}
`;

export const SInner = styled.div`
  padding-top: 24px;
`;

const colorByScore = (val) => {
  switch (val) {
    case EDqScore.Подходит:
      return theme.mtsColor.base.success;
    case EDqScore.Допустимо:
      return theme.mtsColor.base.warning;
    case EDqScore["Не подходит"]:
      return theme.mtsColor.base.critical;
    case EDqScore["В процессе"]:
    default:
      return theme.mtsColor.base.idle;
  }
};

export const SControls = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
`;

export const SICon = styled.div`
  display: flex;
  justify-content: center;
`;
