import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    minWidth: "0",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  footerDark: {
    backgroundColor: theme.mtsColor.background.primaryElevated.darkMode,
  },
  footerFull: {
    "& $block": {
      minHeight: "64px",
      height: "auto",
      padding: "0 40px",
      flexDirection: "row",
      justifyContent: "space-between",
      gap: "12px",
    },
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  mainLinks: {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "flex-start",
  },
  aboutTitle: {
    "&.MuiTypography-root": {
      color: theme.mtsColor.text.primary.lightMode,
      textAlign: "start",
    },
  },
  aboutText: {
    "&.MuiTypography-root": {
      color: theme.mtsColor.text.primary.lightMode,
      textAlign: "start",
    },
  },
  mainLinkItem: {
    flexBasis: "50%",
    textAlign: "start",
    marginBottom: "15.5px",
  },
  mainLink: {
    textDecoration: "none",
  },
  mainLinkText: {
    "&.MuiTypography-root": {
      color: theme.mtsColor.text.secondaryLink.lightMode,
      textDecoration: "none",

      "&:hover": {
        color: theme.mtsColor.red,
      },
    },
  },
  block: {
    width: "100%",
    minHeight: "0px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "32px 40px 24px 32px",
  },
  сopyright: {
    display: "flex",
  },
  сopyrightText: {
    display: "flex",
    "&.MuiTypography-root": {
      color: theme.mtsColor.text.secondary.lightMode,
    },
  },
  сopyrightWhiteText: {
    display: "flex",
    "&.MuiTypography-root": {
      color: theme.mtsColor.text.primary.darkMode,
    },
  },
  agreementLink: {
    display: "block",
    textDecoration: "none",
    marginTop: "8px",
  },
  agreementText: {
    "&.MuiTypography-root": {
      color: theme.mtsColor.text.secondary.lightMode,
    },
  },
  otherLinks: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    textAlign: "left",
    gap: "8px",
  },
  otherLinkItem: {
    display: "flex",
    flexWrap: "wrap",
    marginRight: "30px",
    "&:last-child": {
      marginRight: 0,
    },
  },
  otherLink: {
    textDecoration: "none",
  },
  otherLinkText: {
    "&.MuiTypography-root": {
      color: theme.mtsColor.text.secondary.lightMode,
    },
  },
}));

export default useStyles;
