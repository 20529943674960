import { useContext, useEffect } from "react";

import { ContextSettings } from "context";
import { MAIL_SUPPORT, URL_LOGOUT_MTS_BY_ID, WELCOME_PAGE_URL } from "services/urls";
import { login, logout } from "services/auth";

const PROFILE_WIDGET_APP_ID = "profile-widget-app-id-123";

export const HeaderProfile = () => {
  const { setIsAuthorized } = useContext(ContextSettings);

  const handleLogout = async () => {
    try {
      const response = await logout();
      if (response.ok) {
        setIsAuthorized(false);
        const params = `${WELCOME_PAGE_URL}`;
        window.location.href = URL_LOGOUT_MTS_BY_ID(params);
      }
    } catch (error) {
      new Error(error);
    }
  };

  useEffect(() => {
    let widget: any;
    try {
      widget = new (window as any).profileWidgetApp({
        target: document.getElementById(PROFILE_WIDGET_APP_ID),
        props: {
          loginEndpoint: () => {
            login();
          },
          logoutEndpoint: () => {
            handleLogout();
          },
          supportLink: MAIL_SUPPORT,
          blockLoading: false,
        },
      });
    } catch (error) {}

    return () => {
      widget && widget.$destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div id={PROFILE_WIDGET_APP_ID} />
    </div>
  );
};
