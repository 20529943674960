import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { Line } from "react-chartjs-2";
import { isEmpty } from "lodash";

import { Spacer } from "components/spacer/spacer.component";
import { getPeriod } from "components/chart/utils";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SCurrent, STitle, STop } from "./styles";
import { makeOptions } from "./const";

ChartJS.register(
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LinearScale,
  TimeScale,
);

interface IChart {
  id: number | string;
  scaleX?: string;
  chartData: any;
  children?: React.ReactElement | null;
  legend?: string[];
}

export const Chart: React.FC<IChart> = ({
  id,
  scaleX = "seasons",
  chartData,
  children,
  legend = ["День", "Ночь"],
}) => {
  const period = getPeriod(chartData.labels);

  return (
    <>
      <SCurrent>
        <CusTypo variant="p3_medium">ID {id}</CusTypo>
      </SCurrent>
      <STop>
        <STitle>
          <CusTypo variant="p4_regular">Период учета {period}</CusTypo>
          {!isEmpty(legend) ? (
            <div>
              {legend.map((a) => (
                <section key={a}>
                  <div />

                  <CusTypo variant="c1_regular">{a}</CusTypo>
                </section>
              ))}
            </div>
          ) : null}
        </STitle>
        {children}
      </STop>
      <Spacer value="8px" />
      <div style={{ position: "relative" }}>
        <Line options={makeOptions(scaleX) as any} data={chartData as any} height={420} />
      </div>
    </>
  );
};
