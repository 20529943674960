import { RenderFilter } from "components/table/render-filter/renderFilter.component";

export const tableStructure = [
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Дата смены статуса"
        withPadding
      />
    ),
    accessor: "date_archive",
    minWidth: 162,
    maxWidth: 162,
    sortType: "datetime",
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Кто сменил"
        withPadding
      />
    ),
    accessor: "username",
    minWidth: 132,
    maxWidth: 132,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Роль"
        withPadding
      />
    ),
    accessor: "userrole",
    minWidth: 106,
    maxWidth: 106,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Статус"
        withPadding
      />
    ),
    accessor: "fp",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Комментарий"
        withPadding
      />
    ),
    accessor: "comment",
    minWidth: 300,
    maxWidth: 300,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Дата проверки"
        withPadding
      />
    ),
    accessor: "d_cv",
    minWidth: 134,
    maxWidth: 134,
    sortType: "datetime",
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Дата след. проверки"
        withPadding
      />
    ),
    accessor: "d_nv",
    minWidth: 172,
    maxWidth: 172,
    sortType: "datetime",
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Дата повт. проверки"
        withPadding
      />
    ),
    accessor: "date_reverify",
    minWidth: 172,
    maxWidth: 172,
    sortType: "datetime",
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Актуальность"
        withPadding
      />
    ),
    accessor: "not_actual",
    minWidth: 132,
    width: "100%",
  },
];
