import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Archive = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.00008 8.75C6.00008 8.33579 6.33587 8 6.75008 8H9.25008C9.6643 8 10.0001 8.33579 10.0001 8.75C10.0001 9.16421 9.6643 9.5 9.25008 9.5H6.75008C6.33587 9.5 6.00008 9.16421 6.00008 8.75Z"
        fill="#626C77"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.12373 3.12496C2.24849 4.0002 2.18359 4.8722 2.05379 6.61619C2.02014 7.06828 1.99976 7.53463 1.99976 8.00098C1.99976 8.46733 2.02014 8.93367 2.05379 9.38576C2.18359 11.1298 2.24849 12.0017 3.12373 12.877C3.99898 13.7522 4.87098 13.8171 6.61497 13.9469C7.06706 13.9806 7.53341 14.001 7.99976 14.001C8.4661 14.001 8.93245 13.9806 9.38454 13.9469C11.1285 13.8171 12.0005 13.7522 12.8758 12.877C13.751 12.0017 13.8159 11.1298 13.9457 9.38576C13.9794 8.93368 13.9998 8.46733 13.9998 8.00098C13.9998 7.53463 13.9794 7.06828 13.9457 6.61619C13.8159 4.8722 13.751 4.0002 12.8758 3.12496C12.0005 2.24971 11.1285 2.18481 9.38454 2.05501C8.93245 2.02137 8.4661 2.00098 7.99976 2.00098C7.53341 2.00098 7.06706 2.02137 6.61497 2.05501C4.87098 2.18481 3.99898 2.24971 3.12373 3.12496ZM7.99976 3.50098C7.57639 3.50098 7.14766 3.51952 6.7263 3.55087C4.88153 3.68817 4.60464 3.76537 4.1844 4.18562C3.95867 4.41134 3.83191 4.5918 3.74166 5H12.2579C12.1676 4.5918 12.0408 4.41134 11.8151 4.18562C11.3949 3.76537 11.118 3.68817 9.27321 3.55087C8.85186 3.51952 8.42312 3.50098 7.99976 3.50098ZM12.4329 6.5H3.56661C3.56105 6.57187 3.5554 6.65028 3.54965 6.72752C3.51829 7.14888 3.49976 7.57761 3.49976 8.00098C3.49976 8.42434 3.51829 8.85308 3.54965 9.27443C3.68695 11.1192 3.76415 11.3961 4.1844 11.8163C4.60464 12.2366 4.88153 12.3138 6.7263 12.4511C7.14766 12.4824 7.57639 12.501 7.99976 12.501C8.42312 12.501 8.85186 12.4824 9.27321 12.4511C11.118 12.3138 11.3949 12.2366 11.8151 11.8163C12.2354 11.3961 12.3126 11.1192 12.4499 9.27443C12.4812 8.85308 12.4998 8.42434 12.4998 8.00098C12.4998 7.57761 12.4812 7.14888 12.4499 6.72752C12.4441 6.65028 12.4385 6.57187 12.4329 6.5Z"
        fill="#626C77"
      />
    </SvgIcon>
  );
};

export default Archive;
