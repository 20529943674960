import format from "date-fns/format";
import { ru } from "date-fns/locale";

import { RenderFilter } from "components/table/render-filter/renderFilter.component";
import { EMPTY_CELL_VALUE } from "stream-constants/keys-and-constants";
import { MINING_DOWN } from "services/urls";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SDown } from "../summary/styles";
import { MobAskueArchive } from "./subcomponents";

export const constructTableData = (draft: any[]) =>
  draft?.map((d) => {
    const { file_path, time_schema } = d;

    return {
      ...d,
      fileName: file_path,
      time_schema: new Date(time_schema ?? 0),
    };
  });

export const tableStructure = [
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Имя"
      />
    ),
    accessor: "fileName",
    minWidth: 300,
    Cell: ({ value }) => (
      <div style={{ width: 200 }}>
        <CusTypo variant="p4_regular">{value ?? EMPTY_CELL_VALUE}</CusTypo>
      </div>
    ),
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Дата и время загрузки"
      />
    ),
    accessor: "time_schema",
    minWidth: 230,
    Cell: ({ value }) => (
      <div style={{ width: 200, whiteSpace: "pre" }}>
        <CusTypo variant="p4_regular">
          {value
            ? format(value, "dd.MM.yyyy HH:mm", {
                locale: ru,
              })
            : EMPTY_CELL_VALUE}
        </CusTypo>
      </div>
    ),
  },
  {
    Header: "",
    accessor: "file_path",
    minWidth: 150,
    width: "100%",
    Cell: ({ value }) => <SDown href={`${MINING_DOWN}/${value}`}>Скачать файл</SDown>,
  },
];

export const tableMobStructure = [
  {
    accessor: "fileName",
    Row: MobAskueArchive,
  },
  {
    accessor: "time_schema",
    sortType: "datetime",
  },
  {
    accessor: "file_path",
  },
];
