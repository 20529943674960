import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { yupResolver } from "@hookform/resolvers/yup";

import { MTSInput } from "components/mts-input";
import { MTSButton } from "components/mts-button/mts-button";
import { ModalNotifyBody } from "components/modal-notify-body/modal-notify-body";
import { useCreateDemoAcc } from "hooks/api/management";
import { INN_MASK, PHONE_MASK } from "stream-constants/keys-and-constants";
import { unformatPhone } from "utils/formatters";
import { Spacer } from "components/spacer/spacer.component";
import { SModalTitle } from "components/modal-notify-body/styles";
import { ROUTE_USER_AGREEMENT } from "stream-constants/route-constants";
import { defaultValues, FALLBACK_MSG, schema } from "./validation-schema";
import { SBtns, SField } from "./styles";
import { Checkbox } from "components/checkbox/checkbox";

export const FormAddDemoAccount = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<any>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: false,
    reValidateMode: "onChange",
  });

  const mutation = useCreateDemoAcc();

  const onSubmit = async (submData) => {
    mutation.mutate({
      userName: submData.userName,
      email: submData.email,
      phone: unformatPhone(submData.phone),
      companyName: submData.title,
      inn: submData.inn,
    });
  };

  if (mutation?.isSuccess) {
    return <ModalNotifyBody title="Демо акканут успешно зарегистрирован" isSuccess />;
  } else if (mutation?.isError) {
    return (
      <ModalNotifyBody
        title="Демо аккаунт не добавлен"
        isError
        textDescrioption={(mutation?.error as any).message ?? FALLBACK_MSG}
      />
    );
  } else {
    return (
      <>
        <SModalTitle>Регистрация Демо аккаунта</SModalTitle>
        <Spacer value="12px" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <SField>
            <MTSInput
              label="Название организации"
              control={control}
              {...register("title", {
                required: true,
              })}
              errorMessage={errors?.title?.message}
            />
          </SField>
          <SField>
            <InputMask mask={INN_MASK} {...register("inn")}>
              {() => (
                <MTSInput
                  label="ИНН"
                  control={control}
                  {...register("inn", {
                    required: true,
                  })}
                  errorMessage={errors?.inn?.message}
                />
              )}
            </InputMask>
          </SField>
          <SField>
            <MTSInput
              label="ФИО"
              control={control}
              {...register("userName", {
                required: true,
              })}
              errorMessage={errors?.userName?.message}
            />
          </SField>
          <SField>
            <InputMask mask={PHONE_MASK} {...register("phone")}>
              {() => (
                <MTSInput
                  label="Телефон"
                  {...register("phone", {
                    required: true,
                  })}
                  control={control}
                  errorMessage={errors?.phone?.message}
                />
              )}
            </InputMask>
          </SField>
          <SField>
            <MTSInput
              label="Электронная почта"
              placeholder="Необязательно"
              {...register("email", {
                required: false,
              })}
              control={control}
              errorMessage={errors?.email?.message}
            />
          </SField>
          <SBtns>
            <Controller
              name="agree"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Checkbox
                    value="true"
                    checked={value === "true"}
                    onChange={(a) => onChange(a === value ? undefined : a)}
                    label={
                      <>
                        Вы соглаcны на{" "}
                        <Link target="_blank" rel="noreferrer" to={`/lk/${ROUTE_USER_AGREEMENT}`}>
                          обработку персональных данных
                        </Link>
                      </>
                    }
                    color="red"
                  />
                );
              }}
            />
            <MTSButton
              size="M"
              variant="primary"
              type="submit"
              onClick={() => undefined}
              disabled={!isValid}
            >
              Отправить
            </MTSButton>
          </SBtns>
        </form>
      </>
    );
  }
};
