import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Icon from "components/icons";
import { ModalNotifyBody } from "components/modal-notify-body/modal-notify-body";
import { MTSButton } from "components/mts-button/mts-button";
import { MTSInput } from "components/mts-input";
import { Spacer } from "components/spacer/spacer.component";
import { useDemoMngUpdateStatus, useMutationDeleteDemoTenant } from "hooks/api/management";
import { CusTypo } from "components/cusTypo/custom-typography";
import { IDemoMngStatuses, IRequestStatuses } from "interface/management";
import {
  calcShema,
  colorByStatus,
  getStatusCode,
  labelByStatus,
  modalDescrByStatus,
  modalTitleByStatus,
  newByStatus,
  succDescrByStatus,
  succTitleByStatus,
} from "./utils";
import { SModalTitle } from "components/modal-notify-body/styles";
import { SControls, SICon } from "./substyles";

interface IProp {
  nextStatus: IDemoMngStatuses;
  onCancel: () => void;
  tenantId: string;
  tenantTitle: string;
  isOpen?: boolean;
}

const statusWithComment = ["del", "reject", "stop"];
const centerStyles = { textAlign: "center", display: "block" };

export const StatusModal: React.FC<IProp> = ({ tenantId, nextStatus, onCancel, tenantTitle }) => {
  const statusCodeEng = getStatusCode[nextStatus];
  const isComment = statusWithComment.includes(statusCodeEng);
  const currSchema = calcShema(statusCodeEng === "del");

  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { dirtyFields, isValid, errors },
  } = useForm({
    defaultValues: { comment: "" },
    resolver: yupResolver(currSchema),
    mode: "onChange",
  });

  const updateStatus = useDemoMngUpdateStatus();
  const deleteTenant = useMutationDeleteDemoTenant();

  const onSubmit = (sbmt) => {
    updateStatus.mutate({
      id: String(tenantId),
      comment: dirtyFields["comment"] ? sbmt.comment : undefined,
      status: nextStatus as IRequestStatuses,
    });
  };

  return updateStatus.isError || deleteTenant.isError ? (
    <ModalNotifyBody
      title="Операция не выполнена"
      isError
      textDescrioption={
        (updateStatus?.error || deleteTenant.error)?.message ??
        "Попробуйте повторить действие еще раз"
      }
    />
  ) : updateStatus.isSuccess || deleteTenant.isSuccess ? (
    <SuccessModal tenantTitle={tenantTitle} statusCode={statusCodeEng} />
  ) : (
    <>
      <SModalTitle styles={isComment ? undefined : (centerStyles as any)}>
        {modalTitleByStatus[statusCodeEng]}
      </SModalTitle>
      <Spacer value="16px" />
      <CusTypo variant="p4_regular" styles={isComment ? undefined : (centerStyles as any)}>
        {modalDescrByStatus[statusCodeEng]}
      </CusTypo>
      <Spacer value="24px" />
      {isComment ? (
        <>
          <MTSInput
            placeholder="Оставьте комментарий"
            type="textarea"
            label={`Укажите причину ${labelByStatus[statusCodeEng]}`}
            {...register("comment", {
              required: false,
            })}
            control={control}
            errorMessage={errors?.comment?.message}
          />
          <Spacer value="24px" />
        </>
      ) : null}
      <SControls>
        <MTSButton
          size="M"
          variant="primary"
          type="submit"
          disabled={!isValid}
          onClick={
            statusCodeEng === "del"
              ? () =>
                  deleteTenant.mutate({
                    tenant_id: String(tenantId),
                    comment: getValues("comment"),
                  })
              : handleSubmit(onSubmit, undefined)
          }
        >
          {statusCodeEng === "del" ? "Удалить" : "Подтвердить"}
        </MTSButton>
        <MTSButton size="M" variant="secondary" onClick={onCancel}>
          Отмена
        </MTSButton>
      </SControls>
    </>
  );
};

interface IKek {
  tenantTitle: string;
  statusCode: string;
}
const SuccessModal: React.FC<IKek> = ({ tenantTitle, statusCode }) => {
  return (
    <>
      {statusCode === "accept" ? (
        <>
          <SICon>
            <Icon.SuccessShaped sx={{ width: 44, height: 44 }} />
          </SICon>
          <Spacer value="16px" />
        </>
      ) : null}
      <CusTypo
        variant="h3_bold"
        styles={{ textAlign: "center", display: "block", whiteSpace: "pre-wrap" }}
      >
        {succTitleByStatus[statusCode]}
      </CusTypo>
      {calcSubtitle(statusCode, tenantTitle)}
    </>
  );
};

const showInfoFor = ["reject", "accept", "del"];

function calcSubtitle(statusCode, title) {
  const descr =
    statusCode !== "del" ? (
      <>
        {`${title} ${succDescrByStatus[statusCode]}`}

        <CusTypo
          variant="p4_bold"
          styles={{ textAlign: "center", display: "block", color: colorByStatus[statusCode] }}
        >
          {newByStatus[statusCode]}
        </CusTypo>
      </>
    ) : (
      succDescrByStatus[statusCode]
    );

  return showInfoFor.includes(statusCode) ? (
    <>
      <Spacer value="16px" />
      <CusTypo variant="p4_regular" styles={{ textAlign: "center", display: "block" }}>
        {descr}
      </CusTypo>
    </>
  ) : null;
}
