import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "&.MuiCheckbox-root": {
      padding: "0",
    },
  },
}));

export default useStyles;
