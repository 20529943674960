import React, { useContext, useEffect, useState } from "react";

import { ITableSortColumn } from "components/table/table.component";
import { Radio } from "components/radio/radio.component";
import { Select } from "components/select/select";
import { Spacer } from "components/spacer/spacer.component";
import { SlideIn } from "../slideIn/slideIn.component";
import { MTSModal } from "components/mts-modal/mts-modal";
import { CusTypo } from "components/cusTypo/custom-typography";
import { MTSButton } from "components/mts-button/mts-button";
import { EDirection, ISortItem, hardcodedDirection } from "./const";
import { ContextSettings } from "context/context-settings";

interface IProp {
  isOpen: boolean;
  onApplySort: (sortConfig) => void;
  close: () => void;
  items: ISortItem[];
  view?: "slidein" | "modal";
}

export const TableSort: React.FC<IProp> = ({
  isOpen,
  onApplySort,
  close,
  items,
  view = "slidein",
}) => {
  const { isMobile } = useContext(ContextSettings);
  const [dataRes, setDataRes] = useState<ITableSortColumn[]>([]);

  const toggleRadio = (column: string) => {
    setDataRes((s) => {
      let a = s[0];
      return [
        {
          ...a,
          id: column,
        },
      ];
    });
  };

  const toggleDrop = (someVal) => {
    setDataRes((s) => {
      let a = s[0];
      return [
        {
          ...a,
          desc: someVal === EDirection.DESC,
        },
      ];
    });
  };

  const handleApplySort = (filters) => {
    onApplySort(filters);
  };

  useEffect(() => {
    handleApplySort(dataRes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRes]);

  const renderControls = () => (
    <div style={{ padding: "0 0 0 22px" }}>
      {items.map((a, ind, self) => (
        <React.Fragment key={`${a.accessor}_${a.text}`}>
          <Radio
            id={a.accessor}
            name={a.accessor}
            value={a.accessor}
            checked={dataRes?.find((o: any) => o.id === a.accessor) != null}
            onChange={() => toggleRadio(a.accessor)}
            text={a.text}
            color="black"
          />
          {self.length - 1 !== ind ? <Spacer value="29px" /> : null}
        </React.Fragment>
      ))}
    </div>
  );

  return view === "modal" ? (
    <MTSModal open={isOpen} close={close} size="S">
      <CusTypo variant="h4_medium" font="comp">
        Сортировать список
      </CusTypo>
      <Spacer value="24px" />
      <Select
        size={isMobile() ? "M" : "S"}
        optionsList={hardcodedDirection}
        onChange={toggleDrop}
      />
      <Spacer value="12px" />
      {renderControls()}
      <Spacer value="22px" />
      <MTSButton
        size={isMobile() ? "M" : "S"}
        variant="secondary"
        onClick={close}
        style={{ width: "100%" }}
      >
        Отмена
      </MTSButton>
    </MTSModal>
  ) : (
    <>
      <SlideIn isOpen={isOpen} title="Сортировать список" close={close}>
        <div>
          <Select size="M" optionsList={hardcodedDirection} onChange={toggleDrop} />
          <Spacer value="49px" />
          {renderControls()}
        </div>
      </SlideIn>
    </>
  );
};
