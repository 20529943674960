import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Security = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8 0L0 3V9.09C0 14.14 3.41 18.85 8 20C12.59 18.85 16 14.14 16 9.09V3L8 0ZM14 9.09C14 13.09 11.45 16.79 8 17.92C4.55 16.79 2 13.1 2 9.09V4.39L8 2.14L14 4.39V9.09Z" />
    </SvgIcon>
  );
};

export default Security;
