import styled from "@emotion/styled";
import { device } from "theme";

export const SDrop = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column nowrap;

  & > span {
    white-space: nowrap;
    &:first-of-type {
      margin-right: 8px;
      color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
    }
  }

  @media ${device.desktopPlus} {
    flex-flow: row nowrap;
    margin-right: 16px;
  }
`;
