import Icon from "components/icons";
import { CusTypo } from "components/cusTypo/custom-typography";
import { Loader } from "components/loader/loader";
import { SCheckBtn } from "./styles";

interface ICheckBtn {
  children: string;
  onChange: (a) => void;
  name: string;
  value: boolean;
  isLoading: boolean;
}

export const CheckBtn = ({
  children,
  onChange,
  name,
  value = false,
  isLoading = false,
}: ICheckBtn) => {
  return (
    <SCheckBtn
      onClick={() => {
        onChange(!value);
      }}
      isActive={value}
      type="button"
      name={name}
    >
      {isLoading ? <Loader size="S_16" /> : null}
      {!isLoading && value ? <Icon.Check sx={{ width: 16, height: 16 }} /> : null}

      <CusTypo variant="p4_medium">{children}</CusTypo>
    </SCheckBtn>
  );
};
