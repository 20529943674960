import { useRef, useState } from "react";

import theme from "theme";
import { Spacer } from "components/spacer/spacer.component";
import Icon from "components/icons";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SFieldFiles, SUploadSuccess } from "./styles";

type IObj = Pick<File, "name" | "size">;

interface IDragDropFile {
  name: string;
  hasError?: boolean;
  file: IObj | null;
  onChange: (value: any) => void;
  accept?: string;
}

export const DragDropFile: React.FC<IDragDropFile> = ({
  name,
  onChange,
  hasError,
  file,
  accept = ".pdf,.doc",
}) => {
  const [dragActive, setDragActive] = useState(false);
  const [dataUploadFile, setDataUploadFile] = useState<IObj | null>(file);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();

    const fileUploaded = e.dataTransfer.files[0];

    if (fileUploaded.size <= 1e7) {
      onUploadFile(fileUploaded);
      setDragActive(false);
    } else {
      deleteFile();
    }
  };

  const handleUpload = (e) => {
    const fileUploaded = e.target.files[0];

    if (fileUploaded.size <= 1e7) {
      onUploadFile(fileUploaded);
    } else {
      deleteFile();
    }
  };

  const onUploadFile = (fileUploaded: File) => {
    onChange(fileUploaded);
    setDataUploadFile(fileUploaded);
  };

  const onButtonClick = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const deleteFile = () => {
    setDataUploadFile(null);
    onChange(undefined);
    if (inputRef.current) inputRef.current.value = "";
  };

  return (
    <div>
      {dataUploadFile != null && !hasError ? (
        <SUploadSuccess>
          <div>
            <CusTypo variant="c1_regular">{dataUploadFile.name}</CusTypo>

            <CusTypo variant="c1_regular">{(dataUploadFile.size / 1e6).toFixed(2)} MB</CusTypo>
          </div>
          <Icon.Close sx={{ width: 24, height: 24 }} onClick={deleteFile} />
        </SUploadSuccess>
      ) : (
        <>
          <SFieldFiles onDragEnter={handleDrag} active={dragActive} hasError={hasError}>
            <input ref={inputRef} type="file" name={name} onChange={handleUpload} accept={accept} />
            <CusTypo variant="p4_regular" onClick={onButtonClick}>
              Выберите файл или переместите его сюда
            </CusTypo>
          </SFieldFiles>
          <Spacer value="8px" />

          <CusTypo
            variant="c1_regular"
            styles={{
              color: hasError
                ? theme.mtsColor.accent.negative.lightMode
                : theme.mtsColor.grey.greyDark,
            }}
          >
            Формат файла: {accept.toUpperCase()}. Не более 10 Мб.
          </CusTypo>
        </>
      )}
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </div>
  );
};
