export const maxDeploySteps = 5; // кол-во шагов деплоя

export enum TENANT_DEPLOY_STATUS {
  Выполнено = "Выполнено",
  "В процессе" = "В процессе",
  Ошибка = "Ошибка",
}

export const activeTenantTitles = {
  users: "Количество сотрудников",
  service_type: "Оказываемые услуги",
  contr_num: "Номер договора",
  additional_info: "Ответственный",
  time_schema: "Часовой пояс",
};

export const activeTenantDetailsKey: string[] = Object.keys(activeTenantTitles);
