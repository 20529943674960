import {
  IResearch,
  IResearchResponse,
  IResearchSetStatusReq,
  IResearchSetTicketReq,
} from "interface/analytics";

export interface IAnalyticsQuery {
  pageParams: unknown[];
  pages: IResearchResponse[];
}

const isRequestTicketTG = (
  entity: IResearchSetTicketReq | IResearchSetStatusReq,
): entity is IResearchSetTicketReq => {
  return typeof entity["action"] !== "undefined";
};

export const replaceDashboardItem = (
  wholePage: IResearchResponse,
  request: IResearchSetTicketReq | IResearchSetStatusReq,
) => {
  let ind = wholePage.results.findIndex((item) => item.id_pp === request.id_pp);
  let itm = wholePage.results[ind];

  let updItm;

  if (isRequestTicketTG(request)) {
    updItm = {
      [request.ticket]: request.action === "ins" ? 1 : null,
    };
  } else {
    updItm = {
      fp: request.fraud_probability,
      fp_cm: request.comment,
    };
  }

  const composedItm = {
    ...itm,
    ...updItm,
  };

  let resContent = wholePage.results.map((el) => el);
  resContent[ind] = composedItm;

  return {
    ...wholePage,
    results: resContent,
  };
};

export const replaceGeoItem = (
  wholePage: IResearch[],
  request: IResearchSetTicketReq | IResearchSetStatusReq,
) => {
  let ind = wholePage.findIndex((item) => item.id_pp === request.id_pp);
  let itm = wholePage[ind];

  let updItm;

  if (isRequestTicketTG(request)) {
    updItm = {
      [request.ticket]: request.action === "ins" ? 1 : null,
    };
  } else {
    updItm = {
      fp: request.fraud_probability,
      fp_cm: request.comment,
    };
  }

  const composedItm = {
    ...itm,
    ...updItm,
  };

  let resContent = [...wholePage];
  resContent[ind] = composedItm;

  return resContent;
};
