import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { device } from "theme";

export const SCard = styled(Link)`
  background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
  box-shadow: 1px 26px 22px rgba(188, 195, 208, 0.5);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  position: relative;
  overflow: hidden;
  text-decoration: none;

  @media ${device.desktopPlus} {
    flex-direction: row;
    justify-content: inherit;
    padding: 32px;
  }
`;

export const SMarker = styled.div<{ variant: string }>`
  padding: 8px;
  border-radius: 4px;
  background: ${(props) => props.theme.mtsColor.base.idle};

  @media ${device.desktopPlus} {
    padding: 12px;
  }

  ${(props) =>
    props.variant === "toCheck"
      ? `background: ${props.theme.mtsColor.badge.idle}`
      : props.variant === "normal"
      ? `background: ${props.theme.mtsColor.badge.success}`
      : props.variant === "hacking"
      ? `background: ${props.theme.mtsColor.badge.critical}`
      : props.variant === "suspicion"
      ? `background: ${props.theme.mtsColor.badge.warning}`
      : props.variant === "noLoad"
      ? `background: ${props.theme.mtsColor.badge.grey}`
      : props.variant === "noAccess"
      ? `background: ${props.theme.mtsColor.badge.grey}`
      : props.variant === "defective"
      ? `background: ${props.theme.mtsColor.badge.dark}`
      : props.variant === "replaced"
      ? `background: ${props.theme.mtsColor.badge.plum}`
      : null}
`;

export const SMarkerCicle = styled.div<{ variant: string }>`
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 12px;

  @media ${device.desktopPlus} {
    width: 24px;
    height: 24px;
  }

  ${(props) =>
    props.variant === "toCheck"
      ? `background: ${props.theme.mtsColor.base.idle}`
      : props.variant === "normal"
      ? `background: ${props.theme.mtsColor.base.success}`
      : props.variant === "hacking"
      ? `background: ${props.theme.mtsColor.base.critical}`
      : props.variant === "suspicion"
      ? `background: ${props.theme.mtsColor.base.warning}`
      : props.variant === "noLoad"
      ? `background: ${props.theme.mtsColor.grey.greyMedium}`
      : props.variant === "noAccess"
      ? `background: ${props.theme.mtsColor.grey.greyMedium}`
      : props.variant === "defective"
      ? `background: ${props.theme.mtsColor.background.primary.darkMode}`
      : props.variant === "replaced"
      ? `background: ${props.theme.mtsColor.base.plum}`
      : null}
`;

export const SContent = styled.div`
  @media ${device.desktopPlus} {
    padding-left: 35px;
  }
`;

export const SNumber = styled.div`
  & .MuiTypography-root {
    display: block;
    margin-top: 12px;

    @media ${device.desktopPlus} {
      margin-top: 16px;
    }
  }
`;

export const SImg = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
`;
