import { RenderFilter } from "components/table/render-filter/renderFilter.component";

export const tableStructure = [
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="ID"
        withPadding
      />
    ),
    accessor: "id",
    minWidth: 92,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Серийный номер"
        withPadding
      />
    ),
    accessor: "serial",
    minWidth: 232,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Адрес"
        withPadding
      />
    ),
    accessor: "address",
    minWidth: 696,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Вероятность фрода"
        withPadding
      />
    ),
    accessor: "severity",
    minWidth: 315,
    canFilter: true,
    filter: "includesSome",
  },
];
