import { format, isEqual } from "date-fns";
import { IAskue } from "interface/askue";

import { CopyToClipboard } from "components/copyToClipboard/copyToClipboard.component";
import { SelectAction } from "./subComponent/selectAction";
import { CusTypo } from "components/cusTypo/custom-typography";
import { DEPARTURE_RESULT, EMPTY_CELL_VALUE } from "stream-constants/keys-and-constants";
import { SColored } from "./styles";

export const constructTableData = (draft: IAskue[]) =>
  draft?.map((d) => {
    const { dt_create, dt_verify } = d;

    return {
      ...d,
      dt_create: new Date(dt_create ?? 0),
      dt_verify: new Date(dt_verify ?? 0),
    };
  });

export const constructColumns = ({ columns }) => {
  return columns.map((singleColumn) => {
    return {
      ...singleColumn,
      ...(singleColumn.accessor === "sn"
        ? { Cell: ({ value }) => <CopyToClipboard text={value ?? "-"} variant="p4_medium" /> }
        : singleColumn.accessor === "dt_verify" || singleColumn.accessor === "dt_create"
        ? {
            Cell: ({ value }) => (
              <CusTypo variant="c1_regular">
                {isEqual(value, new Date(0)) ? "-" : format(value, "dd.MM.yyyy")}
              </CusTypo>
            ),
          }
        : singleColumn.accessor === "rele_status"
        ? {
            Cell: ({ value }) => (
              <SColored variant="c1_regular" isOn={value}>
                {(value ? DEPARTURE_RESULT.ENABLED : DEPARTURE_RESULT.DISABLED) ?? EMPTY_CELL_VALUE}
              </SColored>
            ),
          }
        : singleColumn.accessor === "action"
        ? {
            Cell: ({ row }) => <SelectAction id={row.original.sn} />,
          }
        : { Cell: ({ value }) => value ?? "—" }),
    };
  });
};
