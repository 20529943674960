import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { isEmpty } from "lodash";

import { IFavs } from "pages/analytics/const";
import { ContextSettings } from "context/context-settings";
import { useEditFavlist } from "hooks/api/analytics";
import { Spacer } from "components/spacer/spacer.component";
import Icon from "components/icons";
import { MTSButton } from "components/mts-button/mts-button";
import { PopupAction } from "components/popup-action/popup-action";
import { MTSModal } from "components/mts-modal/mts-modal";
import { Switch } from "components/switch/switch.component";
import { declWords } from "utils/declensionWords";
import { EditFavForm } from "./editForm";
import { SlideIn } from "../../table/slideIn/slideIn.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SButtons, SFltBtn, SFltVivid, SGroup, SRow, STxtGrey, STxtLink, SVar } from "./styles";

interface IProp {
  isOpen: boolean;
  close: () => void;
  open: () => void;
  favState: IFavs;
  setItems: Dispatch<SetStateAction<IFavs>>;
  onApply: () => void;
  onClear: () => void;
  openAllFavs?: () => void;
  hasValue: boolean;
}

export const TableFav: React.FC<IProp> = ({
  isOpen,
  close,
  open,
  favState,
  setItems,
  onApply,
  onClear,
  openAllFavs,
  hasValue,
}) => {
  const [isOn, setOn] = useState(true);
  const { isDesktop, profile } = useContext(ContextSettings);
  const [isEdit, setEdit] = useState(false);
  const { mutate: duplicate } = useEditFavlist();

  const clearAll = () => {
    setItems((s) => ({
      title: "Новый список",
      favs: [],
    }));
    onClear();
  };

  const removeId = (id) => {
    setItems((s) => {
      const newFavs = s.favs.filter((a) => a !== id);
      return { ...s, favs: newFavs };
    });
  };

  const handleToggleSwitch = (e) => {
    if (e) {
      onApply();
      setOn(true);
    } else {
      onClear();
      setOn(false);
    }
  };

  return (
    <>
      <SFltBtn withValue={hasValue} onClick={open}>
        <Icon.Star />
      </SFltBtn>
      <SlideIn
        isOpen={isOpen}
        close={close}
        withCloseHook={false}
        headerChildren={
          <>
            <Switch
              id="header__switch"
              setChecked={handleToggleSwitch}
              checked={isOn}
              className="header__switch"
            />

            <CusTypo variant={!isDesktop() ? "h4_medium" : "h3_medium"} font="wide">
              Выборка
            </CusTypo>
          </>
        }
      >
        {!isEmpty(favState.favs) ? (
          <>
            <SGroup>
              <CusTypo variant="h3_medium">{favState.title}</CusTypo>
              <div>
                <PopupAction
                  items={[
                    {
                      title: "Сохранить как",
                      onClick: () => setEdit(true),
                    },
                  ].concat(
                    favState.id != null && profile?.id === +(favState.author ?? -1)
                      ? [
                          {
                            title: "Сохранить",
                            onClick: () =>
                              duplicate({
                                id_list: favState.id!,
                                comment_: favState.comment,
                                name_list: favState.title,
                                id_pp_list: favState.favs,
                              }),
                          },
                        ]
                      : [],
                  )}
                  view="contrast"
                />
                <SFltVivid onClick={openAllFavs}>
                  <Icon.List />
                </SFltVivid>
              </div>
            </SGroup>
            {favState.comment ? (
              <section style={{ textAlign: "left" }}>
                <Spacer value="4px" />
                <CusTypo variant="c1_regular" font="comp">
                  {favState.comment}
                </CusTypo>
              </section>
            ) : null}
            <Spacer value="24px" />
            <SVar>
              <CusTypo variant="p3_regular">ID PP</CusTypo>
            </SVar>
            {favState.favs.map((a) => (
              <SRow key={a}>
                <CusTypo variant="p3_regular">{a}</CusTypo>
                <Icon.Close
                  sx={{ width: 24, height: 24, cursor: "pointer" }}
                  onClick={() => removeId(a)}
                />
              </SRow>
            ))}
            <Spacer value="24px" />
            <SButtons>
              <MTSButton size="M" variant="primary" onClick={onApply} disabled={!isOn}>
                Применить
              </MTSButton>
              <MTSButton size="M" variant="secondary" onClick={clearAll}>
                Очистить список
              </MTSButton>
            </SButtons>
            <Spacer value="24px" />

            <CusTypo variant="c1_regular">
              Добавлено {declWords(favState.favs.length, ["устройство", "устройства", "устройств"])}
            </CusTypo>
          </>
        ) : (
          <>
            <Spacer value="160px" />

            <STxtGrey variant="p3_regular">Добавьте устройства или</STxtGrey>

            <STxtLink variant="p3_regular" onClick={openAllFavs}>
              загрузите новый список
            </STxtLink>
          </>
        )}
        <MTSModal open={isEdit} close={() => setEdit(false)} size={"S"}>
          <EditFavForm id={null} onCancel={() => setEdit(false)} idList={favState.favs} />
        </MTSModal>
      </SlideIn>
    </>
  );
};
