import styled from "@emotion/styled";

export const SStack = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;
`;

export const SControls = styled.div`
  min-width: 0;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
`;
