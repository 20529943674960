export const categories = {
  visibility: "Видимость",
  optim: "Оптимизация графиков",
  search: "Поиск устройств на карте",
};

export type IMainCats = keyof typeof categories;

// возможные ключи в state компонента
const CONS = "metrics";
const ADR = "address";
const FDB = "feedback";
const FP = "fp_uc";
const BT = "t0";
const CD = "t1";
const MI = "t2";
const CNS = "t3";
const RC = "t4";
const RE = "t5";
const T1 = "type1";
const T2 = "type2";
const T3 = "type3";
const T4 = "type4";
const T5 = "type5";
const UNI = "unitype";
const GEO = "geo";
const YA = "yandex";
const GOO = "google";
const GIS = "double_gis";

export const headByKey = {
  groups: "Группы и столбцы",
  tickets: "Тикеты",
  bigdata: "Bigdata model",
  optim: "Оптимизация графиков по умолчанию",
  search: "Тип поиска",
  provider: "Карты",
};

export const switchTitleByKey = {
  [CONS]: "Потребление электроэнергии",
  [ADR]: "Адрес абонента",
  [FDB]: "Обратная связь",
  [BT]: "Плохая телеметрия (T0)",
  [CD]: "Смена ПУ (T1)",
  [MI]: "Майнеры (T2)",
  [CNS]: "Снижение потребления (T3)",
  [RC]: "Рецидив (T4)",
  [RE]: "Повторная проверка (T5)",
  [T1]: "Тype1",
  [T2]: "Тype2",
  [T3]: "Тype3",
  [T4]: "Тype4",
  [T5]: "Тype5",
  [UNI]: "Unitype",
  [FP]: "Кто сменил",
};

export const radioTitleByKey = {
  [GEO]: "Геокоординаты",
  [ADR]: "Адрес",
  [YA]: "Yandex",
  [GOO]: "Google",
  [GIS]: "2ГИС",
};

export const scheme = {
  visibility: {
    groups: {
      switch: [CONS, ADR, FDB, FP],
    },
    tickets: {
      switch: [BT, CD, MI, CNS, RC, RE],
    },
    bigdata: {
      switch: [T1, T2, T3, T4, T5, UNI],
    },
  },
  optim: {},
  search: {
    search: [GEO, ADR],
    provider: [YA, GOO, GIS],
  },
};

export const defaultOptim = {
  max: 10,
  mult: 3,
};

export const defaultSearch = { search: "geo", provider: "yandex" };
