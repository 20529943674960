import { IBreadcrumbsLink } from "interface/common";
import { ROUTE_SHOP_MANAGEMENT, ROUTE_MANAGEMENT } from "stream-constants/route-constants";

export const links: IBreadcrumbsLink[] = [
  { name: "Управление", href: `/${ROUTE_MANAGEMENT}` },
  { name: "EnergyTool Shop", href: `/${ROUTE_SHOP_MANAGEMENT}` },
];

export const TABLE_SORT_DEFAULT_SHOP = [
  {
    accessor: "name",
    text: "Название",
  },
  {
    accessor: "category",
    text: "Категория",
  },
  {
    accessor: "type",
    text: "Тип",
  },
  {
    accessor: "price",
    text: "Цена",
  },
  {
    accessor: "hide_flag",
    text: "Видимость",
  },
];
