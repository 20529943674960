import * as React from "react";
import cn from "classnames";
import Button, { ButtonProps } from "@mui/material/Button";
import { CusTypo } from "components/cusTypo/custom-typography";
import { styled } from "@mui/material/styles";
import useStyles from "./styles";

import spinnerWhite16 from "./svg/SpinnerWhite16.svg";
import spinnerPrimary16 from "./svg/SpinnerPrimary16.svg";
import spinnerNegative16 from "./svg/SpinnerNegative16.svg";
import spinnerWhite24 from "./svg/SpinnerWhite24.svg";
import spinnerPrimary24 from "./svg/SpinnerPrimary24.svg";
import spinnerNegative24 from "./svg/SpinnerNegative24.svg";

interface MTSButtonProps extends Omit<ButtonProps, "size" | "variant"> {
  size?: "S" | "M" | "M_REGULAR" | "L" | "XL";
  variant?:
    | "primary"
    | "secondary"
    | "secondary_inverted"
    | "ghost"
    | "negative"
    | "negative_inverted"
    | "outline"
    | "outline_negative"
    | "outline_light"
    | "outline_dark"
    | "dark_blue";
  disabled?: boolean;
  children?: React.ReactNode | string;
  loading?: boolean;
  icon?: React.ReactNode;
  format?: "icon" | "regular" | "iconRound";
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const getTypographyVariantBySize = (size: string) => {
  switch (size) {
    case "S": {
      return "p4_medium";
    }
    case "M": {
      return "p3_medium";
    }
    case "M_REGULAR": {
      return "p3_regular";
    }
    case "L": {
      return "p3_medium";
    }
    case "XL": {
      return "h4_medium";
    }
    default:
      return "p4_medium";
  }
};

const getSpinner = (size: string, variant: string) => {
  if (variant === "primary" || variant === "dark_blue") {
    if (size === "S" || size === "M") {
      return spinnerWhite16;
    }

    if (size === "L" || size === "XL") {
      return spinnerWhite24;
    }
  }

  if (
    variant === "secondary" ||
    variant === "secondary_inverted" ||
    variant === "ghost" ||
    variant === "outline" ||
    variant === "outline_light" ||
    variant === "outline_dark"
  ) {
    if (size === "S" || size === "M" || size === "M_REGULAR") {
      return spinnerPrimary16;
    }

    if (size === "L" || size === "XL") {
      return spinnerPrimary24;
    }
  }

  if (variant === "negative" || variant === "negative_inverted" || variant === "negative_outline") {
    if (size === "S" || size === "M" || size === "M_REGULAR") {
      return spinnerNegative16;
    }

    if (size === "L" || size === "XL") {
      return spinnerNegative24;
    }
  }

  return spinnerPrimary16;
};

const CustomButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  color: "#FFFFFF",
  backgroundColor: "#FF0032",
  borderColor: "none",
  border: "none",
  "&:hover": {
    backgroundColor: "rgba(212, 6, 16, 1)",
    boxShadow: "none",
  },
  "&:active": {
    backgroundColor: "rgba(212, 6, 16, 1)",
    boxShadow: "none",
  },
  "&:focus": {
    backgroundColor: "rgba(212, 6, 16, 1)",
    boxShadow: "none",
  },
});

export const MTSButton = ({
  size = "S",
  variant = "primary",
  disabled = false,
  children = "",
  loading = false,
  icon = false,
  format = "regular",
  onClick,
  ...rest
}: MTSButtonProps) => {
  const classes = useStyles();

  const isBtnIcon = format === "icon" || format === "iconRound";

  const btnClasses = cn({
    [classes.btnSizeS]: size === "S",
    [classes.btnSizeM]: size === "M" || size === "M_REGULAR",
    [classes.btnSizeL]: size === "L",
    [classes.btnSizeXL]: size === "XL",
    [classes.btnDisabled]: disabled,
    [classes.btnPrimary]: !disabled && variant === "primary",
    [classes.btnSecondary]: !disabled && variant === "secondary",
    [classes.btnSecondaryInverted]: !disabled && variant === "secondary_inverted",
    [classes.btnGhost]: !disabled && variant === "ghost",
    [classes.btnNegative]: !disabled && variant === "negative",
    [classes.btnNegativeInverted]: !disabled && variant === "negative_inverted",
    [classes.btnOutline]: !disabled && variant === "outline",
    [classes.btnOutlineNegative]: !disabled && variant === "outline_negative",
    [classes.btnOutlineLight]: !disabled && variant === "outline_light",
    [classes.btnOutlineDark]: !disabled && variant === "outline_dark",
    [classes.btnDarkBlue]: !disabled && variant === "dark_blue",
    [classes.iconBtn]: format === "icon",
    [classes.iconRoundBtn]: format === "iconRound",
  });

  const iconClasses = cn({
    [classes.iconButtons]: true,
    [classes.typographyAlignStyles]: true,
    [classes.iconHidden]: !disabled && loading,
    [classes.iconGrey]: disabled,
    [classes.iconWhite]:
      !disabled &&
      (variant === "primary" || variant === "outline_light" || variant === "dark_blue"),
    [classes.iconBlack]:
      !disabled &&
      (variant === "secondary" ||
        variant === "secondary_inverted" ||
        variant === "ghost" ||
        variant === "outline" ||
        variant === "outline_light" ||
        variant === "outline_dark"),
    [classes.iconRed]:
      !disabled &&
      (variant === "negative" || variant === "negative_inverted" || variant === "outline_negative"),
  });

  const typographyClasses = cn({
    [classes.typographyAlignStyles]: true,
    [classes.typographyHidden]: !disabled && loading,
    [classes.gap6]: size === "S" || size === "M" || size === "M_REGULAR",
    [classes.gap10]: size === "L",
    [classes.gap12]: size === "XL",
    [classes.labelDisabled]: disabled,
    [classes.labelPrimary]: !disabled && variant === "primary",
    [classes.labelSecondary]: !disabled && variant === "secondary",
    [classes.labelGhost]: !disabled && variant === "ghost",
    [classes.labelNegative]: !disabled && variant === "negative",
    [classes.labelNegative]: !disabled && variant === "negative",
    [classes.labelNegativeInverted]: !disabled && variant === "negative_inverted",
    [classes.labelOutline]: !disabled && variant === "outline",
    [classes.labelOutlineNegative]: !disabled && variant === "outline_negative",
    [classes.labelOutlineLight]: !disabled && variant === "outline_light",
    [classes.labelOutlineDark]: !disabled && variant === "outline_dark",
    [classes.labelDarkBlue]: !disabled && variant === "dark_blue",
  });

  return (
    <CustomButton
      classes={{ root: btnClasses }}
      disableRipple
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {!disabled && loading ? (
        <div className={classes.spinnerBlock}>
          <img src={getSpinner(size, variant)} className={classes.spinner} alt="spinner" />
        </div>
      ) : null}
      {isBtnIcon ? (
        icon
      ) : icon ? (
        <>
          <span className={iconClasses}>{icon}</span>
          <CusTypo variant={getTypographyVariantBySize(size)} className={typographyClasses}>
            {children}
          </CusTypo>
        </>
      ) : (
        <CusTypo variant={getTypographyVariantBySize(size)} className={typographyClasses}>
          {children}
        </CusTypo>
      )}
    </CustomButton>
  );
};
