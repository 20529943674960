import { useCallback, useMemo, useState } from "react";
import { isEmpty, sortBy } from "lodash";

import { IMapMiner } from "interface/map";
import Icon from "components/icons";
import { SlideIn } from "components/table/slideIn/slideIn.component";
import { SFltBtn, SResult } from "components/table/table-filter/styles";
import { CusTypo } from "components/cusTypo/custom-typography";
import { MobileTable } from "components/table/mobile-table/mobileTable.component";
import { scoreToTitle } from "../const";
import { ColorCircle, forceCss, SMinListCont, SRow, SRowEnd } from "../styles";
import { declWords } from "utils/declensionWords";
import { Spacer } from "components/spacer/spacer.component";

const SList = ({ row }) => {
  const { zid, score } = row.original;
  return (
    <SMinListCont>
      <SRow style={{ cursor: "pointer" }}>
        <CusTypo variant="p4_regular" font="comp">
          ZID {zid}
        </CusTypo>
        <SRowEnd>
          <ColorCircle score={score} />
          <CusTypo variant="c1_regular" font="comp">
            {scoreToTitle(score)}
          </CusTypo>
        </SRowEnd>
      </SRow>
    </SMinListCont>
  );
};

export const constructTableData = (draft: IMapMiner[]) =>
  draft?.map((d) => {
    const { mean_x, mean_y } = d;

    return {
      ...d,
      id: [mean_y, mean_x],
    };
  });

export const tableMobStructure = [
  {
    accessor: "id",
    Row: SList,
  },
  {
    accessor: "zid",
    canFilter: false,
  },
  {
    accessor: "score",
    canFilter: false,
  },
];

export const MinersList = ({ list, mapInstance }) => {
  const [isOpen, setOpen] = useState(false);

  const mobileColumns = useMemo(() => tableMobStructure, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tableData = useMemo(() => constructTableData(sortBy(list ?? [], ["zid"])), [list?.length]);

  const clickMobile = useCallback(
    (id) => {
      setOpen(false);
      mapInstance?.setView(id, 18);
    },
    [mapInstance],
  );

  return (
    <>
      <SFltBtn onClick={() => setOpen(true)} btnStyle={forceCss}>
        <Icon.List />
      </SFltBtn>
      <SlideIn
        title="Список майнеров"
        isOpen={isOpen}
        close={() => {
          setOpen(false);
        }}
        zIndex={402}
      >
        {!isEmpty(list) ? (
          <>
            <MobileTable columns={mobileColumns} data={tableData} onRowClick={clickMobile} />
            <Spacer value="12px" />
            <SResult>
              <CusTypo variant="c1_regular">
                Найдено {declWords(list?.length, ["результат", "результата", "результатов"])}
              </CusTypo>
            </SResult>
          </>
        ) : (
          <div>Ничего не найдено</div>
        )}
      </SlideIn>
    </>
  );
};
