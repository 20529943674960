import { ReactNode, useState } from "react";
import Icon from "components/icons";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SActions } from "./styles";
import { MTSModal } from "components/mts-modal/mts-modal";
import { MTSButton } from "components/mts-button/mts-button";

interface IModalAction {
  items: { title: ReactNode | string; onClick: Function }[];
  isLight?: boolean;
}

export const ModalAction: React.FC<IModalAction> = ({ items, isLight = true }) => {
  const [showModalAction, setShowModalAction] = useState(false);
  const toggleShowModalAction = () => setShowModalAction(!showModalAction);

  return (
    <>
      <MTSButton
        size="S"
        variant={isLight ? "ghost" : "secondary"}
        onClick={(e) => {
          e.stopPropagation();
          toggleShowModalAction();
        }}
        format="icon"
        icon={<Icon.Ellipsis sx={{ width: 18, height: 4 }} />}
      />
      <MTSModal open={showModalAction} close={toggleShowModalAction}>
        <SActions>
          <ul>
            {items.map((item: any, index: number) => (
              <li
                key={index}
                onClick={(e) => {
                  item.onClick();
                  toggleShowModalAction();
                }}
              >
                {typeof item.title === "string" ? (
                  <CusTypo variant="p4_regular" styles={{ color: item.color ?? "inherit" }}>
                    {item.title}
                  </CusTypo>
                ) : (
                  item.title
                )}
              </li>
            ))}
          </ul>
          <MTSButton size="M" variant="secondary" onClick={toggleShowModalAction}>
            Отмена
          </MTSButton>
        </SActions>
      </MTSModal>
    </>
  );
};
