import React, { useContext } from "react";

import { ContextSettings } from "context/context-settings";
import { makeManagUserCard } from "../utils";
import Icon from "components/icons";
import { CusTypo } from "components/cusTypo/custom-typography";
import { PopupAction } from "components/popup-action/popup-action";
import { ModalAction } from "components/modal-action/modal-action";
import { hasRoleAccess } from "components/forms/user/validation-schema";
import { SContainerMobile, SContent } from "../styles";
import { SName } from "./styles";

export const UserMobCard = ({ data, dataPopup }) => {
  const { isResponsible, phone, roles } = data;
  const { roles: contextRoles, isMobile } = useContext(ContextSettings);
  const isCanSeeTenant = hasRoleAccess(contextRoles, roles);

  return (
    <SContainerMobile>
      <SContent>
        {Object.entries(makeManagUserCard(data)).map(([key, val]) => {
          return key === "status" ? (
            val.value
          ) : (
            <div key={key}>
              {key === "phone" ? (
                <a href={`tel:${phone}`} onClick={(e) => e.stopPropagation()}>
                  <CusTypo variant="c1_regular" font="comp">
                    {val.value}
                  </CusTypo>
                </a>
              ) : key === "tenantTitle" ? (
                isCanSeeTenant ? (
                  <CusTypo variant="c1_regular" font="comp">
                    {val.value}
                  </CusTypo>
                ) : null
              ) : (
                <SName>
                  {isResponsible && key === "userName" ? <Icon.Crown /> : null}
                  <CusTypo variant={key === "userName" ? "p3_bold" : "c1_regular"} font="comp">
                    {val.value}
                  </CusTypo>
                </SName>
              )}
            </div>
          );
        })}
      </SContent>
      {isMobile() ? (
        <ModalAction items={dataPopup} />
      ) : (
        <PopupAction items={dataPopup} view="light" />
      )}
    </SContainerMobile>
  );
};
