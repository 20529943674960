import Icon from "components/icons";
import useStyles from "./styles";

interface AccordionIconProps {
  isActive?: boolean;
  onToggle?: (val: number) => void;
  index?: number;
}

export const AccordionIcon = (props: AccordionIconProps) => {
  const { isActive, onToggle, index = 0 } = props;
  const classes = useStyles();

  return (
    <div
      className={classes.iconWrap}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        if (onToggle) onToggle(index);
        e.stopPropagation();
      }}
    >
      <span className={`${classes.icon} ${isActive ? classes.active : ""}`}>
        <Icon.ArrowHeadRight sx={{ width: 24, height: 24 }} />
      </span>
    </div>
  );
};
