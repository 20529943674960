import { IHttpMethods } from "stream-constants/server-constants";
import { TENANT } from "services/urls";
import { fetchData } from "services/services-utils/fetch-data";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { IMenegementTenantResponse, IUpdateTenant } from "interface/management";
import { base, individual_tenant_mock } from "./mock-data";

export const getManagementTenants = async () => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = base;
  } else {
    result = await fetchData({
      url: TENANT,
      method: IHttpMethods.GET,
      name: "getManagementTenants",
      emptyResponse: false,
    });
  }

  return result;
};

export const getTenantById = async (
  id: number | string,
): Promise<{ data: IMenegementTenantResponse }> => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = individual_tenant_mock;
  } else {
    result = fetchData({
      url: `${TENANT}/${id}`,
      method: IHttpMethods.GET,
      name: "getTenantById",
      emptyResponse: false,
    });
  }

  return result;
};

export const getAddTenant = async (data: any) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = "OK";
  } else {
    result = fetchData({
      url: TENANT,
      method: IHttpMethods.POST,
      name: "formAddTenant",
      body: JSON.stringify(data),
      emptyResponse: false,
    });
  }

  return result;
};

export const deleteTenantById = async (id: number | string) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = "OK";
  } else {
    result = fetchData({
      url: `${TENANT}/${id}`,
      method: IHttpMethods.DELETE,
      name: "deleteTenantById",
      emptyResponse: false,
    });
  }

  return result;
};

export interface IFuncUpdateTenant {
  data: IUpdateTenant;
  id: number | string;
}

export const updateTenant = async ({ data, id }: IFuncUpdateTenant) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = "OK";
  } else {
    result = fetchData({
      url: `${TENANT}/${id}`,
      method: IHttpMethods.PATCH,
      name: "updateTenant",
      body: JSON.stringify(data),
      emptyResponse: false,
    });
  }

  return result;
};
