import { useState } from "react";
import { toast } from "react-toastify";
import * as L from "leaflet";

import Icon from "components/icons";
import { Loader } from "components/loader/loader";
import { Toast } from "components/toast/toast";
import { SToolBtn, SZoomPos } from "../styles";
import mrk from "./pos.svg";

const Location = ({ mapInstance }) => {
  const [isLoading, setLoading] = useState(false);
  const [layer, setLayer] = useState<any>(undefined);

  const handleLocation = () => {
    setLoading(true);
    if (layer != null) mapInstance.removeLayer(layer);
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        setLoading(false);
        if (coords != null) {
          const exp = { lat: coords.latitude, lng: coords.longitude };
          const svg = L.icon({
            iconUrl: mrk,
            iconSize: [32, 32],
            iconAnchor: [16, 32],
          });
          const marker = new L.Marker(exp, { icon: svg });
          const locLayer = L.layerGroup().addTo(mapInstance);
          setLayer(locLayer);
          marker.addTo(locLayer);
          mapInstance?.setView([exp.lat, exp.lng], 18);
        }
      },
      ({ message }) => {
        setLoading(false);
        toast(<Toast title={message} isError />, {
          autoClose: 2000,
          hideProgressBar: false,
        });
      },
      {
        enableHighAccuracy: true,
      },
    );
  };

  return <SToolBtn onClick={handleLocation}>{isLoading ? <Loader /> : <Icon.Navi />}</SToolBtn>;
};

export const ZoomLocation = ({ mapInstance }) => {
  const zoomIn = () => mapInstance.setZoom(mapInstance.getZoom() + 1);
  const zoomOut = () => mapInstance.setZoom(mapInstance.getZoom() - 1);
  return (
    <SZoomPos>
      <SToolBtn onClick={zoomIn}>
        <Icon.Plus />
      </SToolBtn>
      <SToolBtn onClick={zoomOut}>
        <Icon.Minus />
      </SToolBtn>
      <div style={{ margin: "48px 0 0 0" }}>
        <Location mapInstance={mapInstance} />
      </div>
    </SZoomPos>
  );
};
