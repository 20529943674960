import { IHttpMethods } from "stream-constants/server-constants";
import {
  ASKUE_DEMO,
  DEMO_DETAILING,
  DEMO_MANAGEMENT,
  DEMO_MANAGEMENT_UPDATE_STATUS,
} from "services/urls";
import { fetchData } from "services/services-utils/fetch-data";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { base, single_mng_score } from "./mock-data";
import {
  IDemoCreateRequest,
  IDemoDeleteRequest,
  IDemoMngUpdStatusRequest,
} from "interface/management";
import { IDemoAskueCreateRequest } from "interface/askue";

export const getManagmentDemo = async () => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = base;
  } else {
    result = await fetchData({
      url: DEMO_MANAGEMENT,
      method: IHttpMethods.GET,
      name: "getManagmentDemo",
      emptyResponse: false,
    });
  }

  return result;
};

export const getDemoMngScore = async (id: string | number) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = single_mng_score;
  } else {
    result = await fetchData({
      url: `${DEMO_MANAGEMENT}/${id}`,
      method: IHttpMethods.GET,
      name: "getDemoMngScore",
      emptyResponse: false,
    });
  }

  return result;
};

export const demoMngUpdateStatus = async (data: IDemoMngUpdStatusRequest) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = "OK";
  } else {
    result = fetchData({
      url: DEMO_MANAGEMENT_UPDATE_STATUS,
      method: IHttpMethods.PATCH,
      name: "demoMngUpdateStatus",
      body: JSON.stringify(data),
      emptyResponse: false,
    });
  }

  return result;
};

export const demoMngGetDetailingXLSX = async (tenantId: string | number) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = [];
  } else {
    result = await fetchData({
      url: `${DEMO_DETAILING}/${tenantId}`,
      method: IHttpMethods.GET,
      name: "demoMngGetDetailingXLSX",
      emptyResponse: false,
      blobResponse: true,
      responseAbordTimeOut: false,
    }).then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `demo_${tenantId}_detailing.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
  }
  return result;
};

export const demoMngCreate = async (data: IDemoCreateRequest) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = "OK";
  } else {
    result = fetchData({
      url: `${DEMO_MANAGEMENT}/create`,
      method: IHttpMethods.POST,
      name: "demoMngCreate",
      body: JSON.stringify(data),
      emptyResponse: false,
    });
  }

  return result;
};

export const deleteDemoTenantById = async (data: IDemoDeleteRequest) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = "OK";
  } else {
    result = fetchData({
      url: `${DEMO_MANAGEMENT}/delete`,
      method: IHttpMethods.DELETE,
      name: "deleteDemoTenantById",
      emptyResponse: false,
      body: JSON.stringify(data),
    });
  }

  return result;
};

export const demoAskueCreate = async (data: IDemoAskueCreateRequest) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = ["11111", "22222"];
  } else {
    result = fetchData({
      url: `${ASKUE_DEMO}`,
      method: IHttpMethods.POST,
      name: "demoAskueCreate",
      body: JSON.stringify(data),
      emptyResponse: false,
    });
  }

  return result;
};
