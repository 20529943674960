import { MTSButton } from "components/mts-button/mts-button";
import { Spacer } from "components/spacer/spacer.component";
import Icon from "components/icons";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SControls, SStack } from "./styles";

interface IProp {
  onCancel: () => void;
  handleClick: () => void;
}

export const LoadFavForm: React.FC<IProp> = ({ onCancel, handleClick }) => {
  const onConfirm = () => {
    handleClick();
    onCancel();
  };

  return (
    <SStack>
      <Icon.Warn sx={{ width: 44, height: 44 }} />
      <Spacer value="8px" />

      <CusTypo variant="h4_medium" font="comp">
        Загрузить новый список
      </CusTypo>
      <Spacer value="8px" />

      <CusTypo variant="p4_regular" font="comp">
        Все несохраненные изменения
        <br />в текущем списке будут утеряны
      </CusTypo>
      <Spacer value="24px" />
      <SControls>
        <MTSButton size="M" variant="primary" type="submit" onClick={onConfirm}>
          Продолжить
        </MTSButton>
        <MTSButton size="M" variant="secondary" onClick={onCancel}>
          Отмена
        </MTSButton>
      </SControls>
    </SStack>
  );
};
