import { isEmpty } from "lodash";

import { Checkbox } from "components/checkbox/checkbox";
import { FilterAccordion } from "./filter-accordion/filter-accordion";
import { Loader } from "components/loader/loader";
import { IKek } from "../table-filter";
import { SLoadPos } from "../styles";

const titlebyKey = {
  po: "Производственное отделение",
  list_po: "Производственное отделение",
  priem: "РЭС",
  list_res: "РЭС",
  res: "РЭС",
  telebot_request_last_result: "Статус",
  ТМ: "Ошибки телеметрии",
  status: "Статус",
  severity: "Вероятность фрода",
  fraud_probability: "Вероятность фрода",
  mining_probability: "Майнеры",
  metka: "Метки",
  task_status: "Статусы выездов",
  roles_names: "Роли",
  created_dt: "Архив",
  services: "Услуги",
  deploy: "Развертывание",
  request_result: "Аномалии",
  level: "Уровень",
  meter_name: "Модель",
  bill_status: "Статус",
  tenant_id: "Организация",
};

interface IProps {
  isOn: boolean;
  isAllChecked: boolean;
  isLoading?: boolean;
  toggleAll: Function;
  toggleSingle: Function;
  currFilters: IKek;
  filterKey: string;
  targetArray?: {
    id: number;
    name: string | null;
    friendlyName?: string;
  }[];
  amount?: any;
}

export const ChxRenderer: React.FC<IProps> = ({
  targetArray,
  filterKey,
  isOn,
  isAllChecked,
  toggleAll,
  toggleSingle,
  currFilters,
  isLoading,
}) => {
  const targetArr = currFilters[filterKey];
  return (
    <div style={{ margin: "0 0 48px 0" }}>
      <FilterAccordion
        name={filterKey}
        title={titlebyKey[filterKey]}
        amountChecked={targetArr?.length ?? 0}
      >
        {isLoading && isEmpty(targetArray) ? (
          <SLoadPos>
            <Loader />
          </SLoadPos>
        ) : targetArray && !isEmpty(targetArray) ? (
          <>
            <Checkbox
              id={`all${filterKey}`}
              label="Выбрать все"
              name={`all${filterKey}`}
              onChange={() => toggleAll(filterKey, targetArray, isAllChecked)}
              checked={isAllChecked}
              disabled={!isOn}
            />
            {targetArray.map((item: any) => {
              return (
                <Checkbox
                  key={`${item.id}_${filterKey}`}
                  label={item.friendlyName ?? item.name}
                  name={`${item.id}_${filterKey}`}
                  checked={targetArr?.includes(item.name) || false}
                  id={`${item.id}_${filterKey}`}
                  onChange={() => toggleSingle(filterKey, item.name)}
                  disabled={!isOn}
                />
              );
            })}
          </>
        ) : null}
      </FilterAccordion>
    </div>
  );
};
