import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { Footer } from "components/footer/footer";
import { ContextSettings } from "context/context-settings";
import { CusTypo } from "components/cusTypo/custom-typography";
import { hardcoded_storage, links, makeDepStats } from "./const";
import { SSection, SSettings, SStat, SStatCont, STitle, STop } from "./styles";
import { Spacer } from "components/spacer/spacer.component";
import { useDeparturesMarkers } from "hooks/api/map";
import { makeCards } from "pages/anomalies/const";
import { AnomaliesCard } from "components/anomalies-card/anomalies-card";
import { NotifyBlock } from "components/notification-block/notification-block";
import { SERVICES } from "stream-constants/keys-and-constants";
import {
  composeBackendFilter,
  DEPARTURE_COLUMN,
  RES_FILTER,
  RESULT_COLUMN,
} from "pages/mapPage/const";
import { IDeparturesRequest } from "interface/map";
import { Loader } from "components/loader/loader";
import { ROUTE_DEPARTURES_MAP } from "stream-constants/route-constants";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { TableFilter } from "components/table/table-filter/table-filter";
import { SGrid } from "pages/anomalies/styles";

export const DeparturesPage = () => {
  const navigate = useNavigate();
  const { isDesktop, profile } = useContext(ContextSettings);
  const [depFilter, setDepFilter] = useState<any>(undefined);
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const {
    data: departures,
    mutate: mutateDep,
    isSuccess: depIsSuccess,
    isLoading: depIsLoading,
  } = useDeparturesMarkers();

  const depStats = makeDepStats(departures ?? []);

  const handleDepFilter = ({ filtersArr }) => {
    setDepFilter(filtersArr);
  };

  const clickStat = (text) => {
    const res = [...hardcoded_storage, { column: DEPARTURE_COLUMN, value: [text] }];
    StorageAPI.save(EStorageKeys.DEP_FILTER, JSON.stringify(res));
    navigate(`/${ROUTE_DEPARTURES_MAP}`);
  };

  const anomaliesCards = makeCards(departures ?? []) ?? [];

  const clickAnoCard = (text, event) => {
    event.preventDefault();
    const res = [...hardcoded_storage, { column: RESULT_COLUMN, value: [text] }];
    StorageAPI.save(EStorageKeys.DEP_FILTER, JSON.stringify(res));
    navigate(`/${ROUTE_DEPARTURES_MAP}`, {
      state: { is_anomap: true },
    });
  };

  useEffect(() => {
    if (profile?.services_ch.includes(SERVICES.LOSSES)) {
      const newFilt = composeBackendFilter<IDeparturesRequest>(depFilter, "dep");
      if (newFilt != null) mutateDep(newFilt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(depFilter), mutateDep, profile?.services_ch]);

  return (
    <>
      <SSection>
        <Breadcrumbs links={links} />
        <div>
          <STop>
            <STitle>
              <CusTypo variant={isDesktop() ? "h2_medium" : "h4_medium"} font="wide">
                Статус выездов
              </CusTypo>
            </STitle>
            <SSettings>
              <TableFilter
                dataLength={departures?.length ?? 0}
                isOpen={isOpenFilter}
                isOn
                defaultFilter={RES_FILTER}
                toogleShowFilter={setIsOpenFilter}
                onApplyFilter={handleDepFilter}
                scheme={{} as any}
              />
            </SSettings>
          </STop>
        </div>
        <Spacer value="32px" />
        {depIsLoading ? (
          <div style={{ height: 100 }}>
            <Loader />
          </div>
        ) : depIsSuccess && (departures ?? []).length ? (
          <SStatCont>
            {Object.entries(depStats).map(([key, val]) => (
              <SStat key={key} onClick={() => clickStat(key)}>
                <CusTypo variant={isDesktop() ? "p1_regular" : "c1_regular"} font="comp">
                  {key}
                </CusTypo>
                <CusTypo variant={isDesktop() ? "h2_medium" : "h4_medium"} font="wide">
                  {val as any}
                </CusTypo>
              </SStat>
            ))}
          </SStatCont>
        ) : (
          <NotifyBlock content="Нет данных" status="error" />
        )}
        <CusTypo variant={isDesktop() ? "h2_medium" : "h4_medium"} font="wide">
          Результаты проверок
        </CusTypo>
        <Spacer value="24px" />
        {depIsLoading ? (
          <div style={{ height: 100 }}>
            <Loader />
          </div>
        ) : depIsSuccess && (departures ?? []).length ? (
          <>
            <SGrid>
              {anomaliesCards.map((item) => (
                <AnomaliesCard
                  key={item.title}
                  title={item.value}
                  onClick={(e) => clickAnoCard(item.value, e)}
                  number={item.number}
                  variant={item.variant}
                />
              ))}
            </SGrid>
          </>
        ) : (
          <NotifyBlock content="Нет данных" status="error" />
        )}
      </SSection>
      <Footer />
    </>
  );
};
