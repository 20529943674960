import styled from "@emotion/styled";
import { HEADER_HEIGHT } from "components/header/styles";
import { device } from "theme";

export const SCont = styled.section`
  background-color: #ffffff;

  @media ${device.desktopPlus} {
    background-color: #f9f9f9;
  }

  & > main {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: "transparent";

    & > section {
      width: 100%;
      padding: 12px 0;
      min-height: calc(100vh - ${HEADER_HEIGHT}px);

      @media ${device.desktopPlus} {
        padding: 48px 32px 16px 32px;
      }

      & > article {
        padding: 0 20px 12px;

        @media ${device.desktopPlus} {
          padding: 0;
        }
      }
    }
  }
`;

export const SBtns = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column-reverse;
  gap: 12px;
  width: 100%;
  grid-area: btns;

  @media ${device.tabletPlus} {
    flex-direction: row;
  }

  @media ${device.desktopPlus} {
    gap: 16px;
    flex: 1 1 auto;
    width: inherit;
    flex-direction: inherit;

    & .btns__filterSearch {
      flex: 0 1 300px;
    }

    & .btns__filter {
      margin: 0 !important;
    }

    & .MuiButton-root {
      white-space: nowrap;
      flex: 0 0 auto;
      height: 32px;
    }
  }
`;

export const SArticle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "title filters"
    "btns btns";
  gap: 24px;

  @media ${device.desktopPlus} {
    grid-template-columns: auto 1fr 120px;
    grid-template-areas: "title btns filters";
    grid-template-rows: 1fr;
  }
`;

export const STitle = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  grid-area: title;

  & > button {
    position: fixed;
    right: 20px;
    bottom: 48px;
    z-index: 10;

    @media ${device.tabletPlus} {
      position: relative;
      right: inherit;
      bottom: inherit;
    }
  }
`;

export const SItemPage = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 100%;
  padding: 28px 20px 64px 20px;
  background-color: #ffffff;
`;

export const SGroup = styled.div`
  min-width: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  grid-area: filters;
`;

export const SDash = styled.form`
  padding: 0 8px;
`;

export const SPos = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & > .MuiTypography-root {
    margin-right: 16px;
  }

  & > div {
    flex: 1;
    width: 100%;

    @media ${device.desktopPlus} {
      width: 160px;
    }
  }
`;
