import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CheckBorder = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 16 16"
      width="16"
      height="16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.7812 6.94637C11.0741 6.65348 11.0741 6.17861 10.7812 5.88571C10.4883 5.5928 10.0134 5.59278 9.72053 5.88566L7.33331 8.2727L6.28225 7.22163C5.98936 6.92874 5.51449 6.92874 5.22159 7.22163C4.9287 7.51452 4.9287 7.9894 5.22159 8.28229L6.80296 9.86367C7.09584 10.1566 7.5707 10.1566 7.8636 9.86369L10.7812 6.94637Z"
        fill="#12B23F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.05404 6.61522C2.18383 4.87122 2.24873 3.99923 3.12398 3.12398C3.99923 2.24873 4.87122 2.18383 6.61522 2.05404C7.0673 2.02039 7.53365 2 8 2C8.46635 2 8.9327 2.02039 9.38479 2.05404C11.1288 2.18383 12.0008 2.24873 12.876 3.12398C13.7513 3.99923 13.8162 4.87122 13.946 6.61522C13.9796 7.0673 14 7.53365 14 8C14 8.46635 13.9796 8.9327 13.946 9.38479C13.8162 11.1288 13.7513 12.0008 12.876 12.876C12.0008 13.7513 11.1288 13.8162 9.38478 13.946C8.9327 13.9796 8.46635 14 8 14C7.53365 14 7.0673 13.9796 6.61522 13.946C4.87122 13.8162 3.99923 13.7513 3.12398 12.876C2.24873 12.0008 2.18383 11.1288 2.05404 9.38478C2.02039 8.9327 2 8.46635 2 8C2 7.53365 2.02039 7.0673 2.05404 6.61522ZM6.72655 3.5499C7.1479 3.51854 7.57663 3.5 8 3.5C8.42337 3.5 8.8521 3.51854 9.27346 3.5499C11.1182 3.6872 11.3951 3.7644 11.8154 4.18464C12.2356 4.60488 12.3128 4.88177 12.4501 6.72655C12.4815 7.1479 12.5 7.57663 12.5 8C12.5 8.42337 12.4815 8.8521 12.4501 9.27346C12.3128 11.1182 12.2356 11.3951 11.8154 11.8154C11.3951 12.2356 11.1182 12.3128 9.27346 12.4501C8.8521 12.4815 8.42337 12.5 8 12.5C7.57663 12.5 7.1479 12.4815 6.72654 12.4501C4.88177 12.3128 4.60488 12.2356 4.18464 11.8154C3.7644 11.3951 3.6872 11.1182 3.5499 9.27346C3.51854 8.8521 3.5 8.42337 3.5 8C3.5 7.57663 3.51854 7.1479 3.5499 6.72654C3.6872 4.88177 3.7644 4.60488 4.18464 4.18464C4.60488 3.7644 4.88177 3.6872 6.72655 3.5499Z"
        fill="#12B23F"
      />
    </SvgIcon>
  );
};

export default CheckBorder;
