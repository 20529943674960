export const devicesType = ["Однофазный", "Трехфазный"];
export const servicesType = ["Лицензия", "по операторской модели", "по абонентской модели"];
export const categoryWithType = ["Счетчик учета электроэнергии", "Услуги и лицензии"];

export enum EDevicesCost {
  "Фиксированная" = "Фиксированная",
  "Ежемесячная" = "Ежемесячная",
  "По запросу" = "По запросу",
}

export enum EDevicesCategory {
  "Счетчик учета электроэнергии" = "Счетчик учета электроэнергии",
  "Модем" = "Модем",
  "Услуги и лицензии" = "Услуги и лицензии",
  "Специальные предложения" = "Специальные предложения",
}
export const makeListParams = (initial) => Object.values(initial) ?? [];

export const rendSuffixPrice = (type) => {
  return type === EDevicesCost["Ежемесячная"] ? " ₽/месяц" : " ₽";
};
