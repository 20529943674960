export enum CheckboxSize {
  SMALL_16 = "small",
  MEDIUM_24 = "medium",
  LARGE_32 = "large",
}

export type BoxSizes =
  | CheckboxSize.SMALL_16
  | CheckboxSize.MEDIUM_24
  | CheckboxSize.LARGE_32;
