import rgba from "polished/lib/color/rgba";
import { ru } from "date-fns/locale";

import { IServChartData } from "interface/askue";
import { declWords } from "utils/declensionWords";
import theme from "theme";

export const makeOptions = () => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    clip: false,
    hover: {
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        type: "time",
        time: {
          displayFormats: {
            hour: "HH mm",
          },
        },
        ticks: {
          stepSize: 1,
          autoSkip: true,
          source: "data",
        },
        adapters: {
          date: {
            locale: ru,
          },
        },
      },
      y: {
        stacked: true,
        display: false,
        title: {
          display: false,
        },
      },
    },
  };
};

export const makeChartData = (rawData: IServChartData[]) => {
  const labels = rawData.map((a) => a.time);
  const posData = rawData.map((a) => (a.value ? 4 : undefined));
  const negData = rawData.map((a) => (!a.value ? 1 : undefined));

  return {
    labels,
    datasets: [
      {
        type: "bar",
        id: 1,
        data: posData,
        backgroundColor: rgba(theme.mtsColor.text.positive.lightMode, 0.2),
        borderColor: theme.mtsColor.text.positive.lightMode,
        borderWidth: { top: 1 },
        barPercentage: 1,
        categoryPercentage: 1,
      },
      {
        type: "bar",
        id: 2,
        data: negData,
        backgroundColor: rgba(theme.mtsColor.base.critical, 0.2),
        borderColor: theme.mtsColor.base.critical,
        borderWidth: { top: 1 },
        barPercentage: 1,
        categoryPercentage: 1,
      },
    ],
  };
};

export const calcWorktime = (val: number | undefined | null) => {
  return !val
    ? "Сбой в работе"
    : val <= 24
    ? declWords(val, ["час", "часа", "часов"])
    : declWords(Math.floor(val / 24), ["день", "дня", "дней"]);
};
