import { isEmpty } from "lodash";

import { TENANT_DEPLOY_STATUS } from "components/card-info/tenant/const";
import { IMenegementTenantResponse, ITenantDeployStep } from "interface/management";
import { IBreadcrumbsLink } from "interface/common";
import { SERVICES } from "stream-constants/keys-and-constants";
import { IFilter } from "components/table/table-filter/table-filter";
import { ROUTE_MANAGEMENT, ROUTE_TENANT_PAGE } from "stream-constants/route-constants";

export const FILTER_BY_SERVICES = "services";
export const FILTER_BY_DEPLOY = "deploy";

export const links: IBreadcrumbsLink[] = [
  { name: "Управление", href: `/${ROUTE_MANAGEMENT}` },
  { name: "Организации", href: `/${ROUTE_TENANT_PAGE}` },
];

export const HARDCODED_FILTER_ITEMS = {
  checkbox: {
    [FILTER_BY_SERVICES]: [
      {
        id: 0,
        name: SERVICES.LOSSES,
        friendlyName: SERVICES.LOSSES,
      },
      {
        id: 1,
        name: SERVICES.ASKUE,
        friendlyName: SERVICES.ASKUE,
      },
      {
        id: 2,
        name: SERVICES.MINING,
        friendlyName: SERVICES.MINING,
      },
    ],
    [FILTER_BY_DEPLOY]: [
      {
        id: 0,
        name: "active",
        friendlyName: "Завершено",
      },
      {
        id: 1,
        name: "inprogress",
        friendlyName: "В процессе",
      },
      {
        id: 2,
        name: "error",
        friendlyName: "Ошибка",
      },
    ],
  },
  radio: {},
};

const checkIntersection = (firstArr: string[], secondArr: string[]) => {
  return firstArr.some((firstRole) => secondArr.some((secondRole) => secondRole === firstRole));
};

export const calcDeploySteps = (arr: ITenantDeployStep[]) => {
  if (arr.every((a) => a.status === TENANT_DEPLOY_STATUS["Выполнено"])) return "active";
  else if (arr.some((a) => a.status === TENANT_DEPLOY_STATUS["В процессе"])) return "inprogress";
  else return "error";
};

export const filterByService = (item: IMenegementTenantResponse, colFilter?: IFilter) => {
  let currFilt = colFilter?.find((co) => co.column === FILTER_BY_SERVICES)?.value;
  return isEmpty(currFilt)
    ? item
    : checkIntersection(
        item.services.map((c) => c.service_type),
        currFilt ?? [],
      );
};

export const filterByDeploy = (item: IMenegementTenantResponse, colFilter?: IFilter) => {
  let currFilt = colFilter?.find((co) => co.column === FILTER_BY_DEPLOY)?.value;
  return isEmpty(currFilt) ? item : currFilt?.includes(calcDeploySteps(item.deploy_steps));
};

export const filterBySearch = (item: IMenegementTenantResponse, text: string) =>
  text === "" ? item : item.title?.toLowerCase().includes(text.toLocaleLowerCase().trim());
