import styled from "@emotion/styled";

export const SWrap = styled.div`
  max-width: 464px;
  margin: 0 auto;

  & section {
    display: grid;
    align-items: start;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
    column-gap: 16px;

    &.radios {
      align-items: end;
    }

    &.single {
      grid-template-columns: 1fr;
    }

    &:not(:last-of-type) {
      margin-bottom: 24px;
    }
  }
`;

export const SBtns = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;

  & .MuiButton-root {
    white-space: nowrap;
  }
`;

export const STitle = styled.div`
  & > span {
    display: block;
    text-align: left;

    &:not(:last-of-type) {
      margin-bottom: 12px;
    }

    &:nth-of-type(2) {
      color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
    }
  }
`;

export const SChip = styled.span`
  display: inline-block;
  height: auto;
  padding: 4px 8px;
  line-height: 1;
  font-size: 0.8rem;
  font-weight: 400;
  border-radius: 8px;
  background-color: goldenrod;
`;
