import { IBreadcrumbsLink } from "interface/common";
import { SERVICES } from "stream-constants/keys-and-constants";
import { ROUTE_PERSONAL_AREA } from "stream-constants/route-constants";

export const links: IBreadcrumbsLink[] = [{ name: "Главная", href: `/${ROUTE_PERSONAL_AREA}` }];

export const HARDCODED_CARDS = [
  {
    title: "Поиск коммерческих потерь",
    sub: undefined,
    text: "Программная платформа, которая позволит быстро и\u00a0своевременно обнаружить точки коммерческих потерь и\u00a0выявить конкретные участки сети, на\u00a0которых происходит хищение",
    link: "https://energytool.mts.ru/commercial_losses",
    service: SERVICES.LOSSES,
  },
  {
    title: "Энергосфера в\u00a0облаке МТС",
    sub: undefined,
    text: "Облачное решение, которое упрощает сбор показаний и\u00a0значительно уменьшает затраты энергосбытовой компаний при учете потребления электроэнергии",
    link: "https://energytool.mts.ru/askue",
    service: SERVICES.ASKUE,
  },
  {
    title: "Выявление майнинга",
    sub: undefined,
    text: "Наше решение поможет своевременно обнаружить подозрительную активность потенциальных майнинговых ферм с\u00a0указанием их\u00a0расположения",
    link: "https://energytool.mts.ru/mining",
    service: SERVICES.MINING,
  },
];
