import { useRef } from "react";

import { MTSButton } from "components/mts-button/mts-button";
import Icon from "components/icons";
import { Spacer } from "components/spacer/spacer.component";
import { Attached } from "./attached.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SInp, SRow } from "./styles";

interface IAttachBtn {
  textBtn: string;
  onFileAttach: (a: FormData) => void;
}

export const AttachToFormBtn: React.FC<IAttachBtn> = ({ textBtn, onFileAttach }) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleUpload = (e) => {
    const fileUploaded = e.target.files[0];
    let formData = new FormData();
    formData.append("file", fileUploaded);
    onFileAttach(formData);
  };
  const path = hiddenFileInput?.current?.value.split("\\");

  return (
    <form encType="multipart/form-data">
      <SInp type="file" onChange={handleUpload} ref={hiddenFileInput} accept=".xlsx" />
      <MTSButton
        size="S"
        type="button"
        variant="secondary"
        onClick={handleClick}
        icon={<Icon.Plus sx={{ width: 16, height: 16 }} />}
      >
        {textBtn}
      </MTSButton>
      {hiddenFileInput?.current?.value && path ? (
        <>
          <Spacer value="24px" />
          <SRow>
            <Attached />
            <CusTypo variant="p4_regular">{path?.[path?.length - 1]}</CusTypo>
          </SRow>
        </>
      ) : null}
    </form>
  );
};
