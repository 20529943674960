import { useContext } from "react";
import theme from "theme";
import { Controller, useFormContext } from "react-hook-form";

import { Spacer } from "components/spacer/spacer.component";
import { ContextSettings } from "context/context-settings";
import Icon from "components/icons";
import { CustomWidthTooltip } from "components/tooltip/tooltip";
import { getPeriod } from "../../chart/utils";
import {
  min_green_period,
  min_green_quantity,
  min_yellow_period,
  min_yellow_quantity,
  tooltipPeriod,
  tooltipQuantity,
} from "./validation-schema";
import { MTSButton } from "components/mts-button/mts-button";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SCard, SRow } from "./styles";

const commonStyles = {
  width: 18,
  height: 18,
  margin: "0 0 0 12px",
  alignSelf: "end",
};

export const Preview = ({ onSubmit }) => {
  const { profile } = useContext(ContextSettings);
  const { formState, handleSubmit } = useFormContext();

  return (
    <SCard>
      <CusTypo variant="h4_medium">Предварительный просмотр</CusTypo>
      <Spacer value="34px" />
      {profile?.tenant?.title ? (
        <>
          <CusTypo variant="p4_regular">Наименование организации</CusTypo>
          <Spacer value="9px" />
          <CusTypo variant="p3_regular">{profile.tenant.title}</CusTypo>
          <Spacer value="31px" />
        </>
      ) : null}
      <CusTypo variant="p4_regular">Период данных</CusTypo>
      <Spacer value="9px" />
      <Controller
        render={({ field: { value }, fieldState: { error } }) => (
          <SRow>
            <CusTypo variant="p3_regular">{value ? getPeriod(value) : "-"}</CusTypo>
            {value != null ? (
              <CustomWidthTooltip
                title={<div style={{ fontWeight: 400 }}>{tooltipPeriod}</div>}
                arrow
                placement="top"
              >
                <span>
                  {error ? (
                    <Icon.ErrorShaped sx={{ ...commonStyles, height: 19 }} />
                  ) : value >= min_yellow_period && value <= min_green_period ? (
                    <Icon.Warn sx={{ ...commonStyles }} />
                  ) : (
                    <Icon.CheckShaped sx={{ ...commonStyles }} />
                  )}
                </span>
              </CustomWidthTooltip>
            ) : null}
          </SRow>
        )}
        name="uploaded_period"
      />
      <Spacer value="31px" />
      <CusTypo variant="p4_regular">Количество устройств</CusTypo>
      <Spacer value="9px" />
      <Controller
        name="uploaded_quantity"
        render={({ field: { value }, fieldState: { error } }) => (
          <>
            <SRow>
              <CusTypo variant="p3_regular">{value ?? "-"}</CusTypo>
              {value != null ? (
                <CustomWidthTooltip
                  title={<div style={{ fontWeight: 400 }}>{tooltipQuantity}</div>}
                  arrow
                  placement="top"
                >
                  <span>
                    {error ? (
                      <Icon.ErrorShaped sx={{ ...commonStyles, height: 19 }} />
                    ) : value >= min_yellow_quantity && value <= min_green_quantity ? (
                      <Icon.Warn sx={{ ...commonStyles }} />
                    ) : (
                      <Icon.CheckShaped sx={{ ...commonStyles }} />
                    )}
                  </span>
                </CustomWidthTooltip>
              ) : null}
            </SRow>
            {error?.message ? (
              <CusTypo
                variant="c1_regular"
                styles={{ color: theme.mtsColor.text.negative.lightMode }}
              >
                {error.message}
              </CusTypo>
            ) : null}
          </>
        )}
      />
      <Spacer value="48px" />
      <MTSButton
        size="M"
        variant="primary"
        onClick={handleSubmit(onSubmit, undefined)}
        className="btn-submit"
        disabled={!formState.isValid}
      >
        Отправить на модерацию
      </MTSButton>
    </SCard>
  );
};
