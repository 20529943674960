import * as yup from "yup";

const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

export const schema = yup.object().shape({
  service_code: yup
    .string()
    .required("Поле не заполнено")
    .test("correct_service", "Первые 2 символа - буквы, остальные - цифры", (value?: string) =>
      /^([a-zA-Zа-яА-Я]{2}\d{5})$/.test(value ?? ""),
    ),
  contract_number: yup
    .number()
    .required("Поле не заполнено")
    .test("correct_length", "Должно содержать 11 символов", (value?: any) =>
      value ? value?.toString().length === 11 : false,
    ),
  account_number: yup
    .number()
    .required("Поле не заполнено")
    .test("correct_length", "Должно содержать 12 символов", (value?: any) =>
      value ? value?.toString().length === 12 : false,
    ),
  price: yup
    .number()
    .test("is-decimal", "Разрешены только сотые доли суммы", (val: any) => {
      if (val != undefined) {
        return patternTwoDigisAfterComma.test(val);
      }
      return true;
    })
    .min(1, "Цена не может быть 0")
    .required("Поле не заполнено"),
  comment: yup.string().notRequired().optional(),
});
