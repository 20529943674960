import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Layer = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.3661 3.03253C12.0556 2.92713 12.7777 3.08086 13.3692 3.44999C16.0355 5.04849 18.7013 6.64791 21.3667 8.24824C21.6456 8.40703 21.8818 8.64821 22.0102 8.94462C22.2297 9.42882 22.1602 10.0304 21.8302 10.4483C21.6443 10.6996 21.364 10.8483 21.1016 11.0052C18.5117 12.5591 15.9218 14.112 13.3319 15.6654C12.4252 16.2251 11.2151 16.2329 10.3024 15.6824C7.59655 14.0618 4.89342 12.4366 2.18799 10.8151C1.76915 10.5689 1.4847 10.1082 1.46721 9.62075C1.43269 9.08684 1.72036 8.55202 2.18154 8.28276C4.87547 6.66678 7.56893 5.04987 10.2629 3.43434C10.6002 3.23044 10.9758 3.0896 11.3661 3.03253ZM11.2925 5.1074C8.82638 6.58945 6.35752 8.06644 3.89188 9.54849C6.36258 11.025 8.82914 12.5089 11.2975 13.9896C11.6105 14.181 12.0261 14.1705 12.334 13.9735C14.7937 12.4997 17.251 11.0223 19.7107 9.54849C17.2566 8.06966 14.7969 6.59866 12.3405 5.12351C12.0303 4.92192 11.6078 4.90995 11.2925 5.1074Z"
        fill="black"
      />
      <path
        d="M1.69229 13.5187C1.96154 13.4322 2.26394 13.475 2.50281 13.6232C5.30214 15.3013 8.10147 16.9804 10.8999 18.6599C11.1834 18.82 11.4572 19.0516 11.8024 19.0378C12.0961 19.0479 12.3456 18.8799 12.5844 18.7331C15.422 17.0305 18.2595 15.3276 21.0975 13.625C21.3626 13.4566 21.7087 13.4262 21.9982 13.5505C22.2725 13.6623 22.4875 13.9063 22.5653 14.1921C22.6559 14.5074 22.5731 14.8659 22.3526 15.1089C22.2205 15.2585 22.0396 15.3492 21.8726 15.4523C19.0244 17.1594 16.1782 18.8707 13.3291 20.5764C12.4252 21.132 11.2211 21.1435 10.3107 20.5967C7.36688 18.8329 4.42534 17.066 1.48287 15.3009C1.1616 15.1145 0.966913 14.7361 1.00465 14.3665C1.03641 13.9794 1.31994 13.6283 1.69229 13.5187Z"
        fill="black"
      />
    </SvgIcon>
  );
};

export default Layer;
