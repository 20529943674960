interface IResulatForm {
  id_pp: string;
  telebot_request_dt: Date;
  telebot_request_result: string;
  phase_num?: string;
  phase_meter_A?: string;
  phase_clamp_A?: string;
  phase_meter_B?: string;
  phase_clamp_B?: string;
  phase_meter_C?: string;
  phase_clamp_C?: string;
  telebot_request_comment?: string;
  gaz_flg: boolean;
}

export const defaultValues: IResulatForm = {
  id_pp: "",
  telebot_request_dt: new Date(),
  telebot_request_result: "",
  phase_num: undefined,
  phase_meter_A: undefined,
  phase_clamp_A: undefined,
  phase_meter_B: undefined,
  phase_clamp_B: undefined,
  phase_meter_C: undefined,
  phase_clamp_C: undefined,
  telebot_request_comment: "",
  gaz_flg: false,
};

export const checkEnabled = (a) => a?.length > 5;
