export const mock_management_tenant = [
  {
    appl_id: 1,
    comment: "В норме",
    created_time: "2023-09-06T14:12:37.307274",
    status: "Принята",
    tenant_name: "Башкир",
  },
  {
    appl_id: 2,
    comment: "В норме",
    created_time: "2023-09-07T12:07:53.822115",
    status: "Принята",
    tenant_name: "Башкир",
  },
  {
    appl_id: 3,
    comment: "В норме",
    created_time: "2023-09-07T12:07:53.824503",
    status: "Принята",
    tenant_name: "Башкир",
  },
  {
    appl_id: 4,
    comment: "В норме",
    created_time: "2023-09-07T12:07:53.826118",
    status: "Принята",
    tenant_name: "Башкир",
  },
  {
    appl_id: 5,
    comment: "asd",
    created_time: "2023-09-07T13:28:05.391816",
    status: "В работе",
    tenant_name: 'ООО "Башкирэнерго"',
  },
  {
    appl_id: 6,
    comment: "В раздумии",
    created_time: "2023-09-07T13:52:57.164767",
    status: "В работе",
    tenant_name: 'ООО "Башкирэнерго"',
  },
  {
    appl_id: 7,
    comment: "В раздумии",
    created_time: "2023-09-08T09:45:14.848543",
    status: "В работе",
    tenant_name: 'ООО "Башкирэнерго"',
  },
  {
    appl_id: 8,
    comment: "В раздумии",
    created_time: "2023-09-08T09:49:07.842119",
    status: "В работе",
    tenant_name: 'ООО "Башкирэнерго"',
  },
];
