import { Fragment } from "react";
import format from "date-fns/format";
import { sortBy } from "lodash";

import { IMiningInfoResponse } from "interface/map";
import { Spacer } from "components/spacer/spacer.component";
import { StatBubble } from "components/demo/demoTable/subComponents";
import { MINING_DOWN } from "services/urls";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SBar, SBarBack, SBarGrid, SBarHead, SDevicesGrid, SDown, SItem } from "./styles";

const fraudByCount = {
  count_black: "Крайне высокая",
  count_red: "Высокая",
  count_lightred: "Средняя",
  count_beige: "Низкая",
};

const priority = {
  count_black: 1,
  count_red: 2,
  count_lightred: 3,
  count_beige: 4,
};

interface IProp {
  data: IMiningInfoResponse;
}

export const MiningSummary: React.FC<IProp> = ({ data }) => {
  const maximum = Object.values(data.miners[0]).sort((a, b) => b - a)[0];

  return (
    <SDevicesGrid>
      <SItem className="item total">
        <CusTypo variant="h4_regular">Всего обнаружено</CusTypo>
        <CusTypo variant="h2_medium" font="wide">
          {data.count}
        </CusTypo>
      </SItem>
      <SItem className="item time">
        <CusTypo variant="h4_regular">Дата актуального отчета</CusTypo>
        <CusTypo variant="p1_medium" font="wide">
          {format(new Date(data.time_schema ?? 0), "dd.MM.yyyy")}
        </CusTypo>
        <SDown href={`${MINING_DOWN}/${data.file}`}>Скачать файл</SDown>
      </SItem>
      <SItem className="chart">
        <CusTypo variant="h4_regular">Майнеры</CusTypo>
        <Spacer value="19px" />
        <SBarHead>
          <div>Частота активности</div>
          <div>Количество</div>
        </SBarHead>
        <Spacer value="16px" />
        <SBarGrid>
          {sortBy(Object.entries(data.miners[0]), [([key, val]) => priority[key]]).map(
            ([name, count]) => (
              <Fragment key={`${name}_${count}`}>
                <div>
                  <StatBubble value={fraudByCount[name]} size="s" />
                </div>
                <SBar>
                  <SBarBack width={(count * 100) / maximum!} />
                </SBar>
                <div>{count}</div>
              </Fragment>
            ),
          )}
        </SBarGrid>
      </SItem>
    </SDevicesGrid>
  );
};
