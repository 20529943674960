import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Home = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.7919 3.0077C12.4077 2.96195 13.0365 3.12095 13.5536 3.45957C15.6079 4.77954 17.6628 6.09877 19.7155 7.42137C20.5004 7.92686 20.9991 8.8396 20.9988 9.7737C21.001 12.4242 20.9995 15.075 20.9995 17.7254C21.0014 18.3393 20.7974 18.9509 20.4258 19.4399C19.9072 20.1366 19.0559 20.5705 18.1863 20.5731C14.2121 20.575 10.2383 20.5735 6.26416 20.5739C5.97466 20.572 5.68292 20.5859 5.39605 20.5379C4.68656 20.4273 4.02807 20.0316 3.59158 19.4624C3.20834 18.9697 2.99759 18.3472 3.00022 17.7232C3.00059 15.0735 2.99909 12.4242 3.00097 9.77445C2.99947 8.84034 3.49896 7.92724 4.28457 7.4225C6.3384 6.10064 8.39337 4.78029 10.4483 3.45957C10.8488 3.19895 11.3157 3.04258 11.7919 3.0077ZM11.8313 4.20768C11.5759 4.23355 11.3277 4.3213 11.1113 4.4593C9.11785 5.7414 7.12364 7.02313 5.1298 8.30486C4.97868 8.4001 4.82531 8.49535 4.69743 8.62135C4.37757 8.92772 4.19532 9.36871 4.20019 9.8112C4.20019 12.4369 4.20019 15.0626 4.20019 17.6883C4.19194 18.0967 4.33782 18.5065 4.61006 18.8122C4.91756 19.1669 5.38217 19.3765 5.85166 19.3731C9.93871 19.3746 14.0261 19.3735 18.1132 19.3739C18.5576 19.3833 19.0034 19.2089 19.3158 18.8917C19.6244 18.5872 19.801 18.1567 19.7992 17.7232C19.801 15.174 19.7992 12.6244 19.7999 10.0748C19.7995 9.87045 19.8082 9.66383 19.765 9.46283C19.6762 9.01997 19.3915 8.6251 19.0064 8.3911C16.9747 7.085 14.9434 5.77853 12.9117 4.4728C12.5955 4.2643 12.2082 4.16793 11.8313 4.20768Z"
        fill="#FF0032"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1865 20.7731C15.6322 20.7743 13.0781 20.7741 10.524 20.7739C9.10405 20.7738 7.68414 20.7737 6.26422 20.7739H6.26291C6.20907 20.7735 6.15358 20.7738 6.09684 20.7741C5.86447 20.7752 5.61114 20.7765 5.36419 20.7353C4.60377 20.6164 3.90044 20.1935 3.43337 19.5847C3.0228 19.0566 2.79755 18.3908 2.80027 17.7227C2.8004 16.7767 2.80029 15.8306 2.80019 14.8845C2.8 13.1811 2.79981 11.4777 2.80101 9.7743C2.79957 8.77192 3.33387 7.7956 4.17651 7.25422C5.93362 6.12334 7.69156 4.99356 9.44956 3.86374C9.74629 3.67303 10.043 3.48233 10.3398 3.29162C10.7686 3.01265 11.2678 2.84556 11.7773 2.80823C12.4358 2.75932 13.1082 2.92898 13.6625 3.29175C14.0804 3.56027 14.4983 3.82877 14.9163 4.09728C16.5525 5.14846 18.1889 6.19978 19.8239 7.25323C20.6658 7.79545 21.1992 8.77139 21.1988 9.7736C21.2004 11.6667 21.2001 13.5601 21.1998 15.4533C21.1997 16.2105 21.1996 16.9677 21.1996 17.7248C21.2016 18.382 20.9836 19.0361 20.5857 19.5601C20.0298 20.3064 19.1194 20.7703 18.1865 20.7731ZM11.1114 4.45929C11.3277 4.32129 11.576 4.23354 11.8314 4.20767C12.2082 4.16792 12.5956 4.26429 12.9117 4.47279C14.1647 5.27804 15.4175 6.08358 16.6704 6.88915C17.4491 7.38981 18.2277 7.89048 19.0065 8.39109C19.3916 8.62509 19.6762 9.01995 19.7651 9.46282C19.8018 9.63406 19.801 9.80938 19.8002 9.98392C19.8 10.0142 19.7999 10.0446 19.8 10.0748C19.7996 11.1246 19.7998 12.1743 19.7999 13.224C19.8001 14.7238 19.8003 16.2236 19.7992 17.7232C19.8011 18.1567 19.6245 18.5872 19.3158 18.8916C19.0035 19.2089 18.5576 19.3833 18.1132 19.3739C16.6281 19.3737 15.1429 19.3738 13.6576 19.3739C11.0556 19.374 8.4536 19.3741 5.85171 19.3731C5.38222 19.3765 4.9176 19.1669 4.61011 18.8121C4.33787 18.5065 4.19199 18.0967 4.20024 17.6883V9.81119C4.19537 9.3687 4.37761 8.9277 4.69748 8.62134C4.8216 8.49904 4.96973 8.40572 5.11652 8.31324L5.12985 8.30484C5.56734 8.0236 6.00485 7.74237 6.44236 7.46113C7.9988 6.46062 9.55531 5.46007 11.1114 4.45929Z"
        fill="#FF0032"
      />
      <path
        d="M9.49616 16.3837C9.6304 16.366 9.76578 16.3754 9.90077 16.3739C11.3887 16.3735 12.8767 16.3743 14.3647 16.3735C14.5608 16.3642 14.7581 16.4523 14.8754 16.6109C15.0457 16.8228 15.0393 17.1524 14.8597 17.3568C14.7404 17.5038 14.5507 17.5825 14.3621 17.5743C12.7871 17.5735 11.2117 17.5739 9.63678 17.5743C9.44891 17.5833 9.26141 17.5023 9.14104 17.3579C8.96667 17.1606 8.95429 16.8457 9.10691 16.6323C9.19691 16.5022 9.33978 16.4088 9.49616 16.3837Z"
        fill="#FF0032"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.47015 16.1853C9.58118 16.1707 9.70075 16.1722 9.80132 16.1734C9.83645 16.1739 9.86927 16.1743 9.89859 16.1739H9.90076C10.5386 16.1738 11.1763 16.1738 11.814 16.1739C12.6627 16.1739 13.5114 16.174 14.3602 16.1735C14.6165 16.1629 14.8754 16.277 15.0339 16.4888C15.2613 16.775 15.2532 17.2089 15.0126 17.4858C14.8514 17.6824 14.6024 17.7836 14.358 17.7743C12.7859 17.7735 11.2134 17.7739 9.64128 17.7743C9.39449 17.7845 9.14943 17.6788 8.98931 17.4882C8.75387 17.2197 8.7394 16.8037 8.94336 16.5172C9.06296 16.3451 9.25234 16.2203 9.46446 16.1862L9.47015 16.1853ZM9.10695 16.6323C8.95433 16.8457 8.9667 17.1607 9.14107 17.3579C9.26145 17.5023 9.44894 17.5833 9.63681 17.5743C11.2118 17.5739 12.7871 17.5735 14.3621 17.5743C14.5507 17.5825 14.7405 17.5038 14.8597 17.3568C15.0393 17.1524 15.0457 16.8228 14.8755 16.6109C14.7581 16.4523 14.5608 16.3642 14.3647 16.3735C13.5144 16.374 12.6641 16.3739 11.8138 16.3739C11.1762 16.3738 10.5385 16.3738 9.90081 16.3739C9.86205 16.3744 9.82325 16.3739 9.78446 16.3734C9.68816 16.3723 9.59189 16.3711 9.49619 16.3837C9.33982 16.4088 9.19695 16.5022 9.10695 16.6323Z"
        fill="#FF0032"
      />
    </SvgIcon>
  );
};

export default Home;
