import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Icon from "components/icons";

import getSpinnerColor from "./utils/get-spinner-color";
import getSpinnerSize from "./utils/get-spinner-size";

const useStyles = makeStyles((theme: Theme) => ({
  rotateIcon: {
    animation: "spin 1s linear infinite",
  },
}));

interface ISpinnerProps {
  size?: "S_16" | "M_24" | "L_40";
  color?: "white" | "black" | "orange" | "red";
}

export const Spinner = (props: ISpinnerProps): JSX.Element => {
  const { size = "M_24", color = "red" } = props;
  const classes = useStyles();

  return (
    <Icon.Spinner
      className={classes.rotateIcon}
      sx={{
        fontSize: getSpinnerSize(size),
        color: getSpinnerColor(color),
        "@keyframes spin": {
          from: { transform: "rotate(0deg)" },
          to: { transform: "rotate(360deg)" },
        },
      }}
    />
  );
};
