import { useContext, useMemo } from "react";

import { ContextSettings } from "context/context-settings";
import { Table } from "components/table/table.component";
import { IAskuePort } from "interface/askue";
import { constructPortColumns, portsColumns } from "./utils";

interface IProp {
  rawData: IAskuePort[];
}
export const PortsTable: React.FC<IProp> = ({ rawData }) => {
  const { isMobile } = useContext(ContextSettings);
  const tableData = useMemo(() => rawData, [rawData]);
  const tableColumns = useMemo(() => constructPortColumns(portsColumns), []);

  return <Table columns={tableColumns} data={tableData} height={isMobile() ? 124 : 376} />;
};
