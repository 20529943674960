import { FC, useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { yupResolver } from "@hookform/resolvers/yup";
import { MTSInput } from "components/mts-input";
import { MTSButton } from "components/mts-button/mts-button";
import { useUpdateProdCard } from "hooks/api/management";
import { MTSAutocomplete } from "components/mts-autocomplete/mts-autocomplete";
import { ModalNotifyBody } from "components/modal-notify-body/modal-notify-body";
import { FALLBACK_MSG, makeDefaultForEdit, schema } from "./validation-schema";
import { IShopProduct } from "interface/management";
import { Spacer } from "components/spacer/spacer.component";
import { SModalTitle } from "components/modal-notify-body/styles";
import { SBody, SBtns, SDesc, SForm, SFormField, SPrice } from "./styles";
import {
  EDevicesCategory,
  EDevicesCost,
  devicesType,
  makeListParams,
  rendSuffixPrice,
  servicesType,
} from "./const";

type IProps = {
  onCancel: Function;
  prodData: IShopProduct;
  setIsNotifyModal: (a) => void;
};

export const FormEditProduct: FC<IProps> = ({ onCancel, prodData, setIsNotifyModal }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    getValues,
    setValue,
  } = useForm<any>({
    defaultValues: makeDefaultForEdit(prodData),
    resolver: yupResolver(schema),
    mode: "onTouched",
    shouldFocusError: false,
    reValidateMode: "onChange",
  });

  const [watchPriceType] = useWatch({ control, name: ["price_type"] });
  const [watchCategory] = useWatch({ control, name: ["category"] });
  const { mutate, isSuccess, isError } = useUpdateProdCard();

  const onSubmit = (submData) => {
    const type = submData.type instanceof Array ? submData.type[0] : submData.type;

    mutate({
      id: prodData.id,
      category: submData.category,
      type: type,
      name: submData.name,
      price:
        submData.price_type !== "По запросу"
          ? Number(String(submData.price).replace(/\s+/g, ""))
          : -1,
      price_type: submData.price_type,
      description: submData.description,
    });
  };

  useEffect(() => {
    reset(makeDefaultForEdit(prodData as IShopProduct));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, prodData]);

  useEffect(() => {
    prodData.category !== watchCategory ? setValue("type", []) : setValue("type", [prodData.type]);
  }, [getValues, prodData, setValue, watchCategory]);

  useEffect(() => {
    if (isSuccess || isError) setIsNotifyModal(true);
  }, [isSuccess, isError, setIsNotifyModal]);

  if (isSuccess) {
    return <ModalNotifyBody title="Редактирование успешно" isSuccess />;
  } else if (isError) {
    return (
      <ModalNotifyBody title="Редактирование не удалось" isError textDescrioption={FALLBACK_MSG} />
    );
  } else {
    return (
      <>
        <SModalTitle>Редактировать товар</SModalTitle>
        <SForm onSubmit={handleSubmit(onSubmit)}>
          <SBody>
            <div>
              <SFormField>
                {getValues("category") ? (
                  <MTSAutocomplete
                    label="Категория"
                    placeholder=""
                    options={makeListParams(EDevicesCategory)}
                    control={control}
                    size="M"
                    {...register("category", {
                      required: true,
                    })}
                    errorMessage={errors?.category?.message}
                  />
                ) : null}
              </SFormField>
              <SFormField>
                <MTSAutocomplete
                  label="Тип"
                  placeholder=""
                  options={
                    watchCategory === EDevicesCategory["Услуги и лицензии"]
                      ? servicesType
                      : watchCategory === EDevicesCategory["Счетчик учета электроэнергии"]
                      ? devicesType
                      : []
                  }
                  control={control}
                  size="M"
                  {...register("type")}
                  disabled={
                    !(
                      watchCategory === EDevicesCategory["Услуги и лицензии"] ||
                      watchCategory === EDevicesCategory["Счетчик учета электроэнергии"]
                    )
                  }
                  errorMessage={errors?.type?.message}
                />
              </SFormField>
              <SFormField>
                <MTSInput
                  label="Название"
                  control={control}
                  {...register("name", {
                    required: true,
                  })}
                  errorMessage={errors?.name?.message}
                />
              </SFormField>
              <SFormField>
                {getValues("price_type") ? (
                  <MTSAutocomplete
                    label="Цена"
                    placeholder=""
                    options={makeListParams(EDevicesCost)}
                    control={control}
                    size="M"
                    {...register("price_type", {
                      required: true,
                    })}
                    errorMessage={errors?.price_type?.message}
                  />
                ) : null}
              </SFormField>
              <SPrice>
                {watchPriceType !== "По запросу" ? (
                  <SFormField>
                    <Controller
                      name="price"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <NumericFormat
                          customInput={MTSInput}
                          suffix={rendSuffixPrice(watchPriceType)}
                          placeholder={`0 ${rendSuffixPrice(watchPriceType)}`}
                          onValueChange={(v) => {
                            onChange(Number(v.value));
                          }}
                          thousandSeparator={" "}
                          value={value}
                          allowNegative={false}
                        />
                      )}
                    />
                  </SFormField>
                ) : null}
              </SPrice>
            </div>
            <SDesc>
              <SFormField>
                <MTSInput
                  placeholder="Описание товара"
                  type="textarea"
                  label="Описание"
                  {...register("description", {
                    required: false,
                  })}
                  control={control}
                  errorMessage={errors?.description?.message}
                />
              </SFormField>
            </SDesc>
          </SBody>

          <Spacer value="24px" />
          <SBtns>
            <MTSButton size="M" variant="secondary" onClick={() => onCancel()}>
              Отмена
            </MTSButton>
            <MTSButton
              size="M"
              variant="primary"
              type="submit"
              onClick={() => undefined}
              disabled={!isDirty}
            >
              Сохранить
            </MTSButton>
          </SBtns>
        </SForm>
      </>
    );
  }
};
