import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const LogOut = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.3552 20.9641C11.2649 20.9907 11.17 20.9987 11.0759 21C9.27672 20.9992 7.47751 21.0008 5.67789 20.9992C4.67936 21.003 3.71838 20.3921 3.28556 19.4944C3.09614 19.1215 3.0046 18.7026 3.00503 18.285C3.0046 14.0947 3.0046 9.90441 3.00503 5.71372C3.00081 5.09064 3.22017 4.4701 3.61882 3.99045C4.11408 3.37876 4.88734 3.00374 5.67536 3.00163C7.47541 2.99952 9.27503 3.00121 11.0751 3.00036C11.3543 2.99235 11.6332 3.11848 11.8066 3.33827C12.0196 3.59728 12.0639 3.97906 11.9201 4.28153C11.78 4.58822 11.456 4.79999 11.1181 4.79957C9.30414 4.80294 7.48975 4.79788 5.67578 4.80209C5.20119 4.80463 4.78862 5.23829 4.80507 5.71161C4.80465 9.90399 4.80465 14.0964 4.80507 18.2887C4.78862 18.7616 5.19951 19.1957 5.67409 19.1983C7.48848 19.2025 9.30372 19.1974 11.1181 19.2008C11.3936 19.2025 11.6615 19.3409 11.8251 19.5615C12.0019 19.7931 12.0512 20.1137 11.9568 20.3887C11.8656 20.6634 11.6336 20.8853 11.3552 20.9641Z"/>
      <path d="M17.1297 16.2447C16.9074 16.4721 16.5602 16.5658 16.2543 16.4784C15.9536 16.3987 15.7051 16.1511 15.6249 15.8507C15.5363 15.5432 15.6313 15.1947 15.8591 14.9712C16.5484 14.2797 17.2394 13.5904 17.9295 12.8999C14.534 12.9003 11.139 12.8999 7.74345 12.9003C7.56965 12.899 7.39036 12.9125 7.22331 12.8551C6.95796 12.7699 6.73902 12.5544 6.6517 12.2894C6.55594 12.0148 6.60656 11.695 6.78205 11.4626C6.9529 11.2293 7.24145 11.0922 7.53 11.1002C10.9959 11.1002 14.4623 11.1002 17.9283 11.1002C17.2394 10.4084 16.5471 9.72035 15.8586 9.02851C15.6642 8.83657 15.5642 8.55393 15.5988 8.28225C15.6304 8.00214 15.8038 7.7427 16.0506 7.6056C16.3117 7.45584 16.6488 7.45162 16.9137 7.59506C17.0483 7.66424 17.152 7.77645 17.2575 7.88234C18.4176 9.04412 19.5798 10.2042 20.7399 11.366C21.0782 11.6896 21.0884 12.2802 20.7593 12.6138C19.5507 13.8254 18.3396 15.0344 17.1297 16.2447Z"/>
    </SvgIcon>
  );
};

export default LogOut;
