import { IBreadcrumbsLink } from "interface/common";
import {
  ANAL_HANDBOOK,
  ANAL_RESEARCH,
  ANAL_STAT,
  ROUTE_ANALYTICS_PAGE,
} from "stream-constants/route-constants";

export const links: IBreadcrumbsLink[] = [
  { name: "Аналитика", href: `/${ROUTE_ANALYTICS_PAGE}` },
  { name: "Приборы учета", href: `/${ANAL_RESEARCH}` },
];
export const handbookLinks: IBreadcrumbsLink[] = [
  { name: "Аналитика", href: `/${ROUTE_ANALYTICS_PAGE}` },
  { name: "Оценки", href: `/${ANAL_HANDBOOK}` },
];
export const statLinks: IBreadcrumbsLink[] = [
  { name: "Аналитика", href: `/${ROUTE_ANALYTICS_PAGE}` },
  { name: "Статистика РЭС", href: `/${ANAL_STAT}` },
];

export const TABLE_RESIZER = "42px";

export type IChartMode = {
  max: number;
  mult: number;
  mode: string;
};

export type IFavs = {
  title: string;
  id?: number;
  comment?: string;
  favs: number[];
  author?: string;
};

export const defaultFavs = {
  title: "Новый список",
  favs: [],
};

export const handbook_options = [
  { friendlyName: "Городские", val: "1" },
  { friendlyName: "Сельские", val: "2" },
  { friendlyName: "Смешанные", val: "0" },
];

export const ANAL_TABS = [
  {
    title: "График потребления",
    value: "chart",
  },
  {
    title: "История изменения статусов",
    value: "history",
  },
];

export const rangeArray = [
  { val: "30", friendlyName: "30м" },
  { val: "100", friendlyName: "100м" },
  { val: "300", friendlyName: "300м" },
];
