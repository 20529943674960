import styled from "@emotion/styled";
import { css } from "@emotion/react";

const calcStyles = (size: string, withSearch?: boolean) => {
  switch (size) {
    case "S":
      return css`
        padding: 4px 12px 4px ${withSearch ? "44px" : "12px"};
        line-height: 20px;
        font-size: 1rem;
        height: 32px;
      `;
    case "M":
    default:
      return css`
        padding: 8px 36px 8px ${withSearch ? "40px" : "12px"};
        line-height: 1.411;
        font-size: 1.21rem;
      `;
  }
};

export const SWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SLabel = styled.div`
  display: flex;
`;

export const SCont = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  align-items: center;
  height: auto;

  & .icon--search {
    position: absolute;
    margin-left: 11px;
    color: ${(props) => props.theme.mtsColor.icons.tertiary.lightMode};
  }
`;

export const inputCommon = css`
  width: 100%;
  height: 44px;
  font-weight: 400;
  outline: none;
  border: 1px solid;
  border-radius: 8px;
  background-color: #ffffff;
  transition: 0.2s all ease-out;
  line-height: 1;
`;

export const SInput = styled.input<{ hasError?: boolean; sizing: string; withSearch?: boolean }>`
  ${inputCommon};
  color: ${(props) => props.theme.mtsColor.text.primary.lightMode};
  border-color: ${(props) =>
    props.hasError
      ? `${props.theme.mtsColor.accent.negative.lightMode} !important`
      : "rgba(188, 195, 208, 0.5)"};
  ${(props) => calcStyles(props.sizing, props.withSearch)};

  &:not(:disabled):not(:focus):hover {
    border-color: ${(props) => props.theme.mtsColor.controls.secondaryActive.lightMode};
  }
  &:focus {
    border-color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
  }
  &::placeholder {
    color: ${(props) => props.theme.mtsColor.text.tertiary.lightMode};
  }
  &:disabled {
    background-color: ${(props) => props.theme.mtsColor.background.secondary.lightMode};
    color: ${(props) => props.theme.mtsColor.controls.inactive.lightMode};
  }
`;

export const STextarea = styled.textarea<{
  hasError?: boolean;
}>`
  ${inputCommon};
  height: unset;
  min-height: 96px;
  border-color: ${(props) =>
    props.hasError
      ? `${props.theme.mtsColor.accent.negative.lightMode} !important`
      : "rgba(188, 195, 208, 0.5)"};
  color: ${(props) => props.theme.mtsColor.text.primary.lightMode};
  padding: 16px;
  line-height: 20px;
  font-size: 1rem;

  &:not(:disabled):not(:focus):hover {
    border-color: ${(props) => props.theme.mtsColor.controls.secondaryActive.lightMode};
  }
  &:focus {
    border-color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
  }
  &::placeholder {
    color: ${(props) => props.theme.mtsColor.text.tertiary.lightMode};
  }
  &:disabled {
    background-color: ${(props) => props.theme.mtsColor.background.secondary.lightMode};
    color: ${(props) => props.theme.mtsColor.controls.inactive.lightMode};
  }
`;

export const SInpIcon = styled.div`
  position: absolute;
  display: inline-flex;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
`;
