import { Fragment, useContext } from "react";

import { ContextSettings } from "context/context-settings";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { CusTypo } from "components/cusTypo/custom-typography";
import { Spacer } from "components/spacer/spacer.component";
import { getPhoneWithSpaces } from "utils/formatters";
import { Footer } from "components/footer/footer";
import Icon from "components/icons";
import { LIST_ROLES_NAME } from "utils/roles";
import { HARDCODED_CARDS, links } from "./const";
import { SCard, SIcon, SLink, SPhone, SPre, SSub, STitle, STop, SWrap } from "./styles";

export const MainPage = () => {
  const { profile, roles } = useContext(ContextSettings);

  return (
    <>
      <SWrap>
        <Breadcrumbs links={links} />
        <STop>
          <CusTypo variant="h2_medium" font="wide">
            {profile?.userName}
          </CusTypo>
          <Spacer value="8px" />
          <CusTypo variant="p3_regular" font="comp">
            {roles.map((a) => LIST_ROLES_NAME[a.toUpperCase()]).join(", ")}
          </CusTypo>
          <Spacer value="8px" />
          <SPhone variant="p4_regular">{getPhoneWithSpaces(profile?.phone ?? "")}</SPhone>
        </STop>
        <Spacer value="48px" />
        <div>
          {HARDCODED_CARDS.map((a, indx, self) => {
            const isEnabled = profile?.services_ch.includes(a.service);
            return (
              <Fragment key={a.link}>
                <SCard isDis={!isEnabled}>
                  {isEnabled ? (
                    <SIcon>
                      <Icon.CheckShaped sx={{ width: 24, height: 24 }} />
                    </SIcon>
                  ) : null}
                  <STitle variant="h3_medium" font="comp" isDis={!isEnabled}>
                    {a.title}
                  </STitle>
                  {a.sub ? (
                    <>
                      <SSub variant="p3_medium" isDis={!isEnabled}>
                        {a.sub}
                      </SSub>
                    </>
                  ) : null}
                  <Spacer value="16px" />
                  <SPre variant="c1_regular" font="comp">
                    {a.text}
                  </SPre>
                  <Spacer value="16px" />
                  <SLink href={a.link} target="_blank" rel="noreferrer">
                    Подробнее
                  </SLink>
                </SCard>
                {indx === self.length - 1 ? null : <Spacer value="16px" />}
              </Fragment>
            );
          })}
        </div>
        <Spacer value="48px" />
      </SWrap>
      <Footer />
    </>
  );
};
