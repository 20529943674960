import styled from "@emotion/styled";

export const SInp = styled.input`
  position: absolute;
  z-index: 1;
  overflow: hidden;
  display: block;
  width: 186px;
  height: 35px;
  cursor: pointer;
  opacity: 0;

  &:hover + .MuiButton-root {
    cursor: pointer;
    background-color: ${(props) => props.theme.mtsColor.grey.greyLight};
    box-shadow: none;
  }
`;

export const SRow = styled.div`
  display: flex;
  flex-flow: row nowrap;

  & > svg {
    margin-right: 5px;
  }
`;
