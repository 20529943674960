import { IDevicesPriemResponse, IDevicesPoResponse } from "interface/anomalies";

export const mock_priem: IDevicesPriemResponse = {
  priem: [
    "Восточный РЭС",
    "Западный РЭС",
    "Северный РЭС",
    "Центральный РЭС",
    "Юго-Восточный РЭС",
    "Южный РЭС",
  ],
};

export const mock_po: IDevicesPoResponse = {
  po: ["БЭС", "КЭС", "УГЭС", "ЦЭС"],
};
