import { RenderFilter } from "components/table/render-filter/renderFilter.component";
import { MobDeviceCard } from "./subComponents";
import { PO_COLUMN, RES_COLUMN } from "pages/anomalies/const";

// здесь строгая проверка на соответствие стринге, в отличие от встроенного фильтра 'includesSome', который цепляет "восточный" и "юго-восточный" например
function multiSelectPriem(rows, columnIds, filterValue) {
  return filterValue.length === 0
    ? rows
    : rows.filter((row) => filterValue.includes(String(row.original[RES_COLUMN])));
}

function multiSelectStatus(rows, columnIds, filterValue) {
  return filterValue.length === 0
    ? rows
    : rows.filter((row) => {
        const { telebot_request_last_result } = row.original;
        return (
          telebot_request_last_result && filterValue.includes(String(telebot_request_last_result))
        );
      });
}

function dontFilter(rows, columnIds, filterValue) {
  return rows;
}

export const tableStructure = [
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Серийный номер"
        withPadding
      />
    ),
    accessor: "factory_count_num",
    minWidth: 188,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Адрес"
        withPadding
      />
    ),
    accessor: "adress_norm",
    minWidth: 424,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="РЭС"
        withPadding
      />
    ),
    accessor: RES_COLUMN,
    minWidth: 172,
    canFilter: true,
    filter: multiSelectPriem,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Статус"
        withPadding
      />
    ),
    accessor: "telebot_request_last_result",
    minWidth: 148,
    canFilter: true,
    filter: multiSelectStatus,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Дата"
        withPadding
      />
    ),
    accessor: "telebot_request_last_dt",
    minWidth: 100,
    sortType: "datetime",
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Состояние"
        withPadding
      />
    ),
    accessor: "state_code",
    minWidth: 120,
    canFilter: true,
    filter: "includesSome",
  },
  {
    accessor: "action",
    minWidth: 80,
    width: "100%",
    disableSortBy: true,
    canFilter: false,
    disableFilters: true,
    filter: dontFilter,
  },
  {
    accessor: PO_COLUMN,
    maxWidth: 0,
    canFilter: false,
    disableFilters: true,
    filter: dontFilter,
  },
  {
    accessor: "id",
    minWidth: 0,
    maxWidth: 0,
  },
  {
    accessor: "id_point",
    maxWidth: 0,
  },
  {
    accessor: "id_pp",
    maxWidth: 0,
  },
  {
    accessor: "po",
    maxWidth: 0,
    canFilter: false,
    disableFilters: true,
    filter: dontFilter,
  },
  {
    accessor: "priem",
    maxWidth: 0,
    canFilter: false,
    disableFilters: true,
    filter: dontFilter,
  },
];

export const tableMobStructure = [
  {
    accessor: "factory_count_num",
    Row: MobDeviceCard,
  },
  {
    accessor: "adress_norm",
  },
  {
    accessor: RES_COLUMN,
    canFilter: true,
    filter: multiSelectPriem,
  },
  {
    accessor: "telebot_request_last_result",
    canFilter: true,
    filter: multiSelectStatus,
  },
  {
    accessor: "telebot_request_last_dt",
    sortType: "datetime",
  },
  {
    accessor: PO_COLUMN,
    canFilter: false,
    disableFilters: true,
    filter: dontFilter,
  },
  {
    accessor: "id_point",
  },
  {
    accessor: "id_pp",
  },
  {
    accessor: "po",
    maxWidth: 0,
    canFilter: false,
    disableFilters: true,
    filter: dontFilter,
  },
  {
    accessor: "priem",
    maxWidth: 0,
    canFilter: false,
    disableFilters: true,
    filter: dontFilter,
  },
  {
    accessor: "state_code",
    canFilter: false,
    disableFilters: true,
    filter: dontFilter,
  },
];
