import { format } from "date-fns";
import * as yup from "yup";
import theme from "theme";

import { getDatesDiffInYears } from "components/chart/utils";
import { EDemoMngStatus, EDqScore } from "interface/common";
import { IDemoMngScoreResponse } from "interface/management";

const actionsetByStatus = (val) => {
  switch (val) {
    case EDemoMngStatus.Приостановлен:
      return ["Восстановить", "Детализация данных", "Удалить"];
    case EDemoMngStatus["Активен"]:
      return ["Приостановить", "Детализация данных", "Удалить"];
    case EDemoMngStatus["На модерации"]:
      return ["Утвердить", "Отклонить", "Детализация данных", "Удалить"];
    case EDemoMngStatus["На согласовании"]:
      return ["Утвердить", "Отклонить", "Детализация данных", "Удалить"];
    case EDemoMngStatus["Заполнение анкеты"]:
    default:
      return ["Удалить"];
  }
};

export const getStatusCode = {
  Удалить: "del",
  Восстановить: "recover",
  Приостановить: "stop",
  Отклонить: "reject",
  Утвердить: "accept",
  "Детализация данных": "detail",
};

const statusWithoutModal = "Детализация данных";

export const makeSet = ({ status, commonCallback, detailCallback }) => {
  return actionsetByStatus(status).reduce((total, amount) => {
    return [
      ...total,
      {
        title: amount,
        onClick: amount === statusWithoutModal ? detailCallback : () => commonCallback(amount),
      },
    ];
  }, [] as any);
};

const calculateScoreByKey = {
  meter_cnt: (val) => (val >= 500 ? "green" : val >= 300 && val < 500 ? "yellow" : "red"),
  period: (val: string[]) => {
    const res = getDatesDiffInYears(val);
    return res >= 2 ? "green" : res >= 1 && res < 2 ? "yellow" : "red";
  },
  consumer_type: (val) => {
    let target = val.split(";");
    return (target.includes("ФЛ") && target.includes("ТУ")) ||
      (target.includes("ФЛ") && !target.includes("ЮЛ"))
      ? "green"
      : target.includes("ФЛ") && target.includes("ЮЛ")
      ? "yellow"
      : "red";
  },
  change_flg: (val) => (val ? "green" : "yellow"),
  fraud_flg: (val) => (val ? "green" : "yellow"),
  TM: (val) => {
    if (val != null) {
      const real = parseInt(val);
      return real > 40 ? "red" : real < 39 && real > 10 ? "yellow" : "green";
    } else {
      return "red";
    }
  },
};

export const titleByKey = {
  meter_cnt: "Количество устройств",
  period: "Период сбора данных",
  consumer_type: "Категория абонента",
  change_flg: "Смена прибора учета",
  fraud_flg: "Есть ли в данных ранее обнаруженные взломанные приборы учета?",
  TM: "Ошибки телеметрии",
  email: "Электронная почта",
  inn: "ИНН",
  measure_type: "Единица измерения",
  tariff_type: "Тариф",
  user_comment: "Комментарий",
  val_type: "Тип записи электроэнергии",
  discr_type: "Дискретизация",
  full_name: "ФИО",
};

export const makeScorePanel = (dt: IDemoMngScoreResponse) => {
  const { meter_cnt, consumer_type, change_flg, fraud_flg, TM, period_from, period_to } = dt;
  return {
    meter_cnt: { text: meter_cnt, score: calculateScoreByKey["meter_cnt"](meter_cnt) },
    period: {
      text: [
        format(new Date(period_from ?? 0), "dd.MM.yyyy"),
        format(new Date(period_to ?? 0), "dd.MM.yyyy"),
      ].join(" - "),
      score: calculateScoreByKey["period"]([period_from, period_to]),
    },
    consumer_type: {
      text: consumer_type
        .split(";")
        .map((a) => consumerByAbbr[a])
        .join(", "),
      score: calculateScoreByKey["consumer_type"](consumer_type),
    },
    change_flg: {
      text: change_flg ? "Да" : "Нет признака",
      score: calculateScoreByKey["change_flg"](change_flg),
    },
    fraud_flg: {
      text: fraud_flg ? "Да" : "Нет признака",
      score: calculateScoreByKey["fraud_flg"](fraud_flg),
    },
    TM: {
      text: TM != null ? parseInt(TM) + "%" : "нет данных",
      score: calculateScoreByKey["TM"](TM),
    },
  };
};

const consumerByAbbr = {
  ЮЛ: "Юридические лица",
  ФЛ: "Физические лица",
  ТУ: "Технологический учет",
};

export const dqToScore = (val) => {
  switch (val) {
    case EDqScore.Подходит:
      return "green";
    case EDqScore.Допустимо:
      return "yellow";
    case EDqScore["Не подходит"]:
      return "red";
  }
};

export const makeDetailPanel = (dt: IDemoMngScoreResponse) => {
  const {
    inn,
    email,
    measure_type,
    val_type,
    tariff_type,
    user_comment,
    consumer_type,
    full_name,
  } = dt;
  return {
    inn: {
      text: inn,
      isCanCopy: true,
    },
    full_name: {
      text: full_name,
      isCanCopy: true,
    },
    email: {
      text: email,
      isCanCopy: true,
    },
    measure_type: {
      text: measure_type,
    },
    val_type: {
      text: val_type,
    },
    discr_type: {
      text: consumer_type
        .split(";")
        .map((a) => consumerByAbbr[a])
        .join(", "),
    },
    tariff_type: {
      text: tariff_type,
    },
    user_comment: {
      text: user_comment,
    },
  };
};

export const modalTitleByStatus = {
  del: "Удалить демо аккаунт?",
  reject: "Отклонить данные?",
  stop: "Приостановить демо акканут?",
  recover: "Восстановить демо акканут?",
  accept: "Утвердить данные?",
};

export const modalDescrByStatus = {
  del: "Чтобы продолжить пользоваться сервисом, потребуется выполнить новую регистрацию",
  reject: "Пользователю необходимо будет снова пройти опросный лист и\u00A0заполнить шаблон",
  stop: "До\u00A0тех пор, пока аккаунт не\u00A0будет восстановлен администратором, пользователь не\u00A0сможет получить доступ к\u00A0сервису",
  recover: "Пользователь снова получит доступ к\u00A0сервису",
  accept:
    "Модерация будет пройдена и\u00A0пользователь получит доступ к\u00A0демо аналитической платформы",
};

export const labelByStatus = {
  del: "удаления демо аккаунта",
  reject: "отклонения данных",
  stop: "приостановки демо аккаунта",
};

export const succTitleByStatus = {
  reject: "Данные отклонены",
  del: "Демо аккаунт удален",
  stop: "Демо аккаунт\nприостановлен",
  recover: "Демо аккаунт\nвосстановлен",
  accept: "Модерация пройдена",
};

export const succDescrByStatus = {
  reject: "присвоен статус",
  del: "Сообщение было отправлено на\u00A0контактные данные, указанные при регистрации",
  accept: "присвоен статус",
};

export const newByStatus = {
  accept: "Активен",
  reject: "Заполнение анкеты",
};

export const colorByStatus = {
  accept: theme.mtsColor.base.success,
  reject: "#606060",
};

export const calcShema = (isForDeletion: boolean) => {
  return isForDeletion
    ? yup.object({
        comment: yup
          .string()
          .required()
          .test(
            "correctComment",
            "Комментарий должен быть осмысленным",
            (a) => (a ?? "").length > 5,
          ),
      })
    : yup.object({ comment: yup.string().notRequired().optional() });
};
