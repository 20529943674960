import styled from "@emotion/styled";

export const SHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 12px;

  & div {
    display: flex;
    align-items: center;
    gap: 4px;

    & svg {
      margin-bottom: -6px;
    }
  }
`;

export const SBody = styled.div`
  & > ul {
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      white-space: nowrap;
      padding: 4px 0;

      &:not(:last-of-type) {
        border-bottom: 1px solid ${(props) => props.theme.mtsColor.background.secondary.lightMode};
      }

      & > span {
        &:first-of-type {
          text-align: left;
          width: 120px;
          flex: 0 0 120px;
          color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
        }

        &:last-of-type {
          flex: 1 1 auto;
        }

        &:not(:first-of-type) {
          white-space: normal;
          text-align: right;
        }
      }
    }
  }
`;

export const SContent = styled.div<{ isHide: boolean }>`
  flex: 1;

  ${SHeader} {
    & > div:first-of-type {
      filter: ${(props) => (props.isHide ? "opacity(.4)" : "inherit")};
    }
  }

  & > div:nth-of-type(2) {
    filter: ${(props) => (props.isHide ? "opacity(.4)" : "inherit")};
  }

  ${SBody} {
    filter: ${(props) => (props.isHide ? "opacity(.4)" : "inherit")};
  }
`;
