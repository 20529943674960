import { useRef, useState, useLayoutEffect, useContext } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import { convertRoleToCyr, hasRoleAccess } from "components/forms/user/validation-schema";
import { ContextSettings } from "context/context-settings";
import { convertEmptyValue } from "components/card-info/utils";
import { UserAccordionDetails } from "components/card-info/users/user.component";
import Icon from "components/icons";
import { EMPTY_CELL_VALUE } from "stream-constants/keys-and-constants";
import { CusTypo } from "components/cusTypo/custom-typography";
import { HEIGHT_EXP } from "components/table/styles";

const MAGIC_HEIGHT = 255;
const FAULT = 40;

export const Info = ({ rowData }) => {
  const { roles } = useContext(ContextSettings);
  const isCanSeeTenant = hasRoleAccess(roles, rowData.roles);

  return (
    <CusTypo variant="p4_regular" styles={{ textAlign: "left" }}>
      {isCanSeeTenant ? (
        <CusTypo variant="p4_medium">{`${rowData.tenant?.title ?? EMPTY_CELL_VALUE} / `}</CusTypo>
      ) : null}
      {rowData.roles?.map((a) => convertRoleToCyr(a.name)).join(", ")}
    </CusTypo>
  );
};

export const SInnerItem = styled.li`
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
  &:nth-of-type(2n) {
    background-color: ${(props) => props.theme.mtsColor.background.primary.lightMode};
  }

  & div:not(:last-child) span {
    color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
  }
`;

export const SInner = styled.div`
  padding: 24px;
  cursor: default;
`;

export const SAccordion = styled(Accordion)<{ expanded?: boolean }>`
  z-index: 1;
  position: absolute;
  left: 0;
  top: 92px;
  min-height: 0;
  height: auto;
  width: 100%;
  padding: 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  ${(props) =>
    props.expanded
      ? css`
          border-top: 1px solid ${props.theme.mtsColor.grey.greyLight};
        `
      : ""};

  &.MuiAccordion-root {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: none;
    margin: 0;
    &:before {
      display: none;
    }
  }
`;

export const SRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
`;

export const Expandable = ({ value, row, isOpen }) => {
  const [parent, setParent] = useState(900);
  const [heightInnerCard, setHeightInnerCard] = useState(0);

  const refOne = useRef<HTMLDivElement>(null);
  const refTwo = useRef<any>(null);

  const calcHeightInner = (val: number) => setHeightInnerCard(val);

  useLayoutEffect(() => {
    if (refOne?.current) {
      const width = refOne?.current?.closest?.(".tbody_tr")?.clientWidth ?? 900;
      setParent(width);
    }
  }, []);

  useLayoutEffect(() => {
    if (refTwo?.current) {
      const elem = refTwo?.current;
      const measured = elem?.getBoundingClientRect().height;
      const totalHeight = measured < MAGIC_HEIGHT ? measured + FAULT : measured;
      if (isOpen) {
        elem.closest(".tbody_tr").style.height =
          heightInnerCard > totalHeight ? `${heightInnerCard}px` : `${totalHeight + HEIGHT_EXP}px`;
      } else {
        elem.closest(".tbody_tr").style.height = `${HEIGHT_EXP}px`;
      }
    }
  }, [isOpen, heightInnerCard]);

  return (
    <>
      <SRow>
        {row.original.isResponsible ? <Icon.Crown /> : null}

        <CusTypo variant="p4_medium" ref={refOne}>
          {convertEmptyValue(value)}
        </CusTypo>
      </SRow>
      <SAccordion
        id={row.original.id}
        aria-controls={`panel${row.original.id}-content`}
        expanded={isOpen}
        TransitionProps={{
          unmountOnExit: true,
        }}
        style={{
          width: parent,
        }}
        ref={refTwo}
      >
        <AccordionSummary
          style={{
            minHeight: 0,
            height: 0,
          }}
        />
        <AccordionDetails>
          <UserAccordionDetails data={row.original} calcHeightInner={calcHeightInner} />
        </AccordionDetails>
      </SAccordion>
    </>
  );
};
