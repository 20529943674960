import { Fragment } from "react";
import styled from "@emotion/styled";

import { getNumberWithSpaces } from "utils/formatters";
import { CusTypo } from "components/cusTypo/custom-typography";
import { Spacer } from "components/spacer/spacer.component";
import { IResearchStatInfoRes } from "interface/analytics";
import { makeStat } from "./utils";
import { infoByKey } from "./const";

const SCont = styled.section`
  position: relative;
  max-width: 100%;
  width: 100%;
  padding: 44px 12px 12px 12px;
  min-height: 100px;
  height: auto;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.mtsColor.background.stroke.lightMode};
  background-color: white;
`;

const SHead = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 32px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0px 12px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: ${(props) => props.theme.mtsColor.background.secondary.lightMode};
  border-bottom: 1px solid ${(props) => props.theme.mtsColor.background.stroke.lightMode};
`;

const SRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

const STotal = styled(SRow)`
  padding-top: 8px;
  border-top: 1px solid ${(props) => props.theme.mtsColor.background.stroke.lightMode};
`;

const SGrey = styled(CusTypo)`
  color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
`;

export const StatBlock: React.FC<{ data: IResearchStatInfoRes }> = ({ data }): any => {
  return makeStat(data).map((a, indx, self) => (
    <Fragment key={a.title}>
      <SCont>
        <SHead>
          <CusTypo variant="c1_medium" font="comp">
            {a.title}
          </CusTypo>
        </SHead>
        <div>
          {Object.entries(a.columns).map(([key, val]) => (
            <Fragment key={key}>
              <SRow>
                <SGrey variant="c1_regular" font="comp">
                  {infoByKey[key]}
                </SGrey>
                <CusTypo variant="c1_regular" font="comp">
                  {getNumberWithSpaces(val)}
                </CusTypo>
              </SRow>
              <Spacer value="8px" />
            </Fragment>
          ))}
          <STotal>
            <SGrey variant="c1_medium" font="comp">
              {indx === 0 ? "Всего ПУ" : "Всего"}
            </SGrey>
            <CusTypo variant="c1_medium" font="comp">
              {a.total != null
                ? getNumberWithSpaces(a.total)
                : getNumberWithSpaces(
                    Object.entries(a.columns).reduce((tot, [key, val]) => tot + val, 0),
                  )}
            </CusTypo>
          </STotal>
        </div>
      </SCont>
      {indx !== self.length - 1 ? <Spacer value="12px" /> : null}
    </Fragment>
  ));
};
