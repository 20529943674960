import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Information = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="6"
      height="16"
      viewBox="0 0 6 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.46341 1.95122C5.46341 3.02829 4.58927 3.90244 3.5122 3.90244C2.43512 3.90244 1.56098 3.02829 1.56098 1.95122C1.56098 0.874146 2.43512 0 3.5122 0C4.58927 0 5.46341 0.874146 5.46341 1.95122ZM5.01073 7.08683C5.30732 7.38341 5.46341 7.77366 5.46341 8.19512V14.439C5.46341 15.2976 4.76098 16 3.90244 16C3.0439 16 2.34146 15.2976 2.34146 14.439V9.7561H1.56098C0.702439 9.7561 0 9.05366 0 8.19512C0 7.33659 0.702439 6.63415 1.56098 6.63415H3.90244C4.3239 6.63415 4.71415 6.80585 5.01073 7.08683Z"
      />
    </SvgIcon>
  );
};

export default Information;
