import { IDemoScore } from "interface/management";
import { Highlighter, StatBubble } from "./subComponents";

export const constructTableData = (draft: IDemoScore[]) =>
  draft?.map((d) => {
    const { address, id, score, sn } = d;

    return {
      id,
      severity: score,
      serial: sn,
      address,
    };
  });

export const constructColumns = (data) => {
  const { columns, selectedId } = data;
  return columns.map((singleColumn) => {
    return {
      ...singleColumn,
      ...(singleColumn.accessor === "id"
        ? {
            Cell: ({ value, row }) => (
              <Highlighter val={value} isSelected={selectedId === row.original.id} />
            ),
          }
        : singleColumn.accessor === "severity"
        ? {
            Cell: StatBubble,
          }
        : {}),
    };
  });
};
