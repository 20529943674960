import { FC, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { yupResolver } from "@hookform/resolvers/yup";

import { MTSInput } from "components/mts-input";
import { MTSButton } from "components/mts-button/mts-button";
import { useGetUser, useManagementTenants, useUpdateUser } from "hooks/api/management";
import { unformatPhone } from "utils/formatters";
import { MTSAutocomplete } from "components/mts-autocomplete/mts-autocomplete";
import { Loader } from "components/loader/loader";
import { SModalTitle } from "components/modal-notify-body/styles";
import { Toast } from "components/toast/toast";
import { PHONE_MASK } from "stream-constants/keys-and-constants";
import { LIST_ROLES } from "utils/roles";
import { ContextSettings } from "context";
import {
  calcDropdownRoles,
  convertOrgnameToId,
  convertRoleToObject,
  makeDefaultForEdit,
  makeDropdownFromTenants,
  schema,
} from "./validation-schema";
import { SBtns, SForm, SFormField } from "./styles";

type IProps = {
  onCancel: Function;
  id: number;
};

export const FormEditUser: FC<IProps> = ({ onCancel, id }) => {
  const { roles } = useContext(ContextSettings);

  const {
    register,
    control,
    handleSubmit,
    formState: { dirtyFields, errors, isDirty },
    reset,
    getValues,
  } = useForm<any>({
    defaultValues: {},
    resolver: yupResolver(schema),
    mode: "onTouched",
    shouldFocusError: false,
    reValidateMode: "onChange",
  });

  const userPrefilled = useGetUser(id);
  const mutation = useUpdateUser();
  const tenantsData = useManagementTenants();

  const isAdmin = roles.includes(LIST_ROLES.ADMIN);
  const rolesArray = calcDropdownRoles(roles);

  const onSubmit = (submData) => {
    mutation.mutate({
      data: {
        userName: dirtyFields["userName"] != null ? submData.userName : undefined,
        email: dirtyFields["email"] != null ? submData.email : undefined,
        phone: dirtyFields["phone"] != null ? unformatPhone(submData.phone) : undefined,
        roles: dirtyFields["roles"] != null ? convertRoleToObject(submData.roles) : undefined,
        tenantId:
          dirtyFields["tenant"] != null
            ? convertOrgnameToId(submData.tenant, tenantsData?.data)
            : undefined,
      },
      id,
    });
    onCancel();
  };

  useEffect(() => {
    reset(makeDefaultForEdit(userPrefilled?.data as any, roles, isAdmin));
  }, [reset, userPrefilled.data, isAdmin, roles]);

  return (
    <>
      <SModalTitle>Редактировать пользователя</SModalTitle>
      <SForm onSubmit={handleSubmit(onSubmit)}>
        <SFormField>
          <MTSInput
            label="ФИО"
            control={control}
            {...register("userName", {
              required: true,
            })}
            errorMessage={errors?.userName?.message}
          />
        </SFormField>
        <SFormField>
          <InputMask mask={PHONE_MASK} {...register("phone")}>
            {() => (
              <MTSInput
                label="Телефон"
                {...register("phone", {
                  required: true,
                })}
                control={control}
                errorMessage={errors?.phone?.message}
              />
            )}
          </InputMask>
        </SFormField>
        <SFormField>
          <MTSInput
            label="Электронная почта"
            {...register("email", {
              required: true,
            })}
            control={control}
            errorMessage={errors?.email?.message}
          />
        </SFormField>

        {isAdmin ? (
          <SFormField>
            {tenantsData.isLoading ? (
              <Loader />
            ) : getValues("tenant") ? (
              <MTSAutocomplete
                label="Организация"
                placeholder=""
                options={makeDropdownFromTenants(tenantsData?.data)}
                control={control}
                size="M"
                {...register("tenant")}
              />
            ) : null}
          </SFormField>
        ) : null}

        <SFormField>
          {getValues("roles") ? (
            <MTSAutocomplete
              label="Роль"
              placeholder=""
              options={rolesArray}
              control={control}
              size="M"
              {...register("roles")}
              errorMessage={errors?.roles?.message}
              variant="checkbox"
            />
          ) : null}
        </SFormField>
        <SBtns>
          <MTSButton
            size="M"
            variant="primary"
            type="submit"
            onClick={() => undefined}
            disabled={!isDirty}
          >
            Сохранить
          </MTSButton>
          <MTSButton size="M" variant="secondary" onClick={() => onCancel()}>
            Отмена
          </MTSButton>
        </SBtns>
      </SForm>
    </>
  );
};

export const toastEditUserSucc = () => {
  toast(<Toast title="Редактирование успешно" />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });
};

export const toastEditUserErr = () => {
  toast(<Toast title="Редактирование не удалось" isError />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });
};
