import styled from "@emotion/styled";
import { HEADER_HEIGHT } from "components/header/styles";
import { device } from "theme";

export const maxWidthSidebar = "256px";

export const SApp = styled.div`
  position: relative;
  width: 100%;
  min-height: inherit;
  height: 100% !important;
  text-align: center;
`;

export const SCont = styled.section<{ withHeader?: boolean; withMenu?: boolean }>`
  height: 100%;

  ${(props) =>
    props.withHeader &&
    `
  		padding-top: ${HEADER_HEIGHT}px;
  `}

  ${(props) =>
    props.withMenu &&
    `
		display: flex;
		width: 100%;
		background-color: ${props.theme.mtsColor.background.primary.lightMode};
  `}
`;

export const SMain = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  min-width: calc(100% - ${maxWidthSidebar});
  min-height: 100%;
  height: 100%;
  overflow-x: hidden;
  background-color: ${(props) => props.theme.mtsColor.background.primary.lightMode};

  @media ${device.desktopPlus} {
    overflow: auto;
  }
`;
