import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Download = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.4 2C7.0688 2 6.8 2.2688 6.8 2.6V7.4H4.4L8 11L11.6 7.4H9.2V2.6C9.2 2.2688 8.9312 2 8.6 2H7.4ZM2 12.8V14H14V12.8H2Z" />
    </SvgIcon>
  );
};

export default Download;
