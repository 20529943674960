import { CusTypo } from "components/cusTypo/custom-typography";
import { eventTitleByKey, makeManagEventInfo } from "../utils";
import { Spacer } from "components/spacer/spacer.component";
import { SContainerMobile } from "../styles";
import { SBody, SContent } from "./styles";
import { MTSStatus } from "components/mts-status/mts-status";

export const EventMobileCard = ({ row }) => {
  const { userName, roles, eventType } = row.original;

  return (
    <SContainerMobile>
      <SContent>
        <div>{MTSStatus(eventType)}</div>
        <Spacer value="8px" />
        <div>
          <CusTypo variant="p3_bold" font="comp">
            {userName}
          </CusTypo>
          <Spacer value="4px" />
          <CusTypo variant="p4_regular" font="comp">
            {roles}
          </CusTypo>
        </div>
        <Spacer value="12px" />
        <SBody>
          <ul>
            {Object.entries(makeManagEventInfo(row.original)).map(([key, val], index) => (
              <li key={index}>
                <CusTypo variant="c1_regular" font="comp">
                  {eventTitleByKey[key]}
                </CusTypo>
                <CusTypo variant="c1_regular" font="comp">
                  <span dangerouslySetInnerHTML={{ __html: val.value }} />
                </CusTypo>
              </li>
            ))}
          </ul>
        </SBody>
      </SContent>
    </SContainerMobile>
  );
};
