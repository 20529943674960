import styled from "@emotion/styled";

export const SCheckBoxWrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  line-height: 0;
`;
export const SCheckBoxLabel = styled.label<{ rightPlhlr?: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${(props) => (props.rightPlhlr ? "left: 0" : "right: 0")};
  width: 28px;
  height: 16px;
  border-radius: 15px;
  background: ${(props) => props.theme.mtsColor.controls.inactive.lightMode};
  cursor: pointer;

  &:after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin: 2px;
    background: #ffffff;
    transition: 0.2s;
  }
`;
export const SCheckBox = styled.input<{ isDisabled?: boolean }>`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  min-width: 0;
  min-height: 0;
  width: 28px;
  height: 16px;
  cursor: pointer;

  &:checked + ${SCheckBoxLabel} {
    background: ${(props) =>
      props.isDisabled
        ? props.theme.mtsColor.controls.inactive.lightMode
        : props.theme.mtsColor.text.primary.lightMode};

    &:after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin-left: 14px;
      transition: 0.2s;
    }
  }
`;

export const SLabel = styled.span<{
  labelWidth?: string;
  labelSize?: string;
  rightPlhlr?: boolean;
}>`
  min-width: 0;
  min-height: 0;
  display: inline-block;
  ${(props) =>
    props.rightPlhlr
      ? `
	  margin-left: ${props.labelSize === "s" ? "24px" : "14px"};
  `
      : `
  margin-right: ${props.labelSize === "s" ? "24px" : "14px"};
  `}

  font-size: ${(props) => (props.labelSize === "s" ? "14px" : "17px")};
  font-weight: ${(props) => (props.labelSize === "s" ? "400" : "500")};
  line-height: 0.5;
  color: ${(props) => props.theme.mtsColor.text.primary.lightMode};
  white-space: normal;
  width: ${(props) => props.labelWidth};
  user-select: none;
`;
