import format from "date-fns/format";
import { ru } from "date-fns/locale";
import { MTSStatus } from "components/mts-status/mts-status";
import { IRoles } from "interface/common";
import { EEventObjectType, IEventsResponse } from "interface/management";
import {
  ROUTE_DEVICE_LIST_PAGE,
  ROUTE_TENANT_PAGE,
  ROUTE_USERS,
} from "stream-constants/route-constants";
import { Anchor } from "./subComponents";

const makeDateRowEvent = (parent: IEventsResponse, roles: IRoles[]) => {
  return parent.events
    .map((event, i, array) => {
      return {
        ...parent,
        roles: roles
          .map((a) => a.name)
          .filter((b) => b != null)
          .join(", "),
        ...array[i],
      };
    })
    .map((a) => ({ ...a, eventDatetime: new Date(a.eventDatetime ?? 0) }));
};

export const constructTableData = (draft: IEventsResponse[]) =>
  draft
    ?.map((d) => {
      const { roles } = d;
      return makeDateRowEvent(d, roles);
    })
    .flat();

export const constructColumns = ({ columns }) => {
  return columns.map((singleColumn) => {
    return {
      ...singleColumn,
      ...(singleColumn.accessor === "eventType"
        ? {
            Cell: ({ value }) => {
              return MTSStatus(value, "medium");
            },
          }
        : singleColumn.accessor === "eventDatetime"
        ? {
            Cell: ({ value }) => {
              return format(new Date(value ?? 0), "dd.MM.yyyy HH:mm", {
                locale: ru,
              });
            },
          }
        : singleColumn.accessor === "eventDesc"
        ? {
            Cell: ({ row }) => <Anchor value={row.original} />,
          }
        : { Cell: ({ value }) => value ?? "—" }),
    };
  });
};

const mapTypeToUrl = {
  [EEventObjectType.user_id]: ROUTE_USERS,
  [EEventObjectType.tenant_id]: ROUTE_TENANT_PAGE,
  [EEventObjectType.id_pp]: ROUTE_DEVICE_LIST_PAGE,
  noname: "/lk",
};

export const getEventItemUrl = (
  objType: keyof typeof EEventObjectType | null,
  id: string | null,
  isMob: boolean,
) => {
  const pre = mapTypeToUrl[objType ?? "noname"];
  return isMob ? `${pre}/${id}` : pre;
};
