import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "lodash";

import { schema } from "./const";
import { MTSButton } from "components/mts-button/mts-button";
import { MTSInput } from "components/mts-input";
import { Spacer } from "components/spacer/spacer.component";
import { SModalTitle } from "components/modal-notify-body/styles";
import { SControls } from "components/analytics-research/reserchForm/styles";
import { useCreateTariff } from "hooks/api/management";
import { NumericFormat } from "react-number-format";
import { Toast } from "components/toast/toast";

interface IProp {
  onCancel: () => void;
}

export const TariffForm: React.FC<IProp> = ({ onCancel }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {} as any,
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const { mutate } = useCreateTariff();

  const handleCancel = () => {
    reset();
    onCancel();
  };

  const onSubmit = (sbmt) => {
    const { service_code, contract_number, account_number, price, comment } = sbmt;
    mutate(
      {
        comment,
        price,
        account_number,
        contract_number,
        service_code,
      },
      {
        onSuccess: () => {
          handleCancel();
          toastSaveSucc();
        },
        onError: () => {
          toastSaveErr();
        },
      },
    );
  };

  return (
    <>
      <SModalTitle>Добавить тариф</SModalTitle>
      <Spacer value="24px" />
      <MTSInput
        placeholder=""
        size="S"
        label="Код услуги"
        control={control}
        {...register("service_code", {
          required: true,
        })}
        errorMessage={errors?.service_code?.message}
      />
      <Spacer value="16px" />
      <Controller
        control={control}
        name="contract_number"
        render={({ field: { onChange, value } }) => {
          return (
            <NumericFormat
              customInput={MTSInput}
              allowNegative={false}
              thousandSeparator={false}
              onValueChange={(v) => {
                onChange(Number(v.value));
              }}
              value={value}
              {...{
                size: "S",
                label: "Номер для тарификации",
                errorMessage: errors?.contract_number?.message,
              }}
            />
          );
        }}
      />
      <Spacer value="16px" />
      <Controller
        control={control}
        name="account_number"
        render={({ field: { onChange, value } }) => {
          return (
            <NumericFormat
              customInput={MTSInput}
              allowNegative={false}
              thousandSeparator={false}
              onValueChange={(v) => {
                onChange(Number(v.value));
              }}
              value={value}
              {...{
                size: "S",
                label: "Лицевой счет",
                errorMessage: errors?.account_number?.message,
              }}
            />
          );
        }}
      />
      <Spacer value="16px" />
      <Controller
        control={control}
        name="price"
        render={({ field: { onChange, value } }) => {
          return (
            <NumericFormat
              suffix=" ₽"
              customInput={MTSInput}
              allowNegative={false}
              thousandSeparator={" "}
              onValueChange={(v) => {
                onChange(Number(v.value));
              }}
              value={value}
              {...{
                size: "S",
                label: "Стоимость",
                errorMessage: errors?.price?.message,
              }}
            />
          );
        }}
      />
      <Spacer value="16px" />
      <MTSInput
        placeholder="Не обязательно"
        type="textarea"
        label="Комментарий"
        {...register("comment", {
          required: false,
        })}
        control={control}
        errorMessage={errors?.comment?.message}
      />
      <Spacer value="24px" />
      <SControls>
        <MTSButton
          size="M"
          variant="primary"
          type="submit"
          disabled={!isEmpty(errors)}
          onClick={handleSubmit(onSubmit, undefined)}
        >
          Подтвердить
        </MTSButton>
        <MTSButton size="M" variant="secondary" onClick={handleCancel}>
          Отмена
        </MTSButton>
      </SControls>
    </>
  );
};

const toastSaveSucc = () =>
  toast(<Toast title="Тарификация прошла успешно" />, {
    progress: undefined,
    autoClose: 2000,
    hideProgressBar: true,
  });

const toastSaveErr = () =>
  toast(<Toast title="Тарификация не удалась" isError />, {
    autoClose: 2000,
    hideProgressBar: false,
  });
