export enum EDirection {
  DESC = "desc",
  ASC = "asc",
}

export const hardcodedDirection = [
  { val: EDirection.DESC, friendlyName: "По убыванию" },
  { val: EDirection.ASC, friendlyName: "По возрастанию" },
];

export interface ISortItem {
  accessor: string;
  text: string;
}
