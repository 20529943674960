import { useContext } from "react";

import { Loader } from "components/loader/loader";
import { PopupAction } from "components/popup-action/popup-action";
import { getNumberWithSpaces } from "utils/formatters";
import { CusTypo } from "components/cusTypo/custom-typography";
import { billingTitleByKey, makeManagBillInfo } from "../utils";
import { CopyToClipboard } from "components/copyToClipboard/copyToClipboard.component";
import { Spacer } from "components/spacer/spacer.component";
import { ModalAction } from "components/modal-action/modal-action";
import { ContextSettings } from "context/context-settings";
import { useDownloadBillXlsx } from "hooks/api/management";
import { SContainerMobile } from "../styles";
import { SBody, SContent, SDown, SHeader } from "./styles";

export const BillingCard = ({ row }) => {
  const { isMobile } = useContext(ContextSettings);
  const { bill_id, bill_amount, service_nm } = row.original;
  const { mutate: getXlsxBilling, isLoading } = useDownloadBillXlsx();

  const handleDownloadFileBilling = () => {
    getXlsxBilling(bill_id);
  };

  const dataPopup = [
    {
      title: "Скачать детализацию счета",
      onClick: handleDownloadFileBilling,
      color: "#007CFF",
    },
  ];

  return (
    <SContainerMobile>
      <SContent>
        <SHeader>
          <CusTypo variant="p3_bold" font="comp">
            {`${getNumberWithSpaces(bill_amount)}  ₽`}
          </CusTypo>
          {isMobile() ? (
            <ModalAction items={dataPopup} />
          ) : (
            <PopupAction items={dataPopup} view="light" />
          )}
        </SHeader>
        <div>
          <CusTypo variant="p4_regular" font="comp">
            {service_nm}
          </CusTypo>
        </div>
        <Spacer value="12px" />
        <SBody>
          <ul>
            {Object.entries(makeManagBillInfo(row.original)).map(([key, val], index) => (
              <li key={index}>
                <CusTypo variant="c1_regular" font="comp">
                  {billingTitleByKey[key]}
                </CusTypo>
                {val["isCanCopy"] ? (
                  <CopyToClipboard text={val.value as any} />
                ) : (
                  <CusTypo variant="c1_regular" font="comp">
                    {val.value}
                  </CusTypo>
                )}
              </li>
            ))}
          </ul>
        </SBody>
      </SContent>
      {isLoading ? <Loader isFullPage /> : null}
    </SContainerMobile>
  );
};

export const BillingDeskAction = ({ data }) => {
  const { bill_id } = data.original;
  const { mutate: getXlsxBilling } = useDownloadBillXlsx();

  return (
    <SDown onClick={() => getXlsxBilling(bill_id)}>
      <CusTypo variant="p4_regular">Скачать детализацию</CusTypo>
    </SDown>
  );
};
