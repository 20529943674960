import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const List = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.36361 3.90911H15.5454V5.36365H5.36361V3.90911ZM5.36361 9.72729V8.27274H15.5454V9.72729H5.36361ZM3.18179 3.54547C3.47112 3.54547 3.74859 3.66041 3.95318 3.86499C4.15776 4.06958 4.2727 4.34705 4.2727 4.63638C4.2727 4.92571 4.15776 5.20318 3.95318 5.40777C3.74859 5.61235 3.47112 5.72729 3.18179 5.72729C2.89246 5.72729 2.61499 5.61235 2.4104 5.40777C2.20582 5.20318 2.09088 4.92571 2.09088 4.63638C2.09088 4.34705 2.20582 4.06958 2.4104 3.86499C2.61499 3.66041 2.89246 3.54547 3.18179 3.54547ZM3.18179 7.90911C3.47112 7.90911 3.74859 8.02404 3.95318 8.22863C4.15776 8.43321 4.2727 8.71069 4.2727 9.00002C4.2727 9.28934 4.15776 9.56682 3.95318 9.77141C3.74859 9.97599 3.47112 10.0909 3.18179 10.0909C2.89246 10.0909 2.61499 9.97599 2.4104 9.77141C2.20582 9.56682 2.09088 9.28934 2.09088 9.00002C2.09088 8.71069 2.20582 8.43321 2.4104 8.22863C2.61499 8.02404 2.89246 7.90911 3.18179 7.90911ZM5.36361 14.0909V12.6364H15.5454V14.0909H5.36361ZM3.18179 12.2727C3.47112 12.2727 3.74859 12.3877 3.95318 12.5923C4.15776 12.7969 4.2727 13.0743 4.2727 13.3637C4.2727 13.653 4.15776 13.9305 3.95318 14.135C3.74859 14.3396 3.47112 14.4546 3.18179 14.4546C2.89246 14.4546 2.61499 14.3396 2.4104 14.135C2.20582 13.9305 2.09088 13.653 2.09088 13.3637C2.09088 13.0743 2.20582 12.7969 2.4104 12.5923C2.61499 12.3877 2.89246 12.2727 3.18179 12.2727Z"
        fill="#1D2023"
      />
    </SvgIcon>
  );
};

export default List;
