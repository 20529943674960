import styled from "@emotion/styled";
import { device } from "theme";
import { css } from "@emotion/react";

import { CusTypo } from "components/cusTypo/custom-typography";
import { Select } from "components/select/select";
import { scrollbarWidth } from "components/table/utils";
import { TABLE_RESIZER } from "./const";

export const SWrap = styled.div<{ isShowResizer?: boolean }>`
  max-width: 100%;
  padding: 48px 32px 0px;

  & .react-resizable {
    position: relative;
  }

  & .react-resizable-handle {
    ${(props) =>
      !props.isShowResizer &&
      `
		display: none;
	`}
    position: absolute;
    width: calc(100% - ${scrollbarWidth}px);
    height: ${TABLE_RESIZER};
    bottom: auto;
    left: 0;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAALCAYAAACK9ybzAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABoSURBVHgB7ZKxCYBAEARvzwYswa4MBRNjO3g7+NREMLQrS/gClPNAhctMTvjgJ9plNlzMy9YdkAiiVAnC0LcrPXg5PiFBS62u0RzJ4OVY7vKS7NjLMZOMGnYVCSSTHf/hCp+gXDi7C1/Poaxt2pQwWgAAAABJRU5ErkJggg==");
    background-position: center center;
    background-color: transparent;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: row-resize;
  }
`;

export const SSettings = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 1 auto;

  & .searchField {
    flex: 1 1 auto;
    margin-right: 16px;
  }

  @media ${device.tabletPlus} {
    flex: 1 1 auto;
    justify-content: flex-end;

    & .searchField {
      flex: 0 0 236px;
    }
  }
`;

export const STop = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 16px;
`;

export const STitle = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  & > span {
    white-space: nowrap;
    &:last-child {
      margin-right: 24px;
    }
  }
`;

export const SError = styled.div`
  padding: 48px 0 0;
`;

export const SSelect = styled.div`
  min-width: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 16px;

  & ${Select} {
    flex: 1 0 128px;
    min-width: 128px;
  }
`;

export const SStat = styled.section`
  min-width: 0;
  max-width: 1312px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  gap: 32px;

  & > div {
    &:first-of-type {
      flex: 0 1 890px;
    }
    &:last-of-type {
      flex: 0 1 352px;
    }
  }
`;

export const STabItem = styled(CusTypo)<{ isActive?: boolean }>`
  color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
  ${(props) => props.isActive && `color: ${props.theme.mtsColor.text.primary.lightMode};`}

  ${(props) =>
    props.isActive &&
    css`
      color: ${props.theme.mtsColor.text.primary.lightMode};
      &:after {
        position: absolute;
        content: "";
        width: 100%;
        bottom: 0;
        left: 0;
        height: 4px;
        background-color: ${props.theme.mtsColor.red};
      }
    `}
`;

export const STab = styled.section`
  min-height: 0;
  min-width: 0;
  display: flex;
  gap: 32px;

  & > * {
    position: relative;
    height: 36px;
    flex: 0 0 auto;
  }
`;

export const SGeoRow = styled.div`
  position: relative;
  top: 3px;
  min-width: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  padding-left: 16px;
  height: 32px;

  &:before {
    position: absolute;
    content: "";
    width: 1px;
    height: 100%;
    left: 0;
    background-color: ${(props) => props.theme.mtsColor.background.hover.lightMode};
  }

  & ${Select} {
    min-width: 94px;
  }
`;
