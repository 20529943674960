import { useState } from "react";
import { useLocation } from "react-router-dom";

import { ROUTE_MINING } from "stream-constants/route-constants";
import { useAnomaliesArchive } from "hooks/api/anomalies";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { ILocationState } from "interface/common";
import { Map } from "../../components/map/map.component";

export const MapPage = () => {
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [depFilter, setDepFilter] = useState<any>(undefined);
  const [miningFilter, setMiningFilter] = useState<any>(undefined);

  const location = useLocation();
  const isMiners = location.pathname.includes(ROUTE_MINING);
  const isAnoPage = (location.state as ILocationState)?.is_anomap;

  const { data: archive } = useAnomaliesArchive();

  const handleDepFilter = ({ filtersArr }) => {
    setDepFilter(filtersArr);
    StorageAPI.remove(EStorageKeys.DEP_FILTER);
  };

  const handleMiningFilter = ({ filtersArr }) => {
    setMiningFilter(filtersArr);
  };

  return (
    <Map
      filterOpen={isOpenFilter}
      filterToggle={setIsOpenFilter}
      archive={archive ?? []}
      handleDepFilter={handleDepFilter}
      depFilter={depFilter}
      handleMiningFilter={handleMiningFilter}
      miningFilter={miningFilter}
      isMinersPage={isMiners}
      isAnoPage={isAnoPage ?? false}
    />
  );
};
