import Icon from "components/icons";
import theme from "theme";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SCheck, SWrap } from "./styles";

type IToast = {
  title: string;
  isError?: boolean;
  isLoading?: boolean;
};

export const Toast = (props: IToast) => {
  return (
    <SWrap>
      <SCheck isError={props.isError} isLoading={props.isLoading}>
        {props.isError ? (
          <Icon.Close />
        ) : props.isLoading ? (
          <Icon.Info
            sx={{
              background: "black",
            }}
          />
        ) : (
          <Icon.Check />
        )}
      </SCheck>

      <CusTypo
        variant="p3_regular"
        styles={{ whiteSpace: "pre-wrap", color: theme.mtsColor.text.primary.darkMode }}
      >
        {props.title}
      </CusTypo>
    </SWrap>
  );
};
