import styled from "@emotion/styled";
import { MTS_WIDE } from "theme";

export const SWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SLogo = styled.div`
  position: relative;
  height: 32px;
  width: 32px;
  background-color: #ff0032;
`;

export const SName = styled.span<{ isLight?: boolean }>`
  padding: 3px 0 0;
  font-family: ${MTS_WIDE}, sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 17px;

  ${(props) =>
    props.isLight
      ? `color: ${props.theme.mtsColor.text.headline.darkMode}`
      : `color: ${props.theme.mtsColor.text.headline.lightMode}`}
`;
