import { Spinner } from "components/spinner";

import useStyles from "./styles";

interface ISpinnerProps {
  color?: "white" | "black" | "orange" | "red";
  isFullPage?: boolean;
  size?: "S_16" | "M_24" | "L_40";
}

export const Loader = (props: ISpinnerProps): JSX.Element => {
  const { color, isFullPage, size = "L_40" } = props;

  const classes = useStyles();

  return (
    <div className={`${classes.loader} ${isFullPage ? classes.loaderPage : ""}`}>
      <Spinner color={color} size={size} />
    </div>
  );
};
