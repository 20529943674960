import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const InputOk = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="16" height="16" rx="8" fill="#26CD58" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1087 4.22455C13.4015 4.51744 13.4016 4.99232 13.1087 5.28521L7.19667 11.1972C7.05602 11.3379 6.86526 11.4169 6.66634 11.4169C6.46743 11.4169 6.27666 11.3379 6.13601 11.1972L2.80268 7.8639C2.50978 7.57101 2.50978 7.09614 2.80268 6.80324C3.09557 6.51035 3.57044 6.51035 3.86334 6.80324L6.66634 9.60625L12.048 4.22455C12.3409 3.93166 12.8158 3.93166 13.1087 4.22455Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default InputOk;
