import { IDeviceList } from "interface/anomalies";
import { IProfileStruct } from "interface/common";
import { createContext } from "react";
import { LIST_ROLES } from "utils/roles";

interface ISettingsContext {
  isAuthorized: boolean;
  setIsAuthorized: (status: boolean) => void;
  profile: IProfileStruct | null;
  setProfile: (data: IProfileStruct | null) => void;
  roles: LIST_ROLES[];
  setRoles: (array: LIST_ROLES[]) => void;
  filteringDeviseList: IDeviceList[] | [];
  setFilteringDeviseList: (data: IDeviceList[] | []) => void;
  mock_mode: string;
  toggleMocks: () => void;
  isDesktop: () => boolean;
  isTablet: () => boolean;
  isMobile: () => boolean;
  getWidth: () => number;
}

export const ContextSettings = createContext<ISettingsContext>({
  isAuthorized: false,
  setIsAuthorized: (status: boolean) => {},
  profile: null,
  setProfile: (data: IProfileStruct | null) => {},
  roles: [],
  setRoles: (array: string[]) => {},
  filteringDeviseList: [],
  setFilteringDeviseList: (data: IDeviceList[] | []) => {},
  mock_mode: "off",
  toggleMocks: () => undefined,
  isDesktop: () => false,
  isTablet: () => false,
  isMobile: () => false,
  getWidth: () => 0,
});
