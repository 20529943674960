import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CheckShaped = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.68585 1.68597C0.372973 2.99884 0.275626 4.30684 0.080931 6.92282C0.0304613 7.60095 -0.00012207 8.30048 -0.00012207 9C-0.00012207 9.69952 0.0304613 10.399 0.0809311 11.0772C0.275626 13.6932 0.372973 15.0012 1.68585 16.314C2.99872 17.6269 4.30671 17.7243 6.9227 17.9189C7.60083 17.9694 8.30035 18 8.99988 18C9.6994 18 10.3989 17.9694 11.0771 17.9189C13.693 17.7243 15.001 17.6269 16.3139 16.314C17.6268 15.0012 17.7241 13.6932 17.9188 11.0772C17.9693 10.399 17.9999 9.69952 17.9999 9C17.9999 8.30048 17.9693 7.60095 17.9188 6.92282C17.7241 4.30684 17.6268 2.99884 16.3139 1.68597C15.001 0.373095 13.693 0.275748 11.0771 0.0810531C10.3989 0.0305833 9.6994 0 8.99988 0C8.30035 0 7.60083 0.0305834 6.9227 0.0810532C4.30671 0.275748 2.99872 0.373095 1.68585 1.68597ZM13.2069 7.20711C13.5974 6.81658 13.5974 6.18342 13.2069 5.79289C12.8164 5.40237 12.1832 5.40237 11.7927 5.79289L7.99977 9.58579L6.70688 8.29289C6.31635 7.90237 5.68319 7.90237 5.29266 8.29289C4.90214 8.68342 4.90214 9.31658 5.29266 9.70711L7.29266 11.7071C7.68319 12.0976 8.31635 12.0976 8.70688 11.7071L13.2069 7.20711Z"
        fill="#26CD58"
      />
    </SvgIcon>
  );
};

export default CheckShaped;
