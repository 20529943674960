import { LOG_OUT, LOG_IN, LOG_IN_VALIDATE, CHANGE_TENANT } from "services/urls";
import { IHttpMethods } from "stream-constants/server-constants";
import { fetchData } from "services/services-utils/fetch-data";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";

const fetch_default: any = {
  mode: "cors",
  cache: "no-store",
  credentials: "include",
  redirect: "follow",
};

export const login = (): void => window.location.replace(LOG_IN);

export const logout = async (): Promise<Response> => {
  return await fetch(LOG_OUT, {
    ...fetch_default,
    method: IHttpMethods.POST,
  });
};

export const refresh = async (): Promise<Response> => {
  return await fetch(LOG_IN_VALIDATE, {
    ...fetch_default,
    method: IHttpMethods.GET,
  });
};

export const authChangeTenant = async (tenant: string) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = "OK";
  } else {
    result = fetchData({
      url: CHANGE_TENANT,
      method: IHttpMethods.POST,
      name: "authChangeTenant",
      body: JSON.stringify({ tenant_id: tenant }),
      emptyResponse: false,
    });
  }

  return result;
};
