import { CopyToClipboard } from "components/copyToClipboard/copyToClipboard.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { MTSStatus } from "components/mts-status/mts-status";
import { format } from "date-fns";
import { EMPTY_CELL_VALUE } from "stream-constants/keys-and-constants";

export const constructColumns = (data) => {
  const { columns } = data;

  return columns.map((singleColumn) => {
    return {
      ...singleColumn,
      ...(singleColumn.accessor === "sn"
        ? {
            Cell: ({ value }) => (
              <CopyToClipboard text={value ?? EMPTY_CELL_VALUE} variant="c1_regular" />
            ),
          }
        : singleColumn.accessor === "level"
        ? { Cell: ({ value }) => MTSStatus(value ?? "-") }
        : singleColumn.accessor === "datetime"
        ? {
            Cell: ({ value }) => (
              <CusTypo variant="c1_medium">
                {format(new Date(value ?? 0), `dd.MM.yyyy hh:mm`)}
              </CusTypo>
            ),
          }
        : {
            Cell: ({ value }) => <CusTypo variant="c1_regular">{value ?? ""}</CusTypo>,
          }),
    };
  });
};
