import { RenderFilter } from "components/table/render-filter/renderFilter.component";
import { MobEvent } from "./subComponents";

function multiSelectLevel(rows, columnIds, filterValue) {
  return filterValue.length === 0
    ? rows
    : rows.filter((row) => {
        const { level } = row.original;
        return level && filterValue.includes(String(level));
      });
}

function multiSelectModel(rows, columnIds, filterValue) {
  return filterValue.length === 0
    ? rows
    : rows.filter((row) => {
        const { meter_name } = row.original;
        return meter_name && filterValue.includes(String(meter_name));
      });
}

function selectPeriod(rows, columnIds, filterValue) {
  if (filterValue.length === 0) return rows;
  else {
    const [from, to] = filterValue;
    let start = new Date(from);
    let end = new Date(to);

    return rows.filter((row) => {
      if (!from) {
        return new Date(row.original.datetime) <= end;
      } else if (!to) {
        return new Date(row.original.datetime) >= start;
      } else {
        return new Date(row.original.datetime) >= start && new Date(row.original.datetime) <= end;
      }
    });
  }
}

export const tableStructure = [
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Дата время"
        withPadding
      />
    ),
    accessor: "datetime",
    minWidth: 150,
    canFilter: true,
    filter: selectPeriod,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Событие"
        withPadding
      />
    ),
    accessor: "event",
    minWidth: 230,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Серийный номер"
        withPadding
      />
    ),
    accessor: "sn",
    minWidth: 150,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Модель"
        withPadding
      />
    ),
    accessor: "meter_name",
    minWidth: 150,
    filter: multiSelectModel,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Уровень"
        withPadding
      />
    ),
    accessor: "level",
    minWidth: 150,
    canFilter: true,
    filter: multiSelectLevel,
  },
];

export const tableMobStructure = [
  {
    accessor: "sn",
    Row: MobEvent,
  },
  {
    accessor: "meter_name",
    canFilter: true,
  },
  {
    accessor: "level",
  },
  {
    accessor: "datetime",
    sortType: "datetime",
  },
  {
    accessor: "event",
  },
];
