import { IHttpMethods } from "stream-constants/server-constants";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { fetchData } from "services/services-utils/fetch-data";
import {
  DEMO_GET_CHART,
  DEMO_GET_SAMPLE_XLSX,
  DEMO_GET_SCORE,
  DEMO_SUBMIT_FORM,
  DEMO_UPLOAD_XLSX,
} from "services/urls";
import { IDemoFormUploadRequest } from "interface/management";
import { chart_data, score_data } from "./mock-data";

export const getSampleXlsx = async (withAddress = false, clientType) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = [];
  } else {
    result = await fetchData({
      url: `${DEMO_GET_SAMPLE_XLSX}/${withAddress}/${clientType}`,
      method: IHttpMethods.GET,
      name: "demoGetSampleXLSX",
      emptyResponse: false,
      blobResponse: true,
      responseAbordTimeOut: false,
    }).then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `demo_sample.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
  }
  return result;
};

export const getDemoScore = async () => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = score_data;
  } else {
    result = await fetchData({
      url: DEMO_GET_SCORE,
      method: IHttpMethods.GET,
      name: "demoGetScore",
      emptyResponse: false,
    });
  }

  return result;
};

export const demoUploadForm = async (data: IDemoFormUploadRequest) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = { OK: 200 };
  } else {
    result = fetchData({
      url: DEMO_SUBMIT_FORM,
      method: IHttpMethods.POST,
      name: "demoUploadForm",
      body: JSON.stringify(data),
      emptyResponse: false,
    });
  }

  return result;
};

export const getDemoChart = async (id: string | number) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = chart_data;
  } else {
    result = await fetchData({
      url: `${DEMO_GET_CHART}/${id}`,
      method: IHttpMethods.GET,
      name: "getDemoChartData",
      emptyResponse: false,
    });
  }

  return result;
};

export const demoUploadTable = async (data: FormData) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = [
      {
        cnt: 1000,
        period_from: "Sat, 17 Jul 2021 00:00:00 GMT",
        period_to: "Wed, 18 Jan 2023 00:00:00 GMT",
      },
    ];
  } else {
    result = fetchData({
      url: DEMO_UPLOAD_XLSX,
      method: IHttpMethods.POST,
      name: "demoUploadTable",
      body: data,
      emptyResponse: false,
      contentType: "none",
    });
  }

  return result;
};
