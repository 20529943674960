import styled from "@emotion/styled";

export const SMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SContainer = styled.section`
  width: 100%;
  padding: 0 20px;
`;

export const SArticle = styled.article`
  position: relative;
  margin: 0 auto;
  padding: 80px 0;
  min-height: calc(100vh - 128px);
  width: 100%;
  max-width: 1072px;
  text-align: left;
`;

export const SWrap = styled.div`
  padding-top: 30px;
`;

export const SBlock = styled.div`
  padding-top: 40px;
  & .MuiTypography-root {
    line-height: 2.285rem;
  }
`;

export const SParag = styled.p`
  padding-top: 20px;
  display: inline-block;
`;

export const SList = styled.ul`
  padding-left: 10px;
`;

export const SListItem = styled.li`
  position: relative;
  padding-left: 15px;
  margin-top: 22px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 13px;
    height: 5px;
    width: 5px;
    border-radius: 3px;
    background-color: ${(props) => props.theme.mtsColor.background.primary.darkMode};
  }
`;
