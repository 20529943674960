import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Back } from "components/back/back.component";
import { Spacer } from "components/spacer/spacer.component";
import { FormAddResultCheck } from "components/forms/result-check/form-add-result-check";
import { ILocationState } from "interface/common";
import { SItemPage } from "./styles";

export const MobDeviceUpdatePage = () => {
  // id_pp
  let { id } = useParams();
  const navigate = useNavigate();
  const locationState = useLocation().state as ILocationState;

  return (
    <>
      <SItemPage>
        <Back />
        <Spacer value="28px" />
        <FormAddResultCheck
          idDevice={id}
          deviceSn={locationState.device_sn}
          close={() => navigate(-1)}
        />
      </SItemPage>
    </>
  );
};
