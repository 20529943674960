import { FC } from "react";
import { rangeToColor } from "theme";
import opacify from "polished/lib/color/opacify";
import { CusTypo } from "components/cusTypo/custom-typography";

const paddingBage = (val: "small" | "medium" | "large") => {
  switch (val) {
    case "small":
    case "medium":
      return "2px 12px";
    case "large":
      return "6px 12px";
  }
};

const typographyType = (val: "small" | "medium" | "large") => {
  switch (val) {
    case "small":
      return "c1_regular";
    case "medium":
    case "large":
      return "p4_regular";
  }
};

interface IStyles {
  backgroundColor: string;
  color: string;
}

export interface IMTSBadgeProps extends Partial<IStyles> {
  label: string;
  size?: "small" | "medium" | "large";
  className?: string;
}

export const MTSBadge: FC<IMTSBadgeProps> = ({
  label,
  backgroundColor = "greyBg",
  color = "grey",
  children,
  size = "small",
}) => {
  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        borderRadius: 16,
        backgroundColor: rangeToColor[backgroundColor],
        padding: `${paddingBage(size)}`,
      }}
    >
      {children ? (
        children
      ) : (
        <>
          <CusTypo styles={{ color: rangeToColor[color] }} variant={typographyType(size)}>
            {label}
          </CusTypo>
        </>
      )}
    </div>
  );
};

export const MtsBadgeCircle = ({ bg = "greyBg" }) => (
  <div
    style={{
      width: 12,
      height: 12,
      borderRadius: "50%",
      backgroundColor: opacify(1, rangeToColor[bg]),
    }}
  />
);
