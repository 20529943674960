import styled from "@emotion/styled";
import { device } from "theme";

export const SFilter = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 64px;
  right: -100vw;
  bottom: 0;
  width: 100%;
  padding: 48px 40px;
  margin: 0 !important;
  transition: all 0.5s ease-out;
  border: 1px solid #dde1e7;
  border-top: none;
  background-color: #ffffff;
  z-index: 10;

  @media ${device.desktopPlus} {
    width: 472px;
  }

  ${(props) =>
    props.isOpen &&
    `
  		right: 0;
  `}
`;

export const SHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;

  & div {
    display: flex;
    align-items: center;

    & .header__switch {
      margin-right: 16px;
    }
  }
`;

export const SBack = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & .MuiTypography-root {
    margin-left: 10px;
  }
`;

export const SWrap = styled.div<{ isDisabled: boolean }>`
  flex: 1;
  overflow-y: auto;

  ${(props) =>
    props.isDisabled &&
    `
	opacity: 0.4;
  `}
`;

export const SBtns = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;

  & button {
    width: 100%;
    height: 46px !important;
  }
`;

export const SResult = styled.div`
  margin-top: 32px;
  text-align: center;

  & .MuiTypography-root {
    color: ${(props) => props.theme.mtsColor.text.secondaryLink.lightMode};
  }
`;

export const SLoadPos = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 234px;
  z-index: 5;
`;

export const SFltBtn = styled.div<{ withValue?: boolean }>`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid #d7dbe3;
  border-radius: 6px;
  background-color: ${(props) => props.theme.mtsColor.background.secondary.lightMode};
  cursor: pointer;

  & > .MuiSvgIcon-root {
    path {
      fill: ${(props) => props.theme.mtsColor.text.secondary.darkMode};
    }
  }

  ${(props) =>
    props.withValue &&
    `
		background-color: ${props.theme.mtsColor.background.secondary.darkMode};
		border-color: ${props.theme.mtsColor.background.secondary.darkMode};

		& > .MuiSvgIcon-root {
			path {
				fill: white;
			}
		}
  `};
`;
