import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { MTSButton } from "components/mts-button/mts-button";
import { ModalNotifyBody } from "components/modal-notify-body/modal-notify-body";
import { AttachToFormBtn } from "components/upload-file-btn/attach-file-to-form";
import { Spacer } from "components/spacer/spacer.component";
import { MTSInput } from "components/mts-input";
import { CusTypo } from "components/cusTypo/custom-typography";
import Icon from "components/icons";
import { getAskueXlsx } from "services/askue";
import { useAskueUploadStatement } from "hooks/api/askue";
import { IAskueUpload } from "interface/askue";
import { schema } from "./validation-schema";
import { SModalTitle } from "components/modal-notify-body/styles";
import { SBtns } from "./styles";

type IProps = {
  onCancel?: any;
};

export const FormAddAskue: FC<IProps> = ({ onCancel }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<any>({
    defaultValues: { comment: "" },
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: false,
    reValidateMode: "onChange",
  });

  const mutation = useAskueUploadStatement();

  const onSubmit = async (submData) => {
    const fomData: IAskueUpload = submData["attached_file"];
    fomData.append("comment", JSON.stringify(submData.comment));
    mutation.mutate(fomData);
  };

  if (mutation?.isSuccess) {
    return <ModalNotifyBody title="Запрос на подключение отправлен" isSuccess />;
  } else if (mutation?.isError) {
    return <ModalNotifyBody title="Запрос не отправлен" isError />;
  } else {
    return (
      <>
        <SModalTitle>Подключить устройства</SModalTitle>
        <Spacer value="24px" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <CusTypo variant="p4_regular">
              1. Скачайте и заполните шаблон добавления устройств в АСКУЭ
            </CusTypo>
            <Spacer value="24px" />
            <MTSButton
              size="S"
              variant="secondary"
              onClick={getAskueXlsx}
              icon={<Icon.Download sx={{ width: 12, height: 12 }} />}
            >
              Скачать шаблон
            </MTSButton>
          </div>
          <Spacer value="24px" />
          <div>
            <CusTypo variant="p4_regular">
              2. Загрузите файл с данными, и отправьте его оператору
            </CusTypo>
            <Spacer value="24px" />
            <Controller
              control={control}
              name="attached_file"
              render={({ field: { onChange, value } }) => {
                return <AttachToFormBtn textBtn="Загрузить файл" onFileAttach={onChange} />;
              }}
            />
          </div>
          <Spacer value="24px" />
          <MTSInput
            placeholder="Оставьте комментарий"
            label="Комментарий"
            type="textarea"
            {...register("comment", {
              required: false,
            })}
            errorMessage={errors?.comment?.message}
          />
          <Spacer value="24px" />
          <SBtns>
            <MTSButton
              size="M"
              variant="primary"
              type="submit"
              onClick={() => undefined}
              disabled={!getValues("attached_file")}
            >
              Отправить
            </MTSButton>
            <MTSButton size="M" variant="secondary" onClick={onCancel}>
              Отмена
            </MTSButton>
          </SBtns>
        </form>
      </>
    );
  }
};
