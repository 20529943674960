import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { IMenegementTenantResponse } from "interface/management";
import { invalidateManagmentTenants, useMutationDeleteTenant } from "hooks/api/management";
import { FormEditTenant } from "components/forms/tenant/form-edit-tenant";
import { FormAddUser } from "components/forms/user/form-add-user";
import { ModalNotifyBody } from "components/modal-notify-body/modal-notify-body";
import { MTSModal } from "components/mts-modal/mts-modal";
import { MTSStatus } from "components/mts-status/mts-status";
import { PopupAction } from "components/popup-action/popup-action";
import { TenantDelete } from "./tenant.component";
import { ROUTE_USERS } from "stream-constants/route-constants";
import { CusTypo } from "components/cusTypo/custom-typography";
import { Spacer } from "components/spacer/spacer.component";
import { ContextSettings } from "context/context-settings";
import { ModalAction } from "components/modal-action/modal-action";
import {
  activeTenantDetails,
  convertEmptyValue,
  isActiveTenantCard,
  renderDeployedDetails,
  renderDeployedBar,
} from "../utils";
import { SCard, SHead } from "./styles";

export const MobTenant = ({ data }: { data: IMenegementTenantResponse }) => {
  const navigate = useNavigate();
  const { isMobile } = useContext(ContextSettings);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showAddUser, setAddUser] = useState(false);

  const toggleShowModalDelete = (e) => {
    setShowModalDelete(!showModalDelete);
    if (deleteTenant?.isSuccess) invalidateManagmentTenants();
  };

  const toggleModalEdit = () => setShowModalEdit(!showModalEdit);

  const toggleAddUser = () => setAddUser(!showAddUser);

  const deleteTenant = useMutationDeleteTenant(data.id as unknown as string);

  const navigateToUsers = () =>
    navigate(`/${ROUTE_USERS}`, {
      state: { tenant_id: data.id, tenant_name: data.title },
    });

  const dataPopup = [
    {
      title: "Добавить пользователя",
      onClick: toggleAddUser,
    },
    {
      title: "Редактировать",
      onClick: toggleModalEdit,
    },
    {
      title: "Управление сотрудниками",
      onClick: navigateToUsers,
    },
    {
      title: "Удалить",
      onClick: toggleShowModalDelete,
    },
  ];

  const isActiveTenant = isActiveTenantCard(data.deploy_steps);

  return (
    <>
      <SCard>
        <SHead>
          <section>
            {isActiveTenant ? (
              <div>{MTSStatus(data.is_active ? "Активна" : "Приостановлена")}</div>
            ) : null}
            <div>
              <CusTypo variant="p3_bold">{convertEmptyValue(data.title)}</CusTypo>
            </div>
            {!isActiveTenant ? renderDeployedBar(data) : null}
          </section>
          {isMobile() ? (
            <ModalAction items={dataPopup} />
          ) : (
            <PopupAction items={dataPopup} view="light" />
          )}
        </SHead>
        <Spacer value="24px" />
        <article>
          {isActiveTenant ? (
            <ul>
              {activeTenantDetails(data).map((item) => (
                <li>
                  <CusTypo variant="c1_regular">{item.title}</CusTypo>

                  <CusTypo variant="c1_medium">{item.value}</CusTypo>
                </li>
              ))}
            </ul>
          ) : (
            renderDeployedDetails(data)
          )}
        </article>
      </SCard>
      <MTSModal open={showModalDelete} close={toggleShowModalDelete}>
        {deleteTenant?.isSuccess ? (
          <ModalNotifyBody title="Организация удалена" isSuccess />
        ) : deleteTenant?.isError ? (
          <ModalNotifyBody
            title="Организация не удалена"
            isError
            textDescrioption="Что-то пошло не так, попробуйте удалить организацию еще раз"
          />
        ) : (
          <TenantDelete
            tenantData={data}
            onClick={() => deleteTenant.mutate()}
            onCancel={toggleShowModalDelete}
          />
        )}
      </MTSModal>
      <MTSModal open={showAddUser} close={toggleAddUser}>
        <FormAddUser onCancel={toggleAddUser} withTenantRefresh tenantId={data.id} />
      </MTSModal>
      <MTSModal open={showModalEdit} close={toggleModalEdit}>
        <FormEditTenant onCancel={toggleModalEdit} id={data.id} />
      </MTSModal>
    </>
  );
};
