import styled from "@emotion/styled";
import { device } from "theme";

export const SSection = styled.section`
  padding: 0 20px;
  margin-top: 48px;
  text-align: left;
`;

export const STop = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  gap: 24px;
`;

export const STitle = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const SSettings = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  align-items: center;

  & > div {
    max-width: inherit;
  }

  @media ${device.desktopPlus} {
    justify-content: end;
  }
`;

export const SStatCont = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  overflow-x: visible;
  padding: 0 0 50px 0;

  @media ${device.desktopPlus} {
    gap: 32px;
  }
`;

export const SStat = styled.div`
  flex: 0 0 140px;
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
  padding: 12px 24px 12px 12px;
  border-radius: 8px;
  background: white;
  box-shadow: 1px 5px 22px 0px ${(props) => props.theme.mtsColor.base.rockBlueTransparent};
  cursor: pointer;

  & > span {
    white-space: nowrap;
  }

  @media ${device.desktopPlus} {
    flex: 0 0 auto;
    gap: 32px;
    padding: 32px 40px 32px 32px;
  }
`;
