import styled from "@emotion/styled";

import { MTS_COMPACT, OFFSET_24, c1_styles } from "theme";
import { customScrollHorisontal, customScrollVertical } from "./utils";

export const HEIGHT_EXP = 92;
const MAGIC_OFFSET = "35px";

export const STable = styled.div<{ height?: number; headerHeight?: number; withStub?: boolean }>`
  display: block;
  max-width: 100%;
  margin-top: 26px;
  min-height: 20px;
  overflow: hidden;
  ${(props) =>
    props.height &&
    `
		height:${props.height}px;
	`}

  & div[data-virtuoso-scroller] {
    ${(props) => customScrollHorisontal(props.theme)};
    ${(props) =>
      customScrollVertical({
        theme: props.theme,
        trackStart: props.headerHeight ?? 0,
      })};

    & table {
      width: 100%;
    }
  }

  & .table {
    width: 100%;
    user-select: none;
    ${(props) =>
      props.withStub &&
      `
    	height: 100%;
	`}
  }

  &.sticky {
    overflow-x: auto;
    overflow-y: auto;

    [data-sticky-td] {
      position: sticky;
      z-index: 1 !important;
      &.td {
        background-color: white;
      }
    }

    [data-sticky-last-left-td] {
      left: 0 !important;
    }

    [data-sticky-first-right-td] {
      right: 0 !important;

      &.td {
        border-left: 1px solid ${(props) => props.theme.mtsColor.background.stroke.lightMode};
        border-right: 1px solid ${(props) => props.theme.mtsColor.background.stroke.lightMode};
      }
      &.th {
        & span {
          display: none !important;
        }
      }
    }
  }

  & .thead {
    position: sticky;
    top: 0;
    z-index: 2;
    height: auto;
  }

  & .thead_tr {
    height: 100%;

    &:not(.thead_tr--sheets) {
      width: 100% !important;
      min-width: 100%;
    }

    &--sheets {
      height: 24px !important;
      &:last-of-type {
        border-bottom: 1px solid ${(props) => props.theme.mtsColor.background.stroke.lightMode};

        & .th {
          background: ${(props) => props.theme.mtsColor.background.secondary.lightMode};
        }
      }

      & .th {
        &:not(.td--plug) {
          &:not(.th-group) {
            padding-left: 8px;
          }

          &:not(:last-of-type) {
            border-right: 1px solid ${(props) => props.theme.mtsColor.background.stroke.lightMode};
          }
        }
      }
    }
  }

  & .th {
    display: inline-flex !important;
    width: 100%;
    align-items: center;
    text-align: left;
    background-color: ${(props) => props.theme.mtsColor.background.primary.lightMode};
    overflow: initial;
    cursor: pointer;

    &.th-group {
      & > * {
        width: 100%;
      }
    }
  }

  & .tfoot {
    position: sticky;
    bottom: 0;
    z-index: 2;
    height: 24px;
  }

  & .tfoot_tr {
    height: 100%;

    &:not(.tfoot_tr--sheets) {
      width: 100% !important;
      min-width: 100%;
    }
  }

  & .tf {
    display: inline-flex !important;
    align-items: center;
    width: 100% !important;
    text-align: left;
    font-size: 12px;
    overflow: initial;
    color: ${(props) => props.theme.mtsColor.text.tertiary.lightMode};
    background-color: ${(props) => props.theme.mtsColor.background.secondary.lightMode};

    &:not(.td--plug) {
      padding-left: 8px;
    }
  }

  & .tbody {
    background-color: ${(props) => props.theme.mtsColor.background.primary.lightMode};
  }

  & .tbody_tr {
    height: 52px;
    border-bottom: 1px solid ${(props) => props.theme.mtsColor.controls.inactive.lightMode};

    &:nth-of-type(1) {
      border-bottom: 1px solid ${(props) => props.theme.mtsColor.controls.inactive.lightMode};
    }

    &:not(.tbody_tr--sheets) {
      width: 100% !important;
      min-width: 100%;
    }

    &--sheets {
      height: 32px !important;
      background-color: white;
    }

    &--tab,
    &--tab-expandable {
      border: none !important;
      margin-bottom: 8px;
    }

    &--tab-alternating-rows {
      border: none !important;
      padding: 10px 0;
      align-items: center;
      min-height: 52px;
      height: auto;

      &:nth-child(2n-1) {
        background-color: #fff;
      }
    }

    &--tab-expandable {
      z-index: 1;
      height: ${HEIGHT_EXP}px;
      align-items: center;
      border-radius: 8px;
      background-color: #ffffff;
      box-shadow: 0 6px 3px -3px rgba(0, 0, 0, 0.16);
      transition: height 0.3s ease-out;
      cursor: pointer;
    }
  }

  & .td {
    position: relative;
    height: 100%;
    max-height: 52px;
    display: inline-flex !important;
    align-items: center;
    text-align: left;
    overflow: hidden;

    &:last-of-type {
      overflow: inherit;
    }

    &--plug {
      width: 0 !important;
      padding: 0 !important;

      & * {
        font-size: 0 !important;
        width: 0 !important;
        padding: 0 !important;
      }
    }

    &--tab,
    &--tab-expandable {
      max-height: none !important;
      padding-left: ${OFFSET_24};
      background-color: #fff;
      ${c1_styles as any};
    }
    &--tab-expandable {
      &:first-of-type {
        align-items: flex-start;
        padding-top: ${MAGIC_OFFSET};
        overflow: visible;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:not(:first-of-type) {
        height: ${HEIGHT_EXP}px;
        align-self: flex-start;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
    &--tab-alternating-rows {
      padding-left: ${OFFSET_24};
    }

    &--sheets {
      padding-left: 8px;
      font-size: 12px !important;
      font-family: ${MTS_COMPACT}, sans-serif;

      &:not(.td--plug) {
        &:not(:last-of-type) {
          border-right: 1px solid ${(props) => props.theme.mtsColor.background.stroke.lightMode};
        }
      }
    }
  }
  & .empty {
    height: 52px;
    border-bottom: 1px solid;
    border-bottom-color: ${(props) => props.theme.mtsColor.controls.inactive.lightMode};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const SMobTable = styled.div<{ height?: number; padding?: string }>`
  display: block;
  padding: 0;
  min-height: 20px;
  overflow: hidden;
  ${(props) =>
    props.height &&
    `
		height:${props.height}px;
	`}

  & div[data-virtuoso-scroller] {
    ${(props) => customScrollHorisontal(props.theme)};
    ${(props) => customScrollVertical({ theme: props.theme, trackStart: 16, trackEnd: 0 })};
  }

  & div[data-viewport-type] {
    margin-top: 16px;
    ${(props) => (props.padding ? `padding: ${props.padding}` : "")};
  }

  & .row {
    width: 100% !important;
    min-width: 100%;
    margin-bottom: 16px;
  }

  & .empty {
    height: 52px;
    border-bottom: 1px solid;
    border-bottom-color: ${(props) => props.theme.mtsColor.controls.inactive.lightMode};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
