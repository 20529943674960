import { ITenantResponse } from "interface/management";
import { LIST_ROLES } from "utils/roles";

export const getAdditionalUsers = (data: ITenantResponse | undefined) => {
  if (!data?.users) return [];

  return data?.users
    .filter((item) =>
      item.roles.some((role) => {
        return role.name === LIST_ROLES.OWNER;
      }),
    )
    .map((item) => item.username);
};
