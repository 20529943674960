import styled from "@emotion/styled";
import rgba from "polished/lib/color/rgba";
import { statusToColor } from "theme";

export const SMarker = styled.div<{ status: string }>`
  position: relative;
  width: 48px;
  height: 48px;
  z-index: auto;
  border-radius: 4px;
  background-color: ${(props) => rgba(statusToColor[props.status], 0.08)};

  &:after {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    content: "";
    z-index: 1;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: ${(props) => statusToColor[props.status]};
  }
`;

export const SCont = styled.div<{ status: string; isActive?: boolean }>`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 25px 24px;
  border-radius: 8px;
  background: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
  transition: background-color 0.3s linear;
  cursor: pointer;

  ${(props) =>
    props.isActive &&
    `
	outline: 1px solid ${statusToColor[props.status]};
  `}

  & > ${SMarker} {
    flex: 0 0 auto;
    margin-right: 16px;
  }

  & > span:nth-of-type(1) {
    flex: 0 0 168px;
    margin-right: 32px;
  }

  &:hover {
    background: #f2f3f7;
  }
`;
