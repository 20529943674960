import { useContext, useMemo } from "react";

import { ContextSettings } from "context/context-settings";
import { ITableSortColumn, Table } from "components/table/table.component";
import { MobileTable } from "components/table/mobile-table/mobileTable.component";
import { constructTableData, tableMobStructure, tableStructure } from "./const";

interface ITableProps {
  data: any[];
  sortByColumn?: ITableSortColumn[];
}

export const MiningArchiveTable: React.FC<ITableProps> = ({ data, sortByColumn }) => {
  const { isDesktop } = useContext(ContextSettings);
  const tableData = useMemo(() => constructTableData(data), [data]);
  const tableColumns = useMemo(() => tableStructure, []);
  const mobileColumns = useMemo(() => tableMobStructure, []);

  const defaultSortBy = useMemo(
    () => [
      {
        id: "time_schema",
        desc: true,
      },
    ],
    [],
  );

  return isDesktop() ? (
    <Table columns={tableColumns} data={tableData} sortBy={defaultSortBy} />
  ) : (
    <MobileTable columns={mobileColumns} data={tableData} sortByColumn={sortByColumn} />
  );
};
