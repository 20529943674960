import { PHONE_MASK } from "stream-constants/keys-and-constants";
import { unformatPhone } from "utils/formatters";

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const correctPhone = (value?: string): boolean => {
  if (!value) return false;
  if (value?.length === PHONE_MASK.length) {
    const phoneWM = unformatPhone(value);

    return phoneWM.length === 10;
  } else {
    const phone: string = value?.replace(/\D/g, "");

    return phone.length === 0 || phone.length === 1 || phone.length === 10;
  }
};

const requiredPhone = (value: string): boolean => {
  return value?.length === 10 || value?.length === PHONE_MASK.length;
};

const correctEmail = (value) => emailRegex.test(value ?? "");

const correctUserName = (value: string | undefined): boolean => {
  if (value) {
    const userNameCheck = /^[a-zа-яё\s]+$/iu;

    return userNameCheck.test(value);
  }

  return false;
};

const requiredUserName = (value: string): boolean => {
  return value?.length > 0;
};

const requiredRole = (value: string): boolean => {
  return value?.length > 0;
};

const requiredOrganizationName = (value: string): boolean => {
  return value?.length > 0;
};

const correctInn = (value?: string) => /^\d{10}$/.test(value ?? "");

const correctOrganization = (value?: string) => /^[А-Я]+[^a-zA-Z]+$/.test(value ?? "");

const correctSn = (value?: string) => /^\d{5,}$/.test(value ?? "");

const validationMethod = {
  correctPhone,
  requiredPhone,
  correctEmail,
  correctUserName,
  requiredUserName,
  requiredRole,
  requiredOrganizationName,
  correctOrganization,
  correctInn,
  correctSn,
};

export default validationMethod;
