import { IHttpMethods } from "stream-constants/server-constants";
import { USERS } from "services/urls";
import { fetchData } from "services/services-utils/fetch-data";
import { aboutUsers } from "services/profile/mock-data";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { IAddUserRequest, IUpdateUserRequest, IUserResponse } from "interface/management";
import { individual_user_mock } from "./mock-data";

export const getManagementUsers = async () => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";
  const current_api_prefix = StorageAPI.get(EStorageKeys.API_PREFIX) ?? "";

  if (IS_SERVER_MODE_MOCKED) {
    result = aboutUsers;
  } else {
    result = await fetchData({
      url: USERS(current_api_prefix),
      method: IHttpMethods.GET,
      name: "getManagementUsers",
      emptyResponse: false,
    });
  }

  return result;
};

export const getAddUser = async (data: IAddUserRequest) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";
  const current_api_prefix = StorageAPI.get(EStorageKeys.API_PREFIX) ?? "";

  if (IS_SERVER_MODE_MOCKED) {
    result = "OK";
  } else {
    result = fetchData({
      url: USERS(current_api_prefix),
      method: IHttpMethods.POST,
      name: "formAddUser",
      body: JSON.stringify(data),
      emptyResponse: false,
    });
  }

  return result;
};

export const getUserById = async (id: number | string): Promise<{ data: IUserResponse }> => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";
  const current_api_prefix = StorageAPI.get(EStorageKeys.API_PREFIX) ?? "";

  if (IS_SERVER_MODE_MOCKED) {
    result = individual_user_mock;
  } else {
    result = fetchData({
      url: `${USERS(current_api_prefix)}/${id}`,
      method: IHttpMethods.GET,
      name: "getUserById",
      emptyResponse: false,
    });
  }

  return result;
};

export interface IFuncUpdateUser {
  data: IUpdateUserRequest;
  id: number | string;
}

export const deleteUserById = async (id: number | string) => {
  const current_api_prefix = StorageAPI.get(EStorageKeys.API_PREFIX) ?? "";
  let result;

  result = fetchData({
    url: `${USERS(current_api_prefix)}/${id}`,
    method: IHttpMethods.DELETE,
    name: "deleteUserById",
    emptyResponse: false,
  });

  return result;
};

export const updateUser = async ({ data, id }: IFuncUpdateUser) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";
  const current_api_prefix = StorageAPI.get(EStorageKeys.API_PREFIX) ?? "";

  if (IS_SERVER_MODE_MOCKED) {
    result = "OK";
  } else {
    result = fetchData({
      url: `${USERS(current_api_prefix)}/${id}`,
      method: IHttpMethods.PATCH,
      name: "updateUser",
      body: JSON.stringify(data),
      emptyResponse: false,
    });
  }

  return result;
};

export const getUserTenants = async () => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = [];
  } else {
    result = fetchData({
      url: `${USERS("")}/tenants/list`,
      method: IHttpMethods.GET,
      name: "getUserTenants",
      emptyResponse: false,
    });
  }

  return result;
};
