import { useEffect, useState } from "react";
import { throttle } from "lodash";
import { breakpoints } from "theme";

interface IUseBreakpoint {
  isMobile: () => boolean;
  isTablet: () => boolean;
  isDesktop: () => boolean;
  getWidth: () => number;
}

const getDeviceWidth = (val: number): string => {
  switch (true) {
    case val >= breakpoints.desktop:
      return "desktop";
    case val >= breakpoints.tablet:
      return "tablet";
    default:
      return "mobile";
  }
};

function useBreakpoint(): IUseBreakpoint {
  const [device, setDevice] = useState(() => getDeviceWidth(window.innerWidth));
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const calcWindowWidth = throttle(() => {
      setDevice(getDeviceWidth(window.innerWidth));
      setWidth(window.innerWidth);
    }, 250);

    window.addEventListener("resize", calcWindowWidth);

    return (): void => {
      window.removeEventListener("resize", calcWindowWidth);
    };
  }, []);

  return {
    isMobile: (): boolean => device === "mobile",
    isTablet: (): boolean => device === "tablet",
    isDesktop: (): boolean => device === "desktop",
    getWidth: (): number => width,
  };
}

export { useBreakpoint };
