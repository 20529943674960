import { useMutatDelFavlist } from "hooks/api/analytics";
import { MTSButton } from "components/mts-button/mts-button";
import { Spacer } from "components/spacer/spacer.component";
import Icon from "components/icons";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SControls, SStack } from "./styles";

interface IProp {
  onCancel: () => void;
  id: number | string;
}

export const DeleteFavForm: React.FC<IProp> = ({ id, onCancel }) => {
  const { mutate } = useMutatDelFavlist(id);

  return (
    <SStack>
      <Icon.Warn sx={{ width: 44, height: 44 }} />
      <Spacer value="8px" />

      <CusTypo variant="h4_medium" font="comp">
        Вы действительно хотите удалить список?
      </CusTypo>
      <Spacer value="24px" />
      <SControls>
        <MTSButton
          size="M"
          variant="primary"
          type="submit"
          onClick={() => {
            mutate(undefined, {
              onSuccess: () => onCancel(),
            });
          }}
        >
          Удалить
        </MTSButton>
        <MTSButton size="M" variant="secondary" onClick={onCancel}>
          Отмена
        </MTSButton>
      </SControls>
    </SStack>
  );
};
