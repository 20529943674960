import { FC } from "react";
import theme from "theme";
import format from "date-fns/format";
import { ru } from "date-fns/locale";
import { NumericFormat } from "react-number-format";

import { IFavs } from "pages/analytics/const";
import { CusTypo } from "components/cusTypo/custom-typography";
import Icon from "components/icons";
import { Spacer } from "components/spacer/spacer.component";
import { SBlue, SFavInfo, SGrey, SInfo } from "./styles";
import { Loader } from "components/loader/loader";

interface IProp {
  dates?: { date_norm?: string | null; date_scores?: string | null };
  favs: IFavs;
  isShowFavs: boolean;
}

export const HeaderInfo: FC<IProp> = ({ dates, favs, isShowFavs }) => {
  return isShowFavs ? (
    <SFavInfo>
      <CusTypo variant="p4_regular" font="comp">
        {favs.title}
      </CusTypo>
      {favs.comment ? (
        <CusTypo
          variant="c1_regular"
          font="comp"
          styles={{ color: theme.mtsColor.text.secondary.lightMode }}
        >
          {favs.comment}
        </CusTypo>
      ) : null}
    </SFavInfo>
  ) : dates?.date_norm != null && dates?.date_scores != null ? (
    <SFavInfo>
      <CusTypo variant="p4_regular" font="comp">
        Дата нормализации{" "}
        {format(new Date(dates.date_norm), "dd.MM.yyyy", {
          locale: ru,
        })}
      </CusTypo>
      <CusTypo
        variant="c1_regular"
        font="comp"
        styles={{ color: theme.mtsColor.text.secondary.lightMode }}
      >
        Дата обновления Bigdata{" "}
        {format(new Date(dates.date_scores), "dd.MM.yyyy", {
          locale: ru,
        })}
      </CusTypo>
    </SFavInfo>
  ) : null;
};

export const InfoAutoScroll = ({ cur, total, handleClick, isLoading, isEndReached }) => {
  return (
    <>
      <Spacer value="8px" />
      <SInfo>
        {isEndReached && cur ? (
          <Icon.SuccessShaped sx={{ width: 12, height: 12, margin: "0 8px 0 0" }} />
        ) : (
          <Icon.Warn sx={{ width: 12, height: 12, margin: "0 8px 0 0" }} />
        )}
        <SGrey variant="c1_regular" font="comp" styles={{ margin: "0 16px 0 0" }}>
          <NumericFormat displayType="text" value={cur} thousandSeparator={" "} /> /{" "}
          <NumericFormat displayType="text" value={total} thousandSeparator={" "} />
        </SGrey>
        <SBlue variant="c1_regular" font="comp" onClick={handleClick}>
          {isLoading ? <Loader size="S_16" /> : isEndReached ? null : "загрузить весь список"}
        </SBlue>
      </SInfo>
    </>
  );
};
