import { IHttpMethods } from "stream-constants/server-constants";
import { GET_EMPLOYEES, GET_MAP_MARKERS, GET_MINERS, TASKS } from "services/urls";
import { fetchData } from "services/services-utils/fetch-data";

import { base, dataEmployees, mock_miners } from "./mock-data";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { ICreateCheckTaskRequest, IDeparturesRequest } from "interface/map";

export const getDeparturesMarkers = async (data: IDeparturesRequest) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = base;
  } else {
    result = await fetchData({
      url: GET_MAP_MARKERS,
      method: IHttpMethods.POST,
      body: JSON.stringify(data),
      name: "getMapMarkers",
      emptyResponse: false,
    });
  }

  return result;
};

export const getEmployees = async () => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = dataEmployees;
  } else {
    result = await fetchData({
      url: GET_EMPLOYEES,
      method: IHttpMethods.GET,
      name: "getEmployees",
      emptyResponse: false,
    });
  }

  return result;
};

export const createCheckTask = async (data: ICreateCheckTaskRequest) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = "OK";
  } else {
    result = fetchData({
      url: TASKS,
      method: IHttpMethods.POST,
      name: "createCheckTask",
      body: JSON.stringify(data),
      emptyResponse: false,
      responseAbordTimeOut: false,
    });
  }

  return result;
};

export const getMiners = async (data: IDeparturesRequest) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = mock_miners;
  } else {
    result = await fetchData({
      url: GET_MINERS,
      method: IHttpMethods.POST,
      name: "getMapMiners",
      emptyResponse: false,
      body: JSON.stringify(data),
    });
  }

  return result;
};

export const deleteDeparture = async (data: { idPP: number[] }) => {
  return await fetchData({
    url: `${TASKS}`,
    method: IHttpMethods.DELETE,
    name: "deleteDeparture",
    emptyResponse: false,
    responseAbordTimeOut: false,
    body: JSON.stringify(data),
  });
};
