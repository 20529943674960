import styled from "@emotion/styled";

export const SWrap = styled.div`
  margin: 0 auto;
  max-width: 1160px;
  padding: 64px 0 94px 0;
  display: grid;
  grid-template-columns: 664px 1fr;
  grid-auto-flow: row;
  align-items: flex-start;
  column-gap: 64px;
  text-align: left;

  & .prewrap {
    white-space: pre-wrap;
    color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
  }

  & section {
    display: grid;
    align-items: end;
    grid-template-columns: 264px 264px auto;
    grid-auto-flow: row;
    column-gap: 24px;
    padding: 24px 0;

    &.equal-width {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &:not(:last-of-type) {
      padding: 24px 0 28px 0;
      border-bottom: 1px solid ${(props) => props.theme.mtsColor.background.stroke.lightMode};
    }
  }

  & .btn-submit {
    width: 100%;
    margin: 0 auto;
  }
`;

export const SLabel = styled.div`
  display: flex;
  flex-flow: row nowrap;
  height: 20px;
  margin-bottom: 12px;

  & > span {
    min-width: 0;
    min-height: 0;
    flex: 0 0 auto;
    display: inline-block;
    font-size: 14px;
    color: ${(props) => props.theme.mtsColor.text.tertiary.lightMode};
    white-space: nowrap;
    user-select: none;
  }
`;

export const SCard = styled.div`
  padding: 48px 40px;
  background-color: #ffffff;
  border-radius: 8px;
`;

export const SRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;
