import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { MTS_TEXT, device } from "theme";

import { tool_base } from "components/table/table-filter/styles";
import { customScrollVertical } from "components/table/utils";
import { SControls } from "components/forms/departures/styles";
import check from "./markers_svgs/check.svg";
import { scoreToColor } from "./const";
import "leaflet/dist/leaflet.css";

export const SCont = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;

  & .leaflet-container {
    font-family: ${MTS_TEXT}, sans-serif;
  }
`;

export const SLeaflet = styled.div`
  width: 100%;
  height: 100%;

  & .leaflet-control-attribution {
    & svg {
      width: 0;
      height: 0;
    }
  }

  & .leaflet-popup-content-wrapper {
    background: transparent !important;
    box-shadow: none !important;

    & .leaflet-popup-content {
      margin: 0;
      padding: 0;
    }
  }

  & .leaflet-popup-close-button {
    font-size: 21px !important;
    top: 9px !important;
    right: 11px !important;
  }

  & .leaflet-marker-icon.marker__group {
    background: #00724d;
    width: 48px !important;
    height: 32px !important;
    border-radius: 4px;
    font-size: 17px;
    line-height: 1.8;
    font-weight: 500;
    color: white;
    &:after {
      position: absolute;
      content: "";
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0 12px 16px 12px;
      border-color: transparent transparent #00724d transparent;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%) rotate(180deg);
    }
  }

  & .marker--default {
    width: 16px !important;
    height: 16px !important;
    border-radius: 50%;
    background: #626c77;
    border: 2px solid white;
    outline: 2px solid #626c77;
    box-sizing: content-box;
  }
  & .dep--blue {
    background: #007cff;
    outline-color: #007cff;
  }
  & .dep--green {
    background-color: #4caf50;
    outline-color: #4caf50;
    background-image: url(${check});
    background-size: 11px 9px;
    background-repeat: no-repeat;
    background-position: center center;
  }
  & .ano--yellow {
    background-color: #ff8f00;
    outline-color: #ff8f00;
  }
  & .ano--green {
    background-color: #4caf50;
    outline-color: #4caf50;
  }
  & .ano--blue {
    background-color: #035cc3;
    outline-color: #035cc3;
  }
  & .ano--red {
    background-color: #e53935;
    outline-color: #e53935;
  }
  & .ano--grey {
    background-color: #616161;
    outline-color: #616161;
  }
  & .ano--black {
    background-color: #000000;
    outline-color: #000000;
  }
  & .ano--plum {
    background-color: #6d2d79;
    outline-color: #6d2d79;
  }

  & .marker--selected {
    background-color: #ff0032 !important;
    outline-color: #ff0032;
    &:after {
      border-color: transparent transparent #ff0032 transparent !important;
    }
  }
`;

export const SSearch = styled.div`
  width: 100%;
  max-width: 95%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: absolute;
  top: 16px;
  left: 22px;
  z-index: 401;

  & .searchField {
    flex: 0 1 90%;
    margin-right: 16px;
  }

  & ul {
    position: absolute;
    top: 46px;
    width: 100%;
    min-height: 10px;
    height: auto;
    max-height: 560px;
    overflow: auto;
    ${(props) =>
      customScrollVertical({
        theme: props.theme,
        trackStart: 8,
        trackEnd: 8,
      })};
    border-radius: 8px;
    background-color: white;
    text-align: left;

    & li {
      padding: 12px 8px;
      cursor: pointer;

      &:hover {
        background-color: #cecece;
      }
    }
  }

  & button {
    flex: 0 0 auto;
  }

  @media ${device.desktopPlus} {
    max-width: 986px;
    margin-right: 6%;
    top: 0;
    left: 0;
    position: relative;
  }
`;

export const SPopup = styled.div`
  position: relative;
  width: 335px;
  min-height: 260px;
  height: auto;
  padding: 16px 16px 32px 16px;
  background-color: white;
  text-align: left;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08), 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;

  & .popup__close {
    position: absolute;
    top: 11px;
    right: 11px;
    cursor: pointer;
  }

  & button {
    width: 100%;
    margin-top: auto;
  }
`;

export const SMinerPopup = styled(SPopup)`
  min-height: 184px;
  padding: 16px;
`;

export const STools = styled.div`
  position: absolute;
  top: 68px;
  right: 22px;
  display: flex;
  flex-flow: column nowrap;
  align-items: end;
  gap: 16px;
  width: auto;
  max-width: 100%;
  z-index: 403;

  @media ${device.desktopPlus} {
    top: 48px;
    right: 32px;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    max-width: 90%;
    width: 100%;
  }
`;

export const SToolBtn = styled.div<{ isOn?: boolean }>`
  ${(props) => tool_base(props.theme)};
  position: relative;
  width: 40px;
  height: 40px;
  background: white;
  & svg {
    width: 20px;
    height: 20px;
  }

  @media ${device.desktopPlus} {
    width: 44px;
    height: 44px;
    & svg {
      width: 24px;
      height: 24px;
    }
  }

  ${(props) =>
    props.isOn &&
    `
	background: black;
	& svg {
		path {
			fill: white;
		}
	}
  `}
`;

export const SLayersBase = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  height: auto;
  padding: 18px 24px;
  background: white;

  & > div {
    &:not(:last-of-type) {
      margin-bottom: 24px;
    }
  }
`;

export const SLayersControl = styled(SLayersBase)`
  position: absolute;
  right: 0;
  top: calc(100% + 4px);
  width: 268px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12), 0px 12px 20px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
`;

export const forceCss = css`
  width: 40px;
  height: 40px;
  background-color: white;

  & > .MuiSvgIcon-root {
    fill: black;
  }

  @media ${device.desktopPlus} {
    width: 44px;
    height: 44px;
  }
`;

export const SRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > span {
    flex: 0 0 auto;
  }
`;

export const SRowBorder = styled(SRow)`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${(props) => props.theme.mtsColor.background.secondary.lightMode};
  }

  @media ${device.desktopPlus} {
    border: none;
  }
`;

export const SRowEnd = styled(SRow)`
  justify-content: flex-end;
`;

export const ColorCircle = styled.div<{ score: number }>`
  width: 12px;
  height: 12px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${(props) => scoreToColor(props.score)};
`;

export const SLPanWrap = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  padding: 0 20px 36px 20px;
  ${(props) =>
    customScrollVertical({
      theme: props.theme,
      trackStart: 0,
    })};

  @media ${device.desktopPlus} {
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;

export const SFilEl = styled.div<{ isSelected?: boolean }>`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  background: ${(props) =>
    props.isSelected
      ? props.theme.mtsColor.background.inverted.lightMode
      : props.theme.mtsColor.background.secondary.lightMode};
  border-radius: 16px;
  min-height: 0;
  height: auto;
  max-height: 24px;
  cursor: pointer;
`;

export const SPanFilCont = styled.div`
  text-align: left;
  user-select: none;

  & > section {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    @media ${device.desktopPlus} {
      justify-content: flex-start;
    }
  }
`;

export const SFilCont = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
`;

export const SStack = styled(SControls)`
  align-items: center;
  text-align: center;

  & button {
    width: 100%;
  }
`;

export const SBord = styled.div`
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);

  @media ${device.desktopPlus} {
    border-radius: 0;
    padding: 24px 0;
    border-bottom: 1px solid ${(props) => props.theme.mtsColor.background.stroke.lightMode};
    box-shadow: none;
  }
`;

export const SMinListCont = styled.article`
  width: 100%;
  padding: 0 0 16px 0;
  border-bottom: 1px solid ${(props) => props.theme.mtsColor.background.hover.lightMode};
`;

export const SZoomPos = styled.div`
  position: absolute;
  right: 22px;
  bottom: 172px;
  z-index: 402;
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
  width: auto;

  @media ${device.desktopPlus} {
    right: 32px;
  }
`;
