import { useEffect, useState, memo } from "react";
import { DemoForm } from "components/demo/demoForm/demoForm.component";
import { DemoGraph } from "components/demo/demoGraph/demoGraph.component";
import { DemoAccepted, DemoRejected } from "components/demo/demoAccepted/demoAccepted.component";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { EDemoStatus } from "interface/common";
import { useDemoScore } from "hooks/api/management";
import { Loader } from "components/loader/loader";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SWrap } from "components/demo/demoAccepted/styles";

export const DemoAnalyticsPage = memo(() => {
  const [step, setStep] = useState<EDemoStatus>(EDemoStatus.START);

  const demo = useDemoScore(step !== EDemoStatus.START);

  const handleRetry = () => {
    setStep(EDemoStatus.START);
    StorageAPI.save(EStorageKeys.DEMO_STATUS, EDemoStatus.START);
  };

  const handleSubmitToReview = () => {
    setStep(EDemoStatus.ONREVIEW);
    StorageAPI.save(EStorageKeys.DEMO_STATUS, EDemoStatus.ONREVIEW);
  };

  useEffect(() => {
    if (demo?.isSuccess) {
      if (demo.data?.isModerating) StorageAPI.save(EStorageKeys.DEMO_STATUS, EDemoStatus.ONREVIEW);
      else if (demo.data?.isFailed) StorageAPI.save(EStorageKeys.DEMO_STATUS, EDemoStatus.REJECTED);
      else if (!demo.data?.isFailed && !demo.data?.isModerating)
        StorageAPI.save(EStorageKeys.DEMO_STATUS, EDemoStatus.SUCC);
    }
  }, [demo?.isSuccess, demo.data?.isModerating, demo.data?.isFailed]);

  useEffect(() => {
    const storageVal = StorageAPI.get(EStorageKeys.DEMO_STATUS) as EDemoStatus | undefined;
    if (storageVal && storageVal in EDemoStatus) setStep(storageVal);
  }, [demo.data?.isModerating, demo.data?.isFailed]);

  if (step === EDemoStatus.START) return <DemoForm callback={handleSubmitToReview} />;

  if (demo.isLoading) return <Loader isFullPage />;

  if (demo.isSuccess)
    return step === EDemoStatus.SUCC ? (
      <DemoGraph scoreData={demo?.data?.results} />
    ) : step === EDemoStatus.ONREVIEW ? (
      <DemoAccepted />
    ) : step === EDemoStatus.REJECTED ? (
      <DemoRejected retry={handleRetry} />
    ) : null;

  if (demo.isError)
    return (
      <SWrap>
        <CusTypo variant="h2_bold">Произошла ошибка запроса данных с сервера</CusTypo>
      </SWrap>
    );

  return null;
});
