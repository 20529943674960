import { useContext, useMemo } from "react";

import { ContextSettings } from "context/context-settings";
import { Table } from "components/table/table.component";
import { IAskueMeter } from "interface/askue";
import { constructTypesColumns, typesColumns } from "./utils";

interface IProp {
  rawData: IAskueMeter[];
}
export const TypesTable: React.FC<IProp> = ({ rawData }) => {
  const { isMobile } = useContext(ContextSettings);
  const tableData = useMemo(() => rawData, [rawData]);
  const columns = useMemo(
    () =>
      constructTypesColumns({
        rawData,
        columns: typesColumns,
      }),

    [rawData],
  );

  return <Table columns={columns} data={tableData} height={isMobile() ? 280 : 376} />;
};
