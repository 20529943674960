import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form";
import { isEmpty } from "lodash";

import { Checkbox } from "components/checkbox/checkbox";
import { MTSInput } from "components/mts-input";
import { Radio } from "components/radio/radio.component";
import { Spacer } from "components/spacer/spacer.component";
import {
  defaultValues,
  stepOne,
  pageHead,
  pageDescr,
  stepTwo,
  stepThree,
  tooltipIdentif,
  tooltipUnits,
  tooltipRecord,
  tooltipDiskret,
  tooltipConsumer,
  tooltipTarif,
  schema,
  tooltipAddress,
  tooltipFraud,
} from "./validation-schema";
import { MTSButton } from "components/mts-button/mts-button";
import Icon from "components/icons";
import { LabelTooltip } from "./label.component";
import { getSampleXlsx } from "services/demo";
import { useDemoUploadSample, useDemoUploadTable } from "hooks/api/management";
import { Preview } from "./preview.component";
import { UploadFileBtn } from "components/upload-file-btn/upload-file-btn";
import { IDemoFileUploadResponse } from "interface/management";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SWrap } from "./styles";

export const DemoForm = ({ callback }) => {
  const formInstance = useForm({
    defaultValues: defaultValues as any,
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const consumersField = useFieldArray({
    control: formInstance.control,
    name: "consumers",
  });

  const tarifField = useFieldArray({
    control: formInstance.control,
    name: "tarif",
  });

  const watchFields = useWatch({
    control: formInstance.control,
    name: [
      "address",
      "consumers",
      "tarif",
      "fraud",
      "identification",
      "units",
      "record",
      "diskret",
    ],
  });

  const mutation = useDemoUploadSample();

  const handleSuccUpload = (data: IDemoFileUploadResponse[]) => {
    const { cnt, period_from, period_to } = data[0];
    formInstance.setValue("uploaded_period", [period_from, period_to]);
    formInstance.setValue("uploaded_quantity", cnt);
    formInstance.trigger(["uploaded_period", "uploaded_quantity"]);
  };

  const onConsumersChange = (val) => {
    const searchableElem =
      consumersField.fields.find((item: any) => item.request_id === val) ?? undefined;
    const isExists = searchableElem != null;
    const targetId = consumersField.fields.indexOf(searchableElem as any);
    !isExists
      ? consumersField.append({
          request_id: val,
        })
      : consumersField.remove(targetId);
    formInstance.trigger("consumers");
  };

  const onTarifChange = (val) => {
    const searchableElem =
      tarifField.fields.find((item: any) => item.request_id === val) ?? undefined;
    const isExists = searchableElem != null;
    const targetId = tarifField.fields.indexOf(searchableElem as any);
    !isExists
      ? tarifField.append({
          request_id: val,
        })
      : tarifField.remove(targetId);
    formInstance.trigger("tarif");
  };

  const onSubmit = (sbmt) => {
    mutation.mutate(
      {
        id_nm: sbmt.identification,
        measure_type: sbmt.units,
        val_type: sbmt.record,
        discr_type: sbmt.diskret,
        pu_owner_type: consumersField.fields?.map((a: any) => a.request_id),
        tariff_type: tarifField.fields?.map((a: any) => a.request_id),
        user_comment: sbmt.comment,
        address_flg: sbmt.address === "yes",
        fraud_flg: sbmt.fraud === "yes",
      },
      {
        onSuccess: () => {
          callback();
        },
      },
    );
  };

  const isDownloadAvail = watchFields.every((a) => (Array.isArray(a) ? !isEmpty(a) : a));

  return (
    <SWrap>
      <div>
        <CusTypo variant="h2_bold" font="wide">
          {pageHead}
        </CusTypo>
        <Spacer value="24px" />

        <CusTypo variant="p4_regular" className="prewrap">
          {pageDescr}
        </CusTypo>
        <Spacer value="54px" />

        <CusTypo variant="p4_medium" className="prewrap">
          {stepOne}
        </CusTypo>
        <section>
          <Controller
            name="address"
            control={formInstance.control}
            defaultValue={false}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <Radio
                  id="address_yes"
                  name="address_yes"
                  value="yes"
                  checked={value === "yes"}
                  onChange={onChange}
                  text="Указывать"
                  label="Адрес установки приборов учета"
                  labelInfo={tooltipAddress}
                  hasError={error != null && !value}
                />
                <Radio
                  id="address_no"
                  name="address_no"
                  value="no"
                  checked={value === "no"}
                  onChange={onChange}
                  text="Не указывать"
                  hasError={error != null && !value}
                />
              </>
            )}
          />
        </section>
        <section>
          <Controller
            name="identification"
            control={formInstance.control}
            defaultValue={false}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <Radio
                  id="identification_serial"
                  name="identification_serial"
                  value="Серийный номер"
                  checked={value === "Серийный номер"}
                  onChange={onChange}
                  text="Серийный номер"
                  label="Идентификация абонента"
                  labelInfo={tooltipIdentif}
                  hasError={error != null && !value}
                />
                <Radio
                  id="identification_id"
                  name="identification_id"
                  value="ID"
                  checked={value === "ID"}
                  onChange={onChange}
                  text="ID"
                  hasError={error != null && !value}
                />
              </>
            )}
          />
        </section>
        <section>
          <Controller
            name="units"
            control={formInstance.control}
            defaultValue={false}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <Radio
                  id="units_vh"
                  name="units_vh"
                  value="Вт*ч"
                  checked={value === "Вт*ч"}
                  onChange={onChange}
                  text="Вт*ч"
                  label="Единица измерения электроэнергии"
                  labelInfo={tooltipUnits}
                  hasError={error != null && !value}
                />
                <Radio
                  id="units_kvh"
                  name="units_kvh"
                  value="кВт*ч"
                  checked={value === "кВт*ч"}
                  onChange={onChange}
                  text="кВт*ч"
                  hasError={error != null && !value}
                />
              </>
            )}
          />
        </section>
        <section>
          <Controller
            name="record"
            control={formInstance.control}
            defaultValue={false}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <Radio
                  id="record_accum"
                  name="record_accum"
                  value="Накопительные"
                  checked={value === "Накопительные"}
                  onChange={onChange}
                  text="Накопительные"
                  label="Тип записи электроэнергии"
                  labelInfo={tooltipRecord}
                  hasError={error != null && !value}
                />
                <Radio
                  id="record_interval"
                  name="record_interval"
                  value="Интервальные"
                  checked={value === "Интервальные"}
                  onChange={onChange}
                  text="Интервальные"
                  hasError={error != null && !value}
                />
              </>
            )}
          />
        </section>
        <section>
          <Controller
            name="diskret"
            control={formInstance.control}
            defaultValue={false}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <Radio
                  id="diskret_hour"
                  name="diskret_hour"
                  value="Часовые"
                  checked={value === "Часовые"}
                  onChange={onChange}
                  text="Часовые"
                  label="Дискретизация"
                  labelInfo={tooltipDiskret}
                  hasError={error != null && !value}
                />
                <Radio
                  id="diskret_day"
                  name="diskret_day"
                  value="Суточные"
                  checked={value === "Суточные"}
                  onChange={onChange}
                  text="Суточные"
                  hasError={error != null && !value}
                />
              </>
            )}
          />
        </section>
        <section className="equal-width">
          <Controller
            name="consumers"
            control={formInstance.control}
            render={() => {
              return (
                <>
                  <div>
                    <LabelTooltip tooltip={tooltipConsumer} label="Потребители" />
                    <Checkbox
                      value="fizik"
                      checked={
                        consumersField.fields.find((o: any) => o.request_id === "fizik") != null
                      }
                      onChange={onConsumersChange}
                      label="Физические лица"
                      color="red"
                      hasError={formInstance.formState.errors["consumers"] != null}
                    />
                  </div>
                  <Checkbox
                    value="urik"
                    checked={
                      consumersField.fields.find((o: any) => o.request_id === "urik") != null
                    }
                    onChange={onConsumersChange}
                    label="Юридические лица"
                    color="red"
                    hasError={formInstance.formState.errors["consumers"] != null}
                  />
                  <Checkbox
                    value="tech"
                    checked={
                      consumersField.fields.find((o: any) => o.request_id === "tech") != null
                    }
                    onChange={onConsumersChange}
                    label="Технический учёт"
                    color="red"
                    hasError={formInstance.formState.errors["consumers"] != null}
                  />
                </>
              );
            }}
          />
        </section>
        <section>
          <Controller
            name="fraud"
            control={formInstance.control}
            defaultValue={false}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <Radio
                  id="fraud_yes"
                  name="fraud_yes"
                  value="yes"
                  checked={value === "yes"}
                  onChange={onChange}
                  text="Да"
                  label="Есть ли в данных ранее обнаруженные взломанные приборы учета?"
                  labelInfo={tooltipFraud}
                  hasError={error != null && !value}
                />
                <Radio
                  id="fraud_no"
                  name="fraud_no"
                  value="no"
                  checked={value === "no"}
                  onChange={onChange}
                  text="Нет"
                  hasError={error != null && !value}
                />
              </>
            )}
          />
        </section>
        <section>
          <Controller
            name="tarif"
            control={formInstance.control}
            render={() => {
              return (
                <>
                  <div>
                    <LabelTooltip tooltip={tooltipTarif} label="Тариф" />
                    <Checkbox
                      value="mono"
                      checked={tarifField.fields.find((o: any) => o.request_id === "mono") != null}
                      onChange={onTarifChange}
                      label="Однотарифный"
                      color="red"
                      hasError={formInstance.formState.errors["tarif"] != null}
                    />
                  </div>
                  <Checkbox
                    value="multi"
                    checked={tarifField.fields.find((o: any) => o.request_id === "multi") != null}
                    onChange={onTarifChange}
                    label="Двухтарифный"
                    color="red"
                    hasError={formInstance.formState.errors["tarif"] != null}
                  />
                  <Checkbox
                    value="other"
                    checked={tarifField.fields.find((o: any) => o.request_id === "other") != null}
                    onChange={onTarifChange}
                    label="Другое"
                    color="red"
                    hasError={formInstance.formState.errors["tarif"] != null}
                  />
                </>
              );
            }}
          />
        </section>
        <Spacer value="8px" />
        <MTSInput
          placeholder="Оставьте комментарий"
          type="textarea"
          {...formInstance.register("comment", {
            required: true,
          })}
          control={formInstance.control}
        />
        <Spacer value="48px" />

        <CusTypo variant="p4_medium" className="prewrap">
          {stepTwo}
        </CusTypo>
        <Spacer value="24px" />
        <MTSButton
          size="S"
          variant="secondary"
          onClick={() =>
            getSampleXlsx(
              formInstance.getValues("address") === "yes",
              consumersField.fields?.length,
            )
          }
          icon={<Icon.Download sx={{ width: 12, height: 12 }} />}
          disabled={!isDownloadAvail}
        >
          Скачать шаблон
        </MTSButton>
        <Spacer value="48px" />

        <CusTypo variant="p4_medium" className="prewrap">
          {stepThree}
        </CusTypo>
        <Spacer value="24px" />
        <UploadFileBtn<IDemoFileUploadResponse[]>
          textBtn="Загрузить файл"
          hook={useDemoUploadTable}
          callback={handleSuccUpload}
        />
      </div>
      <FormProvider {...formInstance}>
        <Preview onSubmit={onSubmit} />
      </FormProvider>
    </SWrap>
  );
};
