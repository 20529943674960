import { useContext } from "react";

import { ContextSettings } from "context/context-settings";
import { LIST_ROLES } from "utils/roles";
import Icon from "components/icons";
import { SidebarAccordion } from "components/sidebar-accordion/sidebar-accordion";
import { TenantSwitch } from "components/header/components/tenant-switch/tenant-switch";
import { SidebarFooter } from "./sidebar";
import { SMobBody, SMobPos, SSwitch } from "./styles";

export const MobSidebar = ({ isOpen = false, toggleOpen, open, close, logout }) => {
  const { roles } = useContext(ContextSettings);
  const isAdminOnly = roles.length === 1 && roles.includes(LIST_ROLES.ADMIN);

  return (
    <SMobPos>
      {isOpen ? (
        <Icon.Close sx={{ width: 32, height: 32 }} onClick={toggleOpen} />
      ) : (
        <Icon.MobileMenuBtn onClick={toggleOpen} />
      )}
      <SMobBody isOpen={isOpen}>
        {isAdminOnly ? null : (
          <SSwitch>
            <TenantSwitch />
          </SSwitch>
        )}
        <SidebarAccordion isOpenSidebar={isOpen} open={open} close={close} />
        <SidebarFooter isOpen={isOpen} toggleOpen={toggleOpen} logout={logout} withHide={false} />
      </SMobBody>
    </SMobPos>
  );
};
