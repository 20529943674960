import React, { useCallback, useEffect, useLayoutEffect, useState, memo } from "react";
import { Virtuoso } from "react-virtuoso";
import {
  Column,
  SortingRule,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
  useBlockLayout,
  useRowSelect,
  Row,
} from "react-table";
import { isEmpty, toString } from "lodash";
import { ITableFilterClm, ITableSortColumn, magic_offset } from "../table.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { NotifyBlock } from "components/notification-block/notification-block";
import { SMobTable } from "../styles";

/* Описание всех пропсов смотри в table.component */
interface IProp {
  data: {
    [key: string]: any;
  }[];
  columns: Column<any>[];
  sortBy?: SortingRule<ITableSortColumn>[];
  initSelRow?: Record<string, boolean>;
  onRowClick?: (clickData: any) => void;
  view?: "sheets" | "tabs" | "expandable";
  filterByColumn?: ITableFilterClm[];
  sortByColumn?: SortingRule<ITableSortColumn>[];
  globalFilter?: string;
  globalFilterFunction?: (
    rows: Row<object>[],
    columnIds: string[],
    filterValue: any,
  ) => Row<object>[];
  onRowsChange?: Function;
  height?: number;
  incViewportBy?: number;
  withStub?: boolean;
  padding?: string;
}

export const MobileTable: React.FC<IProp> = memo(
  ({
    data,
    columns,
    sortBy,
    onRowClick,
    filterByColumn,
    globalFilter,
    onRowsChange,
    height,
    initSelRow,
    incViewportBy,
    sortByColumn,
    withStub,
    globalFilterFunction,
    padding,
  }) => {
    const {
      rows,
      setGlobalFilter,
      setAllFilters,
      setSortBy,
      getTableBodyProps,
      prepareRow,
      page,
      toggleAllRowsSelected,
      setPageSize,
    } = useTable(
      {
        columns,
        data,
        initialState: {
          pageIndex: 0,
          pageSize: 100,
          selectedRowIds: initSelRow ?? {},
          ...(sortBy ?? {}),
        },
        globalFilter: globalFilterFunction,
        autoResetPage: false,
        autoResetSortBy: false,
        autoResetSelectedRows: false,
        autoResetGlobalFilter: false,
        autoResetFilters: false,
      },
      useBlockLayout,
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,
      useRowSelect,
    );

    const [tableHeight, setHeight] = useState(500);

    const parentRef = React.useRef<any>();
    const virtRef = React.useRef<any>();

    const handleRowClick = (preparedRow: Row<any>) => {
      onRowClick?.(preparedRow.original.id);
    };

    const handleRowChange = useCallback((a) => {
      onRowsChange?.(a);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
      if (window && parentRef) {
        const topOffset = parentRef?.current?.getBoundingClientRect().top ?? 0;
        setHeight(+(window.innerHeight - topOffset - magic_offset).toFixed());
      }
    }, []);

    useEffect(() => {
      if (data.length > 0) setPageSize(data?.length);
    }, [data.length, setPageSize]);

    useEffect(() => {
      if (filterByColumn) {
        toggleAllRowsSelected(false);
        const newArr = filterByColumn
          ?.reduce((sum, cur) => {
            if (!isEmpty(cur)) sum.push(cur);
            return sum;
          }, [] as any)
          .map((a) => ({ id: a.column, value: a.value }));
        setAllFilters(newArr);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(filterByColumn), setAllFilters]);

    useEffect(() => {
      if (sortByColumn) {
        setSortBy(sortByColumn);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(sortByColumn), setSortBy]);

    useEffect(() => {
      handleRowChange?.(rows.map((a) => a.original));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toString(rows), handleRowChange]);

    useEffect(() => {
      setGlobalFilter(globalFilter);
    }, [globalFilter, setGlobalFilter]);

    return (
      <SMobTable height={height ?? tableHeight} padding={padding} ref={parentRef}>
        {!data.length && withStub ? (
          <div style={{ padding: "0 20px" }}>
            <NotifyBlock
              content="Ваши приборы учета под защитой. На данный момент аномалии не обнаружены, список анализируется и формируется."
              status="success"
            />
          </div>
        ) : (
          <Virtuoso
            ref={virtRef}
            style={{ height: "100%" }}
            totalCount={rows.length}
            increaseViewportBy={incViewportBy ?? 100}
            components={{
              List: React.forwardRef((props, ref) =>
                isEmpty(data) || isEmpty(page) ? (
                  <div className="empty" {...props} ref={ref}>
                    <CusTypo variant="p4_regular">
                      {isEmpty(data)
                        ? "Список пуст"
                        : isEmpty(page)
                        ? "Фильтрация не дала результатов"
                        : ""}
                    </CusTypo>
                  </div>
                ) : (
                  <div {...getTableBodyProps()} {...props} ref={ref} />
                ),
              ),
            }}
            itemContent={(index) => {
              const row = rows[index];
              prepareRow(row);
              const { render } = row.cells[0];
              return (
                <div
                  className="row"
                  {...row.getRowProps()}
                  onClick={() => {
                    handleRowClick(row);
                  }}
                >
                  {render("Row")}
                </div>
              );
            }}
          />
        )}
      </SMobTable>
    );
  },
);
