import { useContext } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ContextSettings } from "context";

const statToWord = {
  on: "Включены",
  off: "Отключены",
};

const statToColor = {
  on: "green",
  off: "red",
};

export const wrap = css`
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  font-size: 12px;
  white-space: pre-wrap;
  border: 1px solid red;
  background: white;
  cursor: pointer;
`;

const SSpan = styled.span<{ stat: string }>`
  font-weight: 700;
  color: ${(props) => statToColor[props.stat]};
`;

export const SMocker = ({ className = "" }) => {
  const { mock_mode, toggleMocks } = useContext(ContextSettings);

  return (
    <div className={className} onClick={toggleMocks}>
      Моки: <SSpan stat={mock_mode}>{statToWord[mock_mode]}</SSpan>
    </div>
  );
};

export const Mocker = styled(SMocker)`
  ${wrap};
`;
