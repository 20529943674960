import { useEffect, useMemo } from "react";
import { isEmpty } from "lodash";

import { ITableFilterClm, Table } from "components/table/table.component";
import { tableStructure } from "./const";
import { constructColumns, constructTableData } from "./utils";
import { IDemoScore } from "interface/management";

interface IProp {
  rawData: IDemoScore[];
  filterBy?: any;
  filterByColumn?: ITableFilterClm[];
  globalFilter?: string;
  onRowClick: (a: any) => void;
  rowSelected: number;
}
export const DemoTable: React.FC<IProp> = ({
  rawData,
  filterByColumn,
  globalFilter,
  onRowClick,
  rowSelected,
}) => {
  const tableData = useMemo(() => constructTableData(rawData ?? [{}]), [rawData]);

  const columns = useMemo(
    () =>
      constructColumns({
        columns: tableStructure,
        selectedId: rowSelected,
      }),
    [rowSelected],
  );

  useEffect(() => {
    if (!isEmpty(rawData)) onRowClick(rawData[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Table
      columns={columns}
      data={tableData}
      view="tabs"
      onRowClick={onRowClick}
      filterByColumn={filterByColumn}
      globalFilter={globalFilter}
      initSelRow={{ "0": true }}
      height={516}
    />
  );
};
