import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useEditFavlist } from "hooks/api/analytics";
import { MTSButton } from "components/mts-button/mts-button";
import { MTSInput } from "components/mts-input";
import { Spacer } from "components/spacer/spacer.component";
import { SModalTitle } from "components/modal-notify-body/styles";
import { SControls } from "./styles";

export const schema = yup.object().shape({
  title: yup
    .string()
    .required("Поле не заполнено")
    .test("correctTitle", "Минимум 4 символа", (value?: string) => (value ?? "").length > 3),
  comment: yup.string().notRequired(),
});

interface IProp {
  onCancel: () => void;
  id: number | null;
  idList: number[];
  title?: string;
  comment?: string;
}

export const EditFavForm: React.FC<IProp> = ({ id, onCancel, title, comment, idList }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { isDirty, errors },
    reset,
  } = useForm({
    defaultValues: { comment: "", title: "Новый список" } as any,
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { mutate: editList } = useEditFavlist();

  const onSubmit = (sbmt) => {
    editList(
      {
        id_list: id,
        comment_: sbmt.comment,
        name_list: sbmt.title,
        id_pp_list: idList,
      },
      {
        onSuccess: () => onCancel(),
      },
    );
  };

  useEffect(() => {
    if (id !== null) reset({ title: title ?? "", comment: comment ?? "" });
  }, [reset, id, title, comment]);

  return (
    <>
      <SModalTitle>{id !== null ? "Редактировать список" : "Сохранить список"}</SModalTitle>
      <Spacer value="16px" />
      <MTSInput
        placeholder=""
        size="S"
        label="Название"
        control={control}
        {...register("title", {
          required: true,
        })}
        errorMessage={errors?.title?.message}
      />
      <Spacer value="16px" />
      <MTSInput
        placeholder="Оставьте комментарий"
        type="textarea"
        label="Комментарий"
        {...register("comment", {
          required: false,
        })}
        control={control}
        errorMessage={errors?.comment?.message}
      />
      <Spacer value="24px" />
      <SControls>
        <MTSButton
          size="M"
          variant="primary"
          type="submit"
          disabled={!isDirty}
          onClick={handleSubmit(onSubmit, undefined)}
        >
          Сохранить
        </MTSButton>
        <MTSButton size="M" variant="secondary" onClick={onCancel}>
          Отмена
        </MTSButton>
      </SControls>
    </>
  );
};
