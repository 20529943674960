import styled from "@emotion/styled";

export const SContent = styled.div`
  flex: 1;
`;

export const SBody = styled.div`
  & > ul {
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      white-space: nowrap;
      padding: 4px 0;

      &:not(:last-of-type) {
        border-bottom: 1px solid ${(props) => props.theme.mtsColor.background.secondary.lightMode};
      }

      & > span {
        &:first-of-type {
          text-align: left;
          width: 120px;
          flex: 0 0 120px;
          color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
        }

        &:last-of-type {
          flex: 1 1 auto;
        }

        &:not(:first-of-type) {
          white-space: normal;
          text-align: right;
        }
      }
    }
  }
`;
