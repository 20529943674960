import styled from "@emotion/styled";

export const SControl = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid ${(props) => props.theme.mtsColor.background.stroke.lightMode};
  border-radius: 6px;
  background-color: ${(props) => props.theme.mtsColor.background.secondary.lightMode};
  cursor: pointer;

  & > .MuiSvgIcon-root {
    fill: ${(props) => props.theme.mtsColor.text.secondary.darkMode};
  }
`;

export const SWrap = styled.div<{ isOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 48px 40px;
  position: fixed;
  top: 64px;
  right: -100vw;
  bottom: 0;
  z-index: 10;
  width: 472px;
  transition: all 0.5s ease-out;
  border: 1px solid #dde1e7;
  border-top: none;

  ${(props) =>
    props.isOpen &&
    `
  	right: 0;
  `}
`;

export const SAcc = styled.div`
  & .MuiAccordionDetails-root {
    padding: 0 0 0 24px;
  }

  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const SRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;

  & div {
    display: flex;
    align-items: center;
  }
`;

export const SBack = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & .MuiTypography-root {
    margin-left: 10px;
  }
`;

export const SInner = styled.div`
  flex: 1;
  overflow-y: auto;
`;

export const SButtons = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 48px;

  & button {
    width: 100%;
  }
`;

export const SSPan = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto;
`;

export const SInp = styled.article`
  height: 56px;
  max-width: 160px;
  margin-bottom: 16px;
`;
