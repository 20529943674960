import { useContext, useEffect, useState } from "react";

import Icon from "components/icons";
import { SidebarAccordion } from "components/sidebar-accordion/sidebar-accordion";
import { URL_LOGOUT_MTS_BY_ID, WELCOME_PAGE_URL } from "services/urls";
import { logout as authLogout } from "services/auth";
import { ContextSettings } from "context";
import { ROUTE_PERSONAL_AREA, ROUTE_SECURITY } from "stream-constants/route-constants";
import { MobSidebar } from "./mobSidebar";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SBody, SBtnItem, SFooter, SLink, SSidebar, SNavLink } from "./styles";

export const Sidebar = () => {
  const { setIsAuthorized, isDesktop } = useContext(ContextSettings);
  const [isOpen, setOpen] = useState<boolean>(true);

  useEffect(() => {
    setOpen(!isDesktop() ? false : true);
  }, [isDesktop]);

  const toggleOpen = () => {
    setOpen((s) => !s);
  };

  const handleLogout = async () => {
    try {
      const response = await authLogout();
      if (response.ok) {
        setIsAuthorized(false);
        const params = `${WELCOME_PAGE_URL}`;
        window.location.href = URL_LOGOUT_MTS_BY_ID(params);
      }
    } catch (error) {
      new Error(error);
    }
  };

  return !isDesktop() ? (
    <MobSidebar
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      logout={handleLogout}
      open={() => setOpen(true)}
      close={() => setOpen(false)}
    />
  ) : (
    <DeskSidebar
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      open={() => setOpen(true)}
      logout={handleLogout}
    />
  );
};

const DeskSidebar = ({ isOpen = true, open, logout, toggleOpen }) => {
  return (
    <SSidebar isOpen={isOpen}>
      <SBody>
        <SidebarAccordion isOpenSidebar={isOpen} close={() => undefined} open={open} />
      </SBody>
      <SidebarFooter isOpen={isOpen} toggleOpen={toggleOpen} logout={logout} />
    </SSidebar>
  );
};

export const SidebarFooter = ({ isOpen, toggleOpen, logout, withHide = true }) => {
  return (
    <SFooter>
      <SBtnItem>
        <SNavLink to={`/${ROUTE_PERSONAL_AREA}/${ROUTE_SECURITY}`}>
          <Icon.Security sx={{ width: 16, height: 20 }} />

          {isOpen ? <CusTypo variant="p3_regular">Безопасность</CusTypo> : null}
        </SNavLink>
      </SBtnItem>
      <SBtnItem>
        <SLink href="mailto:support@energytool.mts.ru">
          <Icon.Support sx={{ width: 20, height: 20 }} />

          {isOpen ? <CusTypo variant="p3_regular">Поддержка</CusTypo> : null}
        </SLink>
      </SBtnItem>
      <SBtnItem>
        <SLink
          href="/"
          onClick={(e) => {
            e.preventDefault();
            logout();
          }}
        >
          <Icon.LogOut sx={{ width: 24, height: 24 }} />

          {isOpen ? <CusTypo variant="p3_regular">Выход</CusTypo> : null}
        </SLink>
      </SBtnItem>
      {withHide ? (
        <SBtnItem style={{ margin: "16px 0 0 0" }}>
          <SLink
            href="/"
            target="_blank"
            onClick={(e) => {
              e.preventDefault();
              toggleOpen();
            }}
          >
            {isOpen ? (
              <>
                <Icon.ArrowHeadLeft sx={{ width: 8, height: 16 }} />

                <CusTypo variant="p3_regular">Скрыть</CusTypo>
              </>
            ) : (
              <Icon.ArrowHeadRight sx={{ width: 24, height: 24 }} />
            )}
          </SLink>
        </SBtnItem>
      ) : null}
    </SFooter>
  );
};
