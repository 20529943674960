import { MTSBadge, MtsBadgeCircle } from "components/mts-badge";
import { EAskueMngStatus } from "interface/askue";
import { EDemoMngStatus } from "interface/common";
import { EDepStats } from "interface/map";

import { DEPARTURE_RESULT, EMPTY_CELL_VALUE } from "stream-constants/keys-and-constants";

export const MTSStatus = (
  value: string | null,
  size: "small" | "medium" | "large" = "small",
  isCircle = false,
) => {
  if (!value) return EMPTY_CELL_VALUE;

  switch (value) {
    case DEPARTURE_RESULT.NORMAL:
    case DEPARTURE_RESULT.PAID:
    case DEPARTURE_RESULT.ACTIVE:
    case DEPARTURE_RESULT.COMPANY_ACTIVE:
    case EDemoMngStatus.Активен:
    case EDepStats["Проверка завершена"]:
    case EAskueMngStatus.Принята:
      return isCircle ? (
        <MtsBadgeCircle bg="greenBg" />
      ) : (
        <MTSBadge color="green" backgroundColor="greenBg" size={size} label={value} />
      );
    case DEPARTURE_RESULT.IN_WORK:
    case DEPARTURE_RESULT.TO_CHECK:
    case DEPARTURE_RESULT.TRANSACTION:
    case EDemoMngStatus["На согласовании"]:
    case DEPARTURE_RESULT.EVENT_INFORMATION:
    case EAskueMngStatus["В работе"]:
    case EDepStats.Проверяется:
      return isCircle ? (
        <MtsBadgeCircle bg="blueBg" />
      ) : (
        <MTSBadge color="blue" backgroundColor="blueBg" size={size} label={value} />
      );
    case DEPARTURE_RESULT.BREAKING:
    case DEPARTURE_RESULT.BLOCKED:
    case DEPARTURE_RESULT.COMPANY_BLOCKED:
    case DEPARTURE_RESULT.NOT_PAID:
    case EDemoMngStatus.Приостановлен:
    case DEPARTURE_RESULT.EVENT_CRASH:
    case EAskueMngStatus.Отклонена:
    case "Неоплачен":
      return isCircle ? (
        <MtsBadgeCircle bg="redBg" />
      ) : (
        <MTSBadge color="red" backgroundColor="redBg" size={size} label={value} />
      );
    case DEPARTURE_RESULT.SUSPICION:
    case DEPARTURE_RESULT.SUSPENDED:
    case DEPARTURE_RESULT.COMPANY_SUSPENDED:
    case EDemoMngStatus["На модерации"]:
    case DEPARTURE_RESULT.EVENT_WARNING:
      return isCircle ? (
        <MtsBadgeCircle bg="yellowBg" />
      ) : (
        <MTSBadge color="yellow" backgroundColor="yellowBg" size={size} label={value} />
      );
    case DEPARTURE_RESULT.NOT_REQUIRE_PAYMENT:
    case DEPARTURE_RESULT.NO_ACCESS:
    case EDemoMngStatus["Заполнение анкеты"]:
      return isCircle ? (
        <MtsBadgeCircle bg="greyBg" />
      ) : (
        <MTSBadge color="grey" backgroundColor="greyBg" size={size} label={value} />
      );
    case DEPARTURE_RESULT.DEFECTIVE:
      return isCircle ? (
        <MtsBadgeCircle bg="blackBg" />
      ) : (
        <MTSBadge color="black" backgroundColor="blackBg" size={size} label={value} />
      );
    case DEPARTURE_RESULT.REPLACED:
      return isCircle ? (
        <MtsBadgeCircle bg="plumBg" />
      ) : (
        <MTSBadge color="plum" backgroundColor="plumBg" size={size} label={value} />
      );
    default:
      return isCircle ? (
        <MtsBadgeCircle bg="greyBg" />
      ) : (
        <MTSBadge color="grey" backgroundColor="greyBg" size={size} label={value} />
      );
  }
};
