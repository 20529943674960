import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Ellipsis = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="4"
      viewBox="0 0 18 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="18" width="4" height="4" rx="2" transform="rotate(90 18 0)" fill="#969FA8"/>
      <rect x="11" width="4" height="4" rx="2" transform="rotate(90 11 0)" fill="#969FA8"/>
      <rect x="4" width="4" height="4" rx="2" transform="rotate(90 4 0)" fill="#969FA8"/>
    </SvgIcon>
  );
};

export default Ellipsis;
