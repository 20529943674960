import { isEmpty, uniqBy } from "lodash";
import { format, parseISO } from "date-fns";

import { ESn } from "interface/common";
import { EDepStats } from "interface/map";
import { PO_COLUMN, RES_COLUMN } from "pages/anomalies/const";
import { IDropElem } from "components/table/table-filter/table-filter";

export const DEPARTURE_COLUMN = "task_status";
export const FRAUD_COLUMN = "mining_probability";
export const ARCHIVE_COLUMN = "created_dt";
export const RESULT_COLUMN = "request_result";

export const RES_FILTER = {
  [RES_COLUMN]: [],
  [PO_COLUMN]: [],
};
export const MAP_FILTER_DEFAULT_DEPARTURE = {
  [RES_COLUMN]: [],
  [PO_COLUMN]: [],
  [DEPARTURE_COLUMN]: [],
  [ARCHIVE_COLUMN]: null,
};

export const MAP_FILTER_DEFAULT_MINING = {
  [FRAUD_COLUMN]: [],
};

export const MAP_FILTER_DEFAULT_ANOMALIES = {
  [PO_COLUMN]: [],
  [RES_COLUMN]: [],
  [RESULT_COLUMN]: [],
};

export const HARDCODED_FILTER_DEPARTURE = [
  {
    id: 0,
    name: EDepStats["Выезд не назначен"],
  },
  {
    id: 1,
    name: EDepStats.Проверяется,
  },
  {
    id: 2,
    name: EDepStats["Проверка завершена"],
  },
];

export const HARDCODED_FILTER_MINING = [
  {
    id: 0,
    name: ESn.Низкая,
  },
  {
    id: 1,
    name: ESn.Средняя,
  },
  {
    id: 2,
    name: ESn.Высокая,
  },
  {
    id: 3,
    name: "Очень высокая",
  },
];

const HARDCODED_NULL_ARCHIVE = [
  {
    id: 0.1,
    name: null,
    friendlyName: "Текущий список",
  },
];

const departuresOmit = [FRAUD_COLUMN];
const minersOmit = [ARCHIVE_COLUMN, DEPARTURE_COLUMN, RESULT_COLUMN];

export const composeBackendFilter = <T>(somearr, ref: "dep" | "min"): T => {
  const arrToCheck = ref === "dep" ? departuresOmit : minersOmit;
  return somearr?.reduce(
    (sum, cur) => ({
      ...sum,
      ...(isEmpty(cur.value) || arrToCheck.includes(cur.column) ? {} : { [cur.column]: cur.value }),
    }),
    {},
  );
};

export const makeArchiveFilter = (a: (string | null)[]): IDropElem[] => {
  return (HARDCODED_NULL_ARCHIVE as any).concat(
    uniqBy(
      a
        ?.filter((b) => b != null)
        .map((raw, i) => {
          const kek = parseISO(raw!);
          return {
            id: i,
            name: format(kek, "yyyy-MM-dd'T'HH:mm:ss.SSS"),
            friendlyName: format(kek, "dd.MM.yyyy HH:mm"),
          };
        }),
      "name",
    ),
  );
};
