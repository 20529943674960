import { useMutation, useQuery, useQueryClient } from "react-query";

import { toastArchiveFail, toastArchiveSuc } from "components/devices/deviceTable/subComponents";
import {
  ICheckDeviceResponse,
  IDeviceList,
  IDeviceStatusUpdateRequest,
  IDevicesFilterRequest,
  IDevicesPoResponse,
  IDevicesPriemResponse,
  IDevlistReq,
} from "interface/anomalies";
import {
  addDevice,
  anoMakeActive,
  getAddCheckResult,
  getAnomaliesArchive,
  getCheckDevice,
  getDeviceList,
  getListEfficiency,
} from "services/device-list";
import { getDevicesPoList, getDevicesPriemList } from "services/filters-device-list";
import { Keys } from "./const";

export function useDeviceList(period) {
  return useQuery<IDevlistReq, { description?: string }, IDeviceList[]>(
    [Keys.DEVICE_LIST, period],
    () => getDeviceList(period),
    {
      staleTime: 3000,
    },
  );
}

export function useAddCheckResultat() {
  const queryClient = useQueryClient();
  return useMutation<any, any, IDeviceStatusUpdateRequest>((a) => getAddCheckResult(a), {
    onSuccess: async () => {
      setTimeout(async () => await queryClient.invalidateQueries([Keys.DEVICE_LIST]), 500);
    },
  });
}

export function useDevicesPriemList() {
  return useMutation<IDevicesPriemResponse, any, IDevicesFilterRequest | undefined>(
    (a) => getDevicesPriemList(a),
    {
      mutationKey: ["filter_list", "priem"],
    },
  );
}

export function useDevicesPoList(isOpen) {
  return useQuery<any, any, IDevicesPoResponse>(["filter_list", "po"], () => getDevicesPoList(), {
    enabled: isOpen,
  });
}

export function useAnomaliesArchive() {
  const query = useQuery<
    any,
    { description?: string },
    {
      created_time: string;
      id: number;
      phone: string;
      s3_title: string;
      tenant_id: string;
      user_name: string;
      file_dttm: string | null;
    }[]
  >([Keys.DEVICE_LIST, "archive"], () => getAnomaliesArchive());

  return query;
}

export function useListEffeciency() {
  return useMutation<
    {
      eff_prc_by_dt: number;
      eff_prc: number;
    },
    any,
    IDevlistReq
  >((a) => getListEfficiency(a), {
    mutationKey: [Keys.DEVICE_LIST, "effeciency"],
  });
}

export function useAnoMakeActive() {
  const queryClient = useQueryClient();
  return useMutation<any, any, string | number>((a) => anoMakeActive(a), {
    mutationKey: [Keys.DEVICE_LIST, "back_from_archive"],
    onSuccess: async () => {
      toastArchiveSuc();
      await queryClient.invalidateQueries([Keys.DEVICE_LIST]);
    },
    onError: () => {
      toastArchiveFail();
    },
  });
}

export function useCheckDevice(sn: string, isEnabled: boolean) {
  return useQuery<any, any, ICheckDeviceResponse[]>(
    [Keys.DEVICE_LIST, "search_meter", sn],
    () => getCheckDevice(sn),
    {
      enabled: isEnabled,
    },
  );
}

export function useAddDevice() {
  const queryClient = useQueryClient();
  return useMutation<any, any, { id_pp: number }>(({ id_pp }) => addDevice(id_pp), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([Keys.DEVICE_LIST, "add_device"]);
    },
  });
}
