import styled from "@emotion/styled";
import { CusTypo } from "components/cusTypo/custom-typography";
import { rangeToColor } from "theme";

const titleByColor = {
  white: "Не определен",
  red: "Красный",
  yellow: "Желтый",
  green: "Зеленый",
  blue: "Синий",
};

const SStat = styled.div<{ color: string }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  & > div {
    flex: 0 0 auto;
    width: 15px;
    height: 15px;
    margin-right: 8px;
    border-radius: 50%;
    background-color: ${(props) => rangeToColor[props.color]};
    border: 1px solid ${(props) => (props.color === "white" ? "black" : rangeToColor[props.color])};
  }
`;

export const StatBubble = ({ value }) => {
  return (
    <SStat color={value}>
      <div />
      <CusTypo variant="p4_regular" font="comp">
        {titleByColor[value]}
      </CusTypo>
    </SStat>
  );
};
