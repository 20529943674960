import { useState, FC, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import styled from "@emotion/styled";
import ru from "date-fns/locale/ru";

import Icon from "components/icons";
import { Spacer } from "components/spacer/spacer.component";
import { InputLabel } from "components/input-label";
import { InputErrorMessage } from "components/input-error-message";
import "react-datepicker/dist/react-datepicker.css";
import useStyles from "./styles";

interface InputDatePickerProps {
  label?: string;
  errorMessage?: string | undefined;
  disabled?: boolean | undefined;
  isPickDays?: boolean;
  size?: "S" | "M" | "L";
  onSelect?: Function;
  portalId?: string;
  // Пояснительный текст серого цвета
  placeholder?: string;
  // Оформатированная Date черного цвета, в реале оно не проставляется в state при маунте и не отдается во вне
  defaultVal?: Date;
  // Проставить полночь выбранной даты вместо конкретного времени в момент клика
  isPickAM?: boolean;
  className?: string;
  // Позволяет извне обнулить значение state при выполнении условия
  isEmptyVal?: boolean;
}

export const SInputDatePicker: FC<InputDatePickerProps> = ({
  label,
  placeholder,
  isPickDays = true,
  size = "S",
  onSelect,
  errorMessage,
  portalId,
  defaultVal,
  isPickAM = false,
  className = "",
  isEmptyVal = false,
  disabled = false,
}) => {
  const classes = useStyles();
  registerLocale("ru", ru);
  const [isOpen, setOpen] = useState(false);
  const [inner, setInner] = useState<any>(undefined);

  useEffect(() => {
    if (isEmptyVal) setInner(undefined);
  }, [isEmptyVal]);

  return (
    <div className={`${classes.datePickerWrapper} ${className}`} id="datePickerWrap">
      {label ? (
        <>
          <InputLabel label={label} />
          <Spacer value="4px" />
        </>
      ) : null}
      <div className={classes.inputCalendar}>
        <DatePicker
          className={classes.datepicker}
          selected={inner ?? defaultVal}
          onChange={(e) => {
            if (isPickAM) e?.setHours(0, 0, 0, 0);
            setInner(e);
            onSelect?.(e);
          }}
          locale={ru}
          dateFormat={isPickDays ? "dd.MM.yyyy" : "MM.yyyy"}
          showMonthYearPicker={!isPickDays}
          onCalendarClose={() => setOpen(false)}
          onCalendarOpen={() => setOpen(true)}
          isClearable
          placeholderText={placeholder}
          customInput={
            <CustomFieldDatePicker
              size={size}
              isOpenCalendar={isOpen}
              errorMessage={errorMessage}
              disabled={disabled}
            />
          }
          portalId={portalId}
          disabled={disabled}
        />
      </div>
      {errorMessage && <InputErrorMessage errorMessage={errorMessage} />}
    </div>
  );
};

interface ICustom {
  value?: string;
  onClick?: () => void;
  placeholder?: string;
  size?: "S" | "M" | "L";
  isOpenCalendar: boolean;
  errorMessage: string | undefined;
  disabled?: boolean;
}

const CustomFieldDatePicker: React.FC<ICustom> = ({
  value,
  onClick,
  placeholder,
  size,
  isOpenCalendar,
  errorMessage,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <div
      className={`
	  ${classes.customField}
	  ${size === "M" ? classes.sizeM : size === "L" ? classes.sizeL : classes.sizeS}
	  ${disabled ? classes.disabled : ""}
	  ${errorMessage ? classes.error : ""}
	  `}
      onClick={onClick}
    >
      {value ? (
        <div className={classes.valueField}>{value}</div>
      ) : (
        <div className={classes.placeholderField}>{placeholder}</div>
      )}

      {!isOpenCalendar && value ? (
        <Icon.Close className={classes.icon} sx={{ width: 20 }} />
      ) : isOpenCalendar ? (
        <Icon.ArrowUp className={classes.icon} sx={{ width: 20 }} />
      ) : (
        <Icon.ArrowDown className={classes.icon} sx={{ width: 20 }} />
      )}
    </div>
  );
};

export const InputDatePicker = styled(SInputDatePicker)``;
