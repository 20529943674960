import { IHttpMethods } from "stream-constants/server-constants";
import { MINING_ARCHIVE, MINING_INFO, MINING_TEMP_UPLOAD } from "services/urls";
import { fetchData } from "services/services-utils/fetch-data";

import { mock_mining_archive, mock_mining_info } from "./mock-data";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";

export const getMiningInfo = async () => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = mock_mining_info;
  } else {
    result = await fetchData({
      url: MINING_INFO,
      method: IHttpMethods.GET,
      name: "getMiningInfo",
      emptyResponse: false,
    });
  }

  return result;
};

export const getMiningArchive = async () => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = mock_mining_archive;
  } else {
    result = await fetchData({
      url: MINING_ARCHIVE,
      method: IHttpMethods.GET,
      name: "getMiningArchive",
      emptyResponse: false,
    });
  }

  return result;
};

interface ITempUpl {
  tenant_id: string;
  file: File;
}

export const tempFormUpload = async (data: ITempUpl) => {
  return fetchData({
    url: MINING_TEMP_UPLOAD,
    method: IHttpMethods.POST,
    name: "tempFormUpload",
    body: data,
    emptyResponse: false,
    contentType: "none",
  });
};
