import { IHttpMethods } from "stream-constants/server-constants";
import { GET_EVENTS_LIST } from "services/urls";
import { fetchData } from "services/services-utils/fetch-data";

import { base } from "./mock-data";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";

export const getManagementEvents = async () => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = base;
  } else {
    result = await fetchData({
      url: GET_EVENTS_LIST,
      method: IHttpMethods.GET,
      name: "getManagmentEvents",
      emptyResponse: false,
    });
  }

  return result;
};
