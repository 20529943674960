import React from "react";
import { toast } from "react-toastify";

import styled from "@emotion/styled";
import { SCheckBox, SCheckBoxLabel, SCheckBoxWrapper, SLabel } from "./switch.styled";
import { Toast } from "components/toast/toast";

interface ISwitch {
  id: string;
  checked: boolean;
  setChecked: (chk: boolean) => void;
  disabled?: boolean;
  label?: string;
  className?: string;
  labelWidth?: string;
  labelSize?: "s" | "m";
  rightPlhlr?: boolean;
  disabledMessage?: string;
}

const SSwitch: React.FC<ISwitch> = ({
  checked,
  id,
  setChecked,
  label,
  className,
  labelWidth = "100%",
  labelSize = "s",
  rightPlhlr = false,
  disabled,
  disabledMessage,
}) => {
  const handleCheck = (e) => {
    if (disabled) {
      e.preventDefault();
      return disabledMessage
        ? toast(<Toast title={disabledMessage} isError />, {
            progress: undefined,
            autoClose: 2000,
            hideProgressBar: true,
          })
        : undefined;
    } else {
      setChecked(e.target.checked);
    }
  };

  return (
    <SCheckBoxWrapper className={className}>
      {label && !rightPlhlr ? (
        <SLabel labelWidth={labelWidth} labelSize={labelSize}>
          {label}
        </SLabel>
      ) : null}
      <SCheckBox
        id={id}
        type="checkbox"
        checked={checked}
        isDisabled={disabled}
        onClick={handleCheck}
      />
      <SCheckBoxLabel htmlFor={id} rightPlhlr={rightPlhlr} />
      {label && rightPlhlr ? (
        <SLabel labelWidth={labelWidth} labelSize={labelSize} rightPlhlr>
          {label}
        </SLabel>
      ) : null}
    </SCheckBoxWrapper>
  );
};

export const Switch = styled(SSwitch)``;
