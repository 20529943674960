import { useState, useEffect, useContext } from "react";

import { Footer } from "components/footer/footer";
import { MTSButton } from "components/mts-button/mts-button";
import { MTSModal } from "components/mts-modal/mts-modal";
import { ROUTE_PERSONAL_AREA } from "stream-constants/route-constants";
import { ModalNotifyBody } from "components/modal-notify-body/modal-notify-body";
import { ContextSettings } from "context/context-settings";
import { Logo } from "components/logo/logo.component";
import { login } from "services/auth";
import { CusTypo } from "components/cusTypo/custom-typography";
import useStyles from "./styles";

export const WelcomePage = () => {
  const [openModal, setOpenModal] = useState(false);
  const { isAuthorized } = useContext(ContextSettings);
  const [openModalNotAvailable, setOpenModalNotAvailable] = useState(false);
  const classes = useStyles();
  const handleLogin = () => {
    isAuthorized
      ? (window.location.href = window.location.origin + `/${ROUTE_PERSONAL_AREA}`)
      : login();
  };

  function searchShowModalParam() {
    const paramOpen = window.location.search.slice(1);
    const searchParams = new URLSearchParams(paramOpen);
    if (searchParams.has("modalNoReg")) setOpenModal(true);
    else if (searchParams.has("modalNotAvailable")) setOpenModalNotAvailable(true);
  }

  useEffect(() => {
    searchShowModalParam();
  }, []);

  return (
    <>
      <main className={classes.main}>
        <section className={classes.landing}>
          <div className={classes.articleHeader}>
            <Logo isLight />
          </div>
          <article className={classes.article}>
            <div className={classes.containerGeo}>
              <div>
                <CusTypo variant="promo2_black" className={classes.title}>
                  ENERGYTOOL
                </CusTypo>

                <CusTypo variant="h3_medium" className={classes.text}>
                  Лучшие решения на рынке энергетических услуг
                </CusTypo>

                <CusTypo variant="p3_regular" className={classes.subtext}>
                  Комплекс программных решений, которые помогут оптимизировать энергетические
                  расходы вашей организации, выявить подозрительную активность абонентов и
                  автоматизировать процессы сбора и анализа данных
                </CusTypo>
                <div className={classes.blockInfoBtn}>
                  <MTSButton size="L" onClick={handleLogin}>
                    Войти в кабинет
                  </MTSButton>
                </div>
              </div>
            </div>
          </article>
          <MTSModal open={openModal} close={() => setOpenModal(false)}>
            <ModalNotifyBody
              title="Сервис временно недоступен"
              isError
              isNotRegistration
              close={() => setOpenModal(false)}
            />
          </MTSModal>
          <MTSModal open={openModalNotAvailable} close={() => setOpenModalNotAvailable(false)}>
            <ModalNotifyBody
              title="Номер не зарегистрирован"
              isError
              isNotAvailable
              close={() => setOpenModalNotAvailable(false)}
            />
          </MTSModal>
        </section>
      </main>

      <Footer isDarkTheme />
    </>
  );
};
