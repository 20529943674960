import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { CusTypo } from "components/cusTypo/custom-typography";
import { HEADER_HEIGHT } from "components/header/styles";
import { Select } from "components/select/select";
import { customScrollVertical } from "components/table/utils";

export const SWrap = styled.div`
  min-width: 0;
  display: flex;
  flex-flow: row nowrap;
  height: 100%;

  & > section {
    width: 100%;
    max-width: 100%;
    height: calc(100% - ${HEADER_HEIGHT}px);
    overflow-x: hidden;
    overflow-y: auto;
    ${(props) =>
      customScrollVertical({
        theme: props.theme,
        trackStart: 0,
      })};

    &:first-of-type {
      position: relative;
      min-width: 0;
      flex: 0 0 240px;
      padding-right: 40px;
      text-align: left;

      &:after {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        height: 100%;
        width: 1px;
        background: ${(props) => props.theme.mtsColor.background.stroke.lightMode};
      }
    }

    &:last-of-type {
      padding-left: 40px;
    }
  }
`;

export const SCatTitle = styled(CusTypo)`
  display: block;
  margin-bottom: 28px;
`;

export const SAmount = styled.span`
  font-weight: 400;
  font-size: 17px;
  color: ${(props) => props.theme.mtsColor.text.tertiary.lightMode};
`;

export const SGrey = styled.div<{ isActive?: boolean }>`
  min-width: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding: 12px 16px;
  border-radius: 8px;
  ${(props) =>
    props.isActive && `background-color: ${props.theme.mtsColor.background.secondary.lightMode}`};
  cursor: pointer;
`;

export const SGreyHead = styled(SGrey)`
  height: 48px;
  background-color: ${(props) => props.theme.mtsColor.background.secondary.lightMode};
  cursor: default;
`;

export const SBody = styled.article`
  min-height: 0;
  min-width: 0;
  display: flex;
  flex-flow: column nowrap;
  padding: 0 24px;

  & label > span:last-of-type {
    margin-left: 20px !important;
  }
`;

export const SDown = styled.span<{ disabled?: boolean }>`
  width: auto;
  color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
  text-decoration: none;
  cursor: pointer;
  user-select: none;

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      color: ${props.theme.mtsColor.grey.greyLight};
    `}
`;

export const SPeriod = styled.div`
  min-width: 0;
  margin-top: 12px;

  & > span {
    display: block;
    margin-bottom: 4px;
    text-align: left;
  }

  & > div {
    display: grid;
    grid-template-columns: 1fr 24px 1fr;
    gap: 0px;
  }
`;

export const SGrid = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  height: auto;
`;

export const SChip = styled.span`
  position: relative;
  flex: 0 0 auto;
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  padding: 3px 22px 3px 8px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.mtsColor.background.secondary.lightMode};
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    & > svg {
      fill: ${(props) => props.theme.mtsColor.text.primary.lightMode};
    }
  }

  & span {
    line-height: normal;
  }

  & > svg {
    position: absolute;
    right: 2px;
    top: 50%;
    transform: translateY(-50%);
    fill: ${(props) => props.theme.mtsColor.text.secondaryLink.lightMode};
    transition: all 0.3s ease-in-out;
  }
`;

export const SButtons = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  gap: 12px;

  & button {
    width: 100%;
  }
`;

export const SResult = styled.div`
  text-align: center;

  & .MuiTypography-root {
    color: ${(props) => props.theme.mtsColor.text.secondaryLink.lightMode};
  }
`;

export const SHug = styled.div`
  display: inline-flex;
  flex: 0 0 auto;
  height: 44px;
`;

export const SRow = styled.div`
  min-width: 0;
  margin-top: 12px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  & ${Select} {
    flex: 0 0 120px;
    min-width: 120px;
  }
`;

export const SLabelIcon = styled.article`
  min-width: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 4px;
`;
