import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const SuccessShaped = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_3079_708)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.194233 16.9248C0.670174 10.53 0.908144 7.3325 4.11752 4.12313C7.3269 0.913754 10.5243 0.675785 16.9192 0.199846C18.577 0.0764707 20.287 0.00170898 21.997 0.00170898C23.707 0.00170898 25.417 0.076472 27.0747 0.199848C33.4696 0.675789 36.6671 0.91376 39.8765 4.12314C43.0858 7.33251 43.3238 10.53 43.7997 16.9249C43.9231 18.5826 43.9979 20.2926 43.9979 22.0026C43.9979 23.7126 43.9231 25.4226 43.7997 27.0804C43.3238 33.4753 43.0858 36.6727 39.8765 39.8821C36.6671 43.0915 33.4696 43.3294 27.0747 43.8054C25.417 43.9287 23.707 44.0035 21.997 44.0035C20.287 44.0035 18.577 43.9287 16.9192 43.8054C10.5243 43.3294 7.32689 43.0914 4.11751 39.8821C0.908139 36.6727 0.670169 33.4752 0.194231 27.0803C0.0708554 25.4226 -0.00390625 23.7126 -0.00390625 22.0026C-0.00390625 20.2926 0.0708567 18.5826 0.194233 16.9248Z"
          fill="#26CD58"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.2837 17.6173C33.2384 16.6626 33.2384 15.1149 32.2837 14.1603C31.3291 13.2057 29.7814 13.2057 28.8268 14.1603L19.5553 23.4318L16.3949 20.2714C15.4402 19.3168 13.8925 19.3168 12.9379 20.2714C11.9833 21.226 11.9833 22.7738 12.9379 23.7284L17.8268 28.6173C18.7814 29.5719 20.3291 29.5719 21.2837 28.6173L32.2837 17.6173Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3079_708">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default SuccessShaped;
