import { RenderFilter } from "components/table/render-filter/renderFilter.component";
import { EHandbookLitrs } from "interface/common";

export const abbrToTitle = (v) => {
  switch (v) {
    case EHandbookLitrs["Ср.день"]:
      return "Среднее день";
    case EHandbookLitrs["Ср.ночь"]:
      return "Среднее ночь";
    case EHandbookLitrs["Периодич.>0.4"]:
      return "Периодичность  > 0.4";
    default:
      return v;
  }
};

export const tableStructure = [
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Диапазон"
        withPadding
      />
    ),
    accessor: "name_re_color",
    minWidth: 144,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="От"
        withPadding
      />
    ),
    accessor: "val_from",
    minWidth: 144,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="До"
        withPadding
      />
    ),
    accessor: "val_to",
    minWidth: 144,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Скор"
        withPadding
      />
    ),
    accessor: "val_score",
    minWidth: 144,
  },
];
