import * as yup from "yup";
import { ITenantResponse } from "interface/management";
import validationMethod from "../common-validation";
import { validationMethod as specific } from "./validation-method";

const REQUIRED = "Поле не заполнено";
const INCORRECT = "Некорректно заполнено поле";
const INC_ORG =
  "Только кириллица, с заглавной буквы названия компании (либо аббревиатура правовой формы)";
export const FALLBACK_MSG = "Что-то пошло не так, попробуйте еще раз";

export const schema = yup.object().shape({
  title: yup
    .mixed()
    .required()
    .test("requiredOrganizationName", INC_ORG, validationMethod.correctOrganization),
  contr_num: yup
    .mixed()
    .test("requiredOrganizationName", REQUIRED, specific.requiredContractNumber)
    .test("correctOrganizationName", INCORRECT, specific.correctContractNumber),
  service_type: yup.array().min(1, REQUIRED).nullable().required(REQUIRED),
  time_schema: yup.mixed().test("requiredTimeSchema", REQUIRED, specific.requiredTimeSchema),
});

export const makeDefaultForEdit = (data: ITenantResponse | null) => {
  return {
    title: data?.title,
    contr_num: data?.contr_num,
    service_type: data?.service_type ? data?.service_type?.split(", ") : [],
    additional_info: data?.additional_info || [],
    time_schema: data?.time_schema ?? [],
  };
};
