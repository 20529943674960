import { format, isEqual } from "date-fns";
import styled from "@emotion/styled";

import { CusTypo } from "components/cusTypo/custom-typography";
import { StatBubble } from "components/demo/demoTable/subComponents";
import Icon from "components/icons";
import { IResearchStatsArchiveRes } from "interface/analytics";
import { SGroup } from "components/devices/deviceTable/styles";

export const constructTableData = (draft: IResearchStatsArchiveRes[]) =>
  draft?.map((d) => {
    const { d_nv, d_cv, date_reverify, date_archive } = d;

    return {
      ...d,
      d_nv: new Date(d_nv ?? 0),
      d_cv: new Date(d_cv ?? 0),
      date_reverify: new Date(date_reverify ?? 0),
      date_archive: new Date(date_archive ?? 0),
    };
  });

export const SOverflow = styled.div`
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IconCell = ({ value }) => {
  const isBad = value;

  return (
    <SGroup isOk={!isBad}>
      {!isBad ? <Icon.CheckBorder /> : <Icon.Archive />}
      <CusTypo variant="c1_regular">{!isBad ? "Актуально" : "Не актуально"}</CusTypo>{" "}
    </SGroup>
  );
};

const renderCell = (val) => {
  switch (val) {
    case "d_nv":
    case "d_cv":
    case "date_reverify":
    case "date_archive":
      return {
        Cell: ({ value }) => (isEqual(value, new Date(0)) ? "-" : format(value, "dd.MM.yyyy")),
      };
    case "fp":
      return {
        Cell: ({ value }) => <StatBubble value={value} size="XS" />,
      };
    case "username":
    case "userrole":
      return {
        Cell: ({ value }) => <SOverflow>{value ?? "—"}</SOverflow>,
      };
    case "not_actual":
      return {
        Cell: ({ value }) => <IconCell value={value} />,
      };
    default:
      return {
        Cell: ({ value }) => value ?? "—",
      };
  }
};

export const constructColumns = ({ columns }) => {
  return columns.map((singleColumn) => {
    return {
      ...singleColumn,
      ...renderCell(singleColumn.accessor),
    };
  });
};
