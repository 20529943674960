import styled from "@emotion/styled";
import { RenderFilter } from "components/table/render-filter/renderFilter.component";

export const SClamp = styled.div`
  max-width: 100%;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const tableStructure = [
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Лицевой счет"
        withPadding
      />
    ),
    accessor: "contract_number",
    minWidth: 200,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Код услуги"
        withPadding
      />
    ),
    accessor: "service_code",
    minWidth: 110,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Номер"
        withPadding
      />
    ),
    accessor: "account_number",
    minWidth: 134,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Дата"
        withPadding
      />
    ),
    accessor: "created_time",
    minWidth: 150,
    sortType: "datetime",
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Стоимость"
        withPadding
      />
    ),
    accessor: "price",
    minWidth: 140,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Комментарий"
        withPadding
      />
    ),
    accessor: "comment",
    minWidth: 360,
    width: "100%",
  },
];
