import { CheckboxSize } from "../types";

const getBoxSize = (boxSize: string): number => {
  switch (boxSize) {
    case CheckboxSize.SMALL_16:
      return 16;
    case CheckboxSize.MEDIUM_24:
      return 24;
    case CheckboxSize.LARGE_32:
      return 32;
    default:
      return 16;
  }
};

export default getBoxSize;
