import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Crown = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.6359 2.99531C11.0123 2.6414 11.5298 2.44406 12.0464 2.45859C12.5653 2.46796 13.0753 2.69156 13.4353 3.06515C13.6083 3.2414 13.7325 3.45656 13.8703 3.66C14.7356 4.96078 15.5991 6.26343 16.4667 7.56281C17.3292 7.08703 18.173 6.5775 19.0294 6.09C19.5352 5.81296 20.1605 5.77078 20.7009 5.9714C21.2147 6.1575 21.6459 6.55828 21.8667 7.05796C22.0453 7.45218 22.0889 7.90265 22.0008 8.32546C21.5527 10.5572 21.1041 12.7889 20.6559 15.0206C20.558 15.4537 20.5238 15.9159 20.2894 16.3045C19.9439 16.9116 19.2623 17.3062 18.563 17.3002C14.1877 17.2992 9.81235 17.3016 5.43657 17.2987C4.69172 17.3025 3.97125 16.8473 3.65157 16.1752C3.50578 15.8859 3.465 15.5606 3.39844 15.2475C2.93016 12.9394 2.46844 10.6303 1.99594 8.32312C1.89 7.9139 1.90219 7.47234 2.05688 7.07718C2.2486 6.56343 2.66391 6.14156 3.17203 5.93671C3.72 5.71031 4.37016 5.74593 4.88719 6.0375C5.73985 6.55593 6.59813 7.06593 7.45688 7.57406C8.33203 6.31359 9.18516 5.03671 10.0523 3.77015C10.2394 3.50671 10.3945 3.21515 10.6359 2.99531ZM9.17203 8.70468C8.85469 9.16968 8.33766 9.49078 7.77985 9.56906C7.30969 9.64078 6.81891 9.53578 6.41297 9.28921C5.68125 8.84953 4.9486 8.41171 4.21782 7.97015C4.01907 7.82578 3.77344 7.9739 3.55969 8.01C3.705 7.995 3.82875 7.86562 3.97266 7.83984C4.03641 8.08406 4.09875 8.4375 4.14938 8.685C4.59047 10.875 5.02735 13.0655 5.46985 15.255C9.80063 15.2662 14.1314 15.255 18.4622 15.2601C18.4908 15.1472 18.5194 15.0337 18.5428 14.9198C19.0195 12.5972 19.5216 10.2802 19.9894 7.95609C19.3411 8.29687 18.7102 8.67 18.0675 9.02203C17.6836 9.22734 17.3142 9.49031 16.8745 9.55874C16.1138 9.70359 15.2906 9.36703 14.8481 8.73187C13.8928 7.33546 12.96 5.92359 12 4.53046C11.047 5.91468 10.1184 7.31578 9.17203 8.70468Z"
        fill="#FF0032"
      />
      <path
        d="M4.75031 18.532C4.95984 18.4828 5.17687 18.503 5.39015 18.4997C9.92203 18.5006 14.4539 18.4992 18.9853 18.5006C19.328 18.4941 19.6659 18.675 19.8455 18.968C20.0498 19.2834 20.0498 19.7166 19.8455 20.032C19.6655 20.3241 19.328 20.505 18.9853 20.4994C14.3442 20.5012 9.70312 20.4989 5.06203 20.5008C4.81687 20.5064 4.56656 20.438 4.37578 20.2805C4.10578 20.0681 3.96187 19.7114 4.00875 19.3716C4.05422 18.9755 4.36265 18.6262 4.75031 18.532Z"
        fill="#FF0032"
      />
    </SvgIcon>
  );
};

export default Crown;
