import styled from "@emotion/styled";

export const STop = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 24px;
  justify-content: space-between;
`;

export const SWrap = styled.div`
  max-width: 100%;
  padding: 48px 32px 0px;
  text-align: left;
`;
