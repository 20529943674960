import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Accordion } from "@mui/material";

export const SText = styled.div`
  position: relative;
  font-size: 1rem;
  font-weight: 700;
  white-space: nowrap;
`;

export const SAccordion = styled(Accordion)<{ expanded?: boolean }>`
  z-index: 1;
  position: absolute;
  left: 0;
  top: 92px;
  min-height: 0;
  height: auto;
  width: 100%;
  padding: 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  ${(props) =>
    props.expanded
      ? css`
          border-top: 1px solid ${props.theme.mtsColor.grey.greyLight};
        `
      : ""};

  &.MuiAccordion-root {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: none;
    margin: 0;
    &:before {
      display: none;
    }
  }
`;

export const SGroup = styled.div<{ isOk?: boolean }>`
  min-width: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 4px;
  ${(props) =>
    props.isOk
      ? css`
          color: ${props.theme.mtsColor.text.positive.lightMode};
        `
      : ""}

  & > span {
    color: inherit;
  }
`;

export const SCont = styled.div`
  flex: 1 1 100%;
  padding: 12px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  text-align: left;
  cursor: pointer;

  & > section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }
`;

export const SItems = styled.div`
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-of-type) {
      padding: 4px 0;
    }

    &:last-of-type {
      padding: 4px 0 0;
    }

    & > span:first-of-type {
      color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid ${(props) => props.theme.mtsColor.background.secondary.lightMode};
    }
  }
`;

export const SItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-of-type) {
    padding: 4px 0;
  }

  &:last-of-type {
    padding: 4px 0 0;
  }

  & > span:first-of-type {
    color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid ${(props) => props.theme.mtsColor.background.secondary.lightMode};
  }
`;
