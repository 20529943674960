import { useContext } from "react";

import { ContextSettings } from "context";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { Spacer } from "components/spacer/spacer.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { cards, links_instruction } from "./const";
import { SCard, SFile, SFiles, SSection, SSubtitle } from "./styles";
import Icon from "components/icons";

export const AskueInstruction = () => {
  const { isMobile } = useContext(ContextSettings);
  return (
    <>
      <SSection>
        <Breadcrumbs links={links_instruction} />
        <CusTypo variant={!isMobile() ? "h2_medium" : "h4_medium"} font="wide">
          Инструкции
        </CusTypo>
        <Spacer value="48px" />
        {cards.map((item, i) => (
          <>
            <SCard>
              <CusTypo variant={!isMobile() ? "h3_medium" : "p3_medium"} font="comp">
                {item.title}
              </CusTypo>
              {item.subtitle ? (
                <>
                  <Spacer value="8px" />
                  <SSubtitle variant="c1_regular" font="comp">
                    {item.subtitle}
                  </SSubtitle>
                </>
              ) : null}
              <Spacer value="16px" />
              <SFiles>
                {item.files.map((a, index) => (
                  <LinkFile title={a.name} href={a.href} size={a.size} />
                ))}
              </SFiles>
            </SCard>
            {cards.length - 1 !== i ? <Spacer value="16px" /> : null}
          </>
        ))}
        <Spacer value="48px" />
      </SSection>
    </>
  );
};

interface IProp {
  title: string;
  href: string;
  size: string;
}

export const LinkFile: React.FC<IProp> = ({ title, href, size }) => {
  const { isMobile } = useContext(ContextSettings);
  return (
    <SFile href={href} target="_blank" rel="noreferrer">
      <Icon.PDF />
      <CusTypo variant={!isMobile() ? "p3_regular" : "p4_regular"}>{title}</CusTypo>{" "}
      <CusTypo variant={!isMobile() ? "p3_regular" : "p4_regular"}>(pdf, {size})</CusTypo>
    </SFile>
  );
};
