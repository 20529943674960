import * as yup from "yup";
import { EAskueMngStatus } from "interface/askue";

const actionsetByStatus = (val) => {
  switch (val) {
    case EAskueMngStatus["В работе"]:
      return ["Принять", "Отклонить", "Скачать список устройств"];
    case EAskueMngStatus["Принята"]:
      return ["Редактировать статус", "Скачать список устройств"];
    case EAskueMngStatus["Отклонена"]:
      return ["Редактировать статус", "Скачать список устройств"];
    default:
      return ["Удалить"];
  }
};

export const codeByStatus = {
  Отклонена: "reject",
  Принята: "accept",
  "В работе": "in_work",
};

export const statusByAction = {
  Принять: "Принята",
  Отклонить: "Отклонена",
  "Редактировать статус": "В работе",
};

const statusWithoutModal = "Скачать список устройств";

export const makeSet = ({ status, commonCallback, detailCallback }) => {
  return actionsetByStatus(status).reduce((total, amount) => {
    return [
      ...total,
      {
        title: amount,
        onClick: amount === statusWithoutModal ? detailCallback : () => commonCallback(amount),
      },
    ];
  }, [] as any);
};

export const modalTitleByStatus = {
  reject: "Отклонить заявку?",
  accept: "Принять заявку?",
  in_work: "Редактировать статус заявки?",
};

export const modalDescrByStatus = {
  reject: "Пользователю необходимо будет снова скачать шаблон и\u00A0заполнить список устройств",
  accept: "Пользователю станет доступен обновленный список устройств",
};

export const succTitleByStatus = {
  reject: "Заявка отклонена",
  accept: "Заявка принята",
  in_work: "Статус заявки изменен",
};

export const calcShema = (isForDeletion: boolean) => {
  return isForDeletion
    ? yup.object({
        user_comment: yup
          .string()
          .required()
          .test(
            "correctComment",
            "Комментарий должен быть осмысленным",
            (a) => (a ?? "").length > 5,
          ),
      })
    : yup.object({ user_comment: yup.string().notRequired().optional() });
};
