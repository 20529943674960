import { compareAsc, parse } from "date-fns";
import rgba from "polished/lib/color/rgba";
import { chartColor1, chartColor2 } from "components/chart/styles";

import { EChartRates } from "interface/common";
import { IDemoChartResponse } from "interface/management";

type IRate = "common" | "day" | "night";
const filterbyRate = (somearr: IDemoChartResponse[], rate: IRate) =>
  somearr
    .filter((a) => a.n_rate === +EChartRates[rate])
    .map((a) => ({
      ...a,
      measuredate: parse(a.measuredate, "EEE, dd MMMM yyyy HH:mm:ss 'GMT'", new Date()),
    }))
    .sort((a, b) => compareAsc(a.measuredate as any, b.measuredate as any));

export const makeDemoChartData = (rawData: IDemoChartResponse[]) => {
  const dayDraft = filterbyRate(rawData, "day");
  const nightDraft = filterbyRate(rawData, "night");
  const labels = [...dayDraft, ...nightDraft].map((a) => a.measuredate);
  const dayData = dayDraft.map((a) => +a.val);
  const nightData = nightDraft.map((a) => +a.val);

  return {
    labels,
    datasets: [
      {
        type: "line",
        id: 1,
        label: "День",
        data: dayData,
        pointBorderColor: "rgba(0, 0, 0, 0)",
        pointBackgroundColor: "rgba(0, 0, 0, 0)",
        borderColor: rgba(chartColor1, 1.0),
        pointHoverBorderColor: rgba(chartColor1, 1.0),
        borderWidth: 1,
      },
      {
        type: "line",
        id: 2,
        label: "Ночь",
        data: nightData,
        pointBorderColor: "rgba(0, 0, 0, 0)",
        pointBackgroundColor: "rgba(0, 0, 0, 0)",
        borderColor: chartColor2,
        pointHoverBorderColor: chartColor2,
        borderWidth: 1,
      },
    ],
  };
};
