import { useContext } from "react";
import { isEmpty } from "lodash";

import { ContextSettings } from "context";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { Spacer } from "components/spacer/spacer.component";
import { useAskueServer, useGetAskue } from "hooks/api/askue";
import { Loader } from "components/loader/loader";
import { AskueSummary } from "components/askue/summary/askueSummary";
import { AskueServer } from "components/askue/askueServer/askueServer";
import { CusTypo } from "components/cusTypo/custom-typography";
import { links_root } from "./const";
import { SSection, SSumProblem } from "./styles";
import { NotifyBlock } from "components/notification-block/notification-block";

export const AskuePage = () => {
  const { isMobile } = useContext(ContextSettings);
  const info = useGetAskue();
  const server = useAskueServer();

  return (
    <>
      <SSection>
        <Breadcrumbs links={links_root} />
        <div>
          <CusTypo variant={!isMobile() ? "h2_medium" : "h4_medium"} font="wide">
            Общая информация
          </CusTypo>
          <Spacer value="48px" />
        </div>
        {info.isLoading ? (
          <div style={{ height: 100 }}>
            <Loader />
          </div>
        ) : info.isSuccess && !isEmpty(info.data.meter_types) ? (
          <AskueSummary data={info.data} />
        ) : (
          info.isError && (
            <NotifyBlock content={server.error?.description ?? "Нет данных"} status="error" />
          )
        )}
        <Spacer value="64px" />
        {server.isSuccess && !isEmpty(server.data.report_server_activity) ? (
          <>
            <CusTypo variant={!isMobile() ? "h2_medium" : "h4_medium"} font="wide">
              Отчет о работе сервера
            </CusTypo>
            <Spacer value="48px" />
            <AskueServer data={server.data} />
            <Spacer value="48px" />
          </>
        ) : server.isError &&
          server.error?.description !== null ? null : server.isLoading ? null : (
          <SSumProblem isErr />
        )}
        <Spacer value="64px" />
      </SSection>
    </>
  );
};
