import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";

import { useManagementUsers } from "hooks/api/management";
import { useDebounce } from "hooks/useDebounce";
import { Loader } from "components/loader/loader";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { Footer } from "components/footer/footer";
import { MTSButton } from "components/mts-button/mts-button";
import { FormAddUser } from "components/forms/user/form-add-user";
import { MTSModal } from "components/mts-modal/mts-modal";
import Icon from "components/icons";
import { ContextSettings } from "context/context-settings";
import { UsersTable } from "components/management-users/usersTable/usersTable.component";
import { MTSInput } from "components/mts-input";
import { TableFilter } from "components/table/table-filter/table-filter";
import { TableSort } from "components/table/table-sort/table-sort";
import { ILocationState } from "interface/common";
import { CusTypo } from "components/cusTypo/custom-typography";
import {
  FILTER_BY_ROLES,
  HARDCODED_FILTER_ITEMS,
  TABLE_SORT_DEFAULT_USERS,
  calcBreadcrumbs,
} from "./const";
import { SBtns, SItem, SSettings, STitle, STop, SWrap } from "./styles";

export const ManagementUsers = () => {
  const locationState = useLocation().state as ILocationState;
  const { isDesktop } = useContext(ContextSettings);
  const [showModal, setShowModal] = useState(false);
  const [searchVal, setSearch] = useState("");
  const [dataLength, setDataLength] = useState(undefined);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [columnFilter, setColumnFilter] = useState(undefined);
  const [columnSort, setColumnSort] = useState<any>([]);
  const [isOpenSort, setIsOpenSort] = useState(false);

  const { data, isLoading } = useManagementUsers();

  const toggleShowModal = () => setShowModal(!showModal);

  const handleInput = (e) => {
    setSearch(e.target.value);
  };

  const debouncedSearch = useDebounce(searchVal, 500);

  const handleColumnFilter = ({ filtersArr }) => {
    setColumnFilter(filtersArr);
  };

  const handleColumnSort = (sorting) => setColumnSort(sorting);

  const getDataLength = (a) => setDataLength(a.length);

  const implicitFilterUsers =
    locationState?.tenant_id != null
      ? data?.filter((a) => a?.tenant?.id === locationState?.tenant_id) ?? []
      : data ?? [];

  return (
    <>
      <div>
        <SWrap>
          <Breadcrumbs
            links={calcBreadcrumbs(
              locationState?.tenant_name ||
                (locationState?.tenant_id ? `id организации ${locationState?.tenant_id}` : null),
            )}
          />
          <STop>
            <STitle>
              <CusTypo variant={isDesktop() ? "h2_medium" : "h4_medium"} font="wide">
                Пользователи
              </CusTypo>
              <MTSButton
                size="S"
                variant="primary"
                onClick={toggleShowModal}
                format="icon"
                icon={<Icon.Plus sx={{ width: 24, height: 24 }} />}
              />
            </STitle>
            <SSettings>
              <SItem className="searchField">
                <MTSInput
                  searchIcon
                  placeholder="Имя, роль"
                  onChange={handleInput}
                  value={searchVal}
                  size="S"
                />
              </SItem>
            </SSettings>
            <SBtns>
              <SItem>
                <TableFilter
                  className="btns__filter"
                  dataLength={dataLength ?? 0}
                  isOpen={isOpenFilter}
                  isOn
                  defaultFilter={{ [FILTER_BY_ROLES]: [] }}
                  toogleShowFilter={setIsOpenFilter}
                  onApplyFilter={handleColumnFilter}
                  scheme={HARDCODED_FILTER_ITEMS}
                  isSchemeOnly
                />
              </SItem>
              {!isDesktop() ? (
                <SItem>
                  <MTSButton
                    size="S"
                    variant="secondary"
                    onClick={() => setIsOpenSort(true)}
                    format="icon"
                    icon={<Icon.Sort sx={{ width: 16, height: 16 }} />}
                  />
                  <TableSort
                    items={TABLE_SORT_DEFAULT_USERS}
                    isOpen={isOpenSort}
                    close={() => setIsOpenSort(false)}
                    onApplySort={handleColumnSort}
                    view="modal"
                  />
                </SItem>
              ) : null}
            </SBtns>
          </STop>
        </SWrap>
        <UsersTable
          rawData={implicitFilterUsers}
          filterByColumn={columnFilter}
          onRowsChange={getDataLength}
          globalFilter={debouncedSearch}
          sortByColumn={columnSort}
          savedId={Number(locationState?.e_object_id)}
        />
        <MTSModal open={showModal} close={toggleShowModal}>
          <FormAddUser
            onCancel={toggleShowModal}
            tenantId={locationState?.tenant_id}
            isCanChangePreselected={locationState?.tenant_id != null}
          />
        </MTSModal>
      </div>
      <Footer />
      {isLoading ? <Loader isFullPage /> : null}
    </>
  );
};
