import { useState, useRef, useContext } from "react";

import { MTSModal } from "components/mts-modal/mts-modal";
import { PopupAction } from "components/popup-action/popup-action";
import { invalidateManagementAskue } from "hooks/api/askue";
import { statusByAction, makeSet } from "./utils";
import { StatusModal } from "./statusModal.component";
import { IAskueManagementResponse, EAskueMngStatus } from "interface/askue";
import { askueMngGetDeviceListXLSX } from "services/management-askue";
import { ContextSettings } from "context/context-settings";
import { ModalAction } from "components/modal-action/modal-action";

export const SelectAction: React.FC<IAskueManagementResponse> = ({
  appl_id,
  status,
  tenant_name,
  created_time,
}) => {
  const { isMobile } = useContext(ContextSettings);
  const [isOpenModal, setOpenModal] = useState(false);
  const [nextStatus, setNextStatus] = useState<keyof typeof EAskueMngStatus>();

  const refOne = useRef<HTMLDivElement>(null);

  const handleAction = (arg) => {
    setOpenModal(true);
    setNextStatus(statusByAction[arg]);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    invalidateManagementAskue();
  };

  return (
    <div onClick={(e) => e.stopPropagation()} ref={refOne} className="popup-container">
      {isMobile() ? (
        <ModalAction
          items={makeSet({
            status,
            commonCallback: handleAction,
            detailCallback: () => askueMngGetDeviceListXLSX(appl_id),
          })}
          isLight
        />
      ) : (
        <PopupAction
          view="light"
          items={makeSet({
            status,
            commonCallback: handleAction,
            detailCallback: () => askueMngGetDeviceListXLSX(appl_id),
          })}
        />
      )}
      <MTSModal open={isOpenModal} close={handleModalClose}>
        {nextStatus ? (
          <StatusModal
            id={appl_id}
            tenantTitle={tenant_name}
            nextStatus={nextStatus}
            onCancel={handleModalClose}
          />
        ) : null}
      </MTSModal>
    </div>
  );
};
