import styled from "@emotion/styled";
import { CusTypo } from "components/cusTypo/custom-typography";

import { EDevicesCategory } from "components/forms/shop/const";

const MAGIC_OFFSET = "-21px";

export const SName = styled.div<{ isHide: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: ${MAGIC_OFFSET};
  height: 100%;
  ${(props) =>
    props.isHide &&
    `
		filter: opacity(.4);
  `};
`;

export const SImg = styled.div`
  width: 64px;
  height: 64px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;

  & > img {
    height: 100%;
  }
`;

export const DeviceName = ({ data }) => {
  const { name, category, image_url, hide_flag } = data.original;
  return (
    <SName isHide={hide_flag}>
      {category !== EDevicesCategory["Услуги и лицензии"] ? (
        <SImg>
          <img src={image_url} alt={name} />
        </SImg>
      ) : null}
      <CusTypo variant="p4_bold">{name}</CusTypo>
    </SName>
  );
};

export const SActions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  padding-right: 24px;
`;
