import ok_large from "../svg/ok_large.svg";
import ok_medium from "../svg/ok_medium.svg";
import ok_small from "../svg/ok_small.svg";
import { CheckboxSize } from "../types";

const getBoxSize = (boxSize: string) => {
  switch (boxSize) {
    case CheckboxSize.SMALL_16:
      return ok_small;
    case CheckboxSize.MEDIUM_24:
      return ok_medium;
    case CheckboxSize.LARGE_32:
      return ok_large;
    default:
      return ok_small;
  }
};

export default getBoxSize;
