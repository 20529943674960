import { useContext, useMemo } from "react";

import { IShopProduct } from "interface/management";
import { ITableFilterClm, ITableSortColumn, Table } from "components/table/table.component";
import { ContextSettings } from "context/context-settings";
import { MobileTable } from "components/table/mobile-table/mobileTable.component";
import { constructColumns } from "./utils";
import { tableMobStructure, tableStructure } from "./const";

interface IProp {
  rawData: IShopProduct[];
  filterByColumn?: ITableFilterClm[];
  globalFilter?: string;
  onRowsChange?: Function;
  sortByColumn?: ITableSortColumn[];
}
export const ShopDevicesTable: React.FC<IProp> = ({ rawData, globalFilter, sortByColumn }) => {
  const { isDesktop } = useContext(ContextSettings);
  const tableData = useMemo(() => rawData ?? [{}], [rawData]);
  const columns = useMemo(() => constructColumns({ columns: tableStructure }), []);
  const mobileColumns = useMemo(() => tableMobStructure, []);
  const defaultSortBy = useMemo(
    () => [
      {
        id: "hide_flag",
      },
    ],
    [],
  );

  return isDesktop() ? (
    <Table
      view="expandable"
      columns={columns}
      data={tableData}
      globalFilter={globalFilter}
      sortBy={defaultSortBy}
      height={516}
    />
  ) : (
    <MobileTable
      columns={mobileColumns}
      data={tableData}
      globalFilter={globalFilter}
      sortByColumn={sortByColumn}
      sortBy={defaultSortBy}
    />
  );
};
