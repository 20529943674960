import styled from "@emotion/styled";

export const SField = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
`;

export const SBtns = styled.div`
  display: grid;
  flex-flow: column nowrap;
  gap: 8px;
`;
