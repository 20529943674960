import { useMutation, useQuery } from "react-query";

import {
  ICreateCheckTaskRequest,
  IDeparturesRequest,
  IEmployees,
  IMapMarker,
  IMapMiner,
  IMinersRequest,
  IMiningArchiveResponse,
  IMiningInfoResponse,
} from "interface/map";
import { getMiningArchive, getMiningInfo, tempFormUpload } from "services/mining";
import {
  createCheckTask,
  deleteDeparture,
  getDeparturesMarkers,
  getEmployees,
  getMiners,
} from "services/map";
import { Keys } from "./const";

export function useTempFormUpload() {
  return useMutation<any, any, any>((a) => tempFormUpload(a));
}

export function useDeparturesMarkers() {
  return useMutation<IMapMarker[], any, IDeparturesRequest>((a) => getDeparturesMarkers(a), {
    mutationKey: [Keys.MAP_DEPARTURES],
  });
}

export function useMiningMarkers() {
  return useMutation<IMapMiner[], any, IMinersRequest>((a) => getMiners(a), {
    mutationKey: [Keys.MINING, "map"],
  });
}

export function useDeleteDeparture() {
  return useMutation<{}, any, { idPP: number[] }>((a) => deleteDeparture(a), {
    mutationKey: [Keys.MAP_DEPARTURES, "delete"],
  });
}

export function useMiningInfo() {
  return useQuery<any, any, IMiningInfoResponse>([Keys.MINING, "info"], () => getMiningInfo());
}

export function useMiningArchive() {
  return useQuery<any, any, IMiningArchiveResponse>([Keys.MINING, "archive"], () =>
    getMiningArchive(),
  );
}

export function useGetEmployees() {
  return useQuery<any, any, IEmployees[]>([Keys.TASKS, "employees"], () => getEmployees());
}

export function useCreateCheckTask() {
  return useMutation<any, any, ICreateCheckTaskRequest>((a) => createCheckTask(a));
}
