import { IHttpMethods } from "stream-constants/server-constants";
import {
  GET_BILL_TARIFF,
  GET_BILLING_LIST,
  GET_XLSX_BILING,
  SUBMIT_BILL_TARIFF,
} from "services/urls";
import { fetchData } from "services/services-utils/fetch-data";

import { base } from "./mock-data";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { ITariffReq } from "interface/management";

export const getManagementBilling = async () => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = base;
  } else {
    result = await fetchData({
      url: GET_BILLING_LIST,
      method: IHttpMethods.GET,
      name: "getManagementBilling",
      emptyResponse: false,
    });
  }

  return result;
};

export const getXlsxBilling = async (id: number) => {
  return await fetchData({
    url: `${GET_XLSX_BILING}/${id}`,
    method: IHttpMethods.GET,
    name: "downloadFileBilling",
    emptyResponse: false,
    blobResponse: true,
    responseAbordTimeOut: false,
  }).then((blob) => {
    return blob;
  });
};

export const getBillingTariff = async () => {
  return await fetchData({
    url: GET_BILL_TARIFF,
    method: IHttpMethods.GET,
    name: "getBillingTariff",
    emptyResponse: false,
  });
};

export const billingAddTariff = async (data: ITariffReq) => {
  return fetchData({
    url: SUBMIT_BILL_TARIFF,
    method: IHttpMethods.POST,
    name: "billingAddTariff",
    body: JSON.stringify(data),
    emptyResponse: false,
  });
};
