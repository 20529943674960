import { FC, useState, useEffect } from "react";
import { isEmpty } from "lodash";

import { useBreakpoint } from "hooks/useBreakpoint";
import { IProfileStruct } from "interface/common";
import { IDeviceList } from "interface/anomalies";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { LIST_ROLES } from "utils/roles";
import { ContextSettings } from "./context-settings";

const api_admin_prefix = "/admin";

export const ContextProvider: FC = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [profile, setProfile] = useState<IProfileStruct | null>(null);
  const [roles, setRoles] = useState<LIST_ROLES[]>([]);
  const [filteringDeviseList, setFilteringDeviseList] = useState<IDeviceList[] | []>([]);
  const [mock_mode, setMocks] = useState("off");

  const { isDesktop, isMobile, isTablet, getWidth } = useBreakpoint();

  const toggleMocks = () => {
    const localMock = StorageAPI.get(EStorageKeys.MOCKS_STATUS);
    const res = !localMock ? "off" : localMock === "on" ? "off" : "on";

    StorageAPI.save(EStorageKeys.MOCKS_STATUS, res);
    setMocks(res);
  };

  useEffect(() => {
    const localMock = StorageAPI.get(EStorageKeys.MOCKS_STATUS);
    if (localMock) {
      setMocks(localMock);
    } else {
      setMocks("off");
      StorageAPI.save(EStorageKeys.MOCKS_STATUS, "off");
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(roles)) {
      if (roles.includes(LIST_ROLES.ADMIN) || roles.includes(LIST_ROLES.ANALYST_SYSTEM)) {
        StorageAPI.save(EStorageKeys.API_PREFIX, api_admin_prefix);
      } else {
        StorageAPI.remove(EStorageKeys.API_PREFIX);
      }
    }
  }, [roles]);

  const value = {
    isAuthorized,
    setIsAuthorized,
    profile,
    setProfile,
    roles,
    setRoles,
    filteringDeviseList,
    setFilteringDeviseList,
    mock_mode,
    toggleMocks,
    isDesktop,
    isMobile,
    isTablet,
    getWidth,
  };

  return <ContextSettings.Provider value={value}>{children}</ContextSettings.Provider>;
};

ContextProvider.displayName = "ContextProvider";
