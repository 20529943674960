import { format } from "date-fns";
import { MTSStatus } from "components/mts-status/mts-status";
import { getPhoneWithSpaces } from "utils/formatters";
import { SelectAction, Expandable } from "./subComponents/subComponents";
import { CusTypo } from "components/cusTypo/custom-typography";

export const constructColumns = (data) => {
  const { columns, selectedId } = data;
  return columns.map((singleColumn) => {
    return {
      ...singleColumn,
      ...(singleColumn.accessor === "title"
        ? {
            Cell: ({ value, row }) => (
              <Expandable value={value} row={row} isOpen={selectedId?.[row.original.id] ?? false} />
            ),
          }
        : singleColumn.accessor === "phone"
        ? {
            Cell: ({ value }) => <CusTypo variant="p3_medium">{getPhoneWithSpaces(value)}</CusTypo>,
          }
        : singleColumn.accessor === "status"
        ? { Cell: ({ value }) => MTSStatus(value ?? "-") }
        : singleColumn.accessor === "action"
        ? {
            Cell: ({ row }) => (
              <SelectAction
                id={row.original.id}
                status={row.original.status}
                title={row.original.title}
              />
            ),
          }
        : singleColumn.accessor === "created_time"
        ? { Cell: ({ value }) => format(new Date(value ?? 0), "dd.MM.yyyy") }
        : {}),
    };
  });
};
