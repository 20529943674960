import { IHttpMethods } from "stream-constants/server-constants";
import { FILTERS_PO_RESEARCH, FILTERS_RES_RESEARCH } from "services/urls";
import { fetchData } from "services/services-utils/fetch-data";

import { mock_po, mock_priem } from "./mock-data";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";

export const getResearchPriemList = async (data: string[] = []) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = mock_priem;
  } else {
    result = fetchData({
      url: FILTERS_RES_RESEARCH,
      method: IHttpMethods.POST,
      name: "getResearchPriemList",
      body: JSON.stringify({ list_po: data }),
      emptyResponse: false,
    });
  }

  return result;
};

export const getResearchPoList = async () => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = mock_po;
  } else {
    result = fetchData({
      url: FILTERS_PO_RESEARCH,
      method: IHttpMethods.GET,
      name: "getResearchPoList",
      emptyResponse: false,
    });
  }

  return result;
};
