import { useContext, useState } from "react";
import { PopupAction } from "components/popup-action/popup-action";
import { MTSModal } from "components/mts-modal/mts-modal";
import { LANDING_SHOP_URL, LANDING_URL } from "services/urls";
import { ChangeShowProdForm } from "components/management-shop/actionForms/hideForm";
import { FormEditProduct } from "components/forms/shop/form-edit-product";
import { invalidateManagmentShop } from "hooks/api/management";
import { EDevicesCategory } from "components/forms/shop/const";
import { ContextSettings } from "context/context-settings";
import { ModalAction } from "components/modal-action/modal-action";

export const ProductDeskAction = ({ data }) => {
  const { isMobile } = useContext(ContextSettings);
  const { id, hide_flag, category, redirect } = data.original;
  const [showModalHideProd, setShowModalHideProd] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const toggleShowModalHideProd = () => setShowModalHideProd(!showModalHideProd);
  const toggleShowModalEdit = () => setShowModalEdit(!showModalEdit);
  const [isNotifyModal, setIsNotifyModal] = useState(false);

  const editFunc = {
    title: "Редактировать",
    onClick: toggleShowModalEdit,
  };

  const navFunc = {
    title: "Перейти в магазин",
    onClick: () =>
      category === EDevicesCategory["Услуги и лицензии"]
        ? window.open(`${LANDING_URL}/${redirect!}`)
        : window.open(`${LANDING_SHOP_URL}/${id}`),
  };

  const dataPopup = [
    {
      title: hide_flag ? "Показать" : "Скрыть",
      onClick: () => toggleShowModalHideProd(),
    },
  ]
    .concat([editFunc])
    .concat([navFunc]);

  return (
    <div>
      {isMobile() ? (
        <ModalAction items={dataPopup} />
      ) : (
        <PopupAction items={dataPopup} view="light" />
      )}
      <MTSModal open={showModalHideProd} close={() => setShowModalHideProd(false)} size="S">
        <ChangeShowProdForm
          id={id}
          isHide={data.original.hide_flag}
          onCancel={() => setShowModalHideProd(false)}
        />
      </MTSModal>
      <MTSModal
        open={showModalEdit}
        close={() => {
          setShowModalEdit(false);
          setIsNotifyModal(false);
          invalidateManagmentShop();
        }}
        size={isNotifyModal ? "S" : "L"}
      >
        <FormEditProduct
          onCancel={toggleShowModalEdit}
          prodData={data.original}
          setIsNotifyModal={setIsNotifyModal}
        />
      </MTSModal>
    </div>
  );
};
