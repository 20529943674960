import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Quit = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M22 44C34.1503 44 44 34.1503 44 22C44 9.84974 34.1503 0 22 0C9.84974 0 0 9.84974 0 22C0 34.1503 9.84974 44 22 44Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 13C23.7614 13 26 15.2386 26 18C26 18.5523 25.5523 19 25 19C24.4872 19 24.0645 18.614 24.0067 18.1166L24 18C24 16.4023 22.7511 15.0963 21.1763 15.0051L21 15H19C17.4023 15 16.0963 16.2489 16.0051 17.8237L16 18V26C16 27.5977 17.2489 28.9037 18.8237 28.9949L19 29H21C22.5977 29 23.9037 27.7511 23.9949 26.1763L24 26C24 25.4477 24.4477 25 25 25C25.5523 25 26 25.4477 26 26C26 28.6888 23.8777 30.8818 21.2169 30.9954L21 31H19C16.3112 31 14.1182 28.8777 14.0046 26.2169L14 26V18C14 15.3112 16.1223 13.1182 18.7831 13.0046L19 13H21ZM29.6129 19.2097L29.7071 19.2929L31.7071 21.2929C31.7425 21.3283 31.7747 21.3657 31.8037 21.4047L31.8753 21.5159L31.9288 21.6287L31.9642 21.734L31.9931 21.8819L32 22L31.9972 22.0752L31.9798 22.2007L31.9503 22.3121L31.9063 22.4232L31.854 22.5207L31.7803 22.6254L31.7071 22.7071L29.7071 24.7071C29.3166 25.0976 28.6834 25.0976 28.2929 24.7071C27.9324 24.3466 27.9047 23.7794 28.2097 23.3871L28.2929 23.2929L28.584 23H23C22.4477 23 22 22.5523 22 22C22 21.4872 22.386 21.0645 22.8834 21.0067L23 21H28.585L28.2929 20.7071C27.9324 20.3466 27.9047 19.7794 28.2097 19.3871L28.2929 19.2929C28.6534 18.9324 29.2206 18.9047 29.6129 19.2097Z"
        fill="#969FA8"
      />
    </SvgIcon>
  );
};

export default Quit;
