import { Footer } from "components/footer/footer";
import { Breadcrumbs, IBreadcrumbsLink } from "components/breadcrumbs/breadcrumbs";
import { ROUTE_PERSONAL_AREA, ROUTE_SECURITY } from "stream-constants/route-constants";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SMain, SContainer, SArticle, SStep, SWrap, SImg, SLink } from "./styles";

import img1 from "./images/security_1.png";
import img2 from "./images/security_2.png";

const linksBreadcrumbs: IBreadcrumbsLink[] = [
  { name: "Безопасность", href: `${ROUTE_PERSONAL_AREA}/${ROUTE_SECURITY}` },
];

export const Security = () => (
  <>
    <SMain>
      <SContainer>
        <SArticle>
          <Breadcrumbs links={linksBreadcrumbs} />

          <CusTypo variant="h2_bold" font="wide">
            Двухфакторная аутентификация отключена
          </CusTypo>
          <SWrap>
            <SStep>
              <CusTypo variant="p4_regular">
                В&nbsp;целях безопасности вашей учетной записи рекомендуем установить способ входа
                при помощи <b>пароля и&nbsp;кода из&nbsp;SMS</b>.
                <br />
                Для изменения настроек входа выполните следующие шаги:
              </CusTypo>
            </SStep>
            <SStep>
              <CusTypo variant="p4_medium" styles={{ marginBottom: "8px" }}>
                1. Перейдите в&nbsp;настройки безопасности профиля МТС по&nbsp;указанной ниже
                ссылке:
              </CusTypo>
              <SLink href="https://profile.mts.ru/account/safety/auth-level" target="_blank">
                <CusTypo variant="p3_regular">
                  https://profile.mts.ru/account/safety/auth-level
                </CusTypo>
              </SLink>
            </SStep>
            <SStep>
              <CusTypo variant="p4_medium" styles={{ marginBottom: "24px" }}>
                2. В&nbsp;открывшемся окне выберите способ входа “Пароль и&nbsp;код из&nbsp;SMS”
                и&nbsp;нажмите “Создать пароль”. <br /> Если у вас уже создан пароль, нажмите
                “Сохранить”.
              </CusTypo>
              <SImg src={img1}></SImg>
            </SStep>
            <SStep>
              <CusTypo variant="p4_medium" styles={{ marginBottom: "24px" }}>
                3. Если вы&nbsp;выбрали “Создать пароль” следуйте инструкциям в&nbsp;появившемся
                окне.
              </CusTypo>
              <SImg src={img2}></SImg>
            </SStep>
          </SWrap>
        </SArticle>
      </SContainer>
    </SMain>

    <Footer />
  </>
);
