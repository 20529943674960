import { FC, Fragment, useContext, useEffect, useMemo, useState } from "react";
import { isEmpty, isEqual } from "lodash";
import { toast } from "react-toastify";

import {
  useLoadFilter,
  useResearchPoList,
  useResearchPriemList,
  useSaveFilter,
} from "hooks/api/analytics";
import { declWords } from "utils/declensionWords";
import { Chip, Resolver } from "./subcomponents";
import { Toast } from "components/toast/toast";
import Icon from "components/icons";
import { Switch } from "components/switch/switch.component";
import { SlideIn } from "components/table/slideIn/slideIn.component";
import { Spacer } from "components/spacer/spacer.component";
import { MTSButton } from "components/mts-button/mts-button";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SFltBtn } from "components/table/table-filter/styles";
import { ContextSettings } from "context/context-settings";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { scheme, PO_COL, RES_COL, headByKey, categories, IMainCats } from "./const";
import { getChipsValue, prepareFilter, renderCatNumbers, renderSubcatNumbers } from "./utils";
import {
  SAmount,
  SBody,
  SButtons,
  SCatTitle,
  SDown,
  SGrey,
  SGreyHead,
  SGrid,
  SResult,
  SWrap,
} from "./styles";

const words = ["результат", "результата", "результатов"];

interface IProp {
  dataLength: number;
  open: () => void;
  close: () => void;
  isOpen: boolean;
  isFilterOn: boolean;
  handleOn: Function;
}

export const AnalFilter: FC<IProp> = ({
  open,
  close,
  isOpen,
  dataLength,
  isFilterOn,
  handleOn,
}) => {
  const { isDesktop } = useContext(ContextSettings);

  const [cat, setCat] = useState<IMainCats>("subdivision");
  const [innerFilter, setInner] = useState<any>({});

  const { data: poList } = useResearchPoList(isOpen);
  const { data: resList } = useResearchPriemList(isOpen, innerFilter?.[PO_COL]);
  const { mutate: saveFilter, isLoading } = useSaveFilter();
  const { data: filter, isSuccess } = useLoadFilter(isFilterOn);

  const handleToggleSwitch = (e) => {
    if (!isFilterOn) {
      StorageAPI.save(EStorageKeys.RESEARCH_FILTER_IS, "on");
      handleOn(true);
    } else {
      StorageAPI.save(EStorageKeys.RESEARCH_FILTER_IS, "off");
      handleOn(false);
    }
  };

  const resetCateg = (a: string) => {
    let final: any[] = [];
    const base = finalScheme[a];
    Object.keys(base).forEach((i) => {
      if (base[i]?.checkbox != null) {
        if (Array.isArray(base[i]?.checkbox)) {
          final = final.concat(base[i]?.checkbox.map((d) => d.name));
        } else final.push(base[i]?.checkbox?.name);
      }
      if (base[i]?.period != null) final.push(base[i]?.period);
      if (base[i]?.range != null) final = final.concat(base[i]?.range);
      if (base[i]?.select != null) final = final.concat(base[i]?.select);
      if (base[i]?.number != null) final = final.concat(base[i]?.number);
    });

    setInner((s) => {
      const copy = { ...s };
      final.forEach((o) => delete copy[o]);
      return copy;
    });
  };

  const innerItems = useMemo(
    () => ({
      subdivision: {
        [PO_COL]: {
          checkbox: {
            name: PO_COL,
            list: poList?.name_po
              ?.filter((a) => a != null)
              .map((item: string | null, index: number) => {
                return {
                  id: index,
                  name: item,
                };
              }),
          },
        },
        [RES_COL]: {
          checkbox: {
            name: RES_COL,
            list: resList?.name_res?.map((item: string, index: number) => {
              return {
                id: index,
                name: item,
              };
            }),
          },
        },
      },
    }),
    [resList, poList],
  );

  const finalScheme = {
    ...innerItems,
    ...scheme,
  };

  useEffect(() => {
    if (isFilterOn) {
      if (isSuccess && !isEmpty(filter)) {
        setInner(filter);
      }
    } else {
      setInner({});
    }
  }, [isSuccess, filter, isFilterOn]);

  const filtPo = filter?.[PO_COL];
  const innerPo = innerFilter?.[PO_COL];

  useEffect(() => {
    if (filtPo != null && innerPo != null && !isEqual(filtPo, innerPo)) {
      setInner((s) => {
        return {
          ...s,
          [RES_COL]: [],
        };
      });
    }
  }, [filtPo, innerPo]);

  return (
    <>
      <SFltBtn withValue={!isEmpty(prepareFilter(innerFilter))} onClick={open}>
        <Icon.Filter sx={{ width: 24, height: 24 }} />
      </SFltBtn>
      <SlideIn
        isOpen={isOpen}
        close={close}
        maxWidth={750}
        headerChildren={
          <>
            <Switch
              id="header__switch"
              setChecked={handleToggleSwitch}
              checked={isFilterOn}
              className="header__switch"
            />
            <CusTypo variant={!isDesktop() ? "h4_medium" : "h3_medium"} font="comp">
              Фильтр
            </CusTypo>
          </>
        }
      >
        <SWrap>
          <section>
            <SCatTitle variant="h4_medium" font="comp">
              Категория
            </SCatTitle>
            {Object.keys(categories).map((c) => (
              <SGrey isActive={cat === c} onClick={() => setCat(c as IMainCats)} key={c}>
                <CusTypo variant="p4_regular">{categories[c]}</CusTypo>
                <SAmount>{renderCatNumbers(finalScheme[c], innerFilter)}</SAmount>
              </SGrey>
            ))}
            <Spacer value="64px" />
            <SButtons>
              <MTSButton
                size="S"
                variant="primary"
                onClick={() =>
                  saveFilter(prepareFilter(innerFilter), {
                    onSuccess: () => {
                      toast(<Toast title="Фильтр применен" />, {
                        progress: undefined,
                        autoClose: 2000,
                        hideProgressBar: true,
                      });
                    },
                  })
                }
                disabled={!isFilterOn}
                loading={isLoading}
              >
                Применить
              </MTSButton>
              <MTSButton
                size="S"
                variant="dark_blue"
                onClick={() =>
                  saveFilter(
                    {},
                    {
                      onSuccess: () => {
                        setInner({});
                        toast(<Toast title="Настройки фильтра сброшены" isError />, {
                          progress: undefined,
                          autoClose: 2000,
                          hideProgressBar: true,
                        });
                      },
                    },
                  )
                }
                disabled={!isFilterOn}
                loading={isLoading}
              >
                Сбросить все
              </MTSButton>
            </SButtons>
            <Spacer value="24px" />
            <SResult>
              <CusTypo variant="c1_regular">Найдено {declWords(dataLength, words)}</CusTypo>
            </SResult>
            <Spacer value="64px" />
            <SGrid>
              {getChipsValue(innerFilter).map((m) => (
                <Chip filterData={innerFilter} filterSetter={setInner} chip={m} key={m.key} />
              ))}
            </SGrid>
          </section>
          <section>
            {Object.entries(finalScheme[cat]).map(([key, val], indx) => (
              <Fragment key={`${key}_${indx}`}>
                <SGreyHead>
                  <CusTypo variant="p3_regular">{headByKey[key]}</CusTypo>
                  <SAmount>{renderSubcatNumbers(val, innerFilter)}</SAmount>
                </SGreyHead>
                <SBody>
                  {Object.keys(val)?.map((type, i) => (
                    <Resolver
                      key={`${type}_${i}`}
                      categoryTree={val}
                      filterSetter={setInner}
                      filterData={innerFilter}
                      finalScheme={finalScheme}
                      cat={cat}
                      subcat={key}
                      type={type}
                      disabled={!isFilterOn}
                    />
                  ))}
                </SBody>
                <Spacer value="32px" />
                {Object.entries(finalScheme[cat]).length - 1 === indx ? (
                  <SDown
                    onClick={() => (!isFilterOn ? undefined : resetCateg(cat))}
                    disabled={!isFilterOn}
                  >
                    Сбросить категорию
                  </SDown>
                ) : null}
              </Fragment>
            ))}
          </section>
        </SWrap>
      </SlideIn>
    </>
  );
};
