import { useContext } from "react";
import { ROUTE_DEVICE_LIST_PAGE } from "stream-constants/route-constants";
import { CusTypo } from "components/cusTypo/custom-typography";
import { ContextSettings } from "context/context-settings";
import { iconByStatus } from "./const";
import { SCard, SContent, SMarker, SMarkerCicle, SNumber } from "./styles";

interface SectionTitleProps {
  title: string;
  number: number;
  variant:
    | "toCheck"
    | "normal"
    | "hacking"
    | "suspicion"
    | "noLoad"
    | "noAccess"
    | "defective"
    | "replaced";
  onClick: (e) => void;
}

export const AnomaliesCard: React.FC<SectionTitleProps> = ({
  title,
  number = 0,
  variant = "variant",
  onClick,
}) => {
  const { isDesktop } = useContext(ContextSettings);

  return (
    <SCard to={`/${ROUTE_DEVICE_LIST_PAGE}`} onClick={onClick}>
      <div>
        <SMarker variant={variant}>
          <SMarkerCicle variant={variant} />
        </SMarker>
      </div>
      <SContent>
        <CusTypo
          variant={isDesktop() ? "p1_regular" : "c1_regular"}
          styles={{ whiteSpace: "nowrap" }}
          font="comp"
        >
          {title}
        </CusTypo>
        <SNumber>
          <CusTypo variant={isDesktop() ? "h2_medium" : "h3_medium"} font="wide">
            {number}
          </CusTypo>
        </SNumber>
      </SContent>
      {isDesktop() ? iconByStatus(variant, title) : null}
    </SCard>
  );
};
