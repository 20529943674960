import * as yup from "yup";
import { IRoles } from "interface/common";
import { IMenegementTenantResponse, IUserResponse } from "interface/management";
import { LIST_ROLES, LIST_ROLES_NAME, ROLES_BY_NAME, ROLES_ID } from "utils/roles";
import validationMethod from "../common-validation";

const REQUIRED = "Поле не заполнено";
const INCORRECT = "Некорректно заполнено поле";
export const FALLBACK_MSG = "Что-то пошло не так, попробуйте еще раз";

export const schema = yup.object().shape({
  phone: yup
    .mixed()
    .test("correctPhone", INCORRECT, validationMethod.correctPhone)
    .test("requiredPhone", REQUIRED, validationMethod.requiredPhone),
  email: yup
    .mixed()
    .required(REQUIRED)
    .test("correctEmail", INCORRECT, validationMethod.correctEmail),
  userName: yup
    .mixed()
    .test("requiredUserName", REQUIRED, validationMethod.requiredUserName)
    .test("correctUserName", INCORRECT, validationMethod.correctUserName),
  roles: yup.array().nullable().min(1, "Минимум одна роль").required(REQUIRED),
});

export const convertRoleToObject = (arr: string[]) =>
  arr.map((item) => ({
    id: ROLES_ID(item),
    name: ROLES_BY_NAME(item),
  })) as IRoles[];

export const makeDropdownFromTenants = (initial: IMenegementTenantResponse[] | undefined) =>
  initial?.map((a) => a.title) ?? [];

export const convertOrgnameToId = (
  orgName: string,
  referenceArr: IMenegementTenantResponse[] | undefined,
) => referenceArr?.find((a) => a.title === orgName)?.id ?? undefined;

export const convertIdToOrgname = (
  id: string | number,
  referenceArr: IMenegementTenantResponse[] | undefined,
) => referenceArr?.find((a) => a.id === String(id))?.title ?? "";

export const makeDefaultForEdit = (
  data: IUserResponse | null,
  contextRoles,
  booleanFlag = false,
) => {
  return {
    userName: data?.userName,
    phone: data?.phone,
    email: data?.email,
    roles: calcDisplayableRoles(contextRoles, (data?.roles ?? []) as any),
    ...(booleanFlag ? { tenant: data?.tenant?.title } : {}),
  };
};

export const calcDropdownRoles = (contextRoles: LIST_ROLES[]) => {
  const initial = Object.values(LIST_ROLES_NAME).filter((a) => a !== LIST_ROLES_NAME.DEMO_TEST);

  if (contextRoles.includes(LIST_ROLES.OWNER) && !contextRoles.includes(LIST_ROLES.ADMIN)) {
    return initial.filter(
      (a) => a !== LIST_ROLES_NAME.ADMIN && a !== LIST_ROLES_NAME.ANALYST_SYSTEM,
    );
  } else if (!contextRoles.includes(LIST_ROLES.ADMIN)) {
    return initial.filter((a) => a !== LIST_ROLES_NAME.ADMIN);
  } else {
    return initial;
  }
};

// когда роли не английскими кодами, а прямо уже готовыми русскими названиями
export const calcDisplayableCyrillicRoles = (
  contextRoles: LIST_ROLES[],
  userRoles: { id: number; name: string }[],
) => {
  const initial = userRoles.map((a) => a.name);

  if (contextRoles.includes(LIST_ROLES.OWNER) && !contextRoles.includes(LIST_ROLES.ADMIN)) {
    return initial.filter(
      (a) => a !== LIST_ROLES_NAME.ADMIN && a !== LIST_ROLES_NAME.ANALYST_SYSTEM,
    );
  } else if (!contextRoles.includes(LIST_ROLES.ADMIN)) {
    return initial.filter((a) => a !== LIST_ROLES_NAME.ADMIN);
  } else {
    return initial;
  }
};

export const calcDisplayableRoles = (
  contextRoles: LIST_ROLES[],
  userRoles: { id: number; name: string }[],
) => {
  const initial = userRoles.map((a) => LIST_ROLES_NAME[a.name.toUpperCase()]);

  if (contextRoles.includes(LIST_ROLES.OWNER) && !contextRoles.includes(LIST_ROLES.ADMIN)) {
    return initial.filter(
      (a) => a !== LIST_ROLES_NAME.ADMIN && a !== LIST_ROLES_NAME.ANALYST_SYSTEM,
    );
  } else if (!contextRoles.includes(LIST_ROLES.ADMIN)) {
    return initial.filter((a) => a !== LIST_ROLES_NAME.ADMIN);
  } else {
    return initial;
  }
};

export const hasRoleAccess = (
  contextRoles: LIST_ROLES[],
  userRoles: { id: number; name: string }[],
) => {
  const initial = userRoles.map((a) => LIST_ROLES_NAME[a.name.toUpperCase()]);
  if (contextRoles.includes(LIST_ROLES.OWNER) && !contextRoles.includes(LIST_ROLES.ADMIN)) {
    return (
      !initial.includes(LIST_ROLES_NAME.ADMIN) && !initial.includes(LIST_ROLES_NAME.ANALYST_SYSTEM)
    );
  } else if (!contextRoles.includes(LIST_ROLES.ADMIN)) {
    return !initial.includes(LIST_ROLES_NAME.ADMIN);
  } else {
    return true;
  }
};

export const convertRoleToCyr = (role: string) => LIST_ROLES_NAME[role.toUpperCase()];
