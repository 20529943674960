import { ReactNode, useContext } from "react";
import Icon from "components/icons";
import { ContextSettings } from "context/context-settings";
import { SMsg, SRow } from "./styles";

interface IPlugMsg {
  content: string | ReactNode;
  status: "success" | "error" | "warning";
}

export const NotifyBlock = ({ content, status }: IPlugMsg) => {
  const { isMobile } = useContext(ContextSettings);

  return (
    <SRow status={status}>
      <SMsg variant="p4_regular" status={status}>
        {content}
      </SMsg>

      {!isMobile() ? (
        <div style={{ margin: "0 0 0 auto" }}>
          {status === "success" ? (
            <Icon.SuccessShaped sx={{ width: 18, height: 18 }} />
          ) : status === "warning" ? (
            <Icon.Warn sx={{ width: 18, height: 18 }} />
          ) : (
            <Icon.ErrorShaped sx={{ width: 18, height: 18 }} />
          )}
        </div>
      ) : null}
    </SRow>
  );
};
