import { Dispatch, SetStateAction } from "react";
import * as L from "leaflet";
import theme from "theme";
import { uniq } from "lodash";

import { EDepStats, IMapMarker, IMapMiner } from "interface/map";
import { DEPARTURE_RESULT, SERVICES } from "stream-constants/keys-and-constants";

export const markerByStatus = {
  [DEPARTURE_RESULT.SUSPICION]: "ano--yellow",
  [DEPARTURE_RESULT.NORMAL]: "ano--green",
  [DEPARTURE_RESULT.IN_WORK]: "ano--blue",
  [DEPARTURE_RESULT.TO_CHECK]: "ano--blue",
  [DEPARTURE_RESULT.BREAKING]: "ano--red",
  [DEPARTURE_RESULT.NO_ACCESS]: "ano--grey",
  [DEPARTURE_RESULT.DEFECTIVE]: "ano--black",
  [DEPARTURE_RESULT.REPLACED]: "ano--plum",
  [DEPARTURE_RESULT.NO_LOAD]: "",
};

export const markerByDeparture = {
  [EDepStats["Выезд не назначен"]]: "",
  [EDepStats["Проверяется"]]: "dep--blue",
  [EDepStats["Проверка завершена"]]: "dep--green",
};

export const scoreToColor = (val: number) => {
  switch (true) {
    case val >= 22:
      return theme.mtsColor.text.primary.lightMode;
    case val >= 15 && val <= 21:
      return theme.mtsColor.red;
    case val >= 8 && val <= 14:
      return "#F55F7E";
    case val < 8:
    default:
      return theme.mtsColor.accent.warning.lightMode;
  }
};

export const scoreToTitle = (val: number) => {
  switch (true) {
    case val >= 22:
      return "Крайне высокая";
    case val >= 15 && val <= 21:
      return "Высокая";
    case val >= 8 && val <= 14:
      return "Средняя";
    case val < 8:
    default:
      return "Низкая";
  }
};

export const createMarkerIcon = (a) =>
  L.divIcon({
    className: `marker--default ${a}`,
  });

export const marker_default = L.divIcon({
  className: "marker--default",
});

// дефолт центр в районе Йошкар-олы
export const mapOptions = {
  zoom: 10,
  center: {
    lat: 56.6316,
    lng: 47.886178,
  },
  zoomControl: false,
  preferCanvas: true,
};

export interface IMapComponent {
  filterOpen: boolean;
  filterToggle: Dispatch<SetStateAction<boolean>>;
  handleDepFilter: (arg: any) => void;
  depFilter: any;
  handleMiningFilter: (arg: any) => void;
  miningFilter: any;
  isMinersPage: boolean;
  isAnoPage: boolean;
  archive: any[];
}

export type IPopContent = {
  node: Element;
  data: IMapMarker | IMapMiner;
};

export type IFormDepartures = {
  lat: number | null;
  lng: number | null;
  id_pp?: number;
  adress_too?: string | null;
};

export type ISearch = {
  onBtnClick: () => void;
  onItemClick: (a: IFormDepartures) => void;
  list?: IMapMarker[];
  disabled?: boolean;
};

export interface ISel extends L.Marker {
  pointData: IMapMarker;
  _icon: any;
}

export enum ELayers {
  DEP = "switch__departures",
  ANO = "switch__anomalies",
  MIN = "switch__miners",
}

const switchByService = {
  [SERVICES.LOSSES]: [ELayers.DEP, ELayers.ANO],
  [SERVICES.ASKUE]: [],
  [SERVICES.MINING]: [ELayers.MIN],
};

const dependableSwitch = {
  [ELayers.DEP]: ELayers.ANO,
  [ELayers.ANO]: ELayers.DEP,
};

export const calcAvailSwitches = (services: string[]) =>
  uniq(services.map((s) => switchByService[s]).flat());

export const labelBySwitch = {
  [ELayers.DEP]: "Выезды",
  [ELayers.ANO]: "Карта аномалий",
  [ELayers.MIN]: "Майнинговые фермы",
};

export const defaultStateMiners = {
  [ELayers.MIN]: {
    visible: true,
  },
};

const defaultStateDepartures = {
  [ELayers.DEP]: {
    visible: true,
  },
};

const defaultStateAno = {
  [ELayers.ANO]: {
    visible: true,
  },
};

export const composeDefaultState = (isMinersPage, isAnoPage = false) => {
  return isMinersPage ? defaultStateMiners : isAnoPage ? defaultStateAno : defaultStateDepartures;
};

export type IReducerState = {
  [key in ELayers]?: {
    visible: boolean;
  };
};

export const reducer = (state: IReducerState, action: any) => {
  switch (action.type) {
    case "toggle_show":
    default: {
      return {
        ...state,
        ...(action.payload === ELayers.DEP || action.payload === ELayers.ANO
          ? {
              [action.payload]: {
                visible: !state[action.payload]?.visible ?? false,
              },
              [dependableSwitch[action.payload]]: {
                visible: state[action.payload]?.visible
                  ? !state[dependableSwitch[action.payload]]?.visible
                  : false,
              },
            }
          : {
              [action.payload]: {
                visible: !state[action.payload]?.visible ?? false,
              },
            }),
      };
    }
  }
};
