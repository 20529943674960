import { EAskueMngStatus } from "interface/askue";
import { IBreadcrumbsLink } from "interface/common";
import { ROUTE_ASKUE_MANAGEMENT, ROUTE_MANAGEMENT } from "stream-constants/route-constants";

export const links: IBreadcrumbsLink[] = [
  { name: "Управление", href: `/${ROUTE_MANAGEMENT}` },
  { name: "АСКУЭ", href: `/${ROUTE_ASKUE_MANAGEMENT}` },
];

export const HARDCODED_FILTER_ITEMS = {
  checkbox: {
    status: [
      {
        id: 0,
        name: EAskueMngStatus["В работе"],
      },
      {
        id: 1,
        name: EAskueMngStatus["Отклонена"],
      },
      {
        id: 2,
        name: EAskueMngStatus["Принята"],
      },
    ],
  },
  radio: {},
};

export const TABLE_SORT_DEFAULT_ASKUE = [
  {
    accessor: "tenant_name",
    text: "Организация",
  },
  {
    accessor: "created_time",
    text: "Дата и время",
  },
  {
    accessor: "status",
    text: "Статус",
  },
  {
    accessor: "comment",
    text: "Сумма",
  },
];
