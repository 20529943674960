import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Askue = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.90214 3.03584C4.05516 3.00819 4.21154 3.00162 4.36695 3.00022C9.45114 3.00022 14.5358 3.00022 19.6205 3.00022C19.9639 2.996 20.3102 3.05272 20.6249 3.19147C21.4322 3.52944 21.9939 4.35256 21.9978 5.21272C22.0011 6.48772 22.0002 7.76272 21.9983 9.03772C21.9959 9.67991 21.6937 10.3118 21.1924 10.7266C20.7736 11.0824 20.2191 11.2722 19.6655 11.268C14.6303 11.2704 9.59456 11.268 4.55883 11.2694C4.23696 11.2732 3.90886 11.261 3.6009 11.1588C2.66167 10.879 1.97812 9.95928 2.00067 8.99881C2.00019 7.74959 1.99923 6.49991 2.00115 5.25069C1.99971 4.70178 2.21173 4.15616 2.58924 3.74975C2.92934 3.37756 3.39847 3.12116 3.90214 3.03584ZM4.09497 4.52787C3.7731 4.61131 3.53134 4.91928 3.53614 5.24553C3.53518 6.51397 3.53278 7.78241 3.5371 9.05037C3.54238 9.44366 3.91605 9.78022 4.31515 9.76803C9.43291 9.76991 14.5502 9.76897 19.6679 9.7685C20.0786 9.78959 20.4628 9.44272 20.4633 9.03912C20.4666 7.77725 20.4647 6.51537 20.4642 5.25303C20.4753 4.84381 20.0915 4.47678 19.6718 4.50022C14.5689 4.49975 9.46601 4.50116 4.36312 4.49975C4.27294 4.50069 4.18228 4.5035 4.09497 4.52787Z"
        fill="#FF0032"
      />
      <path
        d="M3.9984 12.7853C4.15046 12.7586 4.30492 12.7516 4.45938 12.7506C9.40061 12.7506 14.3418 12.7506 19.2831 12.7506C19.5642 12.752 19.8482 12.7384 20.1259 12.7905C20.7759 12.9058 21.3611 13.3145 21.6892 13.8742C21.9007 14.2272 22.0039 14.6373 21.9995 15.0456C21.9995 16.2798 22.001 17.5141 21.9991 18.7487C22.0024 19.3914 21.7065 20.027 21.2081 20.4466C20.7879 20.8098 20.2266 21.0058 19.6668 21.0001C14.7582 21.0011 9.8496 21.0001 4.94098 21.0006C4.54188 20.9973 4.13271 21.0269 3.74656 20.9069C2.80446 20.6519 2.09789 19.7533 2.09453 18.797C2.08973 17.5319 2.09261 16.2662 2.09309 15.0011C2.09165 14.4522 2.30415 13.9061 2.68214 13.4992C3.02272 13.1266 3.49377 12.8701 3.9984 12.7853ZM4.19267 14.2769C3.86697 14.3584 3.62137 14.6697 3.62808 14.9992C3.62664 16.2498 3.62664 17.5005 3.62808 18.7511C3.61609 19.1561 3.99552 19.5142 4.40661 19.5001C9.49559 19.5011 14.585 19.5006 19.674 19.5006C20.077 19.5203 20.4535 19.1833 20.4626 18.7881C20.4679 17.5258 20.4645 16.2634 20.4645 15.0011C20.4679 14.7391 20.3144 14.4873 20.0861 14.3542C19.9326 14.2591 19.7469 14.2445 19.5704 14.2497C14.5165 14.252 9.46201 14.2497 4.40805 14.2506C4.33562 14.2525 4.26271 14.2576 4.19267 14.2769Z"
        fill="#FF0032"
      />
      <path
        d="M6.15881 6.01657C6.50994 5.97485 6.87642 6.10282 7.12058 6.35267C7.35947 6.59267 7.47843 6.94048 7.43382 7.27282C7.39209 7.62017 7.17239 7.9422 6.85916 8.11235C6.57518 8.27126 6.22069 8.30173 5.91226 8.19532C5.59183 8.08704 5.32704 7.83345 5.21192 7.52173C5.09535 7.21517 5.12461 6.85892 5.29298 6.57532C5.47047 6.2697 5.80097 6.05407 6.15881 6.01657Z"
        fill="#FF0032"
      />
      <path
        d="M6.0151 15.783C6.36335 15.6972 6.75238 15.7811 7.02676 16.0084C7.28819 16.2184 7.446 16.5461 7.44264 16.877C7.44504 17.2286 7.26468 17.575 6.97351 17.7808C6.72408 17.9617 6.39741 18.0358 6.09185 17.9819C5.74552 17.9237 5.43372 17.7001 5.27207 17.395C5.10466 17.087 5.09794 16.7017 5.25432 16.3881C5.39967 16.0895 5.68652 15.8612 6.0151 15.783Z"
        fill="#FF0032"
      />
      <path
        d="M9.65888 6.3893C12.4679 6.37899 15.2774 6.38649 18.0865 6.38555C18.5129 6.38133 18.8808 6.78399 18.8333 7.19743C18.8084 7.58461 18.4352 7.90524 18.0395 7.88461C15.2707 7.88414 12.502 7.88602 9.73323 7.88368C9.34277 7.88883 8.98445 7.5593 8.97101 7.17633C8.9384 6.79008 9.26266 6.41696 9.65888 6.3893Z"
        fill="#FF0032"
      />
      <path
        d="M9.51672 16.1562C9.65151 16.1159 9.79397 16.1253 9.93308 16.1243C12.6519 16.1262 15.3703 16.1225 18.0892 16.1262C18.5194 16.1229 18.8869 16.5345 18.8322 16.9503C18.8005 17.3323 18.4307 17.6459 18.0388 17.6248C15.2696 17.6243 12.5004 17.6262 9.73065 17.6239C9.34403 17.6281 8.98906 17.3033 8.97131 16.925C8.94109 16.5856 9.18142 16.2486 9.51672 16.1562Z"
        fill="#FF0032"
      />
    </SvgIcon>
  );
};

export default Askue;
