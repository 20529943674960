import Icon from "components/icons";
import { SWrap, SName } from "./styles";

interface IProp {
  isLight?: boolean;
}

export const Logo: React.FC<IProp> = ({ isLight }) => (
  <SWrap>
    <Icon.CompanyLogo sx={{ width: 32, height: 32 }} />
    <SName isLight={isLight}>ENERGYTOOL</SName>
  </SWrap>
);
