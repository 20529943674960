import { useState, useEffect } from "react";

type IUseScriptProps = {
  src: string;
};

const useScript = (props: IUseScriptProps) => {
  const { src } = props;

  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    let script: HTMLScriptElement | null = document.querySelector(`script[src="${src}"]`);
    if (!script) {
      script = document.createElement("script");
      script.src = src;
      script.addEventListener("load", () => {
        setIsLoaded(true);
        script?.setAttribute("data-status", "loaded");
      });
      script.addEventListener("error", () => {
        setIsError(true);
        script?.setAttribute("data-status", "error");
      });
      document.body.appendChild(script);
    } else if (script) {
      const status = script.getAttribute("data-status");
      if (status === "loaded") {
        setIsLoaded(true);
      } else if (status === "error") {
        setIsError(true);
      }
    }
  }, [src]);

  return {
    isLoaded,
    isError,
  };
};

export default useScript;
