import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

export const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 648,
    padding: "10px 12px",
    whiteSpace: "pre-wrap",
  },
});
