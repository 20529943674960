import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { NavLink } from "react-router-dom";
import { HEADER_HEIGHT } from "components/header/styles";
import { customScrollVertical } from "components/table/utils";
import { device } from "theme";

export const SSidebar = styled.aside<{ isOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  top: 64px;
  width: 72px;
  height: calc(100vh - 64px);
  border-right: 1px solid ${(props) => props.theme.mtsColor.grey.greyLight};
  transition: 0.3s ease all;
  overflow: hidden;
  ${(props) =>
    props.isOpen &&
    `
		transition: 0.3s ease all;
		width: 256px;

	`};
`;

export const SMobPos = styled.div`
  position: relative;
  display: flex;
  flex: 0 0 32px;
  align-items: center;
  justify-content: center;

  & > svg path {
    fill: ${(props) => props.theme.mtsColor.text.tertiary.lightMode};
  }
`;

export const SMobBody = styled.aside<{ isOpen }>`
  position: fixed;
  left: 0;
  transform: translateX(-100vw);
  top: ${HEADER_HEIGHT}px;
  width: 100vw;
  height: calc(100% - ${HEADER_HEIGHT}px);
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  transition: 0.3s ease transform;
  background-color: ${(props) => props.theme.mtsColor.background.primary.lightMode};

  ${(props) =>
    props.isOpen &&
    `
	transform: translateX(0);
	`};
`;

export const SFooter = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0 32px 0;
  margin-top: auto;

  @media ${device.desktopPlus} {
    margin-top: 0;
    padding: 16px 0;
    border-top: 1px solid ${(props) => props.theme.mtsColor.grey.greyLight};
  }
`;

export const SBody = styled.div`
  flex: 1;
  padding-top: 80px;
  overflow-y: overlay;
  ${(props) =>
    customScrollVertical({
      theme: props.theme,
      trackStart: 80,
    })};
`;

export const SBtnItem = styled.div`
  text-align: left;

  & > .MuiButton-root {
    padding: 0;
    min-width: inherit;
    text-decoration: none;
  }

  & .MuiButton-root:hover {
    background-color: none;
  }
`;

const navLinkStyles = (props) =>
  css`
    display: flex;
    align-items: center;
    min-height: 56px;
    text-decoration: none;
    padding: 16px 16px 16px 24px;
    border-left: 4px solid transparent;

    & > svg {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      margin-right: 8px;
      fill: ${props.theme.mtsColor.text.primary.lightMode};
    }
  `;

export const SLink = styled.a`
  ${navLinkStyles}
`;

export const SNavLink = styled(NavLink)`
  ${navLinkStyles}
  &.active {
    background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
    border-left: 4px solid ${(props) => props.theme.mtsColor.red};
    transition: 0.3s ease all;
  }
`;

export const SSwitch = styled.div`
  padding: 0 20px;
  margin-bottom: 26px;
`;
