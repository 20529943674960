import styled from "@emotion/styled";

export const SWrap = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 660px;
  margin: 0 auto;

  & > span {
    white-space: pre-wrap;
  }
`;

export const SLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
`;
