import { IMenegementTenantResponse, ITenantResponse } from "interface/management";
import { SERVICES } from "stream-constants/keys-and-constants";

export const individual_tenant_mock: ITenantResponse = {
  contr_num: "12345678910",
  service_type: "Поиск коммерческих потерь, Поиск майнинговых ферм",
  title: "Запрос индивид тенанта",
  time_schema: null,
  additional_info: null,
  services: [
    {
      service_type: SERVICES.LOSSES,
    },
  ],
  users: [
    {
      username: "Александр Александров",
      roles: [
        {
          name: "admin",
        },
        {
          name: "owner",
        },
      ],
    },
    {
      username: "Иван Иванов",
      roles: [
        {
          name: "analyst_system",
        },
        {
          name: "owner",
        },
      ],
    },
    {
      username: "Сергей Сергеев",
      roles: [
        {
          name: "admin",
        },
      ],
    },
  ],
};

export const base: IMenegementTenantResponse[] = [
  {
    id: "11",
    is_active: true,
    contr_num: "12345678910",
    service_type: "Поиск коммерческих потерь, Поиск майнинговых ферм",
    created_time: "string",
    title: "ООО «Башкир Энерго»",
    additional_info: "Александр Александров",
    phone: "89991112233",
    email: "email@mail.ru",
    time_schema: "GMT+3 Москва",
    services: [
      {
        service_type: SERVICES.LOSSES,
      },
    ],
    users: [
      {
        userName: "string",
      },
      {
        userName: "string",
      },
    ],
    deploy_steps: [
      {
        step: "Развертывание инфраструктуры VM",
        status: "Выполнено",
        date: "Mon, 06 Sep 2021 01:00:00 GMT",
      },
      {
        step: "Развертывание PostgreSQL",
        status: "Выполнено",
        date: "Tue, 07 Sep 2021 01:00:00 GMT",
      },
      {
        step: "Развертывание структуры БД",
        status: "Выполнено",
        date: "Wed, 08 Sep 2021 01:00:00 GMT",
      },
      { step: "Переливка данных", status: "Выполнено", date: "Thu, 09 Sep 2021 01:00:00 GMT" },
      {
        step: "Аналитика полученных данных",
        status: "Выполнено",
        date: "Fri, 10 Sep 2021 01:00:00 GMT",
      },
    ],
  },
  {
    id: "22",
    is_active: false,
    contr_num: "9876543210",
    service_type: "Поиск коммерческих потерь, Поиск майнинговых ферм",
    created_time: "string",
    title: "ООО «Северо-Запад Энерго»",
    additional_info: "Константин Константинопольский",
    phone: "89991112233",
    email: "email@mail.ru",
    time_schema: "GMT+3 Москва",
    users: [],
    services: [
      {
        service_type: SERVICES.LOSSES,
      },
    ],
    deploy_steps: [
      {
        step: "Развертывание инфраструктуры VM",
        status: "Выполнено",
        date: "Mon, 06 Sep 2021 01:00:00 GMT",
      },
      {
        step: "Развертывание PostgreSQL",
        status: "Выполнено",
        date: "Tue, 07 Sep 2021 01:00:00 GMT",
      },
      {
        step: "Развертывание структуры БД",
        status: "Выполнено",
        date: "Wed, 08 Sep 2021 01:00:00 GMT",
      },
      { step: "Переливка данных", status: "Ошибка", date: "Thu, 09 Sep 2021 01:00:00 GMT" },
      { step: "Аналитика полученных данных", status: null, date: null },
    ],
  },
  {
    id: "33",
    is_active: false,
    contr_num: "9876543210",
    service_type: "Поиск коммерческих потерь, Поиск майнинговых ферм",
    created_time: "string",
    title: "ООО «Северо-Запад Энерго»",
    additional_info: "Константин Константинопольский",
    phone: "89991112233",
    email: "email@mail.ru",
    time_schema: "GMT+3 Москва",
    users: [],
    services: [
      {
        service_type: SERVICES.LOSSES,
      },
    ],
    deploy_steps: [
      {
        step: "Развертывание инфраструктуры VM",
        status: "Выполнено",
        date: "Mon, 06 Sep 2021 01:00:00 GMT",
      },
      {
        step: "Развертывание PostgreSQL",
        status: "Выполнено",
        date: "Mon, 06 Sep 2021 01:00:00 GMT",
      },
      {
        step: "Развертывание структуры БД",
        status: "Выполнено",
        date: "Mon, 06 Sep 2021 01:00:00 GMT",
      },
      { step: "Переливка данных", status: "Выполнено", date: "Mon, 06 Sep 2021 01:00:00 GMT" },
      {
        step: "Аналитика полученных данных",
        status: "В процессе",
        date: "Thu, 09 Sep 2021 01:00:00 GMT",
      },
    ],
  },
  {
    id: "44",
    is_active: false,
    contr_num: "9876543210",
    service_type: "Поиск коммерческих потерь, Поиск майнинговых ферм",
    created_time: "string",
    title: "ООО «Северо-Запад Энерго»",
    additional_info: "Константин Константинопольский",
    phone: "89991112233",
    email: "email@mail.ru",
    time_schema: "GMT+3 Москва",
    users: [],
    services: [
      {
        service_type: SERVICES.LOSSES,
      },
    ],
    deploy_steps: [
      {
        step: "Развертывание инфраструктуры VM",
        status: "Выполнено",
        date: "Mon, 06 Sep 2021 01:00:00 GMT",
      },
      {
        step: "Развертывание PostgreSQL",
        status: "В процессе",
        date: "Thu, 09 Sep 2021 01:00:00 GMT",
      },
      { step: "Развертывание структуры БД", status: null, date: null },
      { step: "Переливка данных", status: null, date: null },
      { step: "Аналитика полученных данных", status: null, date: null },
    ],
  },
];
