import Icon from "components/icons";
import { MTSButton } from "components/mts-button/mts-button";
import { Spacer } from "components/spacer/spacer.component";
import { MAIL_SUPPORT } from "services/urls";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SLink, SWrap } from "./styles";

const succHeader = "Данные отправлены на\u00a0модерацию";
const succText =
  "После обработки данных вы\u00a0получите уведомление по\u00a0СМС о\u00a0том, что аналитика готова и\u00a0размещена в\u00a0личном кабинете пользователя. В\u00a0случае возникновения вопросов относительно загруженных данных мы\u00a0свяжемся с\u00a0вами, для уточнения информации.";

export const DemoAccepted = () => {
  return (
    <SWrap>
      <Icon.Success sx={{ width: 128, height: 128 }} />
      <Spacer value="40px" />

      <CusTypo variant="h2_bold" font="wide">
        {succHeader}
      </CusTypo>
      <Spacer value="24px" />

      <CusTypo variant="p4_regular">{succText}</CusTypo>
    </SWrap>
  );
};

const rejectedHeader = "Данные не смогли пройти модерацию";
const rejectedText =
  "Возможно загруженный файл поврежден или шаблон был заполнен неверно.\nПопробуйте загрузить данные еще раз или ";

export const DemoRejected = ({ retry }) => {
  return (
    <SWrap>
      <Icon.Error sx={{ width: 128, height: 128 }} />
      <Spacer value="40px" />

      <CusTypo variant="h2_bold" font="wide">
        {rejectedHeader}
      </CusTypo>
      <Spacer value="24px" />

      <CusTypo variant="p4_regular">
        {rejectedText}
        <SLink href={MAIL_SUPPORT}>свяжитесь с&nbsp;поддержкой</SLink>
      </CusTypo>
      <Spacer value="32px" />
      <MTSButton size="M" variant="secondary" onClick={retry}>
        Попробовать еще
      </MTSButton>
    </SWrap>
  );
};
