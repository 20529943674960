export const mock_mining_info = {
  count: 6319,
  file: "2023_04_19_s_miners_anon_rep.csv",
  miners: [
    {
      count_beige: 522,
      count_black: 321,
      count_lightred: 3484,
      count_red: 1992,
    },
  ],
  time_schema: "2023-09-14",
};

export const mock_mining_archive = {
  files_list: [
    {
      file_path: "mock_name_1.csv",
      time_schema: "2023-09-15",
    },
    {
      file_path: "mock_name_2.csv",
      time_schema: "2023-09-18",
    },
  ],
};
