import { IHttpMethods } from "stream-constants/server-constants";
import { FILTERS_PO_DEVICE_LIST, FILTERS_PRIEM_DEVICE_LIST } from "services/urls";
import { fetchData } from "services/services-utils/fetch-data";

import { mock_po, mock_priem } from "./mock-data";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { IDevicesFilterRequest } from "interface/anomalies";

const defaultParam = { list_po: [] };

export const getDevicesPriemList = async (data: IDevicesFilterRequest = defaultParam) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = mock_priem;
  } else {
    result = fetchData({
      url: FILTERS_PRIEM_DEVICE_LIST,
      method: IHttpMethods.POST,
      name: "getDevicesPriemList",
      body: JSON.stringify(data),
      emptyResponse: false,
    });
  }

  return result;
};

export const getDevicesPoList = async () => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = mock_po;
  } else {
    result = fetchData({
      url: FILTERS_PO_DEVICE_LIST,
      method: IHttpMethods.GET,
      name: "getDevicesPriemList",
      emptyResponse: false,
    });
  }

  return result;
};
