import styled from "@emotion/styled";
import { CopyToClipboard } from "components/copyToClipboard/copyToClipboard.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { OFFSET_24, statusToColor } from "theme";
import { getTypographyVariant } from "components/select/const";

const SDiv = styled.div<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  font: inherit;
  font-weight: 500;

  &:before {
    display: none;
    position: absolute;
    content: "";
    left: -${OFFSET_24};
    width: 8px;
    height: 100%;
    background: ${(props) => props.theme.mtsColor.red};
  }
  ${(props) =>
    props.isSelected &&
    `
  &:before {
	display: block;
  }
  `}
`;

export const Highlighter = ({ val, isSelected }) => {
  return (
    <SDiv isSelected={isSelected}>
      <CopyToClipboard text={val as any}>{val}</CopyToClipboard>
    </SDiv>
  );
};

const SStat = styled.div<{ status: string; size: string }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  & > div {
    flex: 0 0 auto;
    ${(props) =>
      props.size === "S"
        ? `width: 16px;
    height: 16px;
    margin-right: 8px;`
        : `width: 12px;
    height: 12px;
    margin-right: 6px;`};
    border-radius: 50%;
    background-color: ${(props) => statusToColor[props.status]};
  }
`;

export const StatBubble = ({ value, size = "S" }) => {
  return (
    <SStat status={value} size={size}>
      <div />
      <CusTypo variant={getTypographyVariant(size)} font="comp">
        {value}
      </CusTypo>
    </SStat>
  );
};
