import { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import { StatBubble } from "components/demo/demoTable/subComponents";
import Icon from "components/icons";
import { InputLabel } from "components/input-label";
import { MTSStatus } from "components/mts-status/mts-status";
import useOnClickOutside from "hooks/useOnClickOutside";
import { Loader } from "components/loader/loader";
import { InputErrorMessage } from "components/input-error-message";
import { CusTypo } from "components/cusTypo/custom-typography";
import {
  SIconBlock,
  SResultBlock,
  SContainer,
  SList,
  SOption,
  SSelect,
  SPlaceholder,
  SInline,
  SInlineTypo,
} from "./styles";
import { getTypographyVariant, sizeMTSStatus } from "./const";

export type IOption = {
  val: string | true | null;
  friendlyName?: string;
};

interface ISelect {
  initValue?: string;
  isDefaultValue?: boolean;
  label?: string;
  size?: "S" | "M" | "L";
  type?: "normal" | "status" | "fraude" | "";
  view?: "standard" | "minimal";
  placeholder?: string;
  optionsList?: IOption[];
  errorMessage?: string;
  disabled?: boolean;
  onChange?: Function;
  isLoading?: boolean;
  className?: string;
}

const ComponentSelect: React.FC<ISelect> = ({
  isDefaultValue,
  initValue,
  label,
  size = "S",
  type = "normal",
  view = "standard",
  placeholder,
  optionsList,
  errorMessage,
  disabled,
  onChange,
  isLoading,
  className = "",
}) => {
  const [isShowList, setShowList] = useState(false);
  const [isError, setError] = useState(false);
  const [selectVal, setSelectVal] = useState<string | null | undefined | true>(initValue);
  const containerRef = useRef<HTMLDivElement>(null);

  const closeList = () => {
    setShowList(false);
  };

  const handleToogleList = () => {
    setShowList((prev) => !prev);
  };

  const handleSelectOption = (opt: IOption) => {
    setSelectVal(opt.friendlyName ?? opt.val);
    setError(false);
    setShowList((prev) => !prev);
    onChange?.(opt.val);
  };

  const renderValue = (val: any) => {
    return type === "status" ? (
      MTSStatus(val, sizeMTSStatus(size))
    ) : type === "fraude" ? (
      <StatBubble value={val} size={size} />
    ) : (
      <CusTypo variant={getTypographyVariant(size)}>{val}</CusTypo>
    );
  };

  useEffect(() => {
    if (initValue) setSelectVal(initValue);
  }, [initValue]);

  useEffect(() => {
    setError(!!errorMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  useOnClickOutside(containerRef, closeList);

  return (
    <SContainer ref={containerRef} disabled={disabled} className={className} view={view}>
      {label ? <InputLabel label={label} /> : null}
      {view === "standard" ? (
        <SSelect size={size} onClick={handleToogleList} hasError={isError}>
          <SResultBlock>
            {isLoading ? (
              <Loader size="S_16" />
            ) : !isDefaultValue ? (
              renderValue(selectVal ?? "")
            ) : (
              <SPlaceholder>
                <CusTypo variant={getTypographyVariant(size)}>{placeholder}</CusTypo>
              </SPlaceholder>
            )}
          </SResultBlock>
          <SIconBlock>
            {isShowList ? (
              <Icon.ArrowUp sx={{ width: 16 }} />
            ) : (
              <Icon.ArrowDown sx={{ width: 16 }} />
            )}
          </SIconBlock>
        </SSelect>
      ) : (
        <SInline onClick={handleToogleList}>
          <SInlineTypo variant="p4_regular">{selectVal ?? ""}</SInlineTypo>
          <SIconBlock>
            {isShowList ? (
              <Icon.ArrowUp sx={{ width: 16 }} />
            ) : (
              <Icon.ArrowDown sx={{ width: 16 }} />
            )}
          </SIconBlock>
        </SInline>
      )}

      {isError && <InputErrorMessage errorMessage={errorMessage} />}
      {isShowList ? (
        <SList>
          {optionsList?.map((option, ind) => {
            return (
              <SOption
                data-name={option.val}
                key={`${option.val}_${ind}`}
                onClick={() => handleSelectOption(option)}
              >
                <CusTypo variant={getTypographyVariant(size)}>
                  {renderValue(option.friendlyName ?? option.val)}
                </CusTypo>
              </SOption>
            );
          })}
        </SList>
      ) : null}
    </SContainer>
  );
};

export const Select = styled(ComponentSelect)``;
