import * as yup from "yup";
import { getDatesDiffInDays } from "../../chart/utils";

const min_quantity = 300;
export const min_yellow_quantity = 500;
export const min_green_quantity = 800;
const min_period = 365;
export const min_yellow_period = 366;
export const min_green_period = 730;

export const pageHead = "Демо аналитической платформы";
export const stepOne = "1. Пройдите опросный лист";
export const stepTwo = "2. Скачайте и заполните сформированный шаблон";
export const stepThree =
  "3. Загрузите файл с данными, после чего он пройдет проверку на тип и объем данных.\nПосле успешного прохождения проверки вы сможете отправить файлы на модерацию";

export const pageDescr =
  "Демо-тестирование предоставляет возможность потенциальному заказчику самостоятельно загрузить свои данные полученные с\u00a0приборов учета для теста работы платформы.\nДля продолжения работы с\u00a0платформой выполните следующие шаги:";

export const tooltipAddress =
  "Фактический адрес установки прибора учета. Данная информация упрощает\nвизуализацию аналитики и\u00a0улучшает результат работы алгоритмов по\u00a0выявлению\nкоммерческих потерь.";

export const tooltipFraud =
  "Данная информация необходима для определения точек коммерческих потерь\nза\u00a0весь период потребления, а\u00a0не\u00a0только на\u00a0последний момент времени.";

export const tooltipIdentif =
  "Идентификатор точки учета электроэнергии в\u00a0вашей системе АСКУЭ.\nМожет использоваться серийный номер прибора учета (например: '47778519527146') или\u00a0ID идентификатор присвоенный системой АСКУЭ (например: '551161').";

export const tooltipUnits =
  "Следует указать, в\u00a0какой единице измерения в\u00a0вашей системе АСКУЭ учитывается активная энергия прямого направления.";

export const tooltipRecord =
  "Тип записи данных активной энергии прямого направления в\u00a0вашей системе АСКУЭ.\nНакопительный тип означает рост или сбор путем постепенного увеличения значения в\u00a0целом. Интервальный\u00a0\u2014 это диапазон значений любого порядкового типа.";

export const tooltipDiskret =
  "Вид записи активной энергии прямого направления в\u00a0вашей системе АСКУЭ.\nЧасовые\u00a0\u2014 данные с\u00a0разбивкой по\u00a0каждому часу. Суточные\u00a0\u2014 совокупные данные за\u00a0сутки.";

export const tooltipConsumer =
  "Фактический абонент сетевой организации по\u00a0точке учета электроэнергии.\nФизическое лицо\u00a0\u2014 это человек. Юридическое лицо\u00a0\u2014 организация.\nТехнический учет\u00a0\u2014 расход электроэнергии внутри электростанций, подстанций, и\u00a0т.д.";

export const tooltipTarif =
  "Разбиение активной энергии прямого направления в\u00a0вашей системе АСКУЭ на\u00a0периоды потребления (например: Т1\u00a0и\u00a0Т2\u00a0на счетчике электроэнергии\u00a0\u2014 первый показатель отображает используемое количество электроэнергии днем, второй\u00a0\u2014 ночью).";

export const tooltipPeriod =
  "Для корректной работы платформы период выгрузки данных должен составлять не\u00a0менее 1,5 лет";

export const tooltipQuantity =
  "Для корректной работы платформы рекомендуем использовать не\u00a0менее 1000 счетчиков";

export const defaultValues = {
  address: null,
  history: null,
  identification: null,
  units: null,
  record: null,
  diskret: null,
  consumers: [],
  tarif: [],
  comment: "",
  uploaded_period: null,
  uploaded_quantity: null,
};

export const schema = yup
  .object({
    address: yup.string().nullable().required(),
    fraud: yup.string().nullable().required(),
    identification: yup.string().nullable().required(),
    units: yup.string().nullable().required(),
    record: yup.string().nullable().required(),
    diskret: yup.string().nullable().required(),
    consumers: yup
      .array()
      .required()
      .test("length_check", "", (arr) => arr?.length !== 0),
    tarif: yup
      .array()
      .required()
      .test("length_check", "", (arr) => arr?.length !== 0),
    comment: yup.string().notRequired(),
    uploaded_quantity: yup.number().required().min(min_quantity, "Недостаточный объем данных"),
    uploaded_period: yup
      .array()
      .of(yup.string())
      .required()
      .test("days_check", "", (val) => getDatesDiffInDays(val) >= min_period),
  })
  .required();
