import styled from "@emotion/styled";
import { device } from "theme";

export const SSection = styled.section`
  padding: 0 20px;
  margin-top: 48px;
  text-align: left;
`;

export const SWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 20px;
  margin: 0 -20px;
  padding-bottom: 150px;

  @media ${device.desktopPlus} {
    gap: 0;
  }
`;

export const STop = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "title btns"
    "settings settings";
  gap: 12px 4px;
  padding-bottom: 12px;

  @media ${device.miniTabletPlus} {
    grid-template-columns: auto 1fr auto;
    grid-template-areas: "title settings btns";
    gap: 16px;
  }

  @media ${device.desktopPlus} {
    padding-bottom: 0;
  }
`;

export const STitle = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  grid-area: title;
`;

export const SSettings = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  align-items: center;
  grid-area: settings;

  & > div {
    max-width: inherit;

    @media ${device.desktopPlus} {
      max-width: 400px;
    }
  }

  @media ${device.desktopPlus} {
    justify-content: end;
  }
`;

export const SItem = styled.div`
  &.searchField {
    min-width: 400px;
    width: 100%;

    @media ${device.miniTabletPlus} {
      width: inherit;
    }
  }
`;

export const SBtns = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  grid-area: btns;

  @media ${device.desktopPlus} {
    gap: 16px;
  }
`;
