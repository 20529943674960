import { useContext, useMemo } from "react";
import { ITableFilterClm, ITableSortColumn, Table } from "components/table/table.component";
import { constructColumns, constructTableData } from "./utils";
import { IEventsResponse } from "interface/management";
import { ContextSettings } from "context/context-settings";
import { MobileTable } from "components/table/mobile-table/mobileTable.component";
import { tableMobStructure, tableStructure } from "./const";

interface IProp {
  rawData: IEventsResponse[];
  filterByColumn?: ITableFilterClm[];
  globalFilter?: string;
  onRowClick: (a: any) => void;
  rowSelected: number;
  sortByColumn?: ITableSortColumn[];
}
export const EventsTable: React.FC<IProp> = ({
  rawData,
  filterByColumn,
  globalFilter,
  sortByColumn,
}) => {
  const { isDesktop } = useContext(ContextSettings);
  const tableData = useMemo(() => constructTableData(rawData ?? [{}]), [rawData]);
  const columns = useMemo(() => constructColumns({ columns: tableStructure }), []);
  const mobileColumns = useMemo(() => tableMobStructure, []);
  const defaultSortBy = useMemo(
    () => [
      {
        id: "eventDatetime",
        desc: true,
      },
    ],
    [],
  );

  return isDesktop() ? (
    <Table
      view="alternatingRows"
      columns={columns}
      data={tableData}
      globalFilter={globalFilter}
      filterByColumn={filterByColumn}
      sortByColumn={defaultSortBy}
      height={516}
    />
  ) : (
    <MobileTable
      columns={mobileColumns}
      data={tableData}
      sortByColumn={sortByColumn}
      globalFilter={globalFilter}
      filterByColumn={filterByColumn}
    />
  );
};
