import * as yup from "yup";

const REQUIRED = "Поле не заполнено";
export const FALLBACK_MSG = "Что-то пошло не так, попробуйте еще раз";

export const formDefaultEmpty = {
  devicesList: [{ name: "" }],
  deadLineDate: new Date(),
  user: "",
};

export const schema = yup.object().shape({
  devicesList: yup
    .array()
    .test("devicesList", REQUIRED, (value) => (value ? value.some((a) => a.name.length) : false)),
  deadLineDate: yup.string().nullable().required(REQUIRED),
  user: yup.string().required(REQUIRED),
});

export const employeesId = (employees, submData) => {
  const employesData = employees?.data
    ? employees?.data.filter((item) => {
        return item.name === submData.user;
      })
    : [];
  return employesData[0].subscriberID ?? null;
};

export const removeAllDevices = () => {
  const N = 100; // 100 выбранных устройств в форме
  return Array.from({ length: N }, (_, index) => index + 1);
};
