import Icon from "components/icons";
import { SClose, SContent, SModBox, SOuter } from "./styles";

export const MTSModal = ({
  open,
  close,
  children,
  size = "S",
}: {
  open: boolean;
  close: any;
  children: any;
  size?: "S" | "M" | "L";
}) => {
  return (
    <SOuter
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      BackdropProps={{ timeout: 0 }}
      onClose={close}
      onClick={(e) => e.stopPropagation()}
    >
      <SModBox size={size}>
        <SContent>{children}</SContent>
        <SClose onClick={close}>
          <Icon.Close sx={{ width: 24, height: 24 }} />
        </SClose>
      </SModBox>
    </SOuter>
  );
};
