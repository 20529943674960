import styled from "@emotion/styled";

export const SCard = styled.div`
  & > article {
    & > ul {
      li {
        display: flex;
        justify-content: space-between;
        gap: 24px;
        white-space: nowrap;
        padding: 8px 12px;
        background-color: #f9f9fb;

        &:not(:last-of-type) {
          margin-bottom: 2px;
        }

        & > span {
          &:first-of-type {
            text-align: left;
            width: 120px;
            flex: 0 0 120px;
            color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
          }

          &:last-of-type {
            flex: 1 1 auto;
          }

          &:not(:first-of-type) {
            white-space: normal;
            text-align: right;
          }
        }
      }
    }
  }
`;

export const SHead = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  width: 100%;
  background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  text-align: left;

  & > section {
    & > div {
      &:not(:last-child) {
        margin-bottom: 12px;

        & > .MuiTypography-root:not(:last-child) {
          margin-right: 16px;
        }
      }

      &:first-of-type {
        display: flex;
        flex-flow: row nowrap;
        gap: 16px;
        align-items: center;
      }
    }
  }
`;

export const SBtns = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
`;

export const SWrapper = styled.div`
  & .MuiAccordion-root {
    margin-bottom: 8px;
    padding: 0;

    &.MuiPaper-root:first-of-type {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &.MuiPaper-root:last-of-type {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &.MuiAccordion-root {
      border-radius: 8px;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
      &:before {
        display: none;
      }
    }

    & .MuiAccordionSummary-content {
      margin: 0;
      width: 100%;
      justify-content: space-between;
      &.Mui-expanded {
        margin: 0;
      }
    }

    &.Mui-expanded {
      margin-bottom: 0;
      &:last-of-type {
        margin-bottom: 16px;
      }
      & > div:first-of-type {
        border-bottom: 1px solid ${(props) => props.theme.mtsColor.grey.greyLight};
      }
    }
  }
`;
