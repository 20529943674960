import rgba from "polished/lib/color/rgba";
import { format, differenceInDays, differenceInYears } from "date-fns";
import { IResearchChartRes } from "interface/analytics";
import { isEmpty } from "lodash";

import { chartColor1, chartColor2 } from "./styles";
export const makeChartData = (rawData: IResearchChartRes[]) => {
  const dayData = rawData.map((a) => +a.dsVal);
  const nightData = rawData.map((a) => +a.nsVal);
  const labels = rawData.map((a) => a.measuredate);

  return {
    labels,
    datasets: [
      {
        type: "line",
        id: 1,
        label: "День",
        data: dayData,
        pointBorderColor: "rgba(0, 0, 0, 0)",
        pointBackgroundColor: "rgba(0, 0, 0, 0)",
        borderColor: rgba(chartColor1, 1.0),
        pointHoverBorderColor: rgba(chartColor1, 1.0),
        borderWidth: 1,
      },
      {
        type: "line",
        id: 2,
        label: "Ночь",
        data: nightData,
        pointBorderColor: "rgba(0, 0, 0, 0)",
        pointBackgroundColor: "rgba(0, 0, 0, 0)",
        borderColor: chartColor2,
        pointHoverBorderColor: chartColor2,
        borderWidth: 1,
      },
    ],
  };
};

export const getPeriod = (labelsArr) => {
  return isEmpty(labelsArr) || labelsArr.some((a) => a == null)
    ? "..."
    : [
        format(new Date(labelsArr.slice(0, 1)), "dd.MM.yyyy"),
        format(new Date(labelsArr.slice(-1)), "dd.MM.yyyy"),
      ].join(" - ");
};

export const getDatesDiffInDays = (twoDatesArray): number => {
  return isEmpty(twoDatesArray)
    ? 0
    : Math.abs(differenceInDays(new Date(twoDatesArray[0]), new Date(twoDatesArray[1])));
};

export const getDatesDiffInYears = (twoDatesArray): number => {
  return isEmpty(twoDatesArray)
    ? 0
    : Math.abs(differenceInYears(new Date(twoDatesArray[0]), new Date(twoDatesArray[1])));
};
