import { FC, useEffect } from "react";
import * as yup from "yup";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";

import { MTSButton } from "components/mts-button/mts-button";
import { useTempFormUpload } from "hooks/api/map";
import { useManagementTenants } from "hooks/api/management";
import { MTSAutocomplete } from "components/mts-autocomplete/mts-autocomplete";
import { Loader } from "components/loader/loader";
import { SModalTitle } from "components/modal-notify-body/styles";
import { Spacer } from "components/spacer/spacer.component";
import { SBtns, SForm, SFormField } from "./user/styles";
import { DragDropFile } from "components/drag-drop-file/drag-drop-file";
import { Toast } from "components/toast/toast";
import { IMenegementTenantResponse } from "interface/management";
import { SERVICES } from "stream-constants/keys-and-constants";
import { convertOrgnameToId } from "./user/validation-schema";

const schema = yup.object().shape({
  tenant: yup
    .mixed()
    .required()
    .test("requiredOrganizationName", "", (v) => v && v !== ""),
});

type IProps = {
  onCancel?: any;
};

export const FormTempUpload: FC<IProps> = ({ onCancel }) => {
  const {
    register,
    control,
    handleSubmit,
    clearErrors,
    formState: { errors, isDirty },
    setError,
  } = useForm<any>({
    defaultValues: {},
    resolver: yupResolver(schema),
    mode: "onTouched",
    shouldFocusError: false,
    reValidateMode: "onChange",
  });

  const tenantsData = useManagementTenants();
  const mutation = useTempFormUpload();

  const [watchFile] = useWatch({ control, name: ["file"] });

  const onSubmit = async (sbmtData: any) => {
    let formData = new FormData();
    formData.append("file", sbmtData.file);
    formData.append("tenant_id", convertOrgnameToId(sbmtData.tenant, tenantsData?.data)!);

    mutation.mutate(formData, {
      onSuccess: () => toastSucc(),
      onError: (err) => toastError(err),
    });
  };

  useEffect(() => {
    if (watchFile == null && isDirty) {
      setError("file", {
        type: "userFile",
        message: "Поле не заполнено",
      });
    } else {
      clearErrors("file");
    }
  }, [clearErrors, setError, watchFile, isDirty]);

  return (
    <>
      <SModalTitle>Загрузка данных майнеров для организации</SModalTitle>
      <SForm onSubmit={handleSubmit(onSubmit)}>
        <SFormField>
          {tenantsData.isLoading ? (
            <Loader />
          ) : (
            <MTSAutocomplete
              label="Организация"
              placeholder=""
              options={makeDropdownFromTenants(tenantsData?.data)}
              control={control}
              size="M"
              {...register("tenant")}
              errorMessage={errors?.roles?.message}
            />
          )}
        </SFormField>
        <Controller
          name="file"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <DragDropFile
                name="controlled-file"
                onChange={onChange}
                hasError={!!error?.message}
                file={value}
                accept=".csv"
              />
            );
          }}
        />
        <Spacer value="8px" />
        <SBtns>
          <MTSButton
            size="M"
            variant="primary"
            type="submit"
            onClick={() => undefined}
            disabled={!isEmpty(errors) || !isDirty}
          >
            Отправить
          </MTSButton>
          <MTSButton size="M" variant="secondary" onClick={onCancel}>
            Отмена
          </MTSButton>
        </SBtns>
      </SForm>
    </>
  );
};

const toastError = (e) => {
  toast(<Toast title={e?.message ?? "Файл не загружен"} isError />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });
};

const toastSucc = () => {
  toast(<Toast title="Успешно" />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });
};

const makeDropdownFromTenants = (initial: IMenegementTenantResponse[] | undefined) =>
  initial
    ?.filter((a) => a.services.map((b) => b.service_type).includes(SERVICES.MINING))
    .map((a) => a.title) ?? [];
