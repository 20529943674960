import { MTSStatus } from "components/mts-status/mts-status";
import { format } from "date-fns";
import { SelectAction } from "./subComponents/subComponents";
import { CusTypo } from "components/cusTypo/custom-typography";

export const constructColumns = (data) => {
  const { columns } = data;

  return columns.map((singleColumn) => {
    return {
      ...singleColumn,
      ...(singleColumn.accessor === "title"
        ? {
            Cell: ({ value }) => <CusTypo variant="c1_medium">{value}</CusTypo>,
          }
        : singleColumn.accessor === "status"
        ? { Cell: ({ value }) => MTSStatus(value ?? "-") }
        : singleColumn.accessor === "created_time"
        ? {
            Cell: ({ value }) => (
              <CusTypo variant="c1_regular">
                {" "}
                {format(new Date(value ?? 0), `dd.MM.yyyy hh:mm`)}
              </CusTypo>
            ),
          }
        : singleColumn.accessor === "action"
        ? {
            Cell: ({ row }) => (
              <SelectAction
                appl_id={row.original.appl_id}
                status={row.original.status}
                tenant_name={row.original.tenant_name}
                created_time={row.original.created_time}
              />
            ),
          }
        : { Cell: ({ value }) => <CusTypo variant="c1_regular">{value ?? ""}</CusTypo> }),
    };
  });
};
