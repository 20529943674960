import { FC, Fragment, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { isEmpty, isEqual } from "lodash";

import { useLoadColumns, useSaveColumns } from "hooks/api/analytics";
import { ContextSettings } from "context/context-settings";
import { SlideIn } from "components/table/slideIn/slideIn.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { Spacer } from "components/spacer/spacer.component";
import { MTSButton } from "components/mts-button/mts-button";
import { IChartMode } from "pages/analytics/const";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { SSPan } from "components/table/tableSettings/styles";
import { Radio } from "components/radio/radio.component";
import { Toast } from "components/toast/toast";
import { Resolver } from "../analFilter/subcomponents";
import { Optimiser } from "../reserchForm/subcomponents";
import {
  IMainCats,
  categories,
  defaultOptim,
  defaultSearch,
  headByKey,
  radioTitleByKey,
  scheme,
} from "./const";
import { SBody, SButtons, SCatTitle, SGrey, SGreyHead, SWrap } from "../analFilter/styles";

interface IProp {
  close: () => void;
  isOpen: boolean;
  outerState: any;
  handleOuter: Function;
}

type IState = Pick<IChartMode, "max" | "mult">;

export const AnalSettings: FC<IProp> = ({ close, isOpen, outerState, handleOuter }) => {
  const { isDesktop } = useContext(ContextSettings);
  const [innerFilter, setInner] = useState<any>({});
  const [cat, setCat] = useState<IMainCats>("visibility");

  const [optim, setOptim] = useState<IState>({ max: outerState.max, mult: outerState.mult });
  const [map, setMap] = useState({ search: outerState.search, provider: outerState.provider });

  const { data: saved, isSuccess } = useLoadColumns(isOpen);
  const { mutate } = useSaveColumns();

  const handleGlobalApply = () => {
    const ls =
      (StorageAPI.get(EStorageKeys.RESEARCH_SETTINGS) &&
        JSON.parse(StorageAPI.get(EStorageKeys.RESEARCH_SETTINGS))) ||
      [];

    handleOuter((s) => ({ ...s, ...optim, ...map }));
    const res = { ...ls, chart: optim, ...map };
    StorageAPI.save(EStorageKeys.RESEARCH_SETTINGS, JSON.stringify(res));

    mutate(innerFilter, {
      onSuccess: () => {
        toastSaveSucc();
      },
      onError: () => {
        toastSaveErr();
      },
    });
  };

  const handleGlobalReset = () => {
    setInner({});
    handleOuter((s) => ({ ...s, ...defaultOptim, ...defaultSearch }));
    const res = { ...defaultSearch, chart: defaultOptim };
    StorageAPI.save(EStorageKeys.RESEARCH_SETTINGS, JSON.stringify(res));

    mutate(
      {},
      {
        onSuccess: () => {
          toastResetSucc();
        },
        onError: () => {
          toastResetErr();
        },
      },
    );
  };

  const reference = { ...map, ...optim, mode: outerState.mode };

  useEffect(() => {
    if (isSuccess && !isEmpty(saved)) {
      setInner(saved);
    }
  }, [isSuccess, saved]);

  return (
    <>
      <SlideIn
        isOpen={isOpen}
        close={close}
        maxWidth={750}
        headerChildren={
          <CusTypo variant={!isDesktop() ? "h4_medium" : "h3_medium"} font="comp">
            Настройки
          </CusTypo>
        }
      >
        <SWrap>
          <section>
            <SCatTitle variant="h4_medium" font="comp">
              Категория
            </SCatTitle>
            {Object.keys(categories).map((c) => (
              <SGrey
                isActive={cat === c}
                onClick={() => setCat(c as IMainCats)}
                key={c}
                style={{ whiteSpace: "nowrap" }}
              >
                <CusTypo variant="p4_regular">{categories[c]}</CusTypo>
              </SGrey>
            ))}
            <Spacer value="64px" />
            <SButtons>
              <MTSButton
                size="S"
                variant="primary"
                onClick={handleGlobalApply}
                disabled={isEqual(saved, innerFilter) && isEqual(outerState, reference)}
              >
                Применить
              </MTSButton>
              <MTSButton size="S" variant="dark_blue" onClick={() => handleGlobalReset()}>
                По умолчанию
              </MTSButton>
            </SButtons>
          </section>
          <section>
            {cat === "visibility" &&
              Object.entries(scheme[cat] ?? {})?.map(([key, val], indx) => (
                <Fragment key={`${key}_${indx}`}>
                  <SGreyHead>
                    <CusTypo variant="p3_regular">{headByKey[key]}</CusTypo>
                  </SGreyHead>
                  <SBody>
                    {Object.keys(val as any)?.map((type, i) => (
                      <Resolver
                        key={`${type}_${i}`}
                        categoryTree={val}
                        filterSetter={setInner}
                        filterData={innerFilter}
                        finalScheme={scheme}
                        cat={cat}
                        subcat={key}
                        type={type}
                      />
                    ))}
                    <Spacer value="32px" />
                  </SBody>
                </Fragment>
              ))}
            {cat === "optim" ? (
              <>
                <SGreyHead>
                  <CusTypo variant="p3_regular">{headByKey[cat]}</CusTypo>
                </SGreyHead>
                <Optimiser handleOuter={setOptim} outerState={optim} />
              </>
            ) : null}
            {cat === "search" ? (
              <>
                {Object.entries(scheme[cat] ?? {})?.map(([key, val], indx) => (
                  <Fragment key={`${key}_${indx}`}>
                    <SGreyHead>
                      <CusTypo variant="p3_regular">{headByKey[key]}</CusTypo>
                    </SGreyHead>
                    <div style={{ padding: "0 0 0 24px" }}>
                      {val?.map((elem, i) => (
                        <SSPan key={`${elem}_${i}`}>
                          <Radio
                            id={`${cat}_${elem}`}
                            name={`${cat}_${elem}`}
                            value={elem}
                            checked={map[key] === elem}
                            onChange={() => setMap((s) => ({ ...s, [key]: elem }))}
                            text={radioTitleByKey[elem]}
                            color="black"
                          />
                        </SSPan>
                      ))}
                      <Spacer value="32px" />
                    </div>
                  </Fragment>
                ))}
              </>
            ) : null}
          </section>
        </SWrap>
      </SlideIn>
    </>
  );
};

const toastSaveSucc = () =>
  toast(<Toast title="Настройки сохранены" />, {
    progress: undefined,
    autoClose: 2000,
    hideProgressBar: true,
  });

const toastSaveErr = () =>
  toast(<Toast title="Ошибка при сохранении" isError />, {
    autoClose: 2000,
    hideProgressBar: false,
  });

const toastResetSucc = () =>
  toast(<Toast title="Настройки сброшены до стандартных и сохранены" />, {
    progress: undefined,
    autoClose: 2000,
    hideProgressBar: true,
  });

const toastResetErr = () =>
  toast(<Toast title="Настройки сброшены, но не сохранены" isError />, {
    autoClose: 2000,
    hideProgressBar: false,
  });
