import { IResearch } from "interface/analytics";
import { isEmpty } from "lodash";

export const statusFraud = [
  { val: "Низкая" },
  { val: "Средняя" },
  { val: "Высокая" },
  { val: "Фрод" },
];

type ITags = {
  t5: boolean;
  t2: boolean;
  t3: boolean;
  fp: string;
};

export const getBackendValues = (array: IResearch[] | undefined, selected: number): ITags | {} => {
  if (!isEmpty(array)) {
    const selectedElem = array?.find((item) => item.id_pp === selected);

    return Object.fromEntries([
      ["t5", selectedElem?.t5 ?? false],
      ["t2", selectedElem?.t2 ?? false],
      ["t3", selectedElem?.t3 ?? false],
      ["fp", selectedElem?.fp ?? ""],
      ["comment", selectedElem?.fp_cm],
    ]);
  }

  return {};
};

export const toolTextOptim = "Скрыть исходные данные";
export const toolTextSource = "Отобразить исходные данные";
export const toolStyle = { fontWeight: 400, width: 235 };
