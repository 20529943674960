import { useContext, useEffect, useRef, useState } from "react";
import { isEmpty } from "lodash";

import { useManagementBilling, useManagementTenants } from "hooks/api/management";
import { ROUTE_BILLING_PAGE, ROUTE_MANAGEMENT } from "stream-constants/route-constants";
import { Loader } from "components/loader/loader";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { IBreadcrumbsLink } from "interface/common";
import { Footer } from "components/footer/footer";
import { ContextSettings } from "context/context-settings";
import { CusTypo } from "components/cusTypo/custom-typography";
import { BillingTable } from "components/management-billing/billingTable/billingTable.component";
import { useDebounce } from "hooks/useDebounce";
import { MTSInput } from "components/mts-input";
import Icon from "components/icons";
import { MTSButton } from "components/mts-button/mts-button";
import { TableSort } from "components/table/table-sort/table-sort";
import { TableFilter } from "components/table/table-filter/table-filter";
import { NotifyBlock } from "components/notification-block/notification-block";
import { Spacer } from "components/spacer/spacer.component";
import {
  FILTER_BY_STATUS,
  FILTER_BY_TENANT,
  HARDCODE_STATUSES,
  SCHEME_BILLING,
  TABLE_FILTER_DEFAULT_BILLING,
  TABLE_SORT_DEFAULT_BILLING,
} from "./const";
import { LIST_ROLES } from "utils/roles";
import { SBtns, SSection, SSettings, STitle, STop, SWrap } from "./styles";

const links: IBreadcrumbsLink[] = [
  { name: "Управление", href: `/${ROUTE_MANAGEMENT}` },
  { name: "Счета", href: `/${ROUTE_BILLING_PAGE}` },
];

export const ManagementBilling = () => {
  const { isDesktop, roles } = useContext(ContextSettings);
  const isAdmin = roles.includes(LIST_ROLES.ADMIN);
  const [searchVal, setSearch] = useState("");
  const debouncedSearch = useDebounce(searchVal, 500);
  const [isOpenSort, setIsOpenSort] = useState(false);
  const [columnSort, setColumnSort] = useState<any>([]);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [dataLength, setDataLength] = useState(undefined);
  const [columnFilter, setColumnFilter] = useState<any>([]);

  const { data, isLoading, isSuccess } = useManagementBilling();
  const { data: tenantsData } = useManagementTenants(isAdmin);

  const schemeRef = useRef<any>(SCHEME_BILLING);

  const handleColumnSort = (sorting) => setColumnSort(sorting);

  const handleInput = (e) => {
    setSearch(e.target.value);
  };

  const getDataLength = (a) => setDataLength(a.length);

  const handleColumnFilter = ({ filtersArr }) => {
    setColumnFilter(filtersArr);
  };

  useEffect(() => {
    if (!isEmpty(tenantsData)) {
      schemeRef.current = {
        checkbox: {
          [FILTER_BY_TENANT]: tenantsData?.map((a) => ({
            id: a.id,
            name: a.id,
            friendlyName: a.title,
          })),
          [FILTER_BY_STATUS]: HARDCODE_STATUSES,
        },
        radio: {},
      };
    }
  }, [tenantsData]);

  return (
    <>
      <SSection>
        <Breadcrumbs links={links} />
        <div>
          <STop>
            <STitle>
              <CusTypo variant={isDesktop() ? "h2_medium" : "h4_medium"} font="wide">
                Список счетов
              </CusTypo>
            </STitle>
            <SSettings>
              <MTSInput
                searchIcon
                placeholder="Название организации, номер счета"
                onChange={handleInput}
                value={searchVal}
                size="S"
              />
              <TableFilter
                className="btns__filter"
                dataLength={dataLength ?? 0}
                isOpen={isOpenFilter}
                isOn={true}
                defaultFilter={TABLE_FILTER_DEFAULT_BILLING}
                toogleShowFilter={setIsOpenFilter}
                onApplyFilter={handleColumnFilter}
                scheme={schemeRef.current}
                isSchemeOnly
              />
            </SSettings>
            {!isDesktop() ? (
              <SBtns>
                <MTSButton
                  size="S"
                  variant="secondary"
                  onClick={() => setIsOpenSort(true)}
                  format="icon"
                  icon={<Icon.Sort sx={{ width: 16, height: 16 }} />}
                />
                <TableSort
                  items={TABLE_SORT_DEFAULT_BILLING}
                  isOpen={isOpenSort}
                  close={() => setIsOpenSort(false)}
                  onApplySort={handleColumnSort}
                  view="modal"
                />
              </SBtns>
            ) : null}
          </STop>
          <SWrap>
            {isSuccess && !isEmpty(data) ? (
              <BillingTable
                rawData={data ?? []}
                globalFilter={debouncedSearch}
                sortByColumn={columnSort}
                onRowsChange={getDataLength}
                filterByColumn={columnFilter}
              />
            ) : isLoading ? (
              <Loader isFullPage />
            ) : (
              <>
                <Spacer value="12px" />
                <NotifyBlock content="Отсутствует сформированный список счетов" status="error" />
              </>
            )}
          </SWrap>
        </div>
      </SSection>
      <Footer />
    </>
  );
};
