import { FC, Fragment, useState } from "react";
import { NumericFormat } from "react-number-format";
import format from "date-fns/format";
import parse from "date-fns/parse";

import { CusTypo } from "components/cusTypo/custom-typography";
import { Checkbox } from "components/checkbox/checkbox";
import { InputDatePicker } from "components/mts-datepicker/mts-datepicker";
import { MTSInput } from "components/mts-input";
import { Spacer } from "components/spacer/spacer.component";
import Icon from "components/icons";
import {
  CHANGE_DATE_COL,
  KOEF_COL,
  MINER_COL,
  PU_COL,
  RES_COL,
  periodTitleByKey,
  rangeTitleByKey,
  selectTitleByKey,
} from "./const";
import { SChip, SHug, SLabelIcon, SPeriod, SRow } from "./styles";
import { Switch } from "components/switch/switch.component";
import { switchTitleByKey } from "../analSettings/const";
import { Select } from "components/select/select";
import { selectKeys } from "./utils";

export const Resolver: FC<any> = ({
  filterSetter,
  filterData,
  categoryTree,
  finalScheme,
  cat,
  subcat,
  type,
  disabled,
}) =>
  type === "checkbox" ? (
    <CheckElem
      catTree={categoryTree}
      type={type}
      filterData={filterData}
      filterSetter={filterSetter}
      finalScheme={finalScheme}
      cat={cat}
      subcat={subcat}
      disabled={disabled}
    />
  ) : type === "period" ? (
    <PeriodElem
      catTree={categoryTree}
      type={type}
      filterData={filterData}
      filterSetter={filterSetter}
      disabled={disabled}
    />
  ) : type === "range" ? (
    <RangeElem
      catTree={categoryTree}
      type={type}
      filterData={filterData}
      filterSetter={filterSetter}
      disabled={disabled}
    />
  ) : type === "switch" ? (
    <SwitchElem
      catTree={categoryTree}
      type={type}
      filterData={filterData}
      filterSetter={filterSetter}
      disabled={disabled}
    />
  ) : type === "select" ? (
    <SelectElem
      catTree={categoryTree}
      type={type}
      filterData={filterData}
      filterSetter={filterSetter}
      disabled={disabled}
    />
  ) : null;

export const CheckElem = ({
  catTree,
  type,
  filterData,
  filterSetter,
  finalScheme,
  cat,
  subcat,
  disabled,
}) => {
  const [resSearch, setResSearch] = useState("");

  const toggleChbxAll = (column: string, list: any[], makeReset?: boolean) => {
    if (makeReset) {
      filterSetter((s) => {
        return {
          ...s,
          [column]: [],
        };
      });
    } else {
      filterSetter((s) => {
        const vals = list.map((a) => a.name);
        return {
          ...s,
          [column]: vals,
        };
      });
    }
  };

  const toggleSingleChbx = (column: string, name: string) => {
    filterSetter((s) => {
      const trgColumn = s?.[column] ?? [];
      const arr = trgColumn?.includes(name)
        ? trgColumn?.filter((a) => a !== name)
        : [...trgColumn, name];
      return {
        ...s,
        [column]: arr,
      };
    });
  };

  return (
    <>
      {subcat === RES_COL ? (
        <>
          <Spacer value="12px" />
          <MTSInput
            searchIcon
            placeholder="Поиск по РЭС"
            onChange={(e) => setResSearch(e.target.value.toLowerCase())}
            value={resSearch}
            size="S"
          />
          <Spacer value="12px" />
        </>
      ) : null}
      {Array.isArray(catTree?.[type]) ? (
        <>
          {catTree?.[type]?.map((l, i) =>
            l?.list.map((n) => (
              <Checkbox
                key={`${n.id}_${catTree?.[type]?.[i]?.name}`}
                label={n.friendlyName ?? n.name}
                name={`${n.id}_${catTree?.[type]?.[i]?.name}`}
                id={`${n.id}_${catTree?.[type]?.[i]?.name}`}
                checked={filterData?.[catTree?.[type]?.[i]?.name]?.includes(n.name) || false}
                onChange={() => toggleSingleChbx(catTree?.[type]?.[i]?.name, n.name)}
                disabled={disabled}
              />
            )),
          )}
        </>
      ) : (
        <>
          {catTree?.[type]?.list?.length !== 1 ? (
            <Checkbox
              id={`all_${subcat}`}
              name={`all_${subcat}`}
              label="Выбрать все"
              onChange={() =>
                toggleChbxAll(
                  finalScheme?.[cat]?.[subcat]?.[type]?.name,
                  finalScheme?.[cat]?.[subcat]?.[type]?.list,
                  finalScheme?.[cat]?.[subcat]?.[type]?.list?.length === filterData[subcat]?.length,
                )
              }
              checked={
                finalScheme?.[cat]?.[subcat]?.[type]?.list?.length === filterData[subcat]?.length
              }
              disabled={disabled}
            />
          ) : null}
          {catTree?.[type]?.list
            ?.filter((f) => (!resSearch ? true : f.name.toLowerCase()?.includes(resSearch)))
            .map((a) => (
              <Checkbox
                key={`${a.id}_${catTree?.[type]?.name}`}
                label={a.friendlyName ?? a.name}
                name={`${a.id}_${catTree?.[type]?.name}`}
                id={`${a.id}_${catTree?.[type]?.name}`}
                checked={filterData?.[catTree?.[type]?.name]?.includes(a.name) || false}
                onChange={() => toggleSingleChbx(catTree?.[type]?.name, a.name)}
                disabled={disabled}
              />
            ))}
        </>
      )}
    </>
  );
};

export const PeriodElem = ({ catTree, type, filterData, filterSetter, disabled }) => {
  const handleDatesPeriod = (e, key, index) => {
    filterSetter((s) => {
      let start = s[key] ?? [null, null];
      start[index] = e ? format(new Date(e), "yyyy-MM-dd") : e;
      return { ...s, [key]: start };
    });
  };

  const isDisabled =
    disabled || (filterData[PU_COL]?.[0] === null && catTree?.[type] === CHANGE_DATE_COL);

  return (
    <SPeriod>
      <SLabelIcon>
        <CusTypo variant="p4_regular" font="comp">
          {periodTitleByKey[catTree?.[type]]}
        </CusTypo>
        {isDisabled && periodTitleByKey[catTree?.[type]] ? (
          <Icon.Lock sx={{ width: 16, height: 16 }} />
        ) : null}
      </SLabelIcon>
      <div>
        <InputDatePicker
          placeholder="ДД.ММ.ГГ"
          onSelect={(e) => handleDatesPeriod(e, catTree?.[type], 0)}
          isPickAM
          isEmptyVal={filterData[catTree?.[type]]?.[0] == null}
          defaultVal={
            !filterData[catTree?.[type]]?.[0]
              ? undefined
              : parse(filterData[catTree?.[type]]?.[0] ?? 0, "yyyy-MM-dd", new Date())
          }
          disabled={isDisabled}
        />
        <article>
          <CusTypo variant="p2_regular">-</CusTypo>
        </article>
        <InputDatePicker
          placeholder="ДД.ММ.ГГ"
          onSelect={(e) => handleDatesPeriod(e, catTree?.[type], 1)}
          isPickAM
          isEmptyVal={filterData[catTree?.[type]]?.[1] == null}
          defaultVal={
            !filterData[catTree?.[type]]?.[1]
              ? undefined
              : parse(filterData[catTree?.[type]]?.[1] ?? 0, "yyyy-MM-dd", new Date())
          }
          disabled={isDisabled}
        />
      </div>
    </SPeriod>
  );
};

export const RangeElem = ({ catTree, type, filterData, filterSetter, disabled }) => {
  const handleRange = (e, key, index) => {
    filterSetter((s) => {
      let start = s[key] ?? [null, null];
      start[index] = e === 0 ? null : e;
      return { ...s, [key]: start };
    });
  };

  return catTree?.[type].map((a, indx, self) => {
    const isDisabled = disabled || (filterData[MINER_COL]?.[0] === null && a === KOEF_COL);
    return (
      <SPeriod key={`${type}_${a}`}>
        <SLabelIcon>
          <CusTypo variant="p4_regular" font="comp">
            {rangeTitleByKey[a]}
          </CusTypo>
          {isDisabled ? <Icon.Lock sx={{ width: 16, height: 16 }} /> : null}
        </SLabelIcon>
        <div>
          <NumericFormat
            customInput={MTSInput}
            prefix="от "
            placeholder="от "
            onValueChange={(v) => {
              handleRange(Number(v.value), a, 0);
            }}
            value={filterData?.[a]?.[0] ?? 0}
            allowNegative={false}
            disabled={isDisabled}
            {...{ size: "S" }}
          />
          <article>
            <CusTypo variant="p2_regular">-</CusTypo>
          </article>
          <NumericFormat
            customInput={MTSInput}
            prefix="до "
            placeholder="до "
            onValueChange={(v) => {
              handleRange(Number(v.value), a, 1);
            }}
            value={filterData?.[a]?.[1] ?? 0}
            allowNegative={false}
            disabled={isDisabled}
            {...{ size: "S" }}
          />
        </div>
        {self.length > 1 && self.length - 1 !== indx ? <Spacer value="12px" /> : null}
      </SPeriod>
    );
  });
};

export const SwitchElem = ({ catTree, type, filterData, filterSetter, disabled }) => {
  const handleToggleSwitch = (e, a) =>
    filterSetter((s) => {
      const copy = { ...s };
      if (e) {
        copy[a] = true;
      } else {
        delete copy[a];
      }
      return copy;
    });

  return catTree?.[type].map((a, indx, self) => (
    <SHug key={`${type}_${a}`}>
      <Switch
        rightPlhlr
        id={a}
        setChecked={(e) => handleToggleSwitch(e, a)}
        checked={filterData[a]}
        label={switchTitleByKey[a]}
      />
    </SHug>
  ));
};

const excludedNullableKeys = selectKeys;

export const Chip = ({ filterData, filterSetter, chip }) => {
  const toggleSingleChbx = (column: string, name: string) => {
    filterSetter((s) => {
      const trgColumn = s?.[column] ?? [];
      const arr = trgColumn?.includes(name)
        ? trgColumn?.filter((a) => a !== name)
        : [...trgColumn, name];
      return {
        ...s,
        [column]: arr,
      };
    });
  };

  const removeChip = (delKey: string) => {
    filterSetter((s) => {
      const copy = { ...s };
      delete copy[delKey];
      return copy;
    });
  };

  if (
    !excludedNullableKeys.includes(chip.key) &&
    (!filterData[chip.key] ||
      (Array.isArray(filterData[chip.key]) && filterData[chip.key]?.every((o) => o == null)))
  ) {
    return null;
  }
  return chip.type === "checkbox" ? (
    chip.val.map((mv) => {
      return (
        <SChip
          key={`${mv}_${chip.key}_${chip.type}`}
          onClick={() => toggleSingleChbx(chip.key, mv)}
        >
          <CusTypo variant="c1_regular" font="comp">
            {mv}
          </CusTypo>
          <Icon.Close sx={{ width: 16, height: 16 }} />
        </SChip>
      );
    })
  ) : (
    <SChip key={`${chip.key}_${chip.type}`} onClick={() => removeChip(chip.key)}>
      <CusTypo variant="c1_regular" font="comp">
        {chip.val}
      </CusTypo>
      <Icon.Close sx={{ width: 16, height: 16 }} />
    </SChip>
  );
};

const valToString = {
  null: "Пусто",
  true: "Значение",
};

export const SelectElem = ({ catTree, type, filterData, filterSetter, disabled }) => {
  const handleSelect = (e, a) =>
    filterSetter((s) => {
      const copy = { ...s };
      if (e === "all") {
        delete copy[a];
      } else {
        copy[a] = [e];
      }
      return copy;
    });

  return catTree?.[type].map((a, indx, self) => (
    <Fragment key={`${type}_${a}`}>
      <SRow>
        <CusTypo variant="p4_regular" font="comp">
          {selectTitleByKey[a]}
        </CusTypo>
        <Select
          initValue={valToString[filterData?.[a]?.[0]] ?? "Все"}
          size="S"
          optionsList={[
            { val: "all", friendlyName: "Все" },
            { val: true, friendlyName: "Значение" },
            { val: null, friendlyName: "Пусто" },
          ]}
          onChange={(e) => {
            handleSelect(e, a);
          }}
          disabled={disabled}
        />
      </SRow>
      {self.length > 1 && self.length - 1 !== indx ? <Spacer value="12px" /> : null}
    </Fragment>
  ));
};
