import Icon from "components/icons";
import { SCont, SLabel } from "./styles";

interface InputLabelProps {
  label?: string;
  id?: string;
  disabled?: boolean;
  hasError?: boolean;
}

export const InputLabel: React.FC<InputLabelProps> = ({ label, id, disabled, hasError }) => {
  return (
    <SCont id={id}>
      <SLabel
        variant="p4_regular"
        hasError={hasError}
        dangerouslySetInnerHTML={{ __html: label ?? "" }}
      />
      {disabled ? <Icon.Lock width={16} height={16} className="label__icon" /> : null}
    </SCont>
  );
};
