import { CusTypo } from "components/cusTypo/custom-typography";
import { shopTitleByKey, makeManagShopInfo } from "../utils";
import { Spacer } from "components/spacer/spacer.component";
import { SContainerMobile } from "../styles";
import { SBody, SContent, SHeader } from "./styles";
import Icon from "components/icons";
import { ProductDeskAction } from "../product/product.component";

export const ShopMobCard = ({ row }) => {
  const { name, category, hide_flag } = row.original;

  return (
    <SContainerMobile>
      <SContent isHide={hide_flag}>
        <SHeader>
          <div>
            <CusTypo variant="p3_bold" font="comp">
              {name}
              {hide_flag ? <Icon.Eye sx={{ width: 24, height: 24 }} /> : null}
            </CusTypo>
          </div>
          <ProductDeskAction data={row} />
        </SHeader>
        <div>
          <CusTypo variant="c1_regular" font="comp">
            {category}
          </CusTypo>
        </div>
        <Spacer value="12px" />
        <SBody>
          <ul>
            {Object.entries(makeManagShopInfo(row.original)).map(([key, val], index) => (
              <li key={index}>
                <CusTypo variant="c1_regular" font="comp">
                  {shopTitleByKey[key]}
                </CusTypo>
                <CusTypo variant="c1_regular" font="comp">
                  {val.value}
                </CusTypo>
              </li>
            ))}
          </ul>
        </SBody>
      </SContent>
    </SContainerMobile>
  );
};
