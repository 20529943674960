import { useState, useRef, useLayoutEffect } from "react";

import { MTSModal } from "components/mts-modal/mts-modal";
import { PopupAction } from "components/popup-action/popup-action";
import { AccordionDetails, AccordionSummary } from "@mui/material";
import { invalidateManagementDemo, useDemoMngScore } from "hooks/api/management";
import { ScoreDetails } from "./scoreDetails.component";
import { makeSet } from "./utils";
import { StatusModal } from "./statusModal.component";
import { IDemoMngStatuses, IDemoManagementResponse } from "interface/management";
import { demoMngGetDetailingXLSX } from "services/management-demo";
import { CusTypo } from "components/cusTypo/custom-typography";
import { HEIGHT_EXP } from "components/table/styles";
import { SAccordion } from "components/devices/deviceTable/styles";

type IProp = Pick<IDemoManagementResponse, "id" | "status" | "title">;

export const SelectAction: React.FC<IProp> = ({ id, status, title }) => {
  const [isOpenModal, setOpenModal] = useState(false);
  const [nextStatus, setNextStatus] = useState<IDemoMngStatuses>();

  const handleAction = (arg) => {
    setOpenModal(true);
    setNextStatus(arg);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    invalidateManagementDemo();
  };

  return (
    <div className="popup-container">
      <PopupAction
        items={makeSet({
          status,
          commonCallback: handleAction,
          detailCallback: () => demoMngGetDetailingXLSX(id),
        })}
      />
      <MTSModal open={isOpenModal} close={handleModalClose}>
        {nextStatus ? (
          <StatusModal
            tenantId={id}
            tenantTitle={title}
            nextStatus={nextStatus}
            onCancel={() => setOpenModal(false)}
          />
        ) : null}
      </MTSModal>
    </div>
  );
};

const MAGIC_HEIGHT = 429;
const FAULT = 40;

export const Expandable = ({ value, row, isOpen }) => {
  const [parent, setParent] = useState(900);

  const refOne = useRef<HTMLDivElement>(null);
  const refTwo = useRef<any>(null);

  const mngScore = useDemoMngScore(row.original.id, isOpen);

  useLayoutEffect(() => {
    if (refOne?.current) {
      const width = refOne?.current?.closest?.(".tbody_tr")?.clientWidth ?? 900;
      setParent(width);
    }
  }, []);

  useLayoutEffect(() => {
    if (refTwo?.current) {
      const elem = refTwo?.current;
      const measured = elem?.getBoundingClientRect().height;
      const totalHeight = measured < MAGIC_HEIGHT ? measured + FAULT : measured;
      elem.closest(".tbody_tr").style.height = isOpen
        ? `${totalHeight + HEIGHT_EXP}px`
        : `${HEIGHT_EXP}px`;
    }
  }, [isOpen, mngScore?.isSuccess]);

  return (
    <>
      <CusTypo variant="h4_bold" ref={refOne}>
        {value}
      </CusTypo>
      <SAccordion
        id={row.original.id}
        aria-controls={`panel${row.original.id}-content`}
        expanded={isOpen}
        TransitionProps={{
          unmountOnExit: true,
        }}
        style={{
          width: parent,
        }}
        ref={refTwo}
      >
        <AccordionSummary
          style={{
            minHeight: 0,
            height: 0,
          }}
        />
        <AccordionDetails>
          <ScoreDetails data={mngScore?.data} isLoading={mngScore?.isLoading} />
        </AccordionDetails>
      </SAccordion>
    </>
  );
};
