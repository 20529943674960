import { useContext, useState } from "react";
import { useManagementAskue } from "hooks/api/askue";
import { Loader } from "components/loader/loader";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { Footer } from "components/footer/footer";
import { MTSInput } from "components/mts-input";
import { useDebounce } from "hooks/useDebounce";
import { AskueManagementTable } from "components/askue/askueManagementTable/askueManagementTable.component";
import { TableFilter } from "components/table/table-filter/table-filter";
import { ContextSettings } from "context/context-settings";
import { CusTypo } from "components/cusTypo/custom-typography";
import { HARDCODED_FILTER_ITEMS, TABLE_SORT_DEFAULT_ASKUE, links } from "./const";
import { STop, STitle, SWrap, SSettings, SBtns } from "./styles";
import { MTSButton } from "components/mts-button/mts-button";
import { TableSort } from "components/table/table-sort/table-sort";
import Icon from "components/icons";

export const ManagementAskue = () => {
  const { isDesktop } = useContext(ContextSettings);
  const [dataLength, setDataLength] = useState(undefined);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [columnFilter, setColumnFilter] = useState(undefined);
  const [searchVal, setSearch] = useState("");
  const [isOpenSort, setIsOpenSort] = useState(false);
  const [columnSort, setColumnSort] = useState<any>([]);

  const handleColumnSort = (sorting) => setColumnSort(sorting);

  const tableData = useManagementAskue();

  const debouncedSearch = useDebounce(searchVal, 500);

  const handleInput = (e) => {
    setSearch(e.target.value);
  };

  const handleColumnFilter = ({ filtersArr }) => {
    setColumnFilter(filtersArr);
  };

  const getDataLength = (a) => setDataLength(a.length);

  return (
    <>
      <SWrap>
        <Breadcrumbs links={links} />
        <STop>
          <STitle>
            <CusTypo variant={isDesktop() ? "h2_medium" : "h4_medium"} font="wide">
              Список заявок
            </CusTypo>
          </STitle>
          <SSettings>
            <div className="searchField">
              <MTSInput
                searchIcon
                placeholder="Название организации"
                onChange={handleInput}
                value={searchVal}
                size="S"
              />
            </div>
            <SBtns>
              <TableFilter
                dataLength={dataLength ?? 0}
                isOpen={isOpenFilter}
                isOn={true}
                defaultFilter={{ status: [] }}
                toogleShowFilter={setIsOpenFilter}
                onApplyFilter={handleColumnFilter}
                scheme={HARDCODED_FILTER_ITEMS}
                isSchemeOnly
              />
              {!isDesktop() ? (
                <>
                  <MTSButton
                    size="S"
                    variant="secondary"
                    onClick={() => setIsOpenSort(true)}
                    format="icon"
                    icon={<Icon.Sort sx={{ width: 16, height: 16 }} />}
                  />
                  <TableSort
                    items={TABLE_SORT_DEFAULT_ASKUE}
                    isOpen={isOpenSort}
                    close={() => setIsOpenSort(false)}
                    onApplySort={handleColumnSort}
                    view="modal"
                  />
                </>
              ) : null}
            </SBtns>
          </SSettings>
        </STop>
        <AskueManagementTable
          rawData={tableData.data ?? []}
          globalFilter={debouncedSearch}
          filterByColumn={columnFilter}
          onRowsChange={getDataLength}
          sortByColumn={columnSort}
        />
      </SWrap>
      <Footer />
      {tableData?.isLoading ? <Loader isFullPage /> : null}
    </>
  );
};
