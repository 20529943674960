import { useChangeVisibility } from "hooks/api/management";
import { MTSButton } from "components/mts-button/mts-button";
import { Spacer } from "components/spacer/spacer.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import Icon from "components/icons";
import { SControls, SStack } from "./styles";
import { SModalTitle } from "components/modal-notify-body/styles";

interface IProp {
  onCancel: () => void;
  id: number | string;
  isHide: boolean;
}

const showText = "Товар снова начнет отображаться в магазине с текущими характеристиками";
const hideText =
  "Товар перестанет отображаться в магазине, но при этом сохранятся все его текущие характеристики";

export const ChangeShowProdForm: React.FC<IProp> = ({ id, isHide, onCancel }) => {
  const { mutate } = useChangeVisibility();

  return (
    <SStack>
      <Icon.Warn sx={{ width: 44, height: 44 }} />
      <Spacer value="16px" />
      <SModalTitle styles={{ textAlign: "center" }}>
        Вы действительно хотите {isHide ? "показать" : "скрыть"} товар?
      </SModalTitle>
      <Spacer value="8px" />
      <CusTypo variant="p3_regular">{isHide ? showText : hideText}</CusTypo>
      <Spacer value="20px" />
      <SControls>
        <MTSButton
          size="M"
          variant="primary"
          type="submit"
          onClick={() => {
            mutate({
              hide_flag: !isHide,
              id: id,
            });
          }}
        >
          {isHide ? "Показать товар" : "Скрыть товар"}
        </MTSButton>
        <MTSButton size="M" variant="secondary" onClick={onCancel}>
          Отмена
        </MTSButton>
      </SControls>
    </SStack>
  );
};
