import Icon from "components/icons";
import { CustomWidthTooltip } from "components/tooltip/tooltip";
import { SLabel } from "./styles";

export const LabelTooltip = ({ tooltip, label }) => {
  return (
    <SLabel>
      <span>{label}</span>
      <CustomWidthTooltip
        title={<div style={{ fontWeight: 400 }}>{tooltip}</div>}
        arrow
        placement="right"
      >
        <span>
          <Icon.Info sx={{ width: 16, height: 16, margin: "0 0 0 8px", alignSelf: "end" }} />
        </span>
      </CustomWidthTooltip>
    </SLabel>
  );
};
