import { LIST_ROLES_NAME } from "utils/roles";

export const renderTooltipByVal = (val: string) => {
  switch (val) {
    case LIST_ROLES_NAME.ADMIN:
      return "<ul><li>добавление/удаление тенантов;</li><li>управление учетными записями пользователей всех ролей;</li><li>управление интеграцией с внешними системами и задачами;</li><li>настройка внешних источников данных;</li><li>просмотр журнала событий системы;</li><li>просмотра записей об операциях, которые были выполнены пользователями системы других ролей;</li><li>настройка общесистемных параметров;</li><li>персонализация пользователя.</li></ul>";
    case LIST_ROLES_NAME.OWNER:
      return '<ul><li>управление учетными записями пользователей всех ролей;</li><li>просмотр и анализ данных своей организации;</li><li>формирование отчетов по объектам учета своей организации;</li><li>просмотр вкладки "Счета" своей организации;</li><li>просмотр виджетов своего тенанта;</li><li>просмотр архивных данных своей организации.</li></ul>';
    case LIST_ROLES_NAME.ANALYTIC:
      return '<ul><li>просмотр и анализ данных своей организации;</li><li>формирование отчетов по объектам учета своего тенанта;</li><li>просмотр вкладки "Счета" своей организации;</li><li>просмотр виджетов своего тенанта;</li><li>просмотр архивных данных своей организации.</li></ul>';
    case LIST_ROLES_NAME.ENGINEER:
      return "<ul><li>просмотр основных виджетов своей организации;</li><li>формирование отчетов по объектам учета своей организации;</li><li>просмотр архивных данных своей организации.</li></ul>";
    case LIST_ROLES_NAME.ANALYST_SYSTEM:
      return '<ul><li>добавление/удаление тенантов;</li><li>управление учетными записями пользователей всех ролей;</li><li>просмотр и анализ данных всех компаний;</li><li>формирование отчетов по объектам учета;</li><li>просмотр вкладки "Счета" всех компаний;</li><li>просмотр виджетов всех компаний;</li><li>просмотр архивных данных всех компаний.</li></ul>';
    default:
      return "";
  }
};
