import { IEventsResponse } from "interface/management";

export const base: IEventsResponse[] = [
  {
    userName: "Василий",
    roles: [
      {
        id: 0,
        name: "Администратор системы",
      },
    ],
    events: [
      {
        eventType: "Информация",
        eventDatetime: "2023-06-17T12:26:28.167Z",
        eventDesc: "Выход из системы",
        objectId: null,
        objectType: null,
        objectName: null,
      },
    ],
  },
  {
    userName: "Александр Ветров",
    roles: [
      {
        id: 0,
        name: "Администратор системы",
      },
    ],
    events: [
      {
        eventType: "Авария",
        eventDatetime: "2023-04-13T12:24:28.167Z",
        eventDesc: "Удаление пользователя <b>Иван Елисеев</b>",
        objectId: null,
        objectType: null,
        objectName: null,
      },
    ],
  },
  {
    userName: "Василий",
    roles: [
      {
        id: 0,
        name: "Администратор системы",
      },
    ],
    events: [
      {
        eventType: "Информация",
        eventDatetime: "2024-10-01T12:26:28.167Z",
        eventDesc: "Event 1",
        objectId: null,
        objectType: null,
        objectName: null,
      },
    ],
  },
  {
    userName: "Константин Константинопольский",
    roles: [
      {
        id: 0,
        name: "Администратор системы",
      },
      {
        id: 2,
        name: "Владелец тенанта",
      },
    ],
    events: [
      {
        eventType: "Предупреждение",
        eventDatetime: "2023-03-06T12:24:28.167Z",
        eventDesc: "Удаление организац <b>“Север Энерго”</b>",
        objectId: null,
        objectType: null,
        objectName: null,
      },
      {
        eventType: "Предупреждение",
        eventDatetime: "2024-08-15T12:24:28.167Z",
        eventDesc: "Редактирование часового пояса <b>“Башкир Энерго”</b>",
        objectId: null,
        objectType: null,
        objectName: null,
      },
      {
        eventType: "Предупреждение",
        eventDatetime: "2019-02-17T12:27:28.167Z",
        eventDesc: "Ошибка входа в систему",
        objectId: null,
        objectType: null,
        objectName: null,
      },
    ],
  },
  {
    userName: "Александр Ветров",
    roles: [
      {
        id: 0,
        name: "Администратор системы",
      },
    ],
    events: [
      {
        eventType: "Авария",
        eventDatetime: "2023-02-17T12:24:28.167Z",
        eventDesc: "Event 2",
        objectId: null,
        objectType: null,
        objectName: null,
      },
    ],
  },
  {
    userName: "Василий",
    roles: [
      {
        id: 0,
        name: "Администратор системы",
      },
    ],
    events: [
      {
        eventType: "Информация",
        eventDatetime: "2024-02-17T12:26:28.167Z",
        eventDesc: "Event 3",
        objectId: null,
        objectType: null,
        objectName: null,
      },
    ],
  },
  {
    userName: "Константин Константинопольский",
    roles: [
      {
        id: 0,
        name: "Администратор системы",
      },
      {
        id: 2,
        name: "Владелец тенанта",
      },
    ],
    events: [
      {
        eventType: "Предупреждение",
        eventDatetime: "2023-02-17T12:24:28.167Z",
        eventDesc: "Ошибка входа в систему",
        objectId: null,
        objectType: null,
        objectName: null,
      },
      {
        eventType: "Предупреждение",
        eventDatetime: "2024-02-17T12:24:28.167Z",
        eventDesc: "Удаление организации <b>“Север Энерго”</b>",
        objectId: null,
        objectType: null,
        objectName: null,
      },
      {
        eventType: "Предупреждение",
        eventDatetime: "2025-02-17T12:27:28.167Z",
        eventDesc: "Ошибка входа в систему",
        objectId: null,
        objectType: null,
        objectName: null,
      },
    ],
  },
  {
    userName: "Александр Ветров",
    roles: [
      {
        id: 0,
        name: "Администратор системы",
      },
    ],
    events: [
      {
        eventType: "Авария",
        eventDatetime: "2023-02-17T12:24:28.167Z",
        eventDesc: "Event 4",
        objectId: null,
        objectType: null,
        objectName: null,
      },
    ],
  },
  {
    userName: "Василий",
    roles: [
      {
        id: 0,
        name: "Администратор системы",
      },
    ],
    events: [
      {
        eventType: "Информация",
        eventDatetime: "2024-02-17T12:26:28.167Z",
        eventDesc: "Event 5",
        objectId: null,
        objectType: null,
        objectName: null,
      },
    ],
  },
  {
    userName: "Александр Ветров",
    roles: [
      {
        id: 0,
        name: "Администратор системы",
      },
    ],
    events: [
      {
        eventType: "Авария",
        eventDatetime: "2023-02-17T12:24:28.167Z",
        eventDesc: "Event 6",
        objectId: null,
        objectType: null,
        objectName: null,
      },
    ],
  },
];
