import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { SChildPop, SPop, SPopItem, SPopList } from "components/popup-action/styles";
import { CusTypo } from "components/cusTypo/custom-typography";
import { Toast } from "components/toast/toast";
import { useUpdateFb, useUpdateBd, useUpdateT2, useUpdateT3 } from "hooks/api/analytics";

const bd = "Скоры Big Data";
const fd = "Статус фрода";
const t2 = "Тикет “Майнеры”";
const t3 = "Тикет “Снижение”";

export const DataUpdate = ({ isFilterOn }) => {
  const [child, setChild] = useState<string | undefined>(undefined);

  const { mutate: updFb } = useUpdateFb(isFilterOn);
  const { mutate: updBd } = useUpdateBd(isFilterOn);
  const { mutate: updT2 } = useUpdateT2(isFilterOn);
  const { mutate: updT3, isLoading } = useUpdateT3(isFilterOn);

  const items = [
    {
      title: fd,
      onClick: () =>
        updFb(undefined, {
          onSuccess: () => {
            toastSucc(fd);
          },
          onError: () => {
            toastErr("Ошибка обновления");
          },
        }),
    },
    {
      title: bd,
      onClick: () =>
        updBd(undefined, {
          onSuccess: () => {
            toastSucc(bd);
          },
          onError: () => {
            toastErr("Ошибка обновления");
          },
        }),
    },
    {
      title: t2,
      onClick: () => setChild("miners"),
    },
    {
      title: t3,
      onClick: () =>
        updT3(undefined, {
          onSuccess: () => {
            toastSucc(t3);
          },
          onError: () => {
            toastErr("Ошибка обновления");
          },
        }),
    },
  ];

  const childItems = [
    {
      title: "Весна",
      onClick: () =>
        updT2(2, {
          onSuccess: () => {
            toastSucc(t2);
          },
          onError: (e) => {
            toastErr(e?.description);
          },
        }),
    },
    {
      title: "Осень",
      onClick: () =>
        updT2(1, {
          onSuccess: () => {
            toastSucc(t2);
          },
          onError: (e) => {
            toastErr(e?.description);
          },
        }),
    },
  ];

  useEffect(() => {
    if (isLoading) toastPending();
  }, [isLoading]);

  return (
    <SPop pos={"right"} isOpen style={{ top: 0 }}>
      <SPopList>
        {items.map((item: any, index: number) => (
          <SPopItem
            key={index}
            onClick={() => {
              item.onClick();
            }}
          >
            <CusTypo variant="p4_regular">{item.title}</CusTypo>
          </SPopItem>
        ))}
      </SPopList>
      {child ? (
        <SChildPop style={{ minHeight: "inherit" }}>
          {childItems.map((item: any, index: number) => (
            <SPopItem
              key={index}
              onClick={() => {
                item.onClick();
              }}
            >
              <CusTypo variant="p4_regular">{item.title}</CusTypo>
            </SPopItem>
          ))}
        </SChildPop>
      ) : null}
    </SPop>
  );
};

const toastSucc = (ent: string) => {
  toast(<Toast title={`${ent} обновлен`} />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });
};

const toastErr = (msg) => {
  toast(<Toast title={msg ?? "Информация не обновлена"} isError />, {
    progress: undefined,
    autoClose: 4000,
    hideProgressBar: true,
  });
};

export const toastPending = () =>
  toast(<Toast title="Ожидание обновления Тикетов" isLoading />, {
    progress: undefined,
    autoClose: 10000,
    hideProgressBar: true,
  });
