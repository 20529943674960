import { useMemo, useState, useCallback } from "react";

import { IDemoManagementResponse } from "interface/management";
import { ITableFilterClm, Table } from "components/table/table.component";

import { tableStructure } from "./const";
import { constructColumns } from "./utils";

interface IProp {
  rawData: IDemoManagementResponse[];
  filterBy?: any;
  filterByColumn?: ITableFilterClm[];
  globalFilter?: string;
  onRowsChange?: Function;
}
export const DemoManagementTable: React.FC<IProp> = ({
  rawData,
  filterByColumn,
  globalFilter,
  onRowsChange,
}) => {
  const tableData = useMemo(() => rawData ?? [{}], [rawData]);
  const [selected, setSelected] = useState<any>({});

  const toggle = useCallback((id) => setSelected((a) => ({ [id]: !a[id] })), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRowsChange = useCallback((a) => onRowsChange?.(a), []);

  const columns = useMemo(
    () =>
      constructColumns({
        columns: tableStructure,
        selectedId: selected,
      }),
    [selected],
  );

  return (
    <Table
      columns={columns}
      data={tableData}
      view="expandable"
      onRowClick={toggle}
      filterByColumn={filterByColumn}
      globalFilter={globalFilter}
      height={612}
      incViewportBy={400}
      onRowsChange={handleRowsChange}
    />
  );
};
