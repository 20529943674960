import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { CusTypo } from "components/cusTypo/custom-typography";

const useStyles = makeStyles((theme: Theme) => ({
  errorBlock: {
    width: "100%",
    marginTop: "4px",
    height: "auto",
    display: "flex",
    alignItems: "center",
  },
  errorMsg: {
    "&.MuiTypography-root": {
      color: theme.mtsColor.accent.negative.lightMode,
    },
  },
}));

interface IInputErrorMessageProps {
  errorMessage: undefined | string;
}

export const InputErrorMessage = (props: IInputErrorMessageProps) => {
  const { errorMessage } = props;
  const classes = useStyles();

  return (
    <div className={classes.errorBlock}>
      {errorMessage && (
        <CusTypo variant="c1_regular" className={classes.errorMsg}>
          {errorMessage}
        </CusTypo>
      )}
    </div>
  );
};
