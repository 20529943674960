import styled from "@emotion/styled";
import { device } from "theme";

export const SWrap = styled.div`
  max-width: 100%;
  padding: 48px 20px 0px;
  text-align: left;
`;

export const STop = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "title btns"
    "settings settings";
  gap: 12px 4px;
  padding-bottom: 12px;

  @media ${device.miniTabletPlus} {
    grid-template-columns: auto 1fr auto;
    grid-template-areas: "title settings btns";
    gap: 16px;
  }

  @media ${device.desktopPlus} {
    padding-bottom: 0;
  }
`;

export const STitle = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  grid-area: title;
  & .MuiButton-root {
    position: fixed;
    right: 20px;
    bottom: 48px;
    z-index: 10;

    @media ${device.desktopPlus} {
      position: inherit;
      right: inherit;
      bottom: inherit;
      margin-left: 16px;
    }
  }
`;

export const SSettings = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  grid-area: settings;

  @media ${device.desktopPlus} {
    gap: 16px;
    justify-content: end;
  }
`;

export const SItem = styled.div`
  &.searchField {
    min-width: 300px;
    width: 100%;

    @media ${device.miniTabletPlus} {
      width: inherit;
    }
  }
`;

export const SBody = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 20px;
  margin: 0 -20px;
  padding-bottom: 150px;

  @media ${device.desktopPlus} {
    padding-top: 48px;
  }

  & > div {
    & > div:not(:last-child) {
      margin-bottom: 12px;

      @media ${device.desktopPlus} {
        margin-bottom: 8px;
      }
    }
  }
`;

export const SBtns = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  grid-area: btns;

  @media ${device.desktopPlus} {
    gap: 16px;
  }
`;
