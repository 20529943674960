import { Modal, Box } from "@mui/material";
import styled from "@emotion/styled";
import rgba from "polished/lib/color/rgba";
import { device } from "theme";
import { customScrollVertical } from "components/table/utils";

export const SOuter = styled(Modal)`
  & .MuiBackdrop-root {
    background: ${rgba("#111", 0.25)};
  }
`;

const widthBySize = {
  S: "440px",
  M: "640px",
  L: "1000px",
};

export const SModBox = styled(Box)<{ size: "S" | "M" | "L" }>`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 100%;
  min-height: 10px;
  height: auto;
  background-color: ${(props) => props.theme.mtsColor.background.modal.lightMode};
  border-radius: 16px 16px 0px 0px;
  outline: none;

  @media ${device.miniTabletPlus} {
    max-width: ${(props) => widthBySize[props.size]};
    bottom: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
  }
`;

export const SContent = styled.div`
  max-height: 95vh;
  padding: 20px 20px 40px 20px;
  overflow-y: auto;
  ${(props) =>
    customScrollVertical({
      theme: props.theme,
      trackStart: 16,
    })};

  @media ${device.miniTabletPlus} {
    padding-bottom: 20px;
    ${(props) =>
      customScrollVertical({
        theme: props.theme,
        trackStart: 16,
        trackEnd: 16,
      })};
  }
`;

export const SClose = styled.div`
  display: none;
  position: absolute;
  top: 20px;
  right: -40px;

  & .MuiSvgIcon-root {
    fill: #fff;
    cursor: pointer;
  }

  @media ${device.miniTabletPlus} {
    display: block;
  }
`;
