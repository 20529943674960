import { ManagAskueMobCard } from "components/card-info/askue/askue.component";
import { RenderFilter } from "components/table/render-filter/renderFilter.component";

function multiSelectStatus(rows, columnIds, filterValue) {
  return filterValue.length === 0
    ? rows
    : rows.filter((row) => {
        const { status } = row.original;
        return status && filterValue.includes(String(status));
      });
}

export const tableStructure = [
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Организация"
        withPadding
      />
    ),
    accessor: "tenant_name",
    minWidth: 206,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Дата и время"
        withPadding
      />
    ),
    accessor: "created_time",
    minWidth: 198,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Статус заявки"
        withPadding
      />
    ),
    accessor: "status",
    minWidth: 130,
    canFilter: true,
    filter: multiSelectStatus,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Комментарии"
        withPadding
      />
    ),
    accessor: "comment",
    minWidth: 270,
  },
  {
    accessor: "action",
    minWidth: 56,
    width: "100%",
    disableSortBy: true,
  },
];

export const tableMobStructure = [
  {
    accessor: "tenant_name",
    Row: ManagAskueMobCard,
  },
  {
    accessor: "created_time",
  },
  {
    accessor: "status",
  },
  {
    accessor: "comment",
  },
];
