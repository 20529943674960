import styled from "@emotion/styled";
import { CusTypo } from "components/cusTypo/custom-typography";

export const SInfo = styled(CusTypo)`
  display: block;
  color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
`;

export const SHist = styled.div`
  & > li {
    padding: 18px 24px;
    min-width: 0;
    display: flex;
    flex-flow: row nowrap;
    gap: 0px 32px;
    background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
    &:nth-of-type(2n) {
      background-color: ${(props) => props.theme.mtsColor.background.primary.lightMode};
    }
  }
`;

export const SStack = styled.div`
  min-width: 0;
  min-height: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  & > button {
    width: 100%;
  }
`;

export const SCont = styled.div`
  flex: 1 1 100%;
  padding: 12px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  text-align: left;
`;

export const SItems = styled.div`
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0;

    & > span:first-of-type {
      color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid ${(props) => props.theme.mtsColor.background.secondary.lightMode};
    }
  }
`;
