import React, { useContext, useLayoutEffect, useMemo, useState } from "react";
import theme from "theme";
import { ContextSettings } from "context";
import { AccordionIcon } from "components/accordion-icon/accordion-icon";
import Icon from "components/icons";
import { IDataSidebar } from "interface/common";
import { CusTypo } from "components/cusTypo/custom-typography";
import { getSidebarList } from "./const";
import { SAccBody, SHeadWrap, SItemName, SLink, SRow, SSubItem } from "./styles";

const renderIcon = (val) => {
  switch (val) {
    case "askue":
      return <Icon.Askue sx={{ width: 24, height: 24 }} />;
    case "anomalies":
      return <Icon.Anomalies sx={{ width: 24, height: 24 }} />;
    case "analytics":
      return <Icon.Analytics sx={{ width: 24, height: 24 }} />;
    case "departures":
      return <Icon.GeoPosition sx={{ width: 24, height: 24 }} />;
    case "management":
      return <Icon.Settings sx={{ width: 24, height: 24, fill: theme.mtsColor.red }} />;
    case "mining":
      return <Icon.Bitcoin sx={{ width: 24, height: 24 }} />;
    default:
      return <Icon.Home sx={{ width: 24, height: 24 }} />;
  }
};

interface SidebarAccordionProps {
  isOpenSidebar?: boolean;
  close: () => void;
  open: () => void;
}

export const SidebarAccordion = (props: SidebarAccordionProps) => {
  const { isOpenSidebar, open, close } = props;
  const { roles, profile } = useContext(ContextSettings);
  const contentEl = React.useRef<any>([]);
  const [expanded, setExpanded] = useState<number>(-1);
  const [sortedSidebarData, setSortedSidebarData] = useState<IDataSidebar[]>([]);
  const [heightDetail, setHeightDetail] = useState<number>(72);

  useMemo(() => {
    setSortedSidebarData(getSidebarList(roles, profile?.services_ch ?? []));
  }, [roles, profile?.services_ch]);

  const expandedAccordionSummary = (obj: any, index: number) =>
    obj.subitem ? (
      <AccordionIcon
        isActive={expanded === index}
        onToggle={() => handleToggle(index)}
        index={index}
      />
    ) : null;

  const showFullSidebar = (event: any, index: number) => {
    event.preventDefault();
    if (!isOpenSidebar) {
      open();
      setExpanded(index);
    }
  };

  const handleToggle = (index: number) => setExpanded(expanded === index ? -1 : index);

  useLayoutEffect(() => {
    if (contentEl.current) {
      const newVal = contentEl?.current[expanded]?.clientHeight;
      setHeightDetail(newVal);
    }
  }, [expanded, isOpenSidebar]);

  return (
    <div>
      {sortedSidebarData.map((item: any, index: number) => (
        <div key={index}>
          <SHeadWrap
            to={item.link}
            onClick={(e) => {
              setExpanded(index);
            }}
          >
            {!isOpenSidebar ? (
              <SLink to="" onClick={(event) => showFullSidebar(event, index)}>
                {renderIcon(item.id)}
              </SLink>
            ) : (
              <SLink to={item.link} onClick={close}>
                <SRow>
                  {renderIcon(item.id)}
                  <SItemName variant={isOpenSidebar && item.isActive ? "p3_medium" : "p3_regular"}>
                    {item.title}
                  </SItemName>
                </SRow>
                {expandedAccordionSummary(item, index)}
              </SLink>
            )}
          </SHeadWrap>
          {isOpenSidebar ? (
            <SAccBody style={{ height: expanded === index ? heightDetail : 0 }}>
              <ul ref={(el) => (contentEl.current[index] = el)}>
                {item.subitem?.map((element: any) => (
                  <li key={element.name}>
                    <SSubItem to={element.link} onClick={close}>
                      <CusTypo variant="p4_regular">{element.name}</CusTypo>
                    </SSubItem>
                  </li>
                ))}
              </ul>
            </SAccBody>
          ) : null}
        </div>
      ))}
    </div>
  );
};
