import styled from "@emotion/styled";
import { css } from "@emotion/react";
import format from "date-fns/format";
import { ru } from "date-fns/locale";

import { CusTypo } from "components/cusTypo/custom-typography";
import { EMPTY_CELL_VALUE } from "stream-constants/keys-and-constants";
import { SDown } from "../summary/styles";
import { MINING_DOWN } from "services/urls";

const SRow = styled.div`
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 50px;
  column-gap: 16px;
`;

export const sortCss = css`
  width: 24px;
  height: 24px;

  & > svg {
    width: 16px;
    height: 16px;
  }
`;

export const MobAskueArchive = ({ row }) => {
  const { fileName, time_schema, file_path } = row.original;

  return (
    <SRow>
      <CusTypo variant="c1_medium" font="comp">
        {fileName ?? EMPTY_CELL_VALUE}
      </CusTypo>
      <CusTypo variant="c1_regular" font="comp">
        {time_schema
          ? format(time_schema, "dd.MM.yyyy HH:mm", {
              locale: ru,
            })
          : EMPTY_CELL_VALUE}
      </CusTypo>
      <CusTypo variant="c1_regular" font="comp">
        <SDown href={`${MINING_DOWN}/${file_path}`}>Скачать</SDown>
      </CusTypo>
    </SRow>
  );
};
