import Icon from "components/icons";
import { Spacer } from "components/spacer/spacer.component";
import { LOG_IN, MAIL_SUPPORT, URL_LOGOUT_MTS_BY_ID } from "services/urls";
import { SIcons, SLink, SModalTitle, SText } from "./styles";
import { MTSButton } from "components/mts-button/mts-button";

interface IProp {
  title: string;
  isError?: boolean;
  isSuccess?: boolean;
  isNotAvailable?: boolean;
  isNotRegistration?: boolean;
  textDescrioption?: string; // если используется текст под заголовком без ссылки
  close?: Function;
}

export const ModalNotifyBody: React.FC<IProp> = ({
  title,
  isError,
  isSuccess,
  isNotAvailable,
  isNotRegistration,
  textDescrioption,
  close,
}) => {
  return (
    <>
      <SIcons>
        {isError ? (
          <Icon.ErrorShaped sx={{ width: 44, height: 44 }} />
        ) : isSuccess ? (
          <Icon.CheckShaped sx={{ width: 44, height: 44 }} />
        ) : null}
      </SIcons>
      <Spacer value="16px" />
      <SModalTitle styles={{ textAlign: "center" }}>{title}</SModalTitle>
      {isNotAvailable ? (
        <>
          <Spacer value="8px" />
          <SText>
            {"Попробуйте "}
            <SLink href={URL_LOGOUT_MTS_BY_ID(LOG_IN)}>войти позже</SLink>
            <div>
              {"или "}
              <SLink href={MAIL_SUPPORT}>свяжитесь с поддержкой</SLink>
            </div>
          </SText>
          <Spacer value="20px" />
          <MTSButton
            size="M"
            variant="secondary"
            onClick={() => close?.()}
            style={{ width: "100%" }}
          >
            Закрыть
          </MTSButton>
        </>
      ) : null}

      {isNotRegistration ? (
        <>
          <Spacer value="8px" />

          <SText variant="p4_regular">
            {"Попробуйте "}
            <SLink href={URL_LOGOUT_MTS_BY_ID(LOG_IN)}>войти с другим номером</SLink>
            <div>
              {"или "}
              <SLink href={MAIL_SUPPORT}>свяжитесь с поддержкой</SLink>
            </div>
          </SText>
          <Spacer value="20px" />
          <MTSButton
            size="M"
            variant="secondary"
            onClick={() => close?.()}
            style={{ width: "100%" }}
          >
            Закрыть
          </MTSButton>
        </>
      ) : null}

      {textDescrioption ? (
        <>
          <Spacer value="16px" />

          <SText variant="p4_regular">{textDescrioption}</SText>
        </>
      ) : null}
    </>
  );
};
