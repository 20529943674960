import { useContext, useMemo, useState } from "react";
import { isEmpty } from "lodash";

import { MTSInput } from "components/mts-input";
import { Spacer } from "components/spacer/spacer.component";
import { useDebounce } from "hooks/useDebounce";
import { IDemoScore } from "interface/management";
import { Card } from "../card/card.component";
import { DemoTable } from "../demoTable/demoTable.component";
import { Chart } from "components/chart/Chart.component";
import { useDemoChart } from "hooks/api/management";
import { makeDemoChartData } from "./utils";
import { ContextSettings } from "context/context-settings";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SChartWrap } from "components/chart/styles";
import { SWrap, SRow, SGrid } from "./styles";

const makeCards = (somedata: IDemoScore[]) => {
  const temp = {
    Высокая: 0,
    Средняя: 0,
    Низкая: 0,
    Фрод: 0,
  };

  somedata.forEach((a) => temp[a.score]++);
  return Object.entries(temp).map(([score, amount]) => ({ status: score, amount }));
};
interface IProp {
  scoreData?: IDemoScore[];
}

export const DemoGraph: React.FC<IProp> = ({ scoreData }) => {
  const { isDesktop } = useContext(ContextSettings);
  const [statuses, setStatuses] = useState<any>([]);
  const [selected, setSelected] = useState<number>(0);
  const [searchVal, setSearch] = useState("");
  const debouncedSearch = useDebounce(searchVal, 500);
  const chart = useDemoChart(selected);
  const chartData = makeDemoChartData(chart.data ?? []);

  const handleClick = (stat: string) =>
    setStatuses((s) => (s.includes(stat) ? s.filter((a) => a !== stat) : [...s, stat]));
  const resetStatuses = () => setStatuses([]);

  const handleInput = (e) => {
    resetStatuses();
    setSearch(e.target.value);
  };

  const demoTableFilter = useMemo(() => [{ column: "severity", value: statuses }], [statuses]);

  return (
    <SWrap>
      <Spacer value="48px" />
      <CusTypo variant="p4_regular">Демо аналитической платформы</CusTypo>
      <Spacer value="48px" />
      {!isEmpty(scoreData) || !scoreData ? (
        <>
          <SRow>
            <CusTypo variant={isDesktop() ? "h2_bold" : "h3_bold"}>Количество абонентов</CusTypo>
            <MTSInput
              className="searchField"
              searchIcon
              placeholder="Адрес, ID, серийный номер"
              onChange={handleInput}
              value={searchVal}
              size="S"
            />
          </SRow>
          <Spacer value="48px" />
          <SGrid>
            {makeCards(scoreData ?? []).map(({ status, amount }, index) => (
              <Card
                status={status as any}
                amount={amount}
                key={index}
                onClick={handleClick}
                selected={statuses.includes(status)}
              />
            ))}
          </SGrid>
          <Spacer value="4px" />
          <DemoTable
            rawData={scoreData!}
            filterByColumn={demoTableFilter}
            globalFilter={debouncedSearch}
            onRowClick={setSelected}
            rowSelected={selected}
          />
          <Spacer value="16px" />
          <SChartWrap>
            <Spacer value="24px" />
            <Chart id={selected} chartData={chartData as any} />
          </SChartWrap>
          <Spacer value="96px" />
        </>
      ) : (
        <CusTypo variant="h2_bold">Произошла ошибка. Нет данных для построения информации</CusTypo>
      )}
    </SWrap>
  );
};
