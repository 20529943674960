import styled from "@emotion/styled";
import theme, { device } from "theme";

export const chartColor1 = theme.mtsColor.base.day;
export const chartColor2 = theme.mtsColor.base.night;
export const chartColor3 = theme.mtsColor.accent.positive.darkMode;
export const chartColor4 = theme.mtsColor.accent.warning.darkMode;

export const SChartWrap = styled.div`
  padding: 32px 24px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
`;

export const SCurrent = styled.div`
  padding-left: 16px;
  text-align: left;
`;

export const STitle = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-left: 16px;

  & > span {
    margin-right: 64px;
    text-align: left;
    white-space: nowrap;
  }

  & > div:first-of-type {
    margin-right: 24px;
    display: flex;
    flex-flow: row nowrap;

    & > section {
      display: flex;
      margin-right: 16px;

      & > div {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        border-radius: 2px;
      }
      &:nth-of-type(1) {
        & > div {
          background-color: ${chartColor1};
        }
      }
      &:nth-of-type(2) {
        & > div {
          background-color: ${chartColor2};
        }
      }
      &:nth-of-type(3) {
        & > div {
          background-color: ${chartColor3};
        }
      }
      &:nth-of-type(4) {
        & > div {
          background-color: ${chartColor4};
        }
      }
    }
  }
`;

export const STop = styled.div`
  position: relative;
  top: -6px;
  display: block;

  & ${STitle} {
    margin-bottom: 24px;
  }

  @media ${device.desktopPlus} {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    & ${STitle} {
      margin: 0;
    }
  }
`;
