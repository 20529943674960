import { SImg } from "./styles";

import toCheckImg from "./images/toCheck.svg";
import normalImg from "./images/normal.svg";
import hackingImg from "./images/hacking.svg";
import suspicionImg from "./images/suspicion.svg";
import noLoadImg from "./images/noLoad.svg";
import noAccessImg from "./images/noAccess.svg";
import defectiveImg from "./images/defective.svg";
import replacedImg from "./images/replaced.svg";

export const iconByStatus = (val, title) => {
  switch (val) {
    case "toCheck":
      return <SImg src={toCheckImg} alt={title} />;
    case "normal":
      return <SImg src={normalImg} alt={title} />;
    case "hacking":
      return <SImg src={hackingImg} alt={title} />;
    case "suspicion":
      return <SImg src={suspicionImg} alt={title} />;
    case "noLoad":
      return <SImg src={noLoadImg} alt={title} />;
    case "defective":
      return <SImg src={defectiveImg} alt={title} />;
    case "replaced":
      return <SImg src={replacedImg} alt={title} />;
    default:
      return <SImg src={noAccessImg} alt={title} />;
  }
};
