import { RenderFilter } from "components/table/render-filter/renderFilter.component";

function multiSelectStatus(rows, columnIds, filterValue) {
  return filterValue.length === 0
    ? rows
    : rows.filter((row) => {
        const { status } = row.original;
        return status && filterValue.includes(String(status));
      });
}

export const tableStructure = [
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Организация"
        withPadding
      />
    ),
    accessor: "title",
    minWidth: 324,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Дата регистрации"
        withPadding
      />
    ),
    accessor: "created_time",
    minWidth: 164,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Статус"
        withPadding
      />
    ),
    accessor: "status",
    minWidth: 156,
    canFilter: true,
    filter: multiSelectStatus,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Телефон"
        withPadding
      />
    ),
    accessor: "phone",
    minWidth: 196,
  },
  {
    accessor: "action",
    minWidth: 56,
    width: "100%",
    disableSortBy: true,
  },
];
