import { IBreadcrumbsLink } from "interface/common";
import { IMapMarker } from "interface/map";
import { PO_COLUMN, RES_COLUMN } from "pages/anomalies/const";
import { ARCHIVE_COLUMN } from "pages/mapPage/const";
import { ROUTE_DEPARTURES } from "stream-constants/route-constants";

export const links: IBreadcrumbsLink[] = [{ name: "Выезды", href: `/${ROUTE_DEPARTURES}` }];

export const makeDepStats = (arr: IMapMarker[] | []) => {
  return arr
    .filter((a) => a.task_status != null)
    .reduce((tot, cur) => {
      if (tot[cur.task_status!] == null) {
        return {
          ...tot,
          [cur.task_status!]: 1,
        };
      } else {
        let newVal = tot[cur.task_status!];
        return {
          ...tot,
          [cur.task_status!]: newVal + 1,
        };
      }
    }, {} as any);
};

export const hardcoded_storage = [
  { column: RES_COLUMN, value: [] },
  { column: PO_COLUMN, value: [] },
  { column: ARCHIVE_COLUMN, value: null },
];
