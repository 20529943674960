import styled from "@emotion/styled";
import { CusTypo } from "components/cusTypo/custom-typography";

export const SCont = styled.div`
  & > article {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    & > ul {
      li {
        display: flex;
        flex-direction: column;
        gap: 2px;
        padding: 20px 0;
        &:not(:last-of-type) {
          border-bottom: 1px solid #f9f9fb;
        }

        &:last-of-type {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        & > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 24px;
          white-space: nowrap;
          padding: 8px 12px;
          background-color: #f9f9fb;

          & > span {
            display: flex;
            align-items: center;
            gap: 5px;

            &:first-of-type {
              text-align: left;
              width: 120px;
              flex: 0 0 120px;
              color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
            }

            &:last-of-type {
              justify-content: flex-end;
              flex: 1 1 auto;
              text-align: right;
            }

            &:not(:first-of-type) {
              white-space: normal;
              text-align: right;
            }

            & > div {
              text-align: right;
            }

            &.itemIcon {
              display: flex;
              align-items: center;
              justify-content: flex-end;
            }
          }

          &.comment {
            & > span:last-of-type {
              text-align: left;
            }
          }
        }

        & > section {
          display: block;
          padding-top: 13px;

          & > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 12px;
            background-color: #f9f9fb;
          }
        }
      }
    }
  }

  & + button {
    width: 100%;
  }
`;

export const SInfoMes = styled(CusTypo)`
  display: block;
  text-align: left;
  padding: 16px 0;
  color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
`;

export const SDrop = styled.summary`
  display: block;
  max-width: 100%;
  cursor: pointer;

  & > div {
    position: relative;
    min-height: 20px;
    height: auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    white-space: nowrap;
    width: min-content;

    & > ul {
      position: absolute;
      left: 0;
      top: 24px;
      width: auto;
      background-color: #ffffff;

      & > li {
        padding: 8px;
      }
    }
  }
`;
