import { CusTypo } from "components/cusTypo/custom-typography";
import { ESn } from "interface/common";
import { SCont, SMarker } from "./styles";

interface IProp {
  amount: number;
  status: ESn;
  selected?: boolean;
  onClick: (val: string) => void;
}

// note: TG = type guard
export const isStatusCorrectTG = (entity: ESn | any): entity is ESn => {
  return typeof ESn[entity] !== "undefined";
};

export const Card: React.FC<IProp> = ({ amount, status, selected, onClick }) => {
  return isStatusCorrectTG(status) ? (
    <SCont status={status} isActive={selected} onClick={() => onClick(status)}>
      <SMarker status={status} />

      <CusTypo variant="p3_regular">{`${status} вероятность\u00a0фрода`}</CusTypo>
      <CusTypo variant="h3_bold">{amount}</CusTypo>
    </SCont>
  ) : null;
};
