import theme, { MTS_COMPACT } from "theme";
import { CusTypo } from "components/cusTypo/custom-typography";
import rgba from "polished/lib/color/rgba";
import styled from "@emotion/styled";

const colorByStatus = (val) => {
  switch (val) {
    case "success":
      return theme.mtsColor.base.success;
    case "warning":
      return theme.mtsColor.base.warning;
    case "error":
      return theme.mtsColor.base.critical;
    default:
      return theme.mtsColor.grey.greyMedium;
  }
};

export const SRow = styled.section<{ status: "success" | "error" | "warning" }>`
  padding: 6px 24px;
  min-height: 44px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  ${(props) => `background-color: ${rgba(colorByStatus(props.status), 0.08)};`}
  border-radius: 8px;

  & > div > svg {
    display: block;
  }
`;

export const SMsg = styled(CusTypo)<{ status?: "success" | "error" | "warning" | "" }>`
  font-family: ${MTS_COMPACT}, sans-serif;
  color: ${(props) => colorByStatus(props.status)};
  text-align: left;
`;
