import styled from "@emotion/styled";
import { device } from "theme";

export const SGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(82px, auto) repeat(auto-fit, 4, 1fr);
  gap: 12px;

  & .item {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    min-height: 82px;
  }

  & .time {
    grid-area: time;
  }

  & .sess {
    grid-area: sess;
  }

  & .version {
    grid-area: version;
  }

  & .chart {
    grid-area: chart;
  }

  & .name {
    grid-area: name;
  }

  grid-template-areas:
    "time sess"
    "name name"
    "version version"
    "chart chart";

  @media ${device.tabletPlus} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 140px 140px 205px;
    gap: 16px;
    grid-template-areas:
      "time sess"
      "name version"
      "chart chart";
  }
`;

export const SStatus = styled.span<{ isOk?: boolean }>`
  ${(props) => !props.isOk && `color: ${props.theme.mtsColor.text.negative.lightMode}`}
`;
