import { EDepStats, IMapMarker } from "interface/map";
import { IFormDepartures, ISel } from "../const";
import { Dispatch, SetStateAction } from "react";

export const isMarkerTG = (entity: ISel | IMapMarker): entity is IMapMarker => {
  return typeof entity["pointData"] === "undefined";
};

type IRet = {
  filter: {
    [key in EDepStats]: number;
  };
  toDeparture: IFormDepartures[];
  toCancel: number[];
};

export const preparePanFilter = (items: ISel[] | IMapMarker[]): IRet => {
  return (items as any[])?.reduce(
    (tot, cur: any) => {
      const {
        task_status = "Нет статуса выезда",
        id_pp,
        adress_too,
        lng,
        lat,
      } = isMarkerTG(cur) ? (cur as IMapMarker) : (cur as ISel).pointData;
      let curVal = tot.filter?.[task_status];
      const isToAssign =
        task_status === EDepStats["Выезд не назначен"] ||
        task_status === EDepStats["Проверка завершена"];
      return {
        filter: {
          ...tot.filter,
          [task_status]: curVal != null ? (curVal += 1) : 1,
        },
        toDeparture: [...tot.toDeparture, ...(isToAssign ? [{ id_pp, adress_too, lng, lat }] : [])],
        toCancel: [...tot.toCancel, ...(!isToAssign ? [id_pp] : [])],
      };
    },
    { filter: {}, toDeparture: [], toCancel: [] },
  );
};

type IMakePop = {
  arr: [string, number][];
  openCheck: () => void;
  invokeModal: () => void;
  setForm: Dispatch<SetStateAction<IFormDepartures[] | null>>;
  toDeparture: IFormDepartures[];
  toCancel: number[];
};
export const makePopData = ({
  arr,
  openCheck,
  invokeModal,
  setForm,
  toDeparture,
  toCancel,
}: IMakePop) => {
  let shit = 0;
  const prePopData = arr.reduce((tot, [key, val]) => {
    if (key === EDepStats.Проверяется) return { ...tot, [key]: val };
    else {
      shit += val;
      return {
        ...tot,
        "Выезд не назначен": shit,
      };
    }
  }, {} as { [key: string]: number });
  return Object.entries(prePopData).reduce((tot, [key, val]) => {
    const isUseCancel = key === EDepStats["Проверяется"];

    return val >= 1
      ? tot.concat({
          onClick: isUseCancel
            ? () => invokeModal()
            : () => {
                openCheck();
                setForm(toDeparture);
              },
          title: `${poptitleByTask[key]} (${val})`,
        })
      : tot;
  }, [] as any);
};

export const poptitleByTask = {
  [EDepStats["Выезд не назначен"]]: "Назначить проверку всем",
  [EDepStats["Проверка завершена"]]: "Назначить проверку всем",
  [EDepStats["Проверяется"]]: "Отменить проверку всем",
};
