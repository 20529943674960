import { FilterAccordion } from "./filter-accordion/filter-accordion";
import { IDropElem, IKek } from "../table-filter";
import { Radio } from "components/radio/radio.component";
import { SRadioSpan } from "../styles";

const titlebyKey = {
  created_dt: "Списки устройств",
  rele_status: "Реле нагрузки",
};

interface IProps {
  toggle: Function;
  currFilters: IKek;
  filterKey: string;
  targetArray?: IDropElem[];
}

export const RadioRenderer: React.FC<IProps> = ({
  filterKey,
  toggle,
  currFilters,
  targetArray,
}) => {
  return (
    <div style={{ margin: "0 0 48px 0" }}>
      <FilterAccordion name="details" title={titlebyKey[filterKey]}>
        {targetArray?.map((a) => (
          <SRadioSpan key={`radio_${a.name}`}>
            <Radio
              id={`details_${a.id}`}
              name={`details_${a.id}`}
              value={a.name || undefined}
              checked={
                currFilters[filterKey]?.includes(a.name!) ||
                currFilters[filterKey] === a.name ||
                false
              }
              onChange={() => toggle(filterKey, a.name)}
              text={a.friendlyName}
              color="black"
            />
          </SRadioSpan>
        ))}
      </FilterAccordion>
    </div>
  );
};
