import { fetchProfile } from "services/profile";
import { ROUTE_PERSONAL_AREA } from "stream-constants/route-constants";

export const successHandler = async (navigate, callback) => {
  try {
    const prof = await fetchProfile();
    if (prof.ok) {
      const res = await prof.json();
      callback(res);
    } else {
      throw new Error("profile error");
    }
  } catch (err) {
    throw new Error(err);
  }
  navigate(`${ROUTE_PERSONAL_AREA}`);
};
