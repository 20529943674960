import { renderCell, renderHeader, renderHeaderGroup } from "../researchTable/utils";

export const constructColumns = ({ columns, selectedId }) => {
  return columns.map((singleColumn) => {
    return {
      ...singleColumn,
      Header: ({ column }) => renderHeaderGroup(column),
      columns: singleColumn.columns?.map((a) => ({
        ...a,
        ...renderCell(a.accessor, { selectedId, showHints: false }),
        Header: ({ column }) => renderHeader(column, false),
      })),
    };
  });
};
