import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const SBtn = styled.button<{ view?: string }>`
  background-color: ${(props) => props.theme.mtsColor.controls.tertiaryActive.lightMode};
  border: 1px solid ${(props) => props.theme.mtsColor.background.stroke.lightMode};
  border-radius: 6px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${(props) =>
    props.view === "light"
      ? `
  background-color: transparent;
  border: none;
  `
      : props.view === "contrast"
      ? `
  background-color: white;
  & .MuiSvgIcon-root rect {
    fill: #000000;
  }`
      : ""}
`;

export const SWrap = styled.div`
  position: relative;
  &:first-child {
    margin-left: 0;
  }
  cursor: pointer;
  text-align: left;
`;

export const SPop = styled.div<{ direction?: string; pos: string; isOpen: boolean }>`
  opacity: 0;
  position: absolute;
  ${(props) =>
    props.pos === "right"
      ? "right: 0;"
      : css`
          left: 50%;
          transform: translateX(-50%);
        `};
  ${(props) =>
    props.direction === "down" ? "top: 36px;" : props.direction === "top" ? "bottom: 36px;" : ""};
  min-width: 100px;
  width: max-content;
  background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12), 0px 12px 20px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  z-index: -10;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  ${(props) =>
    props.isOpen &&
    css`
      opacity: 1;
      z-index: 10;
      transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    `}
`;

export const SChildPop = styled.ul`
  position: absolute;
  right: calc(100% + 8px);
  top: 0;
  min-width: 100px;
  height: auto;
  min-height: 100%;
  background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12), 0px 12px 20px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  z-index: 10;
`;

export const SPopList = styled.ul`
  padding: 6px 0%;
`;

export const SPopItem = styled.li`
  padding: 12px 24px;
  transition: all 0.3s ease;
  &:hover {
    background-color: ${(props) => props.theme.mtsColor.background.secondary.lightMode};
    transition: all 0.3s ease;
  }
`;
