import styled from "@emotion/styled";
import { device } from "theme";

export const SWrap = styled.div`
  max-width: 100%;
  padding: 48px 20px 0px;
`;

export const STop = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 16px;
  padding-bottom: 12px;

  @media ${device.desktopPlus} {
    padding-bottom: 0;
  }
`;

export const STitle = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;

  & > span {
    margin-right: 16px;
    white-space: nowrap;
  }
`;

export const SSettings = styled.div`
  display: flex;
  align-items: center;
  gap: 32px 16px;
  flex: 1 1 auto;

  & .searchField {
    flex: 1 1 auto;
  }

  @media ${device.tabletPlus} {
    flex: 0 1 42%;
    justify-content: flex-end;

    & .searchField {
      flex: 0 1 400px;
    }
  }
`;

export const SError = styled.div`
  padding: 48px 0 0;
`;
