import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import { useDeviceList } from "hooks/api/anomalies";
import { Loader } from "components/loader/loader";
import { Back } from "components/back/back.component";
import { Spacer } from "components/spacer/spacer.component";
import { MobDevice } from "components/devices/mobDevice/mobDevice.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { defaultPeriod } from "pages/anomalies/const";
import { SItemPage } from "./styles";

// NOTE: Страница имеет только мобильную верстку
export const DeviceItemPage = () => {
  let { id } = useParams();
  const { data, isSuccess, isLoading } = useDeviceList(defaultPeriod);

  const selected = data?.find((a) => a.id_pp === Number(id));

  return (
    <>
      <SItemPage>
        <Back />
        <Spacer value="28px" />
        {isSuccess && !isEmpty(selected) ? (
          <MobDevice data={selected} />
        ) : (
          <CusTypo variant="p3_regular">Нет данных</CusTypo>
        )}
        {isLoading ? <Loader isFullPage /> : null}
      </SItemPage>
    </>
  );
};
