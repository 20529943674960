import { RenderFilter } from "components/table/render-filter/renderFilter.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SBar, SBarBack, SBarWrap } from "./styles";

export const constructPortColumns = (dt) => {
  return dt.map((singleColumn) => {
    return {
      ...singleColumn,
      ...(singleColumn.accessor === "port"
        ? { Cell: ({ value }) => <CusTypo variant="p4_medium">{value}</CusTypo> }
        : {
            Cell: ({ value }) => <div>{value}</div>,
          }),
    };
  });
};

export const portsColumns = [
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Порт"
      />
    ),
    accessor: "port",
    width: "100%",
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Протокол"
      />
    ),
    accessor: "type",
    width: "100%",
  },
];

export const constructTypesColumns = (data) => {
  const { columns, rawData } = data;
  const maximum = rawData.sort((a, b) => b.meter_count - a.meter_count)[0].meter_count;

  return columns.map((singleColumn) => {
    return {
      ...singleColumn,
      ...(singleColumn.accessor === "meter_count"
        ? {
            Cell: ({ value }) => (
              <SBarWrap>
                <SBar>
                  <SBarBack width={(value * 100) / maximum!} />
                </SBar>
                <div>{value}</div>
              </SBarWrap>
            ),
          }
        : { Cell: ({ value }) => <CusTypo variant="p4_medium">{value ?? "—"}</CusTypo> }),
    };
  });
};

export const typesColumns = [
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Модель"
      />
    ),
    accessor: "meter_name",
    width: "100%",
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Количество"
      />
    ),
    accessor: "meter_count",
    width: "100%",
  },
];
