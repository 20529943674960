import { useContext } from "react";
import { isEmpty } from "lodash";

import { HeaderProfile } from "./components/header-profile/header-profile";
import { Logo } from "components/logo/logo.component";
import { Sidebar } from "components/sidebar/sidebar";
import { ROUTE_LANDINGS } from "stream-constants/route-constants";
import { ContextSettings } from "context";
import { TenantSwitch } from "./components/tenant-switch/tenant-switch";
import { SHeader, SInfo } from "./styles";
import { LIST_ROLES } from "utils/roles";

interface DarkTheme {
  isDarkTheme?: boolean;
}

export const Header = ({ isDarkTheme }: DarkTheme) => {
  const { isAuthorized, isDesktop, profile, roles } = useContext(ContextSettings);
  const isAdminOnly = roles.length === 1 && roles.includes(LIST_ROLES.ADMIN);

  return (
    <SHeader isDark={isDarkTheme}>
      <SInfo>
        <a href={ROUTE_LANDINGS}>
          <Logo isLight={isDarkTheme} />
        </a>
      </SInfo>
      {isAuthorized && !isEmpty(profile) ? (
        <ul>
          {isAdminOnly || !isDesktop() ? null : <TenantSwitch />}
          <HeaderProfile />
        </ul>
      ) : null}
      {!isDesktop() ? <Sidebar /> : null}
    </SHeader>
  );
};
