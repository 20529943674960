import { useContext, useState } from "react";
import { useManagementDemo } from "hooks/api/management";
import { Loader } from "components/loader/loader";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { Footer } from "components/footer/footer";
import { MTSButton } from "components/mts-button/mts-button";
import Icon from "components/icons";
import { MTSInput } from "components/mts-input";
import { useDebounce } from "hooks/useDebounce";
import { DemoManagementTable } from "components/demo/demoManagementTable/demoManagementTable.component";
import { TableFilter } from "components/table/table-filter/table-filter";
import { MTSModal } from "components/mts-modal/mts-modal";
import { FormAddDemoAccount } from "components/forms/demo-account/form-add-demo";
import { ContextSettings } from "context/context-settings";
import { CusTypo } from "components/cusTypo/custom-typography";
import { HARDCODED_FILTER_ITEMS, links } from "./const";
import { STop, STitle, SWrap, SSettings } from "./styles";

export const ManagementDemo = () => {
  const { isDesktop } = useContext(ContextSettings);
  const [showDemoModal, setDemoModal] = useState(false);
  const [dataLength, setDataLength] = useState(undefined);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [columnFilter, setColumnFilter] = useState(undefined);
  const [searchVal, setSearch] = useState("");

  const tableData = useManagementDemo();

  const debouncedSearch = useDebounce(searchVal, 500);

  const handleInput = (e) => {
    setSearch(e.target.value);
  };

  const toggleAddModal = () => {
    setDemoModal((s) => !s);
  };

  const handleColumnFilter = ({ filtersArr }) => {
    setColumnFilter(filtersArr);
  };

  const getDataLength = (a) => setDataLength(a.length);

  return (
    <>
      <SWrap>
        <Breadcrumbs links={links} />
        <STop>
          <STitle>
            <CusTypo variant={isDesktop() ? "h2_medium" : "h4_medium"} font="wide">
              Список демо аккаунтов
            </CusTypo>
            <MTSButton
              size="S"
              variant="primary"
              onClick={toggleAddModal}
              format="icon"
              icon={<Icon.Plus sx={{ width: 24, height: 24 }} />}
            />
          </STitle>
          <SSettings>
            <div className="searchField">
              <MTSInput
                searchIcon
                placeholder="Название организации, номер телефона"
                onChange={handleInput}
                value={searchVal}
                size="S"
              />
            </div>
            <div>
              <TableFilter
                dataLength={dataLength ?? 0}
                isOpen={isOpenFilter}
                isOn={true}
                defaultFilter={{ status: [] }}
                toogleShowFilter={setIsOpenFilter}
                onApplyFilter={handleColumnFilter}
                scheme={HARDCODED_FILTER_ITEMS}
                isSchemeOnly
              />
            </div>
          </SSettings>
        </STop>
        <DemoManagementTable
          rawData={tableData.data ?? []}
          globalFilter={debouncedSearch}
          filterByColumn={columnFilter}
          onRowsChange={getDataLength}
        />
      </SWrap>
      <MTSModal open={showDemoModal} close={toggleAddModal}>
        <FormAddDemoAccount />
      </MTSModal>
      <Footer />
      {tableData?.isLoading ? <Loader isFullPage /> : null}
    </>
  );
};
