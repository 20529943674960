import { useContext, useState } from "react";

import { useDebounce } from "hooks/useDebounce";
import { useBillingTariff } from "hooks/api/management";
import { IBreadcrumbsLink } from "interface/common";
import { ROUTE_MANAGEMENT, ROUTE_TARIFF } from "stream-constants/route-constants";
import { ContextSettings } from "context/context-settings";
import { CusTypo } from "components/cusTypo/custom-typography";
import { Loader } from "components/loader/loader";
import { Footer } from "components/footer/footer";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { MTSInput } from "components/mts-input";
import { MTSButton } from "components/mts-button/mts-button";
import { TariffBillingTable } from "components/management-billing/tariffTable/tariffTable.component";
import { MTSModal } from "components/mts-modal/mts-modal";
import { TariffForm } from "components/management-billing/tariffForm/tariffForm.component";
import { SBtns, SItem, SSection, SSettings, STitle, STop, SWrap } from "./styles";

const links: IBreadcrumbsLink[] = [
  { name: "Управление", href: `/${ROUTE_MANAGEMENT}` },
  { name: "Тарификация", href: `/${ROUTE_TARIFF}` },
];

export const ManagementTariff = () => {
  const { isDesktop } = useContext(ContextSettings);
  const [searchVal, setSearch] = useState("");
  const [isOpen, setOpen] = useState(false);

  const { data, isLoading } = useBillingTariff();

  const debouncedSearch = useDebounce(searchVal, 500);

  const handleInput = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <SSection>
        <Breadcrumbs links={links} />
        <div>
          <STop>
            <STitle>
              <CusTypo variant={isDesktop() ? "h2_medium" : "h4_medium"} font="wide">
                Список тарифов
              </CusTypo>
            </STitle>
            <SSettings>
              <MTSInput
                searchIcon
                placeholder="Лицевой счет, код услуги, номер для тарификации"
                onChange={handleInput}
                value={searchVal}
                size="S"
              />
            </SSettings>
            <SBtns>
              <SItem>
                <MTSButton size="S" variant="dark_blue" onClick={() => setOpen(true)}>
                  Добавить тариф
                </MTSButton>
              </SItem>
            </SBtns>
          </STop>
          <SWrap>
            <TariffBillingTable rawData={data ?? []} globalFilter={debouncedSearch} />
          </SWrap>
        </div>
      </SSection>
      <Footer />
      <MTSModal open={isOpen} close={() => setOpen(false)} size="S">
        <TariffForm onCancel={() => setOpen(false)} />
      </MTSModal>
      {isLoading ? <Loader isFullPage /> : null}
    </>
  );
};
