import styled from "@emotion/styled";

export const SMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SContainer = styled.section`
  width: 100%;
  padding: 0 20px;
`;

export const SArticle = styled.article`
  position: relative;
  margin: 0 auto;
  padding: 48px 0;
  min-height: calc(100vh - 128px);
  width: 100%;
  text-align: left;
`;

export const SWrap = styled.div`
  padding-top: 30px;
`;

export const SStep = styled.div`
  margin-bottom: 48px;
  & .MuiTypography-root {
    display: inline-block;
    color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
  }
`;

export const SLink = styled.a`
  display: inline-block;
  & > .MuiTypography-root {
    color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
    text-decoration: underline;
  }
`;

export const SImg = styled.img`
  display: block;
  max-width: 353px;
`;
