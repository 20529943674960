import { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { isEqual } from "lodash";
import { toast } from "react-toastify";

import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { MTSButton } from "components/mts-button/mts-button";
import { Spacer } from "components/spacer/spacer.component";
import { MTSInput } from "components/mts-input";
import { CusTypo } from "components/cusTypo/custom-typography";
import { Toast } from "components/toast/toast";
import { SControls, SOptCont } from "./styles";

export const Optimiser: FC<any> = ({
  handleOuter,
  outerState,
  isWidget = false,
  handleWidgetBtn,
}) => {
  const storage =
    (StorageAPI.get(EStorageKeys.RESEARCH_SETTINGS) &&
      JSON.parse(StorageAPI.get(EStorageKeys.RESEARCH_SETTINGS))) ||
    [];
  const storageDefault = { max: storage["chart"]?.max, mult: storage["chart"]?.mult };

  const handleReset = () => {
    handleOuter(storageDefault);
  };

  return (
    <SOptCont>
      <MTSInput
        onChange={(e) => handleOuter((s) => ({ ...s, max: parseInt(e.target.value, 10) || 0 }))}
        type="number"
        label="Максимум значений"
        size="S"
        value={outerState.max}
      />
      <Spacer value="24px" />
      <MTSInput
        onChange={(e) => handleOuter((s) => ({ ...s, mult: parseInt(e.target.value, 10) || 0 }))}
        type="number"
        label="Кратность >"
        size="S"
        value={outerState.mult}
      />
      {isWidget ? (
        <>
          <Spacer value="24px" />
          <MTSButton size="M" variant="primary" onClick={() => handleWidgetBtn()}>
            Применить
          </MTSButton>
          <Spacer value="12px" />
          <MTSButton
            size="M"
            variant="secondary"
            onClick={handleReset}
            disabled={isEqual(outerState, storageDefault)}
          >
            По умолчанию
          </MTSButton>
        </>
      ) : null}
    </SOptCont>
  );
};

type IProp = {
  id: number | string;
  onClose: () => void;
  handleSubmit: (a, b) => void;
};
export const CommentChange: FC<IProp> = ({ id, onClose, handleSubmit }) => {
  const { control, formState, register, getValues } = useFormContext();
  const [watchComment] = useWatch({ control, name: ["comment"] });

  return (
    <>
      <CusTypo variant="h3_medium" font="comp">
        ID {id}
      </CusTypo>
      <Spacer value="24px" />
      <MTSInput
        placeholder="Необязательное поле"
        type="textarea"
        label="Комментарий"
        {...register("comment", {
          required: false,
        })}
        control={control}
        errorMessage={formState.errors?.comment?.message}
      />
      <Spacer value="24px" />
      <SControls>
        <MTSButton
          size="M"
          variant="primary"
          type="submit"
          disabled={!formState.isDirty}
          onClick={() => handleSubmit(getValues("fp"), watchComment)}
        >
          Подтвердить
        </MTSButton>
        <MTSButton size="M" variant="secondary" onClick={onClose}>
          Отмена
        </MTSButton>
      </SControls>
    </>
  );
};

export const toastSucc = () => {
  toast(<Toast title="Успешно" />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });
};

export const toastErr = () => {
  toast(<Toast title="Информация не обновлена" isError />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });
};
