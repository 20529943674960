import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  iconWrap: {
    height: "100%",
    width: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "content-box",
    padding: "0 16px 0 10px",
    borderRadius: "6px",
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: ".2s ease all",
  },
  active: {
    transform: "rotate(90deg)",
    transformOrigin: "center",
    transition: ".2s ease all",
  },
}));

export default useStyles;
