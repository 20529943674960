import { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { MTSInput } from "components/mts-input";
import { useCheckVisibleColumns, useResearchChart, useResearchFindGeo } from "hooks/api/analytics";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { ContextSettings } from "context/context-settings";
import { useDebounce } from "hooks/useDebounce";
import { Footer } from "components/footer/footer";
import { Spacer } from "components/spacer/spacer.component";
import { getBackendValues } from "components/analytics-research/reserchForm/const";
import { Loader } from "components/loader/loader";
import { Chart } from "components/chart/Chart.component";
import { makeChartData } from "components/chart/utils";
import { ReserchForm } from "components/analytics-research/reserchForm/reserchForm.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SChartWrap } from "components/chart/styles";
import { GeoTable } from "components/analytics-research/geoTable/geoTable.component";
import { Select } from "components/select/select";
import { IChartMode, links, rangeArray, TABLE_RESIZER } from "./const";
import { SGeoRow, SSettings, STitle, STop, SWrap } from "./styles";

export const AnalyticsGeo = () => {
  let { id } = useParams();
  const { isDesktop } = useContext(ContextSettings);

  const [range, setRange] = useState("30");
  const [selected, setSelected] = useState<number>(() => Number(id ?? 0));
  const [searchVal, setSearch] = useState("");

  const storageSettings =
    (StorageAPI.get(EStorageKeys.RESEARCH_SETTINGS) &&
      JSON.parse(StorageAPI.get(EStorageKeys.RESEARCH_SETTINGS))) ||
    [];

  const [combinedState, setCombined] = useState<IChartMode & { search: string }>({
    max: storageSettings["chart"]?.max ?? 10,
    mult: storageSettings["chart"]?.mult ?? 3,
    mode: "optimize",
    search: storageSettings["search"] ?? "geo",
  });

  const { data: visibility } = useCheckVisibleColumns();

  const geoData = { id_pp: Number(id), search_radius: Number(range) };

  const { data: researchDevices, isError, error, isLoading } = useResearchFindGeo(geoData);

  const debouncedSearch = useDebounce(searchVal, 500);
  const chart = useResearchChart({
    cnt_max_row: combinedState.max,
    id_pp: selected,
    mode: combinedState.mode,
    multiples: combinedState.mult,
  });

  const chartData = makeChartData(chart.data ?? []);

  const handleInput = (e) => {
    setSearch(e.target.value);
  };

  const chartDefaults = useMemo(
    () => getBackendValues(researchDevices, selected),
    [researchDevices, selected],
  );

  const isNothingToShow = isError && error?.description !== null;

  useEffect(() => {
    document.title = `${id}`;
  }, [id]);

  return (
    <>
      <SWrap isShowResizer={!isNothingToShow}>
        <Breadcrumbs links={links} />
        <STop>
          <STitle>
            <CusTypo variant={isDesktop() ? "h2_medium" : "h4_medium"} font="wide">
              Список устройств
            </CusTypo>
            <SGeoRow>
              <CusTypo variant="p3_medium">ID {id}</CusTypo>
            </SGeoRow>
            <SGeoRow>
              <Select
                initValue={range.toString()}
                size="S"
                optionsList={rangeArray}
                onChange={(v) => setRange(v)}
              />
            </SGeoRow>
          </STitle>
          {isNothingToShow ? null : (
            <>
              <SSettings>
                <div className="searchField" style={{ margin: 0 }}>
                  <MTSInput
                    searchIcon
                    placeholder="ID, РЭС, Адрес"
                    onChange={handleInput}
                    value={searchVal}
                    size="S"
                  />
                </div>
              </SSettings>
            </>
          )}
        </STop>
        <GeoTable
          rawData={researchDevices ?? []}
          globalFilter={debouncedSearch}
          onRowClick={setSelected}
          rowSelected={selected}
          columnVisibility={visibility ?? {}}
        />
        {isNothingToShow ? null : (
          <>
            <Spacer value={TABLE_RESIZER} />
            <SChartWrap>
              <Spacer value="24px" />
              <Chart id={selected} chartData={chartData as any} scaleX="years">
                <ReserchForm
                  id={selected}
                  defaultValues={chartDefaults}
                  outerState={combinedState}
                  handleOuter={setCombined}
                  isFilterOn
                  geoData={geoData}
                />
              </Chart>
            </SChartWrap>
          </>
        )}
      </SWrap>
      <Footer />
      {isLoading ? <Loader isFullPage /> : null}
    </>
  );
};
