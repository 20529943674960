import { FC } from "react";
import { useForm, useWatch } from "react-hook-form";
import { isEmpty } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";

import { MTSButton } from "components/mts-button/mts-button";
import { useAddDevice, useCheckDevice } from "hooks/api/anomalies";
import { useDebounce } from "hooks/useDebounce";
import { Loader } from "components/loader/loader";
import { schemaAddDevice } from "./validation-schema";
import { CusTypo } from "components/cusTypo/custom-typography";
import { MTSAutocomplete } from "components/mts-autocomplete/mts-autocomplete";
import { EMPTY_CELL_VALUE } from "stream-constants/keys-and-constants";
import { Spacer } from "components/spacer/spacer.component";
import { checkEnabled } from "./const";
import { SBtns, STitle, SWrap } from "./styles";
import { SModalTitle } from "components/modal-notify-body/styles";

type CommonProps = {
  inModal?: boolean;
  onMutation: (a: string) => void;
  sucHandler: (a: boolean) => void;
  close: () => void;
};

export const FormAddDevice: FC<CommonProps> = ({ inModal, onMutation, sucHandler, close }) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schemaAddDevice),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [watchSn] = useWatch({ control, name: ["sn"] });
  const debouncedSn = useDebounce(watchSn, 500);

  const { data, isLoading } = useCheckDevice(debouncedSn, checkEnabled(debouncedSn));
  const mutation = useAddDevice();
  const selectDevice = data?.find((a) => a.sn === debouncedSn);

  const onSubmit = () => {
    mutation.mutate(
      {
        id_pp: selectDevice?.id_pp ?? 0,
      },
      {
        onSuccess: () => {
          onMutation(String(selectDevice?.id_pp));
          sucHandler(true);
          close();
          reset();
        },
        onError: () => {
          onMutation("");
        },
      },
    );
  };

  return (
    <SWrap>
      {isLoading ? <Loader isFullPage /> : null}
      {inModal ? (
        <SModalTitle>Добавить устройство</SModalTitle>
      ) : (
        <STitle>
          <CusTypo variant="h3_bold">Добавить устройство</CusTypo>
        </STitle>
      )}
      <Spacer value="24px" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <section className="single">
          <MTSAutocomplete
            label="Серийный номер"
            placeholder=""
            options={(data || []).map((a) => a.sn)}
            control={control}
            size="M"
            {...register("sn", {
              required: true,
            })}
            errorMessage={errors?.sn?.message}
          />
        </section>
        {!isEmpty(selectDevice) ? (
          <section className="single">
            <AddressItem title="Населенный пункт" val={selectDevice?.city} />
            <AddressItem title="Улица" val={selectDevice?.street} />
            <AddressItem title="Дом" val={selectDevice?.house} />
            <AddressItem title="Квартира" val={selectDevice?.flat} />
          </section>
        ) : null}
        <section className="single">
          <SBtns>
            <MTSButton
              size="M"
              variant="primary"
              type="submit"
              onClick={() => {}}
              disabled={isEmpty(data) && !checkEnabled(debouncedSn)}
            >
              Далее
            </MTSButton>
            <MTSButton size="M" variant="secondary" onClick={close}>
              Отмена
            </MTSButton>
          </SBtns>
        </section>
      </form>
    </SWrap>
  );
};

type IAddrProps = {
  title: string;
  val: string;
};

const AddressItem: FC<IAddrProps> = ({ title, val }) => {
  const rendAddressField = (a: string) => (!isEmpty(a) ? a : EMPTY_CELL_VALUE);

  return (
    <CusTypo variant="p4_regular">
      <b>{title}: </b>
      {rendAddressField(val)}
    </CusTypo>
  );
};
