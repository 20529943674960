import { ESn } from "interface/common";
import { DEPARTURE_RESULT } from "stream-constants/keys-and-constants";

// возможные ключи в state компонента
export const PO_COL = "po";
export const RES_COL = "res";
export const FLUL_COL = "fl_ul";
export const FRAUD_COL = "fraud_probability";
export const TICKETS_COL = "tickets";
export const BADTEL_COL = "bad_telemetry";
export const RECIDIV_COL = "recidiv";
export const REDCONS_COL = "reduce_consumption";
export const REVER_COL = "reverify";
export const CHANGE_DATE_COL = "date_change_device";
export const PU_COL = "change_device";
export const MINER_COL = "miner";
export const KOEF_COL = "koef_korr";
export const CONS_COL = "type_consumption";
export const WINT_COL = "d_winter";
export const SUM_D_COL = "d_summer";
export const SUM_N_COL = "n_summer";
export const DN_KOEF_COL = "koef_dn";
export const AMP_COL = "amplitude";
export const MULT_COL = "multiplicity";
export const SCORE_COL = "score";
export const CNT_4_COL = "cnt_04";
export const CNT_8_COL = "cnt_008";
export const TYPE_1 = "type_1";
export const TYPE_2 = "type_2";
export const TYPE_3 = "type_3";
export const TYPE_4 = "type_4";
export const TYPE_5 = "type_5";
export const UNI = "unitype";
export const DATE_GET_COL = "d_get";
export const DATE_SEND_COL = "d_send";
export const RESULT_COL = "result_verify";

export const headByKey = {
  [PO_COL]: "Производственное отеделение",
  [RES_COL]: "РЭС",
  [FRAUD_COL]: "Вероятность фрода",
  tickets: "Тикеты",
  [PU_COL]: "Замена ПУ (Т1)",
  [MINER_COL]: "Майнеры (Т2)",
  [CONS_COL]: "Тип потребления",
  consumption: "Потребление",
  types: "Типы",
  [DATE_GET_COL]: "Дата выезда",
  [DATE_SEND_COL]: "Дата отчета",
  [RESULT_COL]: "Статус проверки",
  [FLUL_COL]: "Категория",
};

export const periodTitleByKey = {
  [CHANGE_DATE_COL]: "Дата замены ПУ",
};

export const rangeTitleByKey = {
  [KOEF_COL]: "Коэффициент корреляции",
  [WINT_COL]: "Дневное зима",
  [SUM_D_COL]: "Дневное лето",
  [SUM_N_COL]: "Ночное лето",
  [DN_KOEF_COL]: "Коэффициент день/ночь",
  [AMP_COL]: "Размах",
  [MULT_COL]: "Кратность",
  [SCORE_COL]: "Скор",
  [TYPE_1]: "Type1",
  [TYPE_2]: "Type2",
  [TYPE_3]: "Type3",
  [TYPE_4]: "Type4",
  [TYPE_5]: "Type5",
  [UNI]: "Unitype",
  [CNT_4_COL]: "Количество >0.4",
  [CNT_8_COL]: "Количество >0.08",
};

export const selectTitleByKey = {
  [PU_COL]: "Учитывать замену",
  [MINER_COL]: "Учитывать майнеров",
  [BADTEL_COL]: "Плохая телеметрия (Т0)",
  [RECIDIV_COL]: "Рецидив (Т4)",
  [REDCONS_COL]: "Снижение потребления (Т3)",
  [REVER_COL]: "Повторная проверка (Т5)",
};

export const categories = {
  abonent: "Абонент",
  subdivision: "Подразделения",
  status: "Статус фрода",
  tickets: "Тикеты",
  metricks: "Метрики",
  bigdata: "BigData",
  feedback: "Обратная связь",
};

export type IMainCats = keyof typeof categories;

export const HARDCODED_FILTER_FRAUD = [
  {
    id: 0,
    name: ESn.Низкая,
  },
  {
    id: 1,
    name: ESn.Средняя,
  },
  {
    id: 2,
    name: ESn.Высокая,
  },
  {
    id: 3,
    name: ESn.Фрод,
  },
];

export const HARDCODED_FILTER_TICKETS = [
  {
    id: 0,
    name: "Плохая телеметрия (Т0)",
  },
  {
    id: 1,
    name: "Рецидив (Т4)",
  },
  {
    id: 2,
    name: "Снижение потребления (Т3)",
  },
  {
    id: 3,
    name: "Повторная проверка (Т5)",
  },
];

export const HARDCODED_FILTER_MINER = [
  {
    id: 0,
    name: true,
    friendlyName: "Учитывать майнеров",
  },
];

export const HARDCODED_CONSUMPTION = [
  {
    id: 0,
    name: "Постоянное",
  },
  {
    id: 1,
    name: "Периодическое",
  },
];

export const HARDCODED_RESULT = [
  {
    id: 0,
    name: DEPARTURE_RESULT.TO_CHECK,
  },
  {
    id: 1,
    name: DEPARTURE_RESULT.NORMAL,
  },
  {
    id: 2,
    name: DEPARTURE_RESULT.BREAKING,
  },
  {
    id: 3,
    name: DEPARTURE_RESULT.SUSPICION,
  },
  {
    id: 4,
    name: DEPARTURE_RESULT.DEFECTIVE,
  },
  {
    id: 5,
    name: DEPARTURE_RESULT.REPLACED,
  },
  {
    id: 6,
    name: DEPARTURE_RESULT.NO_LOAD,
  },
  {
    id: 7,
    name: DEPARTURE_RESULT.NO_ACCESS,
  },
  {
    id: 8,
    name: "Пустое значение",
  },
];

const HARDCODED_FLUL = [
  {
    id: 0,
    friendlyName: "Физическое лицо",
    name: "Физлица",
  },
  {
    id: 1,
    friendlyName: "Юридическое лицо",
    name: "Юрлица",
  },
];

export const scheme = {
  abonent: {
    [FLUL_COL]: {
      checkbox: {
        name: FLUL_COL,
        list: HARDCODED_FLUL,
      },
    },
  },
  status: {
    [FRAUD_COL]: {
      checkbox: {
        name: FRAUD_COL,
        list: HARDCODED_FILTER_FRAUD,
      },
    },
  },
  tickets: {
    [TICKETS_COL]: {
      select: [BADTEL_COL, RECIDIV_COL, REDCONS_COL, REVER_COL],
    },
    [PU_COL]: {
      select: [PU_COL],
      period: CHANGE_DATE_COL,
    },
    [MINER_COL]: {
      select: [MINER_COL],
      range: [KOEF_COL],
    },
  },
  metricks: {
    [CONS_COL]: {
      checkbox: { name: CONS_COL, list: HARDCODED_CONSUMPTION },
    },
    consumption: {
      range: [
        WINT_COL,
        SUM_D_COL,
        SUM_N_COL,
        DN_KOEF_COL,
        AMP_COL,
        MULT_COL,
        SCORE_COL,
        CNT_4_COL,
        CNT_8_COL,
      ],
    },
  },
  bigdata: {
    types: {
      range: [TYPE_1, TYPE_2, TYPE_3, TYPE_4, TYPE_5, UNI],
    },
  },
  feedback: {
    [DATE_GET_COL]: {
      period: DATE_GET_COL,
    },
    [DATE_SEND_COL]: {
      period: DATE_SEND_COL,
    },
    [RESULT_COL]: {
      checkbox: { name: RESULT_COL, list: HARDCODED_RESULT },
    },
  },
};
