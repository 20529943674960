import { Fragment, useMemo } from "react";

import { Table } from "components/table/table.component";
import { Spacer } from "components/spacer/spacer.component";
import { IResearchHandbookRes } from "interface/analytics";
import { constructColumns, constructTableData } from "./utils";
import { CusTypo } from "components/cusTypo/custom-typography";
import { abbrToTitle, tableStructure } from "./const";

interface IProp {
  rawData: IResearchHandbookRes[];
  globalFilter?: string | any;
}
export const HandbookTable: React.FC<IProp> = ({ rawData, globalFilter }) => {
  const tableData = useMemo(() => constructTableData(rawData), [rawData]);

  const columns = useMemo(
    () =>
      constructColumns({
        columns: tableStructure,
      }),
    [],
  );

  return (
    <>
      {Object.entries(tableData).map(([key, val]) => (
        <Fragment key={key}>
          <CusTypo variant="h4_medium" font="comp" styles={{ textAlign: "left", display: "block" }}>
            {abbrToTitle(key)}
          </CusTypo>
          <Table
            columns={columns}
            data={val as any}
            view="alternatingRows"
            globalFilter={globalFilter}
            height={52 * ((val as any).length + 1)}
          />
          <Spacer value="32px" />
        </Fragment>
      ))}
    </>
  );
};
