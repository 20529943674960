import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Error = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M32 0C14.3648 0 0 14.3648 0 32C0 49.6352 14.3648 64 32 64C49.6352 64 64 49.6352 64 32C64 14.3648 49.6352 0 32 0ZM32 6.4C46.1764 6.4 57.6 17.8236 57.6 32C57.6 46.1764 46.1764 57.6 32 57.6C17.8236 57.6 6.4 46.1764 6.4 32C6.4 17.8236 17.8236 6.4 32 6.4Z" fill="#EB4A13"/>
      <path d="M42.2635 45.3584C43.1183 46.2132 44.5042 46.2132 45.3589 45.3584C46.2137 44.5037 46.2137 43.1178 45.3589 42.2631L35.0956 32L45.3589 21.7369C46.2136 20.8822 46.2136 19.4963 45.3589 18.6416C44.5041 17.7868 43.1183 17.7868 42.2635 18.6416L32.0003 28.9046L21.7365 18.6411C20.8817 17.7863 19.4959 17.7863 18.6411 18.6411C17.7864 19.4958 17.7864 20.8816 18.6411 21.7364L28.9049 32L18.6411 42.2636C17.7863 43.1184 17.7863 44.5042 18.6411 45.3589C19.4958 46.2137 20.8817 46.2137 21.7365 45.3589L32.0003 35.0953L42.2635 45.3584Z" fill="#EB4A13" stroke="#EB4A13" strokeLinecap="round"/>
    </SvgIcon>
  );
};

export default Error;