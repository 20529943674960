import { IEmployees, IMapMarker } from "interface/map";

export const base: IMapMarker[] = [
  {
    adress_too: "г. Уфа, ул. Ташкентская, д. 3Б 450051",
    id_pp: 794077,
    lat: 54.8334804,
    lng: 56.145268,
    mpoisk_id: 111099,
    sn: "11347140826354",
  },
  {
    adress_too: "г. Уфа, д. Князево, ул. Октябрьская, д. 29 450069",
    id_pp: 846532,
    lat: 54.7943302,
    lng: 56.2382107,
    mpoisk_id: 111099,
    sn: "11889124518129",
  },
  {
    adress_too: "г. Уфа, тер Коллективный сад N40 ОСТ ОАО УМПО, д. 194 450069",
    id_pp: 858998,
    lat: 54.734944,
    lng: 55.9578468,
    mpoisk_id: 111099,
    sn: "11889152148895",
  },
  {
    adress_too: "г. Уфа, ул. Северная, д. 4 450097",
    id_pp: 921348,
    lat: 54.7372989,
    lng: 56.0020647,
    mpoisk_id: 111099,
    sn: "11889162239308",
  },
  {
    adress_too: "г. Уфа, ул. 4-я Районная, д. 14 450097",
    id_pp: 928378,
    lat: 54.732906,
    lng: 56.0000263,
    mpoisk_id: 111099,
    sn: "11889159487086",
  },
];

export const dataEmployees: IEmployees[] = [
  {
    allowedOvertime: 0,
    canRequest: true,
    canSend: true,
    canTrack: true,
    icon: 0,
    isBlockEnabled: false,
    isLocateEnabled: true,
    isOnline: false,
    name: "Сотрудник 1",
    subscriberGroupID: 210403,
    subscriberID: 2401930,
    subscriberTariffTypeID: 13,
    workScheduleID: 22353,
  },
  {
    allowedOvertime: 0,
    canRequest: true,
    canSend: true,
    canTrack: true,
    icon: 0,
    isBlockEnabled: false,
    isLocateEnabled: true,
    isOnline: false,
    name: "Сотрудник 2",
    subscriberGroupID: 210403,
    subscriberID: 3167786,
    subscriberTariffTypeID: 10,
    workScheduleID: 22353,
  },
];

export const mock_miners = [
  {
    score: 26,
    dt: "2023-30-07",
    mean_x: 44.5265,
    mean_y: 48.761727,
    zid: 79188664621,
    district_name: "городской округ волгоград",
    region_name: "волгоградская область",
    points: [
      [54.83495482076795, 56.43246651563945],
      [54.83727114709387, 56.43246642512029],
      [54.83495482076795, 56.43246651563945],
      [54.83727114709387, 56.43246642512029],
      [54.8372711409434, 56.43653357425043],
      [54.83495482691864, 56.43653348373154],
      [54.83495482076795, 56.43246651563945],
    ],
  },
  {
    score: 15,
    dt: "2023-04-05",
    mean_x: 44.4955,
    mean_y: 48.7306835,
    zid: 79088000181,
    district_name: "городской округ волгоград",
    region_name: "волгоградская область",
    points: [
      [48.72954718272272, 44.49364287732006],
      [48.73182078928709, 44.49364281588474],
      [48.73182078685659, 44.49735718384079],
      [48.72954718515327, 44.49735712240554],
      [48.72954718272272, 44.49364287732006],
    ],
  },
  {
    score: 8,
    dt: "2023-04-05",
    mean_x: 44.4875,
    mean_y: 48.6996595,
    zid: 79054445663,
    district_name: "городской округ волгоград",
    region_name: "волгоградская область",
    points: [
      [48.69852317487064, 44.48564370694206],
      [48.70079679716189, 44.48564364561152],
      [48.70079679473296, 44.4893563541138],
      [48.69852317729956, 44.48935629278331],
      [48.69852317487064, 44.48564370694206],
    ],
  },
  {
    score: 7,
    dt: "2023-04-05",
    mean_x: 44.7995,
    mean_y: 48.754561,
    zid: 79113168445,
    district_name: "городской округ волгоград",
    region_name: "волгоградская область",
    points: [
      [48.75342538885919, 44.79764254052183],
      [48.7556975831415, 44.79764247902973],
      [48.75569758057982, 44.80135752067968],
      [48.75342539142105, 44.80135745918766],
      [48.75342538885919, 44.79764254052183],
    ],
  },
];
