import format from "date-fns/format";

import { Spacer } from "components/spacer/spacer.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { MTSStatus } from "components/mts-status/mts-status";
import { SCont, SItems } from "./substyles";

export const MobEvent = ({ row }) => {
  const { sn, meter_name, datetime, level, event } = row.original;

  return (
    <SCont>
      <section>
        <CusTypo variant="p3_bold">{sn}</CusTypo>
        {MTSStatus(level, "medium")}
      </section>
      <Spacer value="8px" />
      <CusTypo variant="c1_regular" font="comp">
        {meter_name}
      </CusTypo>
      <Spacer value="12px" />
      <SItems>
        <div>
          <CusTypo variant="c1_regular" font="comp">
            Дата и время
          </CusTypo>
          <CusTypo variant="c1_regular">{format(new Date(datetime ?? 0), "dd.MM.yyyy")}</CusTypo>
        </div>
        <div>
          <CusTypo variant="c1_regular" font="comp">
            Событие
          </CusTypo>
          <CusTypo variant="c1_regular">{event}</CusTypo>
        </div>
      </SItems>
    </SCont>
  );
};
