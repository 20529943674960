import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Ellipsis = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.73788 3.65669C10.6543 3.14045 11.7311 2.91745 12.7769 3.02742C13.8423 3.13205 14.8663 3.58834 15.6651 4.29932C16.5411 5.07216 17.1471 6.14703 17.341 7.2998C17.5045 8.24943 17.3953 9.24488 17.0237 10.1338C16.664 11.0059 16.0561 11.77 15.2993 12.3305C15.8198 12.5287 16.3337 12.7532 16.8061 13.0503C17.0127 13.1782 17.1654 13.4004 17.181 13.6463C17.2196 14.1022 16.8347 14.5685 16.3654 14.5589C16.0882 14.5627 15.8687 14.3772 15.6369 14.2542C14.4948 13.6188 13.1645 13.3328 11.8624 13.4367C10.4347 13.5406 9.04676 14.1167 7.96616 15.0553C6.89701 15.9748 6.12876 17.2379 5.8103 18.6117C5.68506 19.1352 5.63122 19.6736 5.62893 20.2113C5.62931 20.4648 5.5098 20.7225 5.29253 20.8608C4.92329 21.1093 4.34748 21.0211 4.1138 20.6278C4.00765 20.4583 3.99314 20.2525 4.0023 20.0578C4.01834 18.5526 4.4544 17.0554 5.24938 15.777C6.18641 14.2561 7.62098 13.0518 9.27892 12.3874C8.33501 11.7211 7.61716 10.7367 7.29107 9.62634C6.95811 8.50832 7.02111 7.27689 7.47282 6.20087C7.91423 5.13096 8.72754 4.22066 9.73788 3.65669ZM12.0197 4.63801C10.765 4.70407 9.58515 5.50058 9.05401 6.63922C8.66187 7.4533 8.60268 8.41935 8.88371 9.27734C9.10174 9.94975 9.52902 10.5515 10.0915 10.9792C10.6218 11.3858 11.2694 11.6371 11.9354 11.6925C12.7235 11.7635 13.5345 11.5661 14.1958 11.13C14.8224 10.7211 15.3112 10.1059 15.5682 9.40296C15.8675 8.59194 15.858 7.67171 15.5411 6.86756C15.2669 6.16536 14.7606 5.55671 14.1195 5.16037C13.4975 4.77127 12.7513 4.5899 12.0197 4.63801Z" fill="#1D2023"/>
      <path d="M17.6783 14.502C17.9295 14.366 18.2529 14.3775 18.4939 14.5317C18.7234 14.6738 18.8727 14.9361 18.8749 15.2064C18.8776 15.7574 18.8749 16.3084 18.8761 16.8598C19.4137 16.8602 19.9517 16.859 20.4894 16.8602C20.7089 16.8564 20.9365 16.9266 21.0915 17.0874C21.3703 17.3707 21.3958 17.8552 21.1656 18.1752C21.0228 18.3799 20.7723 18.4899 20.5264 18.4899C19.9762 18.4937 19.4263 18.4899 18.8761 18.4918C18.8753 19.0271 18.8769 19.5624 18.8753 20.0982C18.8769 20.3158 18.8016 20.5399 18.6413 20.6919C18.3465 20.9768 17.8394 20.9909 17.5244 20.7312C17.3361 20.5797 17.2434 20.3349 17.2472 20.097C17.246 19.5621 17.2472 19.0267 17.2468 18.4918C16.6843 18.4906 16.1219 18.4922 15.5598 18.491C15.3502 18.4922 15.1364 18.4154 14.9909 18.2611C14.7037 17.9625 14.6919 17.4444 14.9665 17.1336C15.1303 16.9415 15.3888 16.856 15.6362 16.8598C16.1727 16.8594 16.7095 16.8598 17.2464 16.8598C17.2479 16.3077 17.2449 15.7555 17.2479 15.2034C17.2495 14.9143 17.4228 14.636 17.6783 14.502Z" fill="#1D2023"/>
    </SvgIcon>
  );
};

export default Ellipsis;
