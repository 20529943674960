import { AnomaliesArchive, IDeviceList } from "interface/anomalies";

export const base: IDeviceList[] = [
  {
    adress_norm: "Респ. Башкортостан, г. Мелеуз, ул. Партизанская, д. 9 453851",
    adress_too:
      "453851, Башкортостан Респ., Мелеузовский р-н, Мелеуз г., Партизанская ул., дом №  9",
    city: "Мелеуз",
    city_district: "None",
    factory_count_num: "10266056001198",
    fl_ul: "Физ. лицо",
    geo_lat: 52.9447832,
    geo_lon: 55.9141253,
    house: "9",
    id_point: 86661,
    id_pp: 43204,
    id_pp_dt: null,
    id_tp: 66974,
    postal_code: 453851,
    priem: "Мелеузовский ГРЭС",
    region: "Башкортостан",
    skor_point: "5.0400",
    source_name: "ТП-5083 PLC-E Nero",
    street: "Партизанская",
    state_code: "В архиве",
    telebot_request: [
      {
        telebot_request_comment: "",
        telebot_request_dt: "Tue, 06 Dec 2022 00:00:00 GMT",
        telebot_request_result: "Взлом",
        val_meter_A: "0.1",
        val_clamp_A: "0.1",
        processed_dt: null,
      },
      {
        telebot_request_comment: "sdf",
        telebot_request_dt: "Tue, 17 Jan 2023 00:00:00 GMT",
        telebot_request_result: "Нормальный",
        val_meter_A: "0.2",
        val_clamp_A: "0.2",
        processed_dt: null,
      },
      {
        telebot_request_comment: "л",
        telebot_request_dt: "Sun, 15 Jan 2023 00:00:00 GMT",
        telebot_request_result: "Подозрение",
        val_meter_A: "0.3",
        val_clamp_A: "0.3",
        processed_dt: null,
      },
      {
        telebot_request_comment: "л",
        telebot_request_dt: "Sun, 15 Jan 2023 00:00:00 GMT",
        telebot_request_result: "Подозрение",
        val_meter_A: "0.4",
        val_clamp_A: "0.4",
        processed_dt: null,
      },
      {
        telebot_request_comment: "л",
        telebot_request_dt: "Sun, 15 Jan 2023 00:00:00 GMT",
        telebot_request_result: "Подозрение",
        val_meter_A: "0.5",
        val_clamp_A: "0.5",
        processed_dt: null,
      },
      {
        telebot_request_comment: "л",
        telebot_request_dt: "Sun, 15 Jan 2023 00:00:00 GMT",
        telebot_request_result: "Подозрение",
        val_meter_A: "0.5",
        val_clamp_A: "0.5",
        processed_dt: null,
      },
      {
        telebot_request_comment: "л",
        telebot_request_dt: "Sun, 15 Jan 2023 00:00:00 GMT",
        telebot_request_result: "Подозрение",
        val_meter_A: "0.5",
        val_clamp_A: "0.5",
        processed_dt: null,
      },
    ],
    telebot_request_last_dt: "Tue, 17 Jan 2023 00:00:00 GMT",
    telebot_request_last_result: "Нормальный",
    type_pu: null,
    phase_num: "1",
    manual_flg: true,
  },
  {
    adress_norm:
      "Респ. Башкортостан, Миякинский р-н, с. Нарыстау, ул. Мира, д. 75 к а, кв. 1 452089",
    adress_too:
      "452089, Башкортостан Респ., Миякинский р-н, Нарыстау с., Мира ул., дом №  75, корпус  А, квартира  1",
    city: "Нарыстау",
    city_district: "None",
    factory_count_num: "14727240",
    fl_ul: "Физ. лицо",
    geo_lat: 53.8483471,
    geo_lon: 55.0384866,
    house: "75",
    id_point: 111390,
    id_pp: 66327,
    id_pp_dt: null,
    id_tp: 111347,
    postal_code: 452089,
    priem: "Миякинский РЭС",
    region: "Башкортостан",
    skor_point: "81.3850",
    source_name: "ТП-6838 н.п.Нарстау",
    street: "Мира",
    state_code: "Актуально",
    telebot_request: [
      {
        telebot_request_comment:
          "Хотя в ходе проверки нарушений не было выявлено, абонент вел себя подозрительно",
        telebot_request_dt: "Wed, 07 Dec 2022 00:00:00 GMT",
        telebot_request_result: "Нормальный",
        val_meter_A: "0.1",
        val_clamp_A: "0.1",
        val_meter_B: "0.1",
        val_clamp_B: "0.1",
        val_meter_C: "0.1",
        val_clamp_C: "0.1",
        processed_dt: null,
      },
      {
        telebot_request_comment: "тест",
        telebot_request_dt: "Wed, 07 Dec 2022 00:00:00 GMT",
        telebot_request_result: "Нормальный",
        val_meter_A: "0.1",
        val_clamp_A: "0.1",
        val_meter_B: "0.1",
        val_clamp_B: "0.1",
        val_meter_C: "0.1",
        val_clamp_C: "0.1",
        processed_dt: null,
      },
    ],
    telebot_request_last_dt: "Mon, 28 Nov 2022 00:00:00 GMT",
    telebot_request_last_result: "К проверке",
    type_pu: "Меркурий 206",
    phase_num: "3",
    gaz_flg: true,
    manual_flg: false,
  },
  {
    adress_norm:
      "Респ. Башкортостан, Миякинский р-н, д. Новый Мир, ул. Усова, д. 4 к 1, кв. 1 452096",
    adress_too:
      "452096, Башкортостан Респ., Миякинский р-н, Новый Мирд., Усова ул., дом №  4, корпус  1, квартира  1",
    city: "Новый Мир",
    city_district: "None",
    factory_count_num: "14726570",
    fl_ul: "Физ. лицо",
    geo_lat: 53.6920144,
    geo_lon: 55.0571698,
    house: "4",
    id_point: 111929,
    id_pp: 66847,
    id_pp_dt: null,
    id_tp: 109373,
    postal_code: 452096,
    priem: "Миякинский РЭС",
    region: "Башкортостан",
    skor_point: "59.2380",
    source_name: "ТП-6837 н.п.Нарстау",
    state_code: "Актуально",
    street: "Усова",
    telebot_request: [
      {
        telebot_request_comment: "",
        telebot_request_dt: "Thu, 12 Jan 2023 00:00:00 GMT",
        telebot_request_result: "Нет нагрузки",
        val_meter_A: "0.1",
        val_clamp_A: "0.1",
        processed_dt: null,
      },
    ],
    telebot_request_last_dt: "Thu, 12 Jan 2023 00:00:00 GMT",
    telebot_request_last_result: "Нет нагрузки",
    type_pu: null,
    phase_num: "1",
    manual_flg: true,
  },

  {
    adress_norm: "-",
    adress_too: "450511, Башкортостан Респ., Уфимский р-н, Михайловка с., Аграрная ул., дом № 42",
    city: null,
    city_district: null,
    factory_count_num: "124641367",
    fl_ul: "Физ. лицо",
    geo_lat: null,
    geo_lon: null,
    house: null,
    id_point: 809920,
    id_pp: 898750,
    id_pp_dt: null,
    id_tp: 740238,
    postal_code: null,
    priem: "Уфимский РЭС",
    region: null,
    skor_point: "84.1800",
    source_name: "ТП-4947 с.Михайловка",
    street: null,
    state_code: "Актуально",
    telebot_request: [],
    telebot_request_last_dt: "Mon, 28 Nov 2022 00:00:00 GMT",
    telebot_request_last_result: "К проверке",
    type_pu: "СЕ303 S31 745-JPVZ",
    manual_flg: true,
  },
  {
    adress_norm: "-",
    adress_too: "450014, Башкортостан Респ., Уфимский р-н, Мармылевод., дом № 02:47:050601:586",
    city: null,
    city_district: null,
    factory_count_num: "42944714",
    fl_ul: "Физ. лицо",
    geo_lat: null,
    geo_lon: null,
    house: null,
    id_point: 817676,
    id_pp: 909585,
    id_pp_dt: null,
    id_tp: 777622,
    postal_code: null,
    priem: "Западный РЭС",
    region: null,
    skor_point: "5.1100",
    source_name: "ТП-4239 ЗРЭС ПС Дема Ф-14",
    street: null,
    state_code: "Актуально",
    telebot_request: [],
    telebot_request_last_dt: "Mon, 28 Nov 2022 00:00:00 GMT",
    telebot_request_last_result: "К проверке",
    type_pu: "Меркурий 203.2T RBO",
    manual_flg: true,
  },
  {
    adress_norm: "-",
    adress_too: "452190, Башкортостан Респ., Ермекеевский р-н, Ермекеево с., Гоголя ул., дом № 26",
    city: null,
    city_district: null,
    factory_count_num: "11889159487428",
    fl_ul: "Физ. лицо",
    geo_lat: null,
    geo_lon: null,
    house: null,
    id_point: 822432,
    id_pp: 917304,
    id_pp_dt: null,
    id_tp: 824611,
    postal_code: null,
    priem: "Ермекеевский РЭС",
    region: null,
    skor_point: "78.9450",
    source_name: "ТП-6 с. Ермекеево",
    state_code: "Актуально",
    street: null,
    telebot_request: [],
    telebot_request_last_dt: "Mon, 28 Nov 2022 00:00:00 GMT",
    telebot_request_last_result: "К проверке",
    type_pu: "СЕ303 S31 746-JGVZ",
    manual_flg: true,
  },
  {
    adress_norm: "г. Уфа, ул. Северная, д. 4 450097",
    adress_too: "450097, Башкортостан Респ., Уфа г., Северная ул., дом № 4",
    city: "Уфа",
    city_district: "Советский",
    factory_count_num: "11889162239308",
    fl_ul: "Физ. лицо",
    geo_lat: 54.7372989,
    geo_lon: 56.0020647,
    house: "4",
    id_point: 825009,
    id_pp: 921348,
    id_pp_dt: null,
    id_tp: 545841,
    postal_code: 450097,
    priem: "Восточный РЭС",
    region: "Башкортостан",
    skor_point: "51.1050",
    source_name: "ТП-2372 ВРЭС ПС Промышленная Ф-45",
    street: "Северная",
    state_code: "Актуально",
    telebot_request: [],
    telebot_request_last_dt: "Mon, 28 Nov 2022 00:00:00 GMT",
    telebot_request_last_result: "К проверке",
    type_pu: "СЕ303 S31 746-JGVZ",
    manual_flg: true,
  },
  {
    adress_norm: "-",
    adress_too: "452170, Башкортостан Респ., Чишминский р-н, Чишмы рп, Шаймуратова ул., дом № 10",
    city: null,
    city_district: null,
    factory_count_num: "9227128745863",
    fl_ul: "Физ. лицо",
    geo_lat: null,
    geo_lon: null,
    house: null,
    id_point: 825254,
    id_pp: 921701,
    id_pp_dt: null,
    id_tp: 600863,
    postal_code: null,
    priem: "Чишминский РЭС",
    region: null,
    skor_point: "79.2610",
    state_code: "Актуально",
    source_name: "ТП-2081 н.п. Чишмы",
    street: null,
    telebot_request: [],
    telebot_request_last_dt: "Mon, 28 Nov 2022 00:00:00 GMT",
    telebot_request_last_result: "К проверке",
    type_pu: "СЕ303 S31 745-JPVZ",
    manual_flg: true,
  },
  {
    adress_norm: "-",
    adress_too:
      "452057, Башкортостан Респ., Бижбулякский р-н, Аитово с., Чишминская ул., дом №  38",
    city: null,
    city_district: null,
    factory_count_num: "11889154276022",
    fl_ul: "Физ. лицо",
    geo_lat: null,
    geo_lon: null,
    house: null,
    id_point: 827622,
    id_pp: 925472,
    id_pp_dt: null,
    id_tp: 713825,
    postal_code: null,
    priem: "Бижбулякский РЭС",
    region: null,
    skor_point: "51.6310",
    source_name: "ТП-3285 н.п. Аитово",
    street: null,
    state_code: "Актуально",
    telebot_request: [],
    telebot_request_last_dt: "Mon, 28 Nov 2022 00:00:00 GMT",
    telebot_request_last_result: "К проверке",
    type_pu: "СЕ303 S31 746-JGVZ",
    manual_flg: true,
  },
  {
    adress_norm: "г. Уфа, ул. 4-я Районная, д. 14 450097",
    adress_too: "450097, Башкортостан Респ., Уфа г., 4-я Районная ул., дом № 14",
    city: "Уфа",
    city_district: "Советский",
    factory_count_num: "11889159487086",
    fl_ul: "Физ. лицо",
    geo_lat: 54.732906,
    geo_lon: 56.0000263,
    house: "14",
    id_point: 831246,
    id_pp: 928378,
    id_pp_dt: null,
    id_tp: 545157,
    postal_code: 450097,
    priem: "Восточный РЭС",
    region: "Башкортостан",
    skor_point: "91.1910",
    source_name: "ТП-2092 ВРЭС ПС Глумилино Ф-8",
    street: "4-я Районная",
    state_code: "Актуально",
    telebot_request: [],
    telebot_request_last_dt: "Mon, 28 Nov 2022 00:00:00 GMT",
    telebot_request_last_result: "К проверке",
    type_pu: "СЕ303 S31 746-JGVZ",
    manual_flg: true,
  },
];

export const mock_an_archive: AnomaliesArchive[] = [
  {
    created_time: "2022-02-26T11:49:30.001257",
    id: 3,
    phone: "9658855355",
    s3_title: "9658855355/clss_top_100_1_21_08_2022.xlsx",
    tenant_id: "bsk",
    user_name: "Сергей Юшманов",
  },
  {
    created_time: "2022-04-26T11:49:30.001257",
    id: 3,
    phone: "9658855355",
    s3_title: "9658855355/clss_top_100_1_25_08_2022.xlsx",
    tenant_id: "bsk",
    user_name: "Сергей Юшманов",
  },
  {
    created_time: "2022-08-26T11:49:30.001257",
    id: 3,
    phone: "9658855355",
    s3_title: "9658855355/clss_top_100_1_29_08_2022.xlsx",
    tenant_id: "bsk",
    user_name: "Сергей Юшманов",
  },
  {
    created_time: "2022-11-26T11:49:30.001257",
    id: 3,
    phone: "9658855355",
    s3_title: "9658855355/clss_top_100_1_29_08_2022.xlsx",
    tenant_id: "bsk",
    user_name: "Сергей Юшманов",
  },
  {
    created_time: "2022-08-26T11:49:30.001257",
    id: 3,
    phone: "9658855355",
    s3_title: "9658855355/clss_top_100_1_29_08_2022.xlsx",
    tenant_id: "bsk",
    user_name: "Сергей Юшманов",
  },
];
