import { useState } from "react";
import { CusTypo } from "components/cusTypo/custom-typography";
import { STabs, STabsItem } from "./substyles";
import { tabListDesktop, tabListMob } from "./const";

interface IProp {
  isMobile?: boolean;
  onSelect: (val: string) => void;
}
export const InfoTabsCard: React.FC<IProp> = ({ isMobile = false, onSelect }) => {
  const [isActive, setActive] = useState<"history" | "info">("history");
  const list = isMobile ? tabListMob : tabListDesktop;

  return (
    <STabs>
      {list.map((item) => (
        <STabsItem
          key={item.code}
          active={isActive === item.code ?? false}
          onClick={() => {
            setActive(item.code);
            onSelect(item.code);
          }}
        >
          <CusTypo variant="p4_regular">{item.name}</CusTypo>
        </STabsItem>
      ))}
    </STabs>
  );
};
