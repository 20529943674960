import MUIBreadcrumbs from "@mui/material/Breadcrumbs";
import theme from "theme";
import Icon from "components/icons";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SLink, SWrap } from "./styles";

export interface IBreadcrumbsLink {
  name: string;
  href: string;
}

interface IBreadcrumbsProps {
  links: IBreadcrumbsLink[];
}

export const Breadcrumbs = (props: IBreadcrumbsProps) => {
  const { links } = props;

  return (
    <SWrap>
      <MUIBreadcrumbs
        separator={
          <Icon.ArrowRight sx={{ fontSize: 16, color: theme.mtsColor.icons.secondary.lightMode }} />
        }
        aria-label="breadcrumbs"
      >
        {links.map((link, index) =>
          index === links.length - 1 ? (
            <CusTypo key={link.name} variant="p4_regular" styles={{ color: theme.mtsColor.red }}>
              {link.name}
            </CusTypo>
          ) : (
            <SLink key={link.name} to={link.href}>
              <CusTypo
                variant="p4_regular"
                styles={{ color: theme.mtsColor.text.headline.lightMode }}
              >
                {link.name}
              </CusTypo>
            </SLink>
          ),
        )}
      </MUIBreadcrumbs>
    </SWrap>
  );
};
