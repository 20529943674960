import format from "date-fns/format";

import { Spacer } from "components/spacer/spacer.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { DEPARTURE_RESULT, EMPTY_CELL_VALUE } from "stream-constants/keys-and-constants";
import { SCont, SItems } from "./substyles";
import { SColored } from "../styles";

export const MobDevice = ({ row }) => {
  const { sn, meter_name, dt_create, ActEpr, dt_verify, rele_status, adress } = row.original;

  return (
    <SCont>
      <section>
        <CusTypo variant="p3_bold">{sn}</CusTypo>
        <Spacer value="8px" />
        <CusTypo variant="c1_regular">{meter_name}</CusTypo>
      </section>
      <Spacer value="12px" />
      <SItems>
        <div>
          <CusTypo variant="c1_regular" font="comp">
            Дата подключения
          </CusTypo>
          <CusTypo variant="c1_regular">{format(new Date(dt_create ?? 0), "dd.MM.yyyy")}</CusTypo>
        </div>
        <div>
          <CusTypo variant="c1_regular" font="comp">
            А прием, кВт*ч
          </CusTypo>
          <CusTypo variant="c1_regular">{ActEpr ?? EMPTY_CELL_VALUE}</CusTypo>
        </div>
        <div>
          <CusTypo variant="c1_regular" font="comp">
            Дата последней передачи данных
          </CusTypo>
          <CusTypo variant="c1_regular">{format(new Date(dt_verify ?? 0), "dd.MM.yyyy")}</CusTypo>
        </div>
        <div>
          <CusTypo variant="c1_regular" font="comp">
            Реле нагрузки
          </CusTypo>
          <SColored variant="c1_regular" isOn={rele_status}>
            {rele_status ? DEPARTURE_RESULT.ENABLED : DEPARTURE_RESULT.DISABLED}
          </SColored>
        </div>
        <div>
          <CusTypo variant="c1_regular" font="comp">
            Адрес
          </CusTypo>
          <CusTypo variant="c1_regular">{adress ?? EMPTY_CELL_VALUE}</CusTypo>
        </div>
      </SItems>
    </SCont>
  );
};
