export const TABLE_SORT_ASKUE_ARCHIVE = [
  {
    accessor: "fileName",
    text: "Имя",
  },
  {
    accessor: "time_schema",
    text: "Дата и время загрузки",
  },
];
