import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ArrowHeadLeft = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8 1.01246C8 0.11226 6.92341 -0.338563 6.29377 0.297975C6.29377 0.297976 6.29377 0.297975 6.29377 0.297975L3.19835 3.4273C1.06612 5.58289 -1.26013e-06 6.66069 -3.4969e-07 8.00001C-3.92436e-07 9.33932 1.06612 10.4171 3.19836 12.5727L6.29377 15.702C6.92341 16.3386 8 15.8877 8 14.9875C8 14.7196 7.8947 14.4625 7.70726 14.2731L4.61185 11.1437C3.50577 10.0255 2.82141 9.32762 2.38882 8.75443C1.99838 8.23709 1.99883 8.05648 1.99897 8.00276L1.99897 8.00001L1.99897 7.99725C1.99883 7.94353 1.99838 7.76292 2.38882 7.24558C2.8214 6.67239 3.50577 5.97446 4.61184 4.85627L7.70726 1.72695C7.70726 1.72695 7.70726 1.72695 7.70726 1.72695C7.8947 1.53745 8 1.28044 8 1.01246Z"/>
    </SvgIcon>
  );
};

export default ArrowHeadLeft;
