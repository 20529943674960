import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CompanyLogo = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="32" height="32" fill="#FF0032" />
      <path
        d="M21.3187 2.43884V3.93174H24.5349V9.77243L24.5355 9.86859H26.3361V3.93174H29.5522V2.43884H21.3187Z"
        fill="white"
      />
      <path
        d="M9.7362 2.46108L7.38399 7.34489L5.03175 2.46108H2.46354V9.84571H4.26404V4.56069L6.56326 9.02562H8.20476L10.5046 4.56069V9.84571H12.3051V2.46108H9.7362Z"
        fill="white"
      />
      <path
        d="M27.7471 27.0758C27.7135 27.3486 27.5893 27.6021 27.3943 27.7957C27.1918 27.98 26.9429 28.1056 26.6744 28.1589C26.282 28.2367 25.8825 28.2729 25.4826 28.267C25.0567 28.2746 24.6324 28.2124 24.2266 28.083C23.894 27.975 23.6071 27.7586 23.4118 27.4685C23.2154 27.1779 23.1186 26.7584 23.1186 26.2249V25.4684C23.1186 24.931 23.2174 24.5115 23.4118 24.2209C23.6067 23.9314 23.8928 23.7153 24.2246 23.607C24.6305 23.4776 25.0547 23.4154 25.4806 23.4231C25.8806 23.4171 26.2801 23.4533 26.6725 23.5311C26.941 23.5846 27.1898 23.7102 27.3924 23.8943C27.5873 24.088 27.7115 24.3415 27.7451 24.6142H29.547C29.5144 24.0462 29.2977 23.5042 28.9298 23.0703C28.5669 22.6712 28.0972 22.3846 27.5763 22.2444C26.8932 22.0641 26.1883 21.9797 25.4819 21.9937C24.6186 21.9937 23.8699 22.1181 23.2566 22.3628C22.65 22.5963 22.1418 23.0309 21.8168 23.5939C21.4994 24.1391 21.3325 24.8669 21.3214 25.7589V25.8408L21.4176 25.8447H21.3214V25.9265C21.3325 26.8185 21.4994 27.5464 21.8168 28.0916C22.1418 28.6546 22.65 29.0891 23.2566 29.3226C23.8706 29.5674 24.62 29.6917 25.4819 29.6917C26.1883 29.706 26.8932 29.6216 27.5763 29.4411C28.0974 29.3014 28.5673 29.0146 28.9298 28.6151C29.2979 28.1812 29.5145 27.6392 29.547 27.0712L27.7471 27.0758Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default CompanyLogo;
