import { IHttpMethods } from "stream-constants/server-constants";
import { SERVER_RESPONSE_ABORT_TIME_OUT } from "stream-constants/server-constants";
import { WELCOME_PAGE_URL } from "services/urls";

interface IFetchData {
  url: string;
  body?: any;
  method: IHttpMethods;
  name: string;
  emptyResponse: boolean;
  blobResponse?: boolean;
  contentType?: string;
  accept?: string;
  login?: string;
  responseAbordTimeOut?: boolean;
}

export const fetchData = async ({
  url,
  body,
  method,
  name,
  emptyResponse,
  blobResponse,
  contentType,
  accept,
  login,
  responseAbordTimeOut = true,
}: IFetchData) => {
  let result;
  const controller = new AbortController();
  const timeoutId = setTimeout(() => {
    if (responseAbordTimeOut) controller.abort();
  }, SERVER_RESPONSE_ABORT_TIME_OUT);

  const headers: any = {
    "Content-Type": contentType ? contentType : "application/json",
    Accept: accept ? accept : "*/*",
    Login: login ? login : "",
  };
  if (contentType === "none") {
    delete headers["Content-Type"];
  }

  const obj: RequestInit = {
    signal: controller.signal,
    method,
    mode: "cors",
    cache: "no-store",
    credentials: "include", // include, *same-origin, omit
    headers,
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *client
  };

  if (body) {
    obj.body = body;
  }

  const response = await fetch(url, obj);
  clearTimeout(timeoutId);
  const { status, ok } = response;
  if (!ok || status >= 400) {
    let responseError;
    try {
      responseError = await response.json();
    } catch {
      responseError = `ok = ${ok}, status = ${status}`;
    } finally {
      if (status === 401) {
        window.location.href = WELCOME_PAGE_URL;
      }
      if (responseError?.description) {
        return Promise.reject(responseError);
      }
      let errMessage = `Ошибка запроса ${name}: status = "${status}"`;
      result = Promise.reject(new Error(errMessage));
    }
  } else {
    if (emptyResponse) {
      result = Promise.resolve(true);
    } else {
      if (blobResponse) {
        result = await response.blob();
        const head = await response.headers.get("Content-Disposition");
        const parts = head?.split(";");
        /*
			FIXME в будущем можно добавить здесь дополнительную обработку `filename=`,
			но сейчас есть только одна ручка, которая берет имя файла из хедера, и имя адекватное именно в части UTF-8 формата
		*/
        const filename = parts?.[2]?.split("filename*=UTF-8''")?.[1]?.trim() ?? "noname.xlsx";
        result.filename = decodeURI(filename ?? "");
      } else result = await response.json();
    }
  }

  return result;
};
