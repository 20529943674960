import React, { useEffect, useRef, useState } from "react";

import { ITelebotRequest } from "interface/anomalies";
import { FormAddResultCheck } from "components/forms/result-check/form-add-result-check";
import { MTSModal } from "components/mts-modal/mts-modal";
import { CopyToClipboard } from "components/copyToClipboard/copyToClipboard.component";
import { MTSButton } from "components/mts-button/mts-button";
import { CusTypo } from "components/cusTypo/custom-typography";
import { InfoTabsCard } from "components/devices/subComponents/infoTabsCard";
import {
  detailTitleByKey,
  makeTenantDetails,
  makeTenantHistory,
  sortHistoryCheckByDate,
} from "./utils";
import {
  SBody,
  SBtns,
  SHistory,
  SHistoryBlock,
  SHistoryField,
  SHistoryHeader,
  SHistoryItem,
  SHistoryTitle,
  SInfoMessage,
  SInner,
  SInnerItem,
} from "./styles";

export interface ICardInfo {
  data?: any;
  aria?: any;
  variant?: string | null | undefined;
  expanded?: string | boolean | undefined;
  onToggle?: (val: number) => void;
  updateLastStatus?: string;
  calcHeightInner?: (a: number) => void;
  isAccented?: boolean;
  props?: any;
  handleChange?: any;
  isActiveTenant?: boolean;
  isDisabled?: boolean;
}

// DEVICE LIST ACCORDION

export const DeviceAccordionDetails: React.FC<ICardInfo> = ({
  data,
  calcHeightInner,
  isDisabled,
}) => {
  const [showTab, setShowTab] = useState<"history" | "info">("history");
  const [historyCheckList, setHistoryCheckList] = useState([]);
  const [showResultModal, setResultModal] = useState(false);
  const refInner = useRef<any>(null);
  const tableTitles = [
    "Дата выезда",
    data.phase_num === "1" ? "Фазы клещи (A)" : "Фазы клещи (ABC)",
    data.phase_num === "1" ? "Фазы ПУ (A)" : "Фазы ПУ (ABC)",
    "Газ",
    "Дата отчета",
    "Статус",
    "Комментарий",
  ];

  const toggleResultModal = () => {
    setResultModal((s) => !s);
  };

  useEffect(() => {
    setHistoryCheckList(
      data.telebot_request.sort((x: ITelebotRequest, y: ITelebotRequest) =>
        sortHistoryCheckByDate(x, y),
      ),
    );
  }, [data.telebot_request]);

  useEffect(() => {
    if (refInner?.current) {
      const elem = refInner?.current;
      if (calcHeightInner) {
        calcHeightInner(elem?.getBoundingClientRect().height);
      }
    }
  });

  return (
    <SInner onClick={(event) => event.stopPropagation()} ref={refInner}>
      <div>
        <InfoTabsCard onSelect={setShowTab as any} />
      </div>
      <SBody>
        {showTab === "history" ? (
          <div>
            <SHistoryBlock>
              {historyCheckList.length ? (
                <SHistory>
                  <SHistoryHeader>
                    {tableTitles.map((title) => (
                      <SHistoryTitle>{title}</SHistoryTitle>
                    ))}
                  </SHistoryHeader>
                  {historyCheckList.map((item: ITelebotRequest, index: number) => {
                    return (
                      <SHistoryItem key={index}>
                        {Object.entries(makeTenantHistory(data, item)).map(([key, val]) => {
                          const isComment = key === "telebot_request_comment";

                          return (
                            <SHistoryField
                              key={key}
                              style={{
                                whiteSpace: isComment ? "inherit" : "nowrap",
                              }}
                            >
                              {(key === "telebot_request_metter" ||
                                key === "telebot_request_clamp") &&
                              (item.val_meter_A || item.val_clamp_A) ? (
                                val.value
                              ) : (
                                <CusTypo
                                  variant={isComment ? "p4_regular" : "p4_medium"}
                                  font="comp"
                                >
                                  {val.value}
                                </CusTypo>
                              )}
                            </SHistoryField>
                          );
                        })}
                      </SHistoryItem>
                    );
                  })}
                </SHistory>
              ) : (
                <SInfoMessage>
                  Пока еще не было добавлено ни одного результата проверок
                </SInfoMessage>
              )}
            </SHistoryBlock>
            <SBtns>
              <MTSButton
                size="M"
                variant="primary"
                type="submit"
                onClick={toggleResultModal}
                disabled={isDisabled}
              >
                Добавить результат
              </MTSButton>
            </SBtns>
            <MTSModal open={showResultModal} close={toggleResultModal}>
              <FormAddResultCheck idDevice={data.id_pp} inModal close={toggleResultModal} />
            </MTSModal>
          </div>
        ) : null}
        {showTab === "info" ? (
          <ul>
            {Object.entries(makeTenantDetails(data)).map(([key, val], index) => (
              <SInnerItem key={index}>
                <div>
                  <CusTypo variant="c1_regular">{detailTitleByKey[key]}</CusTypo>
                </div>
                <div>
                  {val["isCanCopy"] ? (
                    <CopyToClipboard text={val.text as any} />
                  ) : (
                    <CusTypo variant="c1_medium">{val.text}</CusTypo>
                  )}
                </div>
              </SInnerItem>
            ))}
          </ul>
        ) : null}
      </SBody>
    </SInner>
  );
};
