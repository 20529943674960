import { css } from "@emotion/react";
import styled from "@emotion/styled";
import rgba from "polished/lib/color/rgba";

const commonBar = css`
  height: 24px;
  border-radius: 4px;
`;
export const SBarWrap = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  padding-right: 16px;

  & > div:last-of-type {
    min-width: 24px;
    text-align: left;
  }
`;

export const SBar = styled.div`
  ${commonBar};
  position: relative;
  width: 100%;
  background-color: ${(props) => rgba(props.theme.mtsColor.accent.active.lightMode, 0.1)};
`;

export const SBarBack = styled.div<{ width: number }>`
  ${commonBar};
  position: absolute;
  left: 0;
  top: 0;
  width: ${(props) => props.width}%;
  background-color: ${(props) => props.theme.mtsColor.accent.active.lightMode};
`;
