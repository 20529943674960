import { useNavigate } from "react-router-dom";
import { CusTypo } from "components/cusTypo/custom-typography";
import Icon from "components/icons";
import { SWrap } from "./styles";

export const Back = () => {
  const navigate = useNavigate();
  return (
    <SWrap onClick={() => navigate(-1)}>
      <Icon.ArrowBack sx={{ width: 21, height: 16 }} />

      <CusTypo variant="p3_regular">Назад</CusTypo>
    </SWrap>
  );
};
