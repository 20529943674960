export const base = [
  {
    id: 1,
    created_time: "2022-06-20T10:00:00",
    phone: "88007777777",
    title: "ООО БашкирЭнерго",
    status: "Приостановлен",
  },
  {
    id: "2",
    created_time: "2022-06-20T10:00:00",
    phone: "+79651478026",
    title: "ООО Мегатреш",
    status: "Заполнение анкеты",
  },
  {
    id: 4,
    created_time: "2022-06-20T10:00:00",
    phone: "+79651478026",
    title: "ООО Незнамочто",
    status: "На модерации",
  },
  {
    id: "5",
    created_time: "2022-06-20T10:00:00",
    phone: "+79651478026",
    title: "ООО Пук",
    status: "Активен",
  },
  {
    id: 3,
    created_time: "2022-06-20T10:00:00",
    phone: "+79651478026",
    title: "ООО Азаза",
    status: "На согласовании",
  },
];

export const single_mng_score = {
  TM: "21",
  change_flg: true,
  consumer_type: "ФЛ;ЮЛ",
  discr_type: "Суточные",
  dq_score: "Допустимо",
  email: "demo@mts.ru",
  fraud_flg: false,
  inn: "1111111",
  measure_type: "кВт*ч",
  meter_cnt: 10,
  period_from: "Sat, 01 Jan 2022 00:00:00 GMT",
  period_to: "Mon, 31 Jan 2022 00:00:00 GMT",
  tariff_type: "Однотарифный",
  user_comment: "мы все умрем",
  val_type: "Интервальные",
};
